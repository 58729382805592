import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FileObject } from 'material-ui-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ThemeDropZone, ThemeModalWindow, ThemeCircularProgress } from 'src/theming';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from './contracts-upload-documents-form-styles';

import { contractUploadDocumentFormValidationSchema } from './contracts-upload-documents-form-validation-schema';

import { ContractUploadDocumentFormDto } from './contracts-upload-documents-form-dto';

import { contractUploadDocumentsFormGetSuccessffulMessage } from './contracts-upload-documents-form-get-success-message';

interface ContractUploadDocumentFormProps {
    isOpen: boolean;
    handleCloseModalWindow: () => void;
    pathForRequest: string;
    formTitle: string;
    dragAndDropLabel: string;
    formDescription?: string;
    documentFormats: string[];
    onSuccess?: () => void;
    withoutValidation?: boolean;
}

type ContractUploadDocumentFormData = {
    files: FileObject[];
    comment: string;
};

export const ContractUploadDocumentForm = (
    props: ContractUploadDocumentFormProps
): JSX.Element => {
    const {
        isOpen,
        handleCloseModalWindow,
        pathForRequest,
        formTitle,
        formDescription,
        dragAndDropLabel,
        documentFormats,
        onSuccess,
        withoutValidation,
    } = props;

    const [isSaveLoading, setSaveLoading] = useState(false);

    const { t } = useTranslation(['common', 'contract-details-view']);
    const { enqueueSnackbar } = useSnackbar();
    const httpClient = useHttpClient();

    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlClasses = useFormControlStyles();
    const inputClasses = useInputStyles();

    const { watch, setValue, control, reset, formState, trigger } =
        useForm<ContractUploadDocumentFormData>({
            mode: 'onChange',
            defaultValues: { files: [], comment: '' },
            resolver: !withoutValidation
                ? yupResolver(contractUploadDocumentFormValidationSchema)
                : null,
        });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    useEffect(() => {
        if (isOpen) {
            trigger();
        }
    }, [isOpen]);

    const [comment] = watch(['comment']);

    const handleAddFile = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
        trigger();
    };

    const handleDeleteFile = (index: number): void => {
        remove(index);
        trigger();
    };

    const handleChangeValue = (name, value): void => {
        setValue(name, value);
    };

    const onClose = (): void => {
        reset({ files: [], comment: '' });
        handleCloseModalWindow();
    };

    const makeRequestForSavingForm = (): Promise<ContractUploadDocumentFormDto> => {
        setSaveLoading(true);
        const formData = new FormData();
        formData.append('comment', comment);
        fields.forEach((item) => {
            formData.append('documents', item.file);
        });
        return httpClient.post<ContractUploadDocumentFormDto>(pathForRequest, formData);
    };

    const handleSave = async (): Promise<void> => {
        try {
            await makeRequestForSavingForm();

            onClose();
            onSuccess();
            enqueueSnackbar(t(contractUploadDocumentsFormGetSuccessffulMessage()), {
                variant: 'success',
            });
        } catch {
            enqueueSnackbar(t('errors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setSaveLoading(false);
        }
    };

    return (
        <ThemeModalWindow
            isOpen={isOpen}
            onClose={onClose}
            modalWindowClass={classes.modalWindowClass}
        >
            <Typography variant='h5' className={classes.modalTitle}>
                {formTitle}
            </Typography>
            <Grid container>
                {formDescription && (
                    <Typography variant='body2' className={classes.description}>
                        {formDescription}
                    </Typography>
                )}
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeDropZone
                                error={Boolean(formState?.errors?.files)}
                                onAdd={handleAddFile}
                                onRemove={handleDeleteFile}
                                fileObjects={fields}
                                formats={documentFormats}
                            />
                        }
                        label={dragAndDropLabel}
                        labelPlacement='top'
                    />
                </FormControl>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                multiline
                                rows={5}
                                classes={inputClasses}
                                name='question'
                                value={comment}
                                onChange={(e) =>
                                    handleChangeValue('comment', e.target.value)
                                }
                                placeholder={t(
                                    'contract-details-view:contractDetails.vehicleDeregistration.enterText'
                                )}
                            />
                        }
                        label={t(
                            'contract-details-view:contractDetails.vehicleDeregistration.comment'
                        )}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleSave}
                    disabled={!formState.isValid || isSaveLoading}
                    startIcon={<ThemeCircularProgress isLoading={isSaveLoading} />}
                >
                    {t('common:send')}
                </Button>
                <Button
                    disabled={isSaveLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};

ContractUploadDocumentForm.defaultProps = {
    onSuccess: () => null,
    withoutValidation: false,
};
