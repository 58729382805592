import React from 'react';
import { createContext, useContext, ReactElement } from 'react';
import { AnyAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { ability } from './ability';

export const AbilityContext = createContext<AnyAbility>(ability);
export const useAbilityCtx = (): AnyAbility => useContext(AbilityContext);
export const Can = createContextualCan(AbilityContext.Consumer);

interface AbilityContextProviderProps {
    children: ReactElement | ReactElement[];
}

export const AbilityContextProvider = ({
    children,
}: AbilityContextProviderProps): ReactElement => {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
