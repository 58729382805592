import React, { BaseSyntheticEvent, useState, useMemo, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import uuid from 'uniqid';

import {
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
    Checkbox,
    Button,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ThemeSelectInput, SelectItem, ThemeCircularProgress } from 'src/theming';
import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';
import { shallowEqual, sortByFirstLevelKey } from 'src/shared/utils';

import { NotificationRowEmails } from './notification-row-emails';

import {
    useNotificationRowStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useFormControlCheckboxLabelStyle,
} from './notifications-styles';

import {
    generateIntermediaryItems,
    generateCustomerRolesDropDownItems,
    generateAccountManagerItems,
} from './notifications-config';
import { notificationValidationSchema } from './notification-validation-schema';
import { notificationRowDefaultState } from './notification-row-default-state';
import { useNotificationMutateRow } from './notification-mutate-row';
import { useNotificationDeleteRow } from './notification-delete-row';

export interface NotificationRow {
    stateId?: string;
    id?: string;
    trigger?: string;
    accountManager?: string;
    customerAdviser?: string[];
    fallbackEmail?: { id?: string; email: string }[];
    customers?: string[];
    furtherReceiversEmail?: { id?: string; email: string }[];
    displayNotificationClerk?: boolean;
    displayNotificationCustomerAdviser?: boolean;
    subrows?: NotificationRow[];
    isSubrow?: boolean;
    divisions?: SelectItem[];
    division?: string;
    cbAfterDivisionSelection?: (deletedDivision: string, newDivision: string) => void;
    onDeleteRowFromState?: (id: string) => void;
    refetchNotifications?: () => Promise<void>;
    expandedRows?: Set<string>;
    handleAddExpandedRow?: (id?: string) => void;
    handleDeleteExpandedRow?: (id?: string) => void;
}

export type NotificationsRowFormData = {
    division: string;
    accountManager: string;
    customerAdvisers: { name: string }[];
    fallbackEmails: { name: string }[];
    furtherReceiversEmails: { name: string }[];
    customers: { name: string }[];
    displayNotificationClerk: boolean;
    displayNotificationCustomerAdviser: boolean;
};

const NotificationsRow = (props: NotificationRow): JSX.Element => {
    const classes = useNotificationRowStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlCheckboxLabelClasses = useFormControlCheckboxLabelStyle();

    const { t } = useTranslation(['divisions']);

    const {
        id,
        stateId,
        trigger,
        customerAdviser,
        fallbackEmail,
        customers,
        furtherReceiversEmail,
        displayNotificationClerk,
        displayNotificationCustomerAdviser,
        accountManager,
        subrows,
        isSubrow,
        divisions,
        division,
        cbAfterDivisionSelection,
        onDeleteRowFromState,
        refetchNotifications,
        expandedRows,
        handleAddExpandedRow,
        handleDeleteExpandedRow,
    } = props;

    const [divisionsInUse, setDivisionsInUse] = useState<Set<string>>(
        new Set(subrows?.map((item) => item.division) || [])
    );
    const [newSubrows, setNewSubrows] = useState<NotificationRow[]>([]);
    const [isAdditionalRowsOpened, setAdditionalRowsOpened] = useState<boolean>(
        expandedRows?.has(id)
    );

    const {
        control,
        setValue,
        register,
        watch,
        formState,
        trigger: validationTrigger,
    } = useForm<NotificationsRowFormData>({
        mode: 'onChange',
        resolver: yupResolver(notificationValidationSchema),
        defaultValues: notificationRowDefaultState({
            division: division
                ? division
                : (isSubrow && divisions?.length === 1 && divisions[0].value) || '',
            accountManager,
            displayNotificationClerk,
            displayNotificationCustomerAdviser,
            customers,
            customerAdviser,
            fallbackEmail,
            furtherReceiversEmail,
        }),
    });

    const { fields: customerAdvisers } = useFieldArray({
        control,
        name: 'customerAdvisers',
    });

    const { fields: controlledCustomers } = useFieldArray({
        control,
        name: 'customers',
    });

    const {
        fields: fallbackEmails,
        prepend: fbPrepend,
        remove: fbRemove,
    } = useFieldArray({
        control,
        name: 'fallbackEmails',
    });

    const {
        fields: furtherReceiversEmails,
        prepend: frPrepend,
        remove: frRemove,
    } = useFieldArray({
        control,
        name: 'furtherReceiversEmails',
    });

    const [
        watchedFallbackEmails,
        watchedFurtherReceivers,
        watchedDisplayNotificationClerk,
        watchedDisplayNotificationCustomerAdviser,
        watchedCustomerAdvisors,
        watchedCustomers,
        watchedAccountManager,
        watchedDivision,
    ] = watch([
        'fallbackEmails',
        'furtherReceiversEmails',
        'displayNotificationClerk',
        'displayNotificationCustomerAdviser',
        'customerAdvisers',
        'customers',
        'accountManager',
        'division',
    ]);

    const [triggerCompare, setTriggerCompare] = useState<boolean>(false);

    const [isNotificationDeletingLoading, deleteRow] =
        useNotificationDeleteRow(refetchNotifications);

    const [isNotificationUploadLoading, updateSettings] = useNotificationMutateRow({
        trigger,
        id,
        formState,
        watchedFurtherReceivers,
        watchedFallbackEmails,
        watchedCustomerAdvisors,
        watchedCustomers,
        watchedAccountManager,
        watchedDisplayNotificationClerk,
        watchedDisplayNotificationCustomerAdviser,
        setTriggerCompare,
        refetchNotifications,
    });

    const {
        compareCustomerAdvisors,
        compareCustomers,
        compareFallbackEmails,
        compareFurtherReceivers,
    } = useMemo(
        () => ({
            compareFurtherReceivers: [...watchedFurtherReceivers],
            compareFallbackEmails: [...watchedFallbackEmails],
            compareCustomerAdvisors: [...watchedCustomerAdvisors],
            compareCustomers: [...watchedCustomers],
        }),
        [triggerCompare]
    );

    const controlledFallbackEmails = fallbackEmails.map((field, index) => {
        return {
            ...field,
            ...watchedFallbackEmails[index],
        };
    });
    const controlledFurtherReceivers = furtherReceiversEmails.map((field, index) => {
        return {
            ...field,
            ...watchedFurtherReceivers[index],
        };
    });

    const handleChangeDropDown = (e: BaseSyntheticEvent): void => {
        setValue(
            e.target.name,
            e.target.value.map((item: string) => ({ name: item }))
        );
    };

    const handleAddFallbackEmail = (): void => {
        fbPrepend({ name: `` });
        validationTrigger();
    };

    const handleRemoveFallbackEmail = (index: number): void => {
        fbRemove(index);
        validationTrigger();
    };

    const handleRemoveFurtherReceiverEmail = (index: number): void => {
        frRemove(index);
        validationTrigger();
    };

    const handleAddFurtherReceiverEmail = (): void => {
        frPrepend({ name: `furtherReceivers${controlledFallbackEmails.length}` });
        validationTrigger();
    };

    const handleChangeCheckbox = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.checked);
    };

    const handleChangeEmail = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
        validationTrigger();
    };

    const handleChangeNonMultipleSelect = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
        validationTrigger();
        if (e.target.name === 'division') {
            cbAfterDivisionSelection(watchedDivision, e.target.value);
            updateSettings(e.target.value);
        } else {
            updateRow();
        }
    };

    const handleDeleteNewSubRowFromState = (id: string): void => {
        const filteredSubRows = newSubrows.filter((item) => item.stateId !== id);
        setNewSubrows(filteredSubRows);
    };

    const compareChanges = (): boolean => {
        return (
            shallowEqual(
                [...watchedCustomers].sort((a, b) => sortByFirstLevelKey(a, b, 'name')),
                [...compareCustomers].sort((a, b) => sortByFirstLevelKey(a, b, 'name'))
            ) &&
            shallowEqual(
                [...watchedFurtherReceivers].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                ),
                [...compareFurtherReceivers].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                )
            ) &&
            shallowEqual(
                [...watchedFallbackEmails].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                ),
                [...compareFallbackEmails].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                )
            ) &&
            shallowEqual(
                [...watchedCustomerAdvisors].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                ),
                [...compareCustomerAdvisors].sort((a, b) =>
                    sortByFirstLevelKey(a, b, 'name')
                )
            ) &&
            accountManager === watchedAccountManager &&
            displayNotificationClerk === watchedDisplayNotificationClerk &&
            displayNotificationCustomerAdviser ===
                watchedDisplayNotificationCustomerAdviser
        );
    };

    const updateRow = (): void => {
        if (!compareChanges()) {
            updateSettings(watchedDivision);
        }
    };

    const handleOnCloseSelect = (): void => {
        updateRow();
    };

    const handleAddNewSubrow = (): void => {
        const newRows = [...newSubrows];
        if (divisions?.length === 1) {
            cbAfterDivisionSelection(watchedDivision, divisions[0]?.value);
        }
        newRows.push({
            trigger,
            division: '',
            stateId: uuid(),
            accountManager: 'None',
        });
        setNewSubrows(newRows);
    };

    const handleUpdateDivisionsInUse = (
        deletedDivision: string,
        newDivision: string
    ): void => {
        const newDivisionsInUse = new Set(Array.from(divisionsInUse));
        newDivisionsInUse.delete(deletedDivision);
        newDivisionsInUse.add(newDivision);
        setDivisionsInUse(newDivisionsInUse);
    };

    const handleExpand = (id: string): void => {
        setAdditionalRowsOpened(!isAdditionalRowsOpened);
        if (isAdditionalRowsOpened) {
            handleDeleteExpandedRow(id);
        } else {
            handleAddExpandedRow(id);
        }
    };

    const customerRoleItems = useMemo(() => generateCustomerRolesDropDownItems(t), [t]);

    useEffect(() => {
        if (isSubrow && divisions?.length === 1 && divisions[0].value) {
            cbAfterDivisionSelection('', divisions[0]?.value);
        }
    }, []);

    useEffect(() => {
        if (
            accountManager !== watchedAccountManager ||
            displayNotificationCustomerAdviser !==
                watchedDisplayNotificationCustomerAdviser ||
            displayNotificationClerk !== watchedDisplayNotificationClerk
        ) {
            updateRow();
        }
    }, [
        accountManager,
        watchedAccountManager,
        displayNotificationCustomerAdviser,
        watchedDisplayNotificationCustomerAdviser,
        displayNotificationClerk,
        watchedDisplayNotificationClerk,
    ]);

    useEffect(() => {
        if (
            fallbackEmails?.length < fallbackEmail?.length ||
            furtherReceiversEmails?.length < furtherReceiversEmail?.length
        ) {
            updateRow();
        }
    }, [fallbackEmails, furtherReceiversEmails]);

    return (
        <>
            <Grid item container spacing={2} className={classes.row}>
                <Grid item container className={classes.cell} style={{ width: '15%' }}>
                    {isSubrow && division && (
                        <Grid container item alignItems='center' spacing={10}>
                            <Grid item md={2}>
                                <IconButton
                                    disabled={
                                        isNotificationUploadLoading ||
                                        isNotificationDeletingLoading
                                    }
                                    className={clsx(
                                        classes.iconButton,
                                        classes.iconDeleteButton
                                    )}
                                    onClick={() => deleteRow(id)}
                                >
                                    {!isNotificationDeletingLoading && <DeleteIcon />}
                                    <ThemeCircularProgress
                                        isLoading={isNotificationDeletingLoading}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}>
                                <Typography variant='body2' className={classes.trigger}>
                                    {t(division)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {isSubrow && !division && (
                        <Grid container item alignItems='center' spacing={10}>
                            <Grid item md={2}>
                                <IconButton
                                    onClick={() => {
                                        onDeleteRowFromState(stateId);
                                        cbAfterDivisionSelection(watchedDivision, '');
                                    }}
                                    disabled={isNotificationUploadLoading}
                                    className={clsx(
                                        classes.iconButton,
                                        classes.iconDeleteButton
                                    )}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}>
                                <FormControl
                                    fullWidth
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t(
                                            'user-management:notificationsSection.division'
                                        )}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <ThemeSelectInput
                                                alphabeticallySort
                                                namespaces={['divisions']}
                                                editMode={!isNotificationUploadLoading}
                                                name='division'
                                                value={watchedDivision}
                                                onChange={handleChangeNonMultipleSelect}
                                                items={
                                                    watchedDivision &&
                                                    !divisions.some(
                                                        (d) => d.value === watchedDivision
                                                    )
                                                        ? [
                                                              ...divisions,
                                                              {
                                                                  title: watchedDivision,
                                                                  value: watchedDivision,
                                                              },
                                                          ]
                                                        : divisions
                                                }
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {!isSubrow && (
                        <Grid container item>
                            <Grid item md={12}>
                                <Typography variant='body2' className={classes.trigger}>
                                    {t(`user-management:notificationsSection.${trigger}`)}
                                </Typography>
                            </Grid>
                            {Array.isArray(subrows) && (
                                <Grid item md={12}>
                                    <Button
                                        className={classes.otherButton}
                                        onClick={() => handleExpand(id)}
                                    >
                                        {!isAdditionalRowsOpened
                                            ? t(
                                                  'user-management:notificationsSection.further'
                                              )
                                            : t(
                                                  'user-management:notificationsSection.hide'
                                              )}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid item container className={classes.cell} style={{ width: '15%' }}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        {appInstance !== AppInstances.AON_KOMPOSIT && (
                            <FormControlLabel
                                label={t(
                                    'user-management:notificationsSection.accountManager'
                                )}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        multiple
                                        editMode={!isNotificationUploadLoading}
                                        name='customerAdvisers'
                                        value={customerAdvisers.map((item) => item.name)}
                                        onChange={handleChangeDropDown}
                                        items={generateIntermediaryItems(t)}
                                        callbackAfterClose={handleOnCloseSelect}
                                    />
                                }
                            />
                        )}
                        {appInstance === AppInstances.AON_KOMPOSIT && (
                            <FormControlLabel
                                label={t(
                                    'user-management:notificationsSection.accountManager'
                                )}
                                labelPlacement='top'
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        editMode={!isNotificationUploadLoading}
                                        name='accountManager'
                                        value={watchedAccountManager}
                                        onChange={handleChangeNonMultipleSelect}
                                        items={generateAccountManagerItems(t)}
                                    />
                                }
                            />
                        )}
                    </FormControl>
                </Grid>
                <Grid item container className={classes.cell} style={{ width: '20%' }}>
                    <NotificationRowEmails
                        emails={controlledFallbackEmails}
                        isNotificationUploadLoading={isNotificationUploadLoading}
                        handleAddEmail={handleAddFallbackEmail}
                        formState={formState}
                        register={register}
                        handleChangeEmail={handleChangeEmail}
                        updateRow={updateRow}
                        handleRemoveEmail={handleRemoveFallbackEmail}
                        registerName='fallbackEmails'
                        startName='fallback'
                    />
                </Grid>
                <Grid item container className={classes.cell} style={{ width: '15%' }}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            label={t('user-management:userGroups.customer')}
                            labelPlacement='top'
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSelectInput
                                    editMode={!isNotificationUploadLoading}
                                    multiple
                                    name='customers'
                                    value={controlledCustomers.map((item) => item.name)}
                                    onChange={handleChangeDropDown}
                                    items={customerRoleItems}
                                    callbackAfterClose={handleOnCloseSelect}
                                />
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item container className={classes.cell} style={{ width: '20%' }}>
                    <NotificationRowEmails
                        emails={controlledFurtherReceivers}
                        isNotificationUploadLoading={isNotificationUploadLoading}
                        handleAddEmail={handleAddFurtherReceiverEmail}
                        formState={formState}
                        register={register}
                        handleChangeEmail={handleChangeEmail}
                        updateRow={updateRow}
                        handleRemoveEmail={handleRemoveFurtherReceiverEmail}
                        registerName='furtherReceiversEmails'
                        startName='furtherReceivers'
                    />
                </Grid>
                <Grid
                    item
                    container
                    className={classes.cell}
                    style={{ width: '15%', justifyContent: 'center' }}
                >
                    <FormControlLabel
                        classes={formControlCheckboxLabelClasses}
                        control={
                            <Checkbox
                                disabled={isNotificationUploadLoading}
                                disableRipple
                                icon={
                                    <CheckBoxOutlineBlankIcon className={classes.icon} />
                                }
                                checkedIcon={
                                    <CheckBoxIcon
                                        className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                        )}
                                    />
                                }
                                checked={watchedDisplayNotificationClerk}
                                onChange={handleChangeCheckbox}
                                name='displayNotificationClerk'
                            />
                        }
                        label={t('user-management:notificationsSection.customer')}
                        labelPlacement='top'
                    />
                    <FormControlLabel
                        classes={formControlCheckboxLabelClasses}
                        control={
                            <Checkbox
                                disabled={isNotificationUploadLoading}
                                disableRipple
                                icon={
                                    <CheckBoxOutlineBlankIcon className={classes.icon} />
                                }
                                checkedIcon={
                                    <CheckBoxIcon
                                        className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                        )}
                                    />
                                }
                                checked={watchedDisplayNotificationCustomerAdviser}
                                onChange={handleChangeCheckbox}
                                name='displayNotificationCustomerAdviser'
                            />
                        }
                        label={t('user-management:notificationsSection.intern')}
                        labelPlacement='top'
                    />
                </Grid>
            </Grid>
            {isAdditionalRowsOpened && Array.isArray(subrows) && (
                <>
                    {subrows.map((props) => {
                        return (
                            <NotificationsRow
                                isSubrow
                                key={props.id}
                                trigger={trigger}
                                refetchNotifications={refetchNotifications}
                                {...props}
                            />
                        );
                    })}
                    {newSubrows.map((props) => {
                        return (
                            <NotificationsRow
                                isSubrow
                                key={props.stateId}
                                divisions={divisions?.filter(
                                    (d) => !divisionsInUse.has(d.value)
                                )}
                                cbAfterDivisionSelection={handleUpdateDivisionsInUse}
                                onDeleteRowFromState={handleDeleteNewSubRowFromState}
                                refetchNotifications={refetchNotifications}
                                trigger={trigger}
                                {...props}
                            />
                        );
                    })}
                    {subrows?.length + newSubrows?.length < divisions.length &&
                        newSubrows?.length === 0 && (
                            <Button
                                startIcon={<AddIcon />}
                                className={classes.otherButton}
                                style={{ marginLeft: '2rem', marginBottom: '2rem' }}
                                onClick={handleAddNewSubrow}
                            >
                                {t('user-management:notificationsSection.addDivision')}
                            </Button>
                        )}
                </>
            )}
        </>
    );
};

NotificationsRow.defaultProps = {
    accountManager: 'none',
    division: '',
    divisions: [],
    divisionsInUse: [],
    cbAfterDivisionSelection: () => null,
};

export default memo(NotificationsRow);
