import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        modalTitle: {
            marginBottom: theme.spacing(4),
            color: theme.palette.primary.contrastText,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
        newURLContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& .MuiFormLabel-root': {
                paddingLeft: 0,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.light,
                fontSize: '1.4rem',
            },
            '& .MuiFormLabel-root,.MuiButtonBase-root': {
                width: 'fit-content',
            },
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return `${
                            isDarkMode
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light
                        }!important`;
                    }
                    return `${theme.palette.primary.light}!important`;
                },
            },
            '& .MuiInputBase-root': {
                '& .MuiSvgIcon-root': {
                    width: 15,
                    height: 15,
                },
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles(
    (theme: Theme) => {
        const isDarkMode = THEME_MODES.DARK === theme.palette.type;
        return {
            root: {
                width: '100%',
                height: 40,
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return isDarkMode
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light;
                    }
                    return theme.palette.primary.light;
                },
            },
            focused: {
                '& fieldSet': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
            },
            input: {
                '&:-internal-autofill-selected': {
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
                },
            },
        };
    },
    { index: 1 }
);
