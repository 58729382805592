import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum ProjectPhase {
    STORAGE = 'storage',
    INTERMEDIATE_TRANSPORT = 'intermediateTransport',
    ASSEMBLY_CONSTRUCTION = 'assemblyConstruction',
    TESTING = 'testing',
    WARRANTY_PERIOD_SINCE = 'warrantyPeriodSince',
}

export const damageReportFormGenerateAONTechnicalProjectPhaseOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:projectPhase.storage'),
            value: ProjectPhase.STORAGE,
        },
        {
            title: t('damages-report:projectPhase.intermediateTransport'),
            value: ProjectPhase.INTERMEDIATE_TRANSPORT,
        },
        {
            title: t('damages-report:projectPhase.assemblyConstruction'),
            value: ProjectPhase.ASSEMBLY_CONSTRUCTION,
        },
        {
            title: t('damages-report:projectPhase.testing'),
            value: ProjectPhase.TESTING,
        },
        {
            title: t('damages-report:projectPhase.warrantyPeriodSince'),
            value: ProjectPhase.WARRANTY_PERIOD_SINCE,
        },
    ];
};
