import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { APP_ROUTES } from 'src/routing';

import { DamageResponseBody } from './damage-response-body';

export const useDamageDetailsData = (
    id: string
): {
    data: DamageResponseBody;
    isLoading: boolean;
} => {
    const httpClient = useHttpClient();
    const router = useHistory();

    const [data, setData] = useState<DamageResponseBody>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const getData = async (): Promise<void> => {
        try {
            setLoading(true);
            const data = await httpClient.get<DamageResponseBody>(`damages/${id}`);
            setData(data);
        } catch (err) {
            if (
                err?.error?.response?.data?.errors?.userSettings?.includes(
                    'User does not have access to these data.'
                )
            ) {
                router.push(APP_ROUTES.NO_ACCESS);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setData(null);
        getData();
    }, [id]);

    return { isLoading, data };
};
