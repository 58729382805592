const TIMESTAMP = 'timestamp';

export const dailyExpiringLocalStorage = function <T>(
    key: string,
    subKey: string
): [loadValue: () => T, storeValue: (value: T) => void] {
    const refreshStorage = (): void => {
        localStorage.removeItem(key);
        const values = {};
        values[TIMESTAMP] = today();
        localStorage.setItem(key, JSON.stringify(values));
    };

    const refreshStorageIfNeeded = (): void => {
        const values = JSON.parse(localStorage.getItem(key));
        if (!values) {
            refreshStorage();
        } else if (!values[TIMESTAMP] || values[TIMESTAMP] < today()) {
            refreshStorage();
        }
    };

    const loadValue = (): T => {
        refreshStorageIfNeeded();
        const values = JSON.parse(localStorage.getItem(key));
        return !values ? null : values[subKey] ?? null;
    };

    const storeValue = (value: T): void => {
        refreshStorageIfNeeded();
        const values = JSON.parse(localStorage.getItem(key));
        if (values) {
            values[subKey] = value;
            localStorage.setItem(key, JSON.stringify(values));
        }
    };

    return [loadValue, storeValue];
};

const today = (): string => {
    return new Date().toJSON().slice(0, 10); // e.g. 2023-05-22
};
