import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

type UseManualUserActivationReturn = [boolean, () => Promise<void>];

export const useManualUserActivation = (
    id: string,
    onSuccess: (password: string) => void
): UseManualUserActivationReturn => {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['errors']);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState<boolean>(false);

    const activateUser = async (): Promise<void> => {
        if (id) {
            setLoading(true);
            try {
                const response = await httpClient.put<{ password: string }>(
                    `users/${id}/approve`
                );
                onSuccess(response.password);
            } catch {
                enqueueSnackbar(t('unknownError'), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return [isLoading, activateUser];
};
