import { GridColDef, GridRowId } from '@material-ui/data-grid';
import { CheckboxItem } from 'src/theming';
import {
    renderCellExpand,
    renderCellAsDateExpand,
} from 'src/theming/theme-table/theme-table-cell-expand';
import { TFunction } from 'i18next';
import { IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React from 'react';

export const generateGlobalSearchListDependOnGlobalFilter = (
    t: TFunction,
    list: CheckboxItem[]
): CheckboxItem[] => {
    const filteredClientConnectionList = list.find((item) => item.value === 'all')
        ?.checked
        ? list.filter((item) => item.value !== 'all')
        : list.filter((item) => item.checked && item.value !== 'all');
    return [
        ...filteredClientConnectionList.map((item) => ({ ...item, checked: false })),
        {
            value: 'all',
            title: t('all'),
            checked: true,
            data: undefined,
        },
    ];
};

export enum ContractColumnKeys {
    OPEN = '',
    CLIENT_CONNECTION = 'customerConnection',
    CLIENT = 'customer',
    DIVISION = 'divisionName',
    PRODUCT = 'product',
    RISK = 'risk',
    VNR = 'contractNumber',
    START_OF_CONTRACT = 'startDate',
    CONTRACT_EXPIRATION = 'endDate',
    STATUS_CONTRACT = 'status',
}

export const generateGlobalSearchContractsTableHeader = (
    t: TFunction,
    handleContractDetails: (params: { id: GridRowId }) => void
): GridColDef[] => {
    return [
        {
            field: ContractColumnKeys.OPEN,
            width: 60,
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        handleContractDetails({ id });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: ContractColumnKeys.CLIENT_CONNECTION,
            headerName: t('global-search-list:clientConnection'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.CLIENT,
            headerName: t('global-search-list:client'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.DIVISION,
            headerName: t('global-search-list:division'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.PRODUCT,
            headerName: t('global-search-list:product'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.RISK,
            headerName: t('global-search-list:generalRisk'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.VNR,
            headerName: t('global-search-list:vnr'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: ContractColumnKeys.START_OF_CONTRACT,
            headerName: t('global-search-list:contracts.startOfContract'),
            flex: 1,
            renderCell: renderCellAsDateExpand,
        },
        {
            field: ContractColumnKeys.CONTRACT_EXPIRATION,
            headerName: t('global-search-list:contracts.contractExpiration'),
            flex: 1,
            renderCell: renderCellAsDateExpand,
        },
        {
            field: ContractColumnKeys.STATUS_CONTRACT,
            headerName: t('global-search-list:contracts.status'),
            flex: 1,
            renderCell: renderCellExpand,
        },
    ];
};

export enum DamageColumnKeys {
    OPEN = '',
    CLIENT_CONNECTION = 'customerConnection',
    CLIENT = 'customer',
    PLACE = 'cityAndPostcode',
    DIVISION = 'divisionName',
    PRODUCT = 'product',
    RISK = 'risk',
    VNR = 'contractNumber',
    DAMAGE_NR = 'damageNumber',
    DAMAGE_NR_ESTATE_AGENTS = 'damageNumberE',
    DAMAGE_NR_VU = 'damageNumberI',
    DAMAGE_DAY = 'dayOfDamage',
    DAMAGE_STATUS = 'status',
}

export const generateGlobalSearchDamagesTableHeader = (
    t: TFunction,
    handleContractDetails: (params: { id: GridRowId }) => void
): GridColDef[] => {
    return [
        {
            field: DamageColumnKeys.OPEN,
            width: 60,
            renderCell: ({ id }) => (
                <IconButton
                    onClick={() => {
                        handleContractDetails({ id });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: DamageColumnKeys.CLIENT_CONNECTION,
            headerName: t('global-search-list:clientConnection'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.CLIENT,
            headerName: t('global-search-list:client'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.PLACE,
            headerName: t('global-search-list:place'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.DIVISION,
            headerName: t('global-search-list:division'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.PRODUCT,
            headerName: t('global-search-list:product'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.RISK,
            headerName: t('global-search-list:generalRisk'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.VNR,
            headerName: t('global-search-list:vnr'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.DAMAGE_NR,
            headerName: t('global-search-list:damages.damageNR'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.DAMAGE_NR_ESTATE_AGENTS,
            headerName: t('global-search-list:damages.damageNREstateAgent'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.DAMAGE_NR_VU,
            headerName: t('global-search-list:damages.damageNRVU'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamageColumnKeys.DAMAGE_DAY,
            headerName: t('global-search-list:damages.damageDay'),
            flex: 1,
            renderCell: renderCellAsDateExpand,
        },
        {
            field: DamageColumnKeys.DAMAGE_STATUS,
            headerName: t('global-search-list:damages.status'),
            flex: 1,
            renderCell: renderCellExpand,
        },
    ];
};
