import { useEffect } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { applicationVersion } from 'src/environment';

import { useAxios } from 'src/lib/http-client/use-http-client';

export const useAppVersionInterceptor = (): void => {
    const axios = useAxios();

    useEffect(() => {
        const appVersionInterceptor = (
            config: AxiosRequestConfig
        ): AxiosRequestConfig => {
            if (config.headers) {
                config.headers.AppVersion = applicationVersion || '';
            }

            return config;
        };

        const appVersionResponseInterceptor = (error: AxiosError): Promise<void> => {
            if (
                error.response != null &&
                error.response.status === 400 &&
                typeof error.response.data === 'string'
            ) {
                const msg = error.response.data as string;
                if (msg?.startsWith('Version validation problem')) {
                    window.location.reload();
                    return Promise.resolve();
                }
            }

            return Promise.reject(error);
        };

        const reqInterceptor = axios.interceptors.request.use(appVersionInterceptor);
        const responeInterceptor = axios.interceptors.response.use(
            (response) => response,
            appVersionResponseInterceptor
        );

        return () => {
            if (reqInterceptor) {
                axios.interceptors.request.eject(reqInterceptor);
            }

            if (responeInterceptor) {
                axios.interceptors.response.eject(responeInterceptor);
            }
        };
    }, []);
};
