import { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import update from 'immutability-helper';

import { useDashboardContext } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { Divisions } from 'src/shared/constants';

interface DashboardSettingsDTO {
    widgets: {
        name: string;
        position: number;
        isShown: boolean;
    }[];
}

interface useDashboardSettingsReturn {
    settings: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    editMode: boolean;
    handleChangeShown: (widgetName: string, value: boolean) => void;
}

interface useDashboardSettingsProps {
    division: string;
    setSettingsLoaded?: Dispatch<SetStateAction<boolean>>;
}

const getDivisionForRequest = (division: string): string => {
    if (division === Divisions.KFZW) {
        return Divisions.KFZW;
    } else if (division === Divisions.GBW) {
        return Divisions.GBW;
    } else {
        return Divisions.GLAS;
    }
};

export const useDashboardSettings = ({
    division,
    setSettingsLoaded,
}: useDashboardSettingsProps): useDashboardSettingsReturn => {
    const [settings, setSettings] = useState(null);
    const [initialSettings, setInitialSettings] = useState(null);

    const {
        editMode,
        isCanceled,
        setCanceled,
        setNeedToSaveSettings,
        needToSaveSettings,
        setLoadingUpdateDashboardSettings,
    } = useDashboardContext();

    const httpClient = useHttpClient();

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = settings[dragIndex];
            const newPanels = update(settings, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            });
            setSettings(newPanels);
        },
        [settings]
    );

    const makeRequestForDashboardSettings = async (): Promise<DashboardSettingsDTO> => {
        const divisionForRequest = getDivisionForRequest(division);
        const data = await httpClient.get<DashboardSettingsDTO>(
            `users/dashboards/${divisionForRequest}/dashboard-settings`
        );
        return data;
    };

    const makeRequestForUpdatingDashboardSettings = async (): Promise<void> => {
        const divisionForRequest = getDivisionForRequest(division);
        return httpClient.put(
            `users/dashboards/${divisionForRequest}/dashboard-settings`,
            {
                widgets: settings.map((widget) => {
                    return {
                        name: widget.dashboardWidget,
                        isShown: widget.isShown,
                    };
                }),
            }
        );
    };

    useEffect(() => {
        if (settings && setSettingsLoaded) {
            setSettingsLoaded(true);
        }
    }, [settings, setSettingsLoaded]);

    useEffect(() => {
        if (needToSaveSettings) {
            setLoadingUpdateDashboardSettings(true);
            makeRequestForUpdatingDashboardSettings()
                .then(() => {
                    const newSettings = settings.map((widget, index) => ({
                        dashboardWidget: widget.dashboardWidget,
                        position: index,
                        isShown: widget.isShown,
                    }));
                    setSettings(
                        newSettings.sort((a, b) => (a.position > b.position ? 1 : -1))
                    );
                    setInitialSettings(newSettings);
                    setNeedToSaveSettings(false);
                })
                .finally(() => {
                    setLoadingUpdateDashboardSettings(false);
                });
        }
    }, [needToSaveSettings, settings]);

    useEffect(() => {
        if (isCanceled) {
            setSettings(initialSettings);
            setCanceled(false);
        }
    }, [isCanceled]);

    useEffect(() => {
        if (division) {
            setSettings(null);
            makeRequestForDashboardSettings().then((data: DashboardSettingsDTO) => {
                const newSettings = data.widgets.map((widget) => ({
                    dashboardWidget: widget.name,
                    position: widget.position,
                    isShown: widget.isShown,
                }));
                setSettings(
                    newSettings.sort((a, b) => (a.position > b.position ? 1 : -1))
                );
                setInitialSettings(newSettings);
            });
        }
    }, [division]);

    const handleChangeShown = (widgetName: string, value: boolean): void => {
        const newSettings = [...settings];
        const indexOfElement = newSettings?.findIndex(
            (item) => item.dashboardWidget === widgetName
        );
        if (indexOfElement !== -1) {
            newSettings[indexOfElement].isShown = value;
            setSettings(newSettings);
        }
    };

    return {
        editMode,
        moveCard,
        settings,
        handleChangeShown,
    };
};
