import moment from 'moment';
import uniqid from 'uniqid';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormAONKompositTechnicalTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-technical-types';

export const damageReportFormTechnicalGetReportRecordAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState?: string
): DamageReportFormAONKompositTechnicalTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        placeOfDamage: data?.technicalInsuranceClaim?.damageLocation,
        itemsAffectedByTheDamage:
            Array.isArray(data?.technicalInsuranceClaim?.damagedItems) &&
            data?.technicalInsuranceClaim?.damagedItems?.length > 0
                ? data?.technicalInsuranceClaim?.damagedItems?.map((item) => ({
                      id: uniqid(),
                      numberAndTypeOfThings: item.numberAndTypeOfThings,
                      acquisitionOrReplacementPrice: item.machineDirectoryPosition,
                      repairCosts: item.damageExtent
                          ? String(item.damageExtent).replace('.', ',')
                          : '',
                  }))
                : [
                      {
                          id: uniqid(),
                          numberAndTypeOfThings: '',
                          acquisitionOrReplacementPrice: '',
                          repairCosts: '',
                      },
                  ],
        damageAndCause: data?.technicalInsuranceClaim?.damageAndCause,
        estimatedLossAmountInEUR: data?.technicalInsuranceClaim?.estimatedLossAmount
            ? String(data?.technicalInsuranceClaim?.estimatedLossAmount).replace('.', ',')
            : '',
        shouldDamagedItemsBeOperatedProvisionally:
            data?.technicalInsuranceClaim?.isDamagedItemsOperatedProvisionally,
        namely: data?.technicalInsuranceClaim?.namely,
        isARepairPossibleAndPlanned:
            data?.technicalInsuranceClaim?.isRepairPossibleAndPlanned,
        through: data?.technicalInsuranceClaim?.through,
        whoCanAnswerQuestionsAboutTheTechnicalDetails:
            data?.technicalInsuranceClaim?.whoAnswerAboutTechnicalDetails,
        isWitnesses: data?.witnessesInformationTechnical?.isAnyWitnesses,
        witnessesSurname: data?.witnessesInformationTechnical?.name,
        witnessesRoad: data?.witnessesInformationTechnical?.street,
        witnessesPostcode: data?.witnessesInformationTechnical?.postCode,
        witnessesPlace: data?.witnessesInformationTechnical?.ort,
        witnessesPhoneNumber: data?.witnessesInformationTechnical?.phoneNumber,
        witnessesEmail: data?.witnessesInformationTechnical?.email,
        damageInformationAreDamagedItemsOtherwiseInsured:
            data?.technicalInsuranceClaim?.isOtherwiseInsured,
        indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance:
            data?.technicalInsuranceClaim?.insuranceCompanyIndication,
        warrantyPeriodForTheDamagedObjectExpired:
            data?.technicalInsuranceClaim?.hasWarrantyPeriod,
        namelyBy: data?.technicalInsuranceClaim?.namelyBy,
        lossOfInsuredItemsDueToTheftBurglaryOrArson:
            data?.technicalInsuranceClaim?.insuredItemsLoss,
        isRecordedByThePolice: data?.policeRecordTechnical?.incidentRecordedByPolice,
        policeStation: data?.policeRecordTechnical?.policeStation,
        fileNumber: data?.policeRecordTechnical?.policeFileNumber,
        interruptionInOperationOrDelayIncommissioning:
            data?.businessInformation?.isInterruptionInOperation,
        interruptionInOperationNamely:
            data?.businessInformation?.interruptionInOperationNamely,
        interruptionInOperationStandsSince:
            data?.businessInformation?.interruptionInOperationStandsSince,
        expectedDamageInEurosPerDayOrWeekOrMonth: data?.businessInformation
            ?.expectedDamage
            ? String(data?.businessInformation?.expectedDamage).replace('.', ',')
            : '',
        mitigationMeasuresHaveAlreadyBeenTaken:
            data?.businessInformation?.mitigationMeasures,
        additionalCostsAreIncurredForBridgingMeasures: data?.businessInformation
            ?.additionalCost
            ? String(data?.businessInformation?.additionalCost).replace('.', ',')
            : '',
        whoCreatedTheServicesAffectedByTheDamage:
            data?.whoCreatedServicesAffectedByDamage,
        projectPhase: data?.projectPhaseInformation?.projectPhase,
        thingAffectedByTheDamage: data?.projectPhaseInformation?.thingAffectedByDamage,
        thingAffectedByTheDamageSince:
            data?.projectPhaseInformation?.thingAffectedByTheDamageSince,
        thingAffectedAcceptedSince:
            data?.projectPhaseInformation?.thingAffectedAcceptedSince,
        thingAffectedAcceptedBy: data?.projectPhaseInformation?.thingAffectedAcceptedBy,
        insuredConstructionOrAssemblyProject:
            data?.projectPhaseInformation?.insuredConstruction,
        insuredConstructionOrAssemblyProjectSince:
            data?.projectPhaseInformation?.insuredConstructionOrAssemblyProjectSince,
        insuredConstructionOrAssemblyProjectAcceptedSince:
            data?.projectPhaseInformation
                ?.insuredConstructionOrAssemblyProjectAcceptedSince,
        insuredConstructionOrAssemblyProjectAcceptedBy:
            data?.projectPhaseInformation?.insuredConstructionOrAssemblyProjectAcceptedBy,
        caseOfTheftForConstructionInsurance:
            data?.projectPhaseInformation?.isStolenPartsPermanentlyInstalled,
        files: [],
        fileInfos: data.fileInfos,
    };
};
