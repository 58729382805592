import { useTheme } from '@material-ui/core';
import { Theme as NivoTheme } from '@nivo/core';
import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import i18n from 'i18next';
import { useDashboardPieTachoStyle } from './dashboard-pie-tacho-style';
import { dashboardGetColorForValueLimit } from '../dashboard-get-color-for-value-limit';

interface Props {
    nivoTheme: NivoTheme;
    value?: number;
    unit?: string;
}

const maxValue = 100;

export const DashboardPieTacho = ({ nivoTheme, value, unit }: Props): JSX.Element => {
    const classes = useDashboardPieTachoStyle();
    const theme = useTheme();

    const valueColor = dashboardGetColorForValueLimit(value, theme);

    const data = [
        {
            id: 'value',
            value: value > maxValue ? maxValue : value,
            color: valueColor,
        },
    ];

    if (value < maxValue) {
        data.push({
            id: 'max',
            value: maxValue - value,
            color: theme.palette.grey[50],
        });
    }

    if (value == null) {
        return null;
    }

    return (
        <div className={classes.container}>
            <div className={classes.value}>
                <span>{Intl.NumberFormat(i18n.language).format(value || 0)}</span>
                {unit && <span>{unit}</span>}
            </div>
            {value !== null && (
                <ResponsivePie
                    data={data}
                    isInteractive={false}
                    theme={nivoTheme}
                    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                    innerRadius={0.75}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    colors={(e) => e.data.color}
                />
            )}
        </div>
    );
};
