import React, { useMemo, BaseSyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileObject } from 'material-ui-dropzone';
import { useFieldArray, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import {
    useStyle,
    useInputStyles,
    useFormControlLabelStyles,
    useFormControlStyles,
} from './contracts-edit-form-styles/contracts-edit-form-styles';

import { useAuth } from 'src/user-management/context-auth';

import {
    ThemeModalWindow,
    ThemeDropZone,
    ThemeSelectInput,
    ThemePhoneNumberInput,
    ThemeRadioButtonsGroup,
    ThemeCircularProgress,
} from 'src/theming';
import {
    Typography,
    Grid,
    FormControlLabel,
    OutlinedInput,
    FormControl,
    Button,
} from '@material-ui/core';

import {
    generateVehicleTypeItems,
    generateCoverItems,
    generateContactByPhoneOptions,
} from './contract-edit-form-configs';

import { Divisions } from 'src/shared/constants';

import { contractEditFormValidationSchema } from './contract-edit-form-validation-schema';
import { contractEditFormUpdateAdapter } from './contract-edit-form-update-adapter';
import { ContractEditFormDTO } from './contract-edit-form-dto';

interface ContractsEditFormProps {
    isOpen: boolean;
    handleClose: () => void;
    documentFormats: string[];
    contractData: {
        customerConnection: string;
        customer: string;
        contractNumber: string;
        division: string;
        licenseNumber: string;
        product: string;
        insurer: string;
        address?: string;
    };
}

export type ContractsEditFormData = {
    name: string;
    client: string;
    email: string;
    contractNumber: string;
    mark: string;
    insurer: string;
    cover: string;
    otherRemarks: string;
    changeNotice: string;
    customerConnection: string;
    address: string;
    phoneNumber: string;
    branch: string;
    product: string;
    vehicleType: string;
    contactByPhone: string;
    files: FileObject[];
};

export const ContractsEditForm = (props: ContractsEditFormProps): JSX.Element => {
    const { isOpen, handleClose, contractData, documentFormats } = props;

    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isUpdatingLoading, setUpdatingLoading] = useState(false);

    const { userData } = useAuth();

    const {
        userInfo: { email: userEmail, name: userName, surname: userSurname },
    } = userData;

    const { t } = useTranslation([
        'common',
        'contract-details-view',
        'vehicle-types',
        'cover',
        'contract-types',
        'errors',
    ]);

    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const { watch, setValue, control, formState, reset, trigger } =
        useForm<ContractsEditFormData>({
            mode: 'onChange',
            defaultValues: {
                files: [],
                vehicleType: '',
                cover: '',
                contactByPhone: 'no',
                otherRemarks: '',
                changeNotice: '',
                branch: contractData?.division,
                name: `${userName} ${userSurname}`,
                email: userEmail,
            },
            resolver: yupResolver(contractEditFormValidationSchema),
        });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    useEffect(() => {
        if (contractData) {
            reset({
                branch: contractData.division,
                name: `${userName} ${userSurname}`,
                email: userEmail,
                client: contractData.customer,
                customerConnection: contractData.customerConnection,
                contractNumber: contractData.contractNumber,
                mark: contractData.licenseNumber,
                product: contractData.product,
                insurer: contractData.insurer,
                contactByPhone: 'no',
                address: contractData.address,
                cover: '',
                phoneNumber: '',
            });
            trigger();
        }
    }, [contractData]);

    const onClose = (): void => {
        reset();
        handleClose();
    };

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
        trigger();
    };

    const handleChangeContactByPhone = (e: BaseSyntheticEvent): void => {
        setValue('contactByPhone', e.target.value);
        trigger();
    };

    const onAddAttachment = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
    };

    const onRemoveAttachment = (index: number): void => {
        remove(index);
    };

    const [
        name,
        client,
        email,
        contractNumber,
        mark,
        insurer,
        cover,
        otherRemarks,
        changeNotice,
        customerConnection,
        address,
        phoneNumber,
        branch,
        product,
        vehicleType,
        contactByPhone,
        files,
    ] = watch([
        'name',
        'client',
        'email',
        'contractNumber',
        'mark',
        'insurer',
        'cover',
        'otherRemarks',
        'changeNotice',
        'customerConnection',
        'address',
        'phoneNumber',
        'branch',
        'product',
        'vehicleType',
        'contactByPhone',
        'files',
    ]);

    const makeRequestToUpdateData = async (): Promise<ContractEditFormDTO> => {
        setUpdatingLoading(true);
        const formData = contractEditFormUpdateAdapter({
            name,
            client,
            email,
            contractNumber,
            mark,
            insurer,
            cover,
            otherRemarks,
            changeNotice,
            customerConnection,
            address,
            phoneNumber,
            branch,
            product,
            vehicleType,
            contactByPhone,
            files,
        });
        return httpClient.post<ContractEditFormDTO>(
            `contracts/${id}/request-for-update`,
            formData
        );
    };

    const handleSave = async (): Promise<void> => {
        try {
            await makeRequestToUpdateData();
            handleClose();
            reset({
                branch: contractData.division,
                name: userName,
                email: userEmail,
                client: contractData.customer,
                customerConnection: contractData.customerConnection,
                contractNumber: contractData.contractNumber,
                mark: contractData.licenseNumber,
                product: contractData.product,
                insurer: contractData.insurer,
                contactByPhone: 'no',
            });
            enqueueSnackbar(
                t(
                    'contract-details-view:contractDetails.changingContractData.updatedSuccessfully'
                ),
                { variant: 'success' }
            );
        } catch (err) {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setUpdatingLoading(false);
        }
    };

    const vehicleTypes = useMemo(() => generateVehicleTypeItems(t), [t]);
    const coverItems = useMemo(() => generateCoverItems(t), [t]);
    const contactByPhoneOptions = useMemo(() => generateContactByPhoneOptions(t), [t]);
    const divisionItems = useMemo(
        () =>
            userData?.divisions?.map((item) => ({
                title: item.label,
                value: item.value,
            })),
        [userData]
    );

    return (
        <ThemeModalWindow
            isOpen={isOpen}
            onClose={onClose}
            modalWindowClass={classes.modalWindowClass}
        >
            <Typography variant='h5' className={classes.modalTitle}>
                {t('contract-details-view:contractDetails.changingContractData.title')}
            </Typography>
            <Grid container spacing={4} className={classes.modalContentContainer}>
                <Grid
                    container
                    item
                    className={classes.fieldsColumn}
                    style={{ paddingLeft: 0 }}
                >
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={name}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.name'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={client}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.client'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={email}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.email'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={contractNumber}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.contractNumber'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    {branch === Divisions.KFZW && (
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled
                                        classes={inputClasses}
                                        value={mark}
                                    />
                                }
                                label={t(
                                    'contract-details-view:contractDetails.changingContractData.mark'
                                )}
                                labelPlacement='top'
                            />
                        </FormControl>
                    )}
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={insurer}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.insurer'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    {branch === Divisions.KFZW && (
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.cover)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        editMode
                                        value={cover}
                                        name='cover'
                                        onChange={handleChangeValue}
                                        items={coverItems}
                                    />
                                }
                                label={t(
                                    'contract-details-view:contractDetails.changingContractData.cover'
                                )}
                                labelPlacement='top'
                            />
                        </FormControl>
                    )}
                </Grid>
                <Grid container item className={classes.fieldsColumn}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={customerConnection}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.customerConnection'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={address}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.address'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemePhoneNumberInput
                                    error={Boolean(formState.errors?.phoneNumber)}
                                    name='phoneNumber'
                                    onChange={handleChangeValue}
                                    value={phoneNumber}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.phoneNumber',
                                {
                                    sign: contactByPhone === 'yes' ? '*' : '',
                                }
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        disabled
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSelectInput
                                    editMode={false}
                                    items={divisionItems}
                                    onChange={handleChangeValue}
                                    value={branch}
                                    name='branch'
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.branch'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={product}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.product'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                    {branch === Divisions.KFZW && (
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSelectInput
                                        editMode
                                        items={vehicleTypes}
                                        value={vehicleType}
                                        name='vehicleType'
                                        onChange={handleChangeValue}
                                    />
                                }
                                label={t(
                                    'contract-details-view:contractDetails.changingContractData.vehicleType'
                                )}
                                labelPlacement='top'
                            />
                        </FormControl>
                    )}
                </Grid>
                <Grid item container className={classes.fullWidthFieldsContainer}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    multiline
                                    rows={2}
                                    classes={inputClasses}
                                    className={classes.multiRowTextField}
                                    name={
                                        branch === Divisions.KFZW
                                            ? 'otherRemarks'
                                            : 'changeNotice'
                                    }
                                    value={
                                        branch === Divisions.KFZW
                                            ? otherRemarks
                                            : changeNotice
                                    }
                                    onChange={handleChangeValue}
                                />
                            }
                            label={
                                branch === Divisions.KFZW
                                    ? t(
                                          'contract-details-view:contractDetails.changingContractData.otherRemarks'
                                      )
                                    : t(
                                          'contract-details-view:contractDetails.changingContractData.changeNotice'
                                      )
                            }
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidthFieldsContainer}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeDropZone
                                    formats={documentFormats || []}
                                    fileObjects={fields}
                                    onAdd={onAddAttachment}
                                    onRemove={onRemoveAttachment}
                                />
                            }
                            label={t(
                                'contract-details-view:contractDetails.changingContractData.attachments'
                            )}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={classes.contactByPhoneContainer}>
                    <Typography variant='body1' className={classes.radioButtonsLabel}>
                        {t(
                            'contract-details-view:contractDetails.changingContractData.contactByPhone'
                        )}
                    </Typography>
                    <ThemeRadioButtonsGroup
                        row
                        value={contactByPhone}
                        onChange={handleChangeContactByPhone}
                        radioButtonsList={contactByPhoneOptions}
                        injectedIconClass={classes.radioIcon}
                        injectedCheckedIconClass={classes.radioCheckedIcon}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleSave}
                    disabled={!formState.isValid || isUpdatingLoading}
                    startIcon={<ThemeCircularProgress isLoading={isUpdatingLoading} />}
                >
                    {t('common:send')}
                </Button>
                <Button
                    disabled={isUpdatingLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
