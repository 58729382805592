import { SelectItem } from 'src/theming';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useMonthItems = (): SelectItem[] => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        return [
            { value: '1', title: t('month.january') },
            { value: '2', title: t('month.february') },
            { value: '3', title: t('month.march') },
            { value: '4', title: t('month.april') },
            { value: '5', title: t('month.may') },
            { value: '6', title: t('month.june') },
            { value: '7', title: t('month.july') },
            { value: '8', title: t('month.august') },
            { value: '9', title: t('month.september') },
            { value: '10', title: t('month.october') },
            { value: '11', title: t('month.november') },
            { value: '12', title: t('month.december') },
        ];
    }, [t]);
};
