import { makeStyles, Theme } from '@material-ui/core';

export const useMontageStyles = makeStyles((theme: Theme) => {
    return {
        clause: {
            marginLeft: '20px',
        },
        subtitle: {
            paddingTop: '0!important',
            '& .MuiListItemText-root': {
                marginTop: 0,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});
