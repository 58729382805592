import React, { BaseSyntheticEvent, ChangeEvent, memo } from 'react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import clsx from 'clsx';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';

import { ThemeCircularProgress, ThemeFilesPanel } from 'src/theming';
import { useValidateContractByDate, useQuery } from 'src/shared/hooks';

import { DamageReportFormGeneralBlock } from './damage-report-form-general-block';
import { DamageReportFormAccidentSceneBlock } from './damage-report-form-accident-scene-block';
import { DamageReportFormDriverInformationBlock } from './damage-report-form-driver-information-block';
import { DamageReportFormTeamBlock } from './damage-report-form-team-block';
import { DamageReportFormRecordedByThePolicy } from './damage-report-form-recorded-by-the-policy';
import { DamageReportFormWitnessesInformation } from './damage-report-form-witnesses-information';
import { DamageReportFormOtherPartyInformation } from './damage-report-form-other-party-information';
import { DamageReportFormAccidentDamageDetailsInformation } from './damage-report-form-accident-damage-details-information';

import { DamageReportFormAttachmentsField } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-attachments-field';

import { DamageReportFormFormData } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-form-data-type';

interface DamageReportFormKFZWProps {
    formBlockContainer: string;
    division: string;
    dayOfDamage: ParsableDate;
    timeOfDamage: ParsableDate;
    rootCause: string;
    kfzLicenseNumber: string;
    contractNumber: string;
    policyHolder: string;
    insuranceCompany: string;
    divisionFromRecord: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeDate: (name, value: ParsableDate) => void;
    handleChangeDamageType: (e: ChangeEvent<HTMLInputElement>) => void;
    handleReadOnlyFields: (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }) => void;
    damageTypeFields: { name: string }[];
    accidentScenePostcode: string;
    accidentSceneCity: string;
    accidentSceneCountry: string;
    accidentSceneStreet: string;
    driverInformationEmail: string;
    driverInformationPhoneNumber: string;
    driverInformationCity: string;
    driverInformationPostCode: string;
    driverInformationStreet: string;
    driverInformationTenant: string;
    driverInformationSurname: string;
    driverInformationFirstName: string;
    driverInformationDrivingLicenseAvailable: boolean;
    driverInformationDrivingLicenseClass: string;
    driverInformationDateOfIssue: ParsableDate;
    accidentDamageDetailsInformationDetailedDamageDescription: string;
    accidentDamageDetailsInformationDamageNumberCustomer: string;
    accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle: string;
    accidentDamageDetailsInformationChangeOfVehicle: string;
    handleChangeHaveLicenseNumber: () => void;
    handleChangeTeam: () => void;
    teamLicenseNumber: string;
    teamVehicleType: string;
    teamTowedVehicleKeeper: string;
    teamDamageStreet: string;
    teamDamagePostcode: string;
    teamDamageCity: string;
    teamDamagePhoneNumber: string;
    teamDamageEmail: string;
    teamDamageInsurancePolicyNumber: string;
    isTeam: boolean;
    handleChangeRecordedByThePolice: () => void;
    handleChangeWarnedByThePolice: () => void;
    handleChangeAlcoholDrugSwitcher: () => void;
    policeRecordDiagnosedAlcoholOrDrugUse: boolean;
    incidentRecordedByPolice: boolean;
    policeRecordPoliceFileNumber: string;
    policeRecordReceivingPoliceStation: string;
    policeRecordSomeoneWarnedByPolice: boolean;
    policeRecordWhoWarnedByPolice: string;
    handleChangeWitnesses: () => void;
    witnessesStreet: string;
    witnessesPostcode: string;
    witnessesPhoneNumber: string;
    areThereWitnesses: boolean;
    witnessesEmail: string;
    witnessesCity: string;
    witnessesNameAndSurname: string;
    isOtherInfoShown: boolean;
    otherPartyInformationDamageAmount: string;
    otherPartyInformationLicenseNumber: string;
    otherPartyInformationVehicleType: string;
    otherPartyInformationCityEmail: string;
    otherPartyInformationPostCode: string;
    otherPartyInformationCityPhoneNumber: string;
    otherPartyInformationStreet: string;
    otherPartyInformationNameAndSurname: string;
    otherPartyInformationCity: string;
    files: FileObject[];
    handleAddFile: (newFiles: FileObject[]) => void;
    handleDeleteFile: (index: number) => void;
    onDeleteFile: (id: string) => void;
    buttonsContainer: string;
    formState: FormState<DamageReportFormFormData>;
    isSaveLoading: boolean;
    setDeleteConfirmOpen: (value: boolean) => void;
    deleteText: string;
    isSendingToAmsLoading: boolean;
    saveButton: string;
    deleteButton: string;
    button: string;
    saveText: string;
    cancelText: string;
    sendText: string;
    handleCancel: React.MouseEventHandler<HTMLButtonElement>;
    id: string;
    sendButton: string;
    handleSaveReport: () => void;
    sectionContainer: string;
    footerTitle: string;
    hideButtons: boolean;
    fileInfos: { id: string; fileName: string }[];
}

export const DamageReportFormKFZWView = memo(
    ({
        hideButtons,
        footerTitle,
        formBlockContainer,
        timeOfDamage,
        dayOfDamage,
        insuranceCompany,
        divisionFromRecord,
        contractNumber,
        policyHolder,
        kfzLicenseNumber,
        handleChangeDate,
        handleChangeDamageType,
        handleChangeValue,
        damageTypeFields,
        handleReadOnlyFields,
        onDeleteFile,
        division,
        rootCause,
        accidentSceneCountry,
        accidentSceneCity,
        accidentScenePostcode,
        accidentSceneStreet,
        driverInformationCity,
        driverInformationEmail,
        driverInformationFirstName,
        driverInformationPhoneNumber,
        driverInformationPostCode,
        driverInformationStreet,
        driverInformationSurname,
        driverInformationTenant,
        driverInformationDrivingLicenseAvailable,
        driverInformationDrivingLicenseClass,
        driverInformationDateOfIssue,
        accidentDamageDetailsInformationDamageNumberCustomer,
        accidentDamageDetailsInformationDetailedDamageDescription,
        accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle,
        accidentDamageDetailsInformationChangeOfVehicle,
        handleChangeHaveLicenseNumber,
        handleChangeTeam,
        teamDamageCity,
        teamDamagePostcode,
        teamDamageStreet,
        teamDamagePhoneNumber,
        teamLicenseNumber,
        teamVehicleType,
        teamTowedVehicleKeeper,
        isTeam,
        teamDamageEmail,
        teamDamageInsurancePolicyNumber,
        handleChangeAlcoholDrugSwitcher,
        handleChangeRecordedByThePolice,
        handleChangeWarnedByThePolice,
        incidentRecordedByPolice,
        policeRecordDiagnosedAlcoholOrDrugUse,
        policeRecordPoliceFileNumber,
        policeRecordReceivingPoliceStation,
        policeRecordSomeoneWarnedByPolice,
        policeRecordWhoWarnedByPolice,
        handleChangeWitnesses,
        witnessesPhoneNumber,
        witnessesPostcode,
        witnessesStreet,
        areThereWitnesses,
        witnessesCity,
        witnessesEmail,
        witnessesNameAndSurname,
        isOtherInfoShown,
        otherPartyInformationCity,
        otherPartyInformationCityEmail,
        otherPartyInformationCityPhoneNumber,
        otherPartyInformationDamageAmount,
        otherPartyInformationLicenseNumber,
        otherPartyInformationNameAndSurname,
        otherPartyInformationPostCode,
        otherPartyInformationStreet,
        otherPartyInformationVehicleType,
        files,
        handleAddFile,
        handleDeleteFile,
        buttonsContainer,
        formState,
        isSaveLoading,
        isSendingToAmsLoading,
        button,
        saveButton,
        deleteButton,
        handleCancel,
        cancelText,
        saveText,
        sendText,
        sendButton,
        sectionContainer,
        handleSaveReport,
        fileInfos,
        deleteText,
        setDeleteConfirmOpen,
    }: DamageReportFormKFZWProps) => {
        const { t } = useTranslation(['common']);
        const { id } = useParams<{ id: string }>();

        const contractId = useQuery().get('contractId');
        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        return (
            <>
                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormGeneralBlock
                            divisionFromRecord={divisionFromRecord}
                            readonly={hideButtons}
                            dayOfDamage={dayOfDamage}
                            timeOfDamage={timeOfDamage}
                            handleChangeDamageType={handleChangeDamageType}
                            handleChangeValue={handleChangeValue}
                            handleChangeDate={handleChangeDate}
                            handleReadOnlyFields={handleReadOnlyFields}
                            damageTypeFields={damageTypeFields}
                            licenseNumber={kfzLicenseNumber}
                            contractNumber={contractNumber}
                            policyHolder={policyHolder}
                            insuranceCompany={insuranceCompany}
                            division={division}
                            errors={{
                                dayOfDamage: Boolean(formState?.errors?.dayOfDamage),
                                timeOfDamage: Boolean(formState?.errors?.timeOfDamage),
                                kfzLicenseNumber: Boolean(
                                    formState?.errors?.kfzLicenseNumber
                                ),
                                contractNumber: Boolean(
                                    formState?.errors?.contractNumber
                                ),
                                policyHolder: Boolean(formState?.errors?.policyHolder),
                                insuranceCompany: Boolean(
                                    formState?.errors?.insuranceCompany
                                ),
                                typeOfDamage: Boolean(formState?.errors?.typeOfDamage),
                            }}
                        />
                    </div>
                </div>
                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormDriverInformationBlock
                            readonly={hideButtons}
                            errors={{
                                driverInformationEmail: Boolean(
                                    formState?.errors?.driverInformationEmail
                                ),
                            }}
                            firstName={driverInformationFirstName}
                            surname={driverInformationSurname}
                            tenant={driverInformationTenant}
                            street={driverInformationStreet}
                            postCode={driverInformationPostCode}
                            city={driverInformationCity}
                            phoneNumber={driverInformationPhoneNumber}
                            email={driverInformationEmail}
                            drivingLicenseAvailable={
                                driverInformationDrivingLicenseAvailable
                            }
                            drivingLicenseClass={driverInformationDrivingLicenseClass}
                            dateOfIssue={driverInformationDateOfIssue}
                            handleChangeValue={handleChangeValue}
                            handleChangeDate={handleChangeDate}
                            handleChangeHaveLicenseNumber={handleChangeHaveLicenseNumber}
                        />
                    </div>
                </div>
                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormAccidentDamageDetailsInformation
                            rootCause={rootCause}
                            changeOfVehicle={
                                accidentDamageDetailsInformationChangeOfVehicle
                            }
                            damageNumberCustomer={
                                accidentDamageDetailsInformationDamageNumberCustomer
                            }
                            detailedDamageDescription={
                                accidentDamageDetailsInformationDetailedDamageDescription
                            }
                            estimatedAmountOfDamageToYourOwnVehicle={
                                accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle
                            }
                            errors={{
                                accidentDamageDetailsInformationChangeOfVehicle: Boolean(
                                    formState.errors
                                        .accidentDamageDetailsInformationChangeOfVehicle
                                ),
                            }}
                            readonly={hideButtons}
                            handleChangeValue={handleChangeValue}
                        />
                    </div>
                </div>
                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormAccidentSceneBlock
                            readonly={hideButtons}
                            road={accidentSceneStreet}
                            country={accidentSceneCountry}
                            handleChangeValue={handleChangeValue}
                            place={accidentSceneCity}
                            plz={accidentScenePostcode}
                        />
                    </div>
                </div>

                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormTeamBlock
                            readonly={hideButtons}
                            errors={{
                                teamDamageEmail: Boolean(
                                    formState?.errors?.teamDamageEmail
                                ),
                            }}
                            handleChangeValue={handleChangeValue}
                            handleChangeTeam={handleChangeTeam}
                            licenseNumber={teamLicenseNumber}
                            vehicleType={teamVehicleType}
                            towedVehicleKeeper={teamTowedVehicleKeeper}
                            road={teamDamageStreet}
                            postCode={teamDamagePostcode}
                            place={teamDamageCity}
                            phoneNumber={teamDamagePhoneNumber}
                            emailAddress={teamDamageEmail}
                            insurancePolicyNumber={teamDamageInsurancePolicyNumber}
                            isCombination={isTeam}
                        />
                    </div>
                </div>

                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormRecordedByThePolicy
                            readonly={hideButtons}
                            handleChangeRecordedByThePolice={
                                handleChangeRecordedByThePolice
                            }
                            handleChangeValue={handleChangeValue}
                            handleChangeAlcoholDrugSwitcher={
                                handleChangeAlcoholDrugSwitcher
                            }
                            handleChangeWarnedByThePolice={handleChangeWarnedByThePolice}
                            recordedByThePolice={incidentRecordedByPolice}
                            alcoholDrugUse={policeRecordDiagnosedAlcoholOrDrugUse}
                            receivingPoliceStation={policeRecordReceivingPoliceStation}
                            policeFileNumber={policeRecordPoliceFileNumber}
                            isWarnedByThePolice={policeRecordSomeoneWarnedByPolice}
                            whoWasWarned={policeRecordWhoWarnedByPolice}
                        />
                    </div>
                </div>
                <div className={sectionContainer}>
                    <div className={formBlockContainer}>
                        <DamageReportFormWitnessesInformation
                            readonly={hideButtons}
                            errors={{
                                witnessesEmail: Boolean(
                                    formState?.errors?.witnessesEmail
                                ),
                            }}
                            handleChangeValue={handleChangeValue}
                            handleChangeWitnesses={handleChangeWitnesses}
                            phoneNumber={witnessesPhoneNumber}
                            postCode={witnessesPostcode}
                            road={witnessesStreet}
                            areWitnesses={areThereWitnesses}
                            email={witnessesEmail}
                            surname={witnessesNameAndSurname}
                            place={witnessesCity}
                        />
                    </div>
                </div>

                {isOtherInfoShown && (
                    <div className={sectionContainer}>
                        <div className={formBlockContainer}>
                            <DamageReportFormOtherPartyInformation
                                readonly={hideButtons}
                                errors={{
                                    otherPartyInformationCityEmail: Boolean(
                                        formState?.errors?.otherPartyInformationCityEmail
                                    ),
                                }}
                                handleChangeValue={handleChangeValue}
                                place={otherPartyInformationCity}
                                surname={otherPartyInformationNameAndSurname}
                                road={otherPartyInformationStreet}
                                phoneNumber={otherPartyInformationCityPhoneNumber}
                                postCode={otherPartyInformationPostCode}
                                emailAddress={otherPartyInformationCityEmail}
                                vehicleType={otherPartyInformationVehicleType}
                                licenseNumber={otherPartyInformationLicenseNumber}
                                damageAmount={otherPartyInformationDamageAmount}
                            />
                        </div>
                    </div>
                )}

                <div className={sectionContainer}>
                    {Array.isArray(fileInfos) && fileInfos.length > 0 && (
                        <Box pb={4}>
                            <ThemeFilesPanel
                                routeId={id}
                                route='damage-reports'
                                onDeleteFile={onDeleteFile}
                                title={t('documents')}
                                readonly={hideButtons}
                                files={fileInfos}
                            />
                        </Box>
                    )}
                    {!hideButtons && (
                        <>
                            <div className={formBlockContainer}>
                                <Typography>{footerTitle}</Typography>
                                <Box mt={4}>
                                    <DamageReportFormAttachmentsField
                                        files={files}
                                        handleAddFile={handleAddFile}
                                        handleDeleteFile={handleDeleteFile}
                                    />
                                </Box>
                            </div>
                            <Grid
                                container
                                className={clsx(buttonsContainer, formBlockContainer)}
                            >
                                <Box mr={4}>
                                    <Button
                                        disabled={
                                            isSaveLoading ||
                                            isSendingToAmsLoading ||
                                            !division ||
                                            !dayOfDamage ||
                                            !contractNumber ||
                                            !policyHolder ||
                                            !insuranceCompany ||
                                            (contractId && !isContractValidByDate)
                                        }
                                        className={clsx(button, saveButton)}
                                        startIcon={
                                            <ThemeCircularProgress
                                                isLoading={isSaveLoading}
                                            />
                                        }
                                        onClick={handleSaveReport}
                                    >
                                        {saveText}
                                    </Button>
                                </Box>
                                {id && (
                                    <Box mr={4}>
                                        <Button
                                            className={clsx(button, deleteButton)}
                                            disabled={
                                                isSaveLoading || isSendingToAmsLoading
                                            }
                                            variant='outlined'
                                            onClick={() => setDeleteConfirmOpen(true)}
                                        >
                                            {deleteText}
                                        </Button>
                                    </Box>
                                )}
                                <Button
                                    disabled={isSendingToAmsLoading || isSaveLoading}
                                    variant='outlined'
                                    onClick={handleCancel}
                                >
                                    {cancelText}
                                </Button>
                                <div style={{ flexGrow: 1 }} />
                                <Button
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isSendingToAmsLoading}
                                        />
                                    }
                                    disabled={
                                        !formState.isValid ||
                                        isSendingToAmsLoading ||
                                        isSaveLoading ||
                                        (contractId && !isContractValidByDate)
                                    }
                                    className={clsx(button, sendButton)}
                                    type='submit'
                                >
                                    {sendText}
                                </Button>
                            </Grid>
                        </>
                    )}
                </div>
            </>
        );
    }
);
