import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core';

const useThemeLongTextStyles = makeStyles((theme: Theme) => {
    return {
        button: {
            color: theme.palette.info.main,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    };
});

interface Props {
    limit: number;
    text: string;
    className?: string;
}

export const ThemeLongText = ({ limit, text, className }: Props): JSX.Element => {
    const { t } = useTranslation(['common']);
    const classes = useThemeLongTextStyles();
    const [isShowAll, setShowAll] = useState<boolean>(false);

    const showMore = (): void => setShowAll(true);

    const showLess = (): void => setShowAll(false);

    if (text.length <= limit) {
        return <p className={className}>{text}</p>;
    }

    if (isShowAll) {
        return (
            <p className={className}>
                {text}{' '}
                <span
                    className={classes.button}
                    tabIndex={-1}
                    role='button'
                    onClick={showLess}
                >
                    {t('readLess')}
                </span>
            </p>
        );
    }

    const toShow = text.substring(0, limit) + '...';

    return (
        <p className={className}>
            {toShow}{' '}
            <span
                className={classes.button}
                tabIndex={-1}
                role='button'
                onClick={showMore}
            >
                {t('readMore')}
            </span>
        </p>
    );
};
