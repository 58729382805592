import { Divisions } from 'src/shared/constants';

import { DamageReportFormTRANSPTypes } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-TRANSP-types';

export const damageReportFormTRANSPDefaultState = (
    contractId: string
): DamageReportFormTRANSPTypes => {
    return {
        contractId,
        id: '',
        division: Divisions.TRANSP,
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        ariaOfLiability: 'No choice',
        policeLicensePlate: '',
        vehicleBody: 'No choice',
        typeOfDamage: 'No choice',
        whoWasTheClient: '',
        driver: '',
        departurePlace: '',
        destination: '',
        whoLoaded: 'No choice',
        haveAgreementsAboutLoadingMet: false,
        whichAgreement: '',
        wasInQuantityTakeoverRequested: false,
        whichQuantityTakeoverRequested: '',
        doesDriverHaveNumberOfPiecesAtTakeoverChecked: false,
        doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged: false,
        whoDischarged: 'No choice',
        hasItBeenReloaded: false,
        whichGoodsAreMissingOrDamaged: '',
        estimatedDamageAmount: null,
        totalWeightOfCompleteCharge: null,
        totalWeightOfDamageConcernedCharge: null,
        wasGoodsPacked: false,
        kindOfPackage: '',
        whenIsDamageDeveloped: 'No choice',
        grantedRecipientReceipt: false,
        textofReservation: '',
        whereWasReservationMade: 'No choice',
        damageAmountReducedByFreight: false,
        detailedDamageDescription: '',
        whereIsDamagedItemLocated: '',
        averageAndCommissioner: '',
        isRecordedByThePolice: false,
        policeStation: '',
        fileNumber: '',
        attachedDocuments: [],
        files: [],
        fileInfos: [],
    };
};
