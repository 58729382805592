import React, { BaseSyntheticEvent } from 'react';
import { CheckboxItem } from 'src/theming';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ThemeMessageComponent, MessageTypes } from 'src/theming';

import { useCardStyle } from './user-editing-form-styles';

interface UserEditFormClientConnectionCardProps {
    clientConnections: CheckboxItem[];
    setClientConnections: (clientConnections: CheckboxItem[]) => void;
    editMode: boolean;
    isAllCustomerConnectionsAreAssigned: boolean;
}

export const UserEditFormClientConnectionCard = (
    props: UserEditFormClientConnectionCardProps
): JSX.Element => {
    const {
        clientConnections,
        setClientConnections,
        editMode,
        isAllCustomerConnectionsAreAssigned,
    } = props;

    const classes = useCardStyle();
    const { t } = useTranslation(['common', 'user-management']);

    const handleChange = (e: BaseSyntheticEvent): void => {
        const newClientConnections =
            clientConnections?.map((item) => {
                if (item.value === e.target.name) {
                    return { ...item, checked: e.target.checked };
                }
                return item;
            }) || [];
        setClientConnections(newClientConnections);
    };

    return (
        <Grid container className={classes.cardContainer}>
            <FormControl component='fieldset' className={classes.formControl}>
                <FormLabel>
                    {t('user-management:umSection.form.clientConnection')}
                </FormLabel>
                <FormGroup>
                    {isAllCustomerConnectionsAreAssigned && (
                        <ThemeMessageComponent
                            type={MessageTypes.INFO}
                            message={t(
                                'user-management:umSection.allCustomerConnectionsAreAssinedMessage'
                            )}
                        />
                    )}
                    {!isAllCustomerConnectionsAreAssigned &&
                        clientConnections
                            .sort((a, b) => a.title.localeCompare(b.title))
                            .map((clientConnection) => (
                                <FormControlLabel
                                    key={clientConnection.value}
                                    control={
                                        <Checkbox
                                            disabled={!editMode}
                                            checked={clientConnection.checked}
                                            name={clientConnection.value}
                                            onChange={handleChange}
                                            icon={
                                                <CheckBoxOutlineBlankIcon
                                                    className={classes.icon}
                                                />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon
                                                    className={clsx(
                                                        classes.icon,
                                                        classes.checkedIcon
                                                    )}
                                                />
                                            }
                                        />
                                    }
                                    label={clientConnection.title}
                                />
                            ))}
                </FormGroup>
            </FormControl>
        </Grid>
    );
};
