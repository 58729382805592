import React, { useState, useMemo, ChangeEvent } from 'react';
import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';
import { useTranslation } from 'react-i18next';

import { ThemeSelectInput } from 'src/theming';

enum PrivilegesTableSelectItemsEnum {
    EDIT = 'toChange',
    READ = 'read',
    NONE = 'none',
}

const generatePrivilegesTableSelectItems = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('user-management:privilegesSection.table.edit'),
            value: PrivilegesTableSelectItemsEnum.EDIT,
        },
        {
            title: t('user-management:privilegesSection.table.read'),
            value: PrivilegesTableSelectItemsEnum.READ,
        },
        {
            title: t('user-management:privilegesSection.table.none'),
            value: PrivilegesTableSelectItemsEnum.NONE,
        },
    ];
};

interface PrivilegesSelectCellProps {
    value: string;
}

export const PrivilegesSelectCell = (props: PrivilegesSelectCellProps): JSX.Element => {
    const { value } = props;

    const { t } = useTranslation(['user-management']);

    const [stateValue, setStateValue] = useState(value);

    const handleChangeSelect = (e: ChangeEvent<HTMLInputElement>): void => {
        setStateValue(e.target.value);
    };

    const items = useMemo(() => generatePrivilegesTableSelectItems(t), [t]);

    return (
        <ThemeSelectInput
            editMode
            items={items}
            value={stateValue}
            name=''
            onChange={handleChangeSelect}
        />
    );
};
