import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UseDeleteMessageReturn {
    isLoading: boolean;
    deleteMessage: (id: string) => Promise<void>;
}

export const useDeleteMessage = (onSuccess: () => void): UseDeleteMessageReturn => {
    const { t } = useTranslation(['errors', 'notification-center']);
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState<boolean>(false);

    const deleteMessage = async (id: string): Promise<void> => {
        try {
            setLoading(true);
            await httpClient.delete(`notifications/${id}`);
            enqueueSnackbar(t('notification-center:messageIsDeletedSuccessfully'), {
                variant: 'success',
            });
            onSuccess();
        } catch {
            enqueueSnackbar(t('errors:unkonwnError'), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, deleteMessage };
};
