import { DamageReportFormContainer } from '../../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormFullInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormFullwidthInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import {
    FormContainerProps,
    FormElement,
} from '../damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigAONKompositTechnicalReturnType {
    title?: string;
    titleData?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
}

interface DamageReportFormConfigAONKompositTechnicalProps {
    division: string;
    rootCause: string;
    damage: string;
    damageDescription: string;
    fullWidthClass?: string;
}

export const DamageReportFormConfigAonKompositUniversal = ({
    division,
    rootCause,
    damage,
    damageDescription,
    fullWidthClass,
}: DamageReportFormConfigAONKompositTechnicalProps): DamageReportFormConfigAONKompositTechnicalReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:damageNotification.title',
                titleData: `divisions:${division}`,
                Container: DamageReportFormContainer,
                id: 'damageNotification',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageNotification.rootCause',
                        name: 'rootCause',
                        value: rootCause,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:damageNotification.damage',
                        name: 'damage',
                        value: damage,
                        className: fullWidthClass,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:damageNotification.damageDescription',
                        name: 'damageDescription',
                        value: damageDescription,
                    },
                ],
            },
        ],
    ];
};
