import moment from 'moment';
import { DamageReportFormPostDto } from '../damage-report-form-types/damage-report-form-KFZW-mutate-dto-types';
import { DamageTypes } from '../damage-report-form-selection-items/damage-report-form-generate-damage-types-options';
import { DamageReportFormFormData } from '../damage-report-form-types/damage-report-form-form-data-type';
import { Divisions } from 'src/shared/constants';

export const damageReportFormKFZWGetReportRecordAdapter = (
    data: DamageReportFormPostDto
): DamageReportFormFormData => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: Divisions.KFZW,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,
        contractId: data.contractId,
        kfzLicenseNumber: data.kfzLicenseNumber,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        typeOfDamage: [
            ...(data.liability ? [{ name: DamageTypes.LIABILITY }] : []),
            ...(data.fullyCoverage ? [{ name: DamageTypes.FULLY_COMPREHENSIVE }] : []),
            ...(data.partialCoverage ? [{ name: DamageTypes.PARTIAL_COVERAGE }] : []),
        ],
        rootCause: data.accidentAndDamageInformation?.rootCause,
        accidentSceneStreet: data.accidentScene?.street,
        accidentScenePostcode: data.accidentScene?.postCode,
        accidentSceneCity: data.accidentScene?.city,
        accidentSceneCountry: data.accidentScene?.country,
        driverInformationFirstName: data.driverInformation?.firstname,
        driverInformationSurname: data.driverInformation?.surname,
        driverInformationTenant: data.driverInformation?.tenant,
        driverInformationStreet: data.driverInformation?.street,
        driverInformationPostCode: data.driverInformation?.postCode,
        driverInformationCity: data.driverInformation?.city,
        driverInformationPhoneNumber: data.driverInformation?.phoneNumber,
        driverInformationEmail: data.driverInformation?.email,
        driverInformationDrivingLicenseAvailable:
            data.driverInformation?.drivingLicenseAvailable,
        driverInformationDrivingLicenseClass: data.driverInformation?.drivingLicenseClass,
        driverInformationDateOfIssue: data.driverInformation?.dateOfIssue
            ? moment(
                  moment(data.driverInformation?.dateOfIssue).valueOf() + offset
              ).toISOString()
            : null,
        accidentDamageDetailsInformationChangeOfVehicle:
            data.accidentAndDamageInformation?.changeOfVehicle,
        accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle:
            data.accidentAndDamageInformation?.estimatedAmountOfDamageToYourOwnVehicle,
        accidentDamageDetailsInformationDamageNumberCustomer:
            data.accidentAndDamageInformation?.damageNumberCustomer,
        accidentDamageDetailsInformationDetailedDamageDescription:
            data.accidentAndDamageInformation?.detailedDamageDescription,
        isTeam: data.isTeam,
        teamDamageStreet: data.teamDamage?.street,
        teamDamagePostcode: data.teamDamage?.postCode,
        teamDamageCity: data.teamDamage?.city,
        teamDamagePhoneNumber: data.teamDamage?.phoneNumber,
        teamDamageEmail: data.teamDamage?.email,
        teamDamageInsurancePolicyNumber: data.teamDamage?.insurancePolicyNumber,
        teamVehicleType: data.teamDamage?.vehicleType,
        teamLicenseNumber: data.teamDamage?.licenseNumber,
        teamTowedVehicleKeeper: data.teamDamage?.towedVehicleKeeper,
        incidentRecordedByPolice: data.incidentRecordedByPolice,
        policeRecordDiagnosedAlcoholOrDrugUse:
            data.policeRecord?.diagnosedAlcoholOrDrugUse,
        policeRecordReceivingPoliceStation: data.policeRecord?.receivingPoliceStation,
        policeRecordPoliceFileNumber: data.policeRecord?.policeFileNumber,
        policeRecordSomeoneWarnedByPolice: data.policeRecord?.someoneWarnedByPolice,
        policeRecordWhoWarnedByPolice: data.policeRecord?.whoWarnedByPolice,
        areThereWitnesses: data.areThereWitnesses,
        witnessesNameAndSurname: data.witnessesInformation?.nameAndSurname,
        witnessesStreet: data.witnessesInformation?.street,
        witnessesPostcode: data.witnessesInformation?.postCode,
        witnessesCity: data.witnessesInformation?.city,
        witnessesPhoneNumber: data.witnessesInformation?.phoneNumber,
        witnessesEmail: data.witnessesInformation?.email,
        otherPartyInformationNameAndSurname: data.otherPartyInformation?.nameAndSurname,
        otherPartyInformationStreet: data.otherPartyInformation?.street,
        otherPartyInformationPostCode: data.otherPartyInformation?.postCode,
        otherPartyInformationCity: data.otherPartyInformation?.city,
        otherPartyInformationCityPhoneNumber: data.otherPartyInformation?.phoneNumber,
        otherPartyInformationCityEmail: data.otherPartyInformation?.email,
        otherPartyInformationLicenseNumber: data.otherPartyInformation?.licenseNumber,
        otherPartyInformationVehicleType: data.otherPartyInformation?.vehicleType,
        otherPartyInformationDamageAmount: data.otherPartyInformation?.damageAmount
            ? data.otherPartyInformation?.damageAmount
            : '',
        fileInfos: data.fileInfos,
    };
};
