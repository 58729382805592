import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { APP_ROUTES } from 'src/routing';

import { ContractDetailsViewResponseBody } from './contract-details-view-response-body';

export const useGetGeneralContractDetails = (
    id: string
): [boolean, ContractDetailsViewResponseBody] => {
    const httpClient = useHttpClient();
    const router = useHistory();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [contractData, setData] = useState<ContractDetailsViewResponseBody>(null);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    setLoading(true);
                    const data = await httpClient.get<ContractDetailsViewResponseBody>(
                        `contracts/${id}`
                    );
                    setData(data);
                } catch (err) {
                    if (
                        err?.error?.response?.data?.errors?.userSettings?.includes(
                            'User does not have access to these data.'
                        )
                    ) {
                        router.push(APP_ROUTES.NO_ACCESS);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [id]);

    return [isLoading, contractData];
};
