import React, { FC, ReactElement } from 'react';
import { useDashboardContext } from 'src/shared/contexts';
import LoadingIndicator from 'src/shared/loading-indicator';
import { ValueWithTrendPercentage } from '..';
import { ValueChangeColorScheme } from '../common';
import { DashboardCard } from '../dashboard-card/dashboard-card';
import { DashboardNumericValue } from '../dashboard-numeric-value/dashboard-numeric-value';
import { DashboardListOfNumericValues } from '../dashboard-list-of-numeric-values/dashboard-list-of-numeric-values';

interface Props {
    title: string;
    editMode: boolean;
    isShown: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
    bookedPremium: ValueWithTrendPercentage<{ year: string; value: number }[]>;
}

export const DashboardBookedPremium: FC<Props> = ({
    title,
    editMode,
    isShown,
    handleChangeShown,
    name,
    bookedPremium,
}): ReactElement => {
    const { isGetDashboardDataLoading } = useDashboardContext();

    return (
        <DashboardCard
            header={title}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            <>
                {isGetDashboardDataLoading && <LoadingIndicator />}
                {!isGetDashboardDataLoading &&
                    Array.isArray(bookedPremium?.dashboardValues) &&
                    bookedPremium.dashboardValues.length <= 1 && (
                        <DashboardNumericValue
                            value={bookedPremium?.dashboardValues[0]?.value}
                            offset={
                                bookedPremium
                                    ? {
                                          offset: bookedPremium?.trendPercentage,
                                          unit: '%',
                                          colorScheme: ValueChangeColorScheme.Irrelevant,
                                      }
                                    : undefined
                            }
                            unit='€'
                        />
                    )}
                {!isGetDashboardDataLoading &&
                    Array.isArray(bookedPremium?.dashboardValues) &&
                    bookedPremium?.dashboardValues.length > 1 && (
                        <DashboardListOfNumericValues
                            values={bookedPremium?.dashboardValues}
                            unit='€'
                        />
                    )}
            </>
        </DashboardCard>
    );
};
