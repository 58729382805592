import React, {
    BaseSyntheticEvent,
    ChangeEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';

import { useGlobalFilter } from 'src/shared/contexts';
import { Divisions } from 'src/shared/constants';
import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

import {
    DamageReportFormAONKompositTranspTypes,
    DamageReportFormAONKompositTransportMutation,
} from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-transport-types';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import { damageReportFormAONKompositTranspDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-AON-Komposit-default-state/damage-report-form-AON-default-state-transp';
import {
    generateDisabledInputs,
    generateFooter,
} from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-Komposit-transport';
import { damageReportFormAonTranspDocumentsOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-tranp-documents-selection-option';
import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';
import { DamageReportFormView } from '../damage-report-form-view';
import { damageReportFormGenerateAONTtanspDamageTypeOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-transp-type-of-damage-selection-option';
import { damageReportFormGenerateAONTranspDamageAssessmentOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-transp-damage-assessment-selection-options';
import { DamageReportFormConfigAONKompositTransp } from '../../damage-report-form-config/damage-report-form-AON-komposit-config/damage-report-form-config-AON-Komposit-transport';
import { damageReportFormAonTranspInformationOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-AON-transp-information-selection-options';
import { damageReportFormGeneralAONKompositSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-schemas-AON-Komposit/damage-report-form-general-AON-Komposit-schema';
import { damageReportFormTransportGetAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-transport-get-adapter';
import { damageReportFormTransportMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-adapters-AON-Komposit/damage-report-form-transport-mutate-adapter';
import { useDamageReportFormSaveReport } from '../../damage-report-form-hooks';

interface DamageReportFormONKompositTransportProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState?: string;
}

export const DamageReportFormAONTransport = memo(
    (props: DamageReportFormONKompositTransportProps) => {
        const { setDivision, hideButtons, data } = props;

        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const contractId = useQuery().get('contractId');

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormAONKompositTranspTypes>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(damageReportFormGeneralAONKompositSchema),
            defaultValues: damageReportFormAONKompositTranspDefaultState(contractId),
        });

        const { changeDivision } = useGlobalFilter();

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();
        const classes = useStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });

        const { fields: documentsAttached, append: appendDocuments } = useFieldArray({
            control,
            name: 'documentsAttached',
        });

        const { fields: transportInformation, append: appendInformation } = useFieldArray(
            {
                control,
                name: 'transportInformation',
            }
        );

        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            policyOrCertificateNumber,
            insuredCompanyFileNumber,
            ibanCompensation,
            bic,
            bank,
            accountOwner,
            typeOfDamage,
            departureDate,
            deliveringCarrier,
            senderOrRecipient,
            insuredGoods,
            damagedGoods,
            totalLoss,
            consignmentComplete,
            outwardlyDamageShipment,
            containerNumber,
            leadSealOrSeal,
            availableUponArrival,
            damaged,
            damageAssessment,
            atThe,
            carrierForwardingAgentBeenGivenPureReceipt,
            averageCommissionerWasCalledIn,
            damageDescription,
            expectedAmountOfDamage,
            transportFrom,
            transportVia,
            transportAfter,
            storageIn,
            storageInOrAt,
            exhibition,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'policyOrCertificateNumber',
            'insuredCompanyFileNumber',
            'ibanCompensation',
            'bic',
            'bank',
            'accountOwner',
            'typeOfDamage',
            'departureDate',
            'deliveringCarrier',
            'senderOrRecipient',
            'insuredGoods',
            'damagedGoods',
            'totalLoss',
            'consignmentComplete',
            'outwardlyDamageShipment',
            'containerNumber',
            'leadSealOrSeal',
            'availableUponArrival',
            'damaged',
            'damageAssessment',
            'atThe',
            'carrierForwardingAgentBeenGivenPureReceipt',
            'averageCommissionerWasCalledIn',
            'damageDescription',
            'expectedAmountOfDamage',
            'transportFrom',
            'transportVia',
            'transportAfter',
            'storageIn',
            'storageInOrAt',
            'exhibition',
        ]);

        const {
            isLoading: isSaveLoading,
            setLoading: setSaveLoading,
            saveReport,
            handleSaveReport,
        } = useDamageReportFormSaveReport<
            DamageReportFormAONKompositTranspTypes,
            DamageReportFormAONKompositTransportMutation
        >({
            id,
            division: Divisions.TRANSP,
            pathname: 'transp',
            adapter: damageReportFormTransportMutateAdapter,
            data: { ...getValues(), files },
        });

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(damageReportFormTransportGetAdapter(data));
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormAONKompositTranspTypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormAONKompositTranspTypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(Divisions.TRANSP);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleChangeSwitcher = useCallback(
            (name, value: boolean): void => {
                setValue(name, value);
                trigger();
            },

            [setValue]
        );

        const handleChangedocumentsAttached = useCallback(
            (e: ChangeEvent<HTMLInputElement>): void => {
                if (e.target.checked) {
                    appendDocuments({ name: e.target.name });
                } else {
                    const newChosenDocuments = documentsAttached.filter(
                        (item) => item.name !== e.target.name
                    );
                    setValue('documentsAttached', newChosenDocuments);
                }
            },
            [appendDocuments, setValue, documentsAttached]
        );

        const handleChangeTransportInformationOptions = useCallback(
            (e: ChangeEvent<HTMLInputElement>): void => {
                if (e.target.checked) {
                    appendInformation({ name: e.target.name });
                } else {
                    const newChosenInformation = transportInformation.filter(
                        (item) => item.name !== e.target.name
                    );
                    setValue('transportInformation', newChosenInformation);
                }
            },
            [appendInformation, setValue, transportInformation]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: formState.errors,
                }),
            [
                risk,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState.errors.contractNumber,
                formState.errors.insuranceCompany,
                formState.errors.policyHolder,
            ]
        );

        const chosenDocuments = useMemo(
            () => documentsAttached?.map((item) => item.name) || [],
            [documentsAttached]
        );

        const documentsAttachedOptions = useMemo(
            () => damageReportFormAonTranspDocumentsOptions(t, chosenDocuments),
            [t, chosenDocuments]
        );

        const chosenInformation = useMemo(
            () => transportInformation?.map((item) => item.name) || [],
            [transportInformation]
        );

        const transportInformationOptions = useMemo(
            () => damageReportFormAonTranspInformationOptions(t, chosenInformation),
            [t, chosenInformation]
        );

        const damageTypeOptions = useMemo(
            () => damageReportFormGenerateAONTtanspDamageTypeOptions(t),
            [t]
        );

        const damageAssessmentOptions = useMemo(
            () => damageReportFormGenerateAONTranspDamageAssessmentOptions(t),
            [t]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigAONKompositTransp({
                    policyOrCertificateNumber,
                    insuredCompanyFileNumber,
                    ibanCompensation,
                    bic,
                    bank,
                    accountOwner,
                    typeOfDamage,
                    damageTypeOptions,
                    departureDate,
                    deliveringCarrier,
                    senderOrRecipient,
                    insuredGoods,
                    damagedGoods,
                    totalLoss,
                    consignmentComplete,
                    outwardlyDamageShipment,
                    containerNumber,
                    leadSealOrSeal,
                    availableUponArrival,
                    damaged,
                    damageAssessment,
                    damageAssessmentOptions,
                    atThe,
                    carrierForwardingAgentBeenGivenPureReceipt,
                    averageCommissionerWasCalledIn,
                    damageDescription,
                    expectedAmountOfDamage,
                    transportInformationOptions,
                    transportFrom,
                    transportVia,
                    transportAfter,
                    storageIn,
                    storageInOrAt,
                    exhibition,
                    handleChangeTransportInformationOptions,
                    handleChangeSwitcher,
                }),
            [
                policyOrCertificateNumber,
                insuredCompanyFileNumber,
                ibanCompensation,
                bic,
                bank,
                accountOwner,
                typeOfDamage,
                damageTypeOptions,
                departureDate,
                deliveringCarrier,
                senderOrRecipient,
                insuredGoods,
                damagedGoods,
                totalLoss,
                consignmentComplete,
                outwardlyDamageShipment,
                containerNumber,
                leadSealOrSeal,
                availableUponArrival,
                damaged,
                damageAssessment,
                damageAssessmentOptions,
                atThe,
                carrierForwardingAgentBeenGivenPureReceipt,
                averageCommissionerWasCalledIn,
                damageDescription,
                expectedAmountOfDamage,
                transportInformationOptions,
                transportFrom,
                transportVia,
                transportAfter,
                storageIn,
                storageInOrAt,
                exhibition,
                handleChangeTransportInformationOptions,
                handleChangeSwitcher,
            ]
        );

        const footerConfig = useMemo(
            () =>
                generateFooter({
                    documentsAttachedOptions,
                    handleChangedocumentsAttached,
                }),
            [documentsAttachedOptions, handleChangedocumentsAttached]
        );

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.TRANSPORT}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={footerConfig}
                        headerConfig={headerConfig}
                        configs={configs}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
