import React, { BaseSyntheticEvent, ChangeEvent, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Button,
    Popover,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    IconButton,
    withStyles,
    Theme,
    Badge,
} from '@material-ui/core';
import { useFormControlLabelStyle, useStyle } from './theme-checkboxes-dropdown-styles';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const StyledBadge = withStyles((theme: Theme) => ({
    badge: {
        top: 10,
        right: 1,
        padding: '0 4px',
        transform: 'scale(0.5) translate(50%, -50%)',
        backgroundColor: theme.palette.info.main,
    },
}))(Badge);

export interface CheckboxItem {
    title: string;
    value: string;
    checked: boolean;
    // eslint-disable-next-line
    data: any | undefined;
    labelAsKeyForTFunction?: boolean;
}

interface ThemeCheckboxesDropdownProps {
    itemsList: CheckboxItem[];
    setItemsList: (itemLists: CheckboxItem[], name?: string) => void;
    buttonTitle?: string;
    buttonIcon?: JSX.Element;
    withoutBadge?: boolean;
    iconButtonClass?: string;
    titleButtonClass?: string;
    name?: string;
    withBadgeNumber?: boolean;
    triggerExternalCallback?: () => void;
    onCloseHandler?: () => void;
}

export const ThemeCheckboxesDropdown = (
    props: ThemeCheckboxesDropdownProps
): JSX.Element => {
    const { t } = useTranslation();
    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyle();

    const {
        itemsList,
        setItemsList,
        buttonTitle,
        buttonIcon,
        withoutBadge,
        iconButtonClass,
        titleButtonClass,
        name,
        withBadgeNumber,
        triggerExternalCallback,
        onCloseHandler,
    } = props;

    const isInvisible = useMemo(
        () => !itemsList?.slice(1)?.filter(({ checked }) => checked)?.length,
        [itemsList]
    );

    const isAllSelected = useMemo(() => {
        return itemsList[0] ? itemsList[0]?.checked : true;
    }, [itemsList]);

    const handleStopPropagation = (e: BaseSyntheticEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'all' && event.target.checked) {
            const newItemsList = itemsList.map(
                ({ title, value, data, labelAsKeyForTFunction }) => {
                    if (value === 'all' && event.target.checked) {
                        return {
                            title,
                            value,
                            labelAsKeyForTFunction,
                            checked: true,
                            data,
                        };
                    }
                    return { title, value, labelAsKeyForTFunction, checked: false, data };
                }
            );

            setItemsList(newItemsList, name);
        } else {
            setItemsList(
                itemsList.map((item) => {
                    if (item.value === 'all') {
                        return { ...item, checked: false };
                    }
                    if (event.target.name === item.value) {
                        return { ...item, checked: event.target.checked };
                    }
                    return { ...item };
                }),
                name
            );
        }
    };

    const handleOnClose = (e: SyntheticEvent, cb: () => void): void => {
        if (onCloseHandler) {
            onCloseHandler();
        }
        handleStopPropagation(e);
        cb();
        if (typeof triggerExternalCallback === 'function') {
            triggerExternalCallback();
        }
    };

    const handleOnOpen = (e: SyntheticEvent, cb): void => {
        handleStopPropagation(e);
        cb(e.currentTarget);
    };

    const amountOfCheckedValues = useMemo(
        () => itemsList.filter((item) => item.checked).length,
        [itemsList]
    );

    return (
        <PopupState variant='popover'>
            {(popupState) => {
                const popoverProps = bindPopover(popupState);
                const buttonProps = bindTrigger(popupState);
                return (
                    <>
                        {buttonIcon ? (
                            <IconButton
                                {...buttonProps}
                                size='small'
                                className={clsx(
                                    classes.filterIconButton,
                                    iconButtonClass
                                )}
                                onClick={(e) => handleOnOpen(e, buttonProps.onClick)}
                            >
                                {withoutBadge ? (
                                    buttonIcon
                                ) : (
                                    <StyledBadge variant='dot' invisible={isInvisible}>
                                        {buttonIcon}
                                    </StyledBadge>
                                )}
                            </IconButton>
                        ) : (
                            <Button
                                disableRipple
                                className={clsx(classes.button, titleButtonClass)}
                                endIcon={
                                    popupState.isOpen ? (
                                        <>
                                            {withBadgeNumber &&
                                                amountOfCheckedValues !== 0 &&
                                                !isAllSelected && (
                                                    <div className={classes.numberBadge}>
                                                        {amountOfCheckedValues}
                                                    </div>
                                                )}
                                            <ExpandLessIcon />
                                        </>
                                    ) : (
                                        <>
                                            {withBadgeNumber &&
                                                amountOfCheckedValues !== 0 &&
                                                !isAllSelected && (
                                                    <div className={classes.numberBadge}>
                                                        {amountOfCheckedValues}
                                                    </div>
                                                )}
                                            <ExpandMoreIcon />
                                        </>
                                    )
                                }
                                color='primary'
                                {...buttonProps}
                            >
                                <Typography
                                    className={classes.buttonText}
                                    noWrap
                                    variant='body1'
                                >
                                    {buttonTitle}
                                </Typography>
                            </Button>
                        )}
                        <Popover
                            {...popoverProps}
                            onClose={(e) =>
                                handleOnClose(e as SyntheticEvent, popoverProps.onClose)
                            }
                            elevation={2}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box className={classes.itemsContainer} p={2}>
                                {itemsList?.map((item) => {
                                    return (
                                        <FormControlLabel
                                            key={item.value}
                                            classes={formControlLabelClasses}
                                            onClick={(event) => event.stopPropagation()}
                                            control={
                                                <Checkbox
                                                    disableRipple
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon
                                                            className={classes.icon}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon
                                                            className={clsx(
                                                                classes.icon,
                                                                classes.checkedIcon
                                                            )}
                                                        />
                                                    }
                                                    checked={item.checked}
                                                    onChange={handleChange}
                                                    name={item.value}
                                                />
                                            }
                                            label={
                                                item.labelAsKeyForTFunction
                                                    ? t(item.title)
                                                    : item.title
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        </Popover>
                    </>
                );
            }}
        </PopupState>
    );
};
