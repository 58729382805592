import {
    LIGHT_MAAS_THEME_COLORS,
    DARK_MAAS_THEME_COLORS,
} from './maas-theme-colors';

export const lightMaasThemeOverrides = {
    MuiCssBaseline: {
        '@global': {
            '*': {
                scrollbarWidth: 'thin',
                scrollbarColor: `#888888 #595959`,
                '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    background: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                },
                '&::-webkit-scrollbar-thumb': {
                    background: LIGHT_MAAS_THEME_COLORS.SECONDARY_LIGHT_COLOR,
                },
            },
        },
    },
    MuiLink: {
        root: {
            cursor: 'pointer',
            color: LIGHT_MAAS_THEME_COLORS.INFO_MAIN_COLOR,
        },
    },
    MuiPickersDay: {
        day: {
            '&:hover': {
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        daySelected: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
            '&:hover': {
                color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        current: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersToolbar: {
        toolbar: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            '& .MuiTypography-root': {
                color: `${LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT}!important`,
            },
        },
    },
    MuiPickersYear: {
        root: {
            transition: '0.2s',
            '&:hover': {
                color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        yearSelected: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersMonth: {
        root: {
            transition: '0.2s',
            '&:hover': {
                color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        monthSelected: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClock: {
        pin: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClockPointer: {
        pointer: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            cursor: 'pointer',
        },
        thumb: {
            border: `14px solid ${LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR}`,
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            cursor: 'pointer',
        },
        noPoint: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClockNumber: {
        clockNumberSelected: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
        },
    },
};

export const darkMaasThemeOverrides = {
    MuiCssBaseline: {
        '@global': {
            '*': {
                scrollbarWidth: 'thin',
                scrollbarColor: `#888888 #595959`,
                '&::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                },
                '&::-webkit-scrollbar-track': {
                    background: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                },
                '&::-webkit-scrollbar-thumb': {
                    background: LIGHT_MAAS_THEME_COLORS.SECONDARY_LIGHT_COLOR,
                },
            },
        },
    },
    MuiLink: {
        root: {
            cursor: 'pointer',
            color: DARK_MAAS_THEME_COLORS.INFO_MAIN_COLOR,
        },
    },
    MuiPickersDay: {
        day: {
            '&:hover': {
                color: DARK_MAAS_THEME_COLORS.INFO_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        daySelected: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            color: DARK_MAAS_THEME_COLORS.INFO_CONTRAST_TEXT,
            '&:hover': {
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
                color: DARK_MAAS_THEME_COLORS.INFO_CONTRAST_TEXT,
            },
        },
        current: {
            color: DARK_MAAS_THEME_COLORS.INFO_MAIN_COLOR,
        },
    },
    MuiPickersToolbar: {
        toolbar: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            '& .MuiTypography-root': {
                color: `${DARK_MAAS_THEME_COLORS.INFO_CONTRAST_TEXT}!important`,
            },
        },
    },
    MuiPickersYear: {
        root: {
            transition: '0.2s',
            '&:hover': {
                color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        yearSelected: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersMonth: {
        root: {
            transition: '0.2s',
            '&:hover': {
                color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
                backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            },
        },
        monthSelected: {
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClock: {
        pin: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClockPointer: {
        pointer: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            cursor: 'pointer',
        },
        thumb: {
            border: `14px solid ${LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR}`,
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
            cursor: 'pointer',
        },
        noPoint: {
            backgroundColor: LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR,
        },
    },
    MuiPickersClockNumber: {
        clockNumberSelected: {
            color: DARK_MAAS_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
        },
    },
};