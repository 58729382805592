import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum VEHICLE_BODY_OPTIONS {
    PLANZUBG = 'Planzug',
    COOLING_TRAIN = 'Cooling train',
    TANK_TRUCK = 'Tank truck',
    OTHER = 'Other',
}

export const generateVehicleBodyOptions = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:vehicleBodyOptions.planzug'),
            value: VEHICLE_BODY_OPTIONS.PLANZUBG,
        },
        {
            title: t('damages-report:vehicleBodyOptions.coolingTrain'),
            value: VEHICLE_BODY_OPTIONS.COOLING_TRAIN,
        },
        {
            title: t('damages-report:vehicleBodyOptions.tankTruck'),
            value: VEHICLE_BODY_OPTIONS.TANK_TRUCK,
        },
        {
            title: t('damages-report:vehicleBodyOptions.other'),
            value: VEHICLE_BODY_OPTIONS.OTHER,
        },
    ];
};
