import React, { ChangeEvent } from 'react';
import { MenuItem } from 'src/theming';
import { GridColDef } from '@material-ui/data-grid';
import {
    renderCellExpand,
    renderCellAsDateExpand,
} from 'src/theming/theme-table/theme-table-cell-expand';

import { CellWithSearch } from '../../theming/theme-table/cell-with-search';
import { CellWithDatePicker } from '../../theming/theme-table/cell-with-date-picker';
import { TFunction } from 'react-i18next';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { IconButton } from '@material-ui/core';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { DeleteCell } from '../contracts-details-view/delete-document-cell';
import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

const isAonKompositInstance = AppInstances.AON_KOMPOSIT === appInstance;

enum DamagesDetailsColumnKeys {
    DELETE = 'delete',
    DOWNLOAD = 'download',
    TITLE = 'title',
    DATE = 'date',
    TYPE = 'type',
}

export const generateDamagesDetailsAdditionalMenu = (
    t: TFunction<Array<string>>,
    handleOpenUploadModalWindow: () => void
): MenuItem[] => {
    return [
        {
            title: t('damage-details-view:details.damageAddendum'),
            action: handleOpenUploadModalWindow,
        },
    ];
};

export const generateDamagesDetailsTableHeader = (
    t: TFunction<'damage-details-view'>,
    title: string,
    dateValue: ParsableDate,
    handleChangeValue: (e: ChangeEvent<HTMLInputElement>) => void,
    handleChangeDate: (name: string, value: ParsableDate) => void,
    dateSearchFieldClass: string,
    onDownload: (letterId: string) => void,
    onRemoveSuccess: () => void
): GridColDef[] => {
    return [
        ...(isAonKompositInstance
            ? [
                  {
                      field: DamagesDetailsColumnKeys.DELETE,
                      width: 60,
                      renderHeader: () => <></>,
                      renderCell: (props) => (
                          <DeleteCell
                              {...props}
                              entity='document-damages'
                              onSuccess={onRemoveSuccess}
                              approveMessage='damage-details-view:documents.approveDeletingMessage'
                          />
                      ),
                  },
              ]
            : []),
        {
            field: DamagesDetailsColumnKeys.DOWNLOAD,
            width: 60,
            renderHeader: () => <></>,
            renderCell: (props) => (
                <IconButton onClick={() => onDownload(props.row.amsIdNr)}>
                    <CloudDownloadOutlined />
                </IconButton>
            ),
        },
        {
            field: DamagesDetailsColumnKeys.TITLE,
            headerName: t('damage-details-view:documents.table.title'),
            flex: 3,
            renderCell: renderCellExpand,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('damage-details-view:documents.table.title')}
                    value={title}
                    handleChange={handleChangeValue}
                    name='title'
                />
            ),
        },
        {
            field: DamagesDetailsColumnKeys.DATE,
            headerName: t('damage-details-view:documents.table.date'),
            flex: 1,
            renderCell: renderCellAsDateExpand,
            renderHeader: (props) => (
                <CellWithDatePicker
                    {...props}
                    title={t('damage-details-view:documents.table.date')}
                    value={dateValue}
                    handleChange={handleChangeDate}
                    name='date'
                    dateSearchFieldClass={dateSearchFieldClass}
                />
            ),
        },
        {
            field: DamagesDetailsColumnKeys.TYPE,
            headerName: t('damage-details-view:documents.table.type'),
            flex: 0.5,
            renderCell: renderCellExpand,
        },
    ];
};
