import * as yup from 'yup';
import { Divisions } from 'src/shared/constants';

export const contractEditFormValidationSchema = yup.object().shape({
    contactByPhone: yup.string().nullable(),
    phoneNumber: yup.string().when('contactByPhone', {
        is: (val: string) => val === 'yes',
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string(),
    }),
    branch: yup.string().nullable(),
    cover: yup.string().when('branch', {
        is: (val: string) => val === Divisions.KFZW,
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string(),
    }),
});
