import { Divisions } from 'src/shared/constants';

export interface DamageReportFormSettingsForBHVAndPHVMutation {
    pathname: string;
}

export const damageReportFormGBWAndGESCHAndHRGetSettingsForMutation = (
    division: string
): DamageReportFormSettingsForBHVAndPHVMutation => {
    switch (division) {
        case Divisions.HR: {
            return {
                pathname: 'hr',
            };
        }
        case Divisions.GESCH: {
            return {
                pathname: 'gesch',
            };
        }
        case Divisions.GBW: {
            return {
                pathname: 'gbw',
            };
        }
        default: {
            return null;
        }
    }
};
