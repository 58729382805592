import moment from 'moment';
import { DamageReportFormPostDto } from '../damage-report-form-types/damage-report-form-KFZW-mutate-dto-types';
import { DamageReportFormFormData } from '../damage-report-form-types/damage-report-form-form-data-type';
import { DamageTypes } from '../damage-report-form-selection-items/damage-report-form-generate-damage-types-options';

export const damageReportFormKFZWMutateAdapter = (
    data: DamageReportFormFormData
): DamageReportFormPostDto => {
    const offset = new Date().getTimezoneOffset() * 60000;
    let { otherPartyInformationDamageAmount } = data;
    if (typeof otherPartyInformationDamageAmount === 'string') {
        otherPartyInformationDamageAmount = data.otherPartyInformationDamageAmount
            .replace(/\./g, '')
            .replace(',', '.');
    }

    return {
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(data.dayOfDamage).startOf('day').utc(true).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        kfzLicenseNumber: data.kfzLicenseNumber,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        liability: Boolean(
            data.typeOfDamage.find((item) => item.name === DamageTypes.LIABILITY)
        ),
        fullyCoverage: Boolean(
            data.typeOfDamage.find(
                (item) => item.name === DamageTypes.FULLY_COMPREHENSIVE
            )
        ),
        partialCoverage: Boolean(
            data.typeOfDamage.find((item) => item.name === DamageTypes.PARTIAL_COVERAGE)
        ),
        accidentScene: {
            street: data.accidentSceneStreet,
            postCode: data.accidentScenePostcode,
            city: data.accidentSceneCity,
            country: data.accidentSceneCountry,
        },
        driverInformation: {
            firstname: data.driverInformationFirstName,
            surname: data.driverInformationSurname,
            tenant: data.driverInformationTenant,
            street: data.driverInformationStreet,
            postCode: data.driverInformationPostCode,
            city: data.driverInformationCity,
            phoneNumber: data.driverInformationPhoneNumber,
            email: data.driverInformationEmail,
            drivingLicenseAvailable: data.driverInformationDrivingLicenseAvailable,
            drivingLicenseClass: data.driverInformationDrivingLicenseClass,
            dateOfIssue: data.driverInformationDateOfIssue
                ? moment(
                      moment(data.driverInformationDateOfIssue).valueOf() - offset
                  ).toISOString()
                : null,
        },
        accidentAndDamageInformation: {
            rootCause: data.rootCause,
            changeOfVehicle: data.accidentDamageDetailsInformationChangeOfVehicle,
            estimatedAmountOfDamageToYourOwnVehicle:
                data.accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle,
            damageNumberCustomer:
                data.accidentDamageDetailsInformationDamageNumberCustomer,
            detailedDamageDescription:
                data.accidentDamageDetailsInformationDetailedDamageDescription,
        },
        isTeam: data.isTeam,
        teamDamage: {
            street: data.teamDamageStreet,
            city: data.teamDamageCity,
            phoneNumber: data.teamDamagePhoneNumber,
            email: data.teamDamageEmail,
            postCode: data.teamDamagePostcode,
            insurancePolicyNumber: data.teamDamageInsurancePolicyNumber,
            licenseNumber: data.teamLicenseNumber,
            vehicleType: data.teamVehicleType,
            towedVehicleKeeper: data.teamTowedVehicleKeeper,
        },
        incidentRecordedByPolice: data.incidentRecordedByPolice,
        policeRecord: {
            diagnosedAlcoholOrDrugUse: data.policeRecordDiagnosedAlcoholOrDrugUse,
            receivingPoliceStation: data.policeRecordReceivingPoliceStation,
            policeFileNumber: data.policeRecordPoliceFileNumber,
            someoneWarnedByPolice: data.policeRecordSomeoneWarnedByPolice,
            whoWarnedByPolice: data.policeRecordWhoWarnedByPolice,
        },
        areThereWitnesses: data.areThereWitnesses,
        witnessesInformation: {
            nameAndSurname: data.witnessesNameAndSurname,
            street: data.witnessesStreet,
            postCode: data.witnessesPostcode,
            city: data.witnessesCity,
            phoneNumber: data.witnessesPhoneNumber,
            email: data.witnessesEmail,
        },
        otherPartyInformation: {
            nameAndSurname: data.otherPartyInformationNameAndSurname,
            street: data.otherPartyInformationStreet,
            postCode: data.otherPartyInformationPostCode,
            city: data.otherPartyInformationCity,
            email: data.otherPartyInformationCityEmail,
            phoneNumber: data.otherPartyInformationCityPhoneNumber,
            licenseNumber: data.otherPartyInformationLicenseNumber,
            vehicleType: data.otherPartyInformationVehicleType,
            damageAmount: otherPartyInformationDamageAmount || null,
        },
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
    };
};
