import { DamageReportFormContainer } from '../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageFormDisabledInput } from '../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { SelectItem } from '../../../theming';
import { DamageFormConditionalBlock } from '../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from './damage-report-form-config-GBW-HR-GESCH';
import { DamageReportFormInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageReportFormCurrencyInput } from '../damage-report-form-components/damage-report-form-items/damage-form-currency-input';

import { DamageReportFormDynamicFieldsRow } from '../damage-report-form-components/damage-report-form-dynimic-fields-row/damage-report-form-dynimic-fields-row';
import { DamageReportFormFullInput } from '../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormSelect } from '../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { DamageReportFormText } from '../damage-report-form-components/damage-report-form-items/damage-report-form-text';

export interface DamageReportFormConfigTravelWReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
    formElementsRows?: {
        RowComponent: React.FC<{ rows: { id: string; formElements: FormElement[] }[] }>;
        rows: { id: string; formElements: FormElement[] }[];
        handleAddRow?: () => void;
        handleDeleteRow?: (id: string) => void;
        handleOnChangeRow: (id: string, row: unknown) => void;
    };
}

interface DamageReportFormConfigTravelProps {
    fileNumberInsuredCompany: string;
    inputTaxDeductionForTheDamage: boolean;
    damageBeenReportedToThePolice: boolean;
    existenceForItemAffectedByTheDamage: boolean;
    insurer: string;
    areBusinessInterruptionLossesToBeExpected: boolean;
    policeStation: string;
    fileNumber: string;
    typeOfDamage: string;
    causedByThirdParty: string;
    damageTypeOptions: SelectItem[];
    handleChangeSwitcher: (name: string, value: boolean) => void;
    handleAddRow: () => void;
    handleDeleteRow: (id: string) => void;
    handleOnChangeRow?: (id: string, row: unknown) => void;
    itemsAffectedByTheDamageFields: {
        id: string;
        numberAndTypeOfThings: string;
        replacementPrice: number | string;
        repairCosts: number | string;
    }[];
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigTravelWReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigTravel = ({
    fileNumberInsuredCompany,
    inputTaxDeductionForTheDamage,
    damageBeenReportedToThePolice,
    existenceForItemAffectedByTheDamage,
    insurer,
    areBusinessInterruptionLossesToBeExpected,
    policeStation,
    fileNumber,
    damageTypeOptions,
    typeOfDamage,
    causedByThirdParty,
    handleChangeSwitcher,
    handleAddRow,
    handleDeleteRow,
    itemsAffectedByTheDamageFields,
    handleOnChangeRow,
}: DamageReportFormConfigTravelProps): DamageReportFormConfigTravelWReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:itemsAffectedByTheDamage.label',
                Container: DamageReportFormContainer,
                id: 'typeOfDamage',
                formElements: [
                    {
                        items: damageTypeOptions,
                        Item: DamageReportFormSelect,
                        label: 'damages-report:itemsAffectedByTheDamage.typeOfDamage',
                        name: 'typeOfDamage',
                        value: typeOfDamage,
                    },
                ],
            },
            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'fileNumberInsuredCompany',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:indicateBelowAllItemsAffected.fileNumberInsuredCompany',
                        name: 'fileNumberInsuredCompany',
                        value: fileNumberInsuredCompany,
                    },
                ],
            },
            {
                title: 'damages-report:itemsAffectedByTheDamage.title',
                Container: DamageReportFormContainer,
                id: '',
                formElementsRows: {
                    handleAddRow,
                    handleDeleteRow,
                    handleOnChangeRow,
                    RowComponent: DamageReportFormDynamicFieldsRow,
                    rows: itemsAffectedByTheDamageFields?.map((item) => ({
                        id: item.id,
                        formElements: [
                            {
                                Item: DamageReportFormInput,
                                label: 'damages-report:numberAndTypeOfThings',
                                name: 'numberAndTypeOfThings',
                                value: item.numberAndTypeOfThings,
                            },
                            {
                                Item: DamageReportFormCurrencyInput,
                                label: 'damages-report:replacementPrice',
                                name: 'replacementPrice',
                                value: item.replacementPrice,
                            },
                            {
                                Item: DamageReportFormCurrencyInput,
                                label: 'damages-report:repairCosts',
                                name: 'repairCosts',
                                value: item.repairCosts,
                            },
                        ],
                    })),
                },
                formElements: [],
            },

            {
                title: '',
                Container: DamageReportFormContainer,
                id: 'causedByThirdParty',
                formElements: [
                    {
                        additionalLabel:
                            'damages-report:detailedDamageInfo.ifTheDamageWasCaused',
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:detailedDamageInfo.causedByThirdParty',
                        name: 'causedByThirdParty',
                        value: causedByThirdParty,
                    },
                ],
            },
            {
                title: 'damages-report:detailedDamageInfo.inputTaxDeductionForTheDamage',
                id: 'inputTaxDeductionForTheDamage',
                formElements: [],
                isCombination: inputTaxDeductionForTheDamage,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'inputTaxDeductionForTheDamage',
                        !inputTaxDeductionForTheDamage
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:detailedDamageInfo.existenceForItemAffectedByTheDamage',
                id: 'existenceForItemAffectedByTheDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:indicateBelowAllItemsAffected.insurer',
                        value: insurer,
                        name: 'insurer',
                    },
                ],
                isCombination: existenceForItemAffectedByTheDamage,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'existenceForItemAffectedByTheDamage',
                        !existenceForItemAffectedByTheDamage
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:detailedDamageInfo.areBusinessInterruptionLossesToBeExpected',
                id: 'areBusinessInterruptionLossesToBeExpected',
                formElements: [
                    {
                        Item: DamageReportFormText,
                        label: 'damages-report:detailedDamageInfo.telPreRegistration',
                        name: '',
                        value: '',
                    },
                ],
                isCombination: areBusinessInterruptionLossesToBeExpected,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'areBusinessInterruptionLossesToBeExpected',
                        !areBusinessInterruptionLossesToBeExpected
                    ),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:recordedByThePolicy.title',
                Container: DamageReportFormContainer,
                id: 'recordedByThePolice',
                formElements: [],
            },
            {
                title: 'damages-report:detailedDamageInfo.damageBeenReportedToThePolice',
                Container: DamageFormConditionalBlock,
                id: 'recordedByThePolice',
                isCombination: damageBeenReportedToThePolice,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'damageBeenReportedToThePolice',
                        !damageBeenReportedToThePolice
                    ),
                formElements: [
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:damageData.policeStation',
                        value: policeStation,
                        name: 'policeStation',
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:recordedByThePolicy.policeFileNumber',
                        value: fileNumber,
                        name: 'fileNumber',
                    },
                ],
            },
        ],
    ];
};
