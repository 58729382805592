import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemText } from '@material-ui/core';

import { useStyle } from './contracts-details-view-styles';

interface Props {
    value: string | JSX.Element;
    title: string;
    hidden: boolean;
    additionalTitle?: { [key: string]: number | string };
    titleClassName?: string;
}

export const ContractDetailsListItem = ({
    title,
    value,
    hidden,
    additionalTitle,
    titleClassName,
}: Props): JSX.Element => {
    const classes = useStyle();

    const { t } = useTranslation();

    return (
        <>
            {value && !hidden && (
                <ListItem className={classes.listItem}>
                    <ListItemText data-title className={titleClassName}>
                        {t(title, additionalTitle)}
                    </ListItemText>
                    <ListItemText data-value>{value}</ListItemText>
                </ListItem>
            )}
        </>
    );
};
