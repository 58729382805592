import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { appInstance, hectorStyleInstances } from 'src/environment';

import {
    useAonStyle,
    useAonPaperStyle,
    useContractDetailsViewDataAonStyle,
} from './contracts-details-view-aon-styles';

import {
    useAonMotorStyle,
    useAonMotorPaperStyle,
    useContractDetailsViewDataAonMotorStyle,
} from './contracts-details-view-aon-motor-styles';

import {
    useHectorStyle,
    useHectorPaperStyle,
    useContractDetailsViewDataHectorStyle,
} from './contracts-details-view-hector-styles';

import {
    useSgiffoxxkStyle,
    useSgiffoxxkPaperStyle,
    useContractDetailsViewDataSgiffoxxkStyle,
} from './contracts-details-view-sgiffoxxk-styles';

import { AppInstances } from 'src/shared/constants';

const styleInstances = {
    [AppInstances.AON]: useAonStyle,
    [AppInstances.AON_KOMPOSIT]: useAonStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorStyle,
    [AppInstances.AON_MOTOR]: useAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useSgiffoxxkStyle,
};

const paperStyleInstances = {
    [AppInstances.AON]: useAonPaperStyle,
    [AppInstances.AON_KOMPOSIT]: useAonPaperStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorPaperStyle,
    [AppInstances.AON_MOTOR]: useAonMotorPaperStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorPaperStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkPaperStyle,
    [AppInstances.AGRAVIS]: useSgiffoxxkPaperStyle,
};

const contractDetailsViewDataStyleInstances = {
    [AppInstances.AON]: useContractDetailsViewDataAonStyle,
    [AppInstances.AON_KOMPOSIT]: useContractDetailsViewDataAonStyle,
    [AppInstances.AON_DIGITAL]: useContractDetailsViewDataAonMotorStyle,
    [AppInstances.AON_MOTOR]: useContractDetailsViewDataAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useContractDetailsViewDataAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useContractDetailsViewDataSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useContractDetailsViewDataSgiffoxxkStyle,
};

hectorStyleInstances.forEach(instance => {
    styleInstances[instance] = useHectorStyle;
    paperStyleInstances[instance] = useHectorPaperStyle;
    contractDetailsViewDataStyleInstances[instance] = useContractDetailsViewDataHectorStyle;
});

export const useStyle = (): ClassNameMap => {
    return styleInstances[appInstance]();
};

export const usePaperStyle = (): ClassNameMap => {
    return paperStyleInstances[appInstance]();
};

export const useContractDetailsViewDataStyle = (): ClassNameMap => {
    return contractDetailsViewDataStyleInstances[appInstance]();
};
