import * as yup from 'yup';

export const nonUsersDamageReportFormValidationSchema = yup.object().shape({
    licensePlate: yup
        .string()
        .trim()
        .matches(
            /^[A-Z]{1,3}[-,\s][A-Z]{1,2}[-,\s]\d{1,4}(\s?[E])?$/,
            'Is not in correct format'
        )
        .required(),
    driverInformationFirstName: yup.string().required(),
    driverInformationSurname: yup.string().required(),
    driverInformationEmail: yup.string().email().required(),
    driverInformationPhoneNumber: yup.string().required(),
    damageDay: yup.string().required(),
    damageTime: yup.string().required(),
    accidentLocation: yup.string().required(),
    accidentHostEmail: yup.string().email(),
    damageDescription: yup.string().required(),
});
