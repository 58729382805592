import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isSGIFFOXXKInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { getBackgroundGradient } from 'src/contracts/contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            customerListContainer: {
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 120px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.primary.main
                        : theme.palette.primary.dark,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(2)}px`,
                },
            },
            header: {
                height: 110,
                alignItems: 'center',
                flexWrap: 'nowrap',
                padding: `0 120px`,
                backgroundColor: () =>
                    isDarkMode
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 80,
                },
            },
            backButton: {
                marginRight: theme.spacing(2),
                '& .MuiSvgIcon-root': {
                    width: 25,
                    height: 20,
                },
            },
            title: {
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
            },
            button: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                transition: '0.2s',
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor:
                        theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                },
            },
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            titleContainer: {
                width: '100%',
                height: 75,
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    height: 50,
                },
            },
            tableContainer: {
                flexGrow: 1,
                paddingBottom: theme.spacing(4),
            },
            searchFieldContainer: {
                display: 'flex',
                flexWrap: 'nowrap',
                width: '35%',
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            customerListContainer: {
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 140px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            header: {
                height: 110,
                alignItems: 'center',
                flexWrap: 'nowrap',
                padding: `0 140px`,
                background: theme.palette.secondary.main,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 80,
                },
            },
            backButton: {
                marginRight: theme.spacing(2),
                '& .MuiSvgIcon-root': {
                    width: 25,
                    height: 20,
                },
            },
            title: {
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
            },
            button: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                transition: '0.2s',
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor:
                        theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                },
            },
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            titleContainer: {
                width: '100%',
                height: 75,
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    height: 50,
                },
            },
            tableContainer: {
                flexGrow: 1,
                paddingBottom: theme.spacing(4),
            },
            searchFieldContainer: {
                display: 'flex',
                flexWrap: 'nowrap',
                width: '35%',
            },
        };
    }
    if (isHectorStyles) {
        return {
            customerListContainer: {
                alignContent: 'flex-start',
                flexDirection: 'column',
                padding: `0 140px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.LIGHT
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(2)}px`,
                },
            },
            header: {
                height: 110,
                alignItems: 'center',
                flexWrap: 'nowrap',
                padding: `0 140px`,
                background: getBackgroundGradient(!isDarkMode, theme),
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 80,
                },
            },
            backButton: {
                marginRight: theme.spacing(2),
                '& .MuiSvgIcon-root': {
                    width: 25,
                    height: 20,
                },
            },
            title: {
                color: theme.palette.primary.contrastText,
                fontWeight: theme.typography.fontWeightMedium,
            },
            button: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                transition: '0.2s',
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor:
                        theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                },
            },
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            titleContainer: {
                width: '100%',
                height: 75,
                alignItems: 'center',
                [theme.breakpoints.down('lg')]: {
                    height: 50,
                },
            },
            tableContainer: {
                flexGrow: 1,
                paddingBottom: theme.spacing(4),
            },
            searchFieldContainer: {
                display: 'flex',
                flexWrap: 'nowrap',
                width: '35%',
            },
        };
    }
    return {
        customerListContainer: {
            alignContent: 'flex-start',
            flexDirection: 'column',
            padding: `0 ${theme.spacing(1)}rem`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(2)}px`,
            },
        },
        header: {
            width: '100%',
            height: 75,
            padding: `0 ${theme.spacing(1)}rem`,
            alignItems: 'center',
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
            borderBottom: `1px solid ${
                theme.palette.type == THEME_MODES.DARK && 'rgba(255, 255, 255, 0.2)'
            }`,
            [theme.breakpoints.down('md')]: {
                height: 60,
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        backButton: {
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        title: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        button: {
            height: 40,
            padding: `0 ${theme.spacing(4)}px`,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            color: theme.palette.info.contrastText,
            transition: '0.2s',
            '&:hover': {
                opacity: 0.6,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
            },
        },
        searchIcon: {
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        titleContainer: {
            width: '100%',
            height: 75,
            alignItems: 'center',
        },
        tableContainer: {
            flexGrow: 1,
            paddingBottom: theme.spacing(4),
        },
        searchFieldContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            width: '40%',
            [theme.breakpoints.down('lg')]: {
                width: '35%',
            },
        },
    };
});
