import React from 'react';
import { CircularProgress, useTheme } from '@material-ui/core';

interface ThemeCircularProgressProps {
    isLoading: boolean;
    size?: number;
}

export const ThemeCircularProgress = (props: ThemeCircularProgressProps): JSX.Element => {
    const theme = useTheme();

    const { isLoading } = props;
    return isLoading ? (
        <CircularProgress
            style={{ color: theme.palette.info.main }}
            size={props.size ? props.size : '2rem'}
        />
    ) : null;
};
