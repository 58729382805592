import * as yup from 'yup';

export const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required(),
    newPassword: yup.string().required(),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null])
        .required(),
});
