import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';
import { LIGHT_HECTOR_THEME_COLORS } from 'src/theming/themes/hector/hector-theme-colors';

export const useContractDashboardSgiffoxxkStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.type === THEME_MODES.LIGHT;

    return {
        dashboardContainer: {
            flexDirection: 'column',
            height: '100%',
        },
        toolBar: {
            height: 110,
            alignItems: 'center',
            flexWrap: 'nowrap',
            padding: `0 140px`,
            background: theme.palette.secondary.main,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
            [theme.breakpoints.down('md')]: {
                height: 80,
            },
        },
        dragContractType: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.primary.light}, ${theme.palette.primary.light} 25px, ${theme.palette.secondary.main} 25px, ${theme.palette.secondary.main} 50px)`,
                border: `2px dashed ${theme.palette.info.main}`,
                zIndex: 1000,
            },
        },
        searchIcon: {
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        cardData: {
            width: '60%',
        },
        quickActionsButton: {
            minWidth: 300,
            marginRight: theme.spacing(4),
            marginBottom: theme.spacing(4),
            height: 50,
            padding: `0 ${theme.spacing(5)}px`,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            transition: '0.2s',
            fontSize: '1.8rem',
            borderRadius: 5,
            '&:hover': {
                backgroundColor: theme.palette.secondary.light,
                opacity: 0.8,
            },
            [theme.breakpoints.down('md')]: {
                height: 40,
                fontSize: '1.6rem',
            },
        },
        showMoreLink: {
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: `${theme.spacing(1)}rem`,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium,
        },
        searchButton: {
            height: 50,
            width: 50,
            borderRadius: 0,
            backgroundColor: theme.palette.common.white,
            transition: '0.2s',
            '& .MuiSvgIcon-root': {
                width: 35,
                height: 35,
                color: theme.palette.secondary.main,
            },
            '&:hover': {
                opacity: 0.6,
                backgroundColor: theme.palette.primary.main,
            },
            '&.Mui-disabled': {
                opacity: 0.6,
                color: theme.palette.secondary.contrastText,
            },
        },
        contractsDashboardContainer: {
            padding: `${theme.spacing(4)}px ${theme.spacing(1)}rem`,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        cardsContainer: {
            width: '30%',
            paddingRight: theme.spacing(4),
            opacity: ({ editMode }: { editMode: boolean; isDarkMode: boolean }) =>
                editMode ? 0.4 : 1,
        },
        chartsContainer: {
            width: '70%',
            paddingLeft: theme.spacing(4),
            opacity: ({ editMode }: { editMode: boolean; isDarkMode: boolean }) =>
                editMode ? 0.4 : 1,
        },
        quickActionsContainer: {
            width: '30%',
            padding: `${theme.spacing(4)}px ${theme.spacing(1)}rem`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
            },
        },
        divisionsContainer: {
            width: '100%',
            paddingTop: theme.spacing(4),
        },
        card: {
            width: '100%',
            '&:not(:last-child)': {
                marginBottom: theme.spacing(6),
            },
        },
        chart: {
            width: '50%',
        },
        linearChart: {
            width: '100%',
        },
        dashboardSettingsContainer: {
            padding: `${theme.spacing(3)}px ${theme.spacing(1)}rem`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
            },
        },
        printButton: {
            width: 50,
            height: 50,
            borderRadius: 5,
            backgroundColor: isLightMode
                ? theme.palette.secondary.main
                : 'rgba(0, 0, 0, 0)',
            color: theme.palette.secondary.contrastText,
            transition: '0.2s',
            border: isLightMode
                ? 'none'
                : `1px solid ${LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT}`,
            '&:hover': {
                opacity: 0.6,
                backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiSvgIcon-root': {
                width: 35,
                height: 35,
            },
            [theme.breakpoints.down('md')]: {
                width: 40,
                height: 40,
            },
        },
        damageLinkButton: {
            width: 300,
            height: 50,
            padding: `0 ${theme.spacing(5)}px`,
            marginRight: theme.spacing(4),
            marginBottom: theme.spacing(4),
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderRadius: 5,
            transition: '0.2s',
            '& .MuiSvgIcon-root': {
                color: theme.palette.secondary.contrastText,
            },
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
            '&:hover': {
                backgroundColor: theme.palette.info.light,
                opacity: 0.8,
            },
            [theme.breakpoints.down('md')]: {
                height: 40,
            },
        },
        radioButtonLabel: {
            [theme.breakpoints.down('lg')]: {
                '& .MuiFormControlLabel-label': {
                    fontSize: '1.6rem',
                },
            },
            [theme.breakpoints.down('md')]: {
                '& .MuiFormControlLabel-label': {
                    fontSize: '1.4rem',
                },
            },
        },
        radioFormControl: {
            minWidth: 300,
            '& .MuiFormGroup-root': {
                flexWrap: 'nowrap',
            },
            [theme.breakpoints.down('md')]: {
                minWidth: 240,
            },
        },
        searchFieldContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            width: '30%',
            '@media (max-width:1650px)': {
                width: '25%',
            },
        },
    };
});

export const useFormControlYearSgiffoxxkStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;

    return {
        root: {
            '& .MuiInputBase-root': {
                borderRadius: 5,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: isLightMode
                    ? `1px solid ${theme.palette.secondary.main}`
                    : `1px solid ${theme.palette.secondary.contrastText}`,
            },
        },
    };
});

export const useFormControlSgiffoxxkStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            maxWidth: '30%',
            margin: `0 ${theme.spacing(4)}px 0 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});

export const useInputSgiffoxxkStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: ({ isDarkMode }: { isDarkMode: boolean }) =>
                isDarkMode ? theme.palette.primary.main : theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});

export const useFormControlLabelSgiffoxxkStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;

    return {
        root: {
            display: 'flex!important',
            alignItems: 'center',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.6rem',
            marginRight: theme.spacing(4),
            color: isLightMode
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
        },
    };
});

export const useDashboardActionsSgiffoxxkStyle = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.type === THEME_MODES.LIGHT;

    return {
        editModeButtons: {
            width: '50%',
            '&>button': {
                height: 50,
                padding: `0 ${theme.spacing(4)}px`,
                marginRight: theme.spacing(3),
                backgroundColor: isLightMode
                    ? theme.palette.secondary.main
                    : 'rgba(0, 0, 0, 0)',
                color: theme.palette.secondary.contrastText,
                borderRadius: 5,
                fontSize: '1.8rem',
                transition: '0.2s',
                border: isLightMode
                    ? 'none'
                    : `1px solid ${LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT}`,
                '&[data-mode=edit]': {
                    backgroundColor: theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.grey.A100}`,
                },
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor: theme.palette.secondary.main,
                },
                '& .MuiSvgIcon-root': {
                    width: 30,
                    height: 30,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                    fontSize: '1.6rem',
                },
            },
        },
        saveButton: {
            backgroundColor: theme.palette.secondary.main,
        },
    };
});

export const useSgiffoxxkSelectStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        root: {
            height: 50,
            lineHeight: 3,
            padding: `0 ${theme.spacing(2)}px`,
            fontSize: '1.4rem',
            '& fieldset': {
                border: `1px solid ${
                    isLightMode
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.contrastText
                }`,
            },
            [theme.breakpoints.down('md')]: {
                height: 40,
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        select: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            color: theme.palette.primary.contrastText,
            fontSize: '1.8rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.6rem',
            },
        },
        icon: {
            width: 30,
            height: 30,
            top: 'calc(50% - 15px)',
            color: isLightMode
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
            [theme.breakpoints.down('md')]: {
                width: 25,
                height: 25,
                top: 'calc(50% - 13px)',
            },
        },
        disabled: {
            color: theme.palette.primary.contrastText,
        },
    };
});

export const useSgiffoxxkMenuStyles = makeStyles((theme: Theme) => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.main,
        },
        list: {
            padding: 0,
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
        },
    };
});
