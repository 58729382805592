import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useContractsAonStyles = makeStyles(
    (theme: Theme) => {
        return {
            contractsListContainer: {
                flexDirection: 'column',
                height: '100%',
            },
            toolBar: {
                height: 75,
                alignItems: 'center',
                padding: `0 ${theme.spacing(1)}rem`,
                backgroundColor: ({ isDarkMode }: { isDarkMode: boolean }) =>
                    isDarkMode ? theme.palette.primary.main : theme.palette.primary.light,
                borderBottom: ({ isDarkMode }: { isDarkMode: boolean }) =>
                    isDarkMode ? `1px solid rgba(255, 255, 255, 0.2)` : 'none',
                [theme.breakpoints.down('md')]: {
                    height: 60,
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            contentContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: `${theme.spacing(4)}px ${theme.spacing(1)}rem`,
                [theme.breakpoints.down('md')]: {
                    padding: `0 ${theme.spacing(2)}px`,
                },
            },
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            settingsContainer: {
                display: 'flex',
                height: 75,
                alignItems: 'center',
            },
            settings: {
                width: 'fit-content',
                alignItems: 'center',
            },
            settingButton: {
                width: 40,
                height: 40,
                marginRight: theme.spacing(4),
                borderRadius: 2,
                backgroundColor: theme.palette.info.light,
                '& .MuiSvgIcon-root': {
                    width: 23,
                    height: 23,
                    color: theme.palette.info.main,
                },
            },
            tableContainer: {
                flexGrow: 1,
                minHeight: 700,
            },
            containedButtons: {
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : 'auto',
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: ({ isDarkMode }: { isDarkMode: boolean }) =>
                        isDarkMode
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                    opacity: 0.8,
                },
            },
            searchButton: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                transition: '0.2s',
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor:
                        theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                },
                '&.Mui-disabled': {
                    opacity: 0.6,
                    color: theme.palette.secondary.contrastText,
                },
            },
            divisionsContainer: {
                padding: `${theme.spacing(3)}px ${theme.spacing(1)}rem`,
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
                },
            },
            resetSettingsButton: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.main,
            },
            searchFieldContainer: {
                display: 'flex',
                flexWrap: 'nowrap',
                width: '40%',
                [theme.breakpoints.down('lg')]: {
                    width: '35%',
                },
            },
        };
    },
    { index: 1 }
);
