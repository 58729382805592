import { useState, useEffect } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface CustomerAdvisorsDTO {
    id: string;
    name: string;
    surname: string;
}

export const useCustomerAdvisors = (isAccess: boolean): CustomerAdvisorsDTO[] => {
    const httpClient = useHttpClient();
    const [customerAdvisors, setCustomerAdvisors] = useState<CustomerAdvisorsDTO[]>([]);

    const getCustomerAdvisors = async (): Promise<void> => {
        try {
            const data = await httpClient.get<CustomerAdvisorsDTO[]>(
                'users/dropdown-search'
            );
            setCustomerAdvisors(data);
        } catch {
            setCustomerAdvisors([]);
        }
    };

    useEffect(() => {
        if (isAccess) {
            getCustomerAdvisors();
        }
    }, [isAccess]);

    return customerAdvisors;
};
