import { makeStyles, Theme } from '@material-ui/core/styles';

import { THEME_MODES } from 'src/theming';
import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { getBackgroundGradient } from 'src/contracts/contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        userManagementContainer: {
            flexDirection: 'column',
            height: '100%',
        },
        toolBar: {
            height:
                isSGIFFOXXKInstance ||
                isHectorStyles ||
                isAonDigitalInstance ||
                isAonMotorInstance ||
                isHectorDemoInstance
                    ? 110
                    : 75,
            alignItems: 'center',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return '0 120px';
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return '0 140px';
                }
                return `0 ${theme.spacing(1)}rem`;
            },
            background: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return !isLightMode
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light;
                }
                if (isSGIFFOXXKInstance) {
                    return theme.palette.secondary.main;
                }
                if (isHectorStyles) {
                    return getBackgroundGradient(isLightMode, theme);
                }
                return THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light;
            },
            borderBottom:
                THEME_MODES.DARK === theme.palette.type
                    ? `1px solid rgba(255, 255, 255, 0.2)`
                    : 'none',
            [theme.breakpoints.down(
                isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                height: 80,
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        header: {
            padding: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return `${theme.spacing(5)}px 120px`;
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return `${theme.spacing(5)}px 140px`;
                }
                return `${theme.spacing(5)}px ${theme.spacing(1)}rem`;
            },
            justifyContent: 'space-between',
            [theme.breakpoints.down(
                isHectorStyles ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px!important`,
            },
        },
        searchIcon: {
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        mainContentMenu: {
            width: '100%',
            flexGrow: 1,
            gap: `${theme.spacing(4)}px`,
            flexWrap: 'nowrap',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return '0 120px';
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return '0 140px';
                }
                return `0 ${theme.spacing(1)}rem ${theme.spacing(10)}px ${theme.spacing(
                    1
                )}rem`;
            },
            [theme.breakpoints.down(
                isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        usersListContainer: {
            minWidth: 350,
        },
        userSettingsContainer: {
            flexGrow: 1,
            padding: theme.spacing(4),
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.main;
                }
                return theme.palette.primary.light;
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            color: theme.palette.primary.contrastText,
        },
        addUserButton: {
            height: 40,
            padding: `0 ${theme.spacing(4)}px`,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.dark;
                }
                return theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main;
            },
            color: theme.palette.info.contrastText,
            transition: '0.2s',
            '&:hover': {
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.dark;
                    }
                    return theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                },
                opacity: 0.6,
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '40%',
            margin: () => {
                if (
                    isSGIFFOXXKInstance ||
                    isHectorStyles ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                ) {
                    return `0 ${theme.spacing(4)}px 0 0`;
                }
                return 0;
            },
            '& label': {
                fontSize: '2.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                margin: () => {
                    if (
                        isSGIFFOXXKInstance ||
                        isHectorStyles ||
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return `0 ${theme.spacing(1)}px 0 0`;
                    }
                },
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor:
                THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});
