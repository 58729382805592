import React, { BaseSyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { FormState, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    IconButton,
    Grid,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Link,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import {
    useNotificationRowStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from './notifications-styles';

import { NotificationsRowFormData } from './notifications-row';

interface NotificationRowEmailsProps {
    emails: { id?: string; name: string }[];
    isNotificationUploadLoading: boolean;
    handleAddEmail: () => void;
    formState: FormState<NotificationsRowFormData>;
    register: UseFormRegister<NotificationsRowFormData>;
    handleChangeEmail: (e: BaseSyntheticEvent) => void;
    updateRow: () => void;
    handleRemoveEmail: (index: number) => void;
    registerName: string;
    startName: string;
}

export const NotificationRowEmails = (props: NotificationRowEmailsProps): JSX.Element => {
    const classes = useNotificationRowStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const { t } = useTranslation(['user-management']);

    const {
        emails,
        isNotificationUploadLoading,
        handleAddEmail,
        formState,
        register,
        handleChangeEmail,
        updateRow,
        handleRemoveEmail,
        registerName,
        startName,
    } = props;

    const [allShown, setAllShown] = useState<boolean>(false);

    const handleToggleEmailsShown = (): void => {
        setAllShown(!allShown);
    };

    const onAddEmail = (): void => {
        setAllShown(true);
        handleAddEmail();
    };

    return (
        <>
            {emails.length === 0 && (
                <IconButton
                    disabled={isNotificationUploadLoading}
                    className={clsx(classes.iconButton, classes.iconAddButton)}
                    onClick={onAddEmail}
                    style={{ marginTop: 20 }}
                >
                    <AddIcon />
                </IconButton>
            )}
            {(allShown ? emails : emails.slice(0, 1)).map((field, index) => {
                return (
                    <Grid
                        key={field.id}
                        container
                        spacing={2}
                        alignItems='center'
                        wrap='nowrap'
                        className={classes.emailsContainer}
                    >
                        <Grid item sm={8}>
                            <FormControl
                                fullWidth
                                error={Boolean(
                                    Array.isArray(
                                        formState?.errors?.furtherReceiversEmails
                                    )
                                        ? formState?.errors?.furtherReceiversEmails[index]
                                        : null
                                )}
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            disabled={isNotificationUploadLoading}
                                            type='email'
                                            classes={inputClasses}
                                            {...register(
                                                `${registerName}.${index}.name` as keyof NotificationsRowFormData
                                            )}
                                            onChange={handleChangeEmail}
                                            value={
                                                !field.name.startsWith(startName)
                                                    ? field.name
                                                    : ''
                                            }
                                            onBlur={updateRow}
                                        />
                                    }
                                    label={t(
                                        'user-management:notificationsSection.email'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <IconButton
                            disabled={isNotificationUploadLoading}
                            className={clsx(classes.iconButton, classes.iconDeleteButton)}
                            onClick={() => handleRemoveEmail(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                        {index === 0 && (
                            <IconButton
                                disabled={isNotificationUploadLoading}
                                className={clsx(
                                    classes.iconButton,
                                    classes.iconAddButton
                                )}
                                onClick={onAddEmail}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </Grid>
                );
            })}
            {emails.length > 1 && (
                <Link className={classes.showMoreLink} onClick={handleToggleEmailsShown}>
                    {allShown
                        ? t('user-management:notificationsSection.hideEmails')
                        : t('user-management:notificationsSection.showEmails')}
                </Link>
            )}
        </>
    );
};

NotificationRowEmails.defaultProps = {
    emails: [],
    isNotificationUploadLoading: false,
    handleAddFurtherReceiverEmail: () => null,
    formState: {},
    register: () => null,
    handleChangeEmail: () => null,
    updateRow: () => null,
    handleRemoveFurtherReceiverEmail: () => null,
    name: '',
};
