import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { List, ListItem, ListItemText, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { useISStyle } from './contract-details-view-is-style';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewISResponseBody } from './contract-details-view-is-response-body';

interface ContractDetailsViewISProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewIS = (props: ContractDetailsViewISProps): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const ISClasses = useISStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] = useState<ContractDetailsViewISResponseBody>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewISResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewISResponseBody>(
                `contracts/${id}/is`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewISResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {!isLoadingDetails && detailsData && !isDetailDataEmpty && (
                <>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '50%' }}
                    >
                        {(detailsData?.fireInsuranceProperty ||
                            detailsData?.fireMainOperatingMode) && (
                            <ListItem className={ISClasses.subtitle}>
                                <ListItemText data-title>
                                    {t('contract-details-view:contractDetails.fire')}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            value={detailsData?.fireInsuranceProperty}
                            title='contract-details-view:contractDetails.insuranceProperty'
                            hidden={!detailsData?.fireInsuranceProperty}
                        />
                        <ContractDetailsListItem
                            value={detailsData?.fireMainOperatingMode}
                            title='contract-details-view:contractDetails.mainOperatingMode'
                            hidden={!detailsData?.fireMainOperatingMode}
                        />

                        <List>
                            <ContractDetailsListItem
                                value={detailsData?.year ? String(detailsData?.year) : ''}
                                title='contract-details-view:contractDetails.year'
                                hidden={!detailsData?.year}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.maliciousDamage
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.maliciousDamage'
                                hidden={!detailsData?.maliciousDamage}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.vehicleImpactAndSmoke
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.vehicleImpactAndSmoke'
                                hidden={
                                    typeof detailsData?.vehicleImpactAndSmoke !==
                                    'boolean'
                                }
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.sprinklerSystem
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.sprinklerSystem'
                                hidden={!detailsData?.sprinklerSystem}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.tapWater
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.tapWater'
                                hidden={!detailsData?.tapWater}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.storm ? t('common:yes') : t('common:no')
                                }
                                title='contract-details-view:contractDetails.storm'
                                hidden={!detailsData?.storm}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.hail ? t('common:yes') : t('common:no')
                                }
                                title='contract-details-view:contractDetails.hail'
                                hidden={!detailsData?.hail}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.lossOfRent
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.lossOfRent'
                                hidden={!detailsData?.lossOfRent}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.unnamedDangers
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.unnamedDangers'
                                hidden={!detailsData?.unnamedDangers}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.elementary
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.elementary'
                                hidden={!detailsData?.elementary}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.flooding
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.floodingOfTheInsuredLocation'
                                hidden={!detailsData?.flooding}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.earthquake
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.earthquake'
                                hidden={!detailsData?.earthquake}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.subsidenceOrLandslide
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.subsidenceOrLandslide'
                                hidden={
                                    typeof detailsData?.subsidenceOrLandslide !==
                                    'boolean'
                                }
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.backwater
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.backwater'
                                hidden={!detailsData?.backwater}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.earthquake
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.snowPressureOrAvalanches'
                                hidden={!detailsData?.earthquake}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.volcanicEruption
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.volcanicEruption'
                                hidden={!detailsData?.volcanicEruption}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.graffiti
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                title='contract-details-view:contractDetails.graffiti'
                                hidden={!detailsData?.graffiti}
                            />
                            <ContractDetailsListItem
                                value={
                                    detailsData?.edOrV ? t('common:yes') : t('common:no')
                                }
                                title='contract-details-view:contractDetails.edOrV'
                                hidden={!detailsData?.edOrV}
                            />
                        </List>
                    </List>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '50%' }}
                    >
                        {(detailsData?.ecInsuranceProperty ||
                            detailsData?.ecMainOperatingMode) && (
                            <ListItem className={ISClasses.subtitle}>
                                <ListItemText data-title>
                                    {t('contract-details-view:contractDetails.ec')}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            value={
                                detailsData?.ecInsuranceProperty
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.insuranceProperty'
                            hidden={!detailsData?.ecInsuranceProperty}
                        />
                        <ContractDetailsListItem
                            value={
                                detailsData?.ecMainOperatingMode
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.mainOperatingMode'
                            hidden={!detailsData?.ecMainOperatingMode}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
