import { appInstance, hectorStyleInstances } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { useContractsAonMotorStyles } from './contract-aon-motor-styles';
import { useContractsAonStyles } from './contract-aon-styles';
import { useContractsHectorStyles } from './contract-hector-styles';
import { useContractsSgiffoxxkStyles } from './contract-sgiffoxxk-styles';

const constractStylesInstance = {
    [AppInstances.AON]: useContractsAonStyles,
    [AppInstances.AON_KOMPOSIT]: useContractsAonStyles,
    [AppInstances.AON_MOTOR]: useContractsAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useContractsAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useContractsSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useContractsSgiffoxxkStyles,
    [AppInstances.HECTOR_DEMO]: useContractsAonMotorStyles,
};

hectorStyleInstances.forEach(
    (instance) => (constractStylesInstance[instance] = useContractsHectorStyles)
);

interface useStyleProps {
    isDarkMode: boolean;
}

export const useStyle = (props: useStyleProps): ClassNameMap => {
    return constractStylesInstance[appInstance](props);
};
