import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, Typography } from '@material-ui/core';

import { UserManagementNavigationPanel } from 'src/user-management/user-management-navigation-panel/user-management-navigation-panel';

import { ThemeLoader } from 'src/theming';

import { NotificationsDto } from './notifications-dto';

import NotificationsRow from './notifications-row';

import { useStyle } from './notifications-styles';

import { NotificationRow } from './notifications-row';
import { notificationGetAdapter } from './notification-get-adapter';

import { useNotificationDivisions } from './notification-use-divisions-for-triggers';

export const Notifications = (): JSX.Element => {
    const { t } = useTranslation(['divisions']);
    const httpClient = useHttpClient();

    const divisions = useNotificationDivisions();

    const [isReceivingTriggersLoading, setReceivingTriggersLoading] = useState(false);
    const [isMounted, setMounted] = useState(false);
    const [data, setData] = useState<NotificationRow[]>();
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

    const ref = useRef(null);

    const [rowsMaxHeight, setRowsMaxHeight] = useState(0);

    const classes = useStyle({ rowsMaxHeight });

    const handleAddExpandedRow = (id: string): void => {
        const updatedExpandedRows = new Set(Array.from(expandedRows));
        updatedExpandedRows.add(id);
        setExpandedRows(updatedExpandedRows);
    };

    const handleDeleteExpandedRow = (id: string): void => {
        const updatedExpandedRows = new Set(Array.from(expandedRows));
        updatedExpandedRows.delete(id);
        setExpandedRows(updatedExpandedRows);
    };

    const makeRequestForTrigger = (): Promise<NotificationsDto[]> => {
        setReceivingTriggersLoading(true);
        return httpClient.get('notification-triggers');
    };

    const refetchNotifications = async (): Promise<void> => {
        try {
            const response = await makeRequestForTrigger();
            setData(notificationGetAdapter(response));
        } finally {
            setReceivingTriggersLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted) {
            refetchNotifications();
        }
    }, [isMounted]);

    useEffect(() => {
        if (ref.current) {
            setRowsMaxHeight(ref.current?.offsetHeight - 100);
            setMounted(true);
        }
    }, [ref]);

    const formattedDivisions = useMemo(() => {
        if (Array.isArray(divisions) && divisions?.length > 0) {
            return divisions.map((item) => ({
                title: item.name,
                value: item.id,
                titleAsKeyForTranslation: true,
            }));
        }
        return [];
    }, [divisions]);

    return (
        <Grid container className={classes.notificationsContainer}>
            <Grid container item className={classes.toolBar}>
                <UserManagementNavigationPanel activeMenu='notifications' />
            </Grid>
            <Grid container item className={classes.header}>
                <Typography variant='h5' className={classes.title}>
                    {t('user-management:notificationsSection.title')}
                </Typography>
            </Grid>
            <Grid container ref={ref} item className={classes.content}>
                {isReceivingTriggersLoading && <ThemeLoader />}
                {!isReceivingTriggersLoading && data && (
                    <>
                        <Grid
                            item
                            container
                            spacing={2}
                            className={classes.columnTitlesContainer}
                        >
                            <Grid item style={{ width: '15%' }}>
                                <Typography variant='h6' className={classes.columnTitle}>
                                    {t('user-management:notificationsSection.trigger')}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '15%' }}>
                                <Typography variant='h6' className={classes.columnTitle}>
                                    {t('user-management:notificationsSection.receiver')}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '20%' }}>
                                <Typography variant='h6' className={classes.columnTitle}>
                                    {t('user-management:notificationsSection.fallback')}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '15%' }}>
                                <Typography variant='h6' className={classes.columnTitle}>
                                    {t('user-management:notificationsSection.customer')}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '20%' }}>
                                <Typography variant='h6' className={classes.columnTitle}>
                                    {t(
                                        'user-management:notificationsSection.furtherReceivers'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: '15%' }}>
                                <Typography
                                    variant='h6'
                                    align='center'
                                    className={classes.columnTitle}
                                >
                                    {t(
                                        'user-management:notificationsSection.displayNotifications'
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.rowsContainer}>
                            {data.map((row) => {
                                return (
                                    <NotificationsRow
                                        key={row.id}
                                        {...row}
                                        refetchNotifications={refetchNotifications}
                                        divisions={formattedDivisions}
                                        expandedRows={expandedRows}
                                        handleAddExpandedRow={handleAddExpandedRow}
                                        handleDeleteExpandedRow={handleDeleteExpandedRow}
                                    />
                                );
                            })}
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
