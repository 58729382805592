import React from 'react';
import { Layer } from '@nivo/line';

export const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }): Layer[] => {
    return series.map(({ id, data, color, isDashed }) => (
        <path
            key={id}
            d={lineGenerator(
                data.map((d) => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill='none'
            stroke={color}
            style={
                isDashed === true
                    ? {
                          strokeDasharray: '5, 6',
                          strokeWidth: 1,
                      }
                    : {
                          strokeWidth: 2,
                      }
            }
        />
    ));
};
