import moment from 'moment';

import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormAONKompositUniversalTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-universal-types';

export const damageReportFormUniversalGetAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState?: string
): DamageReportFormAONKompositUniversalTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;

    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        files: [],
        fileInfos: data?.fileInfos,
        damage: data?.universalDamageInfo?.damage,
        damageDescription: data?.universalDamageInfo?.damageDescription,
        rootCause: data?.universalDamageInfo?.rootCause,
    };
};
