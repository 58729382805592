import React, { SyntheticEvent, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IconButton, Typography } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';
import { Moment } from 'moment';

interface ThemeDatePickerProps {
    name: string;
    value: ParsableDate;
    editMode?: boolean;
    onChange?: (name: string, value: Moment) => void;
    placeholder?: string;
    className?: string;
    iconButtonTrigger?: JSX.Element;
    inputVariant?: 'standard' | 'filled' | 'outlined';
    error?: boolean;
    maxDate?: ParsableDate;
    maxDateErrorMessage?: string;
}

export const ThemeDatePicker = (props: ThemeDatePickerProps): JSX.Element => {
    const {
        value,
        placeholder,
        onChange,
        name,
        className,
        iconButtonTrigger,
        inputVariant,
        editMode,
        error,
        maxDate,
        maxDateErrorMessage,
    } = props;
    const [open, setOpen] = useState(false);

    const handleChangeDate = (date): void => {
        onChange(name, date);
    };
    const handleOpen = (): void => {
        if (value) {
            handleChangeDate(null);
        } else {
            setOpen(true);
        }
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleClearDate = (): void => {
        onChange(name, null);
    };

    const handleStopPropagation = (e: SyntheticEvent): void => {
        e.stopPropagation();
    };

    let keyboardIcon = null;

    if (editMode) {
        keyboardIcon = !value ? (
            <CalendarTodayIcon style={{ width: 15, height: 15 }} fontSize='small' />
        ) : (
            <CloseIcon style={{ width: 20, height: 20 }} />
        );
    }

    return (
        <div
            className={className}
            tabIndex={0}
            role='button'
            onClick={handleStopPropagation}
            onKeyPress={handleStopPropagation}
        >
            {iconButtonTrigger && (
                <>
                    {!value && (
                        <IconButton size='small' onClick={handleOpen}>
                            {iconButtonTrigger}
                        </IconButton>
                    )}
                    {value && (
                        <IconButton onClick={handleClearDate} size='small'>
                            <CloseIcon />
                        </IconButton>
                    )}
                </>
            )}
            <KeyboardDatePicker
                autoOk
                error={error || value > maxDate}
                autoComplete='off'
                disabled={!editMode}
                inputVariant={inputVariant ? inputVariant : null}
                open={open}
                value={value}
                placeholder={placeholder}
                onChange={handleChangeDate}
                format='DD.MM.yyyy'
                onClose={handleClose}
                views={['year', 'month', 'date']}
                invalidDateMessage=''
                minDateMessage=''
                maxDateMessage={
                    maxDateErrorMessage ? (
                        <Typography>{maxDateErrorMessage}</Typography>
                    ) : (
                        ''
                    )
                }
                maxDate={maxDate ?? new Date('2100-01-01')}
                onOpen={handleOpen}
                keyboardIcon={keyboardIcon}
                DialogProps={{
                    disableScrollLock: true,
                }}
            />
        </div>
    );
};

ThemeDatePicker.defaultProps = {
    inputVariant: 'standard',
    editMode: true,
};
