import { DivisionWidgetDto } from 'src/user-management/context-auth-types';
import { Card } from './generate-divisions';

export const contractsDashboardPrepareDivisionsForSaving = (
    divisionsFromUserSettings: DivisionWidgetDto[],
    favoriteDivisions: Set<string>,
    cards: Card[]
): DivisionWidgetDto[] => {
    const formattedDivisions = divisionsFromUserSettings.map((item) => {
        return {
            ...item,
            selected: false,
            favourite: favoriteDivisions.has(item.value),
            position: cards.findIndex((card) => card.key === item.value),
        };
    });
    return formattedDivisions;
};
