import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from '../themes';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles(() => {
    return {
        expansionPanelContainer: {
            width: '100%',
        },
    };
});

export const useAccordionStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        root: {
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark;
                }
                return theme.palette.primary.light;
            },
            borderBottom: `1px solid ${theme.palette.grey.A100}`,
            borderRadius: `0!important`,
        },
        expanded: {
            margin: `0!important`,
        },
    };
});

export const useAccordionActionsStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            padding: 0,
            '& .MuiButtonBase-root': {
                padding: theme.spacing(1),
            },
            '& .MuiSvgIcon-root': {
                color: () => {
                    if (
                        (isAonDigitalInstance ||
                            isAonMotorInstance ||
                            isHectorDemoInstance) &&
                        isDarkMode
                    ) {
                        return theme.palette.primary.contrastText;
                    }
                },
            },
        },
    };
});

export const useAccordionSummaryStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'row-reverse',
            gap: 10,
            color: theme.palette.primary.contrastText,
            cursor: ({ readonly }: { readonly: boolean }) =>
                readonly ? 'pointer' : 'grab!important',
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        content: {
            alignItems: 'center',
            '& .MuiCircularProgress-root': {
                color: theme.palette.info.main,
            },
        },
    };
});

export const useAccordionDetailsStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: `${theme.spacing(1)}px ${theme.spacing(1)}rem ${theme.spacing(4)}px`,
            fontSize: '1.4rem',
            color:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.grey['300']
                    : theme.palette.grey['600'],
        },
    };
});
