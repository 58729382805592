import useDarkMode from 'use-dark-mode';
import { ThemeLayout } from 'src/theming';
import { APP_MODULES } from 'src/shared/constants';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { TwoFactorSection } from 'src/pages/user-settings/two-factor/two-factor-section';
import { useStyle } from 'src/pages/user-settings/user-settings-styles';

export const UserSettingsPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    const classes = useStyle();
    const { t } = useTranslation(['common', 'user-settings']);

    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.USER_SETTINGS}>
            <Grid container className={classes.mainContainer}>
                <Grid container className={classes.header}>
                    <Typography variant='h5' className={classes.title}>
                        {t('user-settings:title')}
                    </Typography>
                </Grid>
                <Grid container className={classes.configSection}>
                    <TwoFactorSection />
                </Grid>
            </Grid>
        </ThemeLayout>
    );
};
