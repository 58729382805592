import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from './faq-question-form-styles';

import { faqQuestionFormValidationSchema } from './faq-question-form-validation-schema';
import {
    baseUrl,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

type AddNewQuestionFormData = {
    question: string;
    dirty: boolean;
};

interface FAQQuestionFormProps {
    cbAfterSuccessfulCreation: () => void;
    initialData: null | { content: string; header: string; id: string };
    handleClearEditData: () => void;
}

export const FAQQuestionForm = (props: FAQQuestionFormProps): JSX.Element => {
    const { cbAfterSuccessfulCreation, initialData, handleClearEditData } = props;

    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const classes = useStyle();

    const httpClient = useHttpClient();

    const { t } = useTranslation(['faq', 'common']);

    const [data, setData] = useState('');
    const [isOpen, setOpen] = useState(false);
    const [isDirtyEditor, setDirtyEditor] = useState(false);
    const [isRequestLoading, setRequestLoading] = useState(false);

    const { watch, setValue, reset, formState, trigger } =
        useForm<AddNewQuestionFormData>({
            mode: 'onChange',
            defaultValues: { question: '' },
            resolver: yupResolver(faqQuestionFormValidationSchema),
        });

    useEffect(() => {
        if (initialData?.id) {
            setData(initialData.content);
            setValue('question', initialData.header);
            setOpen(true);
            trigger();
        } else {
            setDirtyEditor(false);
            reset({ question: '' });
            setData('');
            handleClearEditData();
        }
    }, [initialData]);

    const handleCloseModalWindow = (): void => {
        setDirtyEditor(false);
        reset({ question: '' });
        handleClearEditData();
        setData('');
        setOpen(false);
    };

    const handleOpenModalWindow = (): void => setOpen(true);

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value, { shouldDirty: true });
        trigger();
    };

    const handleSave = (): void => {
        setRequestLoading(true);
        (!initialData?.id
            ? httpClient.post('faqs', { question: questionValue, answer: data })
            : httpClient.put(`faqs/${initialData.id}`, {
                  question: questionValue,
                  answer: data,
              })
        )
            .then(() => {
                handleCloseModalWindow();
                if (cbAfterSuccessfulCreation) {
                    cbAfterSuccessfulCreation();
                }
            })
            .finally(() => {
                setRequestLoading(false);
            });
    };

    const [questionValue] = watch(['question']);

    return (
        <>
            <Button
                variant='contained'
                color='secondary'
                onClick={handleOpenModalWindow}
                className={clsx({
                    [classes.addFaqBtn]:
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance,
                })}
            >
                {t('addQuestion')}
            </Button>
            <ThemeModalWindow isOpen={isOpen} onClose={handleCloseModalWindow}>
                <Typography variant='h5' className={classes.modalTitle}>
                    {t('addNewQuestion')}
                </Typography>
                <Grid container>
                    <FormControl
                        fullWidth
                        error={Boolean(formState?.errors.question)}
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    classes={inputClasses}
                                    name='question'
                                    onChange={handleChangeValue}
                                    value={questionValue}
                                />
                            }
                            label={t('question')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <div className={classes.ckEditorContainer}>
                        <FormLabel error={!data} className={classes.ckEditorLabel}>
                            {t('answer')}
                        </FormLabel>
                        <CKEditor
                            editor={ClassicEditor}
                            data={data}
                            config={{
                                ckfinder: {
                                    uploadUrl: `${baseUrl}/api/faqs/upload-file`,
                                },
                            }}
                            onChange={(_: BaseSyntheticEvent, editor: ClassicEditor) => {
                                setDirtyEditor(true);
                                const nextData = editor.getData();
                                setData(nextData);
                            }}
                        />
                    </div>
                </Grid>
                <Grid container className={classes.modalActionsContainer}>
                    <Button
                        disableElevation
                        variant='contained'
                        className={clsx(classes.modalButton, classes.saveButton)}
                        onClick={handleSave}
                        disabled={
                            (!isDirtyEditor && !formState.isDirty) ||
                            !formState.isValid ||
                            isRequestLoading ||
                            !data
                        }
                        startIcon={<ThemeCircularProgress isLoading={isRequestLoading} />}
                    >
                        {t('common:save')}
                    </Button>
                    <Button
                        variant='outlined'
                        className={clsx(classes.modalButton, classes.cancelButton)}
                        onClick={handleCloseModalWindow}
                    >
                        {t('common:cancel')}
                    </Button>
                </Grid>
            </ThemeModalWindow>
        </>
    );
};
