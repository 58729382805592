import moment from 'moment';

import { TransportInfo } from '../../damage-report-form-selection-items/damage-report-form-generate-AON-transp-information-selection-options';
import {
    DamageReportFormAONKompositTransportMutation,
    DamageReportFormAONKompositTranspTypes,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-transport-types';

export const damageReportFormTransportMutateAdapter = (
    data: DamageReportFormAONKompositTranspTypes
): DamageReportFormAONKompositTransportMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    const isTransportChecked = data?.transportInformation?.some(
        (item) => item.name === TransportInfo.TRANSPORT
    );
    const isStorageChecked = data?.transportInformation?.some(
        (item) => item.name === TransportInfo.STORAGE
    );
    const isExhibitionChecked = data?.transportInformation?.some(
        (item) => item.name === TransportInfo.EXHIBITION
    );
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        transpСlientInfo: {
            policyNumber: data.policyOrCertificateNumber,
            insuredCompanyFileNumber: data.insuredCompanyFileNumber,
            ibanCompensation: data.ibanCompensation,
            bank: data.bank,
            bic: data.bic,
            accountOwner: data.accountOwner,
        },
        broadcastInfo: {
            isTotalLoss: data.totalLoss,
            isConsignmentComplete: data.consignmentComplete,
            isOutwardlyDamageShipment: data.outwardlyDamageShipment,
            containerNumber: data.containerNumber,
        },
        originalSeal: {
            isAvailableUponArrival: data?.availableUponArrival,
            isDamaged: data.damaged,
            leadSealOrSeal: data.leadSealOrSeal,
        },
        damageAssessmen: {
            deliverType: data.damageAssessment,
            atThe: data.atThe,
            hasCarrierGivenPureReceipt: data.carrierForwardingAgentBeenGivenPureReceipt,
            isAverageCommissionerCalledIn: data.averageCommissionerWasCalledIn,
            damageDescription: data.damageDescription,
            expectedDamageAmountDamage:
                data?.expectedAmountOfDamage &&
                typeof data?.expectedAmountOfDamage === 'string'
                    ? Number(
                          data?.expectedAmountOfDamage
                              .replaceAll('.', '')
                              .replace(',', '.')
                      )
                    : '',
        },
        transpDocumentsInfo: {
            transpAttachedDocuments: data?.documentsAttached?.map((item) => item.name),
        },
        transpDamageInfo: {
            damageType: data.typeOfDamage,
            departureDate: data.departureDate,
            isTransport: isTransportChecked,
            isStorage: isStorageChecked,
            isExhibition: isExhibitionChecked,
            transportOf: data?.transportFrom,
            transportTo: data?.transportAfter,
            transportVia: data?.transportVia,
            storageIn: data?.storageIn,
            storageAt: data?.storageInOrAt,
            exhibition: data.exhibition,
            deliveringCarrier: data.deliveringCarrier,
            senderOrRecipient: data?.senderOrRecipient,
            insuredGoods: data?.insuredGoods,
            damagedGoods: data?.damagedGoods,
        },
    };
};
