import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { useStyle } from './theme-layout-styles';
import { ThemeHeader } from '../theme-header/theme-header';
import { DarkMode } from 'use-dark-mode';
import { APP_MODULES } from 'src/shared/constants';
import { ThemeFooter } from '../theme-footer';

interface ThemeLayoutProps {
    children: JSX.Element;
    darkMode: DarkMode;
    module?: APP_MODULES;
}

export const ThemeLayout = ({
    children,
    darkMode,
    module,
}: ThemeLayoutProps): JSX.Element => {
    const classes = useStyle();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid item className={classes.header}>
                <ThemeHeader darkMode={darkMode} module={module} />
            </Grid>
            <Grid item component='main' className={classes.main}>
                {children}
            </Grid>
            <Grid item className={classes.footer}>
                <ThemeFooter />
            </Grid>
        </Container>
    );
};
