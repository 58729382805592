import { useMutation, UseMutationResult } from 'react-query';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { CheckboxItem } from 'src/theming';
import { DashboardDataResponseType } from './dashboard-data-response-type';
import { dailyExpiringLocalStorage } from 'src/lib/utils/daily-expiring-local-storage';
import { useQuery } from '../../hooks';
import { useEffect } from 'react';
import { useGlobalFilter } from '../contexts-global-filter/contexts-global-filter';

interface Params {
    years: number[];
    division: string;
    customers: CheckboxItem[];
    customerConnections: CheckboxItem[];
}

interface FetchDashboardDataBody {
    division: string;
    customers: string[];
    damageYears: number[];
    isGlobalCustomersSearch: boolean;
}

export const buildFetchDashboardDataBody = ({
    years,
    customers,
    customerConnections,
    division,
}: Params): FetchDashboardDataBody => {
    const isAllCustomerConnectionsChecked = customerConnections.find(
        (item) => item.value === 'all'
    )?.checked;
    const isAllChecked = customers.find((item) => item.value === 'all')?.checked;
    const isGlobalCustomersSearch = isAllCustomerConnectionsChecked && isAllChecked;
    const items = isAllChecked ? customers : customers.filter((i) => i.checked);
    const names = items.map((item) => item.value).filter((item) => item !== 'all');

    return {
        division: division,
        customers: names,
        damageYears: years,
        isGlobalCustomersSearch: isGlobalCustomersSearch,
    };
};

export const useFetchDashboardData = (): UseMutationResult<DashboardDataResponseType> => {
    const httpClient = useHttpClient();
    const query = useQuery();
    const forcedDiv = query.get('division');
    const forcedIsGlobalCustomersSearch = query.get('isGlobalCustomersSearch') === 'True';
    const forcedCustomers = query.get('customers')?.split(';');
    const forcedDamageYears = query.get('damageYears')?.split(';');

    return useMutation(
        async ({ years, customers, customerConnections, division }: Params) => {
            const body = buildFetchDashboardDataBody({
                years,
                customers,
                customerConnections,
                division,
            });

            if (division && years.length > 0) {
                const storageKey = 'dashboardData';
                const subKey =
                    division + body.customers.join('') + body.damageYears.join('');
                const [loadValue, storeValue] =
                    dailyExpiringLocalStorage<DashboardDataResponseType>(
                        storageKey,
                        subKey
                    ); // cache
                if (!loadValue()) {
                    storeValue(
                        await httpClient.post<DashboardDataResponseType>('dashboard', {
                            division: forcedDiv ?? body.division,
                            customers: forcedCustomers ?? body.customers,
                            damageYears: forcedDamageYears ?? body.damageYears,
                            isGlobalCustomersSearch:
                                forcedIsGlobalCustomersSearch ??
                                body.isGlobalCustomersSearch,
                        })
                    );
                }
                return loadValue();
            }
            return null;
        }
    );
};
