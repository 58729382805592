import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useMontageStyles } from './contract-details-view-montage-style';
import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewMontageResponseBody } from './contract-details-view-montage-response-body';

interface ContractDetailsViewMontageDetailsProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}
export const ContractDetailsViewMontageDetails = (
    props: ContractDetailsViewMontageDetailsProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const { t } = useTranslation(['contract-details-view']);

    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const montageClasses = useMontageStyles();
    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();
    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewMontageResponseBody>();
    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewMontageResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewMontageResponseBody>(
                `contracts/${id}/montage`
            );
        };
    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewMontageResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    const areAnyValuesInClause = useMemo(() => {
        if (detailsData) {
            return (
                typeof detailsData?.internalUnrest === 'boolean' ||
                typeof detailsData?.lockout === 'boolean' ||
                typeof detailsData?.other === 'boolean'
            );
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            value={detailsData?.purchaser}
                            hidden={!detailsData?.purchaser}
                            title='contract-details-view:contractDetails.purchaser'
                        />
                        <ContractDetailsListItem
                            value={detailsData?.riskLocation}
                            hidden={!detailsData?.riskLocation}
                            title='contract-details-view:contractDetails.riskLocationMontage'
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedDate', {
                                date: detailsData.startOfAssembly,
                            })}
                            hidden={!detailsData?.startOfAssembly}
                            title='contract-details-view:contractDetails.startOfAssembly'
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedDate', {
                                date: detailsData?.endOfAssembly,
                            })}
                            hidden={!detailsData?.endOfAssembly}
                            title='contract-details-view:contractDetails.endOfAssembly'
                        />
                        <ContractDetailsListItem
                            value={
                                detailsData.contractPeriod
                                    ? String(detailsData.contractPeriod)
                                    : ''
                            }
                            hidden={
                                detailsData?.contractPeriod === null ||
                                detailsData?.contractPeriod === 0
                            }
                            title='contract-details-view:contractDetails.contractPeriod'
                        />
                        <ContractDetailsListItem
                            value={detailsData?.projectDescription}
                            hidden={!detailsData?.projectDescription}
                            title='contract-details-view:contractDetails.projectDescription'
                        />
                        {areAnyValuesInClause && (
                            <ListItem className={montageClasses.subtitle}>
                                <ListItemText>
                                    {t('contract-details-view:contractDetails.clause')}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            value={
                                detailsData?.internalUnrest
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            hidden={!detailsData?.internalUnrest}
                            title='contract-details-view:contractDetails.internalUnrest'
                        />
                        <ContractDetailsListItem
                            value={
                                detailsData?.lockout ? t('common:yes') : t('common:no')
                            }
                            hidden={!detailsData?.lockout}
                            title='contract-details-view:contractDetails.lockout'
                        />
                        <ContractDetailsListItem
                            value={detailsData?.other ? t('common:yes') : t('common:no')}
                            hidden={!detailsData?.other}
                            title='contract-details-view:contractDetails.other'
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.sb,
                            })}
                            hidden={detailsData?.sb === null || detailsData?.sb === 0}
                            title='contract-details-view:contractDetails.sb'
                        />
                        <ContractDetailsListItem
                            value={detailsData?.subsequentLiability}
                            hidden={!detailsData?.subsequentLiability}
                            title='contract-details-view:contractDetails.subsequentLiability'
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
