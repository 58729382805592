import React, { SyntheticEvent, memo, Ref } from 'react';
import { useDataGridStyle } from './theme-table-styles';

import {
    DataGrid,
    GridColumns,
    GridRowParams,
    GridRowsProp,
    GridSortModel,
    GridSortModelParams,
    GridRowIdGetter,
    GridOverlay,
} from '@material-ui/data-grid';

import { ThemeCircularProgress, ThemePagination } from 'src/theming';

interface ThemeTableProps {
    columns: GridColumns;
    rows: GridRowsProp;
    rowCount?: number;
    rowsPerPageOptions?: number[];
    pageSize?: number;
    sortModel?: GridSortModel;
    handleChangeSort?: (sortModelParams: GridSortModelParams) => void;
    onChangePage?: (e: SyntheticEvent, page: number) => void;
    page?: number;
    handleChangeRowsPerPage?: (e: SyntheticEvent, rowsPerPage: number) => void;
    onRowClick?: (params: GridRowParams) => void;
    withoutPagination?: boolean;
    noRowsMessage?: string;
    getRowId?: GridRowIdGetter;
    isLoading?: boolean;
    dataLoadingMessage?: string;
    infinite?: boolean;
    innerRef?: Ref<HTMLDivElement>;
    minTableHeight?: number;
    amountRecordsToShow?: number;
    amountRecordsToShowLoading?: boolean;
}

const CustomLoadingOverlay = (): JSX.Element => {
    return (
        <GridOverlay style={{ zIndex: 10 }}>
            <div>
                <ThemeCircularProgress isLoading size={52} />
            </div>
        </GridOverlay>
    );
};

export const ThemeTable = memo(
    ({
        columns,
        rows,
        rowCount,
        rowsPerPageOptions,
        pageSize,
        handleChangeSort,
        sortModel,
        onChangePage,
        page,
        getRowId,
        handleChangeRowsPerPage,
        onRowClick,
        withoutPagination,
        noRowsMessage,
        isLoading,
        infinite,
        innerRef,
        minTableHeight,
        amountRecordsToShow,
        amountRecordsToShowLoading,
        ...rest
    }: ThemeTableProps): JSX.Element => {
        const dataGridClasses = useDataGridStyle({
            withoutPagination,
            minTableHeight,
            isOnRowClick: Boolean(onRowClick),
        });

        return (
            <DataGrid
                pagination
                ref={innerRef}
                localeText={{
                    noRowsLabel: noRowsMessage,
                }}
                disableSelectionOnClick
                loading={isLoading}
                disableColumnMenu
                hideFooterPagination={withoutPagination}
                paginationMode='server'
                sortingMode='server'
                classes={dataGridClasses}
                columns={columns}
                rows={isLoading ? [] : rows ? rows : []}
                getRowId={getRowId}
                pageSize={pageSize}
                sortModel={sortModel}
                onSortModelChange={handleChangeSort}
                components={{
                    Pagination: ThemePagination,
                    LoadingOverlay: CustomLoadingOverlay,
                }}
                onRowClick={onRowClick}
                componentsProps={{
                    pagination: {
                        infinite: infinite
                            ? {
                                  currentRowsCount: rows ? rows.length : 0,
                              }
                            : undefined,
                        onChangePage: onChangePage,
                        rowsPerPage: pageSize,
                        count: rowCount,
                        page: page,
                        rowsPerPageOptions,
                        handleChangeRowsPerPage,
                        amountRecordsToShow,
                        amountRecordsToShowLoading,
                    },
                }}
                {...rest}
            />
        );
    }
);
