import React, { useState, useCallback, BaseSyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';

import {
    Grid,
    Typography,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Box,
    Button,
    FormHelperText,
    useMediaQuery,
} from '@material-ui/core';

import {
    ThemePhoneNumberInput,
    ThemeDatePicker,
    ThemeTimePicker,
    ThemeSwitcher,
    ThemeDropZone,
    ThemeCircularProgress,
    ThemeModalWindow,
} from 'src/theming';

import { useDocumentFormats } from 'src/shared/hooks';
import { buildFormData } from 'src/shared/utils';

import { nonUsersDamageReportFormDefaultState } from './non-users-damage-report-form-default-state';
import { nonUsersDamageReportFormValidationSchema } from './non-users-damage-report-form-validation-schema';

import {
    useStyle,
    useFormControlStyles,
    useInputStyles,
    useFormControlLabelStyles,
} from './non-users-damage-report-form-styles';
import { NonUsersDamageReportFormStateType } from './non-users-damage-report-form-state-type';

import { nonUsersDamageReportFormRequestAdapter } from './non-users-damage-report-form-request-adapter';

interface NonUsersDamageReportFormProps {
    handleOpenSuccessModalWindow: () => void;
    token: string;
}

export const NonUsersDamageReportForm = (
    props: NonUsersDamageReportFormProps
): JSX.Element => {
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();
    const { t } = useTranslation(['non-users-damage-report-form', 'common']);

    const isSmallModalWindow = useMediaQuery('(max-width:600px)');

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const { handleOpenSuccessModalWindow, token } = props;

    const documentFormats = useDocumentFormats();

    const [isSaveLoading, setSaveLoading] = useState<boolean>(false);
    const [licenseNumbserModalWindowError, setLicenseNumberModalWindowError] =
        useState<boolean>(false);

    const { watch, setValue, control, handleSubmit, formState, reset, trigger } =
        useForm<NonUsersDamageReportFormStateType>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            defaultValues: nonUsersDamageReportFormDefaultState,
            resolver: yupResolver(nonUsersDamageReportFormValidationSchema),
        });

    const { fields: files, remove } = useFieldArray({ control, name: 'files' });

    const [
        licensePlate,
        driverInformationFirstName,
        driverInformationSurname,
        driverInformationEmail,
        driverInformationPhoneNumber,
        accidentHostEmail,
        accidentHostFirstName,
        accidentHostPhoneNumber,
        accidentHostSurname,
        damageDay,
        damageTime,
        accidentLocation,
        wasRecordedByThePolice,
        receivingPoliceStation,
        damageDescription,
    ] = watch([
        'licensePlate',
        'driverInformationFirstName',
        'driverInformationSurname',
        'driverInformationEmail',
        'driverInformationPhoneNumber',
        'accidentHostEmail',
        'accidentHostFirstName',
        'accidentHostPhoneNumber',
        'accidentHostSurname',
        'damageDay',
        'damageTime',
        'accidentLocation',
        'wasRecordedByThePolice',
        'receivingPoliceStation',
        'damageDescription',
    ]);

    useEffect(() => {
        trigger();
    }, []);

    const handleChangeValue = useCallback(
        (e: BaseSyntheticEvent): void => {
            setValue(e.target.name, e.target.value);
            trigger();
        },
        [setValue]
    );

    const handleChangeLicenseNumber = (e: BaseSyntheticEvent): void => {
        const startPos = e.target?.selectionStart;
        const endPos = e.target?.selectionEnd;
        setValue(e.target.name, e.target.value?.toUpperCase());
        trigger();
        const timer = setTimeout(() => {
            e.target.setSelectionRange(startPos, endPos);
            clearTimeout(timer);
        }, 0);
    };

    const handleChangeDate = useCallback(
        (name, value: ParsableDate): void => {
            setValue(name, value);
            trigger();
        },
        [setValue]
    );

    const handleChangeRecordedByThePolice = useCallback((): void => {
        setValue('wasRecordedByThePolice', !wasRecordedByThePolice);
    }, [setValue, wasRecordedByThePolice]);

    const handleAddFile = useCallback(
        (newFiles: FileObject[]): void => {
            setValue('files', [...files, ...newFiles]);
        },
        [setValue, files]
    );

    const handleDeleteFile = useCallback(
        (index: number): void => {
            remove(index);
        },
        [setValue]
    );

    const handleCancel = (): void => {
        reset(nonUsersDamageReportFormDefaultState);
        trigger();
    };

    const makeRequestToSentDamageReport = (
        data: NonUsersDamageReportFormStateType
    ): Promise<void> => {
        setSaveLoading(true);
        const formattedData = nonUsersDamageReportFormRequestAdapter(token, data);
        const formData = new FormData();
        buildFormData(formData, formattedData, null);
        return httpClient.post('create-external-damage-report', formData);
    };

    const onSubmit = (data: NonUsersDamageReportFormStateType): void => {
        makeRequestToSentDamageReport(data)
            .then(() => {
                reset(nonUsersDamageReportFormDefaultState);
                handleOpenSuccessModalWindow();
            })
            .catch((response) => {
                if (
                    Array.isArray(
                        response?.error?.response?.data?.errors?.KfzLicenseNumber
                    )
                ) {
                    response.error.response.data.errors.KfzLicenseNumber.forEach(
                        (err) => {
                            if (
                                err ===
                                'Contract does not exist or has different customer connection.'
                            ) {
                                setLicenseNumberModalWindowError(true);
                            } else {
                                enqueueSnackbar(
                                    t(`non-users-damage-report-form:${err}`),
                                    {
                                        variant: 'error',
                                    }
                                );
                            }
                        }
                    );
                } else if (
                    Array.isArray(
                        response?.error?.response?.data?.errors?.confirmationModel
                    )
                ) {
                    response.error.response.data.errors.confirmationModel.forEach(
                        (err) => {
                            enqueueSnackbar(err, { variant: 'error' });
                        }
                    );
                } else if (
                    Array.isArray(
                        response?.error?.response?.data?.errors?.customerConnection
                    )
                ) {
                    response.error.response.data.errors.customerConnection.forEach(
                        (err) => {
                            enqueueSnackbar(err, { variant: 'error' });
                        }
                    );
                } else {
                    enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
                }
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} className={classes.formContainer}>
                    <Typography variant='h6' className={classes.formBlockTitle}>
                        {t('damageReport')}
                    </Typography>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.licensePlate)}
                            classes={formControlClasses}
                            variant='outlined'
                            style={{ marginBottom: 5 }}
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={licensePlate}
                                        onChange={handleChangeLicenseNumber}
                                        name='licensePlate'
                                    />
                                }
                                label={t('licensePlate')}
                                labelPlacement='top'
                            />
                        </FormControl>
                        <FormHelperText className={classes.helpText}>
                            {t('licenseNumberHint')}
                        </FormHelperText>
                    </Grid>
                    <Typography variant='h6' className={classes.formBlockTitle}>
                        {t('driverInformation')}
                    </Typography>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.driverInformationFirstName)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={driverInformationFirstName}
                                        onChange={handleChangeValue}
                                        name='driverInformationFirstName'
                                    />
                                }
                                label={t('firstName')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.driverInformationSurname)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={driverInformationSurname}
                                        onChange={handleChangeValue}
                                        name='driverInformationSurname'
                                    />
                                }
                                label={t('surname')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.driverInformationEmail)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={driverInformationEmail}
                                        onChange={handleChangeValue}
                                        name='driverInformationEmail'
                                    />
                                }
                                label={t('email')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemePhoneNumberInput
                                        error={Boolean(
                                            formState?.errors
                                                ?.driverInformationPhoneNumber
                                        )}
                                        value={driverInformationPhoneNumber}
                                        name='driverInformationPhoneNumber'
                                        onChange={handleChangeValue}
                                    />
                                }
                                label={t('phoneNumber')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Typography variant='h6' className={classes.formBlockTitle}>
                        {t('accidentHost')}
                    </Typography>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={accidentHostFirstName}
                                        onChange={handleChangeValue}
                                        name='accidentHostFirstName'
                                    />
                                }
                                label={t('firstNameOptional')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={accidentHostSurname}
                                        onChange={handleChangeValue}
                                        name='accidentHostSurname'
                                    />
                                }
                                label={t('surnameOptional')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={accidentHostEmail}
                                        onChange={handleChangeValue}
                                        name='accidentHostEmail'
                                    />
                                }
                                label={t('emailOptional')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemePhoneNumberInput
                                        value={accidentHostPhoneNumber}
                                        name='accidentHostPhoneNumber'
                                        onChange={handleChangeValue}
                                    />
                                }
                                label={t('phoneNumberOptional')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Typography variant='h6' className={classes.formBlockTitle}>
                        {t('damageInformation')}
                    </Typography>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            className={classes.dateField}
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeDatePicker
                                        error={Boolean(formState?.errors?.damageDay)}
                                        inputVariant='outlined'
                                        value={damageDay}
                                        name='damageDay'
                                        onChange={handleChangeDate}
                                    />
                                }
                                label={t('damageDay')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            className={classes.dateField}
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeTimePicker
                                        error={Boolean(formState?.errors?.damageTime)}
                                        editMode={true}
                                        value={damageTime}
                                        name='damageTime'
                                        onChange={handleChangeDate}
                                    />
                                }
                                label={t('time')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            error={Boolean(formState?.errors?.accidentLocation)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        classes={inputClasses}
                                        value={accidentLocation}
                                        onChange={handleChangeValue}
                                        name='accidentLocation'
                                    />
                                }
                                label={t('accidentLocation')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            className={classes.switcherFormControl}
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeSwitcher
                                        checked={!wasRecordedByThePolice}
                                        onChange={handleChangeRecordedByThePolice}
                                        leftLabel={t('common:yes')}
                                        rightLabel={t('common:no')}
                                    />
                                }
                                label={t('recordedByThePolice')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    {wasRecordedByThePolice && (
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <OutlinedInput
                                            classes={inputClasses}
                                            value={receivingPoliceStation}
                                            onChange={handleChangeValue}
                                            name='receivingPoliceStation'
                                        />
                                    }
                                    label={t('receivingPoliceStation')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                    )}
                    <Typography variant='h6' className={classes.formBlockTitle}>
                        {t('damageAttachmentsDescription')}
                    </Typography>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                            className={classes.textarea}
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        multiline
                                        rows={5}
                                        error={Boolean(
                                            formState?.errors?.damageDescription
                                        )}
                                        classes={inputClasses}
                                        value={damageDescription}
                                        onChange={handleChangeValue}
                                        name='damageDescription'
                                    />
                                }
                                label={t('descriptionText')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemeDropZone
                                        onAdd={handleAddFile}
                                        onRemove={handleDeleteFile}
                                        fileObjects={files}
                                        formats={documentFormats}
                                    />
                                }
                                label={t('attachments')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} container className={classes.buttonsContainer}>
                        <Box mr={4}>
                            <Button
                                type='submit'
                                disabled={!formState.isValid || isSaveLoading}
                                className={clsx(classes.modalButton, classes.saveButton)}
                                startIcon={
                                    <ThemeCircularProgress isLoading={isSaveLoading} />
                                }
                            >
                                {t('common:send')}
                            </Button>
                        </Box>
                        <Button
                            disabled={isSaveLoading}
                            variant='outlined'
                            onClick={handleCancel}
                            className={clsx(classes.modalButton, classes.cancelButton)}
                        >
                            {t('common:cancel')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <ThemeModalWindow
                width={isSmallModalWindow ? 200 : 600}
                isOpen={licenseNumbserModalWindowError}
                onClose={() => setLicenseNumberModalWindowError(false)}
            >
                <></>
                <p className={classes.modalWindowError}>
                    {t(
                        'non-users-damage-report-form:Contract does not exist or has different customer connection.'
                    )}
                </p>
            </ThemeModalWindow>
        </>
    );
};
