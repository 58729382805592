import { makeStyles, Theme } from '@material-ui/core';

import { THEME_MODES } from '..';
import { LIGHT_HECTOR_THEME_COLORS } from '../themes/hector/hector-theme-colors';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
    isMaasInstance,
    isHectorStyles,
} from 'src/environment';
import { LIGHT_CEAT_THEME_COLORS } from '../themes/ceat';

export const useThemeSearchFieldStyles = makeStyles((theme: Theme) => {
    const isLightMode = theme.palette.type === THEME_MODES.LIGHT;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            searchButton: {
                height: 50,
                width: 50,
                borderRadius: 0,
                backgroundColor: isLightMode
                    ? theme.palette.secondary.dark
                    : 'rgba(0, 0, 0, 0)',
                border: isLightMode
                    ? 'none'
                    : `1px solid ${theme.palette.secondary.contrastText}`,
                transition: '0.2s',
                '& .MuiSvgIcon-root': {
                    width: 35,
                    height: 35,
                    color: theme.palette.secondary.contrastText,
                },
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.dark
                        : theme.palette.primary.main,
                },
                '&.Mui-disabled': {
                    opacity: 0.8,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            searchButton: {
                height: 50,
                width: 50,
                borderRadius: 5,
                backgroundColor: isLightMode
                    ? theme.palette.common.white
                    : theme.palette.secondary.main,
                border: isLightMode
                    ? 'none'
                    : `1px solid ${theme.palette.secondary.contrastText}`,
                transition: '0.2s',
                '& .MuiSvgIcon-root': {
                    width: 35,
                    height: 35,
                    color: isLightMode
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.contrastText,
                },
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: isLightMode
                        ? theme.palette.secondary.contrastText
                        : theme.palette.secondary.main,
                },
                '&.Mui-disabled': {
                    opacity: 0.8,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                },
            },
        };
    }
    if (isHectorStyles) {
        return {
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            searchButton: {
                height: 50,
                width: 50,
                borderRadius: 0,
                backgroundColor: theme.palette.common.white,
                transition: '0.2s',
                '& .MuiSvgIcon-root': {
                    width: 35,
                    height: 35,
                    color: () => {
                        if (isLightMode)
                            return theme.palette.secondary.main;

                        if (isHectorInstance)
                            return LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR;

                        if (isMaasInstance)
                            return LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR;

                        if (isCeatInstance || isCeatMaklerInstance)
                            return LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR;
                    },
                },
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: isLightMode
                        ? theme.palette.primary.main
                        : theme.palette.common.white,
                },
                '&.Mui-disabled': {
                    opacity: 0.8,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                },
            },
        };
    }
    return {
        searchIcon: {
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        searchButton: {
            height: 40,
            padding: `0 ${theme.spacing(4)}px`,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            color: theme.palette.info.contrastText,
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
            },
            '&.Mui-disabled': {
                opacity: 0.8,
                color: theme.palette.secondary.contrastText,
            },
        },
    };
});

export const useThemeSearchFieldInputStyles = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            root: {
                width: '100%',
                height: 50,
                backgroundColor: isLightMode
                    ? theme.palette.secondary.contrastText
                    : 'rgba(0, 0, 0, 0)',
                borderRadius: 0,
                '& fieldset': {
                    border: isLightMode
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.contrastText}`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            focused: {
                '& fieldSet': {
                    borderColor: `${theme.palette.secondary.main}!important`,
                },
            },
            input: {
                height: 50,
                boxSizing: 'border-box',
                color: theme.palette.primary.contrastText,
                '&::placeholder': {
                    fontSize: '1.8rem',
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                width: '100%',
                height: 50,
                backgroundColor: isLightMode
                    ? theme.palette.secondary.contrastText
                    : theme.palette.secondary.main,
                borderRadius: 5,
                '& fieldSet': {
                    borderColor: `${theme.palette.secondary.contrastText}!important`,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            focused: {},
            input: {
                height: 50,
                boxSizing: 'border-box',
                color: isLightMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
                '&::placeholder': {
                    fontSize: '1.8rem',
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
        };
    }
    if (isHectorStyles) {
        return {
            root: {
                width: '100%',
                height: 50,
                backgroundColor: theme.palette.secondary.contrastText,
                borderRadius: 0,
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            focused: {
                '& fieldSet': {
                    borderColor: `${theme.palette.secondary.main}!important`,
                },
            },
            input: {
                height: 50,
                boxSizing: 'border-box',
                color: theme.palette.secondary.main,
                '&::placeholder': {
                    fontSize: '1.8rem',
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
        };
    }
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: ({ isDarkMode }: { isDarkMode: boolean }) =>
                isDarkMode ? theme.palette.primary.main : theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    if (isSGIFFOXXKInstance) {
        return {
            root: {
                margin: `0 ${theme.spacing(3)}px 0 0`,
                '& label': {
                    fontSize: '2.4rem',
                },
            },
        };
    }
    if (isHectorInstance || isMaasInstance) {
        return {
            root: {
                margin: `0 ${theme.spacing(3)}px 0 0`,
                '& label': {
                    fontSize: '2.4rem',
                },
            },
        };
    }
    return {
        root: {
            margin: `0 ${theme.spacing(4)}px 0 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});
