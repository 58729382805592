import React, { ReactElement } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useTranslation } from 'react-i18next';

import { Zoom, Link } from '@material-ui/core';

import { ThemeFooter } from 'src/theming/theme-footer';

import { useUserMaasLayoutStyles } from './user-maas-layout-styles';

interface UserMaasLayoutProps {
    children: ReactElement;
    darkMode: DarkMode;
    backPath?: string;
}

export const UserMaasLayout = ({ children }: UserMaasLayoutProps): ReactElement => {
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useUserMaasLayoutStyles();
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Zoom in>
                    <div className={classes.logoContainer}>
                        <img
                            alt='logo'
                            src='/static/images/maas-logo-dark.png'
                            className={classes.logo}
                        />
                    </div>
                </Zoom>
                <Link
                    className={classes.headerLink}
                    href='https://maas-insurance.de/#Kontakt'
                    target='_blink'
                >
                    {t('user-management:contact')}
                </Link>
            </div>

            <div className={classes.formContainer}>{children}</div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.footerContainer}>
                <ThemeFooter />
            </div>
        </div>
    );
};
