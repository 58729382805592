import { NotificationRow } from './notifications-row';
import { NotificationsRowFormData } from './notifications-row';

export const notificationRowDefaultState = ({
    division,
    accountManager,
    displayNotificationClerk,
    displayNotificationCustomerAdviser,
    customers,
    customerAdviser,
    fallbackEmail,
    furtherReceiversEmail,
}: NotificationRow): NotificationsRowFormData => {
    return {
        division,
        accountManager,
        displayNotificationClerk,
        displayNotificationCustomerAdviser,
        customers:
            customers?.map((item) => {
                return {
                    name: item,
                };
            }) || [],
        customerAdvisers:
            customerAdviser?.map((item) => {
                return {
                    name: item,
                };
            }) || [],
        fallbackEmails:
            fallbackEmail?.map((item) => {
                return {
                    name: item.email,
                };
            }) || [],
        furtherReceiversEmails:
            furtherReceiversEmail?.map((item) => {
                return {
                    name: item.email,
                };
            }) || [],
    };
};
