import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';
import {
    isSGIFFOXXKInstance,
    isHectorInstance,
    isAonMotorInstance,
    isAonDigitalInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
    isMaasInstance,
} from 'src/environment';
import {
    lightPaletteForHector,
    darkPaletteForHector,
    lightHectorThemeOverrides,
    darkHectorThemeOverrides,
} from './hector';

import {
    lightPaletteForMaas,
    darkPaletteForMaas,
    lightMaasThemeOverrides,
    darkMaasThemeOverrides,
} from './maas';

import {
    lightPaletteForSGIFFOXXK,
    darkPaletteForSGIFFOXXK,
    lightSgiffoxxThemeOverrides,
    darkSgiffoxxThemeOverrides,
} from './sgiffoxx';

import {
    mainLightThemePalette,
    mainDarkThemePalette,
    lightMainThemeOverrides,
    darkThemeOverrides,
} from './main';

import {
    lightPaletteForAonMotor,
    darkPaletteForAonMotor,
    lightAonMotorThemeOverrides,
    darkAonMotorThemeOverrides,
} from './aon-motor';

import {
    lightCeatThemeOverrides,
    darkCeatThemeOverrides,
    lightPaletteForCeat,
    darkPaletteForCeat,
} from './ceat';

interface GetInstancePaletteReturn {
    lightPalette: PaletteOptions;
    darkPalette: PaletteOptions;
    lightOverrides: Overrides;
    darkOverrides: Overrides;
    fontFamily?: string;
}

export const instanceTheme = ((): GetInstancePaletteReturn => {
    if (isHectorInstance) {
        return {
            lightPalette: lightPaletteForHector,
            darkPalette: darkPaletteForHector,
            lightOverrides: lightHectorThemeOverrides as Overrides,
            darkOverrides: darkHectorThemeOverrides as Overrides,
            fontFamily: "'Open Sans', sans-serif",
        };
    }
    if (isMaasInstance) {
        return {
            lightPalette: lightPaletteForMaas,
            darkPalette: darkPaletteForMaas,
            lightOverrides: lightMaasThemeOverrides as Overrides,
            darkOverrides: darkMaasThemeOverrides as Overrides,
            fontFamily: "'Open Sans', sans-serif",
        };
    }
    if (isCeatInstance || isCeatMaklerInstance) {
        return {
            lightPalette: lightPaletteForCeat,
            darkPalette: darkPaletteForCeat,
            lightOverrides: lightCeatThemeOverrides as Overrides,
            darkOverrides: darkCeatThemeOverrides as Overrides,
            fontFamily: "'Prompt', 'Helvetica', 'Arial', sans-serif",
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            lightPalette: lightPaletteForSGIFFOXXK,
            darkPalette: darkPaletteForSGIFFOXXK,
            lightOverrides: lightSgiffoxxThemeOverrides as Overrides,
            darkOverrides: darkSgiffoxxThemeOverrides as Overrides,
            fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        };
    }
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            lightPalette: lightPaletteForAonMotor,
            darkPalette: darkPaletteForAonMotor,
            lightOverrides: lightAonMotorThemeOverrides as Overrides,
            darkOverrides: darkAonMotorThemeOverrides as Overrides,
            fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        };
    }
    return {
        lightPalette: mainLightThemePalette,
        darkPalette: mainDarkThemePalette,
        lightOverrides: lightMainThemeOverrides as Overrides,
        darkOverrides: darkThemeOverrides as Overrides,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    };
})();
