import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, List } from '@material-ui/core';
import {
    useContractDetailsViewDataStyle,
    usePaperStyle,
    useStyle,
} from './contracts-details-view-styles';
import { ContractDetailsListItem } from './contract-details-list-item';
import { ContractDetailsViewResponseBody } from './contract-details-view-response-body';

import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

const isAonKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

interface ContractsDetailsViewDataProps {
    contractData: ContractDetailsViewResponseBody;
}

export const ContractsDetailsViewData = (
    props: ContractsDetailsViewDataProps
): JSX.Element => {
    const paperClasses = usePaperStyle();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'divisions',
        'contracts-list',
    ]);

    const { contractData } = props;

    return (
        <Paper classes={paperClasses}>
            <Typography variant='h6' className={classes.cardTitle}>
                {t('contract-details-view:contractData.title')}
            </Typography>
            <List
                className={contractDetailsViewDataClasses.list}
                style={{ width: '25%' }}
            >
                <ContractDetailsListItem
                    title='contract-details-view:contractData.branch'
                    value={t(`divisions:${contractData?.divisionName}`)}
                    hidden={!contractData?.divisionName}
                />
                <ContractDetailsListItem
                    title='contract-details-view:contractData.product'
                    value={contractData?.product}
                    hidden={!contractData?.product}
                />
                <ContractDetailsListItem
                    title='contract-details-view:contractData.risk'
                    value={contractData?.risk}
                    hidden={!contractData?.risk}
                />
            </List>
            <List
                className={contractDetailsViewDataClasses.list}
                style={{ width: '25%' }}
            >
                <ContractDetailsListItem
                    title='contract-details-view:contractData.contractNumber'
                    value={contractData?.contractNumber}
                    hidden={!contractData?.contractNumber}
                />
                <ContractDetailsListItem
                    title='contract-details-view:contractData.insuranceCompany'
                    value={contractData?.insuranceCompany}
                    hidden={!contractData?.insuranceCompany}
                />

                <ContractDetailsListItem
                    title='contract-details-view:contractData.costCentre'
                    value={
                        contractData?.costCentre ? String(contractData?.costCentre) : ''
                    }
                    hidden={
                        contractData?.costCentre === null &&
                        contractData?.costCentre === 0
                    }
                />
            </List>
            <List
                className={contractDetailsViewDataClasses.list}
                style={{ width: '25%' }}
            >
                <ContractDetailsListItem
                    title='contract-details-view:contractData.startOfContract'
                    value={t('formatted-values:formattedDate', {
                        date: contractData?.startDate,
                    })}
                    hidden={!contractData?.startDate}
                />
                <ContractDetailsListItem
                    title='contract-details-view:contractData.contractExpiration'
                    value={t('formatted-values:formattedDate', {
                        date: contractData?.endDate,
                    })}
                    hidden={!contractData?.endDate}
                />
            </List>
            <List
                className={contractDetailsViewDataClasses.list}
                style={{ width: '25%' }}
            >
                <ContractDetailsListItem
                    title='contract-details-view:contractData.premiumWTaxAccToPm'
                    value={t('formatted-values:formattedCurrency', {
                        currency: contractData?.premiumInclusiveTaxes,
                    })}
                    hidden={
                        contractData?.premiumInclusiveTaxes === null ||
                        contractData?.premiumInclusiveTaxes === 0
                    }
                />
                <ContractDetailsListItem
                    title={
                        isAonKompositInstance
                            ? 'contract-details-view:contractData.annualNetPremium'
                            : 'contract-details-view:contractData.annualPremiumWithTax'
                    }
                    value={t('formatted-values:formattedCurrency', {
                        currency: contractData?.annualPremiumInclusiveTaxes,
                    })}
                    hidden={
                        contractData?.annualPremiumInclusiveTaxes === null ||
                        contractData?.annualPremiumInclusiveTaxes === 0
                    }
                />
                <ContractDetailsListItem
                    title='contract-details-view:contractData.paymentMethod'
                    value={t(
                        `contracts-list:paymentMethodRows.${contractData?.paymentMethod
                            ?.toLowerCase()
                            ?.trim()}`
                    )}
                    hidden={!contractData?.paymentMethod}
                />
            </List>
        </Paper>
    );
};
