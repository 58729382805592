import { useEffect, useState } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { CustomerDetailsViewDTO } from '../customer-details-view/customer-details-view-dto';

export const useGetCustomer = <T>(
    id: string,
    formatter?: (data: CustomerDetailsViewDTO) => T
): [boolean, CustomerDetailsViewDTO | T, () => Promise<void>] => {
    const [data, setData] = useState<CustomerDetailsViewDTO | T>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const httpClient = useHttpClient();

    const makeRequestToReceiveData = (
        customerId: string
    ): Promise<CustomerDetailsViewDTO> => {
        return httpClient.get(`customers/${customerId}`);
    };

    const refetch = async (): Promise<void> => {
        try {
            setLoading(true);
            const data = await makeRequestToReceiveData(id);
            setData(formatter ? formatter(data) : data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            refetch();
        }
    }, [id]);

    return [isLoading, data, refetch];
};
