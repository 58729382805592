import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
    isMaasInstance,
    isHectorStyles,
} from 'src/environment';
import { THEME_MODES } from 'src/theming';
import { LIGHT_HECTOR_THEME_COLORS } from 'src/theming/themes/hector/hector-theme-colors';
import { LIGHT_MAAS_THEME_COLORS } from '../themes/maas';
import { LIGHT_CEAT_THEME_COLORS } from '../themes/ceat';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            icon: {
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.dark,
                opacity: 0.6,
            },
            checkedIcon: {
                width: 16,
                height: 16,
                color: isDarkMode
                    ? theme.palette.primary.contrastText
                    : theme.palette.secondary.dark,
                opacity: 1,
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            icon: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 15,
                height: 15,
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.contrastText,
                borderRadius: '50%',
                border: 'none',
            },
            checkedIcon: {
                width: 15,
                height: 15,
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.contrastText,
                opacity: 1,
                '& .MuiSvgIcon-root': {
                    width: 13,
                    height: 11,
                },
            },
        };
    }
    if (isHectorStyles) {
        return {
            icon: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 15,
                height: 15,
                color: !isDarkMode
                    ? theme.palette.primary.main
                    : isMaasInstance
                    ? LIGHT_MAAS_THEME_COLORS.PRIMARY_MAIN_COLOR
                    : LIGHT_HECTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
                backgroundColor: !isDarkMode
                    ? theme.palette.primary.main
                    : isMaasInstance
                    ? LIGHT_MAAS_THEME_COLORS.PRIMARY_MAIN_COLOR
                    : LIGHT_HECTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
                borderRadius: '50%',
                border: 'none',
            },
            checkedIcon: {
                width: 15,
                height: 15,
                color: () => {
                    if (!isDarkMode)
                        return theme.palette.secondary.main;

                    if (isHectorInstance)
                        LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR;

                    if (isMaasInstance)
                        return LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR;

                    if (isCeatInstance || isCeatMaklerInstance)
                        return LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR;
                },
                backgroundColor: () => {
                    if (!isDarkMode)
                        return theme.palette.primary.main;

                    if (isHectorInstance)
                        return LIGHT_HECTOR_THEME_COLORS.PRIMARY_MAIN_COLOR;

                    if (isMaasInstance)
                        return LIGHT_MAAS_THEME_COLORS.PRIMARY_MAIN_COLOR;

                    if (isCeatInstance || isCeatMaklerInstance)
                        return LIGHT_CEAT_THEME_COLORS.PRIMARY_MAIN_COLOR;
                },
                opacity: 1,
                '& .MuiSvgIcon-root': {
                    width: 13,
                    height: 11,
                },
            },
        };
    }
    return {
        icon: {
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (!isHectorInstance && !isMaasInstance && !isSGIFFOXXKInstance) {
        return {
            label: {},
        };
    }
    return {
        label: {
            color: !isDarkMode
                ? theme.palette.primary.main
                : theme.palette.secondary.contrastText,
            fontSize: '1.8rem',
            fontWeight: theme.typography.fontWeightLight,
        },
    };
});
