import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardContext } from 'src/shared/contexts';
import useNivoTheme from 'src/theming/theme-nivo/use-nivo-theme';
import { useDashboardStyle } from './dashboard-style';

import { generateDashboardConfig } from './dashboard-config';
import { DashboardDraggableItem } from './dashboard-draggable-item';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';

interface Props {
    division: string;
    dashboardData: DashboardDataResponseType;
    settings?: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
    moveCard?: (dragIndex: number, hoverIndex: number, isDrop?: boolean) => void;
    handleChangeShown?: (name: string, value: boolean) => void;
    years: number[];
}

export const DivisionDashboard: FC<Props> = ({
    division,
    settings,
    moveCard,
    handleChangeShown,
    dashboardData,
    years,
}) => {
    const { editMode } = useDashboardContext();
    const classes = useDashboardStyle({ editMode });
    const nivoTheme = useNivoTheme();
    const { t } = useTranslation(['dashboard']);

    const dashboardConfig = useMemo(
        () =>
            generateDashboardConfig({
                division,
                years,
                dashboardData,
            }),
        [settings, years, dashboardData, division]
    );

    return (
        <div className={classes.dashboard}>
            {settings.map((item, index) => {
                const widget = dashboardConfig[item.dashboardWidget];
                const Component = widget?.Item;
                if (!widget) {
                    return null;
                }
                return (
                    (editMode || (item.isShown && !editMode)) && (
                        <DashboardDraggableItem
                            key={item.dashboardWidget}
                            className={widget.className}
                            moveCard={moveCard}
                            index={index}
                            card={item}
                        >
                            <Component
                                name={item.dashboardWidget}
                                handleChangeShown={handleChangeShown}
                                title={t(widget.title)}
                                nivoTheme={nivoTheme}
                                expense={widget.expense}
                                bookedPremium={widget.bookedPremium}
                                partialCoverage={widget.partialCoverage}
                                damageRatio={widget.damageRatio}
                                isShown={item.isShown}
                                years={years}
                                amount={widget.amount}
                                amounts={widget.amounts}
                            />
                        </DashboardDraggableItem>
                    )
                );
            })}
        </div>
    );
};
