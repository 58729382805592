import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
    ChangeEvent,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileObject } from 'material-ui-dropzone';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalFilter } from 'src/shared/contexts';
import { Divisions, DamageReportGroups } from 'src/shared/constants';
import { buildFormData } from 'src/shared/utils';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';

import { DamageReportFormView } from '../damage-report-form-view';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';

import { DamageReportFormTRANSPTypes } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-TRANSP-types';
import { damageReportFormTRANSPSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-TRANSP-schema';
import { damageReportFormTRANSPDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-TRANSP-default-state';

import {
    generateDisabledInputs,
    DamageReportFormConfigTRANSP,
    generateFooter,
} from '../../damage-report-form-config/damage-report-form-config-TRANSP';

import { generateVehicleBodyOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-vehicle-body-options';
import { damageReportFormGenerateTRANSPDamageTypeOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-transp-damage-type-options';
import { damageReportFormGenerateAriaLiabilityOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-aria-of-liability-options';
import { damageReportFormGenerateWhoLoadedOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-who-loaded-selection-options';
import { generateWhoDischargedSelectionsOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-who-discharged-selection-options';
import { damageReportFormGenerateWhenDamageDevelopedOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-when-damage-developed-selection-options';
import { damageReportFormGenerateWhereReservationMadeOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-where-reservation-made-selection-options';
import { damageReportFormTRANSPDocumentsOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-transp-document-selection-options';

import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import { damageReportFormTranspMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-transp-mutate-adapter';
import { damageReportFormTRANSPGetReportRecordAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-get-transp-report-record-adapter';

interface DamageReportFormTRANSPProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
}

export const DamageReportFormTRANSP = memo((props: DamageReportFormTRANSPProps) => {
    const { setDivision, hideButtons, data } = props;

    const { t } = useTranslation(['common', 'damages-report', 'errors']);
    const contractId = useQuery().get('contractId');

    const {
        watch,
        setValue,
        control,
        handleSubmit,
        formState,
        reset,
        trigger,
        getValues,
    } = useForm<DamageReportFormTRANSPTypes>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(damageReportFormTRANSPSchema),
        defaultValues: damageReportFormTRANSPDefaultState(contractId),
    });

    const { changeDivision } = useGlobalFilter();

    const [isSaveLoading, setSaveLoading] = useState<boolean>(false);
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

    const { id } = useParams<{ id?: string }>();

    const classes = useStyle();
    const httpClient = useHttpClient();
    const router = useHistory();

    const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const { fields: files, remove } = useFieldArray({ control, name: 'files' });

    const { fields: attachedDocuments, append: appendDocuments } = useFieldArray({
        control,
        name: 'attachedDocuments',
    });
    const { fields: fileInfos } = useFieldArray({
        control,
        name: 'fileInfos',
    });

    const [
        division,
        dayOfDamage,
        timeOfDamage,
        risk,
        contractNumber,
        policyHolder,
        insuranceCompany,
        ariaOfLiability,
        policeLicensePlate,
        vehicleBody,
        typeOfDamage,
        whoWasTheClient,
        driver,
        departurePlace,
        destination,
        whoLoaded,
        haveAgreementsAboutLoadingMet,
        whichAgreement,
        wasInQuantityTakeoverRequested,
        whichQuantityTakeoverRequested,
        doesDriverHaveNumberOfPiecesAtTakeoverChecked,
        doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
        whoDischarged,
        hasItBeenReloaded,
        whichGoodsAreMissingOrDamaged,
        estimatedDamageAmount,
        totalWeightOfCompleteCharge,
        totalWeightOfDamageConcernedCharge,
        wasGoodsPacked,
        kindOfPackage,
        whenIsDamageDeveloped,
        grantedRecipientReceipt,
        textofReservation,
        whereWasReservationMade,
        damageAmountReducedByFreight,
        detailedDamageDescription,
        whereIsDamagedItemLocated,
        averageAndCommissioner,
        isRecordedByThePolice,
        policeStation,
        fileNumber,
    ] = watch([
        'division',
        'dayOfDamage',
        'timeOfDamage',
        'risk',
        'contractNumber',
        'policyHolder',
        'insuranceCompany',
        'ariaOfLiability',
        'policeLicensePlate',
        'vehicleBody',
        'typeOfDamage',
        'whoWasTheClient',
        'driver',
        'departurePlace',
        'destination',
        'whoLoaded',
        'haveAgreementsAboutLoadingMet',
        'whichAgreement',
        'wasInQuantityTakeoverRequested',
        'whichQuantityTakeoverRequested',
        'doesDriverHaveNumberOfPiecesAtTakeoverChecked',
        'doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged',
        'whoDischarged',
        'hasItBeenReloaded',
        'whichGoodsAreMissingOrDamaged',
        'estimatedDamageAmount',
        'totalWeightOfCompleteCharge',
        'totalWeightOfDamageConcernedCharge',
        'wasGoodsPacked',
        'kindOfPackage',
        'whenIsDamageDeveloped',
        'grantedRecipientReceipt',
        'textofReservation',
        'whereWasReservationMade',
        'damageAmountReducedByFreight',
        'detailedDamageDescription',
        'whereIsDamagedItemLocated',
        'averageAndCommissioner',
        'isRecordedByThePolice',
        'policeStation',
        'fileNumber',
    ]);

    const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

    useEffect(() => {
        trigger();
    }, []);

    useEffect(() => {
        if (data) {
            reset(damageReportFormTRANSPGetReportRecordAdapter(data));
            trigger();
        }
    }, [data]);

    useEffect(() => {
        setDivision(division);
    }, [division]);

    const handleChangeValue = useCallback(
        (e: BaseSyntheticEvent): void => {
            setValue(e.target.name, e.target.value);
            trigger();
        },
        [setValue]
    );

    const saveReport = (data: DamageReportFormTRANSPTypes): Promise<{ id: string }> => {
        setSaveLoading(true);
        const formattedData = damageReportFormTranspMutateAdapter(data);
        const formData = new FormData();
        buildFormData(formData, formattedData, null);
        if (!id) {
            return httpClient.post(`create-damage-report/transp`, formData);
        }
        return httpClient.put(`damage-reports/${id}/transp`, formData);
    };

    const onDeleteFile = (id: string): void => {
        const newFiles = fileInfos.filter((file) => {
            return file.id !== id;
        });
        setValue('fileInfos', newFiles);
    };

    const handleSaveReport = (): void => {
        const data = getValues();
        saveReport(data)
            .then(() => {
                if (!id) {
                    changeDivision(Divisions.TRANSP);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                }
                enqueueSnackbar(t(`damages-report:savedSuccessfully`), {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar(t(`errors:unknownError`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const makeRequestForSavingDataToAms = async (
        data: DamageReportFormTRANSPTypes
    ): Promise<void | string> => {
        setSendingToAmsLoading(true);
        try {
            const { id: newId } = await saveReport(data);
            return httpClient
                .post<Promise<void | string>>(`damage-reports/${newId || id}/send-to-ams`)
                .catch(() => {
                    return Promise.reject(newId);
                });
        } catch {
            return Promise.reject();
        }
    };

    const saveReportToAms = (): void => {
        const data: DamageReportFormTRANSPTypes = getValues();
        makeRequestForSavingDataToAms(data)
            .then(() => {
                enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                    variant: 'success',
                });
                changeDivision(Divisions.TRANSP);
                router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
            })
            .catch((newId: string) => {
                if (newId) {
                    router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                }
                enqueueSnackbar(t(`errors:unknownError`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setSendingToAmsLoading(false);
                setSaveLoading(false);
            });
    };

    const handleCancel = (): void => {
        history.back();
    };

    const handleReadOnlyFields = (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }): void => {
        setValue('risk', data.licenseNumber);
        setValue('contractNumber', data.contractNumber);
        setValue('policyHolder', data.policyHolder);
        setValue('insuranceCompany', data.insuranceCompany);
        setValue('contractId', data.contractId);
        trigger();
    };

    const handleChangeDate = useCallback(
        (name, value: ParsableDate): void => {
            setValue(name, value);
            trigger();
        },
        [setValue]
    );

    const handleAddFile = useCallback(
        (newFiles: FileObject[]): void => {
            setValue('files', [...files, ...newFiles]);
        },
        [setValue, files]
    );

    const handleDeleteFile = useCallback(
        (index: number): void => {
            remove(index);
        },
        [setValue]
    );

    const onSubmit = (): void => {
        saveReportToAms();
    };

    const handleChangeSwitcher = useCallback(
        (name, value: boolean): void => {
            setValue(name, value);
            trigger();
        },

        [setValue]
    );

    const handleChangeAttachedDocuments = useCallback(
        (e: ChangeEvent<HTMLInputElement>): void => {
            if (e.target.checked) {
                appendDocuments({ name: e.target.name });
            } else {
                const newChosenDocuments = attachedDocuments.filter(
                    (item) => item.name !== e.target.name
                );
                setValue('attachedDocuments', newChosenDocuments);
            }
        },
        [appendDocuments, setValue, attachedDocuments]
    );

    const isDivisionFromDataIsFromState = data?.division === division;

    const headerConfig = useMemo(
        () =>
            generateDisabledInputs({
                licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                contractNumber:
                    isDivisionFromDataIsFromState || !id ? contractNumber : '',
                policyHolder: isDivisionFromDataIsFromState || !id ? policyHolder : '',
                insuranceCompany:
                    isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                errors: {
                    contractNumber: formState?.errors?.contractNumber,
                    insuranceCompany: formState?.errors?.insuranceCompany,
                    policyHolder: formState?.errors?.policyHolder,
                },
            }),
        [
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            division,
            data?.division,
            formState?.errors?.contractNumber,
            formState?.errors?.insuranceCompany,
            formState?.errors?.policyHolder,
        ]
    );

    const vehicleBodySelectOptions = useMemo(() => generateVehicleBodyOptions(t), [t]);

    const damageTypeSelectOptions = useMemo(
        () => damageReportFormGenerateTRANSPDamageTypeOptions(t),
        [t]
    );

    const ariaOfLiabilityOptions = useMemo(
        () => damageReportFormGenerateAriaLiabilityOptions(t),
        [t]
    );

    const whoLoadedOptions = useMemo(
        () => damageReportFormGenerateWhoLoadedOptions(t),
        [t]
    );

    const whoDischargedOptions = useMemo(
        () => generateWhoDischargedSelectionsOptions(t),
        [t]
    );

    const whenDamageDevelopedOptions = useMemo(
        () => damageReportFormGenerateWhenDamageDevelopedOptions(t),
        [t]
    );

    const whereReservationMadeOptions = useMemo(
        () => damageReportFormGenerateWhereReservationMadeOptions(t),
        [t]
    );

    const chosenDocuments = useMemo(
        () => attachedDocuments?.map((item) => item.name) || [],
        [attachedDocuments]
    );

    const attachedDocumentOptions = useMemo(
        () => damageReportFormTRANSPDocumentsOptions(t, chosenDocuments),
        [t, chosenDocuments]
    );

    const configs = useMemo(
        () =>
            DamageReportFormConfigTRANSP({
                policeLicensePlate,
                vehicleBody,
                typeOfDamage,
                ariaOfLiability,
                whoWasTheClient,
                driver,
                departurePlace,
                destination,
                whoLoaded,
                haveAgreementsAboutLoadingMet,
                whichAgreement,
                wasInQuantityTakeoverRequested,
                whichQuantityTakeoverRequested,
                doesDriverHaveNumberOfPiecesAtTakeoverChecked,
                doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
                whoDischarged,
                hasItBeenReloaded,
                whichGoodsAreMissingOrDamaged,
                estimatedDamageAmount,
                totalWeightOfCompleteCharge,
                totalWeightOfDamageConcernedCharge,
                wasGoodsPacked,
                kindOfPackage,
                whenIsDamageDeveloped,
                grantedRecipientReceipt: grantedRecipientReceipt,
                textofReservation,
                whereWasReservationMade,
                damageAmountReducedByFreight,
                whereIsDamagedItemLocated,
                detailedDamageDescription,
                averageAndCommissioner,
                isRecordedByThePolice,
                policeStation,
                fileNumber,
                vehicleBodySelectOptions,
                damageTypeSelectOptions,
                ariaOfLiabilityOptions,
                whoLoadedOptions,
                whoDischargedOptions,
                whenDamageDevelopedOptions,
                whereReservationMadeOptions,
                handleChangeSwitcher,
                ariaOfLiabilityError: Boolean(formState.errors.ariaOfLiability),
            }),
        [
            policeLicensePlate,
            vehicleBody,
            typeOfDamage,
            ariaOfLiability,
            whoWasTheClient,
            driver,
            departurePlace,
            destination,
            whoLoaded,
            haveAgreementsAboutLoadingMet,
            whichAgreement,
            wasInQuantityTakeoverRequested,
            whichQuantityTakeoverRequested,
            doesDriverHaveNumberOfPiecesAtTakeoverChecked,
            doesDriverHaveNumberOfPiecesAtTakeoverAcknowledged,
            whoDischarged,
            hasItBeenReloaded,
            whichGoodsAreMissingOrDamaged,
            estimatedDamageAmount,
            totalWeightOfCompleteCharge,
            totalWeightOfDamageConcernedCharge,
            wasGoodsPacked,
            kindOfPackage,
            whenIsDamageDeveloped,
            grantedRecipientReceipt,
            textofReservation,
            whereWasReservationMade,
            damageAmountReducedByFreight,
            detailedDamageDescription,
            whereIsDamagedItemLocated,
            averageAndCommissioner,
            isRecordedByThePolice,
            policeStation,
            fileNumber,
            vehicleBodySelectOptions,
            damageTypeSelectOptions,
            ariaOfLiabilityOptions,
            whoLoadedOptions,
            whoDischargedOptions,
            whenDamageDevelopedOptions,
            whereReservationMadeOptions,
            attachedDocumentOptions,
            handleChangeSwitcher,
            formState.errors.ariaOfLiability,
        ]
    );

    const footerConfig = useMemo(
        () => generateFooter({ attachedDocumentOptions, handleChangeAttachedDocuments }),
        [attachedDocumentOptions, handleChangeAttachedDocuments]
    );

    return (
        <>
            <DamageReportFormDeleteConfirmationModalWindow
                id={id}
                isOpen={isDeleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
            />
            {
                <DamageReportFormView
                    damageReportGroup={DamageReportGroups.TRANSPORT}
                    setDeleteConfirmOpen={setDeleteConfirmOpen}
                    deleteText={t('damages-report:deleteReport')}
                    deleteButtonClass={classes.deleteButton}
                    hideButtons={hideButtons}
                    boxContainer={classes.boxContainer}
                    sectionContainer={classes.sectionContainer}
                    formState={formState}
                    handleChangeValue={handleChangeValue}
                    footerConfig={footerConfig}
                    headerConfig={headerConfig}
                    configs={configs}
                    sendText={t('send')}
                    formBlockElementClass={classes.formBlockElement}
                    containerClassName={classes.formBlockContainer}
                    saveText={t('save')}
                    saveButtonClass={clsx(classes.button, classes.saveButton)}
                    sendButtonClass={clsx(classes.button, classes.sendButton)}
                    isSendDisabled={
                        !formState.isValid ||
                        isSendingToAmsLoading ||
                        isSaveLoading ||
                        (contractId && !isContractValidByDate)
                    }
                    isSendingToAmsLoading={isSendingToAmsLoading}
                    isSaveLoading={isSaveLoading}
                    dayOfDamage={dayOfDamage}
                    IsSaveDisabled={
                        isSaveLoading ||
                        isSendingToAmsLoading ||
                        !division ||
                        !dayOfDamage ||
                        !contractNumber ||
                        !insuranceCompany ||
                        !policyHolder ||
                        (contractId && !isContractValidByDate)
                    }
                    handleCancel={handleCancel}
                    cancelText={t('cancel')}
                    handleReadOnlyFields={handleReadOnlyFields}
                    timeOfDamage={timeOfDamage}
                    handleChangeDate={handleChangeDate}
                    division={division}
                    divisionFromRecord={data?.division}
                    mainFormContainer={classes.mainFormContainer}
                    isReceivingDataLoading={false}
                    handleSubmit={handleSubmit(onSubmit)}
                    footerClassName={clsx(
                        classes.buttonsContainer,
                        classes.formBlockContainer
                    )}
                    handleAddFile={handleAddFile}
                    files={files}
                    fileInfos={fileInfos}
                    onDeleteFile={onDeleteFile}
                    handleDeleteFile={handleDeleteFile}
                    handleSaveReport={handleSaveReport}
                />
            }
        </>
    );
});
