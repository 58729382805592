import React, {
    createContext,
    useContext,
    useState,
    SyntheticEvent,
    useCallback,
    ChangeEvent,
} from 'react';
import { useForm, UseFormReset } from 'react-hook-form';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';

interface DamagesListContextType {
    vnrValue: string;
    damageNRValue: string;
    damageNREstateAgentsValue: string;
    damageNRVUValue: string;
    page: number;
    rowsPerPage: number;
    sortModel: GridSortModel;
    handleChangePage: (_: SyntheticEvent | null, newPage: number) => void;
    handleChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeSort: (params: GridSortModelParams) => void;
    handleChangeTableSearchField: (e: ChangeEvent<HTMLInputElement>) => void;
    reset: UseFormReset<DamagesListFormData>;
}

type DamagesListFormData = {
    vnr: string;
    damageNR: string;
    damageNREstateAgents: string;
    damageNRVU: string;
    page: number;
    rowsPerPage: number;
};

export const defaultDamageListContextFormValues = {
    page: 0,
    rowsPerPage: 10,
    vnr: undefined,
    damageNR: undefined,
    damageNREstateAgents: undefined,
    damageNRVU: undefined,
};

export const DamagesListSettings = createContext<DamagesListContextType>(null);

export const DamagesListSettingsProvider = ({ children }): JSX.Element => {
    const { watch, setValue, reset } = useForm<DamagesListFormData>({
        mode: 'onChange',
        defaultValues: defaultDamageListContextFormValues,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>();

    const [
        vnrValue,
        damageNRValue,
        damageNREstateAgentsValue,
        damageNRVUValue,
        page,
        rowsPerPage,
    ] = watch([
        'vnr',
        'damageNR',
        'damageNREstateAgents',
        'damageNRVU',
        'page',
        'rowsPerPage',
    ]);

    const handleChangePage = (_: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: SyntheticEvent): void => {
        setValue('rowsPerPage', Number((e.target as HTMLInputElement).value));
        setValue('page', 0);
    };

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setSortModel]
    );

    const handleChangeTableSearchField = useCallback(
        (e): void => {
            switch (e.target.name) {
                case 'vnr': {
                    setValue('vnr', e.target.value);
                    setValue('page', 0);
                    break;
                }
                case 'damageNR': {
                    setValue('damageNR', e.target.value);
                    setValue('page', 0);
                    break;
                }
                case 'damageNREstateAgents': {
                    setValue('damageNREstateAgents', e.target.value);
                    setValue('page', 0);
                    break;
                }
                case 'damageNRVU': {
                    setValue('damageNRVU', e.target.value);
                    setValue('page', 0);
                    break;
                }
                default: {
                    setValue(e.target.name, e.target.value);
                    setValue('page', 0);
                }
            }
        },
        [setValue]
    );

    const contextProps: DamagesListContextType = {
        vnrValue,
        damageNRValue,
        damageNREstateAgentsValue,
        damageNRVUValue,
        page,
        rowsPerPage,
        sortModel,
        handleChangePage,
        handleChangeRowsPerPage,
        handleChangeTableSearchField,
        handleChangeSort,
        reset,
    };

    return (
        <DamagesListSettings.Provider value={contextProps}>
            {children}
        </DamagesListSettings.Provider>
    );
};

export const useDamagesListSettings = (): DamagesListContextType =>
    useContext<DamagesListContextType>(DamagesListSettings);
