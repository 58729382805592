import React, { ReactNode, useMemo, useState } from 'react';
import {
    ConnectivityService,
    ConnectivityServiceContext,
} from './use-connectivity-service';

interface Props {
    children?: ReactNode;
}

export function ConnectivityIndicatorInfrastructure({ children }: Props): JSX.Element {
    const [isConnected, setIsConnected] = useState(true);
    const [wasUserNotifiedAboutConnectionError, setWasUserNotifiedAboutConnectionError] =
        useState(false);
    const connectivityService = useMemo<InternalConnectivityService>(() => {
        return new InternalConnectivityService(
            isConnected,
            wasUserNotifiedAboutConnectionError,
            setIsConnected,
            setWasUserNotifiedAboutConnectionError
        );
    }, [isConnected, wasUserNotifiedAboutConnectionError]);

    return (
        <ConnectivityServiceContext.Provider value={connectivityService}>
            {children}
        </ConnectivityServiceContext.Provider>
    );
}

class InternalConnectivityService implements ConnectivityService {
    constructor(
        readonly isConnected: boolean,
        readonly wasUserNotifiedAboutConnectionError: boolean,
        private readonly setIsConnected: (value: boolean) => void,
        private readonly setWasUserNotifiedAboutConnetionError: (value: boolean) => void
    ) {}

    reportConnectionError(): void {
        if (!this.isConnected) return;

        this.setIsConnected(false);
        this.setWasUserNotifiedAboutConnetionError(false);
    }

    reportConnectionSuccess(): void {
        this.setIsConnected(true);
    }

    reportUserWasNotifiedAboutConnectionError(): void {
        this.setWasUserNotifiedAboutConnetionError(true);
    }
}
