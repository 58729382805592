import React, { BaseSyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Button,
} from '@material-ui/core';
import { FileObject } from 'material-ui-dropzone';

import {
    ThemeModalWindow,
    ThemePhoneNumberInput,
    ThemeDropZone,
    ThemeRadioButtonsGroup,
    ThemeCircularProgress,
} from 'src/theming';
import { useAuth } from 'src/user-management/context-auth';

import {
    useCustomerChangeNotificationStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from './customer-change-notification-styles';

import { generateContactByPhoneOptions } from '../../contracts/contracts-edit-form/contract-edit-form-configs';
import { customerEditFormUpdateAdapter } from './customer-change-adapter';
import { customerChangeNotificationValidationSchema } from './customer-change-notification-validation-schema';
interface Props {
    isOpen: boolean;
    client: string;
    customerConnection: string;
    address: string;
    documentFormats: string[];
    onClose: () => void;
}

export type ContractsEditFormData = {
    name: string;
    client: string;
    email: string;
    changeNotice: string;
    customerConnection: string;
    address: string;
    phoneNumber: string;
    contactByPhone: string;
    remarks: string;
    files: FileObject[];
};

export const CustomerChangeNotification = ({
    isOpen,
    onClose,
    client,
    customerConnection,
    address,
    documentFormats,
}: Props): JSX.Element => {
    const classes = useCustomerChangeNotificationStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const { t } = useTranslation(['customer-details-view']);

    const [isUpdatingLoading, setUpdatingLoading] = useState(false);

    const { userData } = useAuth();
    const httpClient = useHttpClient();
    const { id } = useParams<{ id: string }>();
    const { enqueueSnackbar } = useSnackbar();

    const {
        userInfo: { email: userEmail, name: userName, surname: userSurname },
    } = userData;

    const { watch, setValue, control, formState, reset, trigger } =
        useForm<ContractsEditFormData>({
            mode: 'onChange',
            defaultValues: {
                files: [],
                contactByPhone: 'no',
                name: `${userName} ${userSurname}`,
                email: userEmail,
                customerConnection: '',
                address: '',
                client: '',
                changeNotice: '',
            },
            resolver: yupResolver(customerChangeNotificationValidationSchema),
        });

    const { fields, remove } = useFieldArray({ control, name: 'files' });

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
        trigger();
    };

    const [phoneNumber, contactByPhone, name, email, remarks, files, changeNotice] =
        watch([
            'phoneNumber',
            'contactByPhone',
            'name',
            'email',
            'remarks',
            'files',
            'changeNotice',
        ]);

    const onAddAttachment = (newFiles: FileObject[]): void => {
        setValue('files', [...fields, ...newFiles]);
    };

    const onRemoveAttachment = (index: number): void => {
        remove(index);
    };

    const handleChangeContactByPhone = (e: BaseSyntheticEvent): void => {
        setValue('contactByPhone', e.target.value);
        trigger('phoneNumber');
    };

    const makeRequestToUpdateData = async (): Promise<ContractsEditFormData> => {
        setUpdatingLoading(true);
        const formData = customerEditFormUpdateAdapter({
            name,
            client,
            email,
            changeNotice,
            customerConnection,
            address,
            phoneNumber,
            contactByPhone,
            remarks,
            files,
        });
        return httpClient.post<ContractsEditFormData>(
            `customer/${id}/request-for-update`,
            formData
        );
    };

    const handleSave = async (): Promise<void> => {
        try {
            await makeRequestToUpdateData();
            onClose();
            reset({
                name: userName,
                email: userEmail,
                client: client,
                customerConnection: customerConnection,
                address: address,
                remarks: '',
                contactByPhone: 'no',
            });
            enqueueSnackbar(t('changeNotification.updatedSuccessfully'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setUpdatingLoading(false);
        }
    };

    const contactByPhoneOptions = useMemo(() => generateContactByPhoneOptions(t), [t]);
    return (
        <ThemeModalWindow
            isOpen={isOpen}
            onClose={onClose}
            modalWindowClass={classes.modalWindowClass}
        >
            <Typography variant='h5' className={classes.modalTitle}>
                {t('changeNotification.title')}
            </Typography>
            <Grid container spacing={4} className={classes.modalContentContainer}>
                <Grid
                    container
                    item
                    className={classes.fieldsColumn}
                    style={{ paddingLeft: 0 }}
                >
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={name}
                                />
                            }
                            label={t('changeNotification.name')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={client}
                                />
                            }
                            label={t('changeNotification.client')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={email}
                                />
                            }
                            label={t('changeNotification.email')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid container item className={classes.fieldsColumn}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={customerConnection}
                                />
                            }
                            label={t('changeNotification.customerConnection')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled
                                    classes={inputClasses}
                                    value={address}
                                />
                            }
                            label={t('changeNotification.address')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemePhoneNumberInput
                                    error={Boolean(formState.errors?.phoneNumber)}
                                    name='phoneNumber'
                                    onChange={handleChangeValue}
                                    value={phoneNumber}
                                />
                            }
                            label={t('changeNotification.phoneNumber', {
                                sign: contactByPhone === 'yes' ? '*' : '',
                            })}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item container className={classes.fullWidthFieldsContainer}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    multiline
                                    rows={2}
                                    classes={inputClasses}
                                    className={classes.multiRowTextField}
                                    name='remarks'
                                    value={remarks}
                                    onChange={handleChangeValue}
                                />
                            }
                            label={t('changeNotification.otherRemarks')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={classes.fullWidthFieldsContainer}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeDropZone
                                    formats={documentFormats || []}
                                    fileObjects={fields}
                                    onAdd={onAddAttachment}
                                    onRemove={onRemoveAttachment}
                                />
                            }
                            label={t('changeNotification.attachments')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={classes.contactByPhoneContainer}>
                    <Typography variant='body1' className={classes.radioButtonsLabel}>
                        {t('changeNotification.contactByPhone')}
                    </Typography>
                    <ThemeRadioButtonsGroup
                        row
                        value={contactByPhone}
                        onChange={handleChangeContactByPhone}
                        radioButtonsList={contactByPhoneOptions}
                        injectedIconClass={classes.radioIcon}
                        injectedCheckedIconClass={classes.radioCheckedIcon}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleSave}
                    disabled={!formState.isValid || isUpdatingLoading}
                    startIcon={<ThemeCircularProgress isLoading={isUpdatingLoading} />}
                >
                    {t('common:send')}
                </Button>
                <Button
                    disabled={isUpdatingLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
