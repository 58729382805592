import React from 'react';
import { ListItem } from 'src/theming';
import { CustomerConnectionSectionResponseBody } from './master-data-client-connections-dto';
import WorkIcon from '@material-ui/icons/Work';

export const masterDataSectionCustomerListFormatter = (
    data: CustomerConnectionSectionResponseBody
): { count: number; result: ListItem[] } => {
    return {
        count: data?.count,
        result: data?.result?.map((user) => {
            return {
                ...user,
                id: user.id,
                text: `${user.customerConnection}`,
                icon: <WorkIcon />,
            };
        }),
    };
};
