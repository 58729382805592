import React, { useEffect, useState } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import {
    appInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
    isSGIFFOXXKInstance,
} from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from '../styles';
import {
    Typography,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Button,
    Link,
} from '@material-ui/core';
import { ThemeCircularProgress } from '../../theming';
import { loginSchema } from './login-form-validation-schema';
import { useAuth } from '../context-auth';
import { useHistory } from 'react-router';
import { LoginTwoFactor } from 'src/user-management/login/login-two-factor';

export type LoginFormData = {
    email: string;
    password: string;
};

export interface LoginFormProps {
    darkMode: DarkMode;
}

export const LoginForm = (props: LoginFormProps): JSX.Element => {
    const { darkMode } = props;

    const {
        handleLogin,
        isLoadingLogin,
        authorized,
        homePath,
        userData,
        requireTwoFactorVerification,
    } = useAuth();
    const history = useHistory();

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();

    const { t } = useTranslation(['user-management', 'errors']);

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
        mode: 'onChange',
        shouldFocusError: true,
    });

    const onSubmit = (vals: LoginFormData): void => {
        handleLogin(vals);
    };

    if (authorized && homePath && !requireTwoFactorVerification) {
        return <Redirect to={`${homePath}${history.location.search}`} />;
    }

    return (
        <form
            id='login-form'
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
            name='login'
        >
            <Typography variant='h3' className={classes.formTitle}>
                {appInstance === AppInstances.AON_KOMPOSIT &&
                    `${t('requestLoginAONKomposit')}`}
                {appInstance === AppInstances.AON && `${t('requestLoginAON')}`}
                {appInstance !== AppInstances.AON_KOMPOSIT &&
                    appInstance !== AppInstances.AON &&
                    t('requestLogIn')}
            </Typography>

            {!isHectorDemoInstance &&
                !isHectorStyles &&
                !isSGIFFOXXKInstance &&
                !isAonDigitalInstance &&
                !isAonMotorInstance && <hr />}

            {requireTwoFactorVerification && <LoginTwoFactor darkMode={darkMode} />}

            {!requireTwoFactorVerification && (
                <>
                    <Typography variant='body1' className={classes.formMessage}>
                        {t('loginMessage')}
                    </Typography>
                    <FormControl
                        fullWidth
                        error={Boolean(errors?.email)}
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    id='email'
                                    disabled={isLoadingLogin}
                                    type='email'
                                    placeholder={t('placeholders.enterEmail')}
                                    classes={inputClasses}
                                    {...register('email')}
                                />
                            }
                            label={t('fields.email')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <FormControl
                        error={Boolean(errors?.password)}
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    id='password'
                                    disabled={isLoadingLogin}
                                    type='password'
                                    autoComplete='off'
                                    placeholder={t('placeholders.enterPassword')}
                                    classes={inputClasses}
                                    {...register('password')}
                                />
                            }
                            label={t('fields.password')}
                            labelPlacement='top'
                        />
                    </FormControl>
                    <Button
                        id='login-button'
                        disableElevation
                        disabled={!isValid || isLoadingLogin || authorized}
                        startIcon={
                            <ThemeCircularProgress
                                isLoading={isLoadingLogin || (authorized && !userData)}
                            />
                        }
                        className={classes.submitButton}
                        variant='contained'
                        type='submit'
                        name='submit'
                    >
                        {t('login')}
                    </Button>
                    <Link
                        href='forgot-password'
                        className={classes.forgotPasswordLink}
                    >{`${t('forgotPassword')}`}</Link>
                </>
            )}
        </form>
    );
};
