import React, { BaseSyntheticEvent } from 'react';
import { useStyle } from './dashboard-card-style';
import clsx from 'clsx';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import { Checkbox } from '@material-ui/core';

interface Props {
    header: string;
    subHeader?: string;
    children: JSX.Element | JSX.Element[];
    editMode?: boolean;
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
}

export const DashboardCard = ({
    header,
    subHeader,
    children,
    editMode,
    isShown,
    handleChangeShown,
    name,
}: Props): JSX.Element => {
    const classes = useStyle();

    const onShown = (e: BaseSyntheticEvent): void => {
        handleChangeShown(e.target.name, e.target.checked);
    };

    return (
        <div className={classes.card}>
            <div className={classes.headerContainer}>
                <div>
                    <div className={classes.header}>{header}</div>
                    {subHeader && <div className={classes.subHeader}>{subHeader}</div>}
                </div>
                {editMode && (
                    <Checkbox
                        disableRipple
                        checked={isShown}
                        onClick={onShown}
                        name={name}
                        icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
                        checkedIcon={
                            <CheckBoxIcon
                                className={clsx(classes.icon, classes.checkedIcon)}
                            />
                        }
                    />
                )}
            </div>
            <div className={classes.content}>{children}</div>
        </div>
    );
};
