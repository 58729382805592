import React from 'react';
import useDarkMode from 'use-dark-mode';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import { ContractsNavigationPanel } from 'src/contracts/contracts-navigation-panel/contracts-navigation-panel';

import { ReportCenterExportContracts } from './report-center-export-contracts';
import { ReportCenterExportDamages } from './report-center-export-damages';
import { ReportCenterExportClientConnections } from './report-center-export-client-connections';

import { useStyle } from '../contracts-styles/contracts-styles';
import { useReportCenterStyles } from './report-center-styles';

export const ReportCenter = (): JSX.Element => {
    const darkMode = useDarkMode();
    const contractClasses = useStyle({ isDarkMode: darkMode.value });
    const reportCenterClasses = useReportCenterStyles();

    return (
        <Grid container className={contractClasses.contractsListContainer}>
            <Grid item container component='form' className={contractClasses.toolBar}>
                <ContractsNavigationPanel activeMenu='report-center' />
            </Grid>
            <Grid item className={contractClasses.contentContainer}>
                <Grid container className={reportCenterClasses.reportSettingsContainer}>
                    <Grid
                        item
                        className={clsx(
                            reportCenterClasses.settingItem,
                            reportCenterClasses.exportClaimsItem
                        )}
                    >
                        <ReportCenterExportDamages />
                    </Grid>
                    <Grid item className={reportCenterClasses.settingItem}>
                        <ReportCenterExportContracts />
                    </Grid>
                    <Grid item className={reportCenterClasses.settingItem}>
                        <ReportCenterExportClientConnections />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
