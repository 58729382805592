import React from 'react';
import { KfzwDashboard } from 'src/dashboard/variants/kfzw-dashboard';
import { DivisionDashboard } from 'src/dashboard/variants/division-dashboard';
import { Divisions } from 'src/shared/constants';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';

interface Props {
    division: string;
    dashboardData: DashboardDataResponseType;
    years: number[];
    settings: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
    moveCard?: (dragIndex: number, hoverIndex: number, isDrop?: boolean) => void;
    handleChangeShown?: (name: string, value: boolean) => void;
}

/*
All dashboard data is stored in the context. It is possible to get it directly in the KfzwDashboard and 
DivisionDashboard components from the useDashboardContext hook, but the ContractsDashboardVariantSelection component gets
this data from the props because there is logic with pdf that is connected to these components and it is neccassary to get data from the props.
  */

export const ContractsDashboardVariantSelection = ({
    division,
    dashboardData,
    years,
    settings,
    moveCard,
    handleChangeShown,
}: Props): JSX.Element => {
    return (
        <>
            {division === Divisions.KFZW && (
                <KfzwDashboard
                    key={division}
                    dashboardData={dashboardData}
                    years={years}
                    settings={settings}
                    moveCard={moveCard}
                    handleChangeShown={handleChangeShown}
                />
            )}
            {division && division !== Divisions.KFZW && (
                <DivisionDashboard
                    key={division}
                    division={division}
                    dashboardData={dashboardData}
                    years={years}
                    settings={settings}
                    moveCard={moveCard}
                    handleChangeShown={handleChangeShown}
                />
            )}
        </>
    );
};
