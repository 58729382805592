import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, useMediaQuery } from '@material-ui/core';

import { ThemeModalWindow } from 'src/theming';

import { useStyle } from './non-users-damage-report-form-styles';

export const NonUsersDamageReportFormNoValidModalWindow = (): JSX.Element => {
    const { t } = useTranslation(['non-users-damage-report-form']);

    const isSmallModalWindow = useMediaQuery('(max-width:600px)');

    const [isOpen, setOpen] = useState(true);

    const classes = useStyle();

    const onClose = (): void => {
        setOpen(false);
    };

    return (
        <ThemeModalWindow
            width={isSmallModalWindow ? 200 : 400}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Typography variant='h5'>{t('noValidLinkTitle')}</Typography>
            <Grid container className={classes.modalWindowContent}>
                <img
                    src='/static/images/dialog-info.png'
                    alt='Dialog info'
                    className={classes.infoIcon}
                />
                <Typography variant='body1' className={classes.modalWindowMessage}>
                    {t('noValidLinkMessage')}
                </Typography>
            </Grid>
        </ThemeModalWindow>
    );
};
