import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { APP_ROUTES } from 'src/routing';
import { useAuth } from 'src/user-management/context-auth';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { CheckboxItem } from 'src/theming';
import { useInIframe } from 'src/shared/hooks';

import { useStyle } from './theme-header-styles/theme-header-styles';
import blobToBase64 from 'src/shared/utils/blob-to-base64';

interface ThemeCustomerHeaderLogoProps {
    customerConnections: CheckboxItem[];
    onLogoLoaded?: () => void;
}

export const ThemeCustomerHeaderLogo = ({
    customerConnections,
    onLogoLoaded,
}: ThemeCustomerHeaderLogoProps): JSX.Element => {
    const classes = useStyle();
    const router = useHistory();
    const httpClient = useHttpClient();
    const { homePath } = useAuth();
    const ability = useAbilityCtx();
    const inIFrame = useInIframe();

    const imageRef = useRef(null);

    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [logoBase64, setLogoBase64] = useState<string>();

    const handleGoTo = useCallback(
        (pathname: APP_ROUTES): void => {
            router.push(pathname);
        },
        [router]
    );

    const makeRequestForImage = (customerConnection: string): Promise<Blob> => {
        if (inIFrame) {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                return httpClient.get(`customer-connections/${customerConnection}/logo`, {
                    axiosConfig: {
                        headers: {
                            'Content-Type': 'image/jpeg',
                            Authorization: `Bearer ${accessToken}`,
                        },
                        responseType: 'blob',
                    },
                });
            }
        }
        return httpClient.get(`customer-connections/${customerConnection}/logo`, {
            axiosConfig: {
                headers: {
                    'Content-Type': 'image/jpeg',
                },
                responseType: 'blob',
            },
        });
    };

    const handleImageResponse = async (blob: Blob): Promise<void> => {
        if (blob) {
            const base64 = await blobToBase64(blob);
            setLogoBase64(base64);
        } else {
            setLogoBase64(undefined);
        }
    };

    useEffect(() => {
        if (ability.can(AbilityActions.see, AbilitySubjects.customerConnectionLogo)) {
            if (Array.isArray(customerConnections) && customerConnections.length > 0) {
                const isAllCustomerConnectionsChecked = customerConnections.find(
                    (item) => item.value === 'all'
                )?.checked;
                (async () => {
                    try {
                        if (isAllCustomerConnectionsChecked) {
                            const imageResponse = await makeRequestForImage(
                                customerConnections[1].title
                            );
                            handleImageResponse(imageResponse);
                        } else {
                            const selectedCustomerConnection = customerConnections.filter(
                                (i) => i.checked && i.value !== 'all'
                            );
                            if (selectedCustomerConnection.length > 0) {
                                const imageResponse = await makeRequestForImage(
                                    selectedCustomerConnection[0]?.title
                                );
                                handleImageResponse(imageResponse);
                            }
                        }
                    } catch {
                        handleImageResponse(null);
                        if (onLogoLoaded) {
                            onLogoLoaded();
                        }
                    }
                })();
            }
        }
    }, [JSON.stringify(customerConnections), ability]);

    const handleOnLoad = (): void => {
        if (!isLoaded) {
            setLoaded(true);
            if (onLogoLoaded) {
                onLogoLoaded();
            }
        }
    };

    return (
        <img
            ref={imageRef}
            alt='customerlogo'
            className={classes.customerLogo}
            src={logoBase64}
            onClick={() => handleGoTo(homePath)}
            style={{ display: (!logoBase64 || !isLoaded) && 'none' }}
            onLoad={handleOnLoad}
        />
    );
};
