import { makeStyles, Theme } from '@material-ui/core';

import { THEME_MODES } from 'src/theming';

export const useRelinkStyles = makeStyles((theme: Theme) => {
    return {
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: `0 ${theme.spacing(1)}rem`,
            backgroundColor: theme.palette.primary.main,
        },
        titleContainer: {
            width: '100%',
            height: 75,
            alignItems: 'center',
        },
        backButton: {
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        backTitle: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        staticFieldsContainer: {
            padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
            backgroundColor: theme.palette.primary.light,
            alignItems: 'center',
            '&>div:nth-child(even)': {
                paddingLeft: theme.spacing(8),
            },
            '&>div:nth-child(odd)': {
                paddingRight: theme.spacing(8),
            },
        },
        menuContainer: {
            padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
            backgroundColor: theme.palette.primary.light,
        },
        dynamicFields: {
            padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
            backgroundColor: theme.palette.primary.light,
            gap: theme.spacing(3),
        },
        actionsContainer: {
            padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
            backgroundColor: theme.palette.primary.light,
        },
        containedButton: {
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            transition: '0.2s',
            '&.Mui-disabled': {
                opacity: 0.8,
                color: theme.palette.primary.contrastText,
            },
            '&:hover': {
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                opacity: 0.8,
            },
        },
        dynamicFieldItem: {
            flex: '1 1 calc((100% / 2) - 2rem)',
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: `${theme.palette.primary.light}!important`,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
            '& .MuiInputBase-root': {
                width: '50%',
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: 0,
            padding: `0!important`,
        },
        label: {
            width: 'fit-content',
            marginRight: theme.spacing(2),
            fontSize: '1.6rem',
            opacity: 0.6,
            whiteSpace: 'nowrap',
        },
    };
});
