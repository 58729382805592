import { Button, FormControlLabel, OutlinedInput } from '@material-ui/core';
import React from 'react';
import { useStyle } from 'src/pages/user-settings/user-settings-styles';
import {
    useFormControlLabelStyles,
    useInputStyles,
} from 'src/pages/non-users-damage-report/non-users-damage-report-form-styles';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    name: string;
    code: string;
    onChange: (value: string) => void;
    onVerify: () => void;
    onResendCode: () => void;
    verifyText: string;
    onCancel?: () => void | undefined;
    cancelText?: string;
}

export const TwoFactorCodeInput = ({
    title,
    name,
    code,
    onChange,
    onVerify,
    onResendCode,
    verifyText,
    onCancel,
    cancelText,
}: Props): JSX.Element => {
    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();
    const { t } = useTranslation(['user-settings']);

    return (
        <>
            <FormControlLabel
                classes={formControlLabelClasses}
                style={{ marginBottom: '1rem' }}
                control={
                    <OutlinedInput
                        classes={inputClasses}
                        value={code}
                        onChange={(e) => onChange(e.target.value)}
                        name={name}
                    />
                }
                label={title}
                labelPlacement='top'
            />

            <Button variant='contained' className={classes.saveButton} onClick={onVerify}>
                {verifyText}
            </Button>

            <Button
                variant='contained'
                className={classes.saveButton}
                onClick={onResendCode}
                style={{ marginLeft: '1rem' }}
            >
                {t('twoFactor.resendVerificationCodeBtn')}
            </Button>

            {onCancel != null && (
                <Button
                    variant='contained'
                    className={classes.saveButton}
                    onClick={onCancel}
                    style={{ marginLeft: '1rem' }}
                >
                    {cancelText ?? 'Cancel'}
                </Button>
            )}
        </>
    );
};
