import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export enum Documents {
    FORWARDING_SHIPPING_ORDER_DELIVERY_NOTE = 'forwardingShippingOrderDeliveryNote',
    WAYBILL_BILL_OF_LADING = 'waybillBillOfLading',
    ORIGINAL_CERTIFICATE = 'originalCertificate',
    REGISTRATION_ONGOING_TRANSPORT_INSURANCE = 'registrationForOngoingTransportInsurance',
    INVOICE_COPY = 'invoiceCopy',
    CLAIMS_ACCOUNT = 'claimsAccount',
    LIABILITY_CARRIER = 'liabilityCarrier',
    LETTER_OF_REPLY_TO_THE_DETENTION = 'letterOfReplyToTheDetention',
    CONFIRMATION_OF_DAMAGE_FROM_THE_CARRIER = 'confirmationOfDamageFromTheCarrier',
    COPY_OF_THE_POLICE_PROTOCOL = 'copyOfThePoliceProtocol',
    EXPERT_OPINION = 'expertOpinion',
}

export const damageReportFormAonTranspDocumentsOptions = (
    t: TFunction,
    chosenDocuments: string[]
): CheckboxItem[] => {
    return [
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.forwardingShippingOrderDeliveryNote'
            ),
            value: Documents.FORWARDING_SHIPPING_ORDER_DELIVERY_NOTE,
            checked: chosenDocuments.includes(
                Documents.FORWARDING_SHIPPING_ORDER_DELIVERY_NOTE
            ),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.waybillBillOfLading'
            ),
            value: Documents.WAYBILL_BILL_OF_LADING,
            checked: chosenDocuments.includes(Documents.WAYBILL_BILL_OF_LADING),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.originalCertificate'
            ),
            value: Documents.ORIGINAL_CERTIFICATE,
            checked: chosenDocuments.includes(Documents.ORIGINAL_CERTIFICATE),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.registrationForOngoingTransportInsurance'
            ),
            value: Documents.REGISTRATION_ONGOING_TRANSPORT_INSURANCE,
            checked: chosenDocuments.includes(
                Documents.REGISTRATION_ONGOING_TRANSPORT_INSURANCE
            ),
            data: undefined,
        },
        {
            title: t('damages-report:theFollowingDocumentsAreAttached.invoiceCopy'),
            value: Documents.INVOICE_COPY,
            checked: chosenDocuments.includes(Documents.INVOICE_COPY),
            data: undefined,
        },
        {
            title: t('damages-report:theFollowingDocumentsAreAttached.claimsAccount'),
            value: Documents.CLAIMS_ACCOUNT,
            checked: chosenDocuments.includes(Documents.CLAIMS_ACCOUNT),
            data: undefined,
        },
        {
            title: t('damages-report:theFollowingDocumentsAreAttached.liabilityCarrier'),
            value: Documents.LIABILITY_CARRIER,
            checked: chosenDocuments.includes(Documents.LIABILITY_CARRIER),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.letterOfReplyToTheDetention'
            ),
            value: Documents.LETTER_OF_REPLY_TO_THE_DETENTION,
            checked: chosenDocuments.includes(Documents.LETTER_OF_REPLY_TO_THE_DETENTION),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.confirmationOfDamageFromTheCarrier'
            ),
            value: Documents.CONFIRMATION_OF_DAMAGE_FROM_THE_CARRIER,
            checked: chosenDocuments.includes(
                Documents.CONFIRMATION_OF_DAMAGE_FROM_THE_CARRIER
            ),
            data: undefined,
        },
        {
            title: t(
                'damages-report:theFollowingDocumentsAreAttached.copyOfThePoliceProtocol'
            ),
            value: Documents.COPY_OF_THE_POLICE_PROTOCOL,
            checked: chosenDocuments.includes(Documents.COPY_OF_THE_POLICE_PROTOCOL),
            data: undefined,
        },
        {
            title: t('damages-report:theFollowingDocumentsAreAttached.expertOpinion'),
            value: Documents.EXPERT_OPINION,
            checked: chosenDocuments.includes(Documents.EXPERT_OPINION),
            data: undefined,
        },
    ];
};
