import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum ThingAffected {
    FINISHED_ASSEMBLED = 'finishedAssembled',
    TESTING = 'testing',
    OPERATION = 'operation',
    TAKEN_DOWN = 'takenDown',
}

export const damageReportFormGenerateAONTechnicalThingAffectedOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:thingAffectedByTheDamageOptions.finishedAssembled'),
            value: ThingAffected.FINISHED_ASSEMBLED,
        },
        {
            title: t('damages-report:thingAffectedByTheDamageOptions.testing'),
            value: ThingAffected.TESTING,
        },
        {
            title: t('damages-report:thingAffectedByTheDamageOptions.operation'),
            value: ThingAffected.OPERATION,
        },
        {
            title: t('damages-report:thingAffectedByTheDamageOptions.takenDown'),
            value: ThingAffected.TAKEN_DOWN,
        },
    ];
};
