import { createMuiTheme, Theme } from '@material-ui/core';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import { ThemeOptions } from '@material-ui/core';

import { THEME_MODES } from './theme-modes';
import { instanceTheme } from './themes-factory';

export { THEME_MODES };

export const baseThemeProperties: ThemeOptions = {
    spacing: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    typography: {
        fontFamily: instanceTheme.fontFamily,
        h1: {
            fontSize: '4rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '3.5rem',
            fontWeight: 700,
        },
        h3: {
            fontSize: '3rem',
            fontWeight: 700,
        },
        h4: {
            fontSize: '2.5rem',
            fontWeight: 700,
        },
        h5: {
            fontSize: '2rem',
            fontWeight: 700,
        },
        h6: {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '1.8rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '1.6rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1.3rem',
        },
        subtitle2: {
            fontSize: '1rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: '100%',
                    fontSize: '10px',
                },
                body: {
                    width: '100vw',
                    height: '100vh',
                    '& #__next': {
                        width: '100%',
                        height: '100%',
                    },
                    '& .MuiSnackbarContent-root': {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        paddingRight: '1.6rem!important',
                        maxWidth: 400,
                        overflow: 'hidden',
                    },
                    '& .MuiSnackbarContent-message': {
                        wordBreak: 'break-all',
                    },
                },
                input: {
                    '&::-webkit-input-placeholder': {
                        fontSize: '1.4rem',
                    },
                },
            },
        },
        MuiButton: {
            root: {
                fontSize: '1.6rem',
                textTransform: 'none',
                fontWeight: 400,
                borderRadius: 2,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1.4rem!important',
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                fontSize: '1.4rem',
            },
        },
        MuiPickersModal: {
            dialogRoot: {
                '& .MuiDialogActions-root': {
                    display: 'none',
                },
            },
        },
    },
};

export const lightTheme: Theme = createMuiTheme({
    ...baseThemeProperties,
    palette: instanceTheme.lightPalette,
    overrides: {
        ...baseThemeProperties.overrides,
        ...instanceTheme.lightOverrides,
        MuiCssBaseline: {
            ...baseThemeProperties.overrides.MuiCssBaseline,
            '@global': {
                ...baseThemeProperties.overrides.MuiCssBaseline['@global'],
                ...instanceTheme.lightOverrides.MuiCssBaseline['@global'],
            },
        },
    },
});

export const darkTheme: Theme = createMuiTheme({
    ...baseThemeProperties,
    palette: instanceTheme.darkPalette,
    overrides: {
        ...baseThemeProperties.overrides,
        ...instanceTheme.darkOverrides,
        MuiCssBaseline: {
            ...baseThemeProperties.overrides.MuiCssBaseline,
            '@global': {
                ...baseThemeProperties.overrides.MuiCssBaseline['@global'],
                ...instanceTheme.darkOverrides.MuiCssBaseline['@global'],
            },
        },
    },
});

export const themes = {
    lightTheme: responsiveFontSizes(lightTheme),
    darkTheme: responsiveFontSizes(darkTheme),
};
