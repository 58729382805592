import React, { SyntheticEvent } from 'react';
import clsx from 'clsx';

import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Box,
} from '@material-ui/core';

import { ThemePagination } from '..';

import {
    useStyle,
    useListStyle,
    useListItemStyle,
    useListItemTextStyle,
    useListItemIconStyle,
} from './theme-list-menu-style';
import { CSSProperties } from '@material-ui/styles';

export interface ListItem {
    id: string;
    text: string;
    icon?: JSX.Element;
    style?: CSSProperties;
}

interface ThemeListMenuProps {
    list: ListItem[];
    setActiveItem: (activeItem: string) => void;
    maxHeight?: number;
    menuTitle?: string;
    activeItem?: string;
    page?: number;
    rowsPerPage?: number;
    count?: number;
    handleChangeRowsPerPage: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    >;
    handleChangePage: (event: SyntheticEvent, page: number) => void;
    noDataMessage?: string;
    isMenuListDisabled?: boolean;
}

export const ThemeListMenu = (props: ThemeListMenuProps): JSX.Element => {
    const {
        list,
        maxHeight,
        menuTitle,
        activeItem,
        setActiveItem,
        page,
        rowsPerPage,
        count,
        handleChangeRowsPerPage,
        handleChangePage,
        noDataMessage,
        isMenuListDisabled,
    } = props;
    const classes = useStyle();
    const listClasses = useListStyle({
        listHeight: (maxHeight / 100) * 85,
    });
    const listItemClasses = useListItemStyle({ isMenuListDisabled });
    const listItemTextClasses = useListItemTextStyle();
    const listItemIconClasses = useListItemIconStyle();

    return (
        <div className={classes.listMenuContainer}>
            {menuTitle && (
                <Typography variant='h6' className={classes.menuTitle}>
                    {menuTitle}
                </Typography>
            )}
            <List dense classes={listClasses}>
                {(!list || list.length === 0) && (
                    <Box m={2}>
                        <Typography
                            align='center'
                            variant='body1'
                            className={classes.noDataMessage}
                        >
                            {noDataMessage}
                        </Typography>
                    </Box>
                )}
                {list.map((item: ListItem) => {
                    return (
                        <ListItem
                            disabled={isMenuListDisabled}
                            key={item.id}
                            classes={listItemClasses}
                            className={clsx({
                                [classes.activeListItem]: activeItem === item.id,
                            })}
                            onClick={
                                !isMenuListDisabled ? () => setActiveItem(item.id) : null
                            }
                        >
                            {item.icon && (
                                <ListItemIcon classes={listItemIconClasses}>
                                    {item.icon}
                                </ListItemIcon>
                            )}
                            <ListItemText
                                classes={listItemTextClasses}
                                style={item.style}
                            >
                                {item.text}
                            </ListItemText>
                        </ListItem>
                    );
                })}
            </List>
            <ThemePagination
                withoutMinMaxButtons
                paginationClassName={classes.pagination}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};

ThemeListMenu.defaultProps = {
    maxHeight: 500,
};
