import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { appInstance, hectorStyleInstances } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import { useDivisionSectionAonMotorStyles } from './division-section-aon-motor-style';
import { useDivisionSectionAonStyles } from './division-section-aon-style';
import { useDivisionSectionHectorStyles } from './division-section-hector-style';
import { useDivisionSectionSgiffoxxkStyles } from './division-section-sgiffoxxk-style';

interface StyleProps {
    editMode?: boolean;
    isDarkMode?: boolean;
    isActive?: boolean;
}

const divisionSectionStyleInstance = {
    [AppInstances.AON]: useDivisionSectionAonStyles,
    [AppInstances.AON_KOMPOSIT]: useDivisionSectionAonStyles,
    [AppInstances.AON_DIGITAL]: useDivisionSectionAonMotorStyles,
    [AppInstances.AON_MOTOR]: useDivisionSectionAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useDivisionSectionAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useDivisionSectionSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useDivisionSectionSgiffoxxkStyles,
};

hectorStyleInstances.forEach(
    (instance) =>
        (divisionSectionStyleInstance[instance] = useDivisionSectionHectorStyles)
);

export const useStyle = (props: StyleProps): ClassNameMap => {
    return divisionSectionStyleInstance[appInstance](props);
};
