import * as yup from 'yup';

export const damageReportFormGBWSchema = yup.object().shape({
    division: yup.string().required().nullable().required(),
    dayOfDamage: yup.string().nullable().required(),
    contractNumber: yup.string().nullable().required(),
    policyHolder: yup.string().nullable().required(),
    insuranceCompany: yup.string().nullable().required(),
    localName: yup.string().nullable().required(),
    email: yup.string().email().nullable(),
});
