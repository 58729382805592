import React from 'react';
import { DarkMode } from 'use-dark-mode';
import {
    CreatePasswordForm,
    UserLayout,
    UserAONLayout,
    UserHectorLayout,
    UserSgiffoxxLayout,
    UserAonMotorLayout,
    UserCeatLayout,
    UserCeatMaklerLayout,
} from '../../user-management';

import { AonMotorThemeProvider } from 'src/shared/hocs/aon-motor-theme-provider';
import { AonThemeProvider } from 'src/shared/hocs/aon-theme-provider';
import { HectorThemeProvider } from 'src/shared/hocs/hector-theme-provider';
import { CeatThemeProvider } from 'src/shared/hocs/ceat-theme-provider';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { MaasThemeProvider } from 'src/shared/hocs/maas-theme-provider';
import { UserMaasLayout } from 'src/user-management/user-layouts/user-maas-layout';
import { UserAgravisLayout } from '../../user-management/user-layouts/user-agravis-layout/user-agravis-layout';

interface Props {
    darkMode: DarkMode;
}

export const CreatePasswordPage = ({ darkMode }: Props): JSX.Element => {
    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.AON_KOMPOSIT: {
            return (
                <AonThemeProvider>
                    <UserAONLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserAONLayout>
                </AonThemeProvider>
            );
        }
        case AppInstances.AON_DIGITAL:
        case AppInstances.AON_MOTOR:
        case AppInstances.HECTOR_DEMO: {
            return (
                <AonMotorThemeProvider>
                    <UserAonMotorLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserAonMotorLayout>
                </AonMotorThemeProvider>
            );
        }
        case AppInstances.SGIFFOXXK: {
            return (
                <UserSgiffoxxLayout darkMode={darkMode}>
                    <CreatePasswordForm darkMode={darkMode} />
                </UserSgiffoxxLayout>
            );
        }
        case AppInstances.AGRAVIS: {
            return (
                <UserAgravisLayout darkMode={darkMode}>
                    <CreatePasswordForm darkMode={darkMode} />
                </UserAgravisLayout>
            );
        }
        case AppInstances.HECTOR: {
            return (
                <HectorThemeProvider>
                    <UserHectorLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserHectorLayout>
                </HectorThemeProvider>
            );
        }
        case AppInstances.MAAS: {
            return (
                <MaasThemeProvider>
                    <UserMaasLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserMaasLayout>
                </MaasThemeProvider>
            );
        }
        case AppInstances.CEAT: {
            return (
                <CeatThemeProvider>
                    <UserCeatLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserCeatLayout>
                </CeatThemeProvider>
            );
        }
        case AppInstances.CEAT_MAKLER: {
            return (
                <CeatThemeProvider>
                    <UserCeatMaklerLayout darkMode={darkMode}>
                        <CreatePasswordForm darkMode={darkMode} />
                    </UserCeatMaklerLayout>
                </CeatThemeProvider>
            );
        }
        default: {
            return (
                <UserLayout darkMode={darkMode}>
                    <CreatePasswordForm darkMode={darkMode} />
                </UserLayout>
            );
        }
    }
};
