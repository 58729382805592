import { useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { RelinkMenu } from './relink-generate-menu';

interface TranscodeFiles {
    type: string;
    customer: string;
    dummyCustomer: string;
    customerConnection: string;
    dummyCustomerConnection: string;
    dynamicFields: { id: string; name: string; value?: string }[];
}

interface TranscodeFilesDTO {
    damagesMapping?: { dummyId: string; realId: string }[];
    contractsMapping?: { dummyId: string; realId: string }[];
    dummyCustomerId: string;
    dummyCustomerConnection: string;
    realCustomerId: string;
    realCustomerConnection: string;
}

const transcodeFilesFormatter = (data: TranscodeFiles): TranscodeFilesDTO => {
    return {
        dummyCustomerId: data.customer,
        dummyCustomerConnection: data.dummyCustomerConnection,
        realCustomerId: data.customer,
        realCustomerConnection: data.customerConnection,
        [data.type === RelinkMenu.CONTRACTS ? 'contractsMapping' : 'damagesMapping']:
            data.dynamicFields
                .filter((item) => item.value !== 'No choice')
                .map((item) => ({ dummyId: item.id, realId: item.value })),
    };
};

export const useTranscodeFiles = (
    data: TranscodeFiles,
    onSuccess: () => void
): [boolean, () => Promise<void>] => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['relink', 'errors']);
    const [isLoading, setLoading] = useState<boolean>(false);

    const transcodeFiles = async (): Promise<void> => {
        try {
            setLoading(true);
            const formattedData = transcodeFilesFormatter(data);
            await httpClient.post('contracts/relink', formattedData);
            onSuccess();
            enqueueSnackbar(
                t('relink:successTranscode', { thing: t(`relink:${data.type}`) }),
                { variant: 'success' }
            );
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return [isLoading, transcodeFiles];
};
