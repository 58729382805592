import { makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
    editMode?: boolean;
    isDarkMode?: boolean;
    isActive?: boolean;
}

export const useDivisionSectionAonStyles = makeStyles((theme: Theme) => {
    return {
        contractTypes: {
            alignContent: 'flex-start',
            backgroundColor: theme.palette.primary.main,
        },
        contractType: {
            position: 'relative',
            width: 300,
            height: 130,
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
        },
        dragContractType: {
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundImage: `repeating-linear-gradient(-45deg, ${theme.palette.info.light}, ${theme.palette.info.light} 25px, ${theme.palette.secondary.main} 25px, ${theme.palette.secondary.main} 50px)`,
                border: `2px dashed ${theme.palette.info.main}`,
                zIndex: 1000,
            },
        },
        cardContent: {
            height: '100%',
        },
        cardData: {
            flexDirection: 'column',
            width: '80%',
            paddingRight: theme.spacing(3),
        },
        cardIcon: {
            width: '20%',
            paddingTop: theme.spacing(5),
            '& .MuiSvgIcon-root': {
                width: 40,
                height: 40,
            },
        },
        cardTitle: {
            fontSize: '1.4rem',
            paddingTop: theme.spacing(1),
        },
        cardValue: {
            fontSize: '2.4rem',
            paddingBottom: theme.spacing(2),
        },
        editModeContainer: {
            alignItems: 'center',
            marginBottom: `${theme.spacing(2)}px`,
        },
        editModeButtons: {
            width: '50%',
            marginLeft: theme.spacing(3),
            '&>button': {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                marginRight: theme.spacing(3),
                backgroundColor: theme.palette.info.light,
                color: theme.palette.info.main,
                borderRadius: 2,
                '&[data-mode=edit]': {
                    backgroundColor: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.grey.A100}`,
                },
            },
        },
        showMoreLink: {
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: `${theme.spacing(1)}rem`,
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
            color: 'auto',
        },
        indicatorContainer: {
            position: 'absolute',
            top: 5,
            right: 5,
        },
        indicatorButton: {
            width: 25,
            height: 25,
            borderRadius: 0,
            backgroundColor: ({ isActive }: StyleProps) =>
                isActive ? theme.palette.info.main : theme.palette.primary.light,
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                '&>path': {
                    fill: ({ isActive }: StyleProps) =>
                        isActive ? theme.palette.primary.light : theme.palette.info.main,
                    opacity: 1,
                },
            },
        },
        divisionsContainer: {
            width: '100%',
        },
        defaultBlock: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            zIndex: 1000,
            '& .MuiFormControlLabel-root': {
                marginRight: 0,
            },
            '& .MuiSvgIcon-root path': {
                fill: ({ isActive }: StyleProps) =>
                    isActive ? theme.palette.primary.main : theme.palette.info.main,
                opacity: 1,
            },
        },
        saveButton: {
            '& .MuiCircularProgress-svg': {
                color: theme.palette.info.main,
            },
        },
    };
});
