import { ContractsEditFormData } from './contracts-edit-form';

import { buildFormData } from 'src/shared/utils';

export const contractEditFormUpdateAdapter = (data: ContractsEditFormData): FormData => {
    const formattedData = {
        SurnameFirstname: data.name,
        PhoneNumber: data.phoneNumber,
        Division: data.branch,
        Cover: data.cover,
        Customer: data.client,
        Email: data.email,
        ContractNumber: data.contractNumber,
        LicenseNumber: data.contractNumber,
        Insurer: data.insurer,
        CustomerConnection: data.customerConnection,
        Address: data.address,
        Product: data.product,
        VehicleType: data.vehicleType,
        OtherRemarks: data.otherRemarks,
        ChangeNotification: data.changeNotice,
        Attachments: data.files?.map((item) => item.file),
        PhoneContact: data.contactByPhone === 'yes',
    };
    const formData = new FormData();
    buildFormData(formData, formattedData, null);
    return formData;
};
