import { Theme } from '@material-ui/core';
import React, { useMemo } from 'react';
import NivoThemeContext, { NivoTheme } from './nivo-theme-context';

interface Props {
    children: React.ReactNode;
    theme: Theme;
}

export const NivoThemeProvider = ({ children, theme }: Props): JSX.Element => {
    const nivoTheme: NivoTheme = useMemo(() => {
        return {
            theme: {
                background: theme.palette.primary.light,
                textColor: theme.palette.text.primary,
                tooltip: {
                    container: {
                        background: theme.palette.primary.light,
                        color: theme.palette.text.primary,
                    },
                },
            },
            themedColors: [
                theme.palette.info.main,
                theme.palette.info.dark,
                theme.palette.primary.dark,
            ],
        };
    }, [theme]);

    return (
        <NivoThemeContext.Provider value={nivoTheme}>
            {children}
        </NivoThemeContext.Provider>
    );
};
