import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { Typography, Grid, Button } from '@material-ui/core';

import { ThemeModalWindow, ThemeCircularProgress } from 'src/theming';
import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { useStyle } from '../damage-report-form-styles/damage-report-form-styles';

interface Props {
    isOpen: boolean;
    id: string;
    onClose: () => void;
}

export const DamageReportFormDeleteConfirmationModalWindow = ({
    isOpen,
    onClose,
    id,
}: Props): JSX.Element => {
    const { t } = useTranslation(['common', 'damages-report']);
    const router = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const httpClient = useHttpClient();
    const classes = useStyle();

    const [isDeletingLoading, setDeletingLoading] = useState<boolean>(false);

    const handleDeleteReport = async (): Promise<void> => {
        setDeletingLoading(true);
        try {
            await httpClient.delete(`damage-report/${id}`);
            router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
            enqueueSnackbar(t('damages-report:damageReportDeletedSuccessfully'), {
                variant: 'success',
            });
        } catch {
            enqueueSnackbar(t('errors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setDeletingLoading(false);
        }
    };

    return (
        <ThemeModalWindow onClose={onClose} isOpen={isOpen}>
            <></>
            <Typography variant='body2'>
                {t('damages-report:deleteConfirmationMessage')}
            </Typography>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    disabled={isDeletingLoading}
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleDeleteReport}
                    startIcon={<ThemeCircularProgress isLoading={isDeletingLoading} />}
                >
                    {t('yes')}
                </Button>
                <Button
                    disabled={isDeletingLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={onClose}
                >
                    {t('no')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
