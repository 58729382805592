import { useState, Dispatch, SetStateAction, useEffect } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { NotificationCenterSections } from './notification-center-enums';

import { MessageDTO } from './notification-center-message-dto';

interface UseNotificationCenterMessages {
    getMessages: (notificationSection: string, userId: string) => Promise<void>;
    messages: MessageDTO[];
    isLoading: boolean;
    getNumberOfUnreadMessages: (userId: string) => Promise<number>;
    setNewUnreadMessage: Dispatch<SetStateAction<MessageDTO>>;
}

export const useNotificationCenterMessages = (
    notificationCenter: NotificationCenterSections
): UseNotificationCenterMessages => {
    const httpClient = useHttpClient();
    const [messages, setMessages] = useState([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [newUnread, setNewUnreadMessage] = useState<MessageDTO>(null);

    useEffect(() => {
        if (
            newUnread &&
            notificationCenter === NotificationCenterSections.UNREAD_MESSAGES
        ) {
            setMessages([newUnread, ...messages]);
            setNewUnreadMessage(null);
        }
    }, [newUnread, messages, notificationCenter]);

    const getNumberOfUnreadMessages = async (userId: string): Promise<number> => {
        const data = await httpClient.get<MessageDTO[]>(
            `notifications/${userId}/${NotificationCenterSections.UNREAD_MESSAGES}`
        );
        return data?.length;
    };

    const getMessages = async (
        notificationSection: string,
        userId: string
    ): Promise<void> => {
        try {
            setLoading(true);
            const data = await httpClient.get<MessageDTO[]>(
                `notifications/${userId}/${notificationSection}`
            );
            setMessages(data);
        } finally {
            setLoading(false);
        }
    };

    return {
        messages,
        getMessages,
        isLoading,
        getNumberOfUnreadMessages,
        setNewUnreadMessage,
    };
};
