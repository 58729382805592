import moment from 'moment';
import uniqid from 'uniqid';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormAONKompositLiabilityTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-liability-types';

export const damageReportFormLiabilityGetAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState: string
): DamageReportFormAONKompositLiabilityTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        fileNumberInsuredCompany:
            data?.liabilityInsuranceNotification?.insuredCompanyFileNumber,
        damageLocation: data?.liabilityInsuranceNotification?.damageLocation,
        damageCause: data?.liabilityInsuranceNotification?.damageOriginator,
        courseOfTheDamage: data?.liabilityInsuranceNotification?.damageCause,
        expectedAmountOfDamage: data?.liabilityInsuranceNotification?.expectedDamageAmount
            ? String(data?.liabilityInsuranceNotification?.expectedDamageAmount).replace(
                  '.',
                  ','
              )
            : '',
        blameForTheDamage: data?.liabilityInsuranceNotification?.isBlameForTheDamage,
        because: data?.liabilityInsuranceNotification?.blameBecause,
        relationshipBetweenYouAndTheInjuredPerson:
            data?.liabilityInsuranceNotification
                ?.isRelationshipBetweenYouAndInjuredPerson,
        wasTheDamagedItemRentedLeasedLoaned:
            data?.liabilityInsuranceNotification?.hasTheDamagedItemRentedLeasedLoaned,
        affectedByTheDamageAlsoThroughAnotherContract:
            data?.liabilityInsuranceNotification
                ?.isAffectedByDamageThroughAnotherContract,
        itemsAffectedByTheDamage:
            Array.isArray(data?.liabilityInsuranceNotification?.liabilityDamagedItems) &&
            data?.liabilityInsuranceNotification?.liabilityDamagedItems?.length > 0
                ? data?.liabilityInsuranceNotification?.liabilityDamagedItems?.map(
                      (item) => ({
                          id: uniqid(),
                          numberAndTypeOfItems: item.numberAndTypeOfThings,
                          acquisitionOrReplacementPrice: item.purchaseOrReplacementPrice
                              ? String(item.purchaseOrReplacementPrice).replace('.', ',')
                              : '',
                          repairCosts: item.repairCost
                              ? String(item.repairCost).replace('.', ',')
                              : '',
                      })
                  )
                : [
                      {
                          id: uniqid(),
                          numberAndTypeOfItems: '',
                          acquisitionOrReplacementPrice: '',
                          repairCosts: '',
                      },
                  ],
        injuredPersonsOrClaimants:
            data?.liabilityInsuranceNotification?.injuredPersonsOrClaimants,
        investigationToBeExpected:
            data?.liabilityInsuranceNotification?.isInvestigationExpected,
        productLiabilityIncludingRecallDamage:
            data?.liabilityInsuranceNotification?.isProductLiabilityIncludingRecallDamage,
        financialLossIncludingFidelityOrComputerAbuse:
            data?.liabilityInsuranceNotification
                ?.isFinancialLossIncludingFidelityOrComputerAbuse,
        descriptionOfTheProductCausingTheDamage:
            data?.productLiabilityClaims?.productCausingDamageDescription,
        purposeOfUse: data?.productLiabilityClaims?.purposeOfUse,
        manufacturerSellerDealerOfTheProduct:
            data?.productLiabilityClaims?.productManufacturerSellerDealer,
        damageCausedByYourSubSupplier:
            data?.productLiabilityClaims?.damageCausedByYourSubSupplier,
        howManyOfYourProductsAreDefective:
            data?.productLiabilityClaims?.defectiveProductsCount,
        deliveryDate: data?.productLiabilityClaims?.deliveryDate,
        batchBasedOnSerialNumberingRecognizable:
            data?.productLiabilityClaims?.batchBasedOnSerialNumberingRecognizable,
        etainedSamplesExist: data?.productLiabilityClaims?.retainedSamplesExist,
        exitInspectionBeenCarriedOut:
            data?.productLiabilityClaims?.exitInspectionBeenCarriedOut,
        defectProduct: data?.productLiabilityClaims?.exitInspectionBeenCarriedOut,
        blameForTheDefect: data?.productLiabilityClaims?.blameForTheDefect,
        elationshipBetweenYouAndTheClaimant: Array.isArray(
            data?.liabilityDocumentsInfo?.liabilityAttachedDocuments
        )
            ? data?.liabilityDocumentsInfo.liabilityAttachedDocuments.map((item) => ({
                  name: item,
              }))
            : [],
        files: [],
        fileInfos: data.fileInfos,
    };
};
