import { makeStyles, Theme } from '@material-ui/core/styles';

export const useGlobalSearchHectorStyle = makeStyles((theme: Theme) => {
    return {
        toolBarDropDownButton: {
            width: ({ filtersCount }: { filtersCount: number }) =>
                `${100 / filtersCount}%!important`,
            borderRight: `1px solid ${theme.palette.primary.dark}`,
            borderRadius: 0,
            color: `${theme.palette.secondary.contrastText}!important`,
            '& .MuiTypography-root': {
                marginLeft: theme.spacing(5),
            },
            '& .MuiButton-endIcon': {
                marginLeft: 'auto',
                marginRight: theme.spacing(5),
            },
            '&:nth-last-child(1)': {
                borderRight: 0,
            },
        },
        tableContainer: {
            flexGrow: 1,
            padding: `0 140px ${theme.spacing(5)}px 140px`,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        title: {
            margin: `${theme.spacing(5)}px 140px`,
            [theme.breakpoints.down('lg')]: {
                margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
            },
        },
        datePicker: {
            width: ({ filtersCount }: { filtersCount: number }) =>
                `${100 / filtersCount}%`,
            paddingLeft: theme.spacing(5),
            '& input': {
                caretColor: theme.palette.secondary.contrastText,
            },
            '& .MuiInput-underline': {
                '&:before': {
                    borderBottom: 'none',
                },
                '&:after': {
                    borderBottom: 'none',
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: 'none',
                },
                '& input::placeholder': {
                    opacity: 1,
                    color: theme.palette.secondary.contrastText,
                },
                '& .MuiSvgIcon-root': {
                    width: 15,
                    height: 15,
                    color: theme.palette.secondary.contrastText,
                },
            },
        },
        autocompleteFilter: {
            width: ({ filtersCount }: { filtersCount: number }) =>
                `${100 / filtersCount}%`,
            '& .MuiAutocomplete-root': {
                width: '100%',
            },
        },
    };
});

export const useInputHectorStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: ({ filtersCount }: { filtersCount: number }) =>
                `${100 / filtersCount}%`,
            height: 33,
            borderRadius: 0,
            border: 0,
            borderRight: `1px solid ${theme.palette.primary.dark}`,
        },
        input: {
            fontSize: '1.6rem',
            color: theme.palette.secondary.contrastText,
            padding: `0 ${theme.spacing(5)}px`,
            '&::placeholder': {
                color: theme.palette.secondary.contrastText,
                opacity: 1,
            },
        },
    };
});
