import { DamageReportFormTravelPurpouses } from '../damage-report-form-selection-items/damage-report-form-generate-travel-purpouse-options';

import { DamageReportFormFormData } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-form-data-type';

export const damageReportFormDefaultState = (
    contractId: string
): DamageReportFormFormData => ({
    contractId,
    id: '',
    division: 'KFZW',
    dayOfDamage: null,
    timeOfDamage: null,
    kfzLicenseNumber: '',
    contractNumber: '',
    policyHolder: '',
    insuranceCompany: '',
    typeOfDamage: [],
    rootCause: '',
    accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle: '',
    accidentDamageDetailsInformationDamageNumberCustomer: '',
    accidentDamageDetailsInformationDetailedDamageDescription: '',
    accidentDamageDetailsInformationChangeOfVehicle:
        DamageReportFormTravelPurpouses.OFFICIAL,
    accidentSceneStreet: '',
    accidentScenePostcode: '',
    accidentSceneCity: '',
    accidentSceneCountry: '',
    driverInformationFirstName: '',
    driverInformationSurname: '',
    driverInformationTenant: '',
    driverInformationStreet: '',
    driverInformationPostCode: '',
    driverInformationCity: '',
    driverInformationPhoneNumber: '',
    driverInformationEmail: '',
    driverInformationDrivingLicenseAvailable: false,
    driverInformationDrivingLicenseClass: '',
    driverInformationDateOfIssue: null,
    driverInformationChangeOfVehicle: '',
    driverInformationEstimatedAmountOfDamageToYourOwnVehicle: '',
    driverInformationDamageNumberCustomer: '',
    driverInformationDetailedDamageDescription: '',
    isTeam: false,
    teamDamageStreet: '',
    teamDamagePostcode: '',
    teamDamageCity: '',
    teamDamagePhoneNumber: '',
    teamDamageEmail: '',
    teamDamageInsurancePolicyNumber: '',
    teamVehicleType: '',
    teamLicenseNumber: '',
    teamTowedVehicleKeeper: '',
    incidentRecordedByPolice: false,
    policeRecordDiagnosedAlcoholOrDrugUse: false,
    policeRecordReceivingPoliceStation: '',
    policeRecordPoliceFileNumber: '',
    policeRecordSomeoneWarnedByPolice: false,
    policeRecordWhoWarnedByPolice: '',
    areThereWitnesses: false,
    witnessesNameAndSurname: '',
    witnessesStreet: '',
    witnessesPostcode: '',
    witnessesCity: '',
    witnessesPhoneNumber: '',
    witnessesEmail: '',
    otherPartyInformationNameAndSurname: '',
    otherPartyInformationStreet: '',
    otherPartyInformationPostCode: '',
    otherPartyInformationCity: '',
    otherPartyInformationCityPhoneNumber: '',
    otherPartyInformationCityEmail: '',
    otherPartyInformationLicenseNumber: '',
    otherPartyInformationVehicleType: '',
    otherPartyInformationDamageAmount: '',
    files: [],
    fileInfos: [],
});
