import { makeStyles, Theme } from '@material-ui/core';
import { isAonDigitalInstance, isAonMotorInstance } from 'src/environment';
import { THEME_MODES } from 'src/theming';

export const useUserAonMotorLayoutStyles = makeStyles((theme: Theme) => {
    const isDark = theme.palette.type === THEME_MODES.DARK;
    return {
        pageContainer: {
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.primary.light,
            overflow: 'hidden',
        },
        previewContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
            width: '64vw',
            height: '100vh',
            padding: `${theme.spacing(10)}px`,
            paddingBottom: '135px',
            paddingLeft: '140px',
            backgroundColor: isDark
                ? theme.palette.primary.dark
                : theme.palette.secondary.light,
            [theme.breakpoints.down('md')]: {
                paddingBottom: '60px',
                paddingLeft: '60px',
            },
        },
        logo: {
            maxWidth: (isAonMotorInstance || isAonDigitalInstance) && 130,
            position: 'absolute',
            top: 35,
            left: 122,
            zIndex: 10,
            filter: isDark ? 'brightness(0) invert(1)' : null,
            '& img': {
                width: '100%',
            },
            [theme.breakpoints.down('md')]: {
                left: 60,
            },
        },
        dots: {
            width: '100%',
            position: 'absolute',
            left: -10,
            top: -85,
            '& img': {
                width: '100%',
            },
        },
        portalInformation: {
            position: 'relative',
            alignSelf: 'flex-end',
            padding: `${theme.spacing(1)}rem 0`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(4)}px 0`,
            },
        },
        portalInformationMessage: {
            position: 'relative',
            color: theme.palette.primary.contrastText,
            fontSize: '2.8rem',
            zIndex: 5,
            [theme.breakpoints.down('lg')]: {
                fontSize: '2.4rem',
            },
        },
        functionalityContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '36vw',
            height: '100vh',
            backgroundColor: isDark
                ? theme.palette.primary.main
                : theme.palette.primary.light,
        },
        toolbarContainer: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            alignItems: 'center',
            width: '100%',
            marginBottom: 'auto',
            marginTop: `${theme.spacing(1)}rem`,
            padding: `0 ${theme.spacing(10)}px`,
        },
        backButton: {
            opacity: 0.6,
        },
        switcherContainer: {
            width: 'fit-content',
        },
        form: {
            position: 'relative',
            left: '-30%',
            width: 800,
            padding: 80,
            backgroundColor: theme.palette.primary.main,
            boxShadow: !isDark ? '0px 3px 10px #0000001A' : 'none',
            border: isDark ? `1px solid ${theme.palette.secondary.contrastText}` : 'none',
            [theme.breakpoints.down('lg')]: {
                width: 650,
                padding: 60,
            },
            [theme.breakpoints.down('md')]: {
                width: 550,
                padding: 40,
            },
        },
        footerWrapper: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 60,
            borderTop: `1px solid ${theme.palette.secondary.main}`,
            [theme.breakpoints.down('md')]: {
                height: 30,
            },
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(8),
            width: '64%',
            height: '100%',
            background: isDark
                ? theme.palette.primary.dark
                : theme.palette.secondary.light,
        },
        hectorLogoContainer: {
            width: 208,
            height: 41,
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: isDark
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.dark,
                },
            },
        },
    };
});
