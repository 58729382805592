import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm, UseFormSetValue, UseFormReset, FormState } from 'react-hook-form';

import { useAbilityCtx } from 'src/roleAccesses';

import { notificationCenterNewMessageValidationSchema } from './notification-center-new-message-validation-schema';

export interface NotificationCenterNewMessageFormData {
    customerAdvisor: string;
    customerConnection: string;
    customer: string;
    user: string;
    message: string;
}

interface UseNotificationCenterNewMessageReturn {
    values: NotificationCenterNewMessageFormData;
    setValue: UseFormSetValue<NotificationCenterNewMessageFormData>;
    reset: UseFormReset<NotificationCenterNewMessageFormData>;
    formState: FormState<NotificationCenterNewMessageFormData>;
}

export const defaultValues: NotificationCenterNewMessageFormData = {
    customerAdvisor: '',
    customerConnection: '',
    customer: '',
    user: '',
    message: '',
};

export const useNotificationCenterNewMessage = (
    userReplyId: string
): UseNotificationCenterNewMessageReturn => {
    const ability = useAbilityCtx();

    const { watch, setValue, reset, formState, trigger } =
        useForm<NotificationCenterNewMessageFormData>({
            defaultValues,
            resolver: yupResolver(notificationCenterNewMessageValidationSchema(ability)),
        });

    const [customerAdvisor, customerConnection, customer, user, message] = watch([
        'customerAdvisor',
        'customerConnection',
        'customer',
        'user',
        'message',
    ]);

    useEffect(() => {
        trigger();
    }, [user, customerAdvisor, message]);

    useEffect(() => {
        if (userReplyId) {
            setValue('user', userReplyId);
        }
    }, [userReplyId]);

    return {
        values: { customerAdvisor, customerConnection, customer, user, message },
        setValue,
        reset,
        formState,
    };
};
