import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyle } from './dashboard-pdf-header-style';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const DashboardPdfHeader = (): JSX.Element => {
    const classes = useStyle();
    const { t } = useTranslation(['dashboard-pdf', 'common']);

    return (
        <Grid container className={classes.headerContainer}>
            <Typography variant='h4' className={classes.headerText}>
                {`${t('headerText')} ${moment(new Date()).format('DD.MM.yyyy')}`}
            </Typography>
        </Grid>
    );
};
