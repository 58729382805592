import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { useMessageComponentStyles } from './theme-message-component-styles';

export enum MessageTypes {
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

interface Props {
    message: string;
    type: MessageTypes;
}

export const ThemeMessageComponent = ({ message, type }: Props): ReactElement => {
    const classes = useMessageComponentStyles();
    return (
        <div className={classes.container}>
            <p
                className={clsx(
                    classes.message,
                    type === MessageTypes.SUCCESS && classes.success,
                    type === MessageTypes.ERROR && classes.error,
                    type === MessageTypes.INFO && classes.info
                )}
            >
                {message}
            </p>
        </div>
    );
};
