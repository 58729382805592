import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router';
import { AnyAbility } from '@casl/ability';

import { useAuth } from 'src/user-management/context-auth';
import { AbilityActions, AbilitySubjects, useAbilityCtx } from 'src/roleAccesses';

import { IThemeTab, ThemeTabs } from 'src/theming';
import { APP_ROUTES } from 'src/routing';
import { useGlobalFilter } from 'src/shared/contexts';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isSGIFFOXXKInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

const generateContractsNavigationPanelTabs = (
    t: TFunction,
    ability: AnyAbility,
    handleGoToCustomerView: () => void
): IThemeTab[] => {
    return [
        {
            title: `${t('dashboard')}`,
            value: 'dashboard',
            path: APP_ROUTES.HOME,
        },
        {
            title: `${t('contracts')}`,
            value: 'contracts',
            path: APP_ROUTES.CONTRACTS,
        },
        {
            title: `${t('damage')}`,
            value: 'damages',
            path: APP_ROUTES.DAMAGES,
        },
        ...(ability.can(AbilityActions.see, AbilitySubjects.customers)
            ? [
                  {
                      title: t('baseData'),
                      value: 'baseData',
                      action: handleGoToCustomerView,
                  },
              ]
            : []),
        {
            title: t('reportCenter'),
            value: 'report-center',
            path: APP_ROUTES.REPORT_CENTER,
        },
    ];
};

interface ContractsNavigationPanelProps {
    activeMenu?: string;
}

export const ContractsNavigationPanel = (
    props: ContractsNavigationPanelProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const { activeMenu } = props;

    const { filter } = useGlobalFilter();
    const { userData } = useAuth();
    const router = useHistory();

    const { t } = useTranslation(['contracts-navigation-panel']);

    const handleGoToCustomerView = useCallback((): void => {
        const selectedCustomers = filter.customers.filter(
            (item) => item.value !== 'all' && item.checked
        );
        if (selectedCustomers.length === 1) {
            router.push(`${APP_ROUTES.CUSTOMERS}/${selectedCustomers[0].value}`);
        } else {
            router.push(APP_ROUTES.CUSTOMERS);
        }
    }, [filter]);

    const tabs = useMemo(() => {
        return generateContractsNavigationPanelTabs(t, ability, handleGoToCustomerView);
    }, [t, userData, ability, handleGoToCustomerView]);

    return (
        <ThemeTabs
            isAonMotorStyles={
                isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance
            }
            isHectorStyles={isHectorStyles}
            isSgiffoxxkStyles={isSGIFFOXXKInstance}
            tabs={tabs}
            activeTab={activeMenu}
        />
    );
};
