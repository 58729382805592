import * as yup from 'yup';
import { CUSTOMER_ROLES, INTERNAL_ROLES } from 'src/shared/constants';
import { CheckboxItem } from 'src/theming';
import { InternalClientCard, WithBranchFormCard } from './user-edit-form-types';

import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';
import { AnyAbility } from '@casl/ability';
import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

const testArray = (value: []): boolean => value?.length > 0;

export const validateInternalClientCard = (
    roles: string[],
    value: InternalClientCard[]
): boolean => {
    if (
        Array.isArray(roles) &&
        Boolean(
            roles.some(
                (role) =>
                    role === INTERNAL_ROLES.CUSTOMER_ADVISER ||
                    role === INTERNAL_ROLES.CA_BROKER
            )
        )
    ) {
        const atLeastOneClientChecked =
            value?.length > 0 && value[0].clients.some((client) => client.checked);
        const atLeastOneClientConnectionChecked =
            value?.length > 0 &&
            value[0].clientConnections.some((client) => client.checked);
        return atLeastOneClientChecked && atLeastOneClientConnectionChecked;
    }
    return true;
};

export const validateClientConnectionsCard = (
    roles: string[],
    ability: AnyAbility,
    clientConnections: CheckboxItem[]
): boolean => {
    if (
        ability.can(
            AbilityActions.edit,
            AbilitySubjects.userManagementClientConnectionCard
        ) &&
        roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER)
    ) {
        const atLeastOneClientConnectionChecked =
            clientConnections?.length > 0 &&
            clientConnections.some((client) => client.checked);
        return atLeastOneClientConnectionChecked;
    }
    return true;
};

export const validateCardsWithBranches = (
    roles: string[],
    ability: AnyAbility,
    userId: string,
    id: string,
    value: WithBranchFormCard[]
): boolean => {
    const needValidate =
        Array.isArray(roles) &&
        roles &&
        (roles.includes(CUSTOMER_ROLES.CLERK) ||
            roles.includes(CUSTOMER_ROLES.CLERK_PLUS) ||
            roles.includes(CUSTOMER_ROLES.ADMINISTRATOR) ||
            roles.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) &&
        userId !== id &&
        !roles.includes(CUSTOMER_ROLES.ACCOUNT_OWNER) &&
        ability.can(AbilityActions.edit, AbilitySubjects.userManagementCardsWithBranches);

    if (!needValidate) {
        return true;
    }
    return (
        value != null &&
        value.every((item) => {
            const isClientConnectionSelected = Boolean(item.clientConnectionValue);
            const atLeastOneClientChecked = item.clients.some((client) => client.checked);
            const atLeastOneDivisionChecked = item.divisions.some(
                (division) => division.checked
            );
            return (
                isClientConnectionSelected &&
                atLeastOneClientChecked &&
                atLeastOneDivisionChecked
            );
        })
    );
};

export const userEditFormValidationSchema = yup.object().shape({
    id: yup.string(),
    name: yup.string().required().nullable(),
    surname: yup.string().required().nullable(),
    email: yup.string().email().required().nullable(),
    active: yup.bool().required().nullable(),
    userGroup: yup.string().required().nullable(),
    customerAdvisorNumber:
        appInstance === AppInstances.AON_KOMPOSIT
            ? yup.string().when('roles', {
                  is: (val) => {
                      return val?.find(
                          (item) => item.name === INTERNAL_ROLES.CUSTOMER_ADVISER
                      );
                  },
                  then: yup.string().required().nullable(),
                  otherwise: yup.string().nullable(),
              })
            : yup.string().nullable(),
    roles: yup
        .array()
        .test(testArray)
        .of(yup.object().shape({ name: yup.string() }))
        .required(),
});
