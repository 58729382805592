import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageAssessment {
    IMMEDIATELY_UPON_DELIVERY = 'immediatelyUponDelivery',
    LATER = 'later',
}

export const damageReportFormGenerateAONTranspDamageAssessmentOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:damageAssessment.immediatelyUponDelivery'),
            value: DamageAssessment.IMMEDIATELY_UPON_DELIVERY,
        },
        {
            title: t('damages-report:damageAssessment.later'),
            value: DamageAssessment.LATER,
        },
    ];
};
