import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';

import { Notifications } from 'src/user-management';
import { APP_MODULES } from 'src/shared/constants';

export const NotificationsPage = (): JSX.Element => {
    const darkMode = useDarkMode();

    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.ADMINISTRATION}>
            <Notifications />
        </ThemeLayout>
    );
};
