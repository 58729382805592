import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ThemeAutocomplete, ThemeAutocompleteOptions } from 'src/theming';
import { withBadge } from 'src/shared/hocs';

import {
    useGlobalSearchAutocompliteFilterStyles,
    useGlobalAutocompleteSearchBadgeStyles,
} from './GlobalSearchAutocompliteFilterStyles';

const ThemeAutocompleteWithBadge = withBadge(ThemeAutocomplete);

interface GlobalSearchAutocompliteFilterProps {
    placeholder: string;
    name: string;
    options: ThemeAutocompleteOptions[];
    onChange: (value: unknown, name: string) => void;
    triggerExternalCallback?: () => void;
    className?: string;
}

export const GlobalSearchAutocompliteFilter = ({
    placeholder,
    name,
    options,
    className,
    onChange,
    triggerExternalCallback,
}: GlobalSearchAutocompliteFilterProps): ReactElement => {
    const classes = useGlobalSearchAutocompliteFilterStyles();
    const badgeClasses = useGlobalAutocompleteSearchBadgeStyles();
    const { t } = useTranslation();

    const handleChange = (name: string, value: ThemeAutocompleteOptions[]): void => {
        onChange(value, name);
    };
    return (
        <div className={clsx(classes.container, className)}>
            <ThemeAutocompleteWithBadge
                multiple
                withoutTags
                badgeValue={
                    options?.reduce(
                        (acc, item) =>
                            item.checked && item.value !== 'all' ? acc + 1 : acc,
                        0
                    ) || 0
                }
                injectedClasses={badgeClasses}
                title=''
                placeholder={t(placeholder)}
                name={name}
                options={options}
                onChange={handleChange}
                popperMinWidth={300}
                triggerExternalCallback={triggerExternalCallback}
            />
        </div>
    );
};
