import { AnyAbility } from '@casl/ability';

import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { APP_ROUTES } from 'src/routing';

export const conextAuthDefineMainPageForUser = (ability: AnyAbility): APP_ROUTES => {
    if (ability.can(AbilityActions.see, AbilitySubjects.dashboard)) {
        return APP_ROUTES.HOME;
    }

    if (ability.can(AbilityActions.see, AbilitySubjects.userManagement)) {
        return APP_ROUTES.USER_MANAGEMENT;
    }

    return null;
};
