import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { getBackgroundGradient } from 'src/contracts/contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        masterDataContainer: {
            flexDirection: 'column',
            height: '100%',
        },
        toolBar: {
            height:
                isSGIFFOXXKInstance ||
                isHectorStyles ||
                isAonDigitalInstance ||
                isAonMotorInstance ||
                isHectorDemoInstance
                    ? 110
                    : 75,
            alignItems: 'center',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return '0 120px';
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return '0 140px';
                }
                return `0 ${theme.spacing(1)}rem`;
            },
            background: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return !isLightMode
                        ? theme.palette.primary.main
                        : theme.palette.secondary.light;
                }
                if (isSGIFFOXXKInstance) {
                    return theme.palette.secondary.main;
                }
                if (isHectorStyles) {
                    return getBackgroundGradient(isLightMode, theme);
                }
                return THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light;
            },
            borderBottom:
                THEME_MODES.DARK === theme.palette.type
                    ? `1px solid rgba(255, 255, 255, 0.2)`
                    : 'none',
            [theme.breakpoints.down(
                isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                height: 80,
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        searchIcon: {
            width: 24,
            height: 24,
            opacity: 0.6,
        },
        header: {
            padding: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return `${theme.spacing(5)}px 120px`;
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return `${theme.spacing(5)}px 140px`;
                }
                return `${theme.spacing(5)}px ${theme.spacing(1)}rem`;
            },
            justifyContent: 'space-between',
            [theme.breakpoints.down(
                isHectorStyles ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px!important`,
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            color: theme.palette.primary.contrastText,
        },
        mainContentMenu: {
            width: '100%',
            flexGrow: 1,
            gap: `${theme.spacing(4)}px`,
            flexWrap: 'nowrap',
            padding: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return '0 120px';
                }
                if (isHectorStyles || isSGIFFOXXKInstance) {
                    return '0 140px';
                }
                return `0 ${theme.spacing(1)}rem ${theme.spacing(10)}px ${theme.spacing(
                    1
                )}rem`;
            },
            [theme.breakpoints.down(
                isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        clientConnectionsContainer: {
            minWidth: 350,
        },
        ccSettingsContainer: {
            flexGrow: 1,
            padding: theme.spacing(4),
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.primary.main;
                }
                return theme.palette.primary.light;
            },
        },
        formHeader: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        formControlButtons: {
            flexGrow: 1,
            gap: theme.spacing(2),
            '& button': {
                height: 40,
                transition: '0.2s',
            },
        },
        containedButton: {
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.dark;
                }
                return !isLightMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main;
            },
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.dark;
                    }
                    return !isLightMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                },
                opacity: 0.8,
            },
        },
        outlinedButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            '&:hover': {
                borderColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                opacity: 0.8,
            },
        },
        editButton: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.dark;
                }
                if (isHectorStyles) {
                    return theme.palette.info.main;
                }
                return theme.palette.info.light;
            },
            color: () => {
                if (
                    isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                ) {
                    return theme.palette.secondary.contrastText;
                }
                return !isLightMode
                    ? theme.palette.info.contrastText
                    : theme.palette.info.main;
            },
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.dark;
                    }
                    if (isHectorStyles) {
                        return theme.palette.info.main;
                    }
                    return theme.palette.info.light;
                },
            },
            '& .MuiSvgIcon-root': {
                color: () => {
                    if (isSGIFFOXXKInstance) {
                        return theme.palette.secondary.contrastText;
                    }
                },
            },
        },
        fieldsContainer: {
            width: '60%',
            gap: theme.spacing(4),
            paddingTop: theme.spacing(4),
        },
        switchContainer: {
            display: 'flex',
            height: 40,
            alignItems: 'center',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
        elementsContainer: {
            borderTop: `1px solid ${
                theme.palette.grey[
                    theme.palette.type === THEME_MODES.DARK ? '400' : '100'
                ]
            }`,
        },
        formControl: {
            margin: theme.spacing(3),
            '& .MuiFormLabel-root': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiFormLabel-root, span': {
                fontSize: '1.4rem',
                whiteSpace: 'nowrap',
                color: theme.palette.primary.contrastText,
                '&.Mui-focused': {
                    color: theme.palette.info.main,
                },
            },
            '& .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root': {
                color: theme.palette.grey.A100,
            },
            '& .MuiFormControlLabel-root .MuiButtonBase-root': {
                padding: theme.spacing(1),
            },
        },
        externalLinkSectionContainer: {
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: theme.spacing(4),
            paddingTop: theme.spacing(8),
            borderTop: `1px solid ${
                theme.palette.grey[
                    theme.palette.type === THEME_MODES.DARK ? '400' : '100'
                ]
            }`,
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return !isLightMode
                            ? 'rgba(0, 0, 0, 0)'
                            : theme.palette.primary.light;
                    }
                    return null;
                },
            },
        },
        additionalMenuIcon: {
            color: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.contrastText;
                }
                return theme.palette.info.main;
            },
        },
        additionalMenuButton: {
            width: 40,
            height: 40,
            borderRadius: 4,
            transition: '0.2s',
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.info.main;
                }
                return theme.palette.info.light;
            },
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.info.main;
                    }
                    return theme.palette.info.light;
                },
            },
        },
        deleteButton: {
            width: 40,
            height: 40,
            borderRadius: 4,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        headerTitle: {
            marginRight: theme.spacing(4),
            whiteSpace: 'nowrap',
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        okButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '40%',
            margin: `0 ${theme.spacing(4)}px 0 0`,
            '& label': {
                fontSize: '2.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                margin: () => {
                    if (isHectorStyles) {
                        return `0 ${theme.spacing(1)}px 0 0`;
                    }
                },
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.light;
                }
                return isDarkMode
                    ? theme.palette.primary.main
                    : theme.palette.primary.light;
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'flex-start',
            margin: 0,
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
            color: theme.palette.primary.contrastText,
        },
        disabled: {
            '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.contrastText,
            },
        },
    };
});
