import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from '../..';

interface StyleProps {
    popperMinWidth: number;
}

export const useThemeAonMotorStyles = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;

    return {
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.info.main,
            opacity: 1,
        },
        expandMoreIcon: {
            width: 30,
            height: 30,
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.dark,
        },
        tag: {
            color: isDarkMode
                ? theme.palette.primary.contrastText
                : theme.palette.common.black,
            fontSize: '1.8rem',
            margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
            [theme.breakpoints.down('md')]: {
                fontSize: '1.4rem',
            },
        },
        mainOptionTitle: {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    };
});

export const useThemeAutocompleteAonMotorStyles = makeStyles(
    (theme: Theme) => {
        const isDarkMode = theme.palette.type === THEME_MODES.DARK;

        return {
            root: {
                width: 280,
                '& fieldset': {
                    borderRadius: 0,
                    borderColor: !isDarkMode
                        ? `${theme.palette.secondary.main}!important`
                        : `${theme.palette.primary.contrastText}!important`,
                },
                '& .MuiFormLabel-root': {
                    color: theme.palette.primary.contrastText,
                    fontWeight: theme.typography.fontWeightBold,
                },
                '& .MuiInputLabel-outlined': {
                    transform: 'translate(14px, 12px) scale(1)',
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
            },
            inputRoot: {
                paddingTop: '0!important',
                paddingBottom: '0!important',
            },
            input: {
                caretColor: theme.palette.secondary.main,
                color: theme.palette.common.black,
                '&::placeholder': {
                    color: theme.palette.primary.contrastText,
                },
            },
            focused: {},
            endAdornment: {
                top: 8,
            },
            paper: {
                paddingTop: '0!important',
                paddingBottom: '0!important',
                backgroundColor: isDarkMode
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
            },
            listbox: {
                paddingTop: '0!important',
                paddingBottom: '0!important',
            },
            option: {
                fontSize: '1.2rem',
                paddingTop: 0,
                paddingBottom: 0,
            },
            noOptions: {
                fontSize: '1.4rem',
            },
            popper: {
                minWidth: ({ popperMinWidth }: StyleProps) => popperMinWidth,
            },
        };
    },
    { index: 1 }
);
