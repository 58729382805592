import React, { ReactElement } from 'react';

import { Grid, Theme, makeStyles } from '@material-ui/core';
import { SliceTooltipProps } from '@nivo/line';

const useDashboardLineChartTooltipStyles = makeStyles((theme: Theme) => {
    return {
        rootContainer: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: `${-6}px ${-10}px`,
            padding: `20px`,
            width: '260px',
        },
        container: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            width: '180px',
        },
        mark: {
            width: 15,
            height: 15,
            marginRight: theme.spacing(1),
        },
    };
});

interface DashboardLineChartTooltipProps {
    valueFormatter?: (val: unknown) => unknown;
    tooltipData: SliceTooltipProps;
    valueSuffix?: string;
}

export const DashboardLineChartTooltip = ({
    tooltipData,
    valueFormatter,
    valueSuffix,
}: DashboardLineChartTooltipProps): ReactElement => {
    const slice = tooltipData.slice;
    const classes = useDashboardLineChartTooltipStyles();

    return (
        <Grid container className={classes.rootContainer}>
            {slice.points.map((point) => (
                <>
                    <Grid item key={point.id} xs={6} className={classes.container}>
                        <div
                            className={classes.mark}
                            style={{ backgroundColor: point.color }}
                        />

                        <span style={{ marginRight: 'auto' }}>{point.serieId}: </span>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <span>{`${
                            valueFormatter ? valueFormatter(point.data.y) : point.data.y
                        }${valueSuffix == null ? '' : ' ' + valueSuffix}`}</span>
                    </Grid>
                </>
            ))}
        </Grid>
    );

    return (
        <div className={classes.rootContainer}>
            {slice.points.map((point) => (
                <div key={point.id} className={classes.container}>
                    <div
                        className={classes.mark}
                        style={{ backgroundColor: point.color }}
                    />
                    <div></div>
                </div>
            ))}
        </div>
    );
};
