import { TFunction } from 'i18next';
import { CheckboxItem } from 'src/theming';

export const globalSearchListGetFilterValuesFromSearchResult = <T extends unknown>(
    t: TFunction,
    columnName: string,
    arr: T[],
    columnNameForValue?: string
): CheckboxItem[] => {
    const possibleValues = {};
    arr.forEach((item) => {
        if (item[columnName]) {
            possibleValues[item[columnName]] = item[columnNameForValue];
        }
    });
    const result = Object.keys(possibleValues).map((item) => {
        return {
            title: item,
            value: possibleValues[item] || item,
            checked: false,
            data: undefined,
        };
    });
    return [...result];
};
