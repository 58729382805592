import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { Grid, Typography, Button } from '@material-ui/core';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useAuth } from 'src/user-management/context-auth';

import {
    ExpandPanel,
    ThemeExpansionPanel,
    ThemeLoader,
    ThemeModalWindow,
    ThemeCircularProgress,
} from 'src/theming';

import { FAQQuestionForm } from 'src/faq/faq-question-form/faq-question-form';
import { INTERNAL_ROLES } from 'src/shared/constants';

import { useStyle } from './faq-list-styles';

import getFaqListFormatter from './get-faq-list-formatter';

import { FAQResponse } from './faq-get-response-body';
import { companyName } from 'src/environment';

export const FAQList = (): JSX.Element => {
    const { t } = useTranslation(['faq', 'common', 'errors']);
    const classes = useStyle();

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { userData } = useAuth();

    const [faqs, setFaqs] = useState([]);
    const [isGettingFaqLoading, setGettingFaqLoading] = useState(true);
    const [editData, setEditData] = useState(null);
    const [idForRemoving, setIdForRemoving] = useState<string>('');
    const [isDeletingLoading, setDeletingLoading] = useState<boolean>(false);

    const makeRequestForFAQList = (): void => {
        setGettingFaqLoading(true);
        httpClient
            .get('faqs/')
            .then((data: FAQResponse[]) => {
                setFaqs(getFaqListFormatter(data));
            })
            .finally(() => {
                setGettingFaqLoading(false);
            });
    };

    const makeRequestForDeletingList = async (): Promise<void> => {
        setDeletingLoading(true);
        return httpClient.delete(`faqs/${idForRemoving}`).then(() => {
            setTimeout(() => {
                makeRequestForFAQList();
            }, 0);
        });
    };

    useEffect(makeRequestForFAQList, []);

    const handleEditQuestion = (panel: string): void => {
        setEditData(panel);
    };

    const handleClearEditData = (): void => {
        setEditData(null);
    };

    const handleDeleteIdForRemoving = (): void => {
        setIdForRemoving('');
    };

    const handleApproveDeleting = (): void => {
        makeRequestForDeletingList()
            .then(() => {
                enqueueSnackbar(t('deletedSuccessfully'), { variant: 'success' });
                handleDeleteIdForRemoving();
            })
            .catch(() => {
                enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
            })
            .finally(() => {
                setDeletingLoading(false);
            });
    };

    const makeRequestForNewPosition = (panels: Array<ExpandPanel>): void => {
        httpClient.put('faqs/positions', {
            faqs: panels.map((item) => ({ id: item.id })),
        });
    };

    const handleSetIdForRemoving = (id: string): void => {
        setIdForRemoving(id);
    };

    const readonly = !(
        userData?.userInfo?.roles?.includes(INTERNAL_ROLES.ADMIN_TECHNICAL) ||
        userData?.userInfo?.roles?.includes(INTERNAL_ROLES.ADMIN_DOMAIN)
    );

    return (
        <Grid container className={classes.faqListContainer}>
            <Grid item container className={classes.faqListHeader}>
                <Typography variant='h5'>
                    {companyName} {t('title')}
                </Typography>
                {!readonly && (
                    <FAQQuestionForm
                        cbAfterSuccessfulCreation={makeRequestForFAQList}
                        initialData={editData}
                        handleClearEditData={handleClearEditData}
                    />
                )}
            </Grid>
            <ThemeModalWindow
                isOpen={Boolean(idForRemoving)}
                onClose={handleDeleteIdForRemoving}
            >
                <p></p>
                <Typography variant='body1'>{t('approveDeletingText')}</Typography>
                <Grid container>
                    <Grid container className={classes.modalActionsContainer}>
                        <Button
                            disabled={isDeletingLoading}
                            variant='outlined'
                            className={clsx(classes.modalButton, classes.cancelButton)}
                            onClick={handleDeleteIdForRemoving}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button
                            disableElevation
                            variant='contained'
                            className={clsx(classes.modalButton, classes.saveButton)}
                            onClick={handleApproveDeleting}
                            startIcon={
                                <ThemeCircularProgress isLoading={isDeletingLoading} />
                            }
                        >
                            {t('common:extinguish')}
                        </Button>
                    </Grid>
                </Grid>
            </ThemeModalWindow>
            <Grid item container className={classes.faqQuestionsContainer}>
                {isGettingFaqLoading && <ThemeLoader />}
                {!isGettingFaqLoading && (
                    <ThemeExpansionPanel
                        data={faqs}
                        readonly={readonly}
                        onEdit={handleEditQuestion}
                        onDelete={handleSetIdForRemoving}
                        onDrop={makeRequestForNewPosition}
                    />
                )}
            </Grid>
        </Grid>
    );
};
