import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';

import { buildFormData } from 'src/shared/utils';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { APP_ROUTES } from 'src/routing';
import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter } from 'src/shared/contexts';

import { DummyContractDTO } from './dummy-contract-dto';
import { ContractCreateForm } from './contracts-create-contract-form';

import { useDummyContractCreationHandleErrors } from './contracts-create-contract-form-use-handle-errors';

const mutationFormatter = (
    data: ContractCreateForm,
    contractId?: string
): DummyContractDTO => {
    const formattedData: DummyContractDTO = {
        divisionName: data?.division,
        startDate: data.startOfContract
            ? moment(data.startOfContract)
                  .set({ hours: 0 })
                  .format('YYYY-MM-DDT00:00:00.000') + 'Z'
            : null,
        endDate: data?.contractExpiration
            ? moment(data.contractExpiration)
                  .set({ hours: 0 })
                  .format('YYYY-MM-DDT00:00:00.000') + 'Z'
            : null,
        annualPremiumInclusiveTaxes: Number(
            data?.netPremium?.replace(/\./g, '')?.replace(',', '.')
        ),
        insuranceCompanyName: data?.insuranceCompany || data?.addInsuranceCompany,
        currency: data?.currency,
        contractNumber: data?.contractNumber,
        risk: data?.risk,
    };
    if (Array.isArray(data.files) && data.files.length > 0 && !contractId) {
        formattedData.documents = data.files?.map((item) => item.file);
    }
    return formattedData;
};

export const useMutation = (
    contractId: string,
    customerId: string,
    onCreationSuccess?: () => void,
    onUpdatingSuccess?: () => void
): [boolean, (data: ContractCreateForm) => Promise<void>] => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['create-contract']);
    const history = useHistory();
    const { getUserData } = useAuth();
    const { refreshContractsAmounts } = useGlobalFilter();

    const { handleErrors } = useDummyContractCreationHandleErrors();

    const [isLoading, setLoading] = useState<boolean>(false);

    const mutate = async (data: ContractCreateForm): Promise<void> => {
        try {
            setLoading(true);
            const formattedData = mutationFormatter(data, contractId);
            const formData = new FormData();
            buildFormData(formData, formattedData, null);
            if (contractId) {
                await httpClient.put(`contracts/dummy/${contractId}`, formData);
                history.push(APP_ROUTES.CONTRACTS);
                enqueueSnackbar(t(`contractUpdatedSuccessfully`), {
                    variant: 'success',
                });
                refreshContractsAmounts();
                if (onUpdatingSuccess) {
                    onUpdatingSuccess();
                }
            } else {
                await httpClient.post(`contracts/dummy/${customerId}`, formData);
                history.push(APP_ROUTES.CUSTOMERS);
                enqueueSnackbar(t(`contractCreatedSuccessfully`), {
                    variant: 'success',
                });
                refreshContractsAmounts();
                if (onCreationSuccess) {
                    onCreationSuccess();
                }
            }
            getUserData();
        } catch (err) {
            handleErrors(err.error);
        } finally {
            setLoading(false);
        }
    };

    return [isLoading, mutate];
};
