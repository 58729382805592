import React, { memo } from 'react';
import { FormControl, FormControlLabel, Grid, OutlinedInput } from '@material-ui/core';

import clsx from 'clsx';
import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../damage-report-form-components-styled';

interface DamageFormDisabledInput {
    value: string;
    label: string;
    name: string;
    error?: boolean;
    handleChangeValue?: () => void;
}

export const DamageFormDisabledInput = memo(
    ({ label, value, name, error }: DamageFormDisabledInput) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        const inputClasses = useInputStyles();

        return (
            <Grid item className={clsx(classes.thirdPart)}>
                <FormControl
                    fullWidth
                    required
                    classes={formControlClasses}
                    variant='outlined'
                    error={error}
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                disabled
                                classes={inputClasses}
                                value={value}
                                name={name}
                            />
                        }
                        label={label}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
