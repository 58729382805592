import React, { ReactElement } from 'react';

import { Typography, Box, Link } from '@material-ui/core';

import { ThemeSimpleHeader } from 'src/theming';

import { useImprintPageStyles } from '../imprint/imprint-page-styles';

export const PrivacyPage = (): ReactElement => {
    const classes = useImprintPageStyles();
    return (
        <div className={classes.container}>
            <ThemeSimpleHeader />
            <Typography variant='h2' className={classes.title}>
                Datenschutzerklärung
            </Typography>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Der Schutz Ihrer Privatsphäre bei der Verarbeitung persönlicher Daten
                    ist für uns ein wichtiges Anliegen. Wenn Sie unsere Webseite besuchen,
                    speichern unsere Webserver standardmäßig die IP Ihres Internet Service
                    Provider, die Webseite, von der aus Sie uns besuchen, die Webseiten,
                    die Sie bei uns besuchen sowie das Datum und die Dauer des Besuches.
                    Diese Informationen sind für die technische Übertragung der Webseiten
                    und den sicheren Serverbetrieb zwingend erforderlich. Eine
                    personalisierte Auswertung dieser Daten erfolgt nicht.
                </Typography>
                <Typography variant='body1' className={classes.text}>
                    Sofern Sie uns Daten per Kontakt-Formular senden, werden diese Daten
                    im Zuge der Datensicherung auf unseren Servern gespeichert. Ihre Daten
                    werden von uns ausschließlich zur Bearbeitung Ihres Anliegens
                    verwendet. Ihre Daten werden streng vertraulich behandelt. Eine
                    Weitergabe an Dritte erfolgt nicht.
                </Typography>
            </Box>
            <Typography variant='h3' className={classes.blockHeader}>
                Verantwortliche Stelle
            </Typography>
            <Typography variant='body1' className={classes.text}>
                CEAT International GmbH
            </Typography>
            <Typography variant='body1' className={classes.text}>
                Postanschrift:
                <br />
                Alsweiler Str. 81
                <br />
                66646 Marpingen
            </Typography>
            <Typography variant='body1' className={classes.text}>
                Tel.: 06853 / 961 88 25
            </Typography>
            <Typography variant='body1' className={classes.text}>
                E-Mail:{' '}
                <Link href='mailto:info@ceat-international.de'>
                    info@ceat-international.de
                </Link>
            </Typography>
            <Box mt={4}>
                <Typography variant='h3' className={classes.blockHeader}>
                    Personenbezogene Daten
                </Typography>
                <Typography variant='body1' className={classes.text}>
                    Personenbezogene Daten sind Daten über Ihre Person. Diese beinhalten
                    Ihren Namen, Ihre Adresse und Ihre Email Adresse. Sie müssen auch
                    keine personenbezogenen Daten preisgeben, um unsere Internetseite
                    besuchen zu können. In einigen Fällen benötigen wir Ihren Namen und
                    Adresse sowie weitere Informationen, um Ihnen die gewünschte
                    Dienstleistung anbieten zu können.
                </Typography>
                <Typography variant='body1' className={classes.text}>
                    Das Gleiche gilt für den Fall, dass wir Sie auf Wunsch mit
                    Informationsmaterial beliefern bzw. wenn wir Ihre Anfragen
                    beantworten. In diesen Fällen werden wir Sie immer darauf hinweisen.
                    Außerdem speichern wir nur die Daten, die Sie uns automatisch oder
                    freiwillig übermittelt haben.
                </Typography>
                <Typography variant='body1' className={classes.text}>
                    Wenn Sie einen unserer Services nutzen, sammeln wir in der Regel nur
                    die Daten, die notwendig sind, um Ihnen unseren Service bieten zu
                    können. Möglicherweise fragen wir Sie nach weiteren Informationen, die
                    aber freiwilliger Natur sind. Wann immer wir personenbezogene Daten
                    verarbeiten, tun wir dies, um Ihnen unseren Service anbieten zu können
                    oder um unsere kommerziellen Ziele zu verfolgen.
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Automatisch gespeicherte Daten / Cookies
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Diese Seite verwendet keine Cookies.
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Sicherheit
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Wir haben technische und administrative Sicherheitsvorkehrungen
                    getroffen, um Ihre personenbezogenen Daten gegen Verlust, Zerstörung,
                    Manipulation und unautorisierten Zugriff zu schützen. All unsere
                    Mitarbeiter sowie für uns tätige Dienstleister sind auf die gültigen
                    Datenschutzgesetze verpflichtet.
                </Typography>
            </Box>

            <Typography variant='body1' className={classes.text}>
                Wann immer wir personenbezogene Daten sammeln und verarbeiten, werden
                diese verschlüsselt, bevor sie übertragen werden. Das heißt, dass Ihre
                Daten nicht von Dritten missbraucht werden können. Unsere
                Sicherheitsvorkehrungen unterliegen dabei einem ständigen
                Verbesserungsprozess und unsere Datenschutzerklärungen werden ständig
                überarbeitet. Bitte stellen Sie sicher, dass Ihnen die aktuelle Version
                vorliegt.
            </Typography>

            <Box mb={4} mt={4}>
                <Typography variant='body1' className={classes.text}>
                    Betroffenenrechte
                </Typography>
            </Box>
            <Typography variant='body1' className={classes.text}>
                Bitte kontaktieren Sie uns jederzeit, wenn Sie sich informieren möchten,
                welche personenbezogenen Daten wir über Sie speichern bzw. wenn Sie diese
                berichtigen oder löschen lassen wollen. Des Weiteren haben Sie das Recht
                auf Einschränkung der Verarbeitung (Art. 18 DSGVO), ein Widerspruchsrechts
                gegen die Verarbeitung (Art. 21 DSGVO) sowie das Recht auf
                Datenübertragbarkeit (Art. 20 DSGVO).
            </Typography>
            <Typography variant='body1' className={classes.text}>
                In diesen Fällen wenden Sie sich bitte direkt an uns.
            </Typography>
            <Box mb={4} mt={4}>
                <Typography variant='body1' className={classes.text}>
                    Änderungen dieser Datenschutzerklärung
                </Typography>
            </Box>
            <Typography variant='body1' className={classes.text}>
                Wir behalten uns das Recht vor, unsere Datenschutzerklärungen zu ändern,
                falls dies aufgrund neuer Technologien notwendig sein sollte. Bitte
                stellen Sie sicher, dass Ihnen die aktuelle Version vorliegt. Werden an
                dieser Datenschutzerklärung grundlegende Änderungen vorgenommen, geben wir
                diese auf unserer Website bekannt.
            </Typography>
            <Box mb={4} mt={4}>
                <Typography variant='body1' className={classes.text}>
                    Alle Interessenten und Besucher unserer Internetseite erreichen uns in
                    Datenschutzfragen unter:
                </Typography>
            </Box>
            <Typography variant='body1' className={classes.text}>
                David Ranko, Geschäftsführer
            </Typography>
            <Typography variant='body1' className={classes.text}>
                CEAT International GmbH
            </Typography>
            <Typography variant='body1' className={classes.text}>
                Postanschrift:
                <br />
                Alsweiler Str. 81
                <br />
                66646 Marpingen
            </Typography>
            <Typography variant='body1' className={classes.text}>
                Tel.: 06853 / 961 88 25
            </Typography>
            <Typography variant='body1' className={classes.text}>
                E-Mail:{' '}
                <Link href='mailto:info@ceat-international.de'>
                    info@ceat-international.de
                </Link>
            </Typography>
            <Box mb={4} mt={4}>
                <Typography variant='body1' className={classes.text}>
                    Sollten wir Ihr Anliegen nicht zu Ihrer Zufriedenheit beantworten
                    können, bleibt Ihnen in jedem Falle das Recht auf Beschwerde bei der
                    für unser Unternehmen zuständigen Datenschutz-Aufsichtsbehörde dem
                    Landesamt für Datenschutz Bayern.
                </Typography>
            </Box>
        </div>
    );
};
