export enum DashboardWidgets {
    DAMAGE_RATIO_GENERAL = 'damageRatioGeneral',
    DAMAGE_RATIO_OVERALL = 'damageRatioOverall',
    DAMAGE_RATIO_LIABILITY = 'damageRatioLiability',
    DAMAGE_RATIO_COMPREHENSIVE_INSURANCE = 'damageRatioComprehensiveInsurance',
    BOOKED_PREMIUM_GENERAL = 'bookedPremiumGeneral',
    BOOKED_PREMIUM_OVERALL = 'bookedPremiumOverall',
    BOOKED_PREMIUM_LIABILITY = 'bookedPremiumLiability',
    BOOKED_PREMIUM_COMPREHENSIVE_INSURANCE = 'bookedPremiumComprehensiveInsurance',
    AMOUNT_ACTIVE_CONTRACTS = 'amountActiveContracts',
    PARTIAL_COVERAGE = 'partialCoverage',
    EXPENSE_GENERAL = 'expenseGeneral',
    EXPENSE_OVERALL = 'expenseOverall',
    EXPENSE_LIABILITY = 'expenseLiability',
    EXPENSE_COMPREHENSIVE_INSURANCE = 'expenseComprehensiveInsurance',
    SUM_YEARLY_NET_PREMIUM = 'sumYearlyNetPremium',
    TOTAL_RESERVE_ALL_OPEN_CLAIMS = 'totalReserveAllTotalClaims',
    OPEN_CLOSED_DAMAGES = 'openClosedDamages',
    DAMAGES_EXPENSE_BY_MONTH = 'damagesExpenseByMonth',
    DAMAGES_COUNT_BY_MONTH = 'damagesCountByMonth',
    DAMAGES_AMOUNT_BY_STATUS = 'damagesAmountByStatus',
    OPEN_DAMAGES_AMOUNT_BY_STATUS = 'openDamagesAmountByStatus',
    DAMAGE_FREQUENCY_GENERAL = 'damageFrequencyGeneral',
    DAMAGE_FREQUENCY_LIABILITY = 'damageFrequencyLiability',
    DAMAGE_FREQUENCY_COMPREHENSIVE = 'damageFrequencyComprehensive',
    DAMAGE_AVERAGE_GENERAL = 'damageAverageGeneral',
    DAMAGE_AVERAGE_LIABILITY = 'damageAverageLiability',
    DAMAGE_AVERAGE_COMPREHENSIVE = 'damageAverageComprehensive',
}
