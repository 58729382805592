import React from 'react';

import { useNotificationCenterStyle } from './notification-center-styles';

interface NotificationCenterLayoutProps {
    children: JSX.Element[];
}

export const NotificationCenterLayout = ({
    children,
}: NotificationCenterLayoutProps): JSX.Element => {
    const classes = useNotificationCenterStyle();
    return (
        <div>
            <header className={classes.headerLayout}>{children[0]}</header>
            <main>{children[1]}</main>
        </div>
    );
};
