import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import {
    useTabsStyle,
    useTabStyle,
    useHectorTabStyles,
    useHectorTabsStyle,
    useSgiffoxxkTabStyles,
    useSgiffoxxkTabsStyle,
    useAonMotorTabsStyle,
    useAonMotorTabStyles,
} from './theme-tabs-styles';

import { Tabs, Tab } from '@material-ui/core';
import { SyntheticEvent } from 'react';

export interface IThemeTab {
    title: string;
    value: string;
    path?: string;
    action?: () => void;
    titleAsKeyForTranslation?: boolean;
}

interface ThemeTabsProps {
    tabs: IThemeTab[];
    isHectorStyles?: boolean;
    isSgiffoxxkStyles?: boolean;
    isAonMotorStyles?: boolean;
    activeTab?: string;
    namespaces?: string[];
    tabsClassName?: string;
    disabled?: boolean;
}

export const ThemeTabs = (props: ThemeTabsProps): JSX.Element => {
    const {
        tabs,
        activeTab,
        namespaces,
        tabsClassName,
        disabled,
        isSgiffoxxkStyles,
        isHectorStyles,
        isAonMotorStyles,
    } = props;

    const { t } = useTranslation(namespaces);
    const darkMode = useDarkMode();

    const history = useHistory();

    const tabsClasses = useTabsStyle();
    const tabClasses = useTabStyle();
    const hectorTabsClasses = useHectorTabsStyle();
    const hectorTabClasses = useHectorTabStyles();
    const sgiffoxxkTabsClasses = useSgiffoxxkTabsStyle();
    const sgiffoxxkTabClasses = useSgiffoxxkTabStyles();
    const aonMotorTabsClasses = useAonMotorTabsStyle();
    const aonMotorTabClasses = useAonMotorTabStyles();

    useEffect(() => {
        window.dispatchEvent(new CustomEvent('resize'));
    }, []);

    const handleOnChangeTab = (_: SyntheticEvent, value): void => {
        const tab = tabs.find((tab) => value === tab.value);
        if (tab?.path) {
            history.push(tab.path);
            return;
        }
        if (tab?.action) {
            tab.action();
        }
    };

    const currentTabsClasses = useMemo(() => {
        if (isHectorStyles) {
            return hectorTabsClasses;
        }
        if (isSgiffoxxkStyles) {
            return sgiffoxxkTabsClasses;
        }
        if (isAonMotorStyles) {
            return aonMotorTabsClasses;
        }
        return tabsClasses;
    }, [darkMode.value]);

    const currentTabClasses = useMemo(() => {
        if (isHectorStyles) {
            return hectorTabClasses;
        }
        if (isSgiffoxxkStyles) {
            return sgiffoxxkTabClasses;
        }
        if (isAonMotorStyles) {
            return aonMotorTabClasses;
        }
        return tabClasses;
    }, [darkMode.value]);

    return (
        <Tabs
            value={activeTab}
            classes={currentTabsClasses}
            className={tabsClassName}
            onChange={handleOnChangeTab}
        >
            {tabs.map(({ title, value, titleAsKeyForTranslation }) => {
                return (
                    <Tab
                        disableRipple
                        disabled={disabled}
                        key={value}
                        label={titleAsKeyForTranslation ? t(title) : title}
                        classes={currentTabClasses}
                        value={value}
                    />
                );
            })}
        </Tabs>
    );
};

ThemeTabs.defaultProps = {
    instanceStyles: 'general',
};
