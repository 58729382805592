import { makeStyles, Theme } from '@material-ui/core/styles';

export const useThemeHeaderAonStyle = makeStyles((theme: Theme) => {
    return {
        header: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            maxHeight: 75,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: `${theme.spacing(3)}px ${theme.spacing(1)}rem`,
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
            },
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        portalLogo: {
            cursor: 'pointer',
        },
        departmentFiltersContainer: {
            flexWrap: 'nowrap',
            width: 'fit-content',
            minWidth: 700,
            padding: `0 ${theme.spacing(6)}px`,
            gap: 20,
            '& .MuiInputBase-root,.MuiAutocomplete-root': {
                height: 'auto',
            },
            '& .MuiChip-root': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            },
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(1)}px`,
                minWidth: 600,
            },
        },
        departmentFilter: {
            width: '50%',
            minWidth: 200,
            alignSelf: 'center',
            '& p': {
                maxWidth: '160px',
                paddingTop: 5,
                paddingBottom: 5,
                overflow: 'hidden',
            },
            '& .MuiAutocomplete-root': {
                width: '100%',
            },
        },
        sectionsNavigationContainer: {
            maxWidth: 400,
            minWidth: 350,
            justifyContent: 'space-between',
            '& button': {
                fontSize: '1.4rem',
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                color: theme.palette.secondary.contrastText,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: theme.palette.info.contrastText,
                    color: theme.palette.secondary.main,
                    opacity: 0.7,
                },
            },
        },
        switcherLabel: {
            color: theme.palette.secondary.contrastText,
        },
        switcherContainer: {
            width: 'fit-content',
            margin: `0 ${theme.spacing(5)}px`,
            [theme.breakpoints.down('md')]: {
                margin: `0 ${theme.spacing(1)}px`,
            },
        },
        openProfileButton: {
            paddingLeft: theme.spacing(5),
            fontSize: '1.4rem',
            color: theme.palette.secondary.contrastText,
            borderLeft: `1px solid ${theme.palette.primary.light}`,
            borderRadius: 0,
        },
        logoWrapper: {},
        customerLogo: {
            cursor: 'pointer',
        },
        logo: {
            cursor: 'pointer',
        },
        customerLogoContainer: {
            flexGrow: 1,
            minWidth: 100,
            maxWidth: 250,

            '& img': {
                width: '100%',
            },
        },
        administrationNavigationContainer: {
            margin: '0 auto',
            minWidth: 'auto',
            maxWidth: 'fit-content',
            width: 'fit-content',
            '& .MuiButton-root': {
                marginRight: theme.spacing(2),
            },
        },
        activeSectionButton: {
            backgroundColor: theme.palette.primary.main,
            color: `${theme.palette.primary.contrastText}!important`,
        },
    };
});
