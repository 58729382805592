import React, { useState, useRef, useEffect } from 'react';
import { GridCellParams, isOverflown } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    createStyles,
    makeStyles,
    Paper,
    Typography,
    Popper,
    Theme,
} from '@material-ui/core';

interface ThemeTableCellExpandProps {
    value: string;
    width: number;
    asLink?: boolean;
    asCurrency?: boolean;
    link?: string;
    asDate?: boolean;
    align?: string;
    renderCellExpandValueAsKeyForTranslation?: boolean;
    namespaces?: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            '& .cellValue': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        linkCell: {
            color: theme.palette.primary.contrastText,
            textDecoration: 'underline',
            '&:hover': {
                color: theme.palette.info.main,
            },
        },
    })
);

export const ThemeTableCellExpand = (props: ThemeTableCellExpandProps): JSX.Element => {
    const classes = useStyles();

    const {
        width,
        value,
        asLink,
        link,
        asCurrency,
        asDate,
        align,
        renderCellExpandValueAsKeyForTranslation,
        namespaces,
    } = props;

    const { t } = useTranslation([
        'formatted-values',
        'user-management',
        ...(namespaces || []),
    ]);

    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = useState(false);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = (): void => {
        const isCurrentlyOverflown = isOverflown(cellValue?.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = (): void => {
        setShowFullCell(false);
    };

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent): void {
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <div
            ref={wrapper}
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ justifyContent: align || 'flex-start' }}
        >
            <div
                ref={cellDiv}
                style={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
            />
            <div ref={cellValue} className='cellValue'>
                {!asLink &&
                    !asCurrency &&
                    !asDate &&
                    (renderCellExpandValueAsKeyForTranslation ? t(value) : value)}
                {asLink && (
                    <Link to={link} className={classes.linkCell}>
                        {value}
                    </Link>
                )}
                {asCurrency && t('formattedCurrency', { currency: value })}
                {asDate && t('formattedDate', { date: value })}
            </div>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl != null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17, overflowWrap: 'break-word' }}
                >
                    <Paper
                        elevation={1}
                        style={{ minHeight: wrapper.current?.offsetHeight - 3 }}
                    >
                        <Typography variant='body2' style={{ padding: 8 }}>
                            {!asLink &&
                                !asCurrency &&
                                !asDate &&
                                (renderCellExpandValueAsKeyForTranslation
                                    ? t(value)
                                    : value)}
                            {asLink && (
                                <Link to={link} className={classes.linkCell}>
                                    {value}
                                </Link>
                            )}
                            {asCurrency && t('formattedCurrency', { currency: value })}
                            {asDate && t('formattedDate', { date: value })}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </div>
    );
};

export const renderCellExpandValueAsKeyForTranslation = (
    params: GridCellParams,
    namespaces: string[]
): JSX.Element => {
    return (
        <ThemeTableCellExpand
            renderCellExpandValueAsKeyForTranslation
            namespaces={namespaces}
            align={params.colDef.align}
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.width}
        />
    );
};

export const renderCellExpand = (params: GridCellParams): JSX.Element => {
    return (
        <ThemeTableCellExpand
            align={params.colDef.align}
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.width}
        />
    );
};

export const renderCellAsLinkExpand = (params: GridCellParams): JSX.Element => {
    return (
        <ThemeTableCellExpand
            asLink
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.width}
            link={params.row.links[params.field]}
        />
    );
};

export const renderCellAsCurrencyExpand = (params: GridCellParams): JSX.Element => {
    return (
        <ThemeTableCellExpand
            asCurrency
            align={params.colDef.align === 'right' && 'flex-end'}
            value={params.value ? params.value.toString() : '0'}
            width={params.colDef.width}
        />
    );
};

export const renderCellAsDateExpand = (params: GridCellParams): JSX.Element => {
    return (
        <ThemeTableCellExpand
            asDate
            value={params.value ? params.value.toString() : ''}
            width={params.colDef.width}
        />
    );
};
