import { TFunction } from 'i18next';

import { CheckboxItem } from 'src/theming';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import { DamagesListTableSettings } from './damages-list-table-settings';
import { ColumnKeysForDamageListWithCreatedStatus } from './damages-list-generate-table-for-created-status';

export const generateCreatedDamageListMenuForTableSettings = (
    t: TFunction,
    data?: DamagesListTableSettings
): CheckboxItem[] => {
    return [
        {
            title: t('damages-list:tableHeaders.vnr'),
            value: ColumnKeysForDamageListWithCreatedStatus.VNR,
            checked: data?.columns.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.VNR
            )?.isShown,
            data: data?.columns?.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.VNR
            ),
        },
        {
            title: t('damages-list:tableHeaders.client'),
            value: ColumnKeysForDamageListWithCreatedStatus.CLIENT,
            checked: data?.columns.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.CLIENT
            )?.isShown,
            data: data?.columns?.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.CLIENT
            ),
        },
        {
            title: t('damages-list:tableHeaders.risk'),
            value: ColumnKeysForDamageListWithCreatedStatus.RISK,
            checked: data?.columns.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.RISK
            )?.isShown,
            data: data?.columns?.find(
                (item) => item.name === ColumnKeysForDamageListWithCreatedStatus.RISK
            ),
        },
        {
            title: t('damages-list:tableHeaders.insuranceCompany'),
            value: ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY,
            checked: data?.columns.find(
                (item) =>
                    item.name ===
                    ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY
            )?.isShown,
            data: data?.columns?.find(
                (item) =>
                    item.name ===
                    ColumnKeysForDamageListWithCreatedStatus.INSURANCE_COMPANY
            ),
        },
        {
            title: t('damages-list:tableHeaders.claimDay'),
            value: ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY,
            checked: data?.columns.find(
                (item) =>
                    item.name === ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY
            )?.isShown,
            data: data?.columns?.find(
                (item) =>
                    item.name === ColumnKeysForDamageListWithCreatedStatus.DAMAGE_DAY
            ),
        },
        ...(appInstance !== AppInstances.AON_KOMPOSIT
            ? [
                  {
                      title: t('damages-list:tableHeaders.liability'),
                      value: ColumnKeysForDamageListWithCreatedStatus.LIABILITY,
                      checked: data?.columns.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.LIABILITY
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.LIABILITY
                      ),
                  },
                  {
                      title: t('damages-list:tableHeaders.fullyComprehensive'),
                      value: ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE,
                      checked: data?.columns.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.FULLY_COMPREHENSIVE
                      ),
                  },
                  {
                      title: t('damages-list:tableHeaders.partialCoverage'),
                      value: ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE,
                      checked: data?.columns.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE
                      )?.isShown,
                      data: data?.columns?.find(
                          (item) =>
                              item.name ===
                              ColumnKeysForDamageListWithCreatedStatus.PARTIAL_COVERAGE
                      ),
                  },
              ]
            : []),
    ];
};
