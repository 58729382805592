import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Grid, Typography } from '@material-ui/core';

import { ThemeLoader } from 'src/theming';

import { PrivilegesSelectCell } from './privileges-select-cell';

import { useStyle } from './privileges-styles';

import { generatePrivilegesListTableHeader } from './privileges-configs';
import { generatePrivilegesData } from './privileges-generate-data';

import { UserManagementNavigationPanel } from 'src/user-management/user-management-navigation-panel/user-management-navigation-panel';

export const Privileges = (): JSX.Element => {
    const ref = useRef(null);

    const [rowsMaxHeight, setRowsMaxHeight] = useState(0);
    const [isMounted, setMounted] = useState(false);

    const classes = useStyle({ rowsMaxHeight });

    const { t } = useTranslation(['user-management']);

    const roles = useMemo(() => generatePrivilegesListTableHeader(t), [t]);

    const data = useMemo(() => generatePrivilegesData(t), [t]);

    useEffect(() => {
        if (!isMounted) {
            setMounted(true);
        }
        if (ref.current) {
            setRowsMaxHeight(ref.current?.offsetHeight - 100);
        }
    }, [ref, isMounted]);

    return (
        <>
            {isMounted && (
                <Grid container className={classes.privilegesContainer}>
                    <Grid container item className={classes.toolBar}>
                        <UserManagementNavigationPanel activeMenu='privileges' />
                    </Grid>
                    <Grid container item className={classes.header}>
                        <Typography variant='h5' className={classes.title}>
                            {t('user-management:privilegesSection.title')}
                        </Typography>
                    </Grid>
                    <Grid container ref={ref} item className={classes.content}>
                        <div className={classes.tableContainer}>
                            <Grid container className={classes.userGroupTitlesContainer}>
                                <Grid
                                    item
                                    className={clsx(classes.groupTitle, classes.cell)}
                                />
                                <Grid
                                    item
                                    container
                                    alignItems='center'
                                    className={classes.groupTitle}
                                >
                                    <Typography variant='subtitle1'>
                                        {t('user-management:userGroups.internal')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    alignItems='center'
                                    className={classes.groupTitle}
                                >
                                    <Typography variant='subtitle1'>
                                        {t('user-management:userGroups.customer')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    alignItems='center'
                                    className={classes.groupTitle}
                                >
                                    <Typography variant='subtitle1'>
                                        {t('user-management:userGroups.external')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item className={classes.rolesContainer}>
                                {roles.map((role) => (
                                    <Grid
                                        item
                                        key={role.id}
                                        className={clsx(classes.roleCell, classes.cell)}
                                    >
                                        {role.headerName}
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container item className={classes.rowsContainer}>
                                {data.map((row) => {
                                    return (
                                        <Grid
                                            container
                                            item
                                            key={row.id}
                                            className={classes.row}
                                        >
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                {row.fieldName}
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.adminTechnical}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.adminDomain}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.customerAdviser}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.accountOwner}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.administrator}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell value={row.clerk} />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.insurance}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.supplier}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={clsx(
                                                    classes.cell,
                                                    classes.selectCell
                                                )}
                                            >
                                                <PrivilegesSelectCell
                                                    value={row.presentation}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            )}
            {!isMounted && <ThemeLoader />}
        </>
    );
};
