import { Theme } from '@nivo/core';
import React from 'react';
export interface NivoTheme {
    theme: Theme;
    themedColors: string[];
}

const NivoThemeContext = React.createContext<NivoTheme>(undefined);

export default NivoThemeContext;
