import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum ROOT_CAUSE_SELECTION_VALUES {
    TURN = 'turn',
    AGREEMENT_FROM_THE_CARRIAGE_WAY = 'agreementFromTheCarriageway',
    ALCOHOL_DRUGS = 'alcoholDrugs',
    REAR_AND_COLISION = 'rearEndColision',
    BBB_DAMAGE = 'bbbDamage',
    FIRE_EXPLOSION = 'fireExplosion',
    THEFT = 'theft',
    LANE_CHANGE = 'laneChange',
    VEHICLE_HEIGHT = 'vehicleHeight',
    BROKEN_GLASS = 'brokenGlass',
    GLASS_REPAIR = 'glassRepair',
    UNINSURED_EVENT = 'uninsuredEvent',
    BREACH_OF_DUTY = 'breachOfDuty',
    GLITCH = 'glitch',
    MANEUVERING_TURNING = 'maneuveringTurning',
    REGRESS = 'regress',
    STORM_HAIL = 'stromHail',
    ANIMAL_BITE_DAMAGE = 'animalBiteDamage',
    OVERTAKING = 'overtaking',
    VANDALISM = 'vandalism',
    RIGHT_OF_WAY = 'rightOfWay',
    GAME_DAMAGE = 'gameDamage',
    MISCELLANEOUS = 'miscellaneous',
}

export const generateDamageReportFormRootCauseSelectionValues = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:rootCauseOptions.turn'),
            value: ROOT_CAUSE_SELECTION_VALUES.TURN,
        },
        {
            title: t('damages-report:rootCauseOptions.agreementFromTheCarriageway'),
            value: ROOT_CAUSE_SELECTION_VALUES.AGREEMENT_FROM_THE_CARRIAGE_WAY,
        },
        {
            title: t('damages-report:rootCauseOptions.alcoholDrugs'),
            value: ROOT_CAUSE_SELECTION_VALUES.ALCOHOL_DRUGS,
        },
        {
            title: t('damages-report:rootCauseOptions.rearEndColision'),
            value: ROOT_CAUSE_SELECTION_VALUES.REAR_AND_COLISION,
        },
        {
            title: t('damages-report:rootCauseOptions.bbbDamage'),
            value: ROOT_CAUSE_SELECTION_VALUES.BBB_DAMAGE,
        },
        {
            title: t('damages-report:rootCauseOptions.fireExplosion'),
            value: ROOT_CAUSE_SELECTION_VALUES.FIRE_EXPLOSION,
        },
        {
            title: t('damages-report:rootCauseOptions.theft'),
            value: ROOT_CAUSE_SELECTION_VALUES.THEFT,
        },
        {
            title: t('damages-report:rootCauseOptions.laneChange'),
            value: ROOT_CAUSE_SELECTION_VALUES.LANE_CHANGE,
        },
        {
            title: t('damages-report:rootCauseOptions.vehicleHeight'),
            value: ROOT_CAUSE_SELECTION_VALUES.VEHICLE_HEIGHT,
        },
        {
            title: t('damages-report:rootCauseOptions.brokenGlass'),
            value: ROOT_CAUSE_SELECTION_VALUES.BROKEN_GLASS,
        },
        {
            title: t('damages-report:rootCauseOptions.glassRepair'),
            value: ROOT_CAUSE_SELECTION_VALUES.GLASS_REPAIR,
        },
        {
            title: t('damages-report:rootCauseOptions.uninsuredEvent'),
            value: ROOT_CAUSE_SELECTION_VALUES.UNINSURED_EVENT,
        },
        {
            title: t('damages-report:rootCauseOptions.breachOfDuty'),
            value: ROOT_CAUSE_SELECTION_VALUES.BREACH_OF_DUTY,
        },
        {
            title: t('damages-report:rootCauseOptions.glitch'),
            value: ROOT_CAUSE_SELECTION_VALUES.GLITCH,
        },
        {
            title: t('damages-report:rootCauseOptions.maneuveringTurning'),
            value: ROOT_CAUSE_SELECTION_VALUES.MANEUVERING_TURNING,
        },
        {
            title: t('damages-report:rootCauseOptions.regress'),
            value: ROOT_CAUSE_SELECTION_VALUES.REGRESS,
        },
        {
            title: t('damages-report:rootCauseOptions.stromHail'),
            value: ROOT_CAUSE_SELECTION_VALUES.STORM_HAIL,
        },
        {
            title: t('damages-report:rootCauseOptions.animalBiteDamage'),
            value: ROOT_CAUSE_SELECTION_VALUES.ANIMAL_BITE_DAMAGE,
        },
        {
            title: t('damages-report:rootCauseOptions.overtaking'),
            value: ROOT_CAUSE_SELECTION_VALUES.OVERTAKING,
        },
        {
            title: t('damages-report:rootCauseOptions.vandalism'),
            value: ROOT_CAUSE_SELECTION_VALUES.VANDALISM,
        },
        {
            title: t('damages-report:rootCauseOptions.rightOfWay'),
            value: ROOT_CAUSE_SELECTION_VALUES.RIGHT_OF_WAY,
        },
        {
            title: t('damages-report:rootCauseOptions.gameDamage'),
            value: ROOT_CAUSE_SELECTION_VALUES.GAME_DAMAGE,
        },
        {
            title: t('damages-report:rootCauseOptions.miscellaneous'),
            value: ROOT_CAUSE_SELECTION_VALUES.MISCELLANEOUS,
        },
    ];
};
