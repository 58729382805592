import { makeStyles, Theme } from '@material-ui/core';

export const useImprintPageStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: `${theme.spacing(5)}px 140px`,
            [theme.breakpoints.down('md')]: {
                height: 80,
                padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
            },
        },
        title: {
            fontSize: '2.5rem',
            margin: `1em 0`,
        },
        blockHeader: {
            fontSize: '2.3rem',
            margin: `0.5em 0`,
        },
        text: {
            fontSize: '1.6rem',
            fontFamily: 'Roboto Light',
        },
    };
});
