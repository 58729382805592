import { Button, FormControlLabel, Link, OutlinedInput } from '@material-ui/core';
import React, { useState } from 'react';
import {
    useFormControlLabelStyles,
    useInputStyles,
    useStyle,
} from 'src/user-management/styles';
import { useTranslation } from 'react-i18next';
import { LoginFormProps } from 'src/user-management/login/login-form';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useAuth } from 'src/user-management/context-auth';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { getAllLoginErrors } from 'src/user-management/get-all-login-errors';

export const LoginTwoFactor = (props: LoginFormProps): JSX.Element => {
    const history = useHistory();
    const { darkMode } = props;
    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const { t } = useTranslation(['user-management', 'errors']);
    const httpClient = useHttpClient();
    const {
        confirmTwoFactor,
        sendTwoFactorCode,
        authorized,
        homePath,
        requireTwoFactorVerification,
    } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const verifyTwoFactor = async (): Promise<void> => {
        try {
            const response = await httpClient.post<boolean>('two-factor/verify-login', {
                code: twoFactorCode,
            });
            if (response === true) {
                confirmTwoFactor();
            }
        } catch (errResponse) {
            const errors = getAllLoginErrors(errResponse);
            if (errors.length > 0) {
                errors.forEach((error) => {
                    enqueueSnackbar(t(`user-management:errorResponses.${error}`), {
                        variant: 'error',
                    });
                });
            } else {
                enqueueSnackbar(t('errors:unknownError'), {
                    variant: 'error',
                });
            }
        }
    };

    if (authorized && homePath && !requireTwoFactorVerification) {
        return <Redirect to={`${homePath}${history.location.search}`} />;
    }

    return (
        <>
            <FormControlLabel
                classes={formControlLabelClasses}
                control={
                    <OutlinedInput
                        id='two-factor-code'
                        placeholder={t('placeholders.enterTwoFactorCode')}
                        classes={inputClasses}
                        value={twoFactorCode}
                        onChange={(e) => setTwoFactorCode(e.target.value)}
                    />
                }
                label={t('fields.twoFactorCode')}
                labelPlacement='top'
            />

            <Button
                id='two-factor-verify-button'
                disableElevation
                className={classes.submitButton}
                variant='contained'
                name='two-factor-verify-btn'
                onClick={verifyTwoFactor}
            >
                {t('verifyTwoFactor')}
            </Button>

            <Link
                onClick={sendTwoFactorCode}
                className={classes.forgotPasswordLink}
            >{`${t('sendTwoFactorCode')}`}</Link>
        </>
    );
};
