import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useHectorStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: '0 140px',

            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${theme.spacing(1)}px!important`,
            },
        },
        titleContainer: {
            width: '100%',
            height: 75,
            alignItems: 'center',
        },
        backButton: {
            marginRight: theme.spacing(2),
            borderRadius: 0,
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 20,
            },
        },
        title: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightMedium,
        },
        cardsContainer: {
            width: '100%',
            margin: 0,
        },
        contractDataContainer: {
            width: '65%',
            minHeight: '320px',
            [theme.breakpoints.down('lg')]: {
                width: '60%',
            },
        },
        documentsContainer: {
            width: '35%',
            '& .MuiDataGrid-footer': {
                display: 'none',
            },
            '& .MuiDataGrid-root': {
                height: '90%',
                marginTop: theme.spacing(2),
            },
            '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('lg')]: {
                width: '40%',
            },
        },
        contractDetailsContainer: {
            width: '100%',
        },
        cardTitle: {
            width: '100%',
            fontSize: '1.8rem',
            color: !isDarkMode
                ? theme.palette.secondary.main
                : theme.palette.secondary.contrastText,
        },
        halfSize: {
            width: '50%',
        },
        documentLink: {
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'right',
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                marginRight: 5,
                transform: 'rotate(45deg)',
            },
            '&:hover': {
                textDecoration: 'none',
                opacity: 0.8,
            },
        },
        contractDetailsButtonsContainer: {
            maxWidth: 250,
            justifyContent: 'space-between',
            marginBottom: 8,
        },
        sectionButton: {
            marginBottom: theme.spacing(6),
            color: theme.palette.primary.contrastText,
            borderColor: isDarkMode && theme.palette.info.main,
        },
        activeButton: {
            backgroundColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
            color: theme.palette.info.contrastText,
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            },
        },
        chosenSection: {
            width: '100%',
        },
        titleAndMenuContainer: {
            width: '100%',
        },
        menuIcon: {
            width: 20,
            height: 20,
            color: theme.palette.info.main,
        },
        additionalMenuButton: {
            width: 40,
            height: 40,
            borderRadius: 0,
            backgroundColor: theme.palette.info.light,
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.info.light,
                opacity: 0.8,
            },
        },
        dateSearchFieldClass: {
            '& .MuiInputBase-root': {
                display: 'none',
            },
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.contrastText,
            },
        },
        damageTableContainer: {
            height: 330,
        },
        listItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        buttonGroups: {
            '& .MuiButtonBase-root': {
                fontSize: '1.6rem',
            },
        },
    };
});

export const useHectorPaperStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        root: {
            display: 'flex',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            height: '100%',
            padding: theme.spacing(4),
            borderRadius: 4,
            border: `1px solid ${
                !isLightMode ? theme.palette.grey['400'] : theme.palette.grey['200']
            }`,
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
        },
    };
});

export const useContractDetailsViewDataHectorStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        list: {
            width: '25%',
            '& .MuiListItem-root': {
                padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                    1
                )}px ${theme.spacing(2)}px`,
            },
            '& .MuiListItemText-root': {
                width: '100%',
                display: 'flex',
                margin: 0,
                wordBreak: 'break-all',
                color: theme.palette.primary.contrastText,
                '&[data-title]': {
                    opacity: 0.8,
                },
                '&[data-value]': {
                    paddingLeft: theme.spacing(2),
                },
            },
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        okButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
    };
});
