import React from 'react';
import { TFunction } from 'i18next';

import { Tooltip } from '@material-ui/core';

import { ThemeAutocompleteOptions } from '../../theme-autocomplete';
import { useThemeAutocompleteMultipleValuesTooltipStyles } from './ThemeAutocompleteMultipleValuesStyles';

interface MultipleValuesProps {
    t: TFunction;
    className: string;
    values: ThemeAutocompleteOptions[];
}

export const MultipleValues = ({
    className,
    values,
    t,
}: MultipleValuesProps): JSX.Element => {
    const tooltipClasses = useThemeAutocompleteMultipleValuesTooltipStyles();
    const itemsForTooltip = values.map((item) => {
        return (
            <p key={item.value} className={className}>
                {item.title}
            </p>
        );
    });

    return (
        <Tooltip title={itemsForTooltip} placement='bottom' classes={tooltipClasses}>
            <p className={className} style={{ cursor: 'pointer' }}>
                {t('several')}
            </p>
        </Tooltip>
    );
};
