import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

export const getUploadDocumentDescription = (): string => {
    switch (appInstance) {
        case AppInstances.AON:
        case AppInstances.SGIFFOXXK:
        case AppInstances.AGRAVIS: {
            return 'contract-details-view:contractDetails.uploadDocument.description';
        }
        case AppInstances.AON_KOMPOSIT: {
            return 'contract-details-view:contractDetails.uploadDocument.AONKomposit.description';
        }
        default: {
            return null;
        }
    }
};
