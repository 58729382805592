import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';
import { CUSTOMER_ROLES } from 'src/shared/constants';

enum AccountManagerItems {
    ACCOUNT_MANAGER = 'AccountManager',
    NONE = 'None',
}

export const generateAccountManagerItems = (t: TFunction): SelectItem[] => [
    {
        title: t('user-management:notificationsSection.accountManager'),
        value: AccountManagerItems.ACCOUNT_MANAGER,
    },
    {
        title: t('user-management:notificationsSection.none'),
        value: AccountManagerItems.NONE,
    },
];

export const generateIntermediaryItems = (t: TFunction): SelectItem[] => [
    {
        title: t('user-management:notificationsSection.intermediary', { number: 1 }),
        value: 'VM1',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 2 }),
        value: 'VM2',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 3 }),
        value: 'VM3',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 4 }),
        value: 'VM4',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 5 }),
        value: 'VM5',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 6 }),
        value: 'VM6',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 7 }),
        value: 'VM7',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 8 }),
        value: 'VM8',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 9 }),
        value: 'VM9',
    },
    {
        title: t('user-management:notificationsSection.intermediary', { number: 10 }),
        value: 'VM10',
    },
];

export const generateCustomerRolesDropDownItems = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('user-management:roles.clerk'),
            value: CUSTOMER_ROLES.CLERK,
        },
        {
            title: t('user-management:roles.clerk_plus'),
            value: CUSTOMER_ROLES.CLERK_PLUS,
        },
        {
            title: t('user-management:roles.accountOwner'),
            value: CUSTOMER_ROLES.ACCOUNT_OWNER,
        },
        {
            title: t('user-management:roles.administrator'),
            value: CUSTOMER_ROLES.ADMINISTRATOR,
        },
        {
            title: t('user-management:roles.administrator_plus'),
            value: CUSTOMER_ROLES.ADMINISTRATOR_PLUS,
        },
    ];
};
