import React, { ReactElement } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useTranslation } from 'react-i18next';

import { Zoom, Link } from '@material-ui/core';

import { HectorLogo } from 'src/theming/theme-logos';
import { ThemeFooter } from 'src/theming/theme-footer';

import { useUserHectorLayoutStyles } from './user-hector-layout-styles';

interface UserHectorLayoutProps {
    children: ReactElement;
    darkMode: DarkMode;
    backPath?: string;
}

export const UserHectorLayout = ({ children }: UserHectorLayoutProps): ReactElement => {
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useUserHectorLayoutStyles();
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Zoom in>
                    <div className={classes.logoContainer}>
                        <HectorLogo color='#ffffff' />
                    </div>
                </Zoom>
                <Link
                    className={classes.headerLink}
                    href='https://www.hector.global/#contact'
                    target='_blink'
                >
                    {t('user-management:contact')}
                </Link>
            </div>

            <div className={classes.formContainer}>{children}</div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.footerContainer}>
                <ThemeFooter />
            </div>
        </div>
    );
};
