import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { RelinkMenu } from './relink-generate-menu';

import { RelinkDTO } from './relink-contract-dto';

export interface FieldItem {
    id: string;
    name: string;
    value?: string;
    label?: string;
}

export const useDynamicFields = (
    type: RelinkMenu,
    customerId: string
): [boolean, FieldItem[]] => {
    const httpClient = useHttpClient();
    const [fields, setFields] = useState<FieldItem[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    const getContracts = async (customerId: string): Promise<void> => {
        if (customerId) {
            setLoading(true);
            try {
                const data = await httpClient.get<RelinkDTO[]>(
                    `get-contracts/relink?customerId=${customerId}&isDummy=true`
                );
                if (Array.isArray(data)) {
                    setFields(
                        data?.map((item) => ({
                            id: item.amsIdNr,
                            label: item.contractNumber,
                            name: item.amsIdNr,
                            value: 'No choice',
                        }))
                    );
                } else {
                    setFields([]);
                }
            } catch {
                setFields([]);
            } finally {
                setLoading(false);
            }
        }
    };

    const getDamages = async (customerId: string): Promise<void> => {
        if (customerId) {
            setLoading(true);
            try {
                const data = await httpClient.get<RelinkDTO[]>(
                    `get-damages/relink?customerId=${customerId}&isDummy=true`
                );
                if (Array.isArray(data)) {
                    setFields(
                        data?.map((item) => ({
                            id: item.amsIdNr,
                            label: item.damageNumber,
                            name: item.amsIdNr,
                            value: 'No choice',
                        }))
                    );
                } else {
                    setFields([]);
                }
            } catch {
                setFields([]);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (type === RelinkMenu.CONTRACTS) {
            getContracts(customerId);
        }
        if (type === RelinkMenu.DAMAGES) {
            getDamages(customerId);
        }
    }, [type, customerId]);

    return [isLoading, fields];
};
