import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { DamageReportFormView } from '../damage-report-form-view';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileObject } from 'material-ui-dropzone';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { APP_ROUTES } from 'src/routing';
import { buildFormData } from 'src/shared/utils';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalFilter } from 'src/shared/contexts';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';

import { damageReportFormGBWAndGESCHAndHRGetSettingsForMutation } from './damage-report-form-get-settings-for-mutation-request';
import { damageReportFormGBWSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-GBW-HR-GESCH-schema';
import { damageReportFormGBWDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-GBW-HR-GESCH-default-state';
import { DamageReportFormGBWTypes } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-GBW-HR-GESCH-types';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';

import {
    DamageReportFormConfigGBW,
    generateDisabledInputs,
    generateFooterElements,
} from '../../damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';
import { generateDamageReportFormDamageItemsSelectionValues } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-type-of-damage-items';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';
import { damageReportFormGBWMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-from-gbw-hr-gesch-mutate-adapter';
import { damageReportFormGBWGetReportRecordAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-get-gbw-hr-gesch-report-record-adapter';

interface DamageReportFormGBWProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState: string;
}

export const DamageReportFormGBW = memo(
    ({ setDivision, hideButtons, data, divisionState }: DamageReportFormGBWProps) => {
        const contractId = useQuery().get('contractId');

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormGBWTypes>({
            mode: 'onChange',
            resolver: yupResolver(damageReportFormGBWSchema),
            defaultValues: {
                ...damageReportFormGBWDefaultState(contractId),
                division: divisionState,
            },
        });

        const { changeDivision } = useGlobalFilter();

        const [isSaveLoading, setSaveLoading] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();
        const classes = useStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });
        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            detailedDamageDescription,
            amountOfDamage,
            typeofDamageGBW,
            road,
            plz,
            place,
            country,
            licenseNumber,
            contractNumber,
            policyHolder,
            insuranceCompany,
            damagedOwner,
            isToggleWhere,
            where,
            isTogglePolice,
            policeStation,
            fileNumber,
            initialMeasures,
            localName,
            firm,
            phone,
            email,
            claimNumber,
            costCentre,
            isDeductVat,
            iban,
            bic,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'detailedDamageDescription',
            'amountOfDamage',
            'typeofDamageGBW',
            'road',
            'plz',
            'place',
            'country',
            'licenseNumber',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'damagedOwner',
            'isToggleWhere',
            'where',
            'isTogglePolice',
            'policeStation',
            'fileNumber',
            'initialMeasures',
            'localName',
            'firm',
            'phone',
            'email',
            'claimNumber',
            'costCentre',
            'isDeductVat',
            'iban',
            'bic',
        ]);

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        const { t } = useTranslation(['common', 'damages-report', 'errors']);

        const typeofDamageGBWItems = useMemo(
            () => generateDamageReportFormDamageItemsSelectionValues(t),
            [t]
        );

        const handleChangeWhere = (): void => {
            setValue('isToggleWhere', !isToggleWhere);
        };

        const handleChangePolice = (): void => {
            setValue('isTogglePolice', !isTogglePolice);
        };

        const handleChangeDeduct = (): void => {
            setValue('isDeductVat', !isDeductVat);
        };

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const config = useMemo(
            () =>
                DamageReportFormConfigGBW({
                    plz,
                    place,
                    country,
                    typeofDamageGBW,
                    items: typeofDamageGBWItems,
                    detailedDamageDescription,
                    amountOfDamage,
                    road,
                    damagedOwner,
                    isToggleWhere,
                    toggleWhere: handleChangeWhere,
                    where,
                    isTogglePolice,
                    togglePolice: handleChangePolice,
                    policeStation,
                    fileNumber,
                    initialMeasures,
                }),
            [
                plz,
                place,
                country,
                typeofDamageGBW,
                typeofDamageGBWItems,
                detailedDamageDescription,
                amountOfDamage,
                road,
                damagedOwner,
                isToggleWhere,
                where,
                isTogglePolice,
                policeStation,
                fileNumber,
                initialMeasures,
            ]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(
            () =>
                generateDisabledInputs({
                    licenseNumber:
                        isDivisionFromDataIsFromState || !id ? licenseNumber : '',
                    contractNumber:
                        isDivisionFromDataIsFromState || !id ? contractNumber : '',
                    policyHolder:
                        isDivisionFromDataIsFromState || !id ? policyHolder : '',
                    insuranceCompany:
                        isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                    errors: {
                        contractNumber: formState?.errors?.contractNumber,
                        insuranceCompany: formState?.errors?.insuranceCompany,
                        policyHolder: formState?.errors?.policyHolder,
                    },
                }),
            [
                licenseNumber,
                contractNumber,
                policyHolder,
                insuranceCompany,
                division,
                data?.division,
                formState?.errors?.contractNumber,
                formState?.errors?.insuranceCompany,
                formState?.errors?.policyHolder,
            ]
        );

        const footerConfig = useMemo(
            () =>
                generateFooterElements({
                    phone,
                    claimNumber,
                    costCentre,
                    firm,
                    email,
                    localName,
                    isDeductVat,
                    iban,
                    bic,
                    handleChangeDeduct,
                    localNameError: Boolean(formState.errors.localName),
                    emailError: Boolean(formState.errors.email),
                }),
            [
                phone,
                claimNumber,
                costCentre,
                firm,
                email,
                localName,
                isDeductVat,
                iban,
                bic,
                formState.errors.localName,
                formState.errors.email,
            ]
        );

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormGBWTypes
        ): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormGBWTypes = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(division);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const saveReport = (data: DamageReportFormGBWTypes): Promise<{ id: string }> => {
            setSaveLoading(true);
            const requestSettings =
                damageReportFormGBWAndGESCHAndHRGetSettingsForMutation(data.division);
            if (requestSettings) {
                const formattedData = damageReportFormGBWMutateAdapter(data);
                const formData = new FormData();
                buildFormData(formData, formattedData, null);
                if (!id) {
                    return httpClient.post(
                        `create-damage-report/${requestSettings.pathname}`,
                        formData
                    );
                }
                return httpClient.put(
                    `damage-reports/${id}/${requestSettings.pathname}`,
                    formData
                );
            }
            return Promise.reject();
        };

        const handleSaveReport = (): void => {
            const data = getValues();
            saveReport(data)
                .then(() => {
                    if (!id) {
                        changeDivision(division);
                        router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                    }
                    enqueueSnackbar(t(`damages-report:savedSuccessfully`), {
                        variant: 'success',
                    });
                })
                .catch(() => {
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        };

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('licenseNumber', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        useEffect(() => {
            if (data) {
                reset(damageReportFormGBWGetReportRecordAdapter(data, division));
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        useEffect(() => {
            trigger();
        }, []);

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <DamageReportFormView
                        damageReportGroup={DamageReportGroups.PROPERTY_INSURANCE}
                        setDeleteConfirmOpen={setDeleteConfirmOpen}
                        deleteText={t('damages-report:deleteReport')}
                        deleteButtonClass={classes.deleteButton}
                        hideButtons={hideButtons}
                        boxContainer={classes.boxContainer}
                        sectionContainer={classes.sectionContainer}
                        formState={formState}
                        handleChangeValue={handleChangeValue}
                        footerConfig={footerConfig}
                        headerConfig={headerConfig}
                        configs={config}
                        sendText={t('send')}
                        formBlockElementClass={classes.formBlockElement}
                        containerClassName={classes.formBlockContainer}
                        saveText={t('save')}
                        saveButtonClass={clsx(classes.button, classes.saveButton)}
                        sendButtonClass={clsx(classes.button, classes.sendButton)}
                        isSendDisabled={
                            !formState.isValid ||
                            isSendingToAmsLoading ||
                            isSaveLoading ||
                            (contractId && !isContractValidByDate)
                        }
                        isSendingToAmsLoading={isSendingToAmsLoading}
                        isSaveLoading={isSaveLoading}
                        dayOfDamage={dayOfDamage}
                        IsSaveDisabled={
                            isSaveLoading ||
                            isSendingToAmsLoading ||
                            !division ||
                            !dayOfDamage ||
                            !contractNumber ||
                            !insuranceCompany ||
                            !policyHolder ||
                            (contractId && !isContractValidByDate)
                        }
                        handleCancel={handleCancel}
                        cancelText={t('cancel')}
                        handleReadOnlyFields={handleReadOnlyFields}
                        timeOfDamage={timeOfDamage}
                        handleChangeDate={handleChangeDate}
                        division={division}
                        divisionFromRecord={data?.division}
                        mainFormContainer={classes.mainFormContainer}
                        isReceivingDataLoading={false}
                        handleSubmit={handleSubmit(onSubmit)}
                        footerClassName={clsx(
                            classes.buttonsContainer,
                            classes.formBlockContainer
                        )}
                        handleAddFile={handleAddFile}
                        files={files}
                        fileInfos={fileInfos}
                        onDeleteFile={onDeleteFile}
                        handleDeleteFile={handleDeleteFile}
                        handleSaveReport={handleSaveReport}
                    />
                }
            </>
        );
    }
);
