import React from 'react';
import i18n from 'i18next';

import { List, ListItem, ListItemText } from '@material-ui/core';

import { useDashboardListOfNumericValuesStyle } from './dashboard-list-of-numeric-values-style';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
    values?: { year: string; value: number; stylesForValue?: CSSProperties }[];
    minValue?: number;
    unit?: string;
}

export const DashboardListOfNumericValues = (props: Props): JSX.Element => {
    const { values, minValue, unit } = props;

    const classes = useDashboardListOfNumericValuesStyle();

    return (
        <List dense classes={classes}>
            {values
                .sort((a, b) => (a.year > b.year ? 1 : -1))
                .map((item) => {
                    const min = minValue ?? 0;
                    let formattedValue = min;
                    if (typeof item.value === 'number') {
                        if (item.value < min) {
                            formattedValue = min;
                        } else {
                            formattedValue = Math.round(item.value);
                        }
                    }
                    return (
                        <ListItem key={item.year + item.value}>
                            <ListItemText>{item.year}</ListItemText>
                            <ListItemText data-value style={item.stylesForValue}>
                                {`${Intl.NumberFormat(i18n.languages).format(
                                    formattedValue
                                )} ${unit}`}
                            </ListItemText>
                        </ListItem>
                    );
                })}
        </List>
    );
};
