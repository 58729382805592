import React, { BaseSyntheticEvent, useMemo } from 'react';
import clsx from 'clsx';

import { KeyboardTimePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';

import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CloseIcon from '@material-ui/icons/Close';

import { useStyle } from './theme-time-picker-styles';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

interface ThemeTimePickerProps {
    editMode: boolean;
    className?: string;
    value?: ParsableDate;
    name: string;
    onChange: (name: string, value: ParsableDate) => void;
    error?: boolean;
}

export const ThemeTimePicker = (props: ThemeTimePickerProps): JSX.Element => {
    const { editMode, className, value, name, onChange, error } = props;

    const classes = useStyle({ editMode });

    const handleChangeTime = (value: ParsableDate): void => {
        onChange(name, value);
    };

    const handleClearTime = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
        onChange(name, null);
    };

    const keyboardIcon = useMemo(() => {
        return editMode && !value ? (
            <QueryBuilderIcon fontSize='small' />
        ) : (
            <IconButton onClick={handleClearTime} size='small'>
                <CloseIcon />
            </IconButton>
        );
    }, [value, editMode]);

    return (
        <KeyboardTimePicker
            fullWidth
            autoOk
            error={error}
            ampm={false}
            orientation='landscape'
            inputVariant='outlined'
            onChange={handleChangeTime}
            disabled={!editMode}
            color='secondary'
            openTo='hours'
            className={clsx(classes.timePicker, className)}
            emptyLabel=''
            value={value}
            DialogProps={{
                disableScrollLock: true,
            }}
            keyboardIcon={keyboardIcon}
        />
    );
};
