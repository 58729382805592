import { AnyAbility, AbilityBuilder, Ability } from '@casl/ability';
import { CUSTOMER_ROLES, INTERNAL_ROLES } from 'src/shared/constants';
import { AbilityActions, AbilitySubjects } from './constants';
import {
    adminDomainRoleAccessGenerator,
    adminTechnicalRoleAccessGenerator,
    administratorRoleAccessGenerator,
    administratorPlusRoleAccessGenerator,
    clerkRoleAccessGenerator,
    clerkPlusRoleAccessGenerator,
    accountOwnerRoleAccessGenerator,
    customerAdvisorRoleAccessGenerator,
    caBrokerRoleAccessGenerator,
} from './roleAccessGenerators';

// There is some role access logic that has to be moved to here

export const updateAbilityInstance = (
    ability: AnyAbility,
    roles: string[],
    userId: string,
    isCustomerAdviserBrokerFromAms?: boolean
): void => {
    if (!Array.isArray(roles) || !roles.length) {
        return;
    }

    const { can, cannot, rules } = new AbilityBuilder(Ability);

    // Order of assigning role accesses matters

    if (roles?.includes(INTERNAL_ROLES.ADMIN_DOMAIN)) {
        adminDomainRoleAccessGenerator(can);
    }

    if (roles?.includes(INTERNAL_ROLES.ADMIN_TECHNICAL)) {
        adminTechnicalRoleAccessGenerator(can);
    }

    if (roles?.includes(CUSTOMER_ROLES.ADMINISTRATOR)) {
        administratorRoleAccessGenerator(can);
    }

    if (roles?.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) {
        administratorPlusRoleAccessGenerator(can);
    }

    if (roles?.includes(INTERNAL_ROLES.CUSTOMER_ADVISER)) {
        can(AbilityActions.see, AbilitySubjects.userManagement);
    }

    if (roles?.includes(CUSTOMER_ROLES.CLERK)) {
        clerkRoleAccessGenerator(can);
    }

    if (roles?.includes(CUSTOMER_ROLES.CLERK_PLUS)) {
        clerkPlusRoleAccessGenerator(can);
    }

    if (roles?.includes(CUSTOMER_ROLES.ACCOUNT_OWNER)) {
        accountOwnerRoleAccessGenerator(can);
    }

    if (roles?.includes(INTERNAL_ROLES.CA_BROKER)) {
        caBrokerRoleAccessGenerator(can, cannot, userId, isCustomerAdviserBrokerFromAms);
    }

    if (roles?.some((role) => role === INTERNAL_ROLES.CUSTOMER_ADVISER)) {
        customerAdvisorRoleAccessGenerator(can, cannot);
    }

    ability.update(rules);
};
