import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

import { isHectorInstance, isHectorDemoInstance } from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = theme.palette.type === THEME_MODES.DARK;
    return {
        logo: {
            cursor: 'pointer',
        },
        logoWrapper: {
            width: 160,
            marginBottom: '2rem',
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: () => {
                        if (isDarkMode) {
                            return theme.palette.primary.contrastText;
                        } else {
                            if (isHectorInstance) {
                                return theme.palette.secondary.main;
                            }
                            if (isHectorDemoInstance) {
                                return theme.palette.secondary.dark;
                            }
                        }
                    },
                },
            },
        },
    };
});
