import React, { ChangeEvent } from 'react';
import { TFunction } from 'i18next';
import { MenuItem } from 'src/theming';
import { CellWithSearch } from 'src/theming/theme-table/cell-with-search';
import { CellWithDatePicker } from 'src/theming/theme-table/cell-with-date-picker';
import {
    renderCellAsDateExpand,
    renderCellExpand,
} from 'src/theming/theme-table/theme-table-cell-expand';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { IconButton } from '@material-ui/core';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { GridColDef } from '@material-ui/data-grid';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants/app-instances';
import { DeleteCell } from '../../contracts/contracts-details-view/delete-document-cell';

const isAonKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

export enum ColumnKeys {
    DELETE = 'delete',
    DOWNLOAD = 'download',
    TITLE = 'title',
    DATE = 'date',
    TYPE = 'type',
}

export const generateCustomerDetailsViewTableHeader = (
    t: TFunction,
    titleValue: string,
    dateValue: ParsableDate,
    handleChangeSearchField: (e: ChangeEvent<HTMLInputElement>) => void,
    handleChangeDate: (name: string, value: ParsableDate) => void,
    dateSearchFieldClass: string,
    onDownload: (letterId: string) => void,
    onRemoveSuccess: () => void,
    matchUpLargeSize: boolean
): GridColDef[] => {
    return [
        ...(isAonKompositInstance
            ? [
                  {
                      field: ColumnKeys.DELETE,
                      width: 60,
                      renderCell: (props) => (
                          <DeleteCell
                              {...props}
                              entity='document-customers'
                              onSuccess={onRemoveSuccess}
                              approveMessage='customer-details-view:documents.approveDeletingMessage'
                          />
                      ),
                      renderHeader: () => <></>,
                  },
              ]
            : []),
        {
            field: ColumnKeys.DOWNLOAD,
            width: 60,
            renderHeader: () => <></>,
            renderCell: (props) => (
                <IconButton onClick={() => onDownload(props.row.id)}>
                    <CloudDownloadOutlined />
                </IconButton>
            ),
        },
        {
            field: ColumnKeys.TITLE,
            headerName: t('customer-details-view:documents.table.title'),
            flex: matchUpLargeSize ? 2.2 : 1,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('customer-details-view:documents.table.title')}
                    value={titleValue}
                    handleChange={handleChangeSearchField}
                    name='title'
                />
            ),
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.DATE,
            headerName: t('customer-details-view:documents.table.date'),
            flex: 1,
            renderHeader: (props) => (
                <CellWithDatePicker
                    {...props}
                    title={t('customer-details-view:documents.table.date')}
                    value={dateValue}
                    handleChange={handleChangeDate}
                    name='date'
                    dateSearchFieldClass={dateSearchFieldClass}
                />
            ),
            renderCell: renderCellAsDateExpand,
        },
        {
            field: ColumnKeys.TYPE,
            headerName: t('customer-details-view:documents.table.type'),
            flex: 0.5,
            renderCell: renderCellExpand,
        },
    ];
};

export const generateCustomerDetailsViewAdditionalMenu = (
    t: TFunction,
    openChangeNotification: () => void
): MenuItem[] => {
    return [
        {
            title: t('customer-details-view:sendNotificationOfMasterDataChanges'),
            action: openChangeNotification,
        },
    ];
};
