import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        headerContainer: {
            backgroundColor: theme.palette.grey[800],
            padding: theme.spacing(2),
            justifyContent: 'center',
        },
        headerText: {
            color: theme.palette.getContrastText(theme.palette.grey[800]),
        },
    };
});
