import * as yup from 'yup';

export const damageReportFormBHVAndPHVSchema = yup.object().shape({
    division: yup.string().required().nullable().required(),
    dayOfDamage: yup.string().nullable().required(),
    contractNumber: yup.string().nullable().required(),
    policyHolder: yup.string().nullable().required(),
    insuranceCompany: yup.string().nullable().required(),
    damageInformationDamageType: yup
        .string()
        .test((value) => {
            return value !== 'No choice';
        })
        .nullable()
        .required(),
    damageInformationDamagePlace: yup.string().nullable().required(),
    damageInformationWhatWasDamaged: yup.string().nullable().required(),
    existenceBetweenYouAndCoInsured: yup.bool().nullable().required(),
    whichExistenceBetweenYouAndCoInsured: yup
        .string()
        .when('existenceBetweenYouAndCoInsured', {
            is: (val: string) => val,
            then: () => {
                return yup.string().required().nullable();
            },
            otherwise: () => yup.string().nullable(),
        }),
    doYouOrCoInsuredPersonPerformedActivity: yup.bool().nullable().required(),
    whichActivity: yup.string().when('doYouOrCoInsuredPersonPerformedActivity', {
        is: (val: string) => val,
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string().nullable(),
    }),
    detailedDescription: yup.string().nullable().required(),
    injuredPartyEmail: yup.string().email().nullable(),
    damageOriginatorEmail: yup.string().email().nullable(),
    witnessesEmail: yup.string().email().nullable(),
});
