export const dashboardBarChartColors = {
    year2020: 'rgb(33, 150, 243)',
    year2021: 'rgb(23, 105, 170)',
    year2022: 'rgb(116, 178, 227)',
};

export const getColorByKey = (key): string => {
    const currYear = new Date().getFullYear();
    const prevYear = currYear - 1;
    const prevPrevYear = prevYear - 1;

    const currYearKey = `year${currYear}`;
    const prevYearKey = `year${prevYear}`;
    const prevPrevYearKey = `year${prevPrevYear}`;

    if (key.id === currYearKey) {
        return dashboardBarChartColors.year2022;
    } else if (key.id === prevYearKey) {
        return dashboardBarChartColors.year2021;
    } else if (key.id === prevPrevYearKey) {
        return dashboardBarChartColors.year2020;
    } else {
        return dashboardBarChartColors.year2022;
    }
};
