import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useAxios } from 'src/lib/http-client/use-http-client';

export const usePathInterceptor = (): void => {
    const axios = useAxios();

    useEffect(() => {
        const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
            config.url = !config.url?.startsWith('api')
                ? `api/${config.url}`
                : config.url;
            return config;
        };

        axios.interceptors.request.use(requestInterceptor);
    }, []);
};
