import { makeStyles, Theme } from '@material-ui/core';

export const useBHVStyles = makeStyles((theme: Theme) => {
    return {
        list: {
            width: '33%',
            '& .MuiListItem-root': {
                padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
                    1
                )}px 0`,
            },
            '& .MuiListItemText-root': {
                width: '50%',
                wordBreak: 'break-all',
                color: theme.palette.primary.contrastText,
                '&[data-title]': {
                    opacity: 0.8,
                },
            },
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
            },
        },
        subtitle: {
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        table: {
            width: '100%',
            marginBottom: theme.spacing(4),
            textAlign: 'left',
        },
        tableHeader: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
        tableCell: {
            color: theme.palette.primary.contrastText,
        },
    };
});
