import { useState, useEffect } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface Item {
    id: string;
    name: string;
}

export const useNotificationDivisions = (): Item[] => {
    const httpClient = useHttpClient();
    const [divisions, setDivisions] = useState<Item[]>([]);

    const updateDivisions = async (): Promise<void> => {
        const data = await httpClient.get<Item[]>('notification-triggers/divisions');
        setDivisions(data);
    };

    useEffect(() => {
        updateDivisions();
    }, []);

    return divisions;
};
