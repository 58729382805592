import React, { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ThemeSwitcher } from 'src/theming';

import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageFormConditionalBlockProps {
    title: string;
    children: JSX.Element;
    isCombination: boolean;
    toggleValue: () => void;
    className?: string;
    disabled?: boolean;
    showIfFalse?: boolean;
}

export const DamageFormConditionalBlock = memo(
    ({
        title,
        children,
        isCombination,
        toggleValue,
        className,
        disabled,
        showIfFalse,
    }: DamageFormConditionalBlockProps) => {
        const classes = useStyle();

        const { t } = useTranslation(['common', 'damages-report', 'errors']);

        return (
            <Grid
                container
                className={className}
                style={{ margin: '10px 0', gap: '10px' }}
            >
                <Grid item className={clsx(classes.fullWidth)}>
                    <div style={{ width: 'fit-content' }}>
                        <Typography variant='body1' className={classes.switcherLabel}>
                            {title}
                        </Typography>
                        <ThemeSwitcher
                            disabled={disabled}
                            checked={!isCombination}
                            onChange={toggleValue}
                            leftLabel={t('yes')}
                            rightLabel={t('no')}
                        />
                    </div>
                </Grid>
                {showIfFalse
                    ? !isCombination && <>{children}</>
                    : isCombination && <>{children}</>}
            </Grid>
        );
    }
);
