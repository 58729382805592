import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isCeatInstance,
    isHectorDemoInstance,
    isCeatMaklerInstance,
    isMaasInstance,
    isHectorStyles,
} from 'src/environment';
import { THEME_MODES } from '../themes';
import { LIGHT_CEAT_THEME_COLORS } from '../themes/ceat/ceat-theme-colors';
import { LIGHT_HECTOR_THEME_COLORS } from '../themes/hector/hector-theme-colors';
import { DARK_MAAS_THEME_COLORS } from '../themes/maas';

export const useStyle = makeStyles(() => {
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            label: {
                fontSize: '1.6rem',
            },
        };
    }
    return {
        label: {
            fontSize: '1.2rem',
        },
    };
});

export const useSwitcherStyle = makeStyles(
    (theme: Theme) => {
        const isDarkMode = theme.palette.type === THEME_MODES.DARK;
        if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
            return {
                root: {
                    width: 32,
                    height: 18,
                    padding: 0,
                    display: 'flex',
                    transform: 'rotate(180deg)',
                },
                switchBase: {
                    padding: 2,
                    color: isDarkMode
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.dark,
                    '&$checked': {
                        transform: 'translateX(14px)',
                        color: theme.palette.secondary.dark,
                        '& + $track': {
                            opacity: 1,
                            backgroundColor: theme.palette.common.white,
                        },
                    },
                },
                thumb: {
                    width: 14,
                    height: 14,
                    boxShadow: 'none',
                },
                track: {
                    border: `1px solid ${
                        isDarkMode
                            ? theme.palette.primary.light
                            : theme.palette.secondary.dark
                    }`,
                    borderRadius: 9,
                    opacity: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                },
                checked: {},
            };
        }
        if (isSGIFFOXXKInstance) {
            return {
                root: {
                    width: 32,
                    height: 18,
                    padding: 0,
                    display: 'flex',
                    transform: 'rotate(180deg)',
                },
                switchBase: {
                    padding: 2,
                    color: theme.palette.secondary.main,
                    '&$checked': {
                        transform: 'translateX(14px)',
                        color: theme.palette.secondary.main,
                        '& + $track': {
                            opacity: 1,
                            backgroundColor: theme.palette.common.white,
                        },
                    },
                },
                thumb: {
                    width: 14,
                    height: 14,
                    boxShadow: 'none',
                },
                track: {
                    border: `1px solid ${theme.palette.secondary.main}`,
                    borderRadius: 9,
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                },
                checked: {},
            };
        }
        if (isHectorStyles) {
            return {
                root: {
                    width: 32,
                    height: 18,
                    padding: 0,
                    display: 'flex',
                    transform: 'rotate(180deg)',
                },
                switchBase: {
                    padding: 2,
                    color:
                        isCeatInstance || isCeatMaklerInstance
                            ? LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : isMaasInstance
                            ? DARK_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR,
                    '&$checked': {
                        transform: 'translateX(14px)',
                        color: theme.palette.secondary.main,
                        '& + $track': {
                            opacity: 1,
                            backgroundColor: theme.palette.common.white,
                        },
                    },
                },
                thumb: {
                    width: 14,
                    height: 14,
                    boxShadow: 'none',
                },
                track: {
                    border: `1px solid ${
                        isHectorInstance
                            ? LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : isMaasInstance
                            ? DARK_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR
                            : LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR
                    }`,
                    borderRadius: 9,
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                },
                checked: {},
            };
        }
        return {
            root: {
                width: 32,
                height: 18,
                padding: 0,
                display: 'flex',
                transform: 'rotate(180deg)',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.common.white,
                '&$checked': {
                    transform: 'translateX(14px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: theme.palette.info.main,
                        borderColor: theme.palette.primary.main,
                    },
                },
            },
            thumb: {
                width: 14,
                height: 14,
                boxShadow: 'none',
            },
            track: {
                borderRadius: 18 / 2,
                opacity: 1,
                backgroundColor: theme.palette.grey['500'],
            },
            checked: {},
        };
    },
    { index: 1 }
);

export const useChipStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                color: ({ checked }: { checked: boolean }) => {
                    if (
                        isSGIFFOXXKInstance ||
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.contrastText;
                    }
                    return checked
                        ? theme.palette.info.contrastText
                        : theme.palette.info.main;
                },
                fontSize: '1.2rem',
                backgroundColor: ({ checked }: { checked: boolean }) =>
                    !checked ? theme.palette.info.light : theme.palette.grey['400'],
            },
        };
    },
    { index: 1 }
);
