import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';

import { CheckboxItem } from 'src/theming';
import { useQuery } from 'src/shared/hooks';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { generateCreatedDamageListMenuForTableSettings } from './damage-list-generate-table-settings-for-created-status';
import { DamagesListTableSettings } from './damages-list-table-settings';
import {
    DamageStatuses,
    generateDamageListMenuForTableSettings,
} from './damage-list-configs';

interface UseDamagesListSettings {
    columnsSettingsList: CheckboxItem[];
    filterValue: string;
    hoverPosition: number;
    startPosition: number;
    isColumnSettingsLoading: boolean;
    columnSettingsResponse: DamagesListTableSettings;
    setColumnsSettingsList: (newList: CheckboxItem[]) => void;
    setColumnSettingsResponse: (data: DamagesListTableSettings) => void;
    setNeedAdditionalColumnsForKFZW: (value: boolean) => void;
    getTableSettings: (tableName: string) => Promise<DamagesListTableSettings>;
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
    onDragUpdate: (result) => void;
    onDragStart: (result) => void;
    updateTableSettings: (
        data: CheckboxItem[],
        tableName: string
    ) => Promise<DamagesListTableSettings>;
    handleResetSettings: (tableName: string) => Promise<void>;
}

export const useDamagesListSettings = (): UseDamagesListSettings => {
    const { t } = useTranslation();
    const httpClient = useHttpClient();
    const history = useHistory();

    const [needAdditionalColumnsForKFZW, setNeedAdditionalColumnsForKFZW] =
        useState<boolean>(false);
    const [columnSettingsResponse, setColumnSettingsResponse] = useState(null);
    const [isColumnSettingsLoading, setColumnSettingsLoading] = useState(true);
    const [columnsSettingsList, setColumnsSettingsList] = useState(
        generateCreatedDamageListMenuForTableSettings(t)
    );
    const [hoverPosition, setHoverPosition] = useState<number>(null);
    const [startPosition, setStartPosition] = useState<number>(null);
    const filterValue = useQuery().get('filterValue');

    const getTableSettings = (tableName: string): Promise<DamagesListTableSettings> => {
        return httpClient.get(`users/tables/${tableName}/table-settings`);
    };

    const handleResetSettings = async (tableName: string): Promise<void> => {
        try {
            await updateTableSettings([], tableName);
            const data = await getTableSettings(tableName);
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        } finally {
            setColumnSettingsLoading(false);
        }
    };

    const updateTableSettings = (
        data: CheckboxItem[],
        tableName: string
    ): Promise<DamagesListTableSettings> => {
        const requestBody = {
            columns: data.map((item) => ({
                name: item.value,
                isShown: Boolean(item.checked),
            })),
        };
        return httpClient.put(`users/tables/${tableName}/table-settings`, requestBody);
    };

    const onDragStart = (result): void => {
        if (result.source) {
            setStartPosition(result?.source?.index);
        }
    };

    const onDragUpdate = (result): void => {
        if (result.destination) {
            setHoverPosition(result.destination.index);
        }
    };

    const onDragEnd = async (result): Promise<void> => {
        setHoverPosition(null);
        setStartPosition(null);
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const newSettingsList = [...columnSettingsResponse.columns];
        const settingsListIndex = newSettingsList.findIndex(
            (item) => item.position === source.index
        );
        if (settingsListIndex !== -1) {
            const column = newSettingsList.splice(settingsListIndex, 1);
            newSettingsList.splice(destination.index, 0, ...column);
        }
        setColumnSettingsLoading(true);
        try {
            await updateTableSettings(
                newSettingsList.map((item) => ({
                    title: '',
                    value: item.name,
                    checked: item.isShown,
                    data: undefined,
                })),
                filterValue === DamageStatuses.CREATED ? 'DamageReports' : 'Damages'
            );
            const data = await getTableSettings(
                filterValue === DamageStatuses.CREATED ? 'DamageReports' : 'Damages'
            );
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        } finally {
            setColumnSettingsLoading(false);
        }
    };

    useEffect(() => {
        if (filterValue) {
            getTableSettings(
                filterValue === DamageStatuses.CREATED ? 'DamageReports' : 'Damages'
            )
                .then((data) => {
                    setColumnSettingsResponse({
                        columns: data.columns?.sort((a, b) =>
                            a.position > b.position ? 1 : -1
                        ),
                    });
                })
                .finally(() => {
                    setColumnSettingsLoading(false);
                });
        } else {
            history.push(`?filterValue=${DamageStatuses.OPEN}`);
        }
    }, [filterValue]);

    useEffect(() => {
        if (columnSettingsResponse) {
            if (filterValue === DamageStatuses.CREATED) {
                setColumnsSettingsList(
                    generateCreatedDamageListMenuForTableSettings(
                        t,
                        columnSettingsResponse
                    )
                );
            } else {
                setColumnsSettingsList(
                    generateDamageListMenuForTableSettings(
                        t,
                        columnSettingsResponse,
                        needAdditionalColumnsForKFZW
                    )
                );
            }
        }
    }, [
        columnSettingsResponse,
        t,
        setColumnsSettingsList,
        filterValue,
        needAdditionalColumnsForKFZW,
    ]);

    return {
        columnsSettingsList,
        filterValue,
        isColumnSettingsLoading,
        updateTableSettings,
        setColumnsSettingsList,
        setNeedAdditionalColumnsForKFZW,
        setColumnSettingsResponse,
        getTableSettings,
        hoverPosition,
        startPosition,
        onDragStart,
        onDragEnd,
        onDragUpdate,
        columnSettingsResponse,
        handleResetSettings,
    };
};
