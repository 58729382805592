import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { buildFormData } from '../utils';

export const useValidateContractByDate = (
    dayOfDamage: ParsableDate,
    contractId: string
): boolean => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['errors']);

    const [isValid, setValid] = useState<boolean>(null);

    const makeRequestToValidateContract = async (
        date: ParsableDate,
        contractId: string
    ): Promise<void> => {
        const formData = new FormData();
        const formattedData = {
            dayOfDamage: moment(date).toISOString(),
            contractId,
        };
        buildFormData(formData, formattedData, null);
        return httpClient.post('damage-reports/validate', formData);
    };

    useEffect(() => {
        if (dayOfDamage && contractId) {
            (async () => {
                try {
                    await makeRequestToValidateContract(dayOfDamage, contractId);
                    setValid(true);
                } catch (err) {
                    if (
                        err?.error?.response?.data?.errors?.DayOfDamage?.includes(
                            'Contract was not active at the entered date. Please select a valid date.'
                        )
                    ) {
                        setValid(false);
                        enqueueSnackbar(
                            t(
                                'Contract was not active at the entered date. Please select a valid date.'
                            ),
                            {
                                variant: 'error',
                                autoHideDuration: 7000,
                            }
                        );
                        return;
                    }
                    setValid(null);
                }
            })();
        } else {
            setValid(null);
        }
    }, [dayOfDamage, contractId]);

    return isValid;
};
