import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';
import { ContractsDetailsView } from 'src/contracts';

export const ContractDetailViewPage = (): JSX.Element => {
    const darkMode = useDarkMode();

    return (
        <ThemeLayout darkMode={darkMode}>
            <ContractsDetailsView />
        </ThemeLayout>
    );
};
