import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';

import { MasterData } from 'src/user-management/master-data/master-data';

import { APP_MODULES } from 'src/shared/constants';

export const MasterDataPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.ADMINISTRATION}>
            <MasterData />
        </ThemeLayout>
    );
};
