import React, { useMemo, BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';

import { FormControl, FormControlLabel, Button, Typography } from '@material-ui/core';

import {
    ThemeExpansionPanel,
    ThemeSelectInput,
    ThemeCircularProgress,
} from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';

import { reportCenterGetDivisionsFromGlobalFilter } from './report-center-get-divisions-from-global-filter';

import {
    useReportCenterStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from './report-center-styles';

type ExportContractsFormData = {
    divisions: { name: string }[];
};

export const ExportContractsForm = (): JSX.Element => {
    const reportCenterClasses = useReportCenterStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlClasses = useFormControlStyles();

    const [isLoading, setLoading] = useState(false);

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation(['report-center', 'errors', 'divisions']);

    const { filter } = useGlobalFilter();

    const divisionSelectionItems = useMemo(
        () => reportCenterGetDivisionsFromGlobalFilter(filter.customerConnections, t),
        [filter, t]
    );

    const { control, setValue, handleSubmit } = useForm<ExportContractsFormData>({
        mode: 'onChange',
        defaultValues: {
            divisions: [{ name: 'all' }],
        },
    });

    const { fields: divisionFields } = useFieldArray({ control, name: 'divisions' });

    const divisions = useMemo(
        () => divisionFields?.map((role) => role.name),
        [divisionFields]
    );

    const handleChangeDivisions = (e: BaseSyntheticEvent): void => {
        const isAllChecked = e.target.value.includes('all') && !divisions.includes('all');
        if (isAllChecked) {
            setValue('divisions', [{ name: 'all' }]);
            return;
        }
        setValue(
            'divisions',
            e.target.value
                .map(
                    (item: string) =>
                        item !== 'all' && {
                            name: item,
                        }
                )
                .filter((item) => item)
        );
    };

    const makeRequestToExportContracts = (
        formData: ExportContractsFormData
    ): Promise<void> => {
        const allowedDivisions = formData?.divisions?.map((item) => item.name);
        const isAllChecked = allowedDivisions.includes('all');
        const areAllCustomersChecked = filter.customers?.find(
            (customer) => customer.value === 'all'
        )?.checked;
        const areAllCustomerConnectionsChecked = filter.customerConnections?.find(
            (cc) => cc.value === 'all'
        )?.checked;
        const requestBody: {
            allowedDivisions?: string[];
            allowedCustomerConnections?: string[];
            allowedCustomers?: string[];
        } = {};
        setLoading(true);
        if (!isAllChecked && allowedDivisions?.length > 0) {
            requestBody.allowedDivisions = allowedDivisions;
        }
        if (!areAllCustomersChecked) {
            requestBody.allowedCustomers = filter.customers
                .filter((customer) => customer.checked)
                .map((customer) => customer.value);
        }
        if (!areAllCustomerConnectionsChecked) {
            requestBody.allowedCustomerConnections = filter.customerConnections
                .filter((cc) => cc.checked)
                .map((cc) => cc.value);
        }
        return httpClient.downloadExcel(`contracts/export-to-excel`, requestBody);
    };

    const onSubmit = async (formData: ExportContractsFormData): Promise<void> => {
        try {
            await makeRequestToExportContracts(formData);
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={reportCenterClasses.formContainer}
        >
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <Typography
                    variant='body2'
                    style={{ fontSize: '1.4rem', paddingBottom: 10 }}
                >
                    {t('exportContracts.hint')}
                </Typography>
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeSelectInput
                            editMode
                            multiple
                            items={divisionSelectionItems}
                            value={divisions}
                            name='divisions'
                            onChange={handleChangeDivisions}
                        />
                    }
                    label={t('divisions')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disabled={isLoading}
                startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                type='submit'
                className={reportCenterClasses.generateReportButton}
            >
                {t('generateReport')}
            </Button>
        </form>
    );
};

export const ReportCenterExportContracts = (): JSX.Element => {
    const { t } = useTranslation(['report-center']);

    const reportCenterClasses = useReportCenterStyles();

    return (
        <ThemeExpansionPanel
            readonly
            contentAsJSX
            externalAccordionSummaryClassName={
                reportCenterClasses.externalAccordionSummary
            }
            externalAccordionDetailsClassName={
                reportCenterClasses.externalAccordionDetails
            }
            externalAccordionClassName={reportCenterClasses.externalAccordionClassName}
            data={[
                {
                    id: '2',
                    header: t('exportContracts.title'),
                    content: <ExportContractsForm />,
                    headerClassName: reportCenterClasses.settingHeader,
                },
            ]}
        />
    );
};
