import React, { useRef, MouseEvent, ChangeEvent } from 'react';
import useDarkMode from 'use-dark-mode';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import i18n from 'i18next';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import LoadingIndicator from 'src/shared/loading-indicator';
import { ThemeHoverCard, ThemeRadioButtonsGroup } from 'src/theming';

import { Card } from './generate-divisions';
import { useStyle } from './division-section-styles/division-section-style';

interface DivisionProps {
    editMode: boolean;
    card: Card;
    handleOnClickCard: (division: string) => void;
    toggleCardVisibility: (e: MouseEvent<HTMLElement>, key: string) => void;
    visibleCards: Set<string>;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number, isDrop?: boolean) => void;
    defaultDivision: string;
    setDefaultDivision: (division: string) => void;
    activeDivision?: string;
    contractsAmount: number;
    isReceivingContractsAmountsLoading: boolean;
}

export const Division = (props: DivisionProps): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['divisions']);

    const {
        card,
        handleOnClickCard,
        editMode,
        toggleCardVisibility,
        visibleCards,
        index,
        moveCard,
        defaultDivision,
        setDefaultDivision,
        activeDivision,
        contractsAmount,
        isReceivingContractsAmountsLoading,
    } = props;

    const classes = useStyle({
        editMode,
        isDarkMode: darkMode.value,
        isActive: activeDivision === card.key,
    });

    const ref = useRef(null);

    const handleChangeDefaultDivision = (e: ChangeEvent<HTMLInputElement>): void => {
        setDefaultDivision(e.target.value);
    };

    const handlePreventDefault = (e): void => {
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
    };

    const [, drop] = useDrop({
        accept: 'card',
        hover(item: { id: string; index: number }) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop(item: { id: string; index: number }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            moveCard(dragIndex, hoverIndex, true);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id: card?.key, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    if (index || index === 0) {
        drag(drop(ref));
    } else drag(drop(null));

    return (
        <>
            {
                <Grid
                    item
                    ref={!editMode ? null : ref}
                    className={clsx(
                        classes.contractType,
                        isDragging ? classes.dragContractType : null
                    )}
                    onClick={!editMode ? () => handleOnClickCard(card.key) : null}
                    style={{ display: (!card.isShow || !contractsAmount) && 'none' }}
                >
                    <ThemeHoverCard
                        draggable={editMode}
                        withoutHover={editMode}
                        darkMode={darkMode}
                        isActive={activeDivision === card.key}
                    >
                        <Grid
                            container
                            className={classes.cardContent}
                            style={{ opacity: isDragging ? 0 : 1 }}
                        >
                            <Grid item container className={classes.cardData}>
                                <Typography variant='h3' className={classes.cardValue}>
                                    {isReceivingContractsAmountsLoading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        Intl.NumberFormat(i18n.languages).format(
                                            contractsAmount
                                        )
                                    )}
                                </Typography>
                                <div style={{ flexGrow: 1 }} />
                                <Typography
                                    variant='subtitle1'
                                    className={classes.cardTitle}
                                >
                                    {t(card.title)}
                                </Typography>
                            </Grid>
                            <Grid item container className={classes.cardIcon}>
                                {card.icon}
                            </Grid>
                            {editMode && (
                                <Grid item className={classes.indicatorContainer}>
                                    <IconButton
                                        disableRipple
                                        size='small'
                                        className={classes.indicatorButton}
                                        onClick={(e) => toggleCardVisibility(e, card.key)}
                                    >
                                        {visibleCards.has(card.key) ? (
                                            <StarIcon />
                                        ) : (
                                            <StarBorderIcon />
                                        )}
                                    </IconButton>
                                </Grid>
                            )}
                            {editMode && (
                                <div
                                    role='button'
                                    tabIndex={-1}
                                    className={classes.defaultBlock}
                                    onClick={handlePreventDefault}
                                    onMouseDown={handlePreventDefault}
                                >
                                    <ThemeRadioButtonsGroup
                                        withoutDisplayingValue
                                        value={defaultDivision}
                                        radioButtonsList={[
                                            { label: card.title, value: card.key },
                                        ]}
                                        onChange={handleChangeDefaultDivision}
                                    />
                                </div>
                            )}
                        </Grid>
                    </ThemeHoverCard>
                </Grid>
            }
        </>
    );
};
