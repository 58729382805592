export function sortByMonth<T>(
    items: T[],
    startMonth: number,
    getMonth: (value: T) => number
): T[] {
    return items.sort(
        (a, b) =>
            ((getMonth(a) - startMonth + 12) % 12) -
            ((getMonth(b) - startMonth + 12) % 12)
    );
}