import React, { ReactElement } from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import { BarTooltipDatum } from '@nivo/bar';

const useDashboardBarChartTooltipStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            margin: `${-6}px ${-10}px`,
            padding: `5px 10px`,
        },
        mark: {
            width: 15,
            height: 15,
            marginRight: theme.spacing(1),
            backgroundColor: ({ color }: { color: string }) => color,
        },
    };
});

interface DashboardBarChartTooltipProps {
    valueFormatter?: (val: unknown) => unknown;
}

export const DashboardBarChartTooltip = ({
    valueFormatter,
    value,
    ...rest
}: DashboardBarChartTooltipProps & BarTooltipDatum): ReactElement => {
    const classes = useDashboardBarChartTooltipStyles({ color: rest.color });
    const year = (rest.id as string).match(/(\d+)/)[0];
    const month = rest.data.month;
    return (
        <div className={classes.container}>
            <div className={classes.mark} />
            <div>
                {month} {year}: {`${valueFormatter ? valueFormatter(value) : value} €`}
            </div>
        </div>
    );
};
