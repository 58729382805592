import { makeStyles, Theme } from '@material-ui/core';

export const usePHVStyles = makeStyles((theme: Theme) => {
    return {
        list: {
            width: '50%',
        },
        table: {
            width: '100%',
            marginBottom: theme.spacing(4),
            textAlign: 'left',
        },
        tableHeader: {
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
        },
        tableCell: {
            color: theme.palette.primary.contrastText,
        },
        subtitle: {
            paddingTop: '0!important',
            '& .MuiListItemText-root': {
                marginTop: 0,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});
