import { LIGHT_SGIFFOXX_THEME_COLORS } from 'src/theming/themes/sgiffoxx';

export const dashboardLineChartColors = {
    0: 'rgb(33, 150, 243)',
    1: 'rgb(23, 105, 170)',
    2: 'rgb(116, 178, 227)',
};

export const dashboardLineChartTrendColor = LIGHT_SGIFFOXX_THEME_COLORS.INFO_MAIN_COLOR;
export const dashboardLineChartTrendColorDark = 'rgb(123, 235, 144)';

export const getColorByKey = (key: number): string => {
    return dashboardLineChartColors[key] || '#000000';
};
