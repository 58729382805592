import { makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        pageContainer: {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${theme.spacing(8)}px`,
            minHeight: 75,
            backgroundColor: theme.palette.secondary.main,
            [theme.breakpoints.down('sm')]: {
                minHeight: 50,
                padding: `0 ${theme.spacing(4)}px`,
            },
        },
        title: {
            color: theme.palette.secondary.contrastText,
            fontWeight: 600,
        },
        modalWindowContent: {
            alignItems: 'center',
            marginTop: theme.spacing(6),
        },
        modalWindowMessage: {
            maxWidth: 300,
            paddingLeft: theme.spacing(4),
            fontWeight: 600,
            fontSize: '1.6rem',
            [theme.breakpoints.down('sm')]: {
                maxWidth: 200,
                fontSize: '1.2rem',
            },
        },
        infoIcon: {
            width: 45,
            height: 48,
            [theme.breakpoints.down('sm')]: {
                width: 35,
                height: 32,
            },
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
        },
        modalButton: {
            height: 40,
            color: theme.palette.info.main,
            padding: `0 ${theme.spacing(3)}px`,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
            },
        },
        saveButton: {
            backgroundColor: theme.palette.info.light,
            '& .MuiCircularProgress-root': {
                color: theme.palette.info.main,
            },
        },
        cancelButton: {
            borderColor: theme.palette.info.main,
        },
        loadingContainer: {
            flexGrow: 1,
        },
        formBlockTitle: {
            width: '100%',
            fontSize: '1.6rem',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
            color: theme.palette.primary.contrastText,
        },
        formContainer: {
            maxWidth: 1000,
            padding: `0 ${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(
                8
            )}px`,
            [theme.breakpoints.down('sm')]: {
                padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(
                    4
                )}px`,
            },
            '& .MuiFormControlLabel-root,.MuiInputBase-input': {
                backgroundColor: `${theme.palette.primary.main}!important`,
            },
        },
        dateField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
        switcherFormControl: {
            '& .MuiGrid-root': {
                backgroundColor: `${theme.palette.primary.main}!important`,
            },
        },
        textarea: {
            '& .MuiInputBase-root': {
                height: 'inherit',
                backgroundColor: theme.palette.primary.main,
            },
        },
        buttonsContainer: {
            marginTop: theme.spacing(4),
        },
        successMessage: {
            paddingLeft: 0,
            paddingTop: theme.spacing(4),
            fontWeight: 600,
            fontSize: '1.6rem',
            maxWidth: 400,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
                fontSize: '1.2rem',
            },
        },
        helpText: {
            fontSize: '1.4rem',
        },
        modalWindowError: {
            maxWidth: 600,
            fontSize: '1.6rem',
            color: theme.palette.error.main,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 200,
                fontSize: '1.2rem',
            },
        },
    };
});

export const useFormControlStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                margin: `${theme.spacing(2)}px 0`,
                [theme.breakpoints.down('sm')]: {
                    margin: 0,
                },
                '& label': {
                    fontSize: '2.4rem',
                    backgroundColor: `${theme.palette.primary.light}!important`,
                },
                '& .MuiInputBase-root': {
                    '& .MuiSvgIcon-root': {
                        width: 15,
                        height: 15,
                    },
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.info.main,
                },
            },
        };
    },
    { index: 1 }
);

export const useFormControlLabelStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                display: 'flex!important',
                alignItems: 'flex-start',
                margin: 0,
                padding: `0!important`,
                '&>div': {
                    width: '100%',
                    '& .MuiFormControl-root': {
                        width: '100%',
                    },
                },
            },
            label: {
                fontSize: '1.4rem',
                opacity: 0.6,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '1.2rem',
                },
            },
        };
    },
    { index: 1 }
);

export const useInputStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                width: '100%',
                height: 40,
                backgroundColor: theme.palette.primary.light,
            },
            focused: {
                '& fieldSet': {
                    borderColor: `${theme.palette.info.main}!important`,
                },
            },
            input: {
                '&:-internal-autofill-selected': {
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
                },
            },
        };
    },
    { index: 1 }
);
