export function moveOptionToNewPosition<T>(
    list: T[],
    newPosition: number,
    key?: string,
    value?: string
): T[] {
    const newList = [...list];
    const isPrimitiveType = !key;
    const findIndex = newList.findIndex((item) =>
        !isPrimitiveType ? item[key] === value : value === value
    );
    if (findIndex === -1) {
        return newList;
    }
    const deletedElement = newList.splice(findIndex, 1);
    newList.splice(newPosition, 0, ...deletedElement);
    return newList;
}
