import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';
import { ContractDetailsViewDetailsResponseBodyUv } from './contract-details-view-details-response-body-uv';

interface ContractDetailsViewUvDetailsProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewUvDetails = (
    props: ContractDetailsViewUvDetailsProps
): JSX.Element => {
    const { t } = useTranslation(['contract-details-view']);

    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyUv>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyUv> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyUv>(
                `contracts/${id}/Uv`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyUv) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys?.map((x) => detailsData[x])?.filter((x) => x)?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.death,
                            })}
                            title='contract-details-view:contractDetails.death'
                            hidden={!detailsData?.death}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.disability,
                            })}
                            title='contract-details-view:contractDetails.disability'
                            hidden={!detailsData?.disability}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.fixedMonthlyRent,
                            })}
                            title='contract-details-view:contractDetails.fixedMonthlyRent'
                            hidden={!detailsData?.fixedMonthlyRent}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.dailyMoney1,
                            })}
                            title='contract-details-view:contractDetails.dailyMoney1'
                            hidden={!detailsData?.dailyMoney1}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.dailyMoney2,
                            })}
                            title='contract-details-view:contractDetails.dailyMoney2'
                            hidden={!detailsData?.dailyMoney2}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.illnessDailyMoney,
                            })}
                            title='contract-details-view:contractDetails.illnessDailyMoney'
                            hidden={!detailsData?.illnessDailyMoney}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.transitionPremium,
                            })}
                            title='contract-details-view:contractDetails.transitionPremium'
                            hidden={!detailsData?.transitionPremium}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.cosmeticOperation,
                            })}
                            title='contract-details-view:contractDetails.cosmeticOperation'
                            hidden={!detailsData?.cosmeticOperation}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.cure,
                            })}
                            title='contract-details-view:contractDetails.cure'
                            hidden={!detailsData?.cure}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.healingCosts,
                            })}
                            title='contract-details-view:contractDetails.healingCosts'
                            hidden={!detailsData?.healingCosts}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.service,
                            })}
                            title='contract-details-view:contractDetails.service'
                            hidden={!detailsData?.service}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.immediatePremium,
                            })}
                            title='contract-details-view:contractDetails.immediatePremium'
                            hidden={!detailsData?.immediatePremium}
                        />
                    </List>
                    <List>
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.commutingAccidentDeath,
                            })}
                            title='contract-details-view:contractDetails.commutingAccidentDeath'
                            hidden={!detailsData?.commutingAccidentDeath}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.commutingAccidentDisability,
                            })}
                            title='contract-details-view:contractDetails.commutingAccidentDisability'
                            hidden={!detailsData?.commutingAccidentDisability}
                        />
                        <ContractDetailsListItem
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.commutingAccidentFullDisability,
                            })}
                            title='contract-details-view:contractDetails.commutingAccidentFullDisability'
                            hidden={!detailsData?.commutingAccidentFullDisability}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
