import React, { ReactElement } from 'react';

import { Typography, Link, Box } from '@material-ui/core';

import { useImprintPageStyles } from './imprint-page-styles';
import { ThemeSimpleHeader } from 'src/theming';

export const ImprintPage = (): ReactElement => {
    const classes = useImprintPageStyles();
    return (
        <div className={classes.container}>
            <ThemeSimpleHeader />
            <Typography variant='h2' className={classes.title}>
                CEAT International GmbH
            </Typography>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Postanschrift:
                    <br />
                    Alsweiler Str. 81
                    <br />
                    66646 Marpingen
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Kontakt:
                    <br />
                    Telefon: 06853 / 961 88 25
                    <br />
                    Telefax: 06853 / 961 88 26
                    <br />
                    E-Mail:{' '}
                    <Link href='mailto:info@ceat-international.de'>
                        info@ceat-international.de
                    </Link>
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Vertreten durch:
                    <br />
                    David Ranko, Geschäftsführer
                </Typography>
            </Box>
            <Box mt={4} mb={4}>
                <Typography variant='body1' className={classes.text}>
                    Eingetragen am
                    <br />
                    Amtsgericht Regensburg
                    <br />
                    HRB 17837
                </Typography>
            </Box>
        </div>
    );
};
