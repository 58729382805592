import { THEME_MODES } from '../theme-modes';
import { LIGHT_THEME_COLORS, DARK_THEME_COLORS } from './main-theme-colors';

export const mainLightThemePalette = {
    type: THEME_MODES.LIGHT,
    primary: {
        main: LIGHT_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: LIGHT_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        contrastText: LIGHT_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: LIGHT_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: LIGHT_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        contrastText: LIGHT_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: LIGHT_THEME_COLORS.INFO_MAIN_COLOR,
        light: LIGHT_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: LIGHT_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: LIGHT_THEME_COLORS.ERROR_MAIN_COLOR,
        light: LIGHT_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};

export const mainDarkThemePalette = {
    type: THEME_MODES.DARK,
    primary: {
        main: DARK_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: DARK_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        contrastText: DARK_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: DARK_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: DARK_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        contrastText: DARK_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: DARK_THEME_COLORS.INFO_MAIN_COLOR,
        light: DARK_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: DARK_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: DARK_THEME_COLORS.ERROR_MAIN_COLOR,
        light: DARK_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};
