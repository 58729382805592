import { makeStyles, Theme } from '@material-ui/core';

import { LIGHT_HECTOR_THEME_COLORS } from 'src/theming/themes/hector';

export const useUserHectorLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            backgroundImage: `url('static/images/hector-auth-bg.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerLink: {
            marginTop: theme.spacing(8),
            marginRight: theme.spacing(10),
            fontSize: '2rem',
            color: LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
            transition: '0.3s',
            '&:hover': {
                textDecoration: 'none',
                color: 'rgba(35,213,208,1)',
            },
        },
        logoContainer: {
            width: 520,
            marginLeft: 100,
            marginTop: 70,
            marginBottom: 40,
            '& svg': {
                width: '100%',
                height: '100%',
            },
            [theme.breakpoints.down('lg')]: {
                width: 400,
                marginTop: 60,
                marginBottom: 30,
                marginLeft: 60,
            },
        },
        formContainer: {
            maxWidth: 670,
            minWidth: 400,
            width: '40%',
            borderRadius: 18,
            marginRight: 100,
            marginBottom: 20,
            padding: '70px',
            marginLeft: 'auto',
            backgroundColor: '#F4F4F4',
            [theme.breakpoints.down('lg')]: {
                padding: 30,
                marginRight: 60,
            },
        },
        footerContainer: {
            width: '100%',
            minHeight: 60,
            backgroundColor: LIGHT_HECTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
            '& a,span': {
                color: '#0D3F67',
            },
        },
    };
});
