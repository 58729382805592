import { NonUsersDamageReportFormStateType } from './non-users-damage-report-form-state-type';
import { NonUsersDamageReportFormDTO } from './non-users-damage-report-form-dto';
import moment from 'moment';

export const nonUsersDamageReportFormRequestAdapter = (
    token: string,
    data: NonUsersDamageReportFormStateType
): NonUsersDamageReportFormDTO => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        ConfirmationToken: token,
        KfzLicenseNumber: data.licensePlate,
        DetailedDamageDescription: data.damageDescription,
        DayOfDamage: data.damageDay
            ? moment(moment(data.damageDay).valueOf() - offset).toISOString()
            : null,
        TimeOfDamage: data.damageTime
            ? moment(moment(data.damageTime).valueOf() - offset).toISOString()
            : null,
        City: data.accidentLocation,
        ReceivingPoliceStation: data.receivingPoliceStation,
        SomeoneWarnedByPolice: data.wasRecordedByThePolice,
        NewFiles: data.files.map((item) => item.file),
        DriverInformation: {
            FirstName: data.driverInformationFirstName,
            Surname: data.driverInformationSurname,
            PhoneNumber: data.driverInformationPhoneNumber,
            Email: data.driverInformationEmail,
        },
        OtherPartyInformation: {
            FirstName: data.accidentHostFirstName,
            SurName: data.accidentHostSurname,
            PhoneNumber: data.accidentHostPhoneNumber,
            Email: data.accidentHostEmail,
        },
    };
};
