import React, { useMemo, BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { FormControl, FormControlLabel, Grid, Typography, Box } from '@material-ui/core';

import { ThemeSelectInput, ThemeDatePicker, ThemeTimePicker } from 'src/theming';

import { useAuth } from 'src/user-management/context-auth';
import { DivisionWidgetDto } from 'src/user-management/context-auth-types';
import { appInstance } from 'src/environment';
import { DamageReportGroups, AppInstances } from 'src/shared/constants';
import { useQuery } from 'src/shared/hooks';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
} from '../damage-report-form-styles/damage-report-form-styles';

import { DamageReportFormSearchContracts } from './damage-report-form-search-contracts';
import { DamageReportFormConfigGBWWReturnType } from '../damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';
import { DamageReportFormConfigGenerator } from '../damage-report-form-config/damage-report-form-config-generator';
import { Divisions } from 'src/shared/constants';

export const filterDivisionsDependingOnAppInstance = (
    divisions: DivisionWidgetDto[]
): DivisionWidgetDto[] => {
    if (appInstance === AppInstances.AON_KOMPOSIT) {
        return divisions.sort((a, b) => a.label.localeCompare(b.label));
    }
    return divisions
        .filter(
            (item) =>
                item.value === Divisions.KFZW ||
                item.value === Divisions.GBW ||
                item.value === Divisions.TRANSP ||
                item.value === Divisions.BHV ||
                item.value === Divisions.PHV ||
                item.value === Divisions.HR ||
                item.value === Divisions.GESCH
        )
        .sort((a, b) => a.label.localeCompare(b.label));
};

interface DamageReportFormHeaderBlockProps {
    readonly: boolean;
    division: string;
    divisionFromRecord: string;
    dayOfDamage: ParsableDate;
    timeOfDamage: ParsableDate;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    handleChangeDate: (name, value: ParsableDate) => void;
    handleReadOnlyFields: (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }) => void;
    errors: {
        dayOfDamage: boolean;
        timeOfDamage: boolean;
        contractNumber: boolean;
    };
    sectionContainer: string;
    headerConfig: DamageReportFormConfigGBWWReturnType[];
    damageReportGroup: DamageReportGroups;
}

export const DamageReportFormHeaderBlock = memo(
    (props: DamageReportFormHeaderBlockProps): JSX.Element => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        const contractIdFromQuery = useQuery().get('contractId');

        const {
            dayOfDamage,
            timeOfDamage,
            division,
            handleChangeValue,
            handleChangeDate,
            handleReadOnlyFields,
            errors,
            sectionContainer,
            headerConfig,
            readonly,
            divisionFromRecord,
            damageReportGroup,
        } = props;

        const { t } = useTranslation(['common', 'damage-report']);

        const { userData } = useAuth();

        const divisionSelectionItems = useMemo(
            () =>
                filterDivisionsDependingOnAppInstance(userData?.divisions).map(
                    (item) => ({
                        title: item.label,
                        value: item.value,
                        checked: false,
                    })
                ),
            [userData]
        );

        return (
            <Grid className={sectionContainer}>
                <div className={classes.formBlockContainer}>
                    <Grid container spacing={2}>
                        <Typography variant='h6' className={classes.formBlockTitle}>
                            {t('damages-report:damageReport')}
                        </Typography>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSelectInput
                                            editMode={
                                                contractIdFromQuery ? false : !readonly
                                            }
                                            value={division}
                                            name='division'
                                            onChange={handleChangeValue}
                                            items={divisionSelectionItems}
                                        />
                                    }
                                    label={t('damages-report:generalFormBlock.division')}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                                className={classes.dateField}
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeDatePicker
                                            editMode={!readonly}
                                            error={errors.dayOfDamage}
                                            inputVariant='outlined'
                                            value={dayOfDamage}
                                            name='dayOfDamage'
                                            onChange={handleChangeDate}
                                            maxDate={Date.now()}
                                        />
                                    }
                                    label={t(
                                        'damages-report:generalFormBlock.dayOfDamage'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.thirdPart)}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                                className={classes.dateField}
                            >
                                <FormControlLabel
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeTimePicker
                                            error={errors.timeOfDamage}
                                            editMode={!readonly}
                                            value={timeOfDamage}
                                            onChange={handleChangeDate}
                                            name='timeOfDamage'
                                        />
                                    }
                                    label={t(
                                        'damages-report:generalFormBlock.timeOfDamage'
                                    )}
                                    labelPlacement='top'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className={clsx(classes.fullWidth)}>
                            <DamageReportFormSearchContracts
                                damageReportGroup={damageReportGroup}
                                divisionFromRecord={divisionFromRecord}
                                error={errors.contractNumber}
                                disabled={!dayOfDamage || readonly}
                                dayOfDamage={dayOfDamage}
                                division={division}
                                handleReadOnlyFields={handleReadOnlyFields}
                            />
                        </Grid>
                        <Box pl='5px' pr='5px' style={{ width: '100%' }}>
                            <DamageReportFormConfigGenerator
                                readonly={readonly}
                                config={headerConfig}
                                handleChangeValue={handleChangeValue}
                                formBlockElementClass=''
                                containerClassName=''
                            />
                        </Box>
                    </Grid>
                </div>
            </Grid>
        );
    }
);
