import React, {
    createContext,
    useContext,
    useState,
    SyntheticEvent,
    useCallback,
    ChangeEvent,
    BaseSyntheticEvent,
    useEffect,
} from 'react';

import { useForm, UseFormReset, UseFormHandleSubmit } from 'react-hook-form';
import { GridSortModel, GridSortModelParams } from '@material-ui/data-grid';

import { useGlobalFilter } from 'src/shared/contexts';

type CustomerListFormData = {
    searchValue: string;
    page: number;
    rowsPerPage: number;
};

interface CustomerListContextType {
    searchValue: string;
    page: number;
    rowsPerPage: number;
    sortModel: GridSortModel;
    handleChangePage: (_: SyntheticEvent | null, newPage: number) => void;
    handleChangeRowsPerPage: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeSort: (params: GridSortModelParams) => void;
    handleSearchValue: (e: BaseSyntheticEvent) => void;
    reset: UseFormReset<CustomerListFormData>;
    handleSubmit: UseFormHandleSubmit<CustomerListFormData>;
}

export const defaultCustomerListContextFormValues = {
    page: 0,
    rowsPerPage: 10,
    searchValue: '',
};

export const CustomersListSettings = createContext<CustomerListContextType>(null);

export const CustomersListSettingsProvider = ({ children }): JSX.Element => {
    const { filter } = useGlobalFilter();
    const { watch, setValue, reset, handleSubmit } = useForm<CustomerListFormData>({
        mode: 'onChange',
        defaultValues: defaultCustomerListContextFormValues,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>();

    const [searchValue, page, rowsPerPage] = watch([
        'searchValue',
        'page',
        'rowsPerPage',
    ]);

    useEffect(() => {
        setValue('page', 0);
    }, [JSON.stringify(filter)]);

    const handleChangePage = (_: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: SyntheticEvent): void => {
        setValue('rowsPerPage', Number((e.target as HTMLInputElement).value));
        setValue('page', 0);
    };

    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setSortModel]
    );

    const handleSearchValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
    };

    const contextProps: CustomerListContextType = {
        searchValue,
        page,
        rowsPerPage,
        sortModel,
        handleChangePage,
        handleChangeRowsPerPage,
        handleChangeSort,
        handleSearchValue,
        reset,
        handleSubmit,
    };

    return (
        <CustomersListSettings.Provider value={contextProps}>
            {children}
        </CustomersListSettings.Provider>
    );
};

export const useCustomersListSettings = (): CustomerListContextType =>
    useContext<CustomerListContextType>(CustomersListSettings);
