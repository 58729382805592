import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    Typography,
    FormControl,
    FormControlLabel,
    OutlinedInput,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeSwitcher, ThemeDatePicker, ThemeNestedMenu, MenuItem } from 'src/theming';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { MasterDataExternalLinkDTO } from './master-data-external-link-dto';

import { UserManagementCreateExternalDamageLinkForm } from '../user-management-create-external-link-form/user-management-create-external-link-form';
import { UserManagementDeleteExternalLinkModalWindow } from '../user-management-delete-external-link-modal-window/user-management-delete-external-link-modal-window';
import { UserManagementCreateExternalDamageLinkFormData } from '../user-management-create-external-link-form/user-management-create-external-link-form';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from './master-data-style';

const generateExternalLinkAdditionalMenu = (
    t,
    createAction: () => void,
    deleteAction: () => void,
    externalId?: string
): MenuItem[] => {
    return [
        {
            title: t('user-management:masterData.createLinkForm.title'),
            action: createAction,
        },
        ...(externalId
            ? [
                  {
                      title: t('user-management:masterData.deleteLinkModalWindow.title'),
                      action: deleteAction,
                  },
              ]
            : []),
    ];
};

interface MasterDataExternalLinkSectionProps {
    activeClientConnection: string;
}

export const MasterDataExternalLinkSection = (
    props: MasterDataExternalLinkSectionProps
): JSX.Element => {
    const { activeClientConnection } = props;
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const httpClient = useHttpClient();

    const [isDeleteModalWindowOpen, setDeleteModalWindowOpen] = useState(false);
    const [isCreateodalWindowOpen, setCreateModalWindowOpen] = useState(false);
    const [ccLinkData, setCCLinkData] =
        useState<UserManagementCreateExternalDamageLinkFormData>();

    const { t } = useTranslation(['common', 'user-translation']);

    const handleCloseCreateModalWindow = (): void => {
        setCreateModalWindowOpen(false);
    };

    const handleOpenCreateModalWindow = (): void => {
        setCreateModalWindowOpen(true);
    };

    const handleCloseDeleteModalWindow = (): void => {
        setDeleteModalWindowOpen(false);
    };

    const handleOpenDeleteModalWindow = (): void => {
        setDeleteModalWindowOpen(true);
    };

    const additionalMenuItems = generateExternalLinkAdditionalMenu(
        t,
        handleOpenCreateModalWindow,
        handleOpenDeleteModalWindow,
        ccLinkData?.id
    );

    const makeRequestForReceivingLink = (): Promise<MasterDataExternalLinkDTO> => {
        return httpClient.get<MasterDataExternalLinkDTO>(
            `external-links/by-customer-connection/${activeClientConnection}`
        );
    };

    const refetch = (): void => {
        makeRequestForReceivingLink().then((data) => {
            if (data) {
                setCCLinkData({
                    date: data?.validTo,
                    password: data?.password,
                    status: data?.status,
                    id: data?.id,
                    url: data?.url,
                });
            } else {
                setCCLinkData(null);
            }
        });
    };

    useEffect(() => {
        if (activeClientConnection) {
            refetch();
        }
    }, [activeClientConnection]);

    return (
        <Grid container className={classes.externalLinkSectionContainer}>
            <Grid item sm={1}>
                <Typography variant='body1' style={{ fontSize: '1.4rem' }}>
                    {t('user-management:masterData.damageLink')}
                </Typography>
                <Typography variant='body1' style={{ fontSize: '1.4rem' }}>
                    {t('user-management:masterData.division', { division: 'KFZW' })}
                </Typography>
            </Grid>
            <Grid item sm={3}>
                <FormControl
                    disabled
                    fullWidth
                    classes={formControlClasses}
                    variant='outlined'
                    style={{ width: '100%' }}
                >
                    <FormControlLabel
                        label={t('user-management:masterData.URL')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                disabled
                                classes={inputClasses}
                                value={ccLinkData?.url || ''}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item sm={3}>
                <FormControl
                    disabled
                    fullWidth
                    classes={formControlClasses}
                    variant='outlined'
                    style={{ width: '100%' }}
                >
                    <FormControlLabel
                        label={t('user-management:masterData.password')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                disabled
                                classes={inputClasses}
                                value={ccLinkData?.password || ''}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item container justify='center' sm={1}>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        label={t('user-management:masterData.createLinkForm.status')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <div className={classes.switchContainer}>
                                <ThemeSwitcher
                                    disabled
                                    checked={
                                        ccLinkData?.status
                                            ? ccLinkData?.status !== 'Active'
                                            : false
                                    }
                                    leftLabel={t(
                                        'user-management:masterData.form.active'
                                    )}
                                    rightLabel={t(
                                        'user-management:masterData.form.inactive'
                                    )}
                                />
                            </div>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item sm={3}>
                <FormControl fullWidth variant='outlined' className={classes.dateField}>
                    <FormControlLabel
                        label={t(
                            'user-management:masterData.createLinkForm.URLValidUntil'
                        )}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <ThemeDatePicker
                                name='date'
                                value={ccLinkData?.date || null}
                                inputVariant='outlined'
                                editMode={false}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item container alignItems='center' sm={1}>
                <FormControl fullWidth variant='outlined' className={classes.dateField}>
                    <FormControlLabel
                        label={t('user-management:masterData.actions')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <ThemeNestedMenu
                                buttonIcon={
                                    <MenuIcon className={classes.additionalMenuIcon} />
                                }
                                menuItems={additionalMenuItems}
                                buttonClass={classes.additionalMenuButton}
                            />
                        }
                    />
                </FormControl>
                <UserManagementCreateExternalDamageLinkForm
                    isOpen={isCreateodalWindowOpen}
                    handleClose={handleCloseCreateModalWindow}
                    date={ccLinkData?.date}
                    status={ccLinkData?.status}
                    password={ccLinkData?.password}
                    clientConnection={activeClientConnection}
                    externalLinkId={ccLinkData?.id}
                    refetch={refetch}
                />
                <UserManagementDeleteExternalLinkModalWindow
                    isOpen={isDeleteModalWindowOpen}
                    handleClose={handleCloseDeleteModalWindow}
                    externalLinkId={ccLinkData?.id}
                    refetch={refetch}
                />
            </Grid>
        </Grid>
    );
};
