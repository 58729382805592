import React, { memo } from 'react';
import clsx from 'clsx';

import { Grid, Typography } from '@material-ui/core';

import { useStyle } from '../damage-report-form-components-styled';

interface DamageReportFormFullInputProps {
    value?: unknown;
    label: string;
    name?: string;
    disabled?: boolean;
}

export const DamageReportFormText = memo(({ label }: DamageReportFormFullInputProps) => {
    const classes = useStyle();
    return (
        <Grid item className={clsx(classes.fullWidth)}>
            <div>
                <Typography variant='subtitle1' className={classes.textField}>
                    {label}
                </Typography>
            </div>
        </Grid>
    );
});
