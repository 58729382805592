import { Dispatch, SetStateAction, useLayoutEffect, useCallback } from 'react';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { UserSettingsDto, TokenResponse } from '../context-auth-types';
import { localStorageServices } from 'src/lib/local-storage-services/local-storage-services';

interface useAuthInterceptorProps {
    isAuth: boolean;
    setAuth: Dispatch<SetStateAction<boolean>>;
    setUserData: (data: UserSettingsDto) => void;
}

const throttleFun = (
    fn: () => Promise<TokenResponse>,
    ms: number
): (() => Promise<TokenResponse>) => {
    let currentTime = 0;
    let result: Promise<TokenResponse>;
    return (): Promise<TokenResponse> => {
        const now = Date.now();
        if (now - currentTime >= ms) {
            currentTime = now;
            result = fn();
            return result;
        }
        return result;
    };
};

export const useAuthInterceptor = ({
    isAuth,
    setAuth,
    setUserData,
}: useAuthInterceptorProps): void => {
    const axios = useAxios();
    const httpClient = useHttpClient();

    const makeRequestForUpdatingTokens = useCallback(async (): Promise<TokenResponse> => {
        try {
            return await httpClient.post('users/refresh-token');
        } catch (error) {
            if (error?.error?.response?.status === 401) {
                localStorageServices.clearAuthData();
                setAuth(false);
                setUserData(null);
            }
            throw error;
        }
    }, []);

    const updateTokensThrottle = throttleFun(makeRequestForUpdatingTokens, 10000);

    useLayoutEffect(() => {
        let resInterceptor: number;
        if (isAuth) {
            const responseInterceptor = {
                response: (response: AxiosResponse) => {
                    return response;
                },
                error: async (
                    error: AxiosError & {
                        config: {
                            _isRetry: boolean;
                        };
                        response: {
                            errors: { refreshToken: string[] };
                        };
                    }
                ) => {
                    const originalRequest = error.config;
                    if (error.response.status === 403) {
                        localStorageServices.clearAuthData();
                        setAuth(false);
                        setUserData(null);
                    }
                    if (error.response.status === 401 && !error.config._isRetry) {
                        error.config._isRetry = true;
                        try {
                            await updateTokensThrottle();
                            return axios(originalRequest);
                        } catch {
                            localStorageServices.clearAuthData();
                            setAuth(false);
                            setUserData(null);
                            return;
                        }
                    }

                    return Promise.reject(error);
                },
            };
            resInterceptor = axios.interceptors.response.use(
                responseInterceptor.response,
                responseInterceptor.error
            );
        }
        return () => {
            if (resInterceptor) {
                axios.interceptors.response.eject(resInterceptor);
            }
        };
    }, [isAuth]);
};
