import { CheckboxItem } from 'src/theming';

export const globalSearchListGetAllowedFilterValues = (
    values: CheckboxItem[]
): string[] => {
    if (!values || values.length === 0) {
        return null;
    }
    const isAllChecked = values.find((item) => item.value === 'all')?.checked;
    const nothingChecked = values.every((item) => !item.checked);
    if (isAllChecked || nothingChecked) {
        return null;
    }
    return values
        .filter((item) => item.value !== 'all' && item.checked)
        .map((item) => item.value);
};
