import React, { BaseSyntheticEvent, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Grid, IconButton, Typography, Button } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { APP_ROUTES } from 'src/routing';

import {
    ThemeTabs,
    ThemeMessageComponent,
    MessageTypes,
    ThemeLoader,
    ThemeCircularProgress,
} from 'src/theming';

import { RelinkSelectInput } from './components';
import { RelinkDynamicFields } from './relink-dynamic-fields';

import { useDummyCustomers } from './use-dummy-customers';
import { useCustomers } from './use-customers';
import { useCustomerConnections } from './use-customer-connections';
import { useDynamicFields } from './use-dynamic-fields';
import { useTranscodeFiles } from './use-transcode-files';

import { useRelinkStyles } from './relink-styles';

import { relinkGenerateMenu, RelinkMenu } from './relink-generate-menu';

import { damageReportFormRelinkSchema } from './relink-validation-schema';

interface Form {
    customer: string;
    dummyCustomer: string;
    customerConnection: string;
    dynamicFields: { id: string; name: string; value?: string }[];
}

export const Relink = (): JSX.Element => {
    const classes = useRelinkStyles();
    const history = useHistory();
    const { t } = useTranslation(['relink']);
    const { state } =
        useLocation<{
            preselectedCC: string;
            page: number;
            rowsPerPage: number;
            searchValue: string;
        }>();

    const { customerConnection: dummyCustomerConnection } =
        useParams<{ customerConnection: string }>();

    const [activeMenu, setActiveMenu] = useState(RelinkMenu.CONTRACTS);

    const { watch, setValue, control, formState, trigger } = useForm<Form>({
        defaultValues: {
            dummyCustomer: '',
            customer: '',
            customerConnection: '',
            dynamicFields: [],
        },
        resolver: yupResolver(damageReportFormRelinkSchema),
    });

    const { fields: dynamicFields } = useFieldArray({ control, name: 'dynamicFields' });

    const [customer, customerConnection, dummyCustomer, watchedDynamicFields] = watch([
        'customer',
        'customerConnection',
        'dummyCustomer',
        'dynamicFields',
    ]);

    const customerConnections = useCustomerConnections();
    const customers = useCustomers(customerConnection);
    const dummyCustomers = useDummyCustomers(dummyCustomerConnection);

    const handleGoBack = (): void => {
        history.push({
            pathname: APP_ROUTES.MASTER_DATA,
            state,
        });
    };

    const [isLoading, fields] = useDynamicFields(activeMenu, dummyCustomer);
    const [isTranscodeLoading, transcodeFiles] = useTranscodeFiles(
        {
            type: activeMenu,
            customer,
            dummyCustomer,
            customerConnection,
            dummyCustomerConnection,
            dynamicFields,
        },
        handleGoBack
    );

    useEffect(() => {
        if (Array.isArray(fields)) {
            setValue('dynamicFields', fields);
        }
    }, [fields]);

    useEffect(() => {
        if (Array.isArray(customers) && customers.length === 1) {
            setValue('customer', customers[0]?.value);
        } else {
            setValue('customer', '');
        }
    }, [customers]);

    useEffect(() => {
        if (Array.isArray(dummyCustomers) && dummyCustomers.length === 1) {
            setValue('dummyCustomer', dummyCustomers[0]?.value);
        }
    }, [dummyCustomers]);

    useEffect(() => {
        trigger();
    }, [dummyCustomer, customer, customerConnection]);

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
    };

    const handleChangeMenu = (value: RelinkMenu): void => {
        setActiveMenu(value);
    };

    const onChangeDynamicField = useCallback(
        (e: BaseSyntheticEvent): void => {
            const newFields = dynamicFields?.map((item) => {
                if (item.name === e.target.name) {
                    return {
                        ...item,
                        value: e.target.value,
                    };
                }
                return item;
            });
            setValue('dynamicFields', newFields);
        },
        [dynamicFields, setValue]
    );

    const relinkMenu = relinkGenerateMenu(t, handleChangeMenu);

    return (
        <Grid container className={classes.pageContainer}>
            <Grid item md={12} container className={classes.titleContainer}>
                <IconButton
                    size='small'
                    className={classes.backButton}
                    onClick={handleGoBack}
                >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant='h5' className={classes.backTitle}>
                    {t('relink:titleBack', { CR: dummyCustomerConnection })}
                </Typography>
            </Grid>
            <Grid
                container
                item
                md={12}
                spacing={2}
                className={classes.staticFieldsContainer}
            >
                <Grid item md={6}>
                    <Typography variant='h5' className={classes.backTitle}>
                        {t('relink:title', { CR: dummyCustomerConnection })}
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <RelinkSelectInput
                        isEdit
                        label={t('relink:dummyCustomer')}
                        items={dummyCustomers}
                        value={dummyCustomer}
                        name='dummyCustomer'
                        onChange={handleChangeValue}
                    />
                </Grid>
                <Grid item md={6}>
                    <RelinkSelectInput
                        isEdit
                        label={t('relink:customerConnection')}
                        items={customerConnections}
                        value={customerConnection}
                        name='customerConnection'
                        onChange={handleChangeValue}
                    />
                </Grid>
                <Grid item md={6}>
                    <RelinkSelectInput
                        isEdit
                        label={t('relink:customer')}
                        items={customers}
                        value={customer}
                        name='customer'
                        onChange={handleChangeValue}
                    />
                </Grid>
            </Grid>
            <Grid item container md={12} spacing={2} className={classes.menuContainer}>
                <ThemeTabs tabs={relinkMenu} activeTab={activeMenu} />
            </Grid>
            <Grid container item md={12} spacing={2} className={classes.dynamicFields}>
                {isLoading && <ThemeLoader />}
                {!isLoading && dynamicFields?.length === 0 && (
                    <ThemeMessageComponent
                        message={t('relink:noData', { thing: t(`relink:${activeMenu}`) })}
                        type={MessageTypes.INFO}
                    />
                )}
                {!isLoading && dynamicFields?.length !== 0 && (
                    <>
                        <Grid item container md={6} className={classes.dynamicFieldItem}>
                            <Grid item md={6}>
                                {activeMenu === RelinkMenu.CONTRACTS &&
                                    t('relink:dummyContracts')}
                                {activeMenu === RelinkMenu.DAMAGES &&
                                    t('relink:dummyDamages')}
                            </Grid>
                            <Grid item md={6}>
                                {activeMenu === RelinkMenu.CONTRACTS &&
                                    t('relink:contracts')}
                                {activeMenu === RelinkMenu.DAMAGES && t('relink:damages')}
                            </Grid>
                        </Grid>
                        <Grid item container md={6} className={classes.dynamicFieldItem}>
                            <Grid item md={6}>
                                {activeMenu === RelinkMenu.CONTRACTS &&
                                    t('relink:dummyContracts')}
                                {activeMenu === RelinkMenu.DAMAGES &&
                                    t('relink:dummyDamages')}
                            </Grid>
                            <Grid item md={6}>
                                {activeMenu === RelinkMenu.CONTRACTS &&
                                    t('relink:contracts')}
                                {activeMenu === RelinkMenu.DAMAGES && t('relink:damages')}
                            </Grid>
                        </Grid>
                        <RelinkDynamicFields
                            customerId={customer}
                            type={activeMenu}
                            fields={watchedDynamicFields}
                            onChangeDynamicField={onChangeDynamicField}
                        />
                    </>
                )}
            </Grid>
            <Grid
                container
                item
                spacing={2}
                md={12}
                justify='flex-end'
                className={classes.actionsContainer}
            >
                <Button
                    disabled={!formState.isValid || isTranscodeLoading}
                    className={classes.containedButton}
                    onClick={transcodeFiles}
                    startIcon={<ThemeCircularProgress isLoading={isTranscodeLoading} />}
                >
                    {t('relink:transcode')}
                </Button>
            </Grid>
        </Grid>
    );
};
