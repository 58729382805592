import { Theme } from '@material-ui/core';

export const dashboardGetColorForValueLimit = (value: number, theme: Theme): string => {
    let colorForValue;
    if (value < 26) {
        colorForValue = theme.palette.success.main;
    } else if (value < 51) {
        colorForValue = theme.palette.warning.light;
    } else if (value < 76) {
        colorForValue = theme.palette.warning.dark;
    } else if (value < 101) {
        colorForValue = '#d64a43';
    } else if (value < 140) {
        colorForValue = theme.palette.error.main;
    } else {
        colorForValue = theme.palette.error.dark;
    }
    return colorForValue;
};
