import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Button, Typography } from '@material-ui/core';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ThemeExpansionPanel, ThemeCircularProgress } from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';

import { useReportCenterStyles } from './report-center-styles';

const ReportCenterExportConnectionsBody = (): JSX.Element => {
    const reportCenterClasses = useReportCenterStyles();
    const { t } = useTranslation(['report-center', 'errors']);
    const [isLoading, setLoading] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const httpClient = useHttpClient();
    const { filter } = useGlobalFilter();

    const makeRequestToExportClientConnections = (): Promise<void> => {
        const areAllCustomersChecked = filter.customers?.find(
            (customer) => customer.value === 'all'
        )?.checked;
        const areAllCustomerConnectionsChecked = filter.customerConnections?.find(
            (cc) => cc.value === 'all'
        )?.checked;
        const requestBody: {
            allowedCustomerConnections?: string[];
            allowedCustomers?: string[];
        } = {};
        setLoading(true);
        if (!areAllCustomersChecked) {
            requestBody.allowedCustomers = filter.customers
                .filter((customer) => customer.checked)
                .map((customer) => customer.value);
        }
        if (!areAllCustomerConnectionsChecked) {
            requestBody.allowedCustomerConnections = filter.customerConnections
                .filter((cc) => cc.checked)
                .map((cc) => cc.value);
        }
        return httpClient.downloadExcel(`customers/export-to-excel`, requestBody);
    };

    const generateExport = async (): Promise<void> => {
        try {
            await makeRequestToExportClientConnections();
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={reportCenterClasses.formContainer}>
            <Typography variant='body2' style={{ fontSize: '1.4rem' }}>
                {t('exportClientConnections.hint')}
            </Typography>
            <Button
                disabled={isLoading}
                startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                className={reportCenterClasses.generateReportButton}
                onClick={generateExport}
            >
                {t('generateReport')}
            </Button>
        </div>
    );
};

export const ReportCenterExportClientConnections = (): JSX.Element => {
    const reportCenterClasses = useReportCenterStyles();
    const { t } = useTranslation(['report-center']);

    return (
        <ThemeExpansionPanel
            readonly
            contentAsJSX
            externalAccordionSummaryClassName={
                reportCenterClasses.externalAccordionSummary
            }
            externalAccordionDetailsClassName={
                reportCenterClasses.externalAccordionDetails
            }
            externalAccordionClassName={reportCenterClasses.externalAccordionClassName}
            data={[
                {
                    id: 'clientConnection',
                    header: t('exportClientConnections.title'),
                    content: <ReportCenterExportConnectionsBody />,
                    headerClassName: reportCenterClasses.settingHeader,
                },
            ]}
        />
    );
};
