import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import qs from 'query-string';
import moment from 'moment';

import { TextField, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';

import { ThemeCircularProgress } from 'src/theming';
import { useQuery } from 'src/shared/hooks';

import {
    useAutocompleteStyles,
    useStyle,
} from '../damage-report-form-styles/damage-report-form-styles';

import { ContractDetailsViewResponseBody } from '../../contracts-details-view/contract-details-view-response-body';
import { DamageReportGroups, Divisions } from 'src/shared/constants';

interface Option {
    name: string;
    value: string;
}

interface DamageReportFormSearchContractsProps {
    damageReportGroup: DamageReportGroups;
    division: string;
    divisionFromRecord: string;
    dayOfDamage: ParsableDate;
    handleReadOnlyFields: (data: {
        licenseNumber: string;
        contractNumber: string;
        policyHolder: string;
        insuranceCompany: string;
        contractId: string;
    }) => void;
    disabled?: boolean;
    error?: boolean;
    contractId?: string;
}

const getPathAndFormatterForSearch = (
    division: string
): {
    path: string;
    formatter: (item: {
        contractNumber: string;
        amsIdNr: string;
        risk?: string;
        address?: string;
    }) => {
        name: string;
        value: string;
    };
} => {
    switch (division) {
        case Divisions.KFZW: {
            return {
                path: 'contracts/dropdown-search/kfzw',
                formatter: (item) => ({
                    name: `${item.contractNumber} - ${item.risk}`,
                    value: item.amsIdNr,
                }),
            };
        }
        case Divisions.GBW: {
            return {
                path: 'contracts/dropdown-search/gbw',
                formatter: (item) => ({
                    name: `${item.contractNumber} - ${item.address}`,
                    value: item.amsIdNr,
                }),
            };
        }
        default: {
            return {
                path: 'contracts/dropdown-search',
                formatter: (item) => ({
                    name: `${item.contractNumber} - ${item.risk}`,
                    value: item.amsIdNr,
                }),
            };
        }
    }
};

export const DamageReportFormSearchContracts = (
    props: DamageReportFormSearchContractsProps
): JSX.Element => {
    const { t } = useTranslation(['common', 'damages-report']);

    const httpClient = useHttpClient();
    const contractId = useQuery().get('contractId');
    const contractNumber = useQuery().get('contractNumber');

    const {
        disabled,
        dayOfDamage,
        division,
        divisionFromRecord,
        handleReadOnlyFields,
        error,
        damageReportGroup,
    } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [options, setOptions] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState<{ name: string; value: string }>(null);

    const autoCompleteClasses = useAutocompleteStyles({ error });
    const classes = useStyle();

    const makeRequestForOptions = async (): Promise<
        { name: string; value: string }[]
    > => {
        setLoading(true);
        const queryParams: { dayOfDamage: string; division: string } = {
            dayOfDamage: moment(dayOfDamage).toISOString(),
            division,
        };
        const pathAndFormatterForSearch = getPathAndFormatterForSearch(division);
        const data = await httpClient.get<
            { amsIdNr: string; risk: string; contractNumber: string }[]
        >(`${pathAndFormatterForSearch.path}?${qs.stringify(queryParams)}`);
        return data.map(pathAndFormatterForSearch.formatter);
    };

    const makeRequestForContractDetails = async (
        id: string
    ): Promise<ContractDetailsViewResponseBody> => {
        return httpClient.get(`contracts/${id}`);
    };

    const handleInputChange = (e: BaseSyntheticEvent, value: string): void => {
        setInputValue(value);
    };

    const onChange = async (_, value: { name: string; value: string }): Promise<void> => {
        setValue(value);
        if (value?.value) {
            let data1 = {
                licenseNumber: '',
                contractNumber: '',
                insuranceCompany: '',
                policyHolder: '',
                contractId: '',
            };
            const data = await makeRequestForContractDetails(value.value);
            data1 = {
                ...data1,
                licenseNumber: data?.licenseNumber || data.risk,
                contractNumber: data?.contractNumber,
                insuranceCompany: data?.insuranceCompany || data?.insuranceCompanyCode,
                policyHolder: data?.customer,
                contractId: data?.amsIdNr,
            };
            handleReadOnlyFields(data1);
        } else {
            handleReadOnlyFields({
                licenseNumber: '',
                contractNumber: '',
                insuranceCompany: '',
                policyHolder: '',
                contractId: '',
            });
        }
    };

    useEffect(() => {
        if (divisionFromRecord ? divisionFromRecord !== division : true) {
            handleReadOnlyFields({
                licenseNumber: '',
                contractNumber: '',
                insuranceCompany: '',
                policyHolder: '',
                contractId: '',
            });
            setValue({ name: '', value: '' });
            setInputValue('');
        }
    }, [division, divisionFromRecord]);

    useEffect(() => {
        if (contractNumber) {
            onChange(null, { name: contractNumber, value: contractId });
            setInputValue(contractNumber);
        }
    }, [contractNumber]);

    useEffect(() => {
        if (!dayOfDamage && !contractId) {
            setInputValue('');
            handleReadOnlyFields({
                licenseNumber: '',
                contractNumber: '',
                insuranceCompany: '',
                policyHolder: '',
                contractId: '',
            });
        }
    }, [dayOfDamage]);

    useEffect(() => {
        if (open) {
            setLoading(true);
        }
    }, [open]);

    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            const contracts = await makeRequestForOptions().finally(() => {
                setLoading(false);
            });
            if (active) {
                setOptions(contracts);
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <>
            <Autocomplete
                fullWidth
                disabled={contractId ? true : disabled}
                loadingText={t('loading')}
                classes={autoCompleteClasses}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionSelected={(option: Option, value: Option) =>
                    option.value === value.value
                }
                getOptionLabel={(option: Option) => option.name}
                options={options}
                loading={loading}
                onInputChange={handleInputChange}
                onChange={onChange}
                value={value}
                inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={t(
                            `damages-report:generalFormBlock.${damageReportGroup}.autocompleteSearchPlaceholder`
                        )}
                        variant='outlined'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {<ThemeCircularProgress isLoading={loading} />}
                                    {<SearchIcon className={classes.icon} />}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <FormHelperText className={classes.helpText}>
                {t(
                    `damages-report:generalFormBlock.${damageReportGroup}.autocompleteHelpText`
                )}
            </FormHelperText>
        </>
    );
};

DamageReportFormSearchContracts.defaultProps = {
    disabled: false,
};
