import { TFunction } from 'i18next';
import { RadioButton } from 'src/theming';

export enum SearchOptions {
    CONTRACT = 'Contract',
    DAMAGE = 'Damage',
}

export const generateContractTypeSearchOptions = (t: TFunction): RadioButton[] => {
    return [
        {
            value: SearchOptions.CONTRACT,
            label: t('contract-types:searchOptions.contract'),
        },
        { value: SearchOptions.DAMAGE, label: t('contract-types:searchOptions.damage') },
    ];
};
