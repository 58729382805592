export enum LIGHT_CEAT_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9F9FB',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_DARK_COLOR = '#F2F0F4',
    PRIMARY_CONTRAST_TEXT = '#5B5B5B',
    SECONDARY_MAIN_COLOR = '#1B71A2',
    SECONDARY_LIGHT_COLOR = '#d0e8ff',
    SECONDARY_DARK_COLOR = '#81546B',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#1B71A2',
    INFO_LIGHT_COLOR = '#8FBEEB',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_CEAT_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#1A1C28',
    PRIMARY_LIGHT_COLOR = '#3E3E3E',
    PRIMARY_DARK_COLOR = '#212121',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#1B71A2',
    SECONDARY_LIGHT_COLOR = '#d0e8ff',
    SECONDARY_DARK_COLOR = '#81546B',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#1B71A2',
    INFO_LIGHT_COLOR = '#8FBEEB',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
