import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface UserDTO {
    id: string;
    name: string;
    surname: string;
}

export const useUsers = (
    customerConnection: string,
    customer: string,
    isAccess: boolean
): UserDTO[] => {
    const httpClient = useHttpClient();
    const [users, setUsers] = useState<UserDTO[]>([]);

    const getUsers = async (): Promise<void> => {
        try {
            const data = await httpClient.get<UserDTO[]>(
                `users/dropdown-search/${customerConnection}/customer/${customer}`
            );
            setUsers(data);
        } catch {
            setUsers([]);
        }
    };

    useEffect(() => {
        if (customerConnection && customer && isAccess) {
            getUsers();
        }
    }, [customerConnection, customer, isAccess]);

    return users;
};
