import { SelectItem } from 'src/theming';
import { DamageFormConditionalBlock } from '../../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormContainer } from '../../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageFormDisabledInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormFullInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormFullwidthInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-fullwidth-input';
import { DamageReportFormInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import { DamageReportFormSelect } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-select';
import { DamageReportFormTitleText } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-title-text';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from '../damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigAONKompositDreiseReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
}

interface DamageReportFormConfigAONKompositDreiseProps {
    insuredCompanyFileNumber: string;
    creditInstitute: string;
    iban: string;
    bic: string;
    accounOwner: string;
    nameOfTheInsured: string;
    addressOfTheInsured: string;
    postCodeAndPlaceResidenceInsuredPerson: string;
    damageLocation: string;
    damageAssessmentDayAndTime: string;
    kindOfTrip: string;
    whereAndHowWasTheLostOrDamagedBaggageStored: string;
    exactDescriptionOfTheCourseOfTheDamage: string;
    witnessesOfTheDamage: string;
    nameAndAddress: string;
    telephoneOrFax: string;
    possiblyPoliceDiaryNumber: string;
    whenWasTheDamageReported: string;
    whoBecameLiableForTheDamageWhenAndHowHeld: string;
    whoHasAlreadyPaidWhatCompensationAndWhen: string;
    whatMeasuresHaveBeenTakenToRegain: string;
    typeOrInsuranceCertificate: string;
    affectedItem: string;
    ownAllOfTheAboveObjects: boolean;
    includeTheOwner: string;
    kindOfTripOptions: SelectItem[];
    handleChangeSwitcher: (name: string, value: boolean) => void;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigAONKompositDreiseReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigAONKompositDreise = ({
    insuredCompanyFileNumber,
    creditInstitute,
    iban,
    bic,
    accounOwner,
    nameOfTheInsured,
    addressOfTheInsured,
    postCodeAndPlaceResidenceInsuredPerson,
    damageLocation,
    damageAssessmentDayAndTime,
    kindOfTrip,
    whereAndHowWasTheLostOrDamagedBaggageStored,
    exactDescriptionOfTheCourseOfTheDamage,
    witnessesOfTheDamage,
    nameAndAddress,
    telephoneOrFax,
    possiblyPoliceDiaryNumber,
    whenWasTheDamageReported,
    whoBecameLiableForTheDamageWhenAndHowHeld,
    whoHasAlreadyPaidWhatCompensationAndWhen,
    whatMeasuresHaveBeenTakenToRegain,
    typeOrInsuranceCertificate,
    affectedItem,
    ownAllOfTheAboveObjects,
    includeTheOwner,
    kindOfTripOptions,
    handleChangeSwitcher,
}: DamageReportFormConfigAONKompositDreiseProps): DamageReportFormConfigAONKompositDreiseReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:luggageDamageReporting.title',
                Container: DamageReportFormContainer,
                id: 'luggageDamageReporting',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.insuredCompanyFileNumber',
                        name: 'insuredCompanyFileNumber',
                        value: insuredCompanyFileNumber,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.creditInstitute',
                        name: 'creditInstitute',
                        value: creditInstitute,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:luggageDamageReporting.iban',
                        name: 'iban',
                        value: iban,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:luggageDamageReporting.bic',
                        name: 'bic',
                        value: bic,
                    },
                    {
                        Item: DamageReportFormInput,
                        label: 'damages-report:luggageDamageReporting.accounOwner',
                        name: 'accounOwner',
                        value: accounOwner,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.nameOfTheInsured',
                        name: 'nameOfTheInsured',
                        value: nameOfTheInsured,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.addressOfTheInsured',
                        name: 'addressOfTheInsured',
                        value: addressOfTheInsured,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.postCodeAndPlaceResidenceInsuredPerson',
                        name: 'postCodeAndPlaceResidenceInsuredPerson',
                        value: postCodeAndPlaceResidenceInsuredPerson,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.damageLocation',
                        name: 'damageLocation',
                        value: damageLocation,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.damageAssessmentDayAndTime',
                        name: 'damageAssessmentDayAndTime',
                        value: damageAssessmentDayAndTime,
                    },
                    {
                        Item: DamageReportFormSelect,
                        label: 'damages-report:luggageDamageReporting.kindOfTrip',
                        name: 'kindOfTrip',
                        value: kindOfTrip,
                        items: kindOfTripOptions,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:luggageDamageReporting.whereAndHowWasTheLostOrDamagedBaggageStored',
                        name: 'whereAndHowWasTheLostOrDamagedBaggageStored',
                        value: whereAndHowWasTheLostOrDamagedBaggageStored,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:luggageDamageReporting.exactDescriptionOfTheCourseOfTheDamage',
                        name: 'exactDescriptionOfTheCourseOfTheDamage',
                        value: exactDescriptionOfTheCourseOfTheDamage,
                    },
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:luggageDamageReporting.witnessesOfTheDamage',
                        name: 'witnessesOfTheDamage',
                        value: witnessesOfTheDamage,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:whomWasDamageReported.title',
                Container: DamageReportFormContainer,
                id: 'whomWasDamageReported',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.nameAndAddress',
                        name: 'nameAndAddress',
                        value: nameAndAddress,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.telephoneOrFax',
                        name: 'telephoneOrFax',
                        value: telephoneOrFax,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.possiblyPoliceDiaryNumber',
                        name: 'possiblyPoliceDiaryNumber',
                        value: possiblyPoliceDiaryNumber,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.whenWasTheDamageReported',
                        name: 'whenWasTheDamageReported',
                        value: whenWasTheDamageReported,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.whoBecameLiableForTheDamageWhenAndHowHeld',
                        name: 'whoBecameLiableForTheDamageWhenAndHowHeld',
                        value: whoBecameLiableForTheDamageWhenAndHowHeld,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.whoHasAlreadyPaidWhatCompensationAndWhen',
                        name: 'whoHasAlreadyPaidWhatCompensationAndWhen',
                        value: whoHasAlreadyPaidWhatCompensationAndWhen,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.whatMeasuresHaveBeenTakenToRegain',
                        name: 'whatMeasuresHaveBeenTakenToRegain',
                        value: whatMeasuresHaveBeenTakenToRegain,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:whomWasDamageReported.typeOrInsuranceCertificate',
                        name: 'typeOrInsuranceCertificate',
                        value: typeOrInsuranceCertificate,
                    },
                ],
            },
        ],
        [
            {
                title: 'damages-report:indicateBelowAllItemsAffected.title',
                Container: DamageReportFormContainer,
                id: 'indicateBelowAllItemsAffected',
                formElements: [
                    {
                        Item: DamageReportFormFullwidthInput,
                        label: 'damages-report:indicateBelowAllItemsAffected.affectedItem',
                        name: 'affectedItem',
                        value: affectedItem,
                    },
                    {
                        Item: DamageReportFormTitleText,
                        label: 'damages-report:indicateBelowAllItemsAffected.сontinuationOfTheTitle',
                        name: '',
                        value: '',
                    },
                ],
            },
            {
                title: 'damages-report:indicateBelowAllItemsAffected.ownAllOfTheAboveObjects',
                id: 'ownAllOfTheAboveObjects',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:indicateBelowAllItemsAffected.includeTheOwner',
                        value: includeTheOwner,
                        name: 'includeTheOwner',
                    },
                ],
                showIfFalse: true,
                isCombination: ownAllOfTheAboveObjects,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'ownAllOfTheAboveObjects',
                        !ownAllOfTheAboveObjects
                    ),
                Container: DamageFormConditionalBlock,
            },
        ],
    ];
};
