import React, { FC } from 'react';
import { useTheme } from '@material-ui/core';
import { NivoTheme } from 'src/theming/theme-nivo/nivo-theme-context';
import { useDashboardContext } from 'src/shared/contexts';
import LoadingIndicator from 'src/shared/loading-indicator';
import { DashboardCard } from '../dashboard-card/dashboard-card';
import { DashboardPieTacho } from '../dashboard-pie-tacho/dashboard-pie-tacho';
import { DashboardListOfNumericValues } from '../dashboard-list-of-numeric-values/dashboard-list-of-numeric-values';
import { dashboardGetColorForValueLimit } from '../dashboard-get-color-for-value-limit';

interface Props {
    nivoTheme: NivoTheme;
    title: string;
    editMode: boolean;
    damageRatio: { year: string; value: number }[];
    isShown?: boolean;
    handleChangeShown?: (name: string, value: boolean) => void;
    name?: string;
}

export const DashboardDamagesRatio: FC<Props> = ({
    nivoTheme,
    title,
    editMode,
    isShown,
    handleChangeShown,
    name,
    damageRatio,
}): JSX.Element => {
    const theme = useTheme();

    const { isGetDashboardDataLoading } = useDashboardContext();

    let ratio = [];
    if (Array.isArray(damageRatio)) {
        ratio = damageRatio?.reduce((acc, item) => {
            const colorForValue = dashboardGetColorForValueLimit(item.value, theme);
            const calculatedYearlyRatio = {
                year: item.year,
                value: item.value,
                stylesForValue: {
                    color: colorForValue,
                },
            };
            return [...acc, calculatedYearlyRatio];
        }, []);
    }

    return (
        <DashboardCard
            header={title}
            editMode={editMode}
            isShown={isShown}
            handleChangeShown={handleChangeShown}
            name={name}
        >
            <>
                {isGetDashboardDataLoading && <LoadingIndicator />}
                {((!isGetDashboardDataLoading && !ratio) || ratio?.length <= 1) && (
                    <DashboardPieTacho
                        nivoTheme={nivoTheme.theme}
                        value={ratio[0]?.value}
                        unit='%'
                    />
                )}
                {!isGetDashboardDataLoading && ratio?.length > 1 && (
                    <DashboardListOfNumericValues values={ratio} minValue={0} unit='%' />
                )}
            </>
        </DashboardCard>
    );
};
