export enum AbilityActions {
    use = 'use',
    create = 'create',
    edit = 'edit',
    delete = 'delete',
    see = 'see',
    cancel = 'cancel',
    change = 'change',
    transcode = 'transcode',
    establish = 'establish',
    update = 'update',
}
