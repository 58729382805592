import { ListItem } from 'src/theming';
import { UserManagementSectionResponseBody } from './user-management-section-response-body';

export const userManagementSectionUserListFormatter = (
    data: UserManagementSectionResponseBody
): { count: number; result: ListItem[] } => {
    return {
        count: data?.count,
        result: data?.result?.map((user) => {
            return {
                ...user,
                id: user.id,
                text: `${user.name} ${user.surname}`,
                style: {
                    opacity: user.active ? 1 : 0.5,
                },
            };
        }),
    };
};
