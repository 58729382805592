import { useState, useEffect } from 'react';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useContractsListSettings, useGlobalFilter } from 'src/shared/contexts';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { useAuth } from 'src/user-management/context-auth';

import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import ContractRecordDto from './contract-record-dto';

import { contractsListRequestBodyPreparing } from './contracts-list-request-body-preparing';
import { constractListGetAdapter } from './contract-list-get-adapter';

const isAONKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

interface UseContractsListDataReturn {
    contracts: { result: ContractRecordDto[]; count: number };
    contractsLoading: boolean;
    contractsAmount: number;
    contractsAmountLoading: boolean;
}

export const useContractsListData = (
    isColumnSettingsLoading: boolean,
    triggerUpdate
): UseContractsListDataReturn => {
    const httpClient = useHttpClient();

    const [contracts, setContracts] =
        useState<{ result: ContractRecordDto[]; count: number }>(null);
    const [contractsLoading, setContractsLoading] = useState<boolean>(false);
    const [contractsAmountData, setContractsAmountData] = useState<{
        count: number;
        isLoading: boolean;
    }>({ count: null, isLoading: false });

    const { filter, division, availableDivisions } = useGlobalFilter();
    const { userData } = useAuth();
    const { sortModel, vnrValue, productsList, page, rowsPerPage, filterValue } =
        useContractsListSettings();

    const debouncedVnrValue = useDebounce(vnrValue);

    const makeRequestForContractsList = (): Promise<{
        result: ContractRecordDto[];
        count: number;
    }> => {
        const requestBody = contractsListRequestBodyPreparing({
            sortModel,
            vnrValue: debouncedVnrValue,
            filterValue,
            productsList: isAONKompositInstance ? [] : productsList,
            customerConnections: filter.customerConnections,
            customers: filter.customers,
            page,
            rowsPerPage,
            division,
            userRoles: userData.userInfo.roles,
            availableDivisions: availableDivisions.map((item) => item.division),
        });
        return httpClient.post(`get-contracts`, requestBody);
    };

    const makeRequestForContractsGeneralAmount = async (): Promise<void> => {
        try {
            setContractsAmountData({ count: null, isLoading: true });
            const requestBody = contractsListRequestBodyPreparing({
                filterValue,
                division,
                vnrValue: debouncedVnrValue,
                productsList: isAONKompositInstance ? [] : productsList,
                customerConnections: filter.customerConnections,
                customers: filter.customers,
                userRoles: userData.userInfo.roles,
                availableDivisions: availableDivisions.map((item) => item.division),
            });
            const data = await httpClient.post<{ count: number }>(
                'get-contracts/count',
                requestBody
            );
            setContractsAmountData({ count: data.count, isLoading: false });
        } catch {
            setContractsAmountData({ count: null, isLoading: false });
        }
    };

    useEffect(() => {
        let canceled = false;
        if (!isColumnSettingsLoading && division && filter) {
            setContractsLoading(true);
            setContracts({ result: [], count: -1 });
            makeRequestForContractsList()
                ?.then((data: { result: ContractRecordDto[]; count: number }) => {
                    if (data && !canceled) {
                        setContracts({
                            ...data,
                            result: data?.result?.map((item) =>
                                constractListGetAdapter(item)
                            ),
                        });
                    }
                })
                .finally(() => setContractsLoading(false));
        }

        return () => {
            canceled = true;
        };
    }, [
        debouncedVnrValue,
        sortModel,
        filterValue,
        isColumnSettingsLoading,
        page,
        rowsPerPage,
        division,
        userData,
        filter,
        triggerUpdate,
    ]);

    useEffect(() => {
        makeRequestForContractsGeneralAmount();
    }, [debouncedVnrValue, filterValue, division, filter, userData]);

    return {
        contracts,
        contractsLoading,
        contractsAmount: contractsAmountData.count,
        contractsAmountLoading: contractsAmountData.isLoading,
    };
};
