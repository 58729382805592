import { CheckboxItem, SelectItem } from 'src/theming';

export interface UserGetResponseBody {
    id: string;
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    active: true;
    isCustomerAdvisorAdmin: boolean;
    userGroup: 'Internal';
    roles: ['string'];
    customerConnections: IClientConnections[];
    customerAdvisorNumber?: string;
    addNewCustomersAutomatically?: boolean;
    activated: boolean;
    hasTwoFactorAuth: boolean;
    isOwnedByDifferentAdvisorAdmin: boolean;
}

export interface WithBranchFormCard {
    id: string;
    clientConnections: SelectItem[];
    clientConnectionDisabled: boolean;
    clientConnectionValue: string;
    clients: CheckboxItem[];
    divisions: CheckboxItem[];
}

export interface UserClientsServerData {
    id: string;
    customerConnection: string;
    customers: {
        customer: string;
        id: string;
        customerName2: string;
        customerName3: string;
    }[];
    divisions: { division: string; id: string }[];
}

export type UserEditingFormData = {
    id?: string;
    name: string;
    surname: string;
    email: string;
    userGroup: string;
    active: boolean;
    phoneNumber: string;
    customerAdvisorNumber?: string;
    isCustomerAdvisorAdmin: boolean;
    activated: boolean;
    roles: { name: string }[];
    chosenClientConnections: IClientConnections[];
    cardsWithBranches?: WithBranchFormCard[];
    addNewCustomersAutomatically?: boolean;
};

export interface UserEditFormApproveDeletingModalWindowProps {
    activeUser: string;
    makeRequestForUsersList: () => void;
    handleAddNewUser: () => void;
}

export interface IClient {
    id: string;
    customer: string;
    customerName2: string;
    customerName3: string;
}

export interface IDivision {
    id: string;
    division: string;
}

export interface IClientConnections {
    id: string;
    customerConnection: string;
    databaseName: string;
    customers: IClient[];
    divisions: IDivision[];
}

export enum UserEditFormRequestTypes {
    CREATE = 'create',
    EDIT = 'edit',
}

export type UserEditFormDataForRequest = {
    url: string;
    data: unknown;
};

export interface InternalClientCard {
    id: string;
    clients: CheckboxItem[];
    clientConnections: CheckboxItem[];
}
