import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useElectroStyles } from './contract-details-view-electro-style';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { ContractDetailsViewElectroResponseBody } from './contract-details-view-electro-response-body';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface ContractDetailsViewElectroProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewElectro = (
    props: ContractDetailsViewElectroProps
): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const electroClasses = useElectroStyles();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewElectroResponseBody>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewElectroResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewElectroResponseBody>(
                `contracts/${id}/elektro`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewElectroResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const areAnyValuesForTable = Boolean(
        typeof detailsData?.sbInEur1 === 'number' ||
            typeof detailsData?.sbInEur2 === 'number' ||
            typeof detailsData?.sbInEur3 === 'number' ||
            typeof detailsData?.vsNewValueInEur1 === 'number' ||
            typeof detailsData?.vsNewValueInEur2 === 'number' ||
            typeof detailsData?.vsNewValueInEur3 === 'number'
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys?.map((x) => detailsData[x])?.filter((x) => x)?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List className={contractDetailsViewDataClasses.list}>
                        {(detailsData?.contractType || detailsData?.risk) && (
                            <>
                                <ContractDetailsListItem
                                    hidden={false}
                                    value={detailsData?.contractType}
                                    title='contract-details-view:contractDetails.contractType'
                                />
                                <ContractDetailsListItem
                                    hidden={false}
                                    value={detailsData?.risk}
                                    title='contract-details-view:contractDetails.versRisiko'
                                />
                            </>
                        )}
                    </List>

                    {
                        <List className={contractDetailsViewDataClasses.list}>
                            {detailsData && areAnyValuesForTable && (
                                <ListItem>
                                    <table className={electroClasses.table}>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.insuredThings'
                                                    )}
                                                </th>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.vsNewValueInEur'
                                                    )}
                                                </th>
                                                <th>
                                                    {t(
                                                        'contract-details-view:contractDetails.sbInEuro'
                                                    )}
                                                </th>
                                            </tr>
                                            {(detailsData?.sbInEur1 ||
                                                detailsData?.vsNewValueInEur1) && (
                                                <tr>
                                                    <td
                                                        className={
                                                            electroClasses.tableCell
                                                        }
                                                    >
                                                        {t(
                                                            `contract-details-view:contractDetails.stationaryDevices`
                                                        )}
                                                    </td>
                                                    {detailsData?.vsNewValueInEur1 !=
                                                        null &&
                                                        detailsData?.vsNewValueInEur1 !=
                                                            0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.vsNewValueInEur1,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                    {detailsData?.sbInEur1 != null &&
                                                        detailsData?.sbInEur1 != 0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.sbInEur1,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                </tr>
                                            )}
                                            {(detailsData?.sbInEur2 ||
                                                detailsData?.vsNewValueInEur2) && (
                                                <tr>
                                                    <td
                                                        className={
                                                            electroClasses.tableCell
                                                        }
                                                    >
                                                        {t(
                                                            `contract-details-view:contractDetails.movingDevices`
                                                        )}
                                                    </td>
                                                    {detailsData?.vsNewValueInEur2 !=
                                                        null &&
                                                        detailsData?.vsNewValueInEur2 !=
                                                            0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.vsNewValueInEur2,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                    {detailsData?.sbInEur2 != null &&
                                                        detailsData?.sbInEur2 != 0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.sbInEur2,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                </tr>
                                            )}
                                            {(detailsData?.vsNewValueInEur3 ||
                                                detailsData?.sbInEur3) && (
                                                <tr>
                                                    <td
                                                        className={
                                                            electroClasses.tableCell
                                                        }
                                                    >
                                                        {t(
                                                            `contract-details-view:contractDetails.dataAndDataCarriers`
                                                        )}
                                                    </td>
                                                    {detailsData?.vsNewValueInEur3 !=
                                                        null &&
                                                        detailsData?.vsNewValueInEur3 !=
                                                            0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.vsNewValueInEur3,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                    {detailsData?.sbInEur3 != null &&
                                                        detailsData?.sbInEur3 != 0 && (
                                                            <td
                                                                className={
                                                                    electroClasses.tableCell
                                                                }
                                                            >
                                                                {t(
                                                                    'formatted-values:formattedCurrency',
                                                                    {
                                                                        currency:
                                                                            detailsData?.sbInEur3,
                                                                    }
                                                                )}
                                                            </td>
                                                        )}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </ListItem>
                            )}
                        </List>
                    }
                </>
            )}
        </Grid>
    );
};
