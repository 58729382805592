import moment from 'moment';

import { DamageReportFormGetRecordDTO } from '../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormGBWTypes } from '../damage-report-form-types/damage-report-form-GBW-HR-GESCH-types';
export const damageReportFormGBWGetReportRecordAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState: string
): DamageReportFormGBWTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,
        detailedDamageDescription: data.damageInformation?.damageDescription,
        amountOfDamage: data.damageInformation?.informationAboutAmountOfDamage,
        typeofDamageGBW: data.damageInformation?.damageType,
        road: data.damageInformation?.street,
        plz: data.damageInformation?.postCode,
        place: data.damageInformation?.city,
        country: data.damageInformation?.country,
        licenseNumber: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        damagedOwner: data.damageInformation?.ownerOfDamagedProperty,
        isToggleWhere: data.damageInformation?.damageItemsInsured,
        where: data.damageInformation?.insurer,
        isTogglePolice: data.policeRecordGbw?.incidentRecordedByPolice,
        policeStation: data.policeRecordGbw?.policeStation,
        fileNumber: data.policeRecordGbw?.policeFileNumber,
        initialMeasures: data.policeRecordGbw?.initialMeasures,
        localName: data.contactPerson?.name,
        firm: data.contactPerson?.company,
        phone: data.contactPerson?.phoneNumber,
        email: data.contactPerson?.email,
        claimNumber: data.contactPerson?.damageNumber,
        costCentre: data.contactPerson?.costCentre,
        isDeductVat: data.contactPerson?.entitledToDeductVat,
        iban: data.contactPerson?.iban,
        bic: data.contactPerson?.bic,
        fileInfos: data.fileInfos,
    };
};
