import { ThemeAutocompleteOptions } from 'src/theming/theme-autocomplete/theme-autocomplete';

const localeCompare = (
    a: ThemeAutocompleteOptions,
    b: ThemeAutocompleteOptions
): number => {
    if (b.value === 'all') {
        return 1;
    }
    if (a.value === 'all') {
        return -1;
    }
    return a.title.localeCompare(b.title);
};

export const splitOptionsToSelectedAndNotSelected = (
    options: ThemeAutocompleteOptions[]
): ThemeAutocompleteOptions[] => {
    const selectedPart: ThemeAutocompleteOptions[] = [];
    const notSelectedPart: ThemeAutocompleteOptions[] = [];

    options.forEach((item) => {
        const formattedItem = {
            ...item,
            additionalTitles: [item.data?.customerName2, item.data?.customerName3],
        };
        if (item.checked) {
            selectedPart.push(formattedItem);
        } else {
            notSelectedPart.push(formattedItem);
        }
    });
    return [...selectedPart.sort(localeCompare), ...notSelectedPart.sort(localeCompare)];
};
