import moment from 'moment';
import uniqid from 'uniqid';

import { TransportInfo } from '../../damage-report-form-selection-items/damage-report-form-generate-AON-transp-information-selection-options';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormAONKompositTranspTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-transport-types';

export const damageReportFormTransportGetAdapter = (
    data: DamageReportFormGetRecordDTO
): DamageReportFormAONKompositTranspTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;

    const transportInformation: { id: string; name: string }[] = [];

    if (data?.transpDamageInfo?.isTransport) {
        transportInformation.push({ id: uniqid(), name: TransportInfo.TRANSPORT });
    }
    if (data?.transpDamageInfo?.isStorage) {
        transportInformation.push({ id: uniqid(), name: TransportInfo.STORAGE });
    }
    if (data?.transpDamageInfo?.isExhibition) {
        transportInformation.push({ id: uniqid(), name: TransportInfo.EXHIBITION });
    }

    return {
        id: data.id,
        division: data?.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        files: [],
        fileInfos: data?.fileInfos,
        policyOrCertificateNumber: data?.transpСlientInfo?.policyNumber,
        insuredCompanyFileNumber: data?.transpСlientInfo?.insuredCompanyFileNumber,
        ibanCompensation: data?.transpСlientInfo?.ibanCompensation,
        bank: data?.transpСlientInfo?.bank,
        bic: data?.transpСlientInfo?.bic,
        accountOwner: data?.transpСlientInfo?.accountOwner,
        typeOfDamage: data?.transpDamageInfo?.damageType,
        departureDate: data?.transpDamageInfo?.departureDate,
        deliveringCarrier: data?.transpDamageInfo?.deliveringCarrier,
        senderOrRecipient: data?.transpDamageInfo?.senderOrRecipient,
        insuredGoods: data?.transpDamageInfo?.insuredGoods,
        damagedGoods: data?.transpDamageInfo?.damagedGoods,
        totalLoss: data?.broadcastInfo?.isTotalLoss,
        consignmentComplete: data?.broadcastInfo?.isConsignmentComplete,
        outwardlyDamageShipment: data?.broadcastInfo?.isOutwardlyDamageShipment,
        containerNumber: data?.broadcastInfo.containerNumber,
        leadSealOrSeal: data?.originalSeal?.leadSealOrSeal,
        availableUponArrival: data?.originalSeal?.isAvailableUponArrival,
        damaged: data?.originalSeal?.isDamaged,
        damageAssessment: data?.damageAssessmen?.deliverType,
        atThe: data?.damageAssessmen?.atThe,
        carrierForwardingAgentBeenGivenPureReceipt:
            data?.damageAssessmen?.hasCarrierGivenPureReceipt,
        averageCommissionerWasCalledIn:
            data?.damageAssessmen?.isAverageCommissionerCalledIn,
        damageDescription: data?.damageAssessmen?.damageDescription,
        expectedAmountOfDamage: data?.damageAssessmen?.expectedDamageAmountDamage
            ? String(data?.damageAssessmen?.expectedDamageAmountDamage).replace('.', ',')
            : '',
        transportInformation: transportInformation,
        transportFrom: data?.transpDamageInfo?.transportOf,
        transportVia: data?.transpDamageInfo?.transportVia,
        transportAfter: data?.transpDamageInfo?.transportTo,
        storageIn: data?.transpDamageInfo?.storageIn,
        storageInOrAt: data?.transpDamageInfo?.storageAt,
        exhibition: data?.transpDamageInfo?.exhibition,
        documentsAttached:
            data?.transpDocumentsInfo?.transpAttachedDocuments?.map((item) => ({
                name: item,
            })) || [],
    };
};
