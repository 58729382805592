import React from 'react';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    darkPaletteForAonMotor,
    lightPaletteForAonMotor,
} from 'src/theming/themes/aon-motor/aon-motor-theme-palettes';

interface AonMotorhemeProviderProps {
    children: JSX.Element;
}

export const AonMotorThemeProvider = (props: AonMotorhemeProviderProps): JSX.Element => {
    return (
        <ThemeProvider
            theme={(outerTheme): ThemeOptions => {
                const aonMotorTheme: ThemeOptions = { ...outerTheme };
                if (aonMotorTheme.palette.type === THEME_MODES.DARK) {
                    aonMotorTheme.palette = {
                        ...aonMotorTheme.palette,
                        ...darkPaletteForAonMotor,
                    };
                } else {
                    aonMotorTheme.palette = {
                        ...aonMotorTheme.palette,
                        ...lightPaletteForAonMotor,
                    };
                }
                return aonMotorTheme;
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};
