import React from 'react';

import { IconProps } from './index';

export const SofaIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='40' height='27' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M20.91 3.375C20.91 1.502 22.526 0 24.545 0h6.363c2 0 3.636 1.519 3.636 3.375V7.02c-2.109.692-3.636 2.548-3.636 4.742v3.425h-10V3.376zm-11.82 8.37v3.443h10V3.374C19.09 1.502 17.474 0 15.455 0H9.091c-2 0-3.636 1.519-3.636 3.375v3.628c2.109.692 3.636 2.565 3.636 4.742zm26.655-3.257c-1.781.27-3.018 1.84-3.018 3.527v4.86H7.273v-5.063c0-1.856-1.618-3.374-3.637-3.374C1.618 8.438 0 9.956 0 11.812v8.438c0 1.856 1.636 3.375 3.636 3.375V27h3.637v-3.375h25.454V27h3.637v-3.375c2 0 3.636-1.519 3.636-3.375v-8.438c0-2.041-1.982-3.678-4.255-3.324z'
                fill={fill}
            />
        </svg>
    );
};
