import React, {
    useEffect,
    useState,
    useContext,
    SetStateAction,
    Dispatch,
    useCallback,
} from 'react';
import { createContext } from 'react';
import { CheckboxItem } from 'src/theming';
import { useAuth } from 'src/user-management/context-auth';
import {
    IClientConnections,
    IDivision,
} from 'src/user-management/user-editing-form/user-edit-form-types';

import { useContractsAmount, ContractsAmount } from './use-contracts-amount';
import { CUSTOMER_ROLES, INTERNAL_ROLES } from '../../constants';
import { GlobalFilter } from '../global-filter';
import { useQuery } from '../../hooks';

type GlobalFilterContextType = {
    availableDivisions: IDivision[];
    division: string;
    rawCustomerConnections: CheckboxItem[];
    rawCustomers: CheckboxItem[];
    customersSearchTerm: string;
    changeCustomersSearchTerm: (searchTerm: string) => void;
    customerConnectionsSearchTerm: string;
    changeCustomerConnectionsSearchTerm: (searchTerm: string) => void;
    filter: GlobalFilter;
    changeDivision: (division: string) => void;
    applyFilter: (customerConnections: CheckboxItem[], customers: CheckboxItem[]) => void;
    changeRawCustomerConnections: (customerConnections: CheckboxItem[]) => void;
    changeRawCustomers: (customers: CheckboxItem[]) => void;
    divisionsThatDoNotHaveContracts: Set<string>;
    setDivisionsThatDoNotHaveContracts: Dispatch<SetStateAction<Set<string>>>;
    isReceivingContractsAmountsLoading: boolean;
    contractsAmounts: ContractsAmount[];
    refreshContractsAmounts: () => Promise<void>;
};

export const GlobalFilterContext = createContext<GlobalFilterContextType>(null);

export const useGlobalFilter = (): GlobalFilterContextType =>
    useContext<GlobalFilterContextType>(GlobalFilterContext);

export const GlobalFilterProvider = ({ children }): JSX.Element => {
    const { userData, authorized } = useAuth();

    const [divisionsThatDoNotHaveContracts, setDivisionsThatDoNotHaveContracts] =
        useState<Set<string>>(new Set());
    const [availableDivisions, setAvailableDivisions] = useState<IDivision[]>([]);
    const [division, setDivision] = useState<string>();
    const [customersSearchTerm, setCustomersSearchTerm] = useState('');
    const [customerConnectionsSearchTerm, setCustomerConnectionsSearchTerm] =
        useState('');

    const [filter, setFilter] = useState<GlobalFilter>({
        customerConnections: [],
        customers: [],
    });

    const [rawCustomerConnections, setRawCustomerConnections] = useState<CheckboxItem[]>(
        []
    );
    const [rawCustomers, setRawCustomers] = useState<CheckboxItem[]>([]);

    const query = useQuery();
    const forcedDiv = query.get('division');
    const forcedCustomerConnections = query.get('customerConnections')?.split(';');
    const forcedCustomers = query.get('customers')?.split(';');

    const {
        isLoading: isReceivingContractsAmountsLoading,
        contractsAmounts,
        refreshContractsAmounts,
    } = useContractsAmount(filter.customers);

    const applyFilter = useCallback(
        (customerConnections: CheckboxItem[], customers: CheckboxItem[]): void => {
            setFilter({
                customerConnections,
                customers,
            });
        },
        [setFilter]
    );

    const getCCFromFilter = useCallback(
        () =>
            filter.customerConnections
                .filter((item) => item.checked)
                .map((item) => item.value),
        [filter.customerConnections]
    );

    const getCustomersFromFilter = useCallback(
        () => filter.customers.filter((item) => item.checked).map((item) => item.value),
        [filter.customers]
    );

    useEffect(() => {
        if (Array.isArray(contractsAmounts)) {
            const newDivisionsThatDoNotHaveContracts = new Set(
                contractsAmounts
                    .filter((item) => item.amount === 0)
                    .map((item) => item.division)
            );
            setDivisionsThatDoNotHaveContracts(newDivisionsThatDoNotHaveContracts);
        }
    }, [contractsAmounts]);

    useEffect(() => {
        if (!authorized || !userData) {
            applyFilter([], []);
            return;
        }

        const ccFromFilter = getCCFromFilter();

        const newCustomerConnections: CheckboxItem[] = [
            {
                title: 'common:all',
                value: 'all',
                checked: ccFromFilter.length === 1 && ccFromFilter[0] === 'all',
                data: undefined,
                labelAsKeyForTFunction: true,
            },
        ];

        const targetDb =
            userData.currentDatabase ??
            userData.favouriteDatabase ??
            (userData.customerConnections && userData.customerConnections.length > 0
                ? userData.customerConnections[0].databaseName
                : undefined);

        const isUserHaveOnlyCustomerRoles =
            (userData?.userInfo?.roles?.includes(CUSTOMER_ROLES.ACCOUNT_OWNER) ||
                userData?.userInfo?.roles?.includes(CUSTOMER_ROLES.CLERK) ||
                userData?.userInfo?.roles?.includes(CUSTOMER_ROLES.CLERK_PLUS) ||
                userData?.userInfo?.roles?.includes(CUSTOMER_ROLES.ADMINISTRATOR) ||
                userData?.userInfo?.roles?.includes(CUSTOMER_ROLES.ADMINISTRATOR_PLUS)) &&
            !(
                userData?.userInfo?.roles?.includes(INTERNAL_ROLES.ADMIN_DOMAIN) ||
                userData?.userInfo?.roles?.includes(INTERNAL_ROLES.ADMIN_TECHNICAL) ||
                userData?.userInfo?.roles?.includes(INTERNAL_ROLES.CUSTOMER_ADVISER) ||
                userData?.userInfo?.roles?.includes(INTERNAL_ROLES.CA_BROKER)
            );

        if (userData.customerConnections) {
            userData.customerConnections.forEach((cc) => {
                if (
                    (cc.databaseName === targetDb || isUserHaveOnlyCustomerRoles) &&
                    cc.divisions &&
                    cc.divisions.length > 0
                ) {
                    newCustomerConnections.push({
                        title: cc.customerConnection,
                        value: cc.customerConnection,
                        checked: ccFromFilter.some(
                            (item) => item === cc.customerConnection
                        ),
                        data: cc,
                    });
                }
            });
        }

        if (
            newCustomerConnections.every((cc) => !cc.checked) &&
            newCustomerConnections.length > 1
        ) {
            newCustomerConnections[1].checked = true;
        }

        setRawCustomers(rawCustomers);
        setRawCustomerConnections(newCustomerConnections);
    }, [userData, authorized, applyFilter]);

    useEffect(() => {
        if (!rawCustomerConnections) {
            return;
        }

        const customersFromFilter = getCustomersFromFilter();

        const newCustomers: CheckboxItem[] = [
            {
                title: 'common:all',
                value: 'all',
                checked:
                    customersFromFilter.length === 1 && customersFromFilter[0] === 'all',
                data: undefined,
                labelAsKeyForTFunction: true,
            },
        ];

        const newCustomersBody: CheckboxItem[] = [];

        const isAllSelected = rawCustomerConnections.some(
            (item) => item.value === 'all' && item.checked
        );

        rawCustomerConnections.forEach((item) => {
            if ((item.checked || isAllSelected) && item.data) {
                const cc = item.data as IClientConnections;

                cc.customers.forEach((c) => {
                    newCustomersBody.push({
                        title: c.customer,
                        value: c.id,
                        checked: customersFromFilter.some((item) => item === c.id),
                        data: c,
                    });
                });
            }
        });

        if (newCustomersBody.length) {
            newCustomersBody.sort(function (a, b): number {
                if (a.title?.toLowerCase() > b.title?.toLowerCase()) {
                    return 1;
                }
                if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
                    return -1;
                }
                return 0;
            });
            newCustomers.push(...newCustomersBody);
        }

        if (newCustomers.every((cc) => !cc.checked) && newCustomers.length > 1) {
            newCustomers[1].checked = true;
        }

        setRawCustomers(newCustomers);
        setDivisionsThatDoNotHaveContracts(new Set([]));

        const allDivisions: Array<IDivision> = [];
        rawCustomerConnections.forEach((item) => {
            if ((item.checked || isAllSelected) && item.data) {
                const cc = item.data as IClientConnections;
                cc.divisions.forEach((item) => {
                    if (
                        !allDivisions.some(
                            (allDivisionsItem) => allDivisionsItem.id === item.id
                        )
                    ) {
                        allDivisions.push({ ...item, division: item.division.trim() });
                    }
                });
            }
        });
        setAvailableDivisions(allDivisions);
        applyFilter(rawCustomerConnections, newCustomers);
    }, [rawCustomerConnections, applyFilter]);

    useEffect(() => {
        if (availableDivisions && availableDivisions.length > 0) {
            if (
                division &&
                availableDivisions.some((item) => item.division === division)
            ) {
                setDivision(division);
            } else if (
                availableDivisions?.find(
                    (availableDivision) =>
                        availableDivision.division === userData?.defaultDivision
                )
            ) {
                setDivision(userData?.defaultDivision);
            } else {
                const newActualDivision = availableDivisions.find((item) => {
                    return !divisionsThatDoNotHaveContracts.has(item.division);
                });
                if (newActualDivision) {
                    setDivision(newActualDivision.division);
                } else {
                    setDivision('');
                }
            }
        } else {
            setDivision(undefined);
        }
    }, [availableDivisions]);

    useEffect(() => {
        if (forcedCustomerConnections?.length > 0 && forcedCustomers?.length > 0) {
            const newCustomerConnections = rawCustomerConnections.filter((rcc) =>
                forcedCustomerConnections.filter((fcc) => fcc === rcc.value)
            );
            const newCustomers = rawCustomers.filter((rc) =>
                forcedCustomers.filter((fc) => fc === rc.value)
            );
            applyFilter(newCustomerConnections, newCustomers);
        }
    }, [
        forcedCustomerConnections,
        rawCustomerConnections,
        forcedCustomers,
        rawCustomers,
    ]);

    return (
        <GlobalFilterContext.Provider
            value={{
                availableDivisions,
                division: forcedDiv ?? division,
                changeDivision: setDivision,
                applyFilter,
                filter:
                    forcedCustomers?.length > 0 && forcedCustomerConnections?.length > 0
                        ? { customerConnections: [], customers: [] }
                        : filter,
                rawCustomerConnections,
                rawCustomers,
                divisionsThatDoNotHaveContracts,
                setDivisionsThatDoNotHaveContracts,
                changeRawCustomerConnections: setRawCustomerConnections,
                changeRawCustomers: setRawCustomers,
                isReceivingContractsAmountsLoading,
                contractsAmounts,
                refreshContractsAmounts,
                customersSearchTerm,
                changeCustomersSearchTerm: setCustomersSearchTerm,
                customerConnectionsSearchTerm,
                changeCustomerConnectionsSearchTerm: setCustomerConnectionsSearchTerm,
            }}
        >
            {children}
        </GlobalFilterContext.Provider>
    );
};
