import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface CheckDummyCustomerDTO {
    message: string;
    isWarning: boolean;
}

interface CheckDummyCustomerDTOWithError extends CheckDummyCustomerDTO {
    error: AxiosError;
}

export const useCheckDummyCustomer = (
    newCustomer: string,
    name: string
): CheckDummyCustomerDTOWithError => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [checkingData, setCheckingData] = useState<CheckDummyCustomerDTO>();
    const [error, setError] = useState(null);

    const makeRequestToCheckCustomer = async (): Promise<CheckDummyCustomerDTO> => {
        return httpClient.post('customers/check-dummy-customer', {
            name,
            matchCode: newCustomer,
        });
    };

    useEffect(() => {
        if (newCustomer && name) {
            setError(null);
            (async () => {
                try {
                    const data = await makeRequestToCheckCustomer();
                    setCheckingData(data);
                } catch (err) {
                    setError(err);
                    err.error.response.data.errors?.userSettings?.map((item) => {
                        enqueueSnackbar(t(`customer-form:errors.${item}`), {
                            variant: 'error',
                        });
                    });
                }
            })();
        }
    }, [newCustomer]);

    return { ...checkingData, error };
};
