import React from 'react';
import clsx from 'clsx';
import { DarkMode } from 'use-dark-mode';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Button, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { ThemeSwitcher } from 'src/theming';
import { ThemeFooter } from 'src/theming/theme-footer';
import { HectorLogo } from 'src/theming/theme-logos';

import { useCeatLayoutStyles } from './user-ceat-layout-styles';
import { isCeatInstance, isCeatMaklerInstance, isHectorInstance } from 'src/environment';

interface UserCeatLayoutProps {
    children: JSX.Element;
    darkMode: DarkMode;
    backPath?: string;
}

const getLogoSrc = (isDark: boolean): string => {
    if (isDark) {
        return '/static/images/ceat-logo.png';
    } else {
        return '/static/images/ceat-logo.png';
    }
};

export const UserCeatLayout = (props: UserCeatLayoutProps): JSX.Element => {
    const { darkMode, children, backPath } = props;
    const router = useHistory();
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useCeatLayoutStyles();

    const handleGoBack = (): void => {
        router.push(backPath);
    };

    const logoSrc = getLogoSrc(darkMode.value);

    return (
        <div className={classes.pageContainer}>
            <div className={clsx(classes.block, classes.logoAndSwitcherContainer)}>
                <div className={classes.logoContainer}>
                    {isHectorInstance && <HectorLogo />}
                    {(isCeatInstance || isCeatMaklerInstance) && (
                        <img alt='logo' src={logoSrc} className={classes.logo} />
                    )}
                </div>
                <div className={classes.switcherContainer}>
                    <ThemeSwitcher
                        leftLabel={t('common:light')}
                        rightLabel={t('common:dark')}
                        checked={darkMode.value}
                        onChange={darkMode.toggle}
                    />
                </div>
            </div>
            <div className={clsx(classes.block, classes.previewContainer)} />
            <div>
                <Grid item>
                    {backPath && (
                        <Button
                            disableElevation
                            disableRipple
                            onClick={handleGoBack}
                            startIcon={<KeyboardBackspaceIcon />}
                        >
                            {t('common:back')}
                        </Button>
                    )}
                </Grid>
                <div style={{ flexGrow: 1 }} />
            </div>
            <div className={classes.childrenContainer}>
                <Typography variant='h3' className={classes.previewText}>
                    {isHectorInstance && t('user-management:hectorPortalDescription')}
                </Typography>
                <div className={classes.formContainer}>{children}</div>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div className={classes.footerContainer}>
                <ThemeFooter />
            </div>
        </div>
    );
};
