import React from 'react';
import { CheckboxItem } from 'src/theming';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DashboardPdfView } from 'src/dashboard/dahboard-pdf-view/dashboard-pdf-view';
import { useTranslation } from 'react-i18next';
import { useGlobalFilter } from 'src/shared/contexts';

const getAllOption = (t): CheckboxItem => ({
    title: t('all'),
    value: 'all',
    checked: true,
    data: undefined,
});

const division: CheckboxItem = {
    title: 'KFZW',
    value: 'KFZW',
    checked: true,
    data: null,
};

const years: CheckboxItem[] = [
    {
        title: '2022',
        value: '2022',
        checked: true,
        data: null,
    },
];

const settings: {
    dashboardWidget: string;
    position: number;
    isShown: boolean;
    className?: string;
}[] = [];

export const DashboardPdfTest = (): JSX.Element => {
    const { filter } = useGlobalFilter();
    const { t } = useTranslation(['common']);

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <DashboardPdfView
                    customerConnections={
                        filter.customerConnections.every((x) => x.checked)
                            ? [getAllOption(t)]
                            : filter.customers
                    }
                    customers={
                        filter.customers.every((x) => x.checked)
                            ? [getAllOption(t)]
                            : filter.customers
                    }
                    division={division}
                    years={years}
                    chartsData={null}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onContentLoaded={() => {}}
                    settings={settings}
                />
            </DndProvider>
        </div>
    );
};
