import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { TFunction } from 'i18next';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { useGBWStyles } from './contract-details-view-gbw-styles';

import { ContractDetailsViewDetailsResponseBodyGbw } from './contract-details-view-details-response-body-gbw';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

interface SBBlockProps {
    t: TFunction;
    sbAmount: number;
    sbPercent: number;
    sbMin: number;
    sbMax: number;
}

const SBBlock = (props: SBBlockProps): JSX.Element => {
    const { t, sbAmount, sbPercent, sbMax, sbMin } = props;
    return (
        <List>
            <ContractDetailsListItem
                value={t('formatted-values:formattedCurrency', {
                    currency: sbAmount,
                })}
                hidden={sbAmount === 0 || sbAmount === null}
                title='contract-details-view:contractDetails.sbInEUR'
            />
            <ContractDetailsListItem
                value={sbPercent ? String(sbPercent) : ''}
                hidden={sbPercent === 0 || sbPercent === null}
                title='contract-details-view:contractDetails.sbInEUR'
            />
            <ContractDetailsListItem
                value={t('formatted-values:formattedCurrency', {
                    currency: sbMin,
                })}
                hidden={sbMin === 0 && sbMin === null}
                title='contract-details-view:contractDetails.sbMin'
            />
            <ContractDetailsListItem
                value={t('formatted-values:formattedCurrency', {
                    currency: sbMax,
                })}
                hidden={sbMax === 0 && sbMax === null}
                title='contract-details-view:contractDetails.sbMax'
            />
        </List>
    );
};

interface ContractDetailsViewGbwProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewGbw = (
    props: ContractDetailsViewGbwProps
): JSX.Element => {
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const gbwClasses = useGBWStyles();

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyGbw>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyGbw> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyGbw>(
                `contracts/${id}/gbw`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyGbw) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {!isLoadingDetails && detailsData && !isDetailDataEmpty && (
                <>
                    <List
                        className={contractDetailsViewDataClasses.list}
                        style={{ width: '100%' }}
                    >
                        <ContractDetailsListItem
                            hidden={!detailsData.riskLocation}
                            value={detailsData.riskLocation}
                            title='contract-details-view:contractDetails.riskLocation'
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ListItem className={gbwClasses.subtitle}>
                            <ListItemText>
                                {t('contract-details-view:contractDetails.VSAndPPost')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.residentialBuildings}
                            value={
                                detailsData.residentialBuildings
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.residentialBuildings'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.residentialBuildingsSbInEuro}
                            sbPercent={detailsData?.residentialBuildingsSbInPercent}
                            sbMin={detailsData?.residentialBuildingsSbMin}
                            sbMax={detailsData?.residentialBuildingsSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.fire}
                            value={detailsData.fire ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.fire'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.fireSbInEuro}
                            sbPercent={detailsData?.fireSbInPercent}
                            sbMin={detailsData?.fireSbMin}
                            sbMax={detailsData?.fireSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.lw}
                            value={detailsData.lw ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.lw'
                            titleClassName={gbwClasses.subtitle}
                        />

                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.lwSbInEuro}
                            sbPercent={detailsData?.lwSbInPercent}
                            sbMin={detailsData?.lwSbMin}
                            sbMax={detailsData?.lwSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.stormOrHail}
                            value={
                                detailsData.stormOrHail ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.stormHill'
                            titleClassName={gbwClasses.subtitle}
                        />

                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.stormOrHailSbInEuro}
                            sbPercent={detailsData?.stormOrHailSbInPercent}
                            sbMin={detailsData?.stormOrHailSbMin}
                            sbMax={detailsData?.stormOrHailSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.ec}
                            value={detailsData.ec ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.ec'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.ecSbInEuro}
                            sbPercent={detailsData?.ecSbInPercent}
                            sbMin={detailsData?.ecSbMin}
                            sbMax={detailsData?.ecSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={detailsData?.elementary}
                            value={
                                detailsData.elementary ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.elementary'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.elementarySbInEuro}
                            sbPercent={detailsData?.elementarySbInPercent}
                            sbMin={detailsData?.elementarySbMin}
                            sbMax={detailsData?.elementarySbMax}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ListItem className={gbwClasses.subtitle}>
                            <ListItemText style={{ height: 24 }} />
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.lossOfRent}
                            value={
                                detailsData.lossOfRent ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.lossOfRent'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.lossOfRentSbInEuro}
                            sbPercent={detailsData?.lossOfRentSbInPercent}
                            sbMin={detailsData?.lossOfRentSbMin}
                            sbMax={detailsData?.lossOfRentSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.glass}
                            value={detailsData.glass ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.glass'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.glassSbInEuro}
                            sbPercent={detailsData?.glassSbInPercent}
                            sbMin={detailsData?.glassSbMin}
                            sbMax={detailsData?.glassSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.unbDangers}
                            value={
                                detailsData.unbDangers ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.dangers'
                            titleClassName={gbwClasses.subtitle}
                        />

                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.unbDangersSbInEuro}
                            sbPercent={detailsData?.unbDangersSbInPercent}
                            sbMin={detailsData?.unbDangersSbMin}
                            sbMax={detailsData?.unbDangersSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.aabCosts}
                            value={
                                detailsData.aabCosts ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.aabCosts'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.aabCostsSbInEuro}
                            sbPercent={detailsData?.aabCostsSbInPercent}
                            sbMin={detailsData?.aabCostsSbMin}
                            sbMax={detailsData?.aabCostsSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.flooding}
                            value={
                                detailsData.flooding ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.flooding'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.floodingSbInEuro}
                            sbPercent={detailsData?.floodingSbInPercent}
                            sbMin={detailsData?.floodingSbMin}
                            sbMax={detailsData?.floodingSbMax}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.inRiots}
                            value={detailsData.inRiots ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.riots'
                            titleClassName={gbwClasses.subtitle}
                        />
                        <SBBlock
                            t={t}
                            sbAmount={detailsData?.inRiotsSbInEuro}
                            sbPercent={detailsData?.inRiotsSbInPercent}
                            sbMin={detailsData?.inRiotsSbMin}
                            sbMax={detailsData?.inRiotsSbMax}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ListItem className={gbwClasses.subtitle}>
                            <ListItemText>
                                {t('contract-details-view:contractDetails.ecElementary')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={
                                typeof detailsData?.civilUnrestOrMaliciousDamageOrStrikeOrLockout !==
                                'boolean'
                            }
                            value={
                                detailsData.civilUnrestOrMaliciousDamageOrStrikeOrLockout
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.civilUnrestOrMaliciousDamageOrStrikeOrLockout'
                        />
                        <ContractDetailsListItem
                            hidden={
                                typeof detailsData?.vehicleImpactOrSmokeOrSupersonicWaves !==
                                'boolean'
                            }
                            value={
                                detailsData.vehicleImpactOrSmokeOrSupersonicWaves
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.vehicleImpactOrSmokeOrSupersonicWaves'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.sprinklerSystem}
                            value={
                                detailsData.sprinklerSystem
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.sprinklerSystem'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.tapWater}
                            value={
                                detailsData.tapWater ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.tapWater'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.storm}
                            value={detailsData.storm ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.storm'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.hail}
                            value={detailsData.hail ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.hail'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.hail}
                            value={detailsData.hail ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.floodingOfInsuredLocation'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.earthquake}
                            value={
                                detailsData.earthquake ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.earthquake'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.subsidenceOrLandslide}
                            value={
                                detailsData.subsidenceOrLandslide
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.subsidenceOrLandslide'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.snowPressureOrAvalanches}
                            value={
                                detailsData.snowPressureOrAvalanches
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.snowPressureOrAvalanches'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.volcanicEruption}
                            value={
                                detailsData.volcanicEruption
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                            title='contract-details-view:contractDetails.volcanicEruption'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.backwater}
                            value={
                                detailsData.backwater ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.backwater'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.graffiti}
                            value={
                                detailsData?.graffiti ? t('common:yes') : t('common:no')
                            }
                            title='contract-details-view:contractDetails.graffiti'
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.edOrV}
                            value={detailsData?.edOrV ? t('common:yes') : t('common:no')}
                            title='contract-details-view:contractDetails.edOrV'
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
