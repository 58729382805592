import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { LoginFormData } from '..';
import { useAbilityCtx, updateAbilityInstance } from 'src/roleAccesses';
import { usePathInterceptor, useQuery } from 'src/shared/hooks';
import { useInIframe } from 'src/shared/hooks';

import { useAuthInterceptor } from './useAuthInterceptor';
import {
    TokenResponse,
    UpdateUserSettingsFromState,
    UserSettingsDto,
} from '../context-auth-types';

import { authContextUpdateUserSettingsFormatter } from '../context-auth-update-user-settings-formatter';
import { conextAuthDefineMainPageForUser } from '../context-auth-define-home-page-for-user';

import { localStorageServices } from '../../lib/local-storage-services/local-storage-services';

import { getAllLoginErrors } from '../get-all-login-errors';
import { useCheckAuth } from './useCheckAuth';
import { AuthContext } from '.';
import { useAppVersionInterceptor } from 'src/shared/hooks/hooks-use-app-version-interceptor';
import { APP_ROUTES } from '../../routing';
import { LoginResult } from 'src/user-management/context-auth/login-result';

const defaultUserData = JSON.parse(localStorage.getItem('userData'));

export const AuthProvider = ({ children }): JSX.Element => {
    const router = useHistory();
    const ability = useAbilityCtx();
    const { t } = useTranslation(['user-management', 'errors']);
    const { enqueueSnackbar } = useSnackbar();

    const query = useQuery();
    const isBackendMode = query.get('isBackendMode') === '1';

    const [homePath, setHomePath] = useState(null);
    const [userData, setUserData] = useState(defaultUserData || null);
    const [authorized, setAuthorized] = useState<boolean>(null);
    const [requireTwoFactorVerification, setRequireTwoFactorVerification] =
        useState(false);
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);

    const inIFrame = useInIframe();

    const httpClient = useHttpClient();
    const { checkAuth } = useCheckAuth({ setAuth: setAuthorized, setUserData });

    usePathInterceptor();
    useAppVersionInterceptor();
    useAuthInterceptor({ isAuth: authorized, setAuth: setAuthorized, setUserData });

    useEffect(() => {
        if (!inIFrame) {
            checkAuth();
        }
    }, [inIFrame]);

    useEffect(() => {
        if (Array.isArray(userData?.userInfo?.roles) && userData.userInfo.roles.length) {
            updateAbilityInstance(
                ability,
                userData?.userInfo?.roles,
                userData?.userInfo?.id,
                userData?.userInfo?.isCustomerAdviserBrokerFromAms
            );
        }
    }, [ability, JSON.stringify(userData?.userInfo?.roles)]);

    const getUserData = useCallback(async (): Promise<void> => {
        return httpClient
            .get<UserSettingsDto>('users/settings')
            .then((data) => {
                if (data) {
                    setUserData(data);
                    localStorage.setItem('userData', JSON.stringify(data));
                }
            })
            .catch((err) => {
                if (authorized) {
                    handleLogout();
                }
            });
    }, []);

    const updateUserSettings = useCallback(
        (data: UpdateUserSettingsFromState): Promise<void> => {
            if (userData) {
                return httpClient
                    .put(
                        `users/settings/${userData.id}`,
                        authContextUpdateUserSettingsFormatter(data)
                    )
                    .then(() => {
                        const timer = setTimeout(() => {
                            getUserData();
                            clearTimeout(timer);
                        }, 0);
                    })
                    .catch((response) => {
                        response?.error?.response?.data?.errors?.customerConnections?.forEach(
                            (item: string) => {
                                enqueueSnackbar(t(`errors:${item}`), {
                                    variant: 'error',
                                });
                            }
                        );
                    });
            }
        },
        [userData]
    );

    const sendTwoFactorCode = async (): Promise<void> => {
        await httpClient.post('two-factor/send-code');
        enqueueSnackbar(t(`user-management:twoFactorCodeSent`), {
            variant: 'success',
        });
    };

    const clearUserData = (): void => {
        setAuthorized(false);
        setHomePath(null);
        setUserData(null);
        localStorageServices.clearAuthData();
    };

    useEffect(() => {
        if (isBackendMode) {
            setHomePath(APP_ROUTES.HOME);
        } else if (userData) {
            const homePath = conextAuthDefineMainPageForUser(ability);
            setHomePath(homePath || null);
        }
    }, [ability, userData, isBackendMode]);

    useEffect(() => {
        if (authorized && !userData) {
            const timer = setTimeout(() => {
                getUserData();
                clearTimeout(timer);
            }, 0);
        }
        if (authorized === false) {
            clearUserData();
        }
    }, [authorized, getUserData]);

    const handleLogin = useCallback(
        async (formData: LoginFormData): Promise<void> => {
            try {
                setIsLoadingLogin(true);
                const response = await httpClient.post<LoginResult>(
                    'users/login',
                    formData
                );

                if (response.requireTwoFactorVerification) {
                    await sendTwoFactorCode();
                    setRequireTwoFactorVerification(true);
                } else {
                    setAuthorized(true);
                }
            } catch (response) {
                const errors = getAllLoginErrors(response);
                if (errors.length > 0) {
                    errors.forEach((error) => {
                        enqueueSnackbar(t(`user-management:errorResponses.${error}`), {
                            variant: 'error',
                        });
                    });
                } else {
                    enqueueSnackbar(t('errors:unknownError'), {
                        variant: 'error',
                    });
                }
            } finally {
                setIsLoadingLogin(false);
            }
        },
        [httpClient, enqueueSnackbar]
    );

    const handleLogout = useCallback(async (): Promise<void> => {
        localStorageServices.clearAllTokens();
        httpClient
            .post('users/logout')
            .catch((error) => {
                clearUserData();
                return Promise.reject(error);
            })
            .finally(() => {
                clearUserData();
            });
    }, [httpClient, router]);

    return (
        <AuthContext.Provider
            value={{
                handleLogin,
                authorized,
                requireTwoFactorVerification,
                handleLogout,
                isLoadingLogin,
                userData,
                getUserData,
                updateUserSettings,
                homePath,
                setUserData,
                confirmTwoFactor: () => {
                    setRequireTwoFactorVerification(false);
                    setAuthorized(true);
                },
                sendTwoFactorCode,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
