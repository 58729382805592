import { CheckboxItem } from 'src/theming';
import { ContractTypes } from 'src/shared/constants';
import { TFunction } from 'i18next';

export enum FilterOptions {
    ACTIVE = 'active',
    ALL = 'all',
}

export const generateBranchList = (t: TFunction): CheckboxItem[] => {
    return [
        {
            value: ContractTypes.TRAVEL_INSURANCE,
            title: t('contract-types:cardTitles.travelInsurance'),
            checked: false,
            data: undefined,
        },
        {
            value: ContractTypes.VEHICLE,
            title: t('contract-types:cardTitles.vehicle'),
            checked: false,
            data: undefined,
        },
        {
            value: ContractTypes.BUILDING,
            title: t('contract-types:cardTitles.building'),
            checked: false,
            data: undefined,
        },
        {
            value: ContractTypes.LIABILITY,
            title: t('contract-types:cardTitles.liability'),
            checked: false,
            data: undefined,
        },
        {
            value: ContractTypes.PREVENTION,
            title: t('contract-types:cardTitles.prevention'),
            checked: false,
            data: undefined,
        },
        {
            value: 'all',
            title: t('all'),
            checked: true,
            data: undefined,
        },
    ];
};
