import React, { useMemo, ChangeEvent, BaseSyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { Moment } from 'moment';

import {
    FormControl,
    FormControlLabel,
    Grid,
    Button,
    Typography,
} from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import {
    ThemeExpansionPanel,
    ThemeSelectInput,
    ThemeDatePicker,
    ThemeCircularProgress,
} from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';

import {
    useReportCenterStyles,
    useFormControlLabelStyles,
    useFormControlStyles,
} from './report-center-styles';

import {
    reportCenterGenerateStatusSelectionItems,
    StatusSelectionItems,
} from './report-center-generate-status-selection-items';
import { reportCenterGetDivisionsFromGlobalFilter } from './report-center-get-divisions-from-global-filter';

type ExportDamagesFormData = {
    status: string;
    divisions: { name: string }[];
    dateFrom: ParsableDate;
    dateTo: ParsableDate;
};

export const ExportDamagesForm = (): JSX.Element => {
    const reportCenterClasses = useReportCenterStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlClasses = useFormControlStyles();

    const { t } = useTranslation(['report-center', 'divisions', 'common', 'errors']);

    const { filter } = useGlobalFilter();
    const { enqueueSnackbar } = useSnackbar();
    const httpClient = useHttpClient();

    const [isLoading, setLoading] = useState(false);

    const divisionSelectionItems = useMemo(
        () => reportCenterGetDivisionsFromGlobalFilter(filter.customerConnections, t),
        [filter, t]
    );

    const { control, watch, setValue, handleSubmit } = useForm<ExportDamagesFormData>({
        mode: 'onChange',
        defaultValues: {
            status: StatusSelectionItems.ALL as string,
            divisions: [{ name: 'all' }],
            dateFrom: null,
            dateTo: null,
        },
    });

    const { fields: divisionFields } = useFieldArray({ control, name: 'divisions' });

    const divisions = useMemo(
        () => divisionFields?.map((role) => role.name),
        [divisionFields]
    );

    const [status, dateFrom, dateTo] = watch(['status', 'dateFrom', 'dateTo']);

    const handleOnChangeStatus = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('status', e.target.value);
    };

    const handleChangeDate = (name, value: Moment): void => {
        setValue(name, value);
    };

    const handleChangeDivisions = (e: BaseSyntheticEvent): void => {
        const isAllChecked = e.target.value.includes('all') && !divisions.includes('all');
        if (isAllChecked) {
            setValue('divisions', [{ name: 'all' }]);
            return;
        }
        setValue(
            'divisions',
            e.target.value
                .map(
                    (item: string) =>
                        item !== 'all' && {
                            name: item,
                        }
                )
                .filter((item) => item)
        );
    };

    const makeRequestToExportDamages = (
        formData: ExportDamagesFormData
    ): Promise<void> => {
        setLoading(true);
        const requestBody: {
            allowedDivisions?: string[];
            status?: string;
            startDate?: ParsableDate;
            endDate?: ParsableDate;
            allowedCustomerConnections?: string[];
            allowedCustomers?: string[];
        } = {
            status: formData.status,
        };
        const allowedDivisions = formData?.divisions?.map((item) => item.name);
        const isAllChecked = allowedDivisions.includes('all');
        const areAllCustomersChecked = filter.customers?.find(
            (customer) => customer.value === 'all'
        )?.checked;
        const areAllCustomerConnectionsChecked = filter.customerConnections?.find(
            (cc) => cc.value === 'all'
        )?.checked;
        if (!isAllChecked && allowedDivisions?.length > 0) {
            requestBody.allowedDivisions = allowedDivisions;
        }
        if (formData.dateFrom) {
            requestBody.startDate = moment(formData.dateFrom).toISOString();
        }
        if (formData.dateTo) {
            requestBody.endDate = moment(formData.dateTo).toISOString();
        }
        if (!areAllCustomersChecked) {
            requestBody.allowedCustomers = filter.customers
                .filter((customer) => customer.checked)
                .map((customer) => customer.value);
        }
        if (!areAllCustomerConnectionsChecked) {
            requestBody.allowedCustomerConnections = filter.customerConnections
                .filter((cc) => cc.checked)
                .map((cc) => cc.value);
        }
        return httpClient.downloadExcel('damages/export-to-excel', requestBody);
    };

    const onSubmit = async (formData: ExportDamagesFormData): Promise<void> => {
        try {
            await makeRequestToExportDamages(formData);
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const statusItems = useMemo(() => reportCenterGenerateStatusSelectionItems(t), [t]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={reportCenterClasses.formContainer}
        >
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <Typography
                    variant='body2'
                    style={{ fontSize: '1.4rem', paddingBottom: 10 }}
                >
                    {t('exportDamages.hint')}
                </Typography>
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeSelectInput
                            editMode
                            items={statusItems}
                            value={status}
                            name='status'
                            onChange={handleOnChangeStatus}
                        />
                    }
                    label={t('exportDamages.status')}
                    labelPlacement='top'
                />
            </FormControl>
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeSelectInput
                            multiple
                            editMode
                            items={divisionSelectionItems}
                            value={divisions}
                            name=''
                            onChange={handleChangeDivisions}
                        />
                    }
                    label={t('divisions')}
                    labelPlacement='top'
                />
            </FormControl>
            <Grid
                container
                item
                justify='space-between'
                alignItems='stretch'
                wrap='nowrap'
            >
                <Grid item md={6}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeDatePicker
                                    editMode
                                    inputVariant='outlined'
                                    value={dateFrom}
                                    name='dateFrom'
                                    onChange={handleChangeDate}
                                    className={reportCenterClasses.dateField}
                                />
                            }
                            label={t('exportDamages.dayOfDamage')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid
                    container
                    item
                    md={1}
                    justify='center'
                    alignItems='center'
                    style={{ marginTop: 18 }}
                >
                    -
                </Grid>
                <Grid item md={6}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            style={{ marginTop: 20 }}
                            control={
                                <ThemeDatePicker
                                    editMode
                                    inputVariant='outlined'
                                    value={dateTo}
                                    name='dateTo'
                                    onChange={handleChangeDate}
                                    className={reportCenterClasses.dateField}
                                />
                            }
                            label=''
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Button
                disabled={isLoading}
                startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                type='submit'
                className={reportCenterClasses.generateReportButton}
            >
                {t('generateReport')}
            </Button>
        </form>
    );
};

export const ReportCenterExportDamages = (): JSX.Element => {
    const { t } = useTranslation(['report-center']);

    const reportCenterClasses = useReportCenterStyles();

    return (
        <ThemeExpansionPanel
            readonly
            contentAsJSX
            externalAccordionSummaryClassName={
                reportCenterClasses.externalAccordionSummary
            }
            externalAccordionDetailsClassName={
                reportCenterClasses.externalAccordionDetails
            }
            externalAccordionClassName={reportCenterClasses.externalAccordionClassName}
            data={[
                {
                    id: '1',
                    header: t('exportDamages.title'),
                    content: <ExportDamagesForm />,
                    headerClassName: reportCenterClasses.settingHeader,
                },
            ]}
        />
    );
};
