import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from '../..';

interface StyleProps {
    popperMinWidth: number;
}

export const useThemeHectorStyle = makeStyles((theme: Theme) => {
    return {
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color:
                theme.palette.type === THEME_MODES.LIGHT
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color:
                theme.palette.type === THEME_MODES.LIGHT
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
            opacity: 1,
        },
        expandMoreIcon: {
            width: 30,
            height: 30,
            color:
                theme.palette.type === THEME_MODES.LIGHT
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
        },
        tag: {
            maxWidth: '80%',
            overflow: 'hidden',
            color: theme.palette.primary.contrastText,
            fontSize: '1.6rem',
            margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
            [theme.breakpoints.down('md')]: {
                fontSize: '1.4rem',
            },
        },
        mainOptionTitle: {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    };
});

export const useThemeAutocompleteHectorStyle = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                height: 40,
                width: '100%',
                '& fieldset': {
                    borderColor: `${
                        theme.palette.type === THEME_MODES.LIGHT
                            ? theme.palette.secondary.main
                            : theme.palette.secondary.contrastText
                    }!important`,
                    borderRadius: 0,
                },
                '& .MuiFormLabel-root': {
                    color: theme.palette.primary.contrastText,
                },
                '& .MuiInputLabel-outlined': {
                    transform: 'translate(14px, 12px) scale(1)',
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
            },
            inputRoot: {
                height: 40,
                paddingTop: '0!important',
                paddingBottom: '0!important',
            },
            input: {
                height: 40,
                boxSizing: 'border-box',
                caretColor: theme.palette.secondary.main,
                color: theme.palette.primary.contrastText,
                '&::placeholder': {
                    color: theme.palette.primary.contrastText,
                },
            },
            focused: {},
            endAdornment: {
                top: 4,
            },
            paper: {
                paddingTop: '0!important',
                paddingBottom: '0!important',
                backgroundColor: theme.palette.primary.light,
            },
            listbox: {
                paddingTop: '0!important',
                paddingBottom: '0!important',
            },
            option: {
                fontSize: '1.2rem',
                paddingTop: 0,
                paddingBottom: 0,
            },
            noOptions: {
                fontSize: '1.4rem',
            },
            popper: {
                minWidth: ({ popperMinWidth }: StyleProps) => popperMinWidth,
            },
        };
    },
    { index: 1 }
);
