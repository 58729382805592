import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useTranslation } from 'react-i18next';

import { Typography, Button, makeStyles, Theme } from '@material-ui/core';

import {
    DashboardPdfView,
    DashboardPdfViewProps,
} from 'src/dashboard/dahboard-pdf-view/dashboard-pdf-view';
import { ThemeModalWindow } from 'src/theming';
import { useGlobalFilter, useDashboardContext } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';

import {
    isAonMotorInstance,
    isAonDigitalInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { useQuery } from 'src/shared/hooks';

import { INTERNAL_ROLES } from 'src/shared/constants';
import { DashboardDataResponseType } from 'src/shared/contexts/dashboard-context/dashboard-data-response-type';
import useDarkMode from 'use-dark-mode';
import { buildFetchDashboardDataBody } from '../../shared/contexts/dashboard-context/use-fetch-dashboard-data';

const useStyle = makeStyles((theme: Theme) => {
    return {
        okButton: {
            height: 40,
            marginRight: 'auto',
            padding: `0 ${theme.spacing(2)}px`,
            backgroundColor: theme.palette.info.light,
            color: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.contrastText;
                }
                return theme.palette.info.main;
            },
        },
    };
});

interface Props {
    isOpen: boolean;
    onClose: () => void;
    years: string[];
    dashboardData: DashboardDataResponseType;
    settings: {
        dashboardWidget: string;
        position: number;
        isShown: boolean;
        className?: string;
    }[];
}

export const ContractsDashboardPrintModalWindow = (props: Props): JSX.Element => {
    const query = useQuery();
    const isBackendMode = query.get('isBackendMode') === '1';
    const propertiesJson = query.get('pj');
    const { isOpen, onClose, years, settings } = props;

    const classes = useStyle();
    const pdfContainerRef = useRef<HTMLDivElement>();

    const { t } = useTranslation(['common', 'contract-types']);
    const httpClient = useHttpClient();
    const { filter, division } = useGlobalFilter();
    const { dashboardData } = useDashboardContext();
    const [propertiesForDashboard, setPropertiesForDashboard] =
        useState<Omit<DashboardPdfViewProps, 'onContentLoaded'>>();
    const iframeRef = useRef<HTMLIFrameElement>();
    const [isPdfLoaded, setIsPdfLoaded] = useState(false);
    const darkMode = useDarkMode();

    const { customerConnections, customers } = filter;

    const {
        userData: {
            userInfo: { roles },
        },
    } = useAuth();

    const allowedClientConnections = useMemo(
        () => customerConnections.filter((item) => item.checked),
        [filter]
    );
    const allowedClients = useMemo(
        () => customers.filter((item) => item.checked),
        [filter]
    );

    const isAllChecked = propertiesForDashboard?.customers.find(
        (item) => item.value === 'all'
    )?.checked;

    const isAllCustomerConnectionsChecked =
        propertiesForDashboard?.customerConnections.find(
            (item) => item.value === 'all'
        )?.checked;

    const hasAccessToPrint = useMemo(() => {
        return !(
            isAllCustomerConnectionsChecked &&
            isAllChecked &&
            roles.includes(INTERNAL_ROLES.ADMIN_TECHNICAL)
        );
    }, [isAllChecked, isAllCustomerConnectionsChecked, JSON.stringify(roles)]);

    const generatePdf = (): void => {
        const body = buildFetchDashboardDataBody({
            years: propertiesForDashboard.years.map((item) => Number(item.value)),
            customers: filter.customers,
            customerConnections: filter.customerConnections,
            division,
        });
        const propertiesJson = JSON.stringify({
            customers: propertiesForDashboard.customers
                .filter((c) => c.checked)
                .map((c) => {
                    return { ...c, data: null };
                }),
            customerConnections: propertiesForDashboard.customerConnections
                .filter((cc) => cc.checked)
                .map((cc) => {
                    return { ...cc, data: null };
                }),
            division: propertiesForDashboard.division,
            years: propertiesForDashboard.years,
        }).replaceAll('&', '<andr>');
        httpClient.downloadExcel('dashboard/export-pdf', {
            division: body.division,
            customers: body.customers.join(';'),
            damageYears: body.damageYears.join(';'),
            isGlobalCustomers: body.isGlobalCustomersSearch,
            propertiesJson,
        });
    };

    const onContentLoaded = (): void => {
        setIsPdfLoaded(true);
    };

    useEffect(() => {
        if (settings && dashboardData) {
            let dashboardProperties = {
                settings,
                customerConnections: allowedClientConnections,
                customers: allowedClients,
                division: {
                    title: division,
                    value: division,
                    checked: true,
                    data: null,
                },
                years: years.map((item) => ({
                    title: item,
                    value: item,
                    checked: true,
                    data: null,
                })),
                chartsData: dashboardData,
            };

            if (propertiesJson) {
                const formattedJson = propertiesJson.replaceAll('<andr>', '&');
                dashboardProperties = {
                    ...dashboardProperties,
                    ...JSON.parse(formattedJson),
                };
            }

            setPropertiesForDashboard(dashboardProperties);
        }
    }, [
        allowedClientConnections,
        allowedClients,
        division,
        years,
        dashboardData,
        iframeRef.current,
        settings,
        isBackendMode,
        propertiesJson,
    ]);

    useEffect(() => {
        if (!isBackendMode && isOpen && propertiesForDashboard) {
            generatePdf();
        }
    }, [isBackendMode, isOpen, propertiesForDashboard]);

    useEffect(() => {
        if (isBackendMode) {
            darkMode.disable();
        }
    }, [isBackendMode]);

    return (
        <>
            {isPdfLoaded && (
                <span id='pdf-loaded' style={{ visibility: 'collapse' }}></span>
            )}
            <ThemeModalWindow isOpen={isOpen} onClose={onClose}>
                <></>
                <Typography variant='body2' style={{ paddingTop: 20, maxWidth: 500 }}>
                    {hasAccessToPrint
                        ? t('contract-types:reportWillBeCreated')
                        : t('contract-types:reportCannotBeCreated')}
                </Typography>
                <Button className={classes.okButton} onClick={onClose}>
                    {t('ok')}
                </Button>
            </ThemeModalWindow>
            {propertiesForDashboard && isBackendMode && (
                <div
                    id='pdf-container'
                    key={JSON.stringify(propertiesForDashboard)}
                    ref={pdfContainerRef}
                >
                    <DashboardPdfView
                        {...propertiesForDashboard}
                        onContentLoaded={onContentLoaded}
                    />
                </div>
            )}
            {!isBackendMode && (
                <iframe
                    ref={iframeRef}
                    style={{
                        width: '100%',

                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: -10000,
                    }}
                    title='dashboard-pdf-generating'
                    src={window.location.href}
                />
            )}
        </>
    );
};
