import * as yup from 'yup';

const atLeastOneThingIsSelected = (value): boolean => {
    return !value.every((item) => item.value === 'No choice');
};

export const damageReportFormRelinkSchema = yup.object().shape({
    customer: yup.string().nullable().required(),
    customerConnection: yup.string().nullable().required(),
    dummyCustomer: yup.string().nullable().required(),
    dynamicFields: yup.array().test(atLeastOneThingIsSelected).min(1).required(),
});
