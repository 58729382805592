import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        pagination: {
            width: '100%',
            padding: `0 ${theme.spacing(3)}px!important`,
            marginLeft: 'auto',
            display: 'block',
            borderBottom: 'none',
            fontSize: '1.2rem',

            '& .MuiTypography-root': {
                fontSize: '1.2rem',
            },

            '& .MuiTablePagination-spacer': {
                flex: 0,
            },

            '& .MuiButtonBase-root': {
                paddingRight: theme.spacing(0),
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            '& .MuiSelect-iconOpen': {
                transform: 'rotate(180deg)',
            },

            '& .MuiTablePagination-selectRoot': {
                fontWeight: 'normal',
                marginRight: 'auto',
            },

            '& .MuiTablePagination-select': {
                minHeight: 'unset',
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
    };
});

export const usePaginationStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(5),
        },
    };
});

export const useRowsPerPageMenuStyle = makeStyles((theme: Theme) => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.main,
        },
        list: {
            padding: 0,
            '& .MuiListItem-root': {
                display: 'flex',
                justifyContent: 'center',
                padding: theme.spacing(1),
                fontSize: '1.2rem',
            },
        },
    };
});
