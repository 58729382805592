import React, {
    useRef,
    useState,
    useEffect,
    ChangeEvent,
    BaseSyntheticEvent,
    ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import {
    Grid,
    FormControl,
    OutlinedInput,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import qs from 'query-string';

import {
    ThemeListMenu,
    ListItem,
    ThemeLoader,
    ThemeMessageComponent,
    MessageTypes,
} from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

import { UserManagementNavigationPanel } from '../user-management-navigation-panel/user-management-navigation-panel';

import { MasterDataExternalLinkSection } from './master-data-external-link-section';
import { MasterDataEditForm } from './master-data-edit-form';

import { useStyle, useFormControlStyles, useInputStyles } from './master-data-style';

import { CustomerConnectionSectionResponseBody } from './master-data-client-connections-dto';
import { masterDataSectionCustomerListFormatter } from './master-data-section-connection-list-formatter';

type MasterDataFormData = {
    searchValue: string;
    page: number;
    rowsPerPage: number;
};

export const MasterData = (): JSX.Element => {
    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const inputClasses = useInputStyles();

    const clientConnectionsListContainerRef = useRef(null);

    const history = useHistory();
    const { state } =
        useLocation<{
            preselectedCC: string;
            page: number;
            searchValue: string;
            rowsPerPage: number;
        }>();

    const httpClient = useHttpClient();

    const [isListLoading, setListloading] = useState<boolean>(false);
    const [isMenuListDisabled, setMenuListDisabled] = useState<boolean>(false);
    const [activeId, setActiveId] = useState<string>(state?.preselectedCC || '');
    const [ccListMenuMaxHeight, setCcListMenuMaxHeight] = useState<number>(null);
    const [ccList, setCcList] = useState<{ count: number; result: ListItem[] }>();
    const [isMounted, setMounted] = useState<boolean>(false);

    const { t } = useTranslation(['common', 'user-management']);

    const { watch, setValue, reset } = useForm<MasterDataFormData>({
        mode: 'onChange',
        defaultValues: {
            searchValue: '',
            page: 0,
            rowsPerPage: 10,
        },
    });

    const [searchValue, page, rowsPerPage] = watch([
        'searchValue',
        'page',
        'rowsPerPage',
    ]);

    const debouncedSearchValue = useDebounce(searchValue, 500);

    const handleChooseActiveId = (value: string): void => {
        setActiveId(value);
        if (state) {
            history.push({ state: null });
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('searchValue', e.target.value);
        setActiveId('');
        if (state) {
            history.push({ state: null });
        }
    };

    const handleChangePage = (_: BaseSyntheticEvent, value: number): void => {
        setValue('page', value);
        if (state) {
            history.push({ state: null });
        }
    };

    const handleChangeRowsPerPage = (
        _: BaseSyntheticEvent,
        value: ReactElement
    ): void => {
        setValue('rowsPerPage', value.props.value);
        if (state) {
            history.push({ state: null });
        }
    };
    const makeRequestForCustomerConnectionList = (): void => {
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage;
        const queryParams: { take: number; skip: number; searchTerm?: string } = {
            take,
            skip,
        };
        if (searchValue) {
            queryParams.searchTerm = searchValue;
        }
        setListloading(true);
        httpClient
            .get(`master-data/connections?${qs.stringify(queryParams)}`)
            .then((data: CustomerConnectionSectionResponseBody) => {
                if (data) {
                    const formattedData = masterDataSectionCustomerListFormatter(data);
                    setCcList(formattedData);
                    if (!state?.preselectedCC) {
                        if (
                            Array.isArray(formattedData?.result) &&
                            formattedData.result.length > 0
                        ) {
                            setActiveId(formattedData.result[0].id);
                        } else {
                            setActiveId('');
                        }
                    }
                }
                if (!activeId) {
                    setActiveId(data?.result[0]?.id);
                }
            })
            .finally(() => {
                setListloading(false);
            });
    };

    useEffect(() => {
        if (state) {
            reset({
                page: state.page || 0,
                rowsPerPage: state.rowsPerPage || 10,
                searchValue: state.searchValue || '',
            });
            setActiveId(state.preselectedCC);
        }
    }, [state]);

    useEffect(() => setMounted(true), []);

    useEffect(() => {
        if (isMounted) {
            makeRequestForCustomerConnectionList();
        }
    }, [debouncedSearchValue, isMounted, rowsPerPage, page]);

    useEffect(() => {
        if (clientConnectionsListContainerRef.current) {
            setCcListMenuMaxHeight(
                clientConnectionsListContainerRef.current?.offsetHeight
            );
        }
    }, [clientConnectionsListContainerRef]);

    return (
        <Grid container className={classes.masterDataContainer}>
            <Grid container item className={classes.toolBar}>
                <UserManagementNavigationPanel activeMenu='master-data' />
                <div style={{ flexGrow: 1 }} />
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <OutlinedInput
                        placeholder={t('search')}
                        startAdornment={
                            <InputAdornment position='start'>
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        }
                        classes={inputClasses}
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                </FormControl>
            </Grid>
            <Grid container className={classes.header}>
                <Typography variant='h5' className={classes.title}>
                    {t('user-management:masterData.title')}
                </Typography>
            </Grid>
            <Grid container className={classes.mainContentMenu}>
                <Grid
                    item
                    ref={clientConnectionsListContainerRef}
                    className={classes.clientConnectionsContainer}
                >
                    {isListLoading && <ThemeLoader />}
                    {!isListLoading && (
                        <ThemeListMenu
                            isMenuListDisabled={isMenuListDisabled}
                            noDataMessage={t(
                                'user-management:masterData.noClientConnections'
                            )}
                            activeItem={activeId}
                            maxHeight={ccListMenuMaxHeight}
                            list={ccList?.result || []}
                            menuTitle={t(
                                'user-management:masterData.clientConnectionsList'
                            )}
                            setActiveItem={handleChooseActiveId}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            count={ccList?.count || 0}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={
                                handleChangeRowsPerPage as React.ChangeEventHandler<
                                    HTMLTextAreaElement | HTMLInputElement
                                >
                            }
                        />
                    )}
                </Grid>
                <Grid item className={classes.ccSettingsContainer}>
                    {!isListLoading &&
                        (!Array.isArray(ccList?.result) ||
                            ccList.result.length === 0) && (
                            <ThemeMessageComponent
                                message={t('user-management:masterData.noCCFound')}
                                type={MessageTypes.INFO}
                            />
                        )}
                    {activeId && (
                        <MasterDataEditForm
                            activeCC={activeId}
                            setMenuListDisabled={setMenuListDisabled}
                            title={activeId}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            searchValue={searchValue}
                            refetchClientConnections={
                                makeRequestForCustomerConnectionList
                            }
                        />
                    )}
                    {activeId && (
                        <MasterDataExternalLinkSection
                            activeClientConnection={activeId}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
