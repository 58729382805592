export enum Divisions {
    KFZW = 'KFZW',
    BHV = 'BHV',
    GBW = 'GBW',
    PHV = 'PHV',
    BAULEIST = 'BAULEIST',
    GESCH = 'GESCH',
    IS = 'IS',
    ELECTRO = 'ELEKTRO',
    BU = 'BU',
    DREISE = 'DREISE',
    GLAS = 'GLAS',
    HR = 'HR',
    LVR = 'LVR',
    MACHINE = 'MASCHINE',
    MONTAGE = 'MONTAGE',
    PRV = 'PRV',
    TRANSP = 'TRANSP',
    RS = 'RS',
    UV = 'UV',
    AON_FOCUS_LIABILITY = 'Aon Fokus Haftpflicht',
    AON_PROTECT_LIABILITY = 'Aon Protect Haftpflicht',
    BUILDERS_LIABIITY = 'Bauherrenhaftpflicht',
    PROFESSIONAL_LIABILITY = 'Berufshaftpflicht',
    COMPANY_PRODUCT_LIABILITY = 'Betriebs-/Produkthaftpfl.',
    PUBLIC_LIABILITY = 'Betriebshaftpflicht',
    EXCEDENT_LIABILITY = 'Excedenten-Haftpflicht',
    WATERSHED_LIABILITY = 'Gewässersch.-Haftpflicht',
    LIABILITY = 'Haftpflicht',
    LIABILITY_SOLAR = 'Haftpflicht Solar',
    LIABILITY_WIND = 'Haftpflicht Wind',
    HOUSE_LAND_LIABILITY = 'Haus-/Grundb.-Haftpflicht',
    HUNTING_LIABILITY = 'Jagdhaftpflicht',
    AVIATION_LIABILITY = 'Luftfahrthaftpflicht',
    AVIATION_PRODUCT_LIABILITY = 'Luftfahrt-Produkt-Haftpf.',
    PERSONAL_LIABILITY = 'Privathaftpflicht',
    PROJECT_LIABILITY = 'Projekthaftpflicht',
    SPECIAL_LIABILITY = 'Spezial-Haftpflicht',
    RADIATION_LIABILITY = 'Strahlenhaftpflicht',
    PET_OWNER_LIABILITY = 'Tierhalterhaftpflicht',
    ENVIRONMENTAL_LIABILITY = 'Umwelthaftpflicht',
    FINANCIAL_LOSS = 'Vermögensschaden',
    PECUNIARY_DAMAGE_LIABILITY = 'Vermögensschadenhaftpfl.',
    FIDELITY = 'Vertrauensschaden',
    WATER_SPORTS_LIABILITY = 'Wassersport-Haftpflicht',
    AON_FOCUS_LUGGAGE = 'Aon Fokus Reisegepäck',
    LUGGAGE = 'Reisegepäck',
    AON_ELECTRONICS_PLATINUM = 'Aon Elektronik Platinum',
    AON_FOCUS_CONSTRUCTION_WORK = 'Aon Fokus Bauleistung',
    AON_ASSEMBLY = 'Aon Montage',
    CONSTRUCTION_WORK = 'Bauleistung',
    ELECTRONICS = 'Elektronik',
    ELECTRONICS_SOLAR_WATT = 'Elektronik Solarwatt',
    ELECTRONICS_SOLARWORLD = 'Elektronik Solarworld',
    ELECTRONICS_SONEPAR = 'Elektronik Sonepar',
    ELECTRONICS_PACKAGE = 'Elektronik-Pauschal',
    FBU_INDUSTRY = 'FBU-Industrie',
    FIRE_INDUSTRY = 'Feuer-Industrie',
    MACHINERY = 'Maschinen',
    MACHINE_FRAMEWORK_AGREEMENT = 'Maschinen Rahmenvertrag',
    MACHINES_CONSTRUCTION_EQUIPMENT = 'Maschinen/Baugeräte',
    MACHINE_BU = 'Maschinen-BU',
    ASSEMBLY = 'Montage',
    AON_FOCUS_TRANSPORT = 'Aon Fokus Transport',
    ALL_RISKS = 'Allgefahren',
    ALL_DANGEROUS_HOUSEHOLD_ITEMS = 'Allgefahren Hausrat',
    FIRE = 'Feuer',
    BUNDLED_BUILDINGS = 'Gebündelte Gebäude',
    BUNDLED_BUSINESS = 'Gebündelte Geschäft',
    BUNDLED_SACH = 'Gebündelte Sach',
    BUNDLED_PROPERTY_FIRE = 'Gebündelte Sach Feuer',
    GLASS = 'Glas',
    HOUSEHOLD_ITEMS = 'Hausrat',
    TAP_WATER = 'Leitungswasser',
    STORM = 'Sturm',
    TERROR = 'Terror',
    AON_ART_COLLECTIONS = 'Aon Art & Collections',
    AON_FOCUS_ON_ALL_HAZARDS = 'Aon Fokus Allgefahren',
    AON_FOCUS_ON_CYBER = 'Aon Fokus Cyber',
    AON_FOCUS_D_AND_O = 'Aon Fokus D&O',
    AON_FOCUS_ON_MEDIUM_SIZED_BUSINESSES = 'Aon Fokus Mittelstand',
    AON_FOCUS_ENGINE = 'Aon Fokus Motor',
    AON_FOCUS_ON_CRIMINAL_LAW = 'Aon Fokus Strafrechtssch.',
    AON_FOCUS_ACCIDENT = 'Aon Fokus Unfall',
    AON_PRIVATE = 'Aon Privat',
    FOREIGN_TRAVEL = 'Auslandsreise',
    AXA_PROFESSIONAL_PROTECTION = 'AXA Profischutz',
    OCCUPATIONAL_DISABILITY = 'Berufsunfähigkeit',
    GUARANTEE_INSURANCE = 'Bürgschaftsversicherung',
    CYBER_INSURANCE = 'Cyber-Versicherung',
    D_AND_O = 'D & O',
    D_AND_O_EXCESS = 'D & O Exzedent',
    D_AND_O_RUN_OFF = 'D & O run-off',
    D_AND_O_DEDUCTABLE = 'D & O Selbstbehalt',
    D_AND_O_BASIC_CONTRACT_SPS2 = 'D&O-Grundvertrag (SPS II)',
    SINGLE_ACCIDENT = 'Einzelunfall',
    VEHICLE_LEGAL_PROTECTION = 'Fahrzeugrechtsschutz',
    FAMILY_LEGAL_PROTECTION = 'Familien-Rechtsschutz',
    FAIRY = 'Fee',
    COMPANY_PROFESSIONAL_JURISPRUDENCE = 'Firmen-/Berufs-Rechtssch.',
    CAMERAS = 'Fotoapparate',
    BUNDLED_FILM = 'Gebündelte Film',
    BUNDLED_INSURANCE = 'Gebündelte Versicherung',
    LAND_TENANT_LAW = 'Grundst/Mieter-Rechtssch.',
    GROUP_ACCIDENT = 'Gruppenunfall',
    GROUP_ACCIDENT_AON = 'Gruppenunfall Aon',
    TRADE_CRAFT = 'Handel-Handwerk',
    FEE_FOR_ADVICE = 'Honorar für Beratung etc.',
    FEE_FOR_PROJECT_BUSINESS = 'Honorar Projektgeschäft',
    MOTOR_VEHICLE_BUSINESS_TRIP = 'Kfz-Dienstreise',
    CAR_SPECIAL = 'KFZ-Spezial',
    SUFFER = 'Kranken',
    ARTS = 'Kunst',
    LIFE = 'Leben',
    METAL_PENSION = 'Metallrente',
    LOSS_OF_RENT = 'Mietverlust',
    MULTI_LINE = 'Multi-Line',
    MUSICAL_INSTRUMENTS = 'Musikinstrumente',
    PRIVATE_TRAFIC_LEGAL_PROTECTION = 'Priv-/Verkehrs-Rechtssch.',
    SUBJECTS = 'Probanden',
    PRODUCT_PROTECTION = 'Produktschutz',
    PRO_DYNAMIC = 'Pro-Dynamic',
    PROJECT_INSURANCE = 'Projektversicherung',
    LEGAL_PROTECTION_FOR_SELF_EMPLOYED = 'Rechtssch.f. Selbständige',
    LEGAL_PROTECTION = 'Rechtsschutz',
    PENSIONS = 'Renten',
    REVENUE_SHARE = 'Revenue Share',
    RECALL_AUTOMOTIVE_SUPPLIER = 'Rückruf KFZ-Zulieferer',
    SOLAR_POLICY = 'Solar-Police',
    OTHER_INSURANCE = 'Sonstige Versicherung',
    SPECIAL_CRIMINAL_LAW_PROTECTION = 'Spezial-Strafrechtsschutz',
    TRANSACTION_INSURANCE = 'Transaktions-Versicherung',
    TRANSPORTATION_GOODS = 'Transport-Waren',
    ENVIRONMENTAL_DAMAGE = 'Umweltschaden',
    ACCIDENT = 'Unfall',
    ACCIDENT_MIDDLE_CLASS = 'Unfall Mittelstand',
    UNITALLRISK = 'UNITALLRISK',
    TRANSPORT_LIABILITY = 'Verkehrshaftung',
    TRAFFIC_LEGAL_PROTECTION = 'Verkehrsrechtsschutz',
    PROPERTY_LEGAL_PROTECTION = 'Vermögensrechtsschutz',
    WATER_SPORTS_INSURANCE = 'Wassersport-Kasko',
    WIND_POLICY = 'Wind-Police',
    BUILDING = 'Gebäude',
}
