import {
    LIGHT_AON_MOTOR_THEME_COLORS,
    DARK_AON_MOTOR_THEME_COLORS,
} from './aon-motor-theme-colors';
import { THEME_MODES } from '..';

export const lightPaletteForAonMotor = {
    type: THEME_MODES.LIGHT,
    primary: {
        main: LIGHT_AON_MOTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: LIGHT_AON_MOTOR_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: LIGHT_AON_MOTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: LIGHT_AON_MOTOR_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: LIGHT_AON_MOTOR_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: LIGHT_AON_MOTOR_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: LIGHT_AON_MOTOR_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: LIGHT_AON_MOTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: LIGHT_AON_MOTOR_THEME_COLORS.INFO_MAIN_COLOR,
        light: LIGHT_AON_MOTOR_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: LIGHT_AON_MOTOR_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: LIGHT_AON_MOTOR_THEME_COLORS.ERROR_MAIN_COLOR,
        light: LIGHT_AON_MOTOR_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};

export const darkPaletteForAonMotor = {
    type: THEME_MODES.DARK,
    primary: {
        main: DARK_AON_MOTOR_THEME_COLORS.PRIMARY_MAIN_COLOR,
        light: DARK_AON_MOTOR_THEME_COLORS.PRIMARY_LIGHT_COLOR,
        dark: DARK_AON_MOTOR_THEME_COLORS.PRIMARY_DARK_COLOR,
        contrastText: DARK_AON_MOTOR_THEME_COLORS.PRIMARY_CONTRAST_TEXT,
    },
    secondary: {
        main: DARK_AON_MOTOR_THEME_COLORS.SECONDARY_MAIN_COLOR,
        light: DARK_AON_MOTOR_THEME_COLORS.SECONDARY_LIGHT_COLOR,
        dark: DARK_AON_MOTOR_THEME_COLORS.SECONDARY_DARK_COLOR,
        contrastText: DARK_AON_MOTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
    },
    info: {
        main: DARK_AON_MOTOR_THEME_COLORS.INFO_MAIN_COLOR,
        light: DARK_AON_MOTOR_THEME_COLORS.INFO_LIGHT_COLOR,
        contrastText: DARK_AON_MOTOR_THEME_COLORS.INFO_CONTRAST_TEXT,
    },
    error: {
        main: DARK_AON_MOTOR_THEME_COLORS.ERROR_MAIN_COLOR,
        light: DARK_AON_MOTOR_THEME_COLORS.ERROR_LIGHT_COLOR,
    },
};
