import React from 'react';
import { useTranslation } from 'react-i18next';
import useDarkMode from 'use-dark-mode';

import { Grid, Typography, Link } from '@material-ui/core';

import { useAuth } from 'src/user-management/context-auth';
import { ThemeLayout } from 'src/theming';
import { APP_MODULES } from 'src/shared/constants';

import { useErrorPageStyles } from './error-page-styles';

interface Props {
    message: string;
}

export const ErrorPage = ({ message }: Props): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['common', 'errors']);
    const classes = useErrorPageStyles();
    const { homePath } = useAuth();

    return (
        <ThemeLayout darkMode={darkMode} module={APP_MODULES.ADMINISTRATION}>
            <Grid container item direction='column' className={classes.container}>
                <Typography variant='body1'>{t(message)}</Typography>
                <Link href={homePath}>{t('goToHomePage')}</Link>
            </Grid>
        </ThemeLayout>
    );
};
