import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from '../themes';

export const useThemeSimpleHeaderStyles = makeStyles((theme: Theme) => {
    const isDark = THEME_MODES.DARK === theme.palette.type;
    return {
        logo: {
            cursor: 'pointer',
            filter: isDark ? 'brightness(0) invert(1)' : null,
        },
        logoWrapper: {
            width: 208,
            height: 41,
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            cursor: 'pointer',
            [theme.breakpoints.down('lg')]: {
                width: 150,
                height: 29,
            },
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: isDark
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.main,
                },
            },
        },
    };
});
