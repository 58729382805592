import React from 'react';

import { IconProps } from './index';

export const NoteTextIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='40' height='40' fill={fill} xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M24.444 15.556h12.223L24.444 3.333v12.223zM4.444 0h22.223L40 13.333v22.223A4.445 4.445 0 0135.556 40H4.444C1.978 40 0 38 0 35.556V4.444A4.43 4.43 0 014.444 0zm0 20v4.444h31.112V20H4.444zm0 8.889v4.444h20V28.89h-20z'
                fill='#000'
            />
        </svg>
    );
};
