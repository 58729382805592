import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';

import { CustomerDetailsView } from 'src/customers/customer-details-view/customer-details-view';

export const CustomerDetailsViewPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode}>
            <CustomerDetailsView />
        </ThemeLayout>
    );
};
