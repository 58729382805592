import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useAuth } from 'src/user-management/context-auth';

import { NotificationCenterNewMessageFormData } from 'src/shared/contexts/notification-center-context/notification-center-use-new-message';

interface UseSendMessageReturn {
    isLoading: boolean;
    send: (data: NotificationCenterNewMessageFormData) => Promise<void>;
}

export const useSendMessage = (onSuccess: () => void): UseSendMessageReturn => {
    const httpClient = useHttpClient();
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation(['notification-center', 'errors']);
    const { enqueueSnackbar } = useSnackbar();

    const {
        userData: {
            userInfo: { id },
        },
    } = useAuth();

    const send = async (data: NotificationCenterNewMessageFormData): Promise<void> => {
        const requestBody = {
            body: data.message,
            senderId: id,
            recipientId: data?.user || data?.customerAdvisor,
            createdDate: new Date().toISOString(),
        };
        try {
            setLoading(true);
            await httpClient.post('notifications', requestBody);
            enqueueSnackbar(t('notification-center:messageIsSentSuccessfully'), {
                variant: 'success',
            });
            onSuccess();
        } catch {
            enqueueSnackbar(t('errors:unknownError'), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return { isLoading, send };
};
