import { makeStyles, Theme } from '@material-ui/core/styles';
import { isCeatInstance, isCeatMaklerInstance, isHectorInstance, isMaasInstance } from 'src/environment';
import { THEME_MODES } from 'src/theming';
import { LIGHT_HECTOR_THEME_COLORS } from 'src/theming/themes/hector/hector-theme-colors';
import { LIGHT_MAAS_THEME_COLORS } from 'src/theming/themes/maas';
import { LIGHT_CEAT_THEME_COLORS } from 'src/theming/themes/ceat';

import { getBackgroundGradient } from '../contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useContractsHectorStyles = makeStyles(
    (theme: Theme) => {
        const isLightMode = theme.palette.type === THEME_MODES.LIGHT;

        return {
            contractsListContainer: {
                flexDirection: 'column',
                height: '100%',
            },
            toolBar: {
                height: 110,
                alignItems: 'center',
                flexWrap: 'nowrap',
                padding: `0 140px`,
                background: getBackgroundGradient(isLightMode, theme),
                [theme.breakpoints.down('lg')]: {
                    height: 80,
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            contentContainer: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                padding: `${theme.spacing(4)}px ${theme.spacing(1)}rem`,
                [theme.breakpoints.down('lg')]: {
                    padding: `0 ${theme.spacing(1)}px`,
                },
            },
            searchIcon: {
                width: 24,
                height: 24,
                opacity: 0.6,
            },
            settingsContainer: {
                display: 'flex',
                height: 75,
                alignItems: 'center',
            },
            settings: {
                width: 'fit-content',
                alignItems: 'center',
            },
            settingButton: {
                width: 50,
                height: 50,
                marginRight: theme.spacing(4),
                fontSize: '1.8rem',
                borderRadius: 0,
                transition: '0.2s',
                backgroundColor: isLightMode
                    ? theme.palette.secondary.main
                    : 'rgba(0, 0, 0, 0)',
                border: isLightMode
                    ? 'none'
                    : `1px solid ${LIGHT_HECTOR_THEME_COLORS.SECONDARY_CONTRAST_TEXT}`,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 0.6,
                },
                '& .MuiSvgIcon-root': {
                    width: 35,
                    height: 35,
                    color: theme.palette.secondary.contrastText,
                },
                [theme.breakpoints.down('md')]: {
                    width: 40,
                    height: 40,
                    '& .MuiSvgIcon-root': {
                        width: 30,
                        height: 30,
                    },
                },
            },
            tableContainer: {
                flexGrow: 1,
                minHeight: 700,
            },
            containedButtons: {
                height: 50,
                fontSize: '1.8rem',
                backgroundColor: isLightMode
                    ? theme.palette.primary.light
                    : theme.palette.secondary.contrastText,
                color: () => {
                    if (isLightMode)
                        return theme.palette.primary.contrastText;

                    if (isHectorInstance)
                        return LIGHT_HECTOR_THEME_COLORS.SECONDARY_MAIN_COLOR;

                    if (isMaasInstance)
                        return LIGHT_MAAS_THEME_COLORS.SECONDARY_MAIN_COLOR;

                    if (isCeatInstance || isCeatMaklerInstance)
                        return LIGHT_CEAT_THEME_COLORS.SECONDARY_MAIN_COLOR;
                },
                transition: '0.2s',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: isLightMode
                        ? theme.palette.primary.light
                        : theme.palette.secondary.contrastText,
                    opacity: 0.8,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            searchButton: {
                height: 40,
                padding: `0 ${theme.spacing(4)}px`,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                color: theme.palette.info.contrastText,
                transition: '0.2s',
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor:
                        theme.palette.type === THEME_MODES.DARK
                            ? theme.palette.info.main
                            : theme.palette.secondary.main,
                },
                '&.Mui-disabled': {
                    opacity: 0.6,
                    color: theme.palette.secondary.contrastText,
                },
            },
            divisionsContainer: {
                padding: `${theme.spacing(3)}px ${theme.spacing(1)}rem`,
                [theme.breakpoints.down('lg')]: {
                    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
                },
            },
            resetSettingsButton: {
                height: 50,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                fontSize: '1.8rem',
                backgroundColor: !isLightMode
                    ? 'rgba(0, 0, 0, 0)'
                    : theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                transition: '0.2s',
                border: isLightMode
                    ? 'none'
                    : `1px solid ${theme.palette.secondary.contrastText}`,
                '&:hover': {
                    opacity: 0.6,
                    backgroundColor: theme.palette.secondary.light,
                },
                [theme.breakpoints.down('md')]: {
                    height: 40,
                },
            },
            searchFieldContainer: {
                display: 'flex',
                flexWrap: 'nowrap',
                width: '35%',
            },
            applicationBtn: {
                display: 'flex',
                alignItems: 'center',
                padding: `0 ${theme.spacing(3)}px`,
                marginRight: theme.spacing(4),
                '&:hover': {
                    textDecoration: 'none',
                },
            },
        };
    },
    { index: 1 }
);
