import moment from 'moment';
import {
    DamageReportFormAONKompositDREISETypes,
    DamageReportFormAonKompositDreiseMutation,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-dreise-types';

export const damageReportFormDreiseMutateAdapter = (
    data: DamageReportFormAONKompositDREISETypes
): DamageReportFormAonKompositDreiseMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        damagedItemInfo: {
            itemInfo: data?.affectedItem,
            isOwnAllObjects: data?.ownAllOfTheAboveObjects,
            ownerInfo: data?.includeTheOwner,
        },
        luggageDamageInfo: {
            insuredCompanyFileNumber: data?.insuredCompanyFileNumber,
            creditInstitute: data?.creditInstitute,
            iban: data?.iban,
            bic: data?.bic,
            accounOwner: data?.accounOwner,
            insuredName: data?.nameOfTheInsured,
            insuredAddress: data?.addressOfTheInsured,
            insuredPersonPostCode: data?.postCodeAndPlaceResidenceInsuredPerson,
            damageLocation: data?.damageLocation,
            damageAssessmentDayAndTime: data?.damageAssessmentDayAndTime,
            kindOfTrip: data?.kindOfTrip,
            whereAndHowDamagedBaggageStored:
                data?.whereAndHowWasTheLostOrDamagedBaggageStored,
            damageCourseExactDescription: data?.exactDescriptionOfTheCourseOfTheDamage,
            damageWitnesses: data?.witnessesOfTheDamage,
            nameAndAddress: data?.nameAndAddress,
            telephoneOrFax: data?.telephoneOrFax,
            possiblyPoliceDiaryNumber: data?.possiblyPoliceDiaryNumber,
            damageReportedDateOrTime: data?.whenWasTheDamageReported,
            claimLiableInfo: data?.whoBecameLiableForTheDamageWhenAndHowHeld,
            compensationInfo: data?.whoHasAlreadyPaidWhatCompensationAndWhen,
            recoverInsuredPropertyMeasures: data?.whatMeasuresHaveBeenTakenToRegain,
            typeOrInsuranceCertificate: data?.typeOrInsuranceCertificate,
        },
    };
};
