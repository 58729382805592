import { USER_GROUPS } from 'src/shared/constants';

import {
    UserEditingFormData,
    UserEditFormRequestTypes,
    UserEditFormDataForRequest,
    IClientConnections,
    WithBranchFormCard,
} from './user-edit-form-types';
import { CheckboxItem } from 'src/theming';

import UserEditFormRequestBody from './user-edit-form-request-body';

const formatter = (
    data: UserEditingFormData,
    clientConnections?: Array<CheckboxItem & IClientConnections>,
    cardsWithBranches?: WithBranchFormCard[],
    initialStateOfCardsWithBranches?: IClientConnections[] | void
): UserEditFormRequestBody => {
    const formattedClientConnections =
        cardsWithBranches && initialStateOfCardsWithBranches
            ? initialStateOfCardsWithBranches
                  .filter((clientConnection) =>
                      cardsWithBranches.find(
                          (card) => card.clientConnectionValue === clientConnection.id
                      )
                  )
                  .map((clientConnection) => {
                      const ccFromCard = cardsWithBranches?.find(
                          (item) => item.clientConnectionValue === clientConnection.id
                      );

                      const allCustomers =
                          ccFromCard.clients.some(
                              (item) => item.checked && item.value === 'all'
                          ) ||
                          ccFromCard.clients
                              .filter((item) => item.value !== 'all')
                              .every((item) => item.checked);
                      return {
                          ...clientConnection,
                          allCustomers,
                          customers: clientConnection.customers.filter((client) => {
                              return Boolean(
                                  cardsWithBranches
                                      .find(
                                          (card) =>
                                              card.clientConnectionValue ===
                                              clientConnection.id
                                      )
                                      ?.clients.find((cardClient) => {
                                          const isAllClientsChecked =
                                              cardClient.value === 'all' &&
                                              cardClient.checked;
                                          return (
                                              (cardClient.value === client.id &&
                                                  cardClient.checked) ||
                                              isAllClientsChecked
                                          );
                                      })
                              );
                          }),
                          divisions: clientConnection.divisions.filter((division) => {
                              return Boolean(
                                  cardsWithBranches
                                      .find(
                                          (card) =>
                                              card.clientConnectionValue ===
                                              clientConnection.id
                                      )
                                      ?.divisions.find((divisionCard) => {
                                          const isAllDivisionsChecked =
                                              divisionCard.value === 'all' &&
                                              divisionCard.checked;
                                          return (
                                              (divisionCard.value === division.id &&
                                                  divisionCard.checked) ||
                                              isAllDivisionsChecked
                                          );
                                      })
                              );
                          }),
                      };
                  })
            : clientConnections
                  ?.filter((clientConnection) => clientConnection.checked)
                  .map((item) => {
                      const allCustomers = cardsWithBranches
                          ?.find((card) => {
                              return card.id === item.id;
                          })
                          ?.clients?.some((item) => item.value === 'all' && item.checked);
                      return { ...item, allCustomers };
                  });

    return {
        name: data.name,
        surname: data.surname,
        email: data.email,
        active: data.active,
        roles: data.roles.map((role) => role.name),
        phoneNumber: data.phoneNumber,
        customerAdvisorNumber: data.customerAdvisorNumber,
        addNewCustomersAutomatically: data.addNewCustomersAutomatically,
        customerConnections: formattedClientConnections,
        isCustomerAdvisorAdmin: data.isCustomerAdvisorAdmin,
    };
};

export const userEditFormPreparingDataForRequest = (
    formData: UserEditingFormData,
    clientConnections,
    requestType: UserEditFormRequestTypes,
    userId?: string,
    cardsWithBranches?: WithBranchFormCard[],
    initialStateOfCardsWithBranches?: IClientConnections[] | void
): UserEditFormDataForRequest | null => {
    switch (formData.userGroup) {
        case USER_GROUPS.INTERNAL: {
            return {
                url:
                    requestType === UserEditFormRequestTypes.CREATE
                        ? 'users/internal'
                        : `users/${userId}/internal`,
                data: formatter(formData),
            };
        }
        case USER_GROUPS.CUSTOMER: {
            return {
                url:
                    requestType === UserEditFormRequestTypes.CREATE
                        ? 'users/customer'
                        : `users/${userId}/customer`,
                data: formatter(
                    formData,
                    clientConnections,
                    cardsWithBranches,
                    initialStateOfCardsWithBranches
                ),
            };
        }
        default: {
            return null;
        }
    }
};
