import { Divisions } from 'src/shared/constants';

import { DamageReportFormAONKompositTranspTypes } from '../../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-transport-types';

export const damageReportFormAONKompositTranspDefaultState = (
    contractId: string
): DamageReportFormAONKompositTranspTypes => {
    return {
        contractId,
        id: '',
        division: Divisions.TRANSP,
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        policyOrCertificateNumber: '',
        insuredCompanyFileNumber: '',
        ibanCompensation: '',
        bic: '',
        bank: '',
        accountOwner: '',
        typeOfDamage: 'No choice',
        departureDate: '',
        deliveringCarrier: '',
        senderOrRecipient: '',
        insuredGoods: '',
        damagedGoods: '',
        totalLoss: false,
        consignmentComplete: false,
        outwardlyDamageShipment: false,
        containerNumber: '',
        leadSealOrSeal: '',
        availableUponArrival: false,
        damaged: false,
        damageAssessment: 'No choice',
        atThe: '',
        carrierForwardingAgentBeenGivenPureReceipt: false,
        averageCommissionerWasCalledIn: false,
        damageDescription: '',
        expectedAmountOfDamage: '',
        transportFrom: '',
        transportVia: '',
        transportAfter: '',
        storageIn: '',
        storageInOrAt: '',
        exhibition: '',
        documentsAttached: [],
        transportInformation: [],
        files: [],
        fileInfos: [],
    };
};
