import React, {
    BaseSyntheticEvent,
    ChangeEvent,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FileObject } from 'material-ui-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Paper } from '@material-ui/core';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { APP_ROUTES } from 'src/routing';
import { buildFormData } from 'src/shared/utils';
import { useGlobalFilter } from 'src/shared/contexts/contexts-global-filter/contexts-global-filter';
import { useQuery } from 'src/shared/hooks';

import { DamageReportFormKFZWView } from './damage-report-form-KFZW-view';
import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';

import { DamageReportFormFormData } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-form-data-type';

import { damageReportFormValidationSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-validation-schema';
import { damageReportFormDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-default-state';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

import { DamageTypes } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-damage-types-options';

import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';
import { damageReportFormKFZWGetReportRecordAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-get--kfzw-report-record-adapter';
import { damageReportFormKFZWMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-kfzw-mutate-adapter';
import { Divisions } from 'src/shared/constants';

interface DamageReportFormKFZWControlsProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
}

export const DamageReportFormKFZWControls = memo(
    ({ setDivision, hideButtons, data }: DamageReportFormKFZWControlsProps) => {
        const { id } = useParams<{ id?: string }>();
        const contractId = useQuery().get('contractId');

        const { changeDivision } = useGlobalFilter();

        const classes = useStyle();
        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const [isOtherInfoShown, setOtherInfoShown] = useState<boolean>(false);
        const [isSaveLoading, setSaveLoading] = useState<boolean>(false);
        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const router = useHistory();
        const httpClient = useHttpClient();
        const { enqueueSnackbar } = useSnackbar();

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormFormData>({
            mode: 'onChange',
            resolver: yupResolver(damageReportFormValidationSchema),
            defaultValues: damageReportFormDefaultState(contractId),
        });

        const { fields: damageTypeFields, append } = useFieldArray({
            control,
            name: 'typeOfDamage',
        });

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });
        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            kfzLicenseNumber,
            contractNumber,
            policyHolder,
            insuranceCompany,
            rootCause,
            accidentSceneStreet,
            accidentScenePostcode,
            accidentSceneCity,
            accidentSceneCountry,
            driverInformationFirstName,
            driverInformationSurname,
            driverInformationTenant,
            driverInformationStreet,
            driverInformationPostCode,
            driverInformationCity,
            driverInformationPhoneNumber,
            driverInformationEmail,
            driverInformationDrivingLicenseAvailable,
            driverInformationDrivingLicenseClass,
            driverInformationDateOfIssue,
            accidentDamageDetailsInformationChangeOfVehicle,
            accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle,
            accidentDamageDetailsInformationDamageNumberCustomer,
            accidentDamageDetailsInformationDetailedDamageDescription,
            isTeam,
            teamDamageStreet,
            teamDamagePostcode,
            teamDamageCity,
            teamDamagePhoneNumber,
            teamDamageEmail,
            teamDamageInsurancePolicyNumber,
            teamLicenseNumber,
            teamVehicleType,
            teamTowedVehicleKeeper,
            incidentRecordedByPolice,
            policeRecordDiagnosedAlcoholOrDrugUse,
            policeRecordReceivingPoliceStation,
            policeRecordPoliceFileNumber,
            policeRecordSomeoneWarnedByPolice,
            policeRecordWhoWarnedByPolice,
            areThereWitnesses,
            witnessesNameAndSurname,
            witnessesStreet,
            witnessesPostcode,
            witnessesCity,
            witnessesPhoneNumber,
            witnessesEmail,
            otherPartyInformationNameAndSurname,
            otherPartyInformationStreet,
            otherPartyInformationPostCode,
            otherPartyInformationCity,
            otherPartyInformationCityPhoneNumber,
            otherPartyInformationCityEmail,
            otherPartyInformationLicenseNumber,
            otherPartyInformationVehicleType,
            otherPartyInformationDamageAmount,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'kfzLicenseNumber',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'rootCause',
            'accidentSceneStreet',
            'accidentScenePostcode',
            'accidentSceneCity',
            'accidentSceneCountry',
            'driverInformationFirstName',
            'driverInformationSurname',
            'driverInformationTenant',
            'driverInformationStreet',
            'driverInformationPostCode',
            'driverInformationCity',
            'driverInformationPhoneNumber',
            'driverInformationEmail',
            'driverInformationDrivingLicenseAvailable',
            'driverInformationDrivingLicenseClass',
            'driverInformationDateOfIssue',
            'accidentDamageDetailsInformationChangeOfVehicle',
            'accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle',
            'accidentDamageDetailsInformationDamageNumberCustomer',
            'accidentDamageDetailsInformationDetailedDamageDescription',
            'isTeam',
            'teamDamageStreet',
            'teamDamagePostcode',
            'teamDamageCity',
            'teamDamagePhoneNumber',
            'teamDamageEmail',
            'teamDamageInsurancePolicyNumber',
            'teamLicenseNumber',
            'teamVehicleType',
            'teamTowedVehicleKeeper',
            'incidentRecordedByPolice',
            'policeRecordDiagnosedAlcoholOrDrugUse',
            'policeRecordReceivingPoliceStation',
            'policeRecordPoliceFileNumber',
            'policeRecordSomeoneWarnedByPolice',
            'policeRecordWhoWarnedByPolice',
            'areThereWitnesses',
            'witnessesNameAndSurname',
            'witnessesStreet',
            'witnessesPostcode',
            'witnessesCity',
            'witnessesPhoneNumber',
            'witnessesEmail',
            'otherPartyInformationNameAndSurname',
            'otherPartyInformationStreet',
            'otherPartyInformationPostCode',
            'otherPartyInformationCity',
            'otherPartyInformationCityPhoneNumber',
            'otherPartyInformationCityEmail',
            'otherPartyInformationLicenseNumber',
            'otherPartyInformationVehicleType',
            'otherPartyInformationDamageAmount',
        ]);

        useEffect(() => {
            if (data) {
                reset(damageReportFormKFZWGetReportRecordAdapter(data));
                trigger();
            }
        }, [data]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('kfzLicenseNumber', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const handleCancel = (): void => {
            history.back();
        };

        const handleChangeDamageType = useCallback(
            (e: ChangeEvent<HTMLInputElement>): void => {
                if (e.target.checked && e.target.name === DamageTypes.LIABILITY) {
                    setOtherInfoShown(true);
                }
                if (!e.target.checked && e.target.name === DamageTypes.LIABILITY) {
                    setOtherInfoShown(false);
                }
                if (e.target.checked) {
                    if (e.target.name === DamageTypes.FULLY_COMPREHENSIVE) {
                        const newChosenDamageTypes = damageTypeFields.filter(
                            (item) => item.name !== DamageTypes.PARTIAL_COVERAGE
                        );
                        setValue('typeOfDamage', [
                            ...newChosenDamageTypes,
                            { name: e.target.name },
                        ]);
                    } else if (e.target.name === DamageTypes.PARTIAL_COVERAGE) {
                        const newChosenDamageTypes = damageTypeFields.filter(
                            (item) => item.name !== DamageTypes.FULLY_COMPREHENSIVE
                        );
                        setValue('typeOfDamage', [
                            ...newChosenDamageTypes,
                            { name: e.target.name },
                        ]);
                    } else {
                        append({ name: e.target.name });
                    }
                } else {
                    const newChosenDamageTypes = damageTypeFields.filter(
                        (item) => item.name !== e.target.name
                    );
                    setValue('typeOfDamage', newChosenDamageTypes);
                }
                trigger();
            },
            [setOtherInfoShown, append, setValue, damageTypeFields]
        );

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleChangeRecordedByThePolice = useCallback((): void => {
            setValue('incidentRecordedByPolice', !incidentRecordedByPolice);
        }, [setValue, incidentRecordedByPolice]);

        const handleChangeWarnedByThePolice = useCallback((): void => {
            setValue(
                'policeRecordSomeoneWarnedByPolice',
                !policeRecordSomeoneWarnedByPolice
            );
        }, [setValue, policeRecordSomeoneWarnedByPolice]);

        const handleChangeAlcoholDrugSwitcher = useCallback((): void => {
            setValue(
                'policeRecordDiagnosedAlcoholOrDrugUse',
                !policeRecordDiagnosedAlcoholOrDrugUse
            );
        }, [setValue, policeRecordDiagnosedAlcoholOrDrugUse]);

        const handleChangeWitnesses = useCallback((): void => {
            setValue('areThereWitnesses', !areThereWitnesses);
        }, [setValue, areThereWitnesses]);

        const handleChangeHaveLicenseNumber = useCallback((): void => {
            setValue(
                'driverInformationDrivingLicenseAvailable',
                !driverInformationDrivingLicenseAvailable
            );
        }, [setValue, driverInformationDrivingLicenseAvailable]);

        const handleChangeTeam = (): void => {
            setValue('isTeam', !isTeam);
        };

        const saveReport = (): Promise<{ id: string }> => {
            setSaveLoading(true);
            const formattedData = damageReportFormKFZWMutateAdapter(getValues());
            const formData = new FormData();
            buildFormData(formData, formattedData, null);
            if (!id) {
                return httpClient.post(`create-damage-report/kfzw`, formData);
            }
            return httpClient.put(`damage-reports/${id}/kfzw`, formData);
        };

        const handleSaveReport = (): void => {
            saveReport()
                .then(() => {
                    if (!id) {
                        changeDivision(Divisions.KFZW);
                        router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                    }
                    enqueueSnackbar(t(`damages-report:savedSuccessfully`), {
                        variant: 'success',
                    });
                })
                .catch(() => {
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        };

        const makeRequestForSavingDataToAms = async (): Promise<void | string> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport();
                return httpClient
                    .post<Promise<void | string>>(
                        `damage-reports/${newId || id}/send-to-ams`
                    )
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            makeRequestForSavingDataToAms()
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(Divisions.KFZW);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId: string) => {
                    if (newId) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const onSubmit = (): void => {
            saveReportToAms();
        };

        useEffect(() => {
            setDivision(division);
        }, [division]);

        useEffect(() => {
            trigger();
        }, []);

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                {
                    <Paper
                        className={classes.mainFormContainer}
                        component='form'
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <DamageReportFormKFZWView
                            hideButtons={hideButtons}
                            footerTitle={t('damages-report:footerTitle')}
                            sectionContainer={classes.sectionContainer}
                            handleChangeValue={handleChangeValue}
                            accidentSceneCity={accidentSceneCity}
                            accidentSceneCountry={accidentSceneCountry}
                            button={classes.button}
                            dayOfDamage={dayOfDamage}
                            division={division}
                            divisionFromRecord={data?.division}
                            handleReadOnlyFields={handleReadOnlyFields}
                            accidentScenePostcode={accidentScenePostcode}
                            buttonsContainer={classes.buttonsContainer}
                            cancelText={t('cancel')}
                            accidentSceneStreet={accidentSceneStreet}
                            areThereWitnesses={areThereWitnesses}
                            damageTypeFields={damageTypeFields}
                            contractNumber={contractNumber}
                            accidentDamageDetailsInformationChangeOfVehicle={
                                accidentDamageDetailsInformationChangeOfVehicle
                            }
                            driverInformationCity={driverInformationCity}
                            accidentDamageDetailsInformationDamageNumberCustomer={
                                accidentDamageDetailsInformationDamageNumberCustomer
                            }
                            driverInformationDateOfIssue={driverInformationDateOfIssue}
                            accidentDamageDetailsInformationDetailedDamageDescription={
                                accidentDamageDetailsInformationDetailedDamageDescription
                            }
                            driverInformationDrivingLicenseAvailable={
                                driverInformationDrivingLicenseAvailable
                            }
                            driverInformationDrivingLicenseClass={
                                driverInformationDrivingLicenseClass
                            }
                            driverInformationEmail={driverInformationEmail}
                            accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle={
                                accidentDamageDetailsInformationEstimatedAmountOfDamageToYourOwnVehicle
                            }
                            driverInformationFirstName={driverInformationFirstName}
                            driverInformationPhoneNumber={driverInformationPhoneNumber}
                            driverInformationPostCode={driverInformationPostCode}
                            driverInformationStreet={driverInformationStreet}
                            driverInformationSurname={driverInformationSurname}
                            driverInformationTenant={driverInformationTenant}
                            files={files}
                            formBlockContainer={classes.formBlockContainer}
                            formState={formState}
                            handleCancel={handleCancel}
                            handleAddFile={handleAddFile}
                            id={id}
                            handleChangeAlcoholDrugSwitcher={
                                handleChangeAlcoholDrugSwitcher
                            }
                            handleChangeDamageType={handleChangeDamageType}
                            handleChangeDate={handleChangeDate}
                            handleChangeHaveLicenseNumber={handleChangeHaveLicenseNumber}
                            handleChangeRecordedByThePolice={
                                handleChangeRecordedByThePolice
                            }
                            handleChangeTeam={handleChangeTeam}
                            handleChangeWarnedByThePolice={handleChangeWarnedByThePolice}
                            handleChangeWitnesses={handleChangeWitnesses}
                            handleSaveReport={handleSaveReport}
                            incidentRecordedByPolice={incidentRecordedByPolice}
                            handleDeleteFile={handleDeleteFile}
                            insuranceCompany={insuranceCompany}
                            isOtherInfoShown={isOtherInfoShown}
                            isSaveLoading={isSaveLoading}
                            isTeam={isTeam}
                            isSendingToAmsLoading={isSendingToAmsLoading}
                            kfzLicenseNumber={kfzLicenseNumber}
                            otherPartyInformationCity={otherPartyInformationCity}
                            otherPartyInformationCityEmail={
                                otherPartyInformationCityEmail
                            }
                            otherPartyInformationCityPhoneNumber={
                                otherPartyInformationCityPhoneNumber
                            }
                            otherPartyInformationDamageAmount={
                                otherPartyInformationDamageAmount
                            }
                            otherPartyInformationLicenseNumber={
                                otherPartyInformationLicenseNumber
                            }
                            otherPartyInformationNameAndSurname={
                                otherPartyInformationNameAndSurname
                            }
                            otherPartyInformationPostCode={otherPartyInformationPostCode}
                            otherPartyInformationStreet={otherPartyInformationStreet}
                            policeRecordDiagnosedAlcoholOrDrugUse={
                                policeRecordDiagnosedAlcoholOrDrugUse
                            }
                            otherPartyInformationVehicleType={
                                otherPartyInformationVehicleType
                            }
                            policeRecordPoliceFileNumber={policeRecordPoliceFileNumber}
                            policeRecordSomeoneWarnedByPolice={
                                policeRecordSomeoneWarnedByPolice
                            }
                            policeRecordReceivingPoliceStation={
                                policeRecordReceivingPoliceStation
                            }
                            policeRecordWhoWarnedByPolice={policeRecordWhoWarnedByPolice}
                            policyHolder={policyHolder}
                            rootCause={rootCause}
                            saveButton={classes.saveButton}
                            saveText={t('save')}
                            sendButton={classes.sendButton}
                            sendText={t('send')}
                            teamDamageCity={teamDamageCity}
                            teamDamageEmail={teamDamageEmail}
                            teamDamageInsurancePolicyNumber={
                                teamDamageInsurancePolicyNumber
                            }
                            teamDamagePhoneNumber={teamDamagePhoneNumber}
                            teamDamagePostcode={teamDamagePostcode}
                            teamDamageStreet={teamDamageStreet}
                            teamLicenseNumber={teamLicenseNumber}
                            timeOfDamage={timeOfDamage}
                            teamTowedVehicleKeeper={teamTowedVehicleKeeper}
                            teamVehicleType={teamVehicleType}
                            witnessesCity={witnessesCity}
                            witnessesEmail={witnessesEmail}
                            witnessesPhoneNumber={witnessesPhoneNumber}
                            witnessesNameAndSurname={witnessesNameAndSurname}
                            witnessesPostcode={witnessesPostcode}
                            witnessesStreet={witnessesStreet}
                            fileInfos={fileInfos}
                            onDeleteFile={onDeleteFile}
                            setDeleteConfirmOpen={setDeleteConfirmOpen}
                            deleteText={t('damages-report:deleteReport')}
                            deleteButton={classes.deleteButton}
                        />
                    </Paper>
                }
            </>
        );
    }
);
