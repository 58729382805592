export enum Currencies {
    USD = 'USD',
    AUD = 'AUD',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    CZK = 'CZK',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    HKD = 'HKD',
    HUF = 'HUF',
    JPY = 'JPY',
    MYR = 'MYR',
    NOK = 'NOK',
    PLN = 'PLN',
    RON = 'RON',
    SEK = 'SEK',
    SGD = 'SGD',
    THB = 'THB',
    ZAR = 'ZAR',
}
