import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum WHERE_RESERVATION_MADE_OPTIONS {
    WAYBILL = 'Waybill',
    DELIVERY_NOTE = 'Delivery note',
    SPED_TRANSFER_SLIP = 'Sped transfer slip',
    ROLL_CARD = 'Roll card',
}

export const damageReportFormGenerateWhereReservationMadeOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:whereWasReservationMadeOptions.waybill'),
            value: WHERE_RESERVATION_MADE_OPTIONS.WAYBILL,
        },
        {
            title: t('damages-report:whereWasReservationMadeOptions.deliveryNote'),
            value: WHERE_RESERVATION_MADE_OPTIONS.DELIVERY_NOTE,
        },
        {
            title: t('damages-report:whereWasReservationMadeOptions.spedTransferSlip'),
            value: WHERE_RESERVATION_MADE_OPTIONS.SPED_TRANSFER_SLIP,
        },
        {
            title: t('damages-report:whereWasReservationMadeOptions.rollCard'),
            value: WHERE_RESERVATION_MADE_OPTIONS.ROLL_CARD,
        },
    ];
};
