import React, { BaseSyntheticEvent } from 'react';

import { Grid } from '@material-ui/core';

import { RelinkSelectInput } from './components';

import { RelinkMenu } from './relink-generate-menu';

import { FieldItem } from './use-dynamic-fields';
import { useOptionsForDynamicFields } from './use-options-for-dynamic-fields';

import { useRelinkStyles } from './relink-styles';

interface Props {
    type: RelinkMenu;
    fields: FieldItem[];
    customerId: string;
    onChangeDynamicField: (e: BaseSyntheticEvent) => void;
}

export const RelinkDynamicFields = ({
    fields,
    type,
    customerId,
    onChangeDynamicField,
}: Props): JSX.Element => {
    const classes = useRelinkStyles();

    const items = useOptionsForDynamicFields(type, customerId);

    return (
        <>
            {fields
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((item, index) => {
                    return (
                        <Grid
                            item
                            md={6}
                            key={`${item.label}-${item.value}`}
                            className={classes.dynamicFieldItem}
                            style={{
                                paddingRight:
                                    fields.length === index + 1 &&
                                    (index + 1) % 2 !== 0 &&
                                    '1rem',
                            }}
                        >
                            <RelinkSelectInput
                                isEdit
                                value={item.value}
                                name={item.name}
                                onChange={onChangeDynamicField}
                                items={items}
                                label={item.label}
                            />
                        </Grid>
                    );
                })}
        </>
    );
};
