import { useContext } from 'react';
import NivoThemeContext, { NivoTheme } from './nivo-theme-context';

export default function useNivoTheme(): NivoTheme {
    const nivoTheme = useContext(NivoThemeContext);

    if (!nivoTheme) {
        throw new Error('Wrap your components with NivoThemeProvider to use this hook');
    }

    return nivoTheme;
}
