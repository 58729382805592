import React, { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import {
    useStyle,
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';
import { ThemeSwitcher, ThemePhoneNumberInput } from 'src/theming';

interface DamageReportFormTeamBlockProps {
    readonly: boolean;
    handleChangeTeam: () => void;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    licenseNumber: string;
    vehicleType: string;
    towedVehicleKeeper: string;
    road: string;
    postCode: string;
    place: string;
    phoneNumber: string;
    emailAddress: string;
    insurancePolicyNumber: string;
    isCombination: boolean;
    errors: {
        teamDamageEmail: boolean;
    };
}

export const DamageReportFormTeamBlock = (
    props: DamageReportFormTeamBlockProps
): JSX.Element => {
    const { t } = useTranslation(['common', 'damage-report']);

    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const {
        handleChangeTeam,
        handleChangeValue,
        licenseNumber,
        vehicleType,
        road,
        postCode,
        place,
        phoneNumber,
        emailAddress,
        insurancePolicyNumber,
        isCombination,
        towedVehicleKeeper,
        errors,
        readonly,
    } = props;

    return (
        <Grid container spacing={2}>
            <Typography variant='h6' className={classes.formBlockTitle}>
                {t('damages-report:team.title')}
            </Typography>
            <Grid item className={clsx(classes.fullWidth)}>
                <div style={{ width: 'fit-content' }}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <ThemeSwitcher
                                    disabled={readonly}
                                    checked={!isCombination}
                                    onChange={handleChangeTeam}
                                    leftLabel={t('yes')}
                                    rightLabel={t('no')}
                                />
                            }
                            label={t('damages-report:team.isCombination')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </div>
            </Grid>
            {isCombination && (
                <>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={licenseNumber}
                                        onChange={handleChangeValue}
                                        name='teamLicenseNumber'
                                    />
                                }
                                label={t('damages-report:team.licenseNumber')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={vehicleType}
                                        onChange={handleChangeValue}
                                        name='teamVehicleType'
                                    />
                                }
                                label={t('damages-report:team.vehicleType')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={towedVehicleKeeper}
                                        onChange={handleChangeValue}
                                        name='teamTowedVehicleKeeper'
                                    />
                                }
                                label={t('damages-report:team.keeper')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={road}
                                        onChange={handleChangeValue}
                                        name='teamDamageStreet'
                                    />
                                }
                                label={t('damages-report:team.road')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={postCode}
                                        onChange={handleChangeValue}
                                        name='teamDamagePostcode'
                                    />
                                }
                                label={t('damages-report:team.postCode')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={place}
                                        onChange={handleChangeValue}
                                        name='teamDamageCity'
                                    />
                                }
                                label={t('damages-report:team.place')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <ThemePhoneNumberInput
                                        disabled={readonly}
                                        value={phoneNumber}
                                        onChange={handleChangeValue}
                                        name='teamDamagePhoneNumber'
                                    />
                                }
                                label={t('damages-report:team.phoneNumber')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            error={Boolean(errors.teamDamageEmail)}
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={emailAddress}
                                        onChange={handleChangeValue}
                                        name='teamDamageEmail'
                                    />
                                }
                                label={t('damages-report:team.emailAddress')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className={clsx(classes.thirdPart)}>
                        <FormControl
                            fullWidth
                            classes={formControlClasses}
                            variant='outlined'
                        >
                            <FormControlLabel
                                classes={formControlLabelClasses}
                                control={
                                    <OutlinedInput
                                        disabled={readonly}
                                        classes={inputClasses}
                                        value={insurancePolicyNumber}
                                        onChange={handleChangeValue}
                                        name='teamDamageInsurancePolicyNumber'
                                    />
                                }
                                label={t('damages-report:team.insurancePolicyNumber')}
                                labelPlacement='top'
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
