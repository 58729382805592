import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { APP_ROUTES } from 'src/routing';
import { useGlobalFilter } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { buildFormData } from 'src/shared/utils';

interface UseDamageReportFormSaveReportReturn<T> {
    isLoading: boolean;
    saveReport: (data: T) => Promise<{ id: string }>;
    handleSaveReport: () => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

interface UseDamageReportFormSaveReportProps<T, P> {
    id: string;
    division: string;
    pathname: string;
    data: T;
    adapter: (data: T) => P;
}

export function useDamageReportFormSaveReport<T, P>({
    id,
    pathname,
    data,
    adapter,
    division,
}: UseDamageReportFormSaveReportProps<T, P>): UseDamageReportFormSaveReportReturn<T> {
    const httpClient = useHttpClient();
    const { t } = useTranslation(['damages-report', 'errors']);
    const { changeDivision } = useGlobalFilter();
    const router = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState<boolean>(false);

    const saveReport = (data: T): Promise<{ id: string }> => {
        setLoading(true);

        const formattedData = adapter(data);
        const formData = new FormData();
        buildFormData(formData, formattedData, null);
        if (!id) {
            return httpClient.post(`create-damage-report/${pathname}`, formData);
        }
        return httpClient.put(`damage-reports/${id}/${pathname}`, formData);
    };

    const handleSaveReport = (): void => {
        saveReport(data)
            .then(() => {
                if (!id) {
                    changeDivision(division);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                }
                enqueueSnackbar(t(`damages-report:savedSuccessfully`), {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar(t(`errors:unknownError`), {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        isLoading,
        setLoading,
        saveReport,
        handleSaveReport,
    };
}
