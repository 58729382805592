import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useStyle } from './theme-loader-styles';

export const ThemeLoader = (): JSX.Element => {
    const classes = useStyle();
    return (
        <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} />
        </div>
    );
};

ThemeLoader.propTypes = {};

ThemeLoader.defaultProps = {};

export default ThemeLoader;
