import React from 'react';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import {
    darkPaletteForMaas,
    lightPaletteForMaas,
} from 'src/theming/themes/maas/maas-theme-palettes';
import { THEME_MODES } from 'src/theming/themes/theme-modes';

interface MaasThemeProviderProps {
    children: JSX.Element;
}

export const MaasThemeProvider = (props: MaasThemeProviderProps): JSX.Element => {
    return (
        <ThemeProvider
            theme={(outerTheme): ThemeOptions => {
                const maasTheme: ThemeOptions = { ...outerTheme };
                if (maasTheme.palette.type === THEME_MODES.DARK) {
                    maasTheme.palette = {
                        ...maasTheme.palette,
                        ...darkPaletteForMaas,
                    };
                } else {
                    maasTheme.palette = {
                        ...maasTheme.palette,
                        ...lightPaletteForMaas,
                    };
                }
                return maasTheme;
            }}
        >
            {props.children}
        </ThemeProvider>
    );
};