import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import {
    Grid,
    Typography,
    Button,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import {
    ThemeCircularProgress,
    ThemeSwitcher,
    ThemeUploadImage,
    ThemeLoader,
    ThemeOutlineInput,
    ThemeSelectInput,
} from 'src/theming';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { buildFormData } from 'src/shared/utils';
import { AbilityActions, AbilitySubjects, Can } from 'src/roleAccesses';

import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from './master-data-style';

import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter } from 'src/shared/contexts';

import DocumentTranscodeActions from './master-data-document-transcode-actions';
import { useMonthItems } from 'src/shared/constants/month-select-items';

interface MasterDataDTO {
    customerConnection: string;
    status: boolean;
    accountOwnerName: string;
    accountOwnerSurname: string;
    hasAttachedDocuments: boolean;
    hasOnlyDummyCustomer: boolean;
    fileInfo: {
        id: string;
        fileName: string;
    };
    financialYearStartMonth: number;
}

type MasterDataEditFormData = {
    status: boolean;
    logoFile: File[];
    logoPreviewUrl: string;
    dashboard: string;
    accountOwner: string;
    hasAttachedDocuments: boolean;
    hasOnlyDummyCustomer: boolean;
    financialYearStartMonth: number;
};

interface MasterDataEditFormProps {
    activeCC: string;
    setMenuListDisabled: (value: boolean) => void;
    title: string;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    refetchClientConnections: () => void;
}

export const MasterDataEditForm = (props: MasterDataEditFormProps): JSX.Element => {
    const {
        setMenuListDisabled,
        activeCC,
        page,
        rowsPerPage,
        searchValue,
        refetchClientConnections,
    } = props;

    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const classes = useStyle();
    const { t } = useTranslation(['common', 'user-management', 'errors']);
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const monthItems = useMonthItems();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [isUpdatingLoading, setUpdatingLoading] = useState<boolean>(false);
    const [editMode, setEditMode] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);

    const { getUserData } = useAuth();
    const { rawCustomerConnections } = useGlobalFilter();

    const { watch, setValue, handleSubmit, reset } = useForm<MasterDataEditFormData>({
        mode: 'onChange',
        defaultValues: {
            status: false,
            logoFile: [],
            dashboard: 'internal',
            logoPreviewUrl: '',
            accountOwner: '',
            financialYearStartMonth: 1,
        },
    });

    const makeRequestToReceiveFormData = (): Promise<MasterDataDTO> => {
        setLoading(true);
        props.setMenuListDisabled(true);
        return httpClient.get<MasterDataDTO>('master-data', {
            axiosConfig: {
                params: {
                    customerConnection: activeCC,
                },
            },
        });
    };

    const makeRequestForImage = (id: string): Promise<Blob> => {
        return httpClient.get(`master-data/customerConnectionAttachment`, {
            axiosConfig: {
                headers: {
                    'Content-Type': 'image/jpeg',
                },
                responseType: 'blob',
                params: {
                    customerConnection: activeCC,
                    attachmentId: id,
                },
            },
        });
    };

    const makeRequestForImageSafe = async (id: string): Promise<Blob> => {
        try {
            return await makeRequestForImage(id);
        } catch {
            return null;
        }
    };

    const getRelevantData = async (): Promise<void> => {
        try {
            const data = await makeRequestToReceiveFormData();
            if (data.fileInfo) {
                setFileInfo(data.fileInfo);
            }
            let imageResponse;
            if (data?.fileInfo?.id) {
                imageResponse = await makeRequestForImageSafe(data?.fileInfo?.id);
            }
            reset({
                status: Boolean(data?.status),
                accountOwner: `${data?.accountOwnerName || ''} ${
                    data?.accountOwnerSurname || ''
                }`,
                logoPreviewUrl: imageResponse ? URL.createObjectURL(imageResponse) : '',
                hasAttachedDocuments: data?.hasAttachedDocuments,
                hasOnlyDummyCustomer: data?.hasOnlyDummyCustomer,
                financialYearStartMonth: data?.financialYearStartMonth ?? 1,
            });
        } catch {
            setFileInfo(null);
            reset({
                status: false,
                logoFile: null,
                dashboard: 'internal',
                logoPreviewUrl: '',
                accountOwner: '',
                financialYearStartMonth: 1,
            });
        } finally {
            setLoading(false);
            props.setMenuListDisabled(false);
        }
    };

    useEffect(() => {
        if (activeCC && !isUpdatingLoading) {
            getRelevantData();
        }
    }, [activeCC, isUpdatingLoading]);

    const [
        status,
        logoPreviewUrl,
        accountOwner,
        hasAttachedDocuments,
        hasOnlyDummyCustomer,
        financialYearStartMonth,
    ] = watch([
        'status',
        'logoPreviewUrl',
        'accountOwner',
        'hasAttachedDocuments',
        'hasOnlyDummyCustomer',
        'financialYearStartMonth',
    ]);

    const openEditMode = (): void => {
        setEditMode(true);
        setMenuListDisabled(true);
    };

    const closeEditMode = (): void => {
        setEditMode(false);
        setMenuListDisabled(false);
        getRelevantData();
    };

    const handleChangeActive = (): void => {
        setValue('status', !status);
    };

    const handleChangeFinancialYearStartMonth = (value: string): void => {
        const parsedMonth = Number.parseInt(value);
        setValue('financialYearStartMonth', parsedMonth);
    };

    const handleChangeLogo = (name, value: File): void => {
        setValue(name, value ? [value] : []);
        setValue('logoPreviewUrl', null);
        setFileInfo(null);
    };

    const makeRequestToUpdateCCFormData = (
        data: MasterDataEditFormData
    ): Promise<void> => {
        setUpdatingLoading(true);
        const requestBody: {
            CustomerConnection: string;
            Status: boolean;
            NewFile: File;
            FileInfo: {
                fileName: string;
                id: string;
            }[];
            FinancialYearStartMonth: number;
        } = {
            CustomerConnection: activeCC,
            Status: data.status,
            NewFile: data.logoFile?.length > 0 ? data.logoFile[0] : null,
            FileInfo: fileInfo ? [fileInfo] : null,
            FinancialYearStartMonth: financialYearStartMonth ?? 1,
        };
        const formData = new FormData();
        buildFormData(formData, requestBody, null);
        if (data.logoFile?.length === 0 && !data.logoPreviewUrl) {
            formData.append('NewFile', null);
        }
        return httpClient.put('master-data', formData);
    };

    const onSubmit = async (data: MasterDataEditFormData): Promise<void> => {
        try {
            await makeRequestToUpdateCCFormData(data);
            setEditMode(false);
            if (
                rawCustomerConnections.some((cc) => cc.checked && cc.value === activeCC)
            ) {
                getUserData();
            }
            enqueueSnackbar(t('user-management:masterData.form.updatedSuccessfully'), {
                variant: 'success',
            });
        } catch {
            enqueueSnackbar(t('errors:unknownError'), { variant: 'error' });
        } finally {
            setUpdatingLoading(false);
        }
    };

    return (
        <>
            {!isLoading && (
                <Grid container component='form' onSubmit={handleSubmit(onSubmit)}>
                    <Grid container item wrap='nowrap' className={classes.formHeader}>
                        <Typography variant='h5' className={classes.headerTitle}>
                            {props.title}
                        </Typography>

                        <Grid
                            item
                            container
                            wrap='nowrap'
                            alignItems='center'
                            justify='flex-end'
                            className={classes.formControlButtons}
                        >
                            {editMode && (
                                <Can
                                    passThrough
                                    I={AbilityActions.transcode}
                                    a={AbilitySubjects.documents}
                                >
                                    {(allowed: boolean) =>
                                        allowed && (
                                            <DocumentTranscodeActions
                                                refetchClientConnections={
                                                    refetchClientConnections
                                                }
                                                isDocuments={hasAttachedDocuments}
                                                isDummyCustomers={hasOnlyDummyCustomer}
                                                activeCC={activeCC}
                                                page={page}
                                                rowsPerPage={rowsPerPage}
                                                searchValue={searchValue}
                                            />
                                        )
                                    }
                                </Can>
                            )}
                            <div style={{ flexGrow: 1 }} />
                            {!editMode && (
                                <Button
                                    onClick={openEditMode}
                                    className={classes.editButton}
                                    startIcon={<EditIcon />}
                                >
                                    {t('edit')}
                                </Button>
                            )}
                            {editMode && (
                                <>
                                    <Button
                                        disableElevation
                                        disabled={isUpdatingLoading}
                                        startIcon={
                                            <ThemeCircularProgress
                                                isLoading={isUpdatingLoading}
                                            />
                                        }
                                        type='submit'
                                        variant='contained'
                                        className={classes.containedButton}
                                    >
                                        {t('save')}
                                    </Button>
                                    <Button
                                        disableElevation
                                        variant='outlined'
                                        className={classes.outlinedButton}
                                        onClick={closeEditMode}
                                    >
                                        {t('cancel')}
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.fieldsContainer}>
                        <Can
                            passThrough
                            I={AbilityActions.see}
                            a={AbilitySubjects.umMasterDataActiveField}
                        >
                            {(allowed: boolean) =>
                                allowed && (
                                    <Grid container item md={12}>
                                        <Grid item sm={5}>
                                            <FormControl
                                                fullWidth
                                                classes={formControlClasses}
                                                variant='outlined'
                                            >
                                                <FormControlLabel
                                                    label={t(
                                                        'user-management:masterData.form.status'
                                                    )}
                                                    labelPlacement='top'
                                                    classes={formControlLabelClasses}
                                                    control={
                                                        <div
                                                            className={
                                                                classes.switchContainer
                                                            }
                                                        >
                                                            <ThemeSwitcher
                                                                checked={!status}
                                                                disabled={!editMode}
                                                                onChange={
                                                                    handleChangeActive
                                                                }
                                                                leftLabel={t(
                                                                    'user-management:masterData.form.active'
                                                                )}
                                                                rightLabel={t(
                                                                    'user-management:masterData.form.inactive'
                                                                )}
                                                            />
                                                        </div>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Can>

                        <Grid item sm={12}>
                            <ThemeOutlineInput
                                disabled
                                name={accountOwner}
                                value={accountOwner}
                                label={t('user-management:masterData.form.accountOwner')}
                                formControlLabelClasses={formControlLabelClasses}
                                formControlClasses={formControlClasses}
                                inputClasses={inputClasses}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <FormControl
                                fullWidth
                                classes={formControlClasses}
                                variant='outlined'
                            >
                                <FormControlLabel
                                    label={t(
                                        'user-management:masterData.form.financialYearStartMonth'
                                    )}
                                    labelPlacement='top'
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeSelectInput
                                            editMode={editMode}
                                            name='financialYearStartMonth'
                                            value={financialYearStartMonth?.toString()}
                                            items={monthItems}
                                            onChange={(e) =>
                                                handleChangeFinancialYearStartMonth(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} style={{ minHeight: 51, paddingBottom: 20 }}>
                            <FormControl fullWidth variant='outlined'>
                                <FormControlLabel
                                    label={t('user-management:masterData.form.logo')}
                                    labelPlacement='top'
                                    classes={formControlLabelClasses}
                                    control={
                                        <ThemeUploadImage
                                            editMode={editMode}
                                            name='logoFile'
                                            onChange={handleChangeLogo}
                                            imageURL={logoPreviewUrl}
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {isLoading && (
                <div style={{ width: '100%', height: 100 }}>
                    <ThemeLoader />
                </div>
            )}
        </>
    );
};
