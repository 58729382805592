import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import { FileObject } from 'material-ui-dropzone';

import { ThemeDropZone } from 'src/theming';

import { useDocumentFormats } from 'src/shared/hooks';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormAttachmentsFieldProps {
    files: FileObject[];
    handleAddFile: (newFiles: FileObject[]) => void;
    handleDeleteFile: (index: number) => void;
    disabled?: boolean;
}

export const DamageReportFormAttachmentsField = (
    props: DamageReportFormAttachmentsFieldProps
): JSX.Element => {
    const { t } = useTranslation(['common', 'damage-report']);

    const classes = useStyle();
    const formControlLabelClasses = useFormControlLabelStyles();
    const formControlClasses = useFormControlStyles();

    const documentFormats = useDocumentFormats();

    const { files, handleAddFile, handleDeleteFile, disabled } = props;

    if (disabled) {
        return null;
    }

    return (
        <Grid item className={clsx(classes.fullWidth)}>
            <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeDropZone
                            onAdd={handleAddFile}
                            onRemove={handleDeleteFile}
                            fileObjects={files}
                            formats={documentFormats}
                        />
                    }
                    label={t('damages-report:attachments')}
                    labelPlacement='top'
                />
            </FormControl>
        </Grid>
    );
};
