import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import {
    Typography,
    Grid,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    OutlinedInput,
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {
    ThemeModalWindow,
    ThemeCircularProgress,
    ThemeDatePicker,
    ThemeSelectInput,
    SelectItem,
} from 'src/theming';

import {
    useStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from './user-management-create-external-link-form-styles';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { TFunction } from 'i18next';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';

const generateStatusOptions = (t: TFunction): SelectItem[] => {
    return [
        {
            title: t('user-management:masterData.createLinkForm.active'),
            value: 'Active',
        },
        {
            title: t('user-management:masterData.createLinkForm.inactive'),
            value: 'Inactive',
        },
    ];
};

interface UserManagementCreateExternalDamageLinkFormProps {
    isOpen: boolean;
    handleClose: () => void;
    refetch: () => void;
    date: ParsableDate;
    password: string;
    status: string;
    clientConnection: string;
    externalLinkId: string;
}

export interface UserManagementCreateExternalDamageLinkFormData {
    date: ParsableDate;
    password: string;
    status: string;
    isNewURL?: boolean;
    id?: string;
    url?: string;
}

export const UserManagementCreateExternalDamageLinkForm = (
    props: UserManagementCreateExternalDamageLinkFormProps
): JSX.Element => {
    const {
        externalLinkId,
        isOpen,
        handleClose,
        clientConnection,
        refetch,
        date: defaultDate,
        password: defaultPassword,
        status: defaultStatus,
    } = props;

    const classes = useStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation(['common', 'user-management', 'errors']);

    const [isLoading, setLoading] = useState(false);

    const { watch, setValue, reset } =
        useForm<UserManagementCreateExternalDamageLinkFormData>({
            mode: 'onChange',
            defaultValues: {
                date: defaultDate,
                isNewURL: !externalLinkId,
                password: defaultPassword,
                status: defaultStatus || 'Active',
            },
        });

    useEffect(() => {
        reset({
            date: defaultDate || null,
            status: defaultStatus || 'Active',
            password: defaultPassword || null,
            isNewURL: !externalLinkId,
        });
    }, [defaultDate, defaultPassword, defaultStatus, clientConnection]);

    const handleSave = (): void => {
        makeRequestToUpdateExternalLink()
            .then(() => {
                handleClose();
                refetch();
                enqueueSnackbar(
                    t(`user-management:masterData.createLinkForm.updatedSuccessfully`),
                    {
                        variant: 'success',
                    }
                );
            })
            .catch((response) => {
                if (
                    Array.isArray(
                        response?.error?.response?.data.errors.customerConnections
                    )
                ) {
                    response?.error?.response?.data.errors.customerConnections?.forEach(
                        (errorMessage) => {
                            enqueueSnackbar(
                                t(
                                    `user-management:masterData.createLinkForm.${errorMessage}`
                                ),
                                {
                                    variant: 'error',
                                }
                            );
                        }
                    );
                } else {
                    if (response?.error?.response?.data?.errors['validTo']?.length) {
                        enqueueSnackbar(t('errors:validDataError'), {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar(t('errors:unknownError'), {
                            variant: 'error',
                        });
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleNewURL = (): void => {
        setValue('isNewURL', !isNewURL);
    };

    const handleChangeValue = (name, value): void => {
        setValue(name, value);
    };

    const handleChangeSelect = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
    };

    const statusOptions = generateStatusOptions(t);

    const [isNewURL, date, status, password] = watch([
        'isNewURL',
        'date',
        'status',
        'password',
    ]);

    const makeRequestToUpdateExternalLink = (): Promise<void> => {
        setLoading(true);
        if (!externalLinkId) {
            return httpClient.post(
                `customer-connections/${clientConnection}/external-links`,
                {
                    password,
                    status,
                    validTo: moment(date).toISOString(),
                }
            );
        } else {
            return httpClient.put(`external-links/${externalLinkId}`, {
                password,
                status,
                generateNewUrl: isNewURL,
                validTo: moment(date).toISOString(),
            });
        }
    };

    return (
        <ThemeModalWindow isOpen={isOpen} onClose={handleClose}>
            <Typography variant='h5' className={classes.modalTitle}>
                {t('user-management:masterData.createLinkForm.title')}
            </Typography>
            <Grid container style={{ maxWidth: 500 }}>
                {externalLinkId && (
                    <FormControl className={classes.newURLContainer}>
                        <FormLabel>
                            {t(
                                'user-management:masterData.createLinkForm.newURLGeneration'
                            )}
                        </FormLabel>
                        <Checkbox
                            checked={isNewURL}
                            name='newURL'
                            icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
                            onChange={handleNewURL}
                            checkedIcon={
                                <CheckBoxIcon
                                    className={clsx(classes.icon, classes.checkedIcon)}
                                />
                            }
                        />
                    </FormControl>
                )}
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    className={classes.dateField}
                    variant='outlined'
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeDatePicker
                                editMode
                                inputVariant='outlined'
                                value={date}
                                name='date'
                                onChange={handleChangeValue}
                            />
                        }
                        label={t(
                            'user-management:masterData.createLinkForm.URLValidUntil'
                        )}
                        labelPlacement='top'
                    />
                </FormControl>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        label={t('user-management:masterData.createLinkForm.password')}
                        labelPlacement='top'
                        control={
                            <OutlinedInput
                                classes={inputClasses}
                                value={password}
                                onChange={(e) => setValue('password', e.target.value)}
                            />
                        }
                    />
                </FormControl>
                <FormControl fullWidth classes={formControlClasses} variant='outlined'>
                    <FormControlLabel
                        label={t('user-management:masterData.createLinkForm.status')}
                        labelPlacement='top'
                        classes={formControlLabelClasses}
                        control={
                            <ThemeSelectInput
                                editMode
                                name='status'
                                value={status}
                                items={statusOptions}
                                onChange={handleChangeSelect}
                            />
                        }
                    />
                </FormControl>
            </Grid>
            <Grid container className={classes.modalActionsContainer}>
                <Button
                    disableElevation
                    disabled={!clientConnection || isLoading}
                    variant='contained'
                    className={clsx(classes.modalButton, classes.saveButton)}
                    onClick={handleSave}
                    startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                >
                    {t('common:save')}
                </Button>
                <Button
                    disabled={isLoading}
                    variant='outlined'
                    className={clsx(classes.modalButton, classes.cancelButton)}
                    onClick={handleClose}
                >
                    {t('common:cancel')}
                </Button>
            </Grid>
        </ThemeModalWindow>
    );
};
