import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    tooltip: {
        position: 'relative',
        top: '20px',
        backgroundColor: palette.grey[900],
        borderRadius: spacing(1),
    },

    overflowChild: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));
