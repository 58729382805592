export enum NotificationCenterSections {
    READ_MESSAGES = 'Read',
    UNREAD_MESSAGES = 'Unread',
    ARCHIVED_MESSAGES = 'Archived',
    NEW_MESSAGE = 'new message',
}

export enum NotificationCenterMessageStatuses {
    UNREAD = 'Unread',
    READ = 'Read',
    ARCHIVED = 'Archived',
}

export enum NotificationCenterMessageTypes {
    PRIVATE_MESSAGE = 'PrivateMessage',
    NOTIFICATION_TRIGGER = 'NotificationTrigger',
    OWN_MESSAGE = 'OwnMessage',
}
