import React, { BaseSyntheticEvent, memo } from 'react';
import { DamageReportFormConfigGBWWReturnType } from './damage-report-form-config-GBW-HR-GESCH';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

interface DamageReportFormConfigGeneratorProps {
    readonly: boolean;
    config: DamageReportFormConfigGBWWReturnType[];
    handleChangeValue: (e: BaseSyntheticEvent) => void;
    containerClassName: string;
    formBlockElementClass: string;
}

export const DamageReportFormConfigGenerator = memo(
    ({
        config,
        handleChangeValue,
        containerClassName,
        formBlockElementClass,
        readonly,
    }: DamageReportFormConfigGeneratorProps) => {
        const { t } = useTranslation(['common', 'damages-report', 'errors', 'divisions']);

        return (
            <>
                <Grid container className={containerClassName}>
                    {config?.map(
                        ({
                            formElements,
                            Container,
                            title,
                            titleData,
                            id,
                            formElementsRows = {},
                            ...props
                        }) => {
                            const {
                                RowComponent,
                                rows,
                                handleAddRow,
                                handleDeleteRow,
                                handleOnChangeRow,
                            } = formElementsRows;
                            return (
                                <Container
                                    key={id}
                                    title={t(title, {
                                        titleData: titleData ? t(titleData) : null,
                                    })}
                                    className={formBlockElementClass}
                                    disabled={readonly}
                                    {...props}
                                >
                                    <>
                                        {formElements?.map(
                                            ({
                                                name,
                                                value,
                                                Item,
                                                label,
                                                items,
                                                options,
                                                regexp,
                                                handleChangeCheckboxesGroup,
                                                className,
                                                ...rest
                                            }) => (
                                                <Item
                                                    regexp={regexp}
                                                    disabled={readonly}
                                                    editMode={!readonly}
                                                    key={name}
                                                    value={value}
                                                    label={t(label)}
                                                    name={name}
                                                    handleChangeValue={handleChangeValue}
                                                    items={items}
                                                    options={options}
                                                    handleChangeCheckboxesGroup={
                                                        handleChangeCheckboxesGroup
                                                    }
                                                    className={className}
                                                    {...rest}
                                                />
                                            )
                                        )}
                                        {RowComponent && (
                                            <RowComponent
                                                rows={rows}
                                                handleAddRow={handleAddRow}
                                                handleDeleteRow={handleDeleteRow}
                                                handleOnChangeRow={handleOnChangeRow}
                                            />
                                        )}
                                    </>
                                </Container>
                            );
                        }
                    )}
                </Grid>
            </>
        );
    }
);
