import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';
import { usePHVStyles } from './contract-details-view-phv-styles';

import { ContractDetailsListItem } from '../contract-details-list-item';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewPHVResponseBody } from './contract-details-view-phv-response-body';

import { TFunction } from 'i18next';

interface ContractDetailsViewPHVTableProps {
    t: TFunction;
    insuredPhv: boolean;
    insuredBuilder: boolean;
    insuredAnimal: boolean;
    insuredWaters: boolean;
    insuredHunting: boolean;
    insuredHouseAndLand: boolean;
    insuredWaterSports: boolean;
    insuredOthers: boolean;
    descriptionPhv: string;
    descriptionWaters: string;
    descriptionAnimal: string;
    descriptionHouseAndLand: string;
    descriptionWaterSports: string;
    descriptionOthers: string;
    totalPhv: number;
    totalAnimal: number;
    totalWaters: number;
    totalHouseAndLand: number;
    totalWaterSports: number;
    totalOthers: number;
}

const ContractDetailsViewPHVTable = (
    props: ContractDetailsViewPHVTableProps
): JSX.Element => {
    const contractPHVClasses = usePHVStyles();

    const {
        t,
        insuredPhv,
        totalPhv,
        descriptionPhv,
        insuredBuilder,
        insuredAnimal,
        descriptionAnimal,
        totalAnimal,
        insuredWaters,
        descriptionWaters,
        totalWaters,
        insuredHouseAndLand,
        descriptionHouseAndLand,
        totalHouseAndLand,
        insuredHunting,
        insuredWaterSports,
        descriptionWaterSports,
        totalWaterSports,
        insuredOthers,
        descriptionOthers,
        totalOthers,
    } = props;

    const areAnyOfNumberValuesMoreThanZero = Boolean(
        totalPhv ||
            totalAnimal ||
            totalHouseAndLand ||
            totalWaterSports ||
            totalOthers ||
            totalWaters
    );

    return (
        <table className={contractPHVClasses.table}>
            <tbody>
                <tr>
                    <th style={{ minWidth: 50 }} />
                    <th>{t('contract-details-view:contractDetails.insured')}</th>
                    <th>{t('contract-details-view:contractDetails.description')}</th>
                    {areAnyOfNumberValuesMoreThanZero && (
                        <th>
                            {t('contract-details-view:contractDetails.totalPieceSqmUnit')}
                        </th>
                    )}
                </tr>
                {(typeof insuredPhv === 'boolean' || totalPhv || descriptionPhv) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.phv')}</td>
                        {typeof insuredPhv === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredPhv ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionPhv && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionPhv}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalPhv && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalPhv,
                                })}
                            </td>
                        )}
                    </tr>
                )}
                {typeof insuredBuilder === 'boolean' && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.builders')}</td>
                        <td className={contractPHVClasses.tableCell}>
                            {insuredBuilder ? t('common:yes') : t('common:no')}
                        </td>
                        <td />
                        <td />
                    </tr>
                )}
                {(typeof insuredAnimal === 'boolean' ||
                    totalAnimal ||
                    descriptionAnimal) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.animal')}</td>
                        {typeof insuredAnimal === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredAnimal ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionAnimal && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionAnimal}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalAnimal && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalAnimal,
                                })}
                            </td>
                        )}
                    </tr>
                )}
                {(typeof insuredWaters === 'boolean' ||
                    totalWaters ||
                    descriptionWaters) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.waters')}</td>
                        {typeof insuredWaters === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredWaters ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionWaters && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionWaters}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalWaters && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalWaters,
                                })}
                            </td>
                        )}
                    </tr>
                )}
                {(typeof insuredHouseAndLand === 'boolean' ||
                    totalHouseAndLand ||
                    descriptionHouseAndLand) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.houseGrand')}</td>
                        {typeof insuredHouseAndLand === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredHouseAndLand ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionHouseAndLand && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionHouseAndLand}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalHouseAndLand && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalHouseAndLand,
                                })}
                            </td>
                        )}
                    </tr>
                )}
                {typeof insuredHunting === 'boolean' && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.hunt')}</td>
                        <td className={contractPHVClasses.tableCell}>
                            {insuredHunting ? t('common:yes') : t('common:no')}
                        </td>
                        <td />
                        <td />
                    </tr>
                )}
                {(typeof insuredWaterSports === 'boolean' ||
                    descriptionWaterSports ||
                    totalWaterSports) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.waterSports')}</td>
                        {typeof insuredWaterSports === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredWaterSports ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionWaterSports && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionWaterSports}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalWaterSports && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalWaterSports,
                                })}
                            </td>
                        )}
                    </tr>
                )}
                {(typeof insuredOthers === 'boolean' ||
                    totalOthers ||
                    descriptionOthers) && (
                    <tr>
                        <td>{t('contract-details-view:contractDetails.others')}</td>
                        {typeof insuredOthers === 'boolean' && (
                            <td className={contractPHVClasses.tableCell}>
                                {insuredOthers ? t('common:yes') : t('common:no')}
                            </td>
                        )}
                        {descriptionOthers && (
                            <td className={contractPHVClasses.tableCell}>
                                {descriptionOthers}
                            </td>
                        )}
                        {areAnyOfNumberValuesMoreThanZero && totalOthers && (
                            <td className={contractPHVClasses.tableCell}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: totalOthers,
                                })}
                            </td>
                        )}
                    </tr>
                )}
            </tbody>
        </table>
    );
};

interface ContractDetailsViewPhvProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewPhv = (
    props: ContractDetailsViewPhvProps
): JSX.Element => {
    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const ability = useAbilityCtx();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const contractPHVClasses = usePHVStyles();

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] = useState<ContractDetailsViewPHVResponseBody>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewPHVResponseBody> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewPHVResponseBody>(
                `contracts/${id}/phv`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewPHVResponseBody) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );

    const areAnyBoatValues = useMemo(
        () =>
            detailsData?.boatArt ||
            detailsData?.boatType ||
            detailsData?.boatConstructionYear ||
            detailsData?.boatManufacturer ||
            detailsData?.boatRegisterNumber ||
            detailsData?.boatSailSqm ||
            detailsData?.boatSailNumber,
        [detailsData]
    );

    const areAnyEngineValues = useMemo(
        () =>
            detailsData?.engineType ||
            detailsData?.engineConstructionYear ||
            detailsData?.engineNumber ||
            detailsData?.engineManufacturer ||
            detailsData?.engineKwOrPs ||
            detailsData?.engineInsideOrOutside,
        [detailsData]
    );

    const areAnyTrailerValues = useMemo(
        () =>
            detailsData?.trailerManufacturer ||
            detailsData?.trailerType ||
            detailsData?.trailerNumber ||
            detailsData?.trailerConstructionYear ||
            detailsData?.trailerValue,
        [detailsData]
    );

    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => x || typeof x === 'boolean')?.length;
        }
        return true;
    }, [detailsData]);

    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List
                        className={clsx(
                            contractDetailsViewDataClasses.list,
                            contractPHVClasses.list
                        )}
                    >
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 1 }}
                            value={detailsData?.coverageType1}
                            hidden={!detailsData?.coverageType1}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 2 }}
                            value={detailsData?.coverageType2}
                            hidden={!detailsData?.coverageType2}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 3 }}
                            value={detailsData?.coverageType3}
                            hidden={!detailsData?.coverageType3}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.coverageType'
                            additionalTitle={{ mark: 4 }}
                            value={detailsData?.coverageType4}
                            hidden={!detailsData?.coverageType4}
                        />
                        <ContractDetailsViewPHVTable
                            t={t}
                            insuredPhv={detailsData?.insuredPhv}
                            descriptionPhv={detailsData?.descriptionPhv}
                            totalPhv={detailsData?.totalPhv}
                            insuredBuilder={detailsData?.insuredBuilder}
                            insuredAnimal={detailsData?.insuredAnimal}
                            descriptionAnimal={detailsData?.descriptionAnimal}
                            totalAnimal={detailsData?.totalAnimal}
                            insuredWaters={detailsData?.insuredWaters}
                            descriptionWaters={detailsData?.descriptionWaters}
                            totalWaters={detailsData?.totalWaters}
                            insuredHouseAndLand={detailsData?.insuredHouseAndLand}
                            descriptionHouseAndLand={detailsData?.descriptionHouseAndLand}
                            totalHouseAndLand={detailsData?.totalHouseAndLand}
                            insuredHunting={detailsData?.insuredHunting}
                            insuredWaterSports={detailsData?.insuredWaterSports}
                            descriptionWaterSports={detailsData?.descriptionWaterSports}
                            totalWaterSports={detailsData?.totalWaterSports}
                            insuredOthers={detailsData?.insuredOther}
                            descriptionOthers={detailsData?.descriptionOther}
                            totalOthers={detailsData?.totalOther}
                        />
                    </List>
                    <List
                        className={clsx(
                            contractDetailsViewDataClasses.list,
                            contractPHVClasses.list
                        )}
                    >
                        {areAnyBoatValues ? (
                            <ListItem className={contractPHVClasses.subtitle}>
                                <ListItemText>
                                    {t(
                                        'contract-details-view:contractDetails.boatDescription'
                                    )}
                                </ListItemText>
                            </ListItem>
                        ) : null}
                        <List>
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.boatArt'
                                value={detailsData?.boatArt}
                                hidden={!detailsData?.boatArt}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.boatType'
                                value={detailsData?.boatType}
                                hidden={!detailsData?.boatType}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.constructionYear'
                                value={detailsData?.boatConstructionYear}
                                hidden={!detailsData?.boatConstructionYear}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.manufacturer'
                                value={detailsData?.boatManufacturer}
                                hidden={!detailsData?.boatManufacturer}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.registrationNumber'
                                value={detailsData?.boatRegisterNumber}
                                hidden={!detailsData?.boatRegisterNumber}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.sailsSqm'
                                value={detailsData?.boatSailSqm}
                                hidden={!detailsData?.boatSailSqm}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.sailsNumber'
                                value={detailsData?.boatSailNumber}
                                hidden={!detailsData?.boatSailNumber}
                            />
                        </List>
                        {areAnyEngineValues ? (
                            <ListItem className={contractPHVClasses.subtitle}>
                                <ListItemText>
                                    {t(
                                        'contract-details-view:contractDetails.engineDescription'
                                    )}
                                </ListItemText>
                            </ListItem>
                        ) : null}
                        <List>
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.engineType'
                                value={detailsData?.engineType}
                                hidden={!detailsData?.engineType}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.constructionYear'
                                value={detailsData?.engineConstructionYear}
                                hidden={!detailsData?.engineConstructionYear}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.engineNumber'
                                value={detailsData?.engineNumber}
                                hidden={!detailsData?.engineNumber}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.manufacturer'
                                value={detailsData?.engineManufacturer}
                                hidden={!detailsData?.engineManufacturer}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.engineKwOrPs'
                                value={detailsData?.engineKwOrPs}
                                hidden={!detailsData?.engineKwOrPs}
                            />
                            <ContractDetailsListItem
                                title='contract-details-view:contractDetails.insideOrOutside'
                                value={detailsData?.engineInsideOrOutside}
                                hidden={!detailsData?.engineInsideOrOutside}
                            />
                        </List>
                        {areAnyTrailerValues && (
                            <ListItem className={contractPHVClasses.subtitle}>
                                <ListItemText>
                                    {t(
                                        'contract-details-view:contractDetails.trailerDescription'
                                    )}
                                </ListItemText>
                            </ListItem>
                        )}
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.manufacturer'
                            value={detailsData?.trailerManufacturer}
                            hidden={!detailsData?.trailerManufacturer}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.typ'
                            value={detailsData?.trailerType}
                            hidden={!detailsData?.trailerType}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.numberAbbr'
                            value={detailsData?.trailerNumber}
                            hidden={!detailsData?.trailerNumber}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.constructionYear'
                            value={detailsData?.trailerConstructionYear}
                            hidden={!detailsData?.trailerConstructionYear}
                        />
                        <ContractDetailsListItem
                            title='contract-details-view:contractDetails.value'
                            value={
                                detailsData?.trailerValue
                                    ? String(detailsData?.trailerValue)
                                    : ''
                            }
                            hidden={!detailsData?.trailerValue}
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
