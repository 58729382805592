import moment from 'moment';
import { useDashboardContext } from 'src/shared/contexts';

import { SelectItem } from 'src/theming';

export const generateYears = (): SelectItem[] => {
    const currentYear = moment().year();
    var monthString = useDashboardContext().dashboardData?.financialYearStartMonthString ?? '';
    return [
        {
            title: `${monthString}${currentYear - 2}`,
            value: `${currentYear - 2}`,
        },
        {
            title: `${monthString}${currentYear - 1}`,
            value: `${currentYear - 1}`,
        },
        {
            title: `${monthString}${currentYear}`,
            value: `${currentYear}`,
        },
    ];
};
