import React, { memo } from 'react';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Grid, OutlinedInput } from '@material-ui/core';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useStyle,
} from '../damage-report-form-components-styled';

interface DamageReportFormFullwidthInputProps {
    value: unknown;
    handleChangeValue: () => void;
    label: string;
    name: string;
    disabled?: boolean;
    error?: boolean;
}

export const DamageReportFormFullwidthInput = memo(
    ({
        handleChangeValue,
        value,
        label,
        name,
        disabled,
        error,
    }: DamageReportFormFullwidthInputProps) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        return (
            <Grid item className={clsx(classes.fullWidth)}>
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    variant='outlined'
                    error={error}
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <OutlinedInput
                                multiline
                                rows={4}
                                disabled={disabled}
                                value={value}
                                onChange={handleChangeValue}
                                name={name}
                            />
                        }
                        label={label}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
