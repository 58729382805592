import React from 'react';
import { useTheme } from '@material-ui/core';

import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isSGIFFOXXKInstance,
    isHectorDemoInstance,
} from 'src/environment';

interface InternalUserIconProps {
    isActive: boolean;
}

export const InternalUserIcon = (props: InternalUserIconProps): JSX.Element => {
    const theme = useTheme();
    const { isActive } = props;

    const currentActiveColor =
        isSGIFFOXXKInstance ||
        isAonDigitalInstance ||
        isAonMotorInstance ||
        isHectorDemoInstance
            ? theme.palette.secondary.contrastText
            : theme.palette.info.main;
    return (
        <svg width='20' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10 11.252V19H0a8 8 0 013.1-6.326c.966-.748 3.4-1.917 6.9-1.422z'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
            <circle
                cx='9.5'
                cy='4.5'
                r='4.5'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
            <path
                d='M12 15.5l2 2 5-5'
                stroke={isActive ? currentActiveColor : '#C2C2C2'}
                strokeWidth='2'
            />
        </svg>
    );
};
