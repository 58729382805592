import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export interface DataBasesDto {
    name: string;
    selected: boolean;
    favourite: boolean;
}

export const useDataBases = (isAccessToMakeRequest: boolean): DataBasesDto[] => {
    const httpClient = useHttpClient();
    const [availableDataBases, setAvailableDataBases] = useState<DataBasesDto[]>([]);

    useEffect(() => {
        if (isAccessToMakeRequest) {
            httpClient.get('users/available-databases').then((data: DataBasesDto[]) => {
                setAvailableDataBases(data);
            });
        }
    }, [isAccessToMakeRequest]);

    return availableDataBases;
};
