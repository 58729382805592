import React, { useCallback, useMemo, useState, useEffect } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter } from 'src/shared/contexts';

import { useDataBases } from 'src/shared/hooks';
import { AbilityActions, AbilitySubjects, useAbilityCtx } from 'src/roleAccesses';

import { ThemeNestedMenu } from '..';

import { generateThemeHeaderProfileMenuConfig } from './theme-header-profile-menu-config';
import { UpdateDashboardDataModalWindow } from 'src/dashboard/update-dashboard-data-modal-window/update-dashboard-data-modal-window';
import { useDashboardContext } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { TwoFactorStatus } from 'src/theming/theme-header/two-factor-status';

export const ThemeHeaderProfileMenu = (): JSX.Element => {
    const { t } = useTranslation(['common']);
    const router = useHistory();
    const [twoFactorStatus, setTwoFactorStatus] = useState<TwoFactorStatus>();
    const { handleLogout, userData, homePath, updateUserSettings } = useAuth();
    const { filter } = useGlobalFilter();
    const ability = useAbilityCtx();
    const httpClient = useHttpClient();

    const { isOpen, isUpdatingLoading, handleOpen, handleClose, handleUpdate } =
        useDashboardContext();

    const [isUpdatingFavouriteDatabaseLoading, setUpdatingFavouriteDatabaseLoading] =
        useState<boolean>(false);
    const [isMounted, setMounted] = useState<boolean>(false);

    const dataBases = useDataBases(
        isMounted && ability.can(AbilityActions.change, AbilitySubjects.dataBase)
    );

    const handleGoTo = useCallback((path: string) => router.push(path), [router]);

    const changeLanguage = (lang: string): void => {
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        httpClient.get<TwoFactorStatus>('two-factor/status').then((data) => {
            setTwoFactorStatus(data);
        });
    }, []);

    const changeDataBase = (favouriteDatabase: string, currentDatabase: string): void => {
        if (
            (favouriteDatabase && favouriteDatabase !== userData.favouriteDatabase) ||
            (currentDatabase && currentDatabase !== userData.currentDatabase)
        ) {
            setUpdatingFavouriteDatabaseLoading(true);
            updateUserSettings({
                favouriteDatabase,
                currentDatabase,
                clientConnections: filter.customerConnections,
                clients: filter.customers,
                darkMode: userData.darkTheme,
                defaultDivision: userData.defaultDivision,
                divisions: userData.divisions,
            }).finally(() => {
                setUpdatingFavouriteDatabaseLoading(false);
            });
        }
    };

    const profileMenuItems = useMemo(() => {
        if (dataBases) {
            return generateThemeHeaderProfileMenuConfig(
                t,
                handleGoTo,
                handleLogout,
                changeLanguage,
                router.location.pathname,
                ability,
                homePath,
                changeDataBase,
                dataBases,
                userData?.favouriteDatabase,
                userData?.currentDatabase,
                handleOpen,
                twoFactorStatus
            );
        }
        return [];
    }, [handleGoTo, handleLogout, t, ability, dataBases, homePath, twoFactorStatus]);

    return (
        <>
            <ThemeNestedMenu
                isDisabled={isUpdatingFavouriteDatabaseLoading}
                title={`${userData?.userInfo?.name || ''} ${
                    userData?.userInfo?.surname || ''
                }`}
                menuItems={profileMenuItems}
            />
            <UpdateDashboardDataModalWindow
                isOpen={isOpen}
                onClose={handleClose}
                handleUpdate={handleUpdate}
                isUpdatingLoading={isUpdatingLoading}
            />
        </>
    );
};
