import { makeStyles, Theme } from '@material-ui/core';

import { DARK_MAAS_THEME_COLORS, LIGHT_MAAS_THEME_COLORS } from 'src/theming/themes/maas';

export const useUserMaasLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            background: 'transparent linear-gradient(180deg, #000000 0%, #00f867 100%) 0% 0% no-repeat padding-box',
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        headerLink: {
            marginTop: theme.spacing(8),
            marginRight: theme.spacing(10),
            fontSize: '2rem',
            color: LIGHT_MAAS_THEME_COLORS.SECONDARY_CONTRAST_TEXT,
            transition: '0.3s',
            '&:hover': {
                textDecoration: 'none',
                color: 'rgba(35,213,208,1)',
            },
        },
        logoContainer: {
            width: 520,
            marginLeft: 100,
            marginTop: 70,
            marginBottom: 40,
            '& svg': {
                width: '100%',
                height: '100%',
            },
            [theme.breakpoints.down('lg')]: {
                width: 400,
                marginTop: 60,
                marginBottom: 30,
                marginLeft: 60,
            },
        },
        logo: {
            width: '96%',
        },
        formContainer: {
            maxWidth: 670,
            minWidth: 400,
            width: '40%',
            borderRadius: 18,
            marginRight: 100,
            marginBottom: 20,
            padding: '70px',
            marginLeft: 'auto',
            backgroundColor: LIGHT_MAAS_THEME_COLORS.PRIMARY_DARK_COLOR,
            [theme.breakpoints.down('lg')]: {
                padding: 30,
                marginRight: 60,
            },
        },
        footerContainer: {
            width: '100%',
            minHeight: 60,
            backgroundColor: DARK_MAAS_THEME_COLORS.PRIMARY_DARK_COLOR,
            '& a,span': {
                color: '#FFFFFF',
            },
        },
    };
});
