import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DarkMode } from 'use-dark-mode';

import { LinearProgress } from '@material-ui/core';

import ErrorBoundary from 'src/lib/error-boundary';
import { useAuth } from 'src/user-management/context-auth';
import {
    appInstancesThatHaveAccessToCreateContract,
    appInstancesThatHaveAccessToCreateCustomer,
    CUSTOMER_ROLES,
    INTERNAL_ROLES,
    AppInstances,
} from 'src/shared/constants';

import { APP_ROUTES, ProtectedRoute } from '.';
import { appInstance } from 'src/environment';
import { DashboardPdfTest } from 'src/pages/dashboard-pdf-test/dashboard-pdf-test';

const ChangePasswordPage = lazy(() =>
    import('src/pages').then(({ ChangePasswordPage }) => ({
        default: ChangePasswordPage,
    }))
);
const ContractDetailViewPage = lazy(() =>
    import('src/pages').then(({ ContractDetailViewPage }) => ({
        default: ContractDetailViewPage,
    }))
);
const ContractsPage = lazy(() =>
    import('src/pages').then(({ ContractsPage }) => ({
        default: ContractsPage,
    }))
);
const CreatePasswordPage = lazy(() =>
    import('src/pages').then(({ CreatePasswordPage }) => ({
        default: CreatePasswordPage,
    }))
);
const CustomerDetailsViewPage = lazy(() =>
    import('src/pages').then(({ CustomerDetailsViewPage }) => ({
        default: CustomerDetailsViewPage,
    }))
);
const CustomerAddFormPage = lazy(() =>
    import('src/pages').then(({ CustomerAddFormPage }) => ({
        default: CustomerAddFormPage,
    }))
);
const CustomersPage = lazy(() =>
    import('src/pages').then(({ CustomersPage }) => ({
        default: CustomersPage,
    }))
);
const DamageDetailsViewPage = lazy(() =>
    import('src/pages').then(({ DamageDetailsViewPage }) => ({
        default: DamageDetailsViewPage,
    }))
);
const DamageReportPage = lazy(() =>
    import('src/pages').then(({ DamageReportPage }) => ({
        default: DamageReportPage,
    }))
);
const DamagesPage = lazy(() =>
    import('src/pages').then(({ DamagesPage }) => ({
        default: DamagesPage,
    }))
);
const FAQPage = lazy(() =>
    import('src/pages').then(({ FAQPage }) => ({
        default: FAQPage,
    }))
);
const ForgotPasswordPage = lazy(() =>
    import('src/pages').then(({ ForgotPasswordPage }) => ({
        default: ForgotPasswordPage,
    }))
);
const GlobalSearchPage = lazy(() =>
    import('src/pages').then(({ GlobalSearchPage }) => ({
        default: GlobalSearchPage,
    }))
);
const HomePage = lazy(() =>
    import('src/pages').then(({ HomePage }) => ({
        default: HomePage,
    }))
);

const LoginPage = lazy(() =>
    import('src/pages').then(({ LoginPage }) => ({
        default: LoginPage,
    }))
);
const NonUsersDamageReport = lazy(() =>
    import('src/pages').then(({ NonUsersDamageReport }) => ({
        default: NonUsersDamageReport,
    }))
);
const NotificationsPage = lazy(() =>
    import('src/pages').then(({ NotificationsPage }) => ({
        default: NotificationsPage,
    }))
);
const PrivilegesPage = lazy(() =>
    import('src/pages').then(({ PrivilegesPage }) => ({
        default: PrivilegesPage,
    }))
);
const UserManagementPage = lazy(() =>
    import('src/pages').then(({ UserManagementPage }) => ({
        default: UserManagementPage,
    }))
);
const MasterDataPage = lazy(() =>
    import('src/pages').then(({ MasterDataPage }) => ({
        default: MasterDataPage,
    }))
);

const ReportCenterPage = lazy(() =>
    import('src/pages').then(({ ReportCenterPage }) => ({
        default: ReportCenterPage,
    }))
);

const CreateContractPage = lazy(() =>
    import('src/pages').then(({ CreateContractPage }) => ({
        default: CreateContractPage,
    }))
);

const ErrorPage = lazy(() =>
    import('src/pages').then(({ ErrorPage }) => ({
        default: ErrorPage,
    }))
);

const TranscodeDocumentsPage = lazy(() =>
    import('src/pages').then(({ TranscodeDocumentsPage }) => ({
        default: TranscodeDocumentsPage,
    }))
);

const ImprintPage = lazy(() =>
    import('src/pages').then(({ ImprintPage }) => ({
        default: ImprintPage,
    }))
);

const PrivacyPage = lazy(() =>
    import('src/pages').then(({ PrivacyPage }) => ({
        default: PrivacyPage,
    }))
);

const UserSettingsPage = lazy(() =>
    import('src/pages').then(({ UserSettingsPage }) => ({ default: UserSettingsPage }))
);

interface Props {
    darkMode: DarkMode;
}

const rolesWhoHaveAccessToTheMainPortal = [
    INTERNAL_ROLES.ADMIN_DOMAIN,
    INTERNAL_ROLES.CUSTOMER_ADVISER,
    INTERNAL_ROLES.CA_BROKER,
    CUSTOMER_ROLES.CLERK,
    CUSTOMER_ROLES.CLERK_PLUS,
];

const rolesWhoHaveAccessToTheUserManagementSection = [
    INTERNAL_ROLES.ADMIN_TECHNICAL,
    INTERNAL_ROLES.CUSTOMER_ADVISER,
    INTERNAL_ROLES.CA_BROKER,
    CUSTOMER_ROLES.ACCOUNT_OWNER,
    CUSTOMER_ROLES.ADMINISTRATOR,
    CUSTOMER_ROLES.ADMINISTRATOR_PLUS,
];

const rolesWhoHaveAccessToThePrivilegesSection = [INTERNAL_ROLES.ADMIN_TECHNICAL];
const rolesWhoHaveAccessToTheNotificationsSection = [INTERNAL_ROLES.ADMIN_TECHNICAL];

export function RoutedContent({ darkMode }: Props): JSX.Element {
    const { authorized, userData } = useAuth();
    // const query = useQuery();
    // const isBackendMode = query.get('isBackendMode') === '1';

    const isAuth = Boolean(authorized && userData);

    // if (isBackendMode) {
    //     return (
    //         <DndProvider backend={HTML5Backend}>
    //             <ProtectedRoute
    //                 path={APP_ROUTES.HOME}
    //                 withoutAuthValidation={isBackendMode}
    //                 render={() => <ContractsDashboard />}
    //                 isAllowed={isBackendMode ? true : isAuth}
    //                 roles={rolesWhoHaveAccessToTheMainPortal}
    //             />
    //         </DndProvider>
    //     );
    // }

    if (authorized === null) {
        return <LinearProgress />;
    }

    return (
        <Switch>
            <Route
                exact
                path={APP_ROUTES.TEST_HOME_PDF}
                render={() => <DashboardPdfTest />}
            />
            <Route
                exact
                path={APP_ROUTES.NO_ACCESS}
                render={() => (
                    <ErrorBoundary>
                        <ErrorPage message='errors:noAccess' />
                    </ErrorBoundary>
                )}
            />
            <Route
                exact
                path={APP_ROUTES.LOGIN}
                render={() => <LoginPage darkMode={darkMode} />}
            />
            <ProtectedRoute
                path={APP_ROUTES.USER_SETTINGS}
                render={() => <UserSettingsPage />}
                isAllowed={isAuth}
            />
            <ProtectedRoute
                isAllowed
                withoutAuthValidation
                path={APP_ROUTES.IMPRINT}
                render={() => <ImprintPage />}
                instances={[AppInstances.CEAT, AppInstances.CEAT_MAKLER]}
            />
            <ProtectedRoute
                isAllowed
                withoutAuthValidation
                path={APP_ROUTES.PRIVACY}
                render={() => <PrivacyPage />}
                instances={[AppInstances.CEAT, AppInstances.CEAT_MAKLER]}
            />
            <ProtectedRoute
                path={APP_ROUTES.TRANSCODE_DOCUMENTS}
                render={() => <TranscodeDocumentsPage />}
                isAllowed={isAuth}
                roles={[INTERNAL_ROLES.CUSTOMER_ADVISER, INTERNAL_ROLES.CA_BROKER]}
            />
            <ProtectedRoute
                path={APP_ROUTES.DUMMY_CONTRACT}
                render={() => <CreateContractPage />}
                isAllowed={
                    isAuth &&
                    appInstancesThatHaveAccessToCreateContract.includes(
                        appInstance as AppInstances
                    )
                }
                roles={[
                    INTERNAL_ROLES.CA_BROKER,
                    INTERNAL_ROLES.CUSTOMER_ADVISER,
                    CUSTOMER_ROLES.ADMINISTRATOR_PLUS,
                ]}
            />
            <ProtectedRoute
                path={APP_ROUTES.REPORT_CENTER}
                render={() => <ReportCenterPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.MASTER_DATA}
                render={() => <MasterDataPage />}
                isAllowed={isAuth}
                roles={[
                    INTERNAL_ROLES.CA_BROKER,
                    INTERNAL_ROLES.CUSTOMER_ADVISER,
                    CUSTOMER_ROLES.ADMINISTRATOR_PLUS,
                ]}
            />
            <ProtectedRoute
                path={APP_ROUTES.NON_USERS_DAMAGE_REPORT_FORM}
                render={() => <NonUsersDamageReport />}
                isAllowed={true}
                withoutAuthValidation
            />
            <ProtectedRoute
                path={APP_ROUTES.DAMAGE_REPORT_RECORD}
                render={() => <DamageReportPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.DAMAGE_REPORT}
                render={() => <DamageReportPage />}
                isAllowed={isAuth}
                roles={[
                    CUSTOMER_ROLES.CLERK,
                    CUSTOMER_ROLES.CLERK_PLUS,
                    INTERNAL_ROLES.CUSTOMER_ADVISER,
                    INTERNAL_ROLES.CA_BROKER,
                ]}
            />
            <ProtectedRoute
                path={APP_ROUTES.CUSTOMER_DETAILS_VIEW_PAGE}
                render={() => <CustomerDetailsViewPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.CUSTOMER_ADD_FORM_PAGE}
                render={() => <CustomerAddFormPage />}
                isAllowed={
                    isAuth &&
                    appInstancesThatHaveAccessToCreateCustomer.includes(
                        appInstance as AppInstances
                    )
                }
                roles={[INTERNAL_ROLES.CUSTOMER_ADVISER, INTERNAL_ROLES.CA_BROKER]}
            />
            <ProtectedRoute
                path={APP_ROUTES.CUSTOMERS}
                render={() => <CustomersPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.NOTIFICATIONS}
                render={() => <NotificationsPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheNotificationsSection}
            />
            <ProtectedRoute
                path={APP_ROUTES.PRIVILEGES}
                render={() => <PrivilegesPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToThePrivilegesSection}
            />
            <ProtectedRoute
                path={APP_ROUTES.USER_MANAGEMENT}
                render={() => <UserManagementPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheUserManagementSection}
            />
            <ProtectedRoute
                path={APP_ROUTES.FAQ}
                render={() => <FAQPage />}
                isAllowed={isAuth}
            />
            <ProtectedRoute
                path={APP_ROUTES.CHANGE_PASSWORD}
                render={() => <ChangePasswordPage darkMode={darkMode} />}
                isAllowed={isAuth}
            />
            <ProtectedRoute
                isAllowed
                withoutAuthValidation
                path={APP_ROUTES.CREATE_PASSWORD}
                render={() => <CreatePasswordPage darkMode={darkMode} />}
            />
            <ProtectedRoute
                isAllowed
                withoutAuthValidation
                path={APP_ROUTES.FORGOT_PASSWORD}
                render={() => <ForgotPasswordPage darkMode={darkMode} />}
            />
            <ProtectedRoute
                path={APP_ROUTES.CONTRACT_DETAILS_VIEW_PAGE}
                render={() => <ContractDetailViewPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.CONTRACTS}
                render={() => <ContractsPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.DAMAGE_DETAILS_VIEW}
                render={() => <DamageDetailsViewPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.DAMAGES}
                render={() => <DamagesPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.GLOBAL_SEARCH}
                render={() => <GlobalSearchPage />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <ProtectedRoute
                path={APP_ROUTES.HOME}
                render={() => <HomePage darkMode={darkMode} />}
                isAllowed={isAuth}
                roles={rolesWhoHaveAccessToTheMainPortal}
            />
            <Redirect to={APP_ROUTES.HOME} />
        </Switch>
    );
}
