export enum AbilitySubjects {
    app = 'app',
    adminTechnical = 'AdminTechnical',
    adminDomain = 'AdminDomain',
    customerAdviser = 'CustomerAdvisor',
    accountOwner = 'AccountOwner',
    admin = 'Admin',
    adminWithExternDamageReport = 'AdminWithExternDamageReport',
    clerk = 'Clerk',
    clerkWithExternDamageReport = 'ClerkWithExternDamageReport',
    caBroker = 'CABroker',
    internalRoleGroup = 'internalRoleGroup',
    customerRoleGroup = 'customerRoleGroup',
    userManagementCardsWithBranches = 'userManagementCardsWithBranches',
    userManagementClientConnectionCard = 'userManagementClientConnectionCard',
    documents = 'documents',
    umNotifications = 'umNotifications',
    umMasterData = 'umMasterData',
    umMasterDataActiveField = 'umMasterDataActiveField',
    damages = 'damages',
    damageReport = 'damageReport',
    dummyContract = 'dummyContract',
    customers = 'customers',
    customer = 'customer',
    notificationCenter = 'notificationCenter',
    notificationNewMessageCustomerConnectionForm = 'notificationNewMessageCustomerConnectionForm',
    notificationNewMessageCustomerAdviserForm = 'notificationNewMessageCustomerAdviserForm',
    contractListProductsDropDown = 'contractListProductsDropDown',
    socketConnection = 'socketConnection',
    customerConnectionLogo = 'customerConnectionLogo',
    dashboard = 'dashboard',
    dashboardData = 'dashboardData',
    userManagement = 'userManagement',
    dataBase = 'dataBase',
    damageLink = 'damageLink',
    contract = 'contract',
    manageAccountOwners = 'manageAccountOwners',
    manageTwoFactor = 'manageTwoFactor',
}
