import moment from 'moment';
import {
    DamageReportFormPropertyTypes,
    DamageReportFormPropertyMutateDTO,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-property-types';

export const damageReportFormPropertyMutateAdapter = (
    data: DamageReportFormPropertyTypes
): DamageReportFormPropertyMutateDTO => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        propertyInsuranceInfo: {
            damageType: data?.typeOfDamage,
            fileNumberInsuredCompany: data?.fileNumberInsuredCompany,
            propertyDamagedItems: data?.itemsAffectedByTheDamage?.map((item) => ({
                thingsNumberAndType: item.numberAndTypeOfThings,
                replacementPrice:
                    item.replacementPrice && typeof item.replacementPrice === 'string'
                        ? Number(
                              item.replacementPrice.replaceAll('.', '').replace(',', '.')
                          )
                        : 0,
                repairCosts:
                    item.repairCosts && typeof item.repairCosts === 'string'
                        ? Number(item.repairCosts.replaceAll('.', '').replace(',', '.'))
                        : 0,
            })),
            isInputTaxDeductionForDamage: data?.inputTaxDeductionForTheDamage,
            causedByThirdParty: data?.causedByThirdParty,
            isExistenceItemAffectedByDamage: data?.existenceForItemAffectedByTheDamage,
            insurer: data.insurer,
            isBusinessInterruptionLossesExpected:
                data?.areBusinessInterruptionLossesToBeExpected,
        },
        policeRecordTechnical: {
            incidentRecordedByPolice: data?.damageBeenReportedToThePolice,
            policeStation: data?.policeStation,
            policeFileNumber: data?.fileNumber,
        },
    };
};
