import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, FormControl, FormControlLabel, GridSize } from '@material-ui/core';

import { ThemeDatePicker } from 'src/theming';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useContractsCreateContractsFormStyles,
} from '../contracts-create-contract-form-styles';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

interface DateFieldProps {
    md?: GridSize;
    name: string;
    value: ParsableDate;
    label: string;
    handleChangeValue: (name, value) => void;
    error?: boolean;
}

export const DateField = ({
    md,
    name,
    value,
    error,
    label,
    handleChangeValue,
}: DateFieldProps): JSX.Element => {
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const classes = useContractsCreateContractsFormStyles();
    const { t } = useTranslation(['create-contract']);

    return (
        <Grid item md={md}>
            <FormControl
                fullWidth
                classes={formControlClasses}
                variant='outlined'
                className={classes.dateField}
                error={error}
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeDatePicker
                            editMode
                            inputVariant='outlined'
                            value={value}
                            name={name}
                            onChange={handleChangeValue}
                            error={error}
                        />
                    }
                    label={t(label)}
                    labelPlacement='top'
                />
            </FormControl>
        </Grid>
    );
};
