import React, { ReactElement } from 'react';
import { DarkMode } from 'use-dark-mode';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Button, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { ThemeSwitcher } from 'src/theming';
import { ThemeFooter } from 'src/theming/theme-footer';
import { useUserSgiffoxxLayoutStyles } from './user-sgiffoxx-layout-styles';

interface UserSgiffoxxLayoutProps {
    children: JSX.Element;
    darkMode: DarkMode;
    backPath?: string;
}

export const UserSgiffoxxLayout = (props: UserSgiffoxxLayoutProps): ReactElement => {
    const { darkMode, children, backPath } = props;
    const router = useHistory();
    const { t } = useTranslation(['common', 'user-management']);
    const classes = useUserSgiffoxxLayoutStyles();

    const handleGoBack = (): void => {
        router.push(backPath);
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid item className={classes.previewContainer}>
                <div className={classes.logo}>
                    <img
                        alt='logo'
                        src='/static/images/sgi-logo-light.png'
                        width={208}
                        height={33}
                    />
                </div>
                <div className={classes.dots}>
                    <img
                        alt='background-mask'
                        src='/static/images/login-background-mask.svg'
                    />
                </div>
                <div className={classes.portalInformation}>
                    <Typography
                        variant='body1'
                        className={classes.portalInformationMessage}
                    >
                        {t('user-management:sgiffoxxkPortalDescription')}
                    </Typography>
                </div>
            </Grid>
            <Grid item className={classes.functionalityContainer}>
                <div className={classes.toolbarContainer}>
                    <Grid item>
                        {backPath && (
                            <Button
                                disableElevation
                                disableRipple
                                onClick={handleGoBack}
                                startIcon={<KeyboardBackspaceIcon />}
                                className={classes.backButton}
                            >
                                {t('common:back')}
                            </Button>
                        )}
                    </Grid>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item className={classes.switcherContainer}>
                        <ThemeSwitcher
                            leftLabel={t('common:light')}
                            rightLabel={t('common:dark')}
                            checked={darkMode.value}
                            onChange={darkMode.toggle}
                        />
                    </Grid>
                </div>
                <div className={classes.form}>{children}</div>
            </Grid>
            <div className={classes.footer}>
                <ThemeFooter />
            </div>
        </Grid>
    );
};
