import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { appInstance, hectorStyleInstances } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import {
    useInputAonMotorStyles,
    useFormControlLabelAonMotorStyles,
    useFormControlAonMotorStyles,
    useAutocompleteAonMotorStyles,
    useAonMotorStyle,
} from './damage-report-form-aon-motor-styles';

import {
    useSgiffoxxkStyle,
    useInputSgiffoxxkStyles,
    useFormControlSgiffoxxkStyles,
    useFormControlLabelSgiffoxxkStyles,
    useAutocompleteSgiffoxxkStyles,
} from './damage-report-form-sgiffoxxk-styles';

import {
    useHectorStyle,
    useInputHectorStyles,
    useFormControlLabelHectorStyles,
    useFormControlHectorStyles,
    useAutocompleteHectorStyles,
} from './damage-report-form-hector-styles';

import {
    useInputAonStyles,
    useFormControlLabelAonStyles,
    useFormControlAonStyles,
    useAutocompleteAonStyles,
    useAonStyle,
} from './damage-report-form-aon-styles';

const inputInstancesStyles = {
    [AppInstances.AON]: useInputAonStyles,
    [AppInstances.AON_KOMPOSIT]: useInputAonStyles,
    [AppInstances.AON_DIGITAL]: useInputAonMotorStyles,
    [AppInstances.AON_MOTOR]: useInputAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useInputAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useInputSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useInputSgiffoxxkStyles,
};

const formControlLabelInstancesStyles = {
    [AppInstances.AON]: useFormControlLabelAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlLabelAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlLabelAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlLabelAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlLabelAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlLabelSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useFormControlLabelSgiffoxxkStyles,
};

const formControlInstancesStyles = {
    [AppInstances.AON]: useFormControlAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useFormControlSgiffoxxkStyles,
};

const autocompleteInstancesStyles = {
    [AppInstances.AON]: useAutocompleteAonStyles,
    [AppInstances.AON_KOMPOSIT]: useAutocompleteAonStyles,
    [AppInstances.AON_DIGITAL]: useAutocompleteAonMotorStyles,
    [AppInstances.AON_MOTOR]: useAutocompleteAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useAutocompleteAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useAutocompleteSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useAutocompleteSgiffoxxkStyles,
};

const instancesStyles = {
    [AppInstances.AON]: useAonStyle,
    [AppInstances.AON_KOMPOSIT]: useAonStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorStyle,
    [AppInstances.AON_MOTOR]: useAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useSgiffoxxkStyle,
};

hectorStyleInstances.forEach((instance) => {
    inputInstancesStyles[instance] = useInputHectorStyles;
    formControlLabelInstancesStyles[instance] = useFormControlLabelHectorStyles;
    formControlInstancesStyles[instance] = useFormControlHectorStyles;
    autocompleteInstancesStyles[instance] = useAutocompleteHectorStyles;
    instancesStyles[instance] = useHectorStyle;
});

export const useStyle = (): ClassNameMap => {
    return instancesStyles[appInstance]();
};

export const useFormControlStyles = (): ClassNameMap => {
    return formControlInstancesStyles[appInstance]();
};

export const useFormControlLabelStyles = (): ClassNameMap => {
    return formControlLabelInstancesStyles[appInstance]();
};

export const useAutocompleteStyles = (props: unknown): ClassNameMap => {
    return autocompleteInstancesStyles[appInstance](props);
};

export const useInputStyles = (): ClassNameMap => {
    return inputInstancesStyles[appInstance]();
};
