import React, { memo } from 'react';
import clsx from 'clsx';
import { FormControl, FormControlLabel, Grid } from '@material-ui/core';
import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useStyle,
} from '../damage-report-form-components-styled';
import { ThemeCurrencyField } from '../../../../theming';

interface DamageReportFormCurrencyInputProps {
    value: string;
    handleChangeValue: () => void;
    label: string;
    name: string;
    disabled?: boolean;
    decimalPlaces: number;
    onBlur?: () => void;
    className?: string;
}

export const DamageReportFormCurrencyInput = memo(
    ({
        handleChangeValue,
        value,
        label,
        name,
        disabled,
        onBlur,
        className,
    }: DamageReportFormCurrencyInputProps) => {
        const classes = useStyle();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();
        return (
            <Grid item className={clsx(classes.thirdPart, className)}>
                <FormControl
                    fullWidth
                    classes={formControlClasses}
                    className={classes.currencyField}
                    variant='outlined'
                >
                    <FormControlLabel
                        classes={formControlLabelClasses}
                        control={
                            <ThemeCurrencyField
                                disabled={disabled}
                                value={value}
                                onChange={handleChangeValue}
                                name={name}
                                decimalPlaces={2}
                                onBlur={onBlur}
                            />
                        }
                        label={label}
                        labelPlacement='top'
                    />
                </FormControl>
            </Grid>
        );
    }
);
