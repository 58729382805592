import { makeStyles, Theme } from '@material-ui/core';

import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';
import { THEME_MODES } from '..';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            icon: {
                display: 'flex',
                alignItems: 'center',
                width: 16,
                height: 16,
                color: !isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.contrastText,
            },
            checkedIcon: {
                width: 16,
                height: 16,
                color: !isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.contrastText,
                opacity: 1,
            },
        };
    }
    if (isSGIFFOXXKInstance) {
        return {
            icon: {
                display: 'flex',
                alignItems: 'center',
                width: 16,
                height: 16,
                color: !isDarkMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
                opacity: 0.6,
            },
            checkedIcon: {
                width: 16,
                height: 16,
                color: !isDarkMode
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.contrastText,
                opacity: 1,
            },
        };
    }
    return {
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
    };
});

export const useSelectStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            height: 40,
            lineHeight: 3,
            padding: `0 ${theme.spacing(2)}px`,
            fontSize: '1.4rem',
        },
        select: {
            width: '100%',
            color: theme.palette.primary.contrastText,
        },
        icon: {
            width: 20,
            height: 20,
            top: 'calc(50% - 10px)',
        },
        disabled: {
            color: theme.palette.primary.contrastText,
        },
    };
});

export const useMenuClasses = makeStyles((theme: Theme) => {
    return {
        paper: {
            backgroundColor: theme.palette.primary.main,
        },
        list: {
            padding: 0,
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
            },
        },
    };
});
