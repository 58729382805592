import { makeStyles, Theme } from '@material-ui/core';

export const useBuStyles = makeStyles((theme: Theme) => {
    return {
        subtitle: {
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        listItem: {
            width: '800px',
        },
    };
});
