import React, { BaseSyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Typography, IconButton, makeStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

const useStyle = makeStyles((theme: Theme) => {
    return {
        uploadImageContainer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        previewContainer: {
            width: '60%',
        },
        previewImage: {
            width: '100%',
        },
        actionsContainer: {
            width: '40%',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        actionButton: {
            width: 30,
            height: 30,
            borderRadius: 4,
        },
        uploadLabel: {
            cursor: 'pointer',
        },
        uploadButton: {
            color: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.contrastText;
                }
                return theme.palette.info.main;
            },
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.info.main;
                }
                return theme.palette.info.light;
            },
            pointerEvents: 'none',
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.info.main;
                    }
                    return theme.palette.info.light;
                },
            },
        },
        deleteButton: {
            marginLeft: theme.spacing(4),
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.light,
        },
        chooseFileInput: {
            display: 'none',
        },
        noLogoMessage: { height: 30, userSelect: 'none' },
    };
});

interface ThemeUploadImageProps {
    editMode: boolean;
    name: string;
    onChange: (name: string, value: File) => void;
    imageURL?: string;
}

export const ThemeUploadImage = (props: ThemeUploadImageProps): JSX.Element => {
    const { imageURL, editMode, name, onChange } = props;

    const [previewPath, setPreviewPath] = useState<string>(null);

    const { t } = useTranslation(['common']);

    const classes = useStyle();

    const handleUpload = (e: BaseSyntheticEvent): void => {
        if (e.target.files?.length > 0) {
            setPreviewPath(URL.createObjectURL(e.target.files[0]));
            onChange(name, e.target.files[0]);
        }
    };

    const handleDelete = (): void => {
        onChange(name, null);
        setPreviewPath(null);
    };

    return (
        <div className={classes.uploadImageContainer}>
            <div className={classes.previewContainer}>
                {!imageURL && !previewPath && (
                    <Typography variant='body1' className={classes.noLogoMessage}>
                        {t('noLogo')}
                    </Typography>
                )}
                {(previewPath || imageURL) && (
                    <img
                        className={classes.previewImage}
                        src={previewPath || imageURL}
                        alt='Client connection logo'
                    />
                )}
            </div>
            {editMode && (
                <>
                    <div className={classes.actionsContainer}>
                        <label htmlFor='button-file' className={classes.uploadLabel}>
                            <IconButton
                                className={clsx(
                                    classes.actionButton,
                                    classes.uploadButton
                                )}
                            >
                                <PublishIcon />
                            </IconButton>
                            <input
                                className={classes.chooseFileInput}
                                accept='image/*'
                                id='button-file'
                                type='file'
                                onChange={handleUpload}
                            />
                        </label>
                        <IconButton
                            onClick={handleDelete}
                            className={clsx(classes.actionButton, classes.deleteButton)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    );
};
