import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useCustomerChangeNotificationStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        modalWindowClass: {
            '& .MuiPaper-root': {
                width: 900,
                paddingRight: theme.spacing(1),
            },
        },
        modalTitle: {
            color: theme.palette.primary.contrastText,
        },
        modalContentContainer: {
            width: 'auto',
            maxHeight: 600,
            alignItems: 'flex-start',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto',
        },
        fieldsColumn: {
            width: '50%',
            paddingBottom: `0!important`,
        },
        fullWidthFieldsContainer: {
            width: '100%',
            paddingLeft: '0!important',
            paddingTop: '0!important',
            paddingBottom: '0!important',
        },
        contactByPhoneContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingLeft: '0!important',
            '& label': {
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return isDarkMode
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light;
                    }
                    return theme.palette.primary.light;
                },
                '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.contrastText,
                },
            },
        },
        radioButtonsLabel: {
            marginRight: theme.spacing(6),
            color: theme.palette.primary.contrastText,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            marginTop: theme.spacing(4),
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
            },
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        multiRowTextField: {
            height: 'auto!important',
        },
        radioIcon: () => {
            if (isSGIFFOXXKInstance) {
                return {
                    color: isDarkMode
                        ? theme.palette.primary.light
                        : theme.palette.secondary.main,
                };
            }
            return null;
        },
        radioCheckedIcon: () => {
            if (isSGIFFOXXKInstance) {
                return {
                    border: isDarkMode
                        ? 'none'
                        : `1px solid ${theme.palette.secondary.main}`,
                };
            }
            return null;
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return `${
                            isDarkMode
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light
                        }!important`;
                    }
                    return `${theme.palette.primary.light}!important`;
                },
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
