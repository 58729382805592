import React, {
    ChangeEvent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useDarkMode from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { GridSortModelParams } from '@material-ui/data-grid';

import { Box, Button, Grid, Typography, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import { useAuth } from 'src/user-management/context-auth';
import { useGlobalFilter } from 'src/shared/contexts';
import { Can, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import {
    CheckboxItem,
    ThemeCheckboxesDropdown,
    ThemeCircularProgress,
    ThemeLoader,
    ThemeToggleButtons,
    ThemeSearchField,
} from 'src/theming';
import { APP_ROUTES } from 'src/routing';
import { ContractsNavigationPanel } from 'src/contracts/contracts-navigation-panel/contracts-navigation-panel';
import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { DivisionsSection } from '../division-section/division-section';
import { DamageStatuses, generateDamageListToggleButtons } from './damage-list-configs';
import { useStyle } from '../contracts-styles/contracts-styles';
import { useDamagesDetailsSearch } from '../contracts-hooks';
import { globalSearchListGetAllowedFilterValues } from '../global-search-list/global-search-list-get-allowed-filter-values';
import { SearchOptions } from '../contracts-dashboard/generateContractTypeSearchOptions';
import { DamageListWithCreatedStatus } from './damage-list-with-created-status';
import { DamagesListOpenClosedStatusesTable } from './damages-list-open-closed-statuses-table';

import {
    useDamagesListSettings,
    defaultDamageListContextFormValues,
} from 'src/shared/contexts';

import { useDamagesListSettings as useDamagesSettings } from './use-damages-list-settings';

const CurrentLockIcon =
    isHectorStyles ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance
        ? LockOutlinedIcon
        : LockIcon;
const CurrentLockOpenIcon =
    isHectorStyles ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance
        ? LockOpenOutlinedIcon
        : LockOpenIcon;
const CurrentSettingsIcon =
    isHectorStyles ||
    isSGIFFOXXKInstance ||
    isAonDigitalInstance ||
    isAonMotorInstance ||
    isHectorDemoInstance
        ? SettingsOutlinedIcon
        : SettingsIcon;

type DamagesListFormData = {
    search: string;
};

export const DamagesList = (): JSX.Element => {
    const darkMode = useDarkMode();
    const history = useHistory();
    const location =
        useLocation<{ dontResetDamageListContext: boolean; filterValue: string }>();
    const { t } = useTranslation(['common', 'damages-list', 'contract-types']);
    const { reset: resetDamagesContext, handleChangeSort } = useDamagesListSettings();

    const { filter, division, changeDivision } = useGlobalFilter();
    const { userData } = useAuth();

    const [isDivisionsEdit, setDivisionsEdit] = useState<boolean>(false);
    const [isDragAndDropLocked, setDragAndDropLocked] = useState<boolean>(true);

    const { handleSubmit, watch, setValue, formState, reset } =
        useForm<DamagesListFormData>({
            mode: 'onChange',
        });

    const classes = useStyle({ isDarkMode: darkMode.value });
    const [search] = watch(['search']);

    const {
        updateTableSettings,
        filterValue,
        setColumnsSettingsList,
        columnsSettingsList,
        isColumnSettingsLoading,
        setNeedAdditionalColumnsForKFZW,
        setColumnSettingsResponse,
        getTableSettings,
        onDragStart,
        onDragEnd,
        onDragUpdate,
        hoverPosition,
        startPosition,
        columnSettingsResponse,
        handleResetSettings,
    } = useDamagesSettings();

    useEffect(() => {
        if (!location.state?.dontResetDamageListContext) {
            resetDamagesContext(defaultDamageListContextFormValues);
            handleChangeSort({ sortModel: [] } as GridSortModelParams);
        }
    }, []);

    const handleFilter = (_: SyntheticEvent, newFilter: string): void => {
        if (newFilter) {
            history.push(`?filterValue=${newFilter}`);
        }
    };

    const onSearchSubmit = (): void => undefined;

    const handleGoToTheDamageReport = (): void => {
        history.push(APP_ROUTES.DAMAGE_REPORT);
    };

    const handleCloseColumnSettings = async (): Promise<void> => {
        try {
            await updateTableSettings(
                columnsSettingsList.sort((a, b) =>
                    a?.data?.position > b?.data?.position ? 1 : -1
                ),
                filterValue === DamageStatuses.CREATED ? 'DamageReports' : 'Damages'
            );
        } finally {
            const data = await getTableSettings(
                filterValue === DamageStatuses.CREATED ? 'DamageReports' : 'Damages'
            );
            setColumnSettingsResponse({
                columns: data.columns?.sort((a, b) => (a.position > b.position ? 1 : -1)),
            });
        }
    };

    const handleColumnSettingsList = useCallback(
        (newSettingsList: CheckboxItem[]): void => {
            setColumnsSettingsList(newSettingsList);
        },
        [setColumnsSettingsList, filterValue]
    );

    const toggleButtons = useMemo(() => generateDamageListToggleButtons(t), [t]);

    const allowedClientConnections = useMemo(
        () => globalSearchListGetAllowedFilterValues(filter.customerConnections),
        [filter.customerConnections]
    );
    const allowedClients = useMemo(
        () => globalSearchListGetAllowedFilterValues(filter.customers),
        [filter.customers]
    );

    const damagesSearchResults = useDamagesDetailsSearch(
        formState.isSubmitted,
        search,
        allowedClientConnections,
        allowedClients
    );

    useEffect(() => {
        if (
            Array.isArray(damagesSearchResults.searchResults?.result) &&
            damagesSearchResults.searchResults.result?.length !== 0
        ) {
            if (damagesSearchResults.searchResults.result.length === 1) {
                history.push(
                    `${APP_ROUTES.DAMAGES}/${damagesSearchResults.searchResults.result[0].amsIdNr}`
                );
            } else if (damagesSearchResults.searchResults.result.length > 1) {
                history.push(APP_ROUTES.GLOBAL_SEARCH, {
                    type: SearchOptions.DAMAGE,
                    globalSearchTerm: search,
                });
            }
        } else {
            reset({
                search: '',
            });
        }
    }, [damagesSearchResults.searchResults]);

    let tableName;

    if (filterValue === DamageStatuses.CREATED) {
        tableName = 'DamageReports';
    } else if (
        filterValue === DamageStatuses.OPEN ||
        filterValue === DamageStatuses.CLOSED
    ) {
        tableName = 'Damages';
    }

    return (
        <Grid container wrap='nowrap' className={classes.contractsListContainer}>
            <Grid
                container
                item
                component='form'
                className={classes.toolBar}
                onSubmit={handleSubmit(onSearchSubmit)}
            >
                <ContractsNavigationPanel activeMenu='damages' />
                <div style={{ flexGrow: 1 }} />

                <Can
                    passThrough
                    I={AbilityActions.create}
                    a={AbilitySubjects.damageReport}
                >
                    {(allowed: boolean) =>
                        allowed && (
                            <Box mr={2}>
                                <Button
                                    disableElevation
                                    color='secondary'
                                    variant='contained'
                                    className={classes.containedButtons}
                                    onClick={handleGoToTheDamageReport}
                                >
                                    {t('damages-list:damageReport')}
                                </Button>
                            </Box>
                        )
                    }
                </Can>

                <div className={classes.searchFieldContainer}>
                    <ThemeSearchField
                        disabled={damagesSearchResults.searchLoading}
                        isLoading={damagesSearchResults.searchLoading}
                        name='search'
                        value={search}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setValue('search', e.target.value)
                        }
                    />
                </div>
            </Grid>
            <Grid item className={classes.divisionsContainer}>
                {Array.isArray(userData.divisions) && userData.divisions.length > 1 && (
                    <DivisionsSection
                        editMode={isDivisionsEdit}
                        setEditMode={setDivisionsEdit}
                        activeDivision={division}
                        handleOnClickCard={changeDivision}
                    />
                )}
            </Grid>
            <Grid item container className={classes.contentContainer}>
                <Grid item container className={classes.settingsContainer}>
                    <Typography variant='h5'>{t('damages-list:title')}</Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Grid item container className={classes.settings}>
                        {!isDragAndDropLocked && (
                            <Box mr={2}>
                                <Button
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isColumnSettingsLoading}
                                        />
                                    }
                                    disabled={isColumnSettingsLoading}
                                    className={classes.resetSettingsButton}
                                    onClick={() => handleResetSettings(tableName)}
                                >
                                    {t('resetSettings')}
                                </Button>
                            </Box>
                        )}
                        <IconButton
                            className={classes.settingButton}
                            onClick={() => setDragAndDropLocked(!isDragAndDropLocked)}
                        >
                            {isDragAndDropLocked ? (
                                <CurrentLockIcon />
                            ) : (
                                <CurrentLockOpenIcon />
                            )}
                        </IconButton>
                        <ThemeCheckboxesDropdown
                            withoutBadge
                            buttonIcon={<CurrentSettingsIcon />}
                            itemsList={columnsSettingsList}
                            setItemsList={handleColumnSettingsList}
                            iconButtonClass={classes.settingButton}
                            onCloseHandler={handleCloseColumnSettings}
                        />
                        <ThemeToggleButtons
                            value={filterValue}
                            handleChange={handleFilter}
                            buttonsList={toggleButtons}
                        />
                    </Grid>
                </Grid>
                <DragDropContext
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragUpdate={onDragUpdate}
                >
                    <Droppable droppableId='contracts-list' direction='horizontal'>
                        {(provided) => (
                            <Grid
                                item
                                className={classes.tableContainer}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {isColumnSettingsLoading && <ThemeLoader />}
                                {filterValue === DamageStatuses.CREATED &&
                                    !isColumnSettingsLoading && (
                                        <DamageListWithCreatedStatus
                                            columnSettingsResponse={
                                                columnSettingsResponse
                                            }
                                            columnsSettingsList={columnsSettingsList}
                                            division={division}
                                            isDragAndDropLocked={isDragAndDropLocked}
                                            hoverPosition={hoverPosition}
                                            startPosition={startPosition}
                                        />
                                    )}
                                {(filterValue === DamageStatuses.OPEN ||
                                    filterValue === DamageStatuses.CLOSED) &&
                                    !isColumnSettingsLoading && (
                                        <DamagesListOpenClosedStatusesTable
                                            columnSettingsResponse={
                                                columnSettingsResponse
                                            }
                                            isDragAndDropLocked={isDragAndDropLocked}
                                            hoverPosition={hoverPosition}
                                            startPosition={startPosition}
                                            division={division}
                                            columnsSettingsList={columnsSettingsList}
                                            filterValue={filterValue}
                                            setNeedAdditionalColumnsForKFZW={
                                                setNeedAdditionalColumnsForKFZW
                                            }
                                        />
                                    )}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Grid>
    );
};
