import { makeStyles, Theme } from '@material-ui/core';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        thirdPart: {
            width: `${100 / 3}%`,
        },
        fullWidth: {
            width: '100%',
        },
        checkboxesGroup: {
            flexDirection: 'row',
            marginLeft: -6,
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
            },
        },
        checkboxesGroupLabel: {
            fontSize: '1.4rem',
            '&.Mui-focused': {
                color: theme.palette.info.main,
            },
        },
        checkboxIcon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: '16px!important',
            height: '16px!important',
            color: `${theme.palette.info.main}!important`,
            opacity: `${1}!important`,
        },
        icon: {
            width: 20,
            height: 20,
            color: theme.palette.primary.contrastText,
            opacity: 0.6,
        },
        checkboxesFormControl: {
            width: '100%',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        checkboxItemLabel: {
            width: '25%',
            margin: 0,
        },
        currencyField: {
            '& .MuiInputBase-root': {
                height: 40,
            },
        },
        textField: {
            color: theme.palette.primary.contrastText,
            opacity: 0.6,
        },
        additionalLabel: {
            margin: 0,
            marginTop: theme.spacing(2),
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return `${
                            isDarkMode
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light
                        }!important`;
                    }
                    return `${theme.palette.primary.light}!important`;
                },
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.main,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
            '&>div': {
                width: '100%',
                '& .MuiFormControl-root': {
                    width: '100%',
                },
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
