import { makeStyles, Theme } from '@material-ui/core/styles';

import { THEME_MODES } from 'src/theming';
import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { getBackgroundGradient } from 'src/contracts/contracts-dashboard/contract-dashboard-styles/contract-dashboard-hector-styles';

export const useStyle = makeStyles((theme: Theme) => {
    const isLightMode = THEME_MODES.LIGHT === theme.palette.type;
    return {
        mainContainer: {
            padding: () => {
                return `${theme.spacing(5)}px 140px`;
            },
        },
        header: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down(
                isHectorStyles ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                    ? 'lg'
                    : 'md'
            )]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(1)}px!important`,
            },
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            color: theme.palette.primary.contrastText,
        },
        configSection: {
            width: '100%',
            flexGrow: 1,
            gap: `${theme.spacing(4)}px`,
            flexWrap: 'nowrap',
        },
        configSubSection: {
            marginBottom: theme.spacing(8),
        },
        pageContainer: {
            height: '100%',
            alignContent: 'flex-start',
            padding: '40px',
            backgroundColor: theme.palette.primary.main,
        },
        saveButton: {
            backgroundColor: theme.palette.info.light,
            '& .MuiCircularProgress-root': {
                color: theme.palette.info.main,
            },
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '40%',
            margin: () => {
                if (
                    isSGIFFOXXKInstance ||
                    isHectorStyles ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                ) {
                    return `0 ${theme.spacing(4)}px 0 0`;
                }
                return 0;
            },
            '& label': {
                fontSize: '2.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                margin: () => {
                    if (
                        isSGIFFOXXKInstance ||
                        isHectorStyles ||
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return `0 ${theme.spacing(1)}px 0 0`;
                    }
                },
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor:
                THEME_MODES.DARK === theme.palette.type
                    ? theme.palette.primary.main
                    : theme.palette.primary.light,
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});
