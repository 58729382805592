import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { AppInstances } from 'src/shared/constants';
import { appInstance } from 'src/environment';

import {
    useGlobalSearchAutocompliteFilterAonMotorStyles,
    useGlobalAutocompleteSearchBadgeAonMotorStyles,
} from './GlobalSearchAutocompliteFilterAONMotorStyles';
import {
    useGlobalAutocompleteSearchBadgeAonStyles,
    useGlobalSearchAutocompliteFilterAonStyles,
} from './GlobalSearchAutocompliteFilterAONStyles';
import {
    useGlobalAutocompleteSearchBadgeCeatStyles,
    useGlobalSearchAutocompliteFilterCeatStyles,
} from './GlobalSearchAutocompliteFilterCEATStyles';

import {
    useGlobalAutocompleteSearchBadgeHectorStyles,
    useGlobalSearchAutocompliteFilterHectorStyles,
} from './GlobalSearchAutocompliteFilterHectorStyles';
import {
    useGlobalAutocompleteSearchBadgeSGIFFOXXKStyles,
    useGlobalSearchAutocompliteFilterSGIFFOXXKStyles,
} from './GlobalSearchAutocompliteFilterSGIFFOXXKStyles';

const instanceGlobalSearchAutocompliteFilterStylesHooks = {
    [AppInstances.AON]: useGlobalSearchAutocompliteFilterAonStyles,
    [AppInstances.AON_KOMPOSIT]: useGlobalSearchAutocompliteFilterAonStyles,
    [AppInstances.HECTOR]: useGlobalSearchAutocompliteFilterHectorStyles,
    [AppInstances.MAAS]: useGlobalSearchAutocompliteFilterHectorStyles,
    [AppInstances.CEAT]: useGlobalSearchAutocompliteFilterCeatStyles,
    [AppInstances.CEAT_MAKLER]: useGlobalSearchAutocompliteFilterCeatStyles,
    [AppInstances.SGIFFOXXK]: useGlobalSearchAutocompliteFilterSGIFFOXXKStyles,
    [AppInstances.AGRAVIS]: useGlobalSearchAutocompliteFilterSGIFFOXXKStyles,
    [AppInstances.AON_MOTOR]: useGlobalSearchAutocompliteFilterAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useGlobalSearchAutocompliteFilterAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useGlobalSearchAutocompliteFilterAonMotorStyles,
};

const instanceGlobalSearchAutocompliteSearchBadgeStylesHooks = {
    [AppInstances.AON]: useGlobalAutocompleteSearchBadgeAonStyles,
    [AppInstances.AON_KOMPOSIT]: useGlobalAutocompleteSearchBadgeAonStyles,
    [AppInstances.HECTOR]: useGlobalAutocompleteSearchBadgeHectorStyles,
    [AppInstances.MAAS]: useGlobalAutocompleteSearchBadgeHectorStyles,
    [AppInstances.CEAT]: useGlobalAutocompleteSearchBadgeCeatStyles,
    [AppInstances.CEAT_MAKLER]: useGlobalAutocompleteSearchBadgeCeatStyles,
    [AppInstances.SGIFFOXXK]: useGlobalAutocompleteSearchBadgeSGIFFOXXKStyles,
    [AppInstances.AGRAVIS]: useGlobalAutocompleteSearchBadgeSGIFFOXXKStyles,
    [AppInstances.AON_MOTOR]: useGlobalAutocompleteSearchBadgeAonMotorStyles,
    [AppInstances.AON_DIGITAL]: useGlobalAutocompleteSearchBadgeAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useGlobalAutocompleteSearchBadgeAonMotorStyles,
};

export const useGlobalSearchAutocompliteFilterStyles = (): ClassNameMap => {
    const useThemeCurrentStyles =
        instanceGlobalSearchAutocompliteFilterStylesHooks[appInstance] ||
        useGlobalSearchAutocompliteFilterAonStyles;
    return useThemeCurrentStyles();
};

export const useGlobalAutocompleteSearchBadgeStyles = (): ClassNameMap => {
    const useThemeCurrentStyles =
        instanceGlobalSearchAutocompliteSearchBadgeStylesHooks[appInstance] ||
        useGlobalAutocompleteSearchBadgeAonStyles;
    return useThemeCurrentStyles();
};
