import { CustomerAddingFormData } from './customer-add-form-types';
import { CustomerDetailsViewDTO } from '../customer-details-view/customer-details-view-dto';

export const customerAddFormGetCustomerFormatter = (
    data: CustomerDetailsViewDTO
): CustomerAddingFormData => {
    return {
        id: data?.amsIdNr,
        name: data?.name,
        street: data?.street,
        postCode: data?.postcode,
        city: data?.city,
        countryCode: data?.countryCode,
        customers: [],
        customerName: data?.matchCode,
    };
};
