import { makeStyles, Theme } from '@material-ui/core';
import {
    isHectorInstance,
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isMaasInstance,
    isHectorStyles,
} from 'src/environment';
import { THEME_MODES } from '../themes';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;

    if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
        return {
            listMenuContainer: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
            menuTitle: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.primary.light,
                borderBottom: `1px solid ${theme.palette.grey.A100}`,
            },
            activeListItem: {
                backgroundColor: isDarkMode
                    ? `${theme.palette.primary.dark}!important`
                    : `${theme.palette.secondary.main}!important`,
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.secondary.contrastText}!important`,
                },
            },
            pagination: {
                padding: `0!important`,
                '& .MuiTablePagination-toolbar': {
                    padding: 0,
                },
            },
            noDataMessage: {
                fontSize: '1.4rem',
            },
        };
    }
    if (isHectorStyles) {
        return {
            listMenuContainer: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
            menuTitle: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.primary.light,
                borderBottom: `1px solid ${theme.palette.grey.A100}`,
            },
            activeListItem: {
                backgroundColor: `${theme.palette.secondary.light}!important`,
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.info.main}!important`,
                },
            },
            pagination: {
                padding: `0!important`,
                '& .MuiTablePagination-toolbar': {
                    padding: 0,
                },
            },
            noDataMessage: {
                fontSize: '1.4rem',
            },
        };
    }
    return {
        listMenuContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        menuTitle: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            borderBottom: `1px solid ${theme.palette.grey.A100}`,
        },
        activeListItem: {
            backgroundColor: `${theme.palette.info.light}!important`,
            '& .MuiSvgIcon-root': {
                color: () => {
                    if (isSGIFFOXXKInstance) {
                        return `${theme.palette.info.contrastText}!important`;
                    }
                    return `${theme.palette.info.main}!important`;
                },
            },
        },
        pagination: {
            padding: `0!important`,
            '& .MuiTablePagination-toolbar': {
                padding: 0,
            },
        },
        noDataMessage: {
            fontSize: '1.4rem',
        },
    };
});

export const useListStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            maxHeight: ({ listHeight }: { listHeight: number }) => `${listHeight}px`,
            minHeight: 400,
            padding: 0,
            overflowY: 'auto',
            backgroundColor: theme.palette.primary.main,
        },
    };
});

export const useListItemStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
        return {
            root: {
                backgroundColor: theme.palette.primary.main,
                transition: '0.2s',
                padding: `${theme.spacing(2)}px`,
                borderBottom: `1px solid ${theme.palette.grey.A100}`,
                '&:nth-child(even)': {
                    backgroundColor: theme.palette.primary.light,
                },
                '&:hover': {
                    cursor: ({ isMenuListDisabled }: { isMenuListDisabled: boolean }) =>
                        isMenuListDisabled ? 'cursor' : 'pointer',
                    backgroundColor: ({
                        isMenuListDisabled,
                    }: {
                        isMenuListDisabled: boolean;
                    }) => {
                        if (isMenuListDisabled) {
                            return null;
                        }
                        if (
                            isAonMotorInstance ||
                            isAonDigitalInstance ||
                            isHectorDemoInstance
                        ) {
                            return isDarkMode
                                ? `${theme.palette.primary.dark}`
                                : `${theme.palette.secondary.light}`;
                        }
                        return `${theme.palette.secondary.light}`;
                    },
                },
            },
        };
    }
    if (isHectorInstance || isMaasInstance) {
        return {
            root: {
                backgroundColor: theme.palette.primary.main,
                transition: '0.2s',
                padding: `${theme.spacing(2)}px`,
                borderBottom: `1px solid ${theme.palette.grey.A100}`,
                '&:nth-child(even)': {
                    backgroundColor: theme.palette.primary.light,
                },
                '&:hover': {
                    cursor: ({ isMenuListDisabled }: { isMenuListDisabled: boolean }) =>
                        isMenuListDisabled ? 'cursor' : 'pointer',
                    backgroundColor: ({
                        isMenuListDisabled,
                    }: {
                        isMenuListDisabled: boolean;
                    }) =>
                        isMenuListDisabled ? null : `${theme.palette.secondary.light}`,
                },
            },
        };
    }
    return {
        root: {
            backgroundColor: theme.palette.primary.main,
            transition: '0.2s',
            padding: `${theme.spacing(2)}px`,
            borderBottom: `1px solid ${theme.palette.grey.A100}`,
            '&:nth-child(even)': {
                backgroundColor: theme.palette.primary.light,
            },
            '&:hover': {
                cursor: ({ isMenuListDisabled }: { isMenuListDisabled: boolean }) =>
                    isMenuListDisabled ? 'cursor' : 'pointer',
                backgroundColor: ({
                    isMenuListDisabled,
                }: {
                    isMenuListDisabled: boolean;
                }) => (isMenuListDisabled ? null : `${theme.palette.info.light}`),
            },
        },
    };
});

export const useListItemTextStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            '& .MuiTypography-root': {
                fontSize: '1.2rem',
                color: theme.palette.primary.contrastText,
                textDecoration: 'underline',
            },
        },
    };
});

export const useListItemIconStyle = makeStyles((theme: Theme) => {
    return {
        root: {
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                color: theme.palette.grey.A200,
            },
        },
    };
});
