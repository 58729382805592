import { TFunction } from 'i18next';

interface PrivilegesRow {
    id: string;
    fieldName: string;
    adminTechnical: string;
    adminDomain: string;
    customerAdviser: string;
    accountOwner: string;
    administrator: string;
    clerk: string;
    insurance: string;
    supplier: string;
    presentation: string;
}

export const generatePrivilegesData = (t: TFunction): PrivilegesRow[] => {
    return [
        {
            id: '1',
            fieldName: t('user-management:privilegesSection.table.name'),
            adminTechnical: 'none',
            adminDomain: 'none',
            customerAdviser: 'none',
            accountOwner: 'none',
            administrator: 'none',
            clerk: 'none',
            insurance: 'none',
            supplier: 'none',
            presentation: 'none',
        },
        {
            id: '2',
            fieldName: t('user-management:privilegesSection.table.clientNumber'),
            adminTechnical: 'none',
            adminDomain: 'none',
            customerAdviser: 'none',
            accountOwner: 'none',
            administrator: 'none',
            clerk: 'none',
            insurance: 'none',
            supplier: 'none',
            presentation: 'none',
        },
        {
            id: '3',
            fieldName: t('user-management:privilegesSection.table.address'),
            adminTechnical: 'none',
            adminDomain: 'none',
            customerAdviser: 'none',
            accountOwner: 'none',
            administrator: 'none',
            clerk: 'none',
            insurance: 'none',
            supplier: 'none',
            presentation: 'none',
        },
        {
            id: '4',
            fieldName: t('user-management:privilegesSection.table.contractNumber'),
            adminTechnical: 'none',
            adminDomain: 'none',
            customerAdviser: 'none',
            accountOwner: 'none',
            administrator: 'none',
            clerk: 'none',
            insurance: 'none',
            supplier: 'none',
            presentation: 'none',
        },
        {
            id: '5',
            fieldName: t('user-management:privilegesSection.table.phoneNumber'),
            adminTechnical: 'none',
            adminDomain: 'none',
            customerAdviser: 'none',
            accountOwner: 'none',
            administrator: 'none',
            clerk: 'none',
            insurance: 'none',
            supplier: 'none',
            presentation: 'none',
        },
    ];
};
