import React, { BaseSyntheticEvent, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { Grid, IconButton } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { FormElement } from '../../damage-report-form-config/damage-report-form-config-GBW-HR-GESCH';

import { useDamageReportFormDynamicFieldsRowStyles } from './damage-report-form-dynimic-fields-row-styles';

const generateDefaultStateForRow = (
    formElements: FormElement[]
): { [key: string]: unknown } => {
    const defaultState: { [key: string]: unknown } = {};
    formElements?.forEach((item) => {
        defaultState[item.name] = item.value;
    });
    return defaultState;
};

interface Props {
    rows: { id: string; formElements: FormElement[] }[];
    handleAddRow: () => void;
    handleDeleteRow: (id: string) => void;
    handleOnChangeRow: (
        id: string,
        row: { id: string; formElements: FormElement[] }
    ) => void;
}

const Row = ({
    rowsLength,
    index,
    row,
    id,
    handleAddRow,
    handleDeleteRow,
    handleOnChangeRow,
}): JSX.Element => {
    const { t } = useTranslation();
    const classes = useDamageReportFormDynamicFieldsRowStyles();

    const { setValue, getValues, watch, reset } = useForm({
        defaultValues: generateDefaultStateForRow(row?.formElments),
    });

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        setValue(e.target.name, e.target.value);
    };

    const names = row.formElements.map(({ name }) => name);

    const values = watch(names);

    useEffect(() => {
        if (row) {
            reset(generateDefaultStateForRow(row.formElements));
        }
    }, [row]);

    const onBlur = (): void => {
        const row: { id: string; [key: string]: string | number } = {
            id,
        };
        names.forEach((item, index) => {
            row[item] = values[index];
        });
        handleOnChangeRow(id, row);
    };

    return (
        <>
            <Grid
                item
                container
                spacing={2}
                key={row.id}
                alignItems='center'
                wrap='nowrap'
            >
                <Grid container item justify='space-between' spacing={2} md={11}>
                    {row.formElements.map(({ Item, name, label }) => {
                        const valueFromInnerState = getValues(name);
                        return (
                            <Item
                                key={`${name}-${row.id}`}
                                name={name}
                                label={t(label)}
                                value={valueFromInnerState}
                                handleChangeValue={handleChangeValue}
                                onBlur={onBlur}
                            />
                        );
                    })}
                </Grid>
                {handleDeleteRow && rowsLength > 1 && (
                    <IconButton
                        onClick={() => handleDeleteRow(row.id)}
                        className={clsx(classes.iconButton, classes.iconDeleteButton)}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
                {index === 0 && handleAddRow && (
                    <IconButton
                        onClick={handleAddRow}
                        className={clsx(classes.iconButton, classes.iconAddButton)}
                    >
                        <AddIcon />
                    </IconButton>
                )}
            </Grid>
        </>
    );
};

export const DamageReportFormDynamicFieldsRow = memo(
    ({ rows, handleAddRow, handleDeleteRow, handleOnChangeRow }: Props): JSX.Element => {
        if (!Array.isArray(rows)) {
            return null;
        }

        return (
            <>
                {rows.map((row, index, arr) => {
                    return (
                        <Row
                            key={row.id}
                            index={index}
                            row={row}
                            id={row.id}
                            rowsLength={arr.length}
                            handleAddRow={handleAddRow}
                            handleDeleteRow={handleDeleteRow}
                            handleOnChangeRow={handleOnChangeRow}
                        />
                    );
                })}
            </>
        );
    }
);
