import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
interface DummyContractCreationHandleErrors {
    handleErrors: (err: AxiosError) => void;
}

export const useDummyContractCreationHandleErrors =
    (): DummyContractCreationHandleErrors => {
        const { t } = useTranslation(['create-contract']);
        const { enqueueSnackbar } = useSnackbar();

        const handleErrors = (err: AxiosError): void => {
            err.response?.data.errors.ContractNumber.forEach((item) => {
                enqueueSnackbar(t(`errors.${item}`), { variant: 'error' });
            });
        };

        return { handleErrors };
    };
