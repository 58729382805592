import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { DarkMode } from 'use-dark-mode';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import {
    Button,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import { ThemeCircularProgress } from 'src/theming';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import PasswordStrengthBar from 'src/lib/password-strength-bar/password-strength-bar';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { APP_ROUTES } from 'src/routing';

import { useQuery } from 'src/shared/hooks';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

import { createPasswordSchema } from './create-password-form-validation-schema';

import {
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
    useStyle,
} from '../styles';

interface CreatePasswordFormProps {
    darkMode: DarkMode;
}

type CreatePasswordFormData = {
    password: string;
    repeatPassword: string;
};

export const CreatePasswordForm = (props: CreatePasswordFormProps): JSX.Element => {
    const { darkMode } = props;

    const classes = useStyle({ isDarkMode: darkMode.value });
    const inputClasses = useInputStyles();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const { t } = useTranslation(['user-management', 'common', 'password']);

    const [isStrong, setIsStrong] = useState(false);
    const [isRequestLoading, setRequestLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const httpClient = useHttpClient();
    const router = useHistory();

    const token = useQuery().get('token');
    const type = useQuery().get('type');

    const {
        watch,
        register,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<CreatePasswordFormData>({
        mode: 'onChange',
        resolver: yupResolver(createPasswordSchema),
    });

    const onSubmit = async (vals: CreatePasswordFormData): Promise<void> => {
        try {
            setRequestLoading(true);
            await httpClient.put(
                type === 'reset' ? 'users/confirm-reset-password' : 'users/confirmEmail',
                {
                    token,
                    password: vals.password,
                }
            );
            setRequestLoading(false);
            enqueueSnackbar(
                t('user-management:successResponses.passwordCreatedSuccessfully'),
                { variant: 'success' }
            );
            router.push(APP_ROUTES.LOGIN);
        } catch (err) {
            setRequestLoading(false);
        }
    };

    const password = watch('password');
    const debouncedPassword = useDebounce(password, 400);

    const handleSetStrong = useCallback((value) => {
        setIsStrong(value);
    }, []);

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formContainer}
        >
            <Typography variant='h3' className={classes.formTitle}>
                {t('createPassword')}
            </Typography>
            <Typography variant='body1' className={classes.formMessage}>
                {t('createPasswordMessage')}
            </Typography>
            {!isHectorDemoInstance &&
                !isHectorStyles &&
                !isAonDigitalInstance &&
                !isAonMotorInstance && <hr />}
            <FormControl
                error={Boolean(errors?.password)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('placeholders.enterNewPassword')}
                            classes={inputClasses}
                            {...register('password')}
                        />
                    }
                    label={t('fields.password')}
                    labelPlacement='top'
                />
            </FormControl>
            <PasswordStrengthBar
                className={classes.strengthBar}
                password={debouncedPassword}
                onStrong={handleSetStrong}
                pathToCheckPassword='users/check-password-strength'
            />
            <FormControl
                error={Boolean(errors?.repeatPassword)}
                fullWidth
                classes={formControlClasses}
                variant='outlined'
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <OutlinedInput
                            type='password'
                            placeholder={t('placeholders.repeatNewPassword')}
                            classes={inputClasses}
                            {...register('repeatPassword')}
                        />
                    }
                    label={t('fields.confirmPassword')}
                    labelPlacement='top'
                />
            </FormControl>
            <Button
                disableElevation
                disabled={!isValid || !isStrong || isRequestLoading}
                startIcon={<ThemeCircularProgress isLoading={isRequestLoading} />}
                className={classes.submitButton}
                variant='contained'
                type='submit'
            >
                {t('createPassword')}
            </Button>
        </form>
    );
};
