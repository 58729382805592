import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isSGIFFOXXKInstance,
    isHectorDemoInstance,
    isHectorStyles,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        header: {
            alignItems: 'center',
        },
        formTitle: {
            width: 'fit-content',
        },
        formControlButtons: {
            width: 'fit-content',
            marginLeft: 'auto',
            '& button': {
                height: 40,
                transition: '0.2s',
                '&:not(:last-child)': {
                    marginRight: theme.spacing(2),
                },
            },
        },
        containedButton: {
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.dark;
                }
                return isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main;
            },
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.dark;
                    }
                    return isDarkMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                },
                opacity: 0.8,
            },
        },
        outlinedButton: {
            borderColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
            '&:hover': {
                borderColor: isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
                opacity: 0.8,
            },
        },
        addCustomerButton: {
            marginTop: theme.spacing(2),
            color: theme.palette.info.main,
        },
        editButton: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return theme.palette.secondary.dark;
                }
                if (isHectorStyles) {
                    return theme.palette.info.main;
                }
                return theme.palette.info.light;
            },
            color: () => {
                if (
                    isHectorStyles ||
                    isSGIFFOXXKInstance ||
                    isAonDigitalInstance ||
                    isAonMotorInstance ||
                    isHectorDemoInstance
                ) {
                    return theme.palette.secondary.contrastText;
                }
                return isDarkMode
                    ? theme.palette.info.contrastText
                    : theme.palette.info.main;
            },
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.secondary.dark;
                    }
                    if (isHectorStyles) {
                        return theme.palette.info.main;
                    }
                    return theme.palette.info.light;
                },
            },
            '& .MuiSvgIcon-root': {
                color: () => {
                    if (isSGIFFOXXKInstance) {
                        return theme.palette.secondary.contrastText;
                    }
                },
            },
        },
        staticFieldsContainer: {
            gap: theme.spacing(2),
            paddingTop: theme.spacing(4),
        },
        switchContainer: {
            display: 'flex',
            height: 40,
            alignItems: 'center',
        },
        dynamicFieldsContainer: {
            width: '100%',
            marginTop: theme.spacing(3),
            borderTop: `1px solid ${theme.palette.grey[isDarkMode ? '400' : '100']}`,
        },
        deleteUserButton: {
            width: 30,
            height: 30,
            marginLeft: theme.spacing(2),
            borderRadius: 4,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        okButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return theme.palette.info.main;
                }
                return isDarkMode
                    ? theme.palette.info.main
                    : theme.palette.secondary.main;
            },
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: () => {
                    if (
                        isAonMotorInstance ||
                        isAonDigitalInstance ||
                        isHectorDemoInstance
                    ) {
                        return theme.palette.info.main;
                    }
                    return isDarkMode
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                },
            },
        },
        cancelButton: {
            borderColor: isDarkMode
                ? theme.palette.info.main
                : theme.palette.secondary.main,
        },
        modalMessage: {
            maxWidth: 500,
        },
        customerAutocompleteAssigning: {
            marginLeft: 0,
            '& .MuiFormControlLabel-label': {
                fontSize: '1.4rem',
            },
        },
    };
});

export const useFormControlStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
            // margin: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
            '& label': {
                fontSize: '2.4rem',
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            fontSize: '1.4rem',
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            color: theme.palette.primary.contrastText,
            textOverflow: 'ellipsis',
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'flex-start',
            margin: 0,
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
            color: theme.palette.primary.contrastText,
        },
        disabled: {
            '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

export const useCardStyle = makeStyles((theme: Theme) => {
    return {
        cardContainer: {
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        formControl: {
            width: '100%',
            margin: theme.spacing(3),
            marginLeft: 0,
            '& .MuiFormLabel-root': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiFormLabel-root, span': {
                fontSize: '1.4rem',
                whiteSpace: 'nowrap',
                color: theme.palette.primary.contrastText,
                '&.Mui-focused': {
                    color: theme.palette.info.main,
                },
            },
            '& .MuiFormControlLabel-root.Mui-disabled .MuiSvgIcon-root': {
                color: theme.palette.grey.A100,
            },
            '& .MuiFormControlLabel-root .MuiButtonBase-root': {
                padding: theme.spacing(1),
            },
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            width: 16,
            height: 16,
            color: theme.palette.secondary.main,
            opacity: 0.6,
        },
        checkedIcon: {
            width: 16,
            height: 16,
            color: theme.palette.info.main,
            opacity: 1,
        },
        deleteCardButton: {
            width: 30,
            height: 30,
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(7),
            borderRadius: 4,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        ccGroup: {
            padding: '1rem',
            borderBottom: `1px solid ${theme.palette.grey['200']}`,
        },
    };
});
