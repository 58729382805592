import React, { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, GridSize } from '@material-ui/core';

import { ThemeOutlineInput } from 'src/theming';

import {
    useFormControlStyles,
    useFormControlLabelStyles,
    useInputStyles,
} from '../contracts-create-contract-form-styles';

interface TextFieldProps {
    name: string;
    value: string;
    label: string;
    handleChangeValue: (name, value) => void;
    md: GridSize;
    error?: boolean;
}

export const TextField = ({
    md,
    label,
    value,
    name,
    error,
    handleChangeValue,
}: TextFieldProps): JSX.Element => {
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();

    const { t } = useTranslation(['create-contract']);

    const onChange = (e: BaseSyntheticEvent): void => {
        handleChangeValue(e.target.name, e.target.value);
    };

    return (
        <Grid item md={md}>
            <ThemeOutlineInput
                inputClasses={inputClasses}
                value={value}
                label={t(label)}
                formControlLabelClasses={formControlLabelClasses}
                formControlClasses={formControlClasses}
                handleChangeValue={onChange}
                name={name}
                error={error}
            />
        </Grid>
    );
};
