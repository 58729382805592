import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@material-ui/lab';

import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { ThemeAutocomplete, ThemeAutocompleteOptions } from '..';

import { useGlobalFilter } from 'src/shared/contexts';

import { useStyle } from './theme-header-styles/theme-header-styles';
import { splitOptionsToSelectedAndNotSelected } from './utils';
import { useQuery } from '../../shared/hooks';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: ThemeAutocompleteOptions) =>
        option.title + option.additionalTitles.join(''),
});

export const ThemeHeaderMainPortalGlobalFilters = (): JSX.Element => {
    const classes = useStyle();
    const theme = useTheme();

    const matchesUpLargeSize = useMediaQuery(theme.breakpoints.up('lg'));
    const { t } = useTranslation('common');

    const globalFilter = useGlobalFilter();

    const onChange = (name, value): void => {
        switch (name) {
            case 'clientConnections': {
                globalFilter.changeRawCustomerConnections(
                    splitOptionsToSelectedAndNotSelected(value)
                );
                break;
            }
            case 'clients': {
                globalFilter.changeRawCustomers(
                    splitOptionsToSelectedAndNotSelected(value)
                );
                break;
            }
            default: {
                return;
            }
        }
    };

    const customers = useMemo(() => {
        const result = splitOptionsToSelectedAndNotSelected([
            ...globalFilter.rawCustomers,
        ]);

        return result;
    }, [globalFilter.rawCustomers]);

    const customerConnections = useMemo(() => {
        const result = splitOptionsToSelectedAndNotSelected([
            ...globalFilter.rawCustomerConnections,
        ]);
        return result;
    }, [globalFilter.rawCustomerConnections]);

    const callbackAfterCrClose = (): void => {
        globalFilter.changeCustomerConnectionsSearchTerm('');
        const atLeastOneCustomerConnectionSelected =
            globalFilter.rawCustomerConnections.some((item) => item.checked);

        const currentCRRaw = atLeastOneCustomerConnectionSelected
            ? globalFilter.rawCustomerConnections
            : globalFilter.rawCustomerConnections.map((item, index) => {
                  if (index === 1) {
                      return {
                          ...item,
                          checked: true,
                      };
                  }
                  return item;
              });
        if (!atLeastOneCustomerConnectionSelected) {
            globalFilter.changeRawCustomerConnections(currentCRRaw);
        }
        globalFilter.applyFilter(currentCRRaw, globalFilter.rawCustomers);
    };

    const callbackAfterCustomerClose = (): void => {
        globalFilter.changeCustomersSearchTerm('');
        const atLeastOneCustomerSelected = globalFilter.rawCustomers.some(
            (item) => item.checked
        );
        const currentCustomersRaw = atLeastOneCustomerSelected
            ? globalFilter.rawCustomers
            : globalFilter.rawCustomers.map((item, index) => {
                  if (index === 1) {
                      return {
                          ...item,
                          checked: true,
                      };
                  }
                  return item;
              });
        if (!atLeastOneCustomerSelected) {
            globalFilter.changeRawCustomers(currentCustomersRaw);
        }
        globalFilter.applyFilter(
            globalFilter.rawCustomerConnections,
            currentCustomersRaw
        );
    };

    return (
        <>
            <Grid item container className={classes.departmentFiltersContainer}>
                <Grid item className={classes.departmentFilter}>
                    {globalFilter.rawCustomerConnections && (
                        <ThemeAutocomplete
                            multiple
                            name='clientConnections'
                            inputValue={globalFilter.customerConnectionsSearchTerm}
                            onInputChange={(value, reason) => {
                                if (reason === 'input') {
                                    return globalFilter.changeCustomerConnectionsSearchTerm(
                                        value
                                    );
                                }
                            }}
                            title={t('clientConnection')}
                            options={customerConnections}
                            triggerExternalCallback={callbackAfterCrClose}
                            onChange={onChange}
                            filterOptions={filterOptions}
                        />
                    )}
                </Grid>
                <Grid item className={classes.departmentFilter}>
                    {globalFilter.rawCustomers && (
                        <ThemeAutocomplete
                            multiple
                            name='clients'
                            inputValue={globalFilter.customersSearchTerm}
                            onInputChange={(value, reason) => {
                                if (reason === 'input') {
                                    return globalFilter.changeCustomersSearchTerm(value);
                                }
                            }}
                            title={t('client')}
                            options={customers}
                            triggerExternalCallback={callbackAfterCustomerClose}
                            onChange={onChange}
                            filterOptions={filterOptions}
                        />
                    )}
                </Grid>
            </Grid>
            {matchesUpLargeSize && <div style={{ flexGrow: 1 }} />}
        </>
    );
};
