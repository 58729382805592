import * as yup from 'yup';

export const customerChangeNotificationValidationSchema = yup.object().shape({
    contactByPhone: yup.string().nullable(),
    phoneNumber: yup.string().when('contactByPhone', {
        is: (val: string) => val === 'yes',
        then: () => {
            return yup.string().required().nullable();
        },
        otherwise: () => yup.string(),
    }),
});
