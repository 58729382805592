import React from 'react';

import { IconProps } from './index';

export const FactoryIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <>
            <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M4 32v4h8v-4H4zm0-8v4h20v-4H4zm12 8v4h8v-4h-8zm12-8v4h8v-4h-8zm0 8v4h8v-4h-8zM0 40V12l10 8v-8l10 8v-8l10 8 2-20h6l2 20v20H0z'
                    fill={fill}
                />
            </svg>
        </>
    );
};
