import { Divisions } from 'src/shared/constants';

import { DamageReportFormGBW } from '../damage-report-form-divisions/damage-report-form-GBW-HR-GESCH/damage-report-form-GBW-HR-GESCH';
import { DamageReportFormAonKompositBhvPhv } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-BHV-PHV';
import { DamageReportFormTechnical } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-technical';
import { DamageReportFormAONTransport } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-transport';
import { DamageReportFormGlasHrGesch } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-glas-hr-gesch';
import { DamageReportFormAONKompositDREISE } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-dreise';
import { DamageReportFormUniversalDamageNotification } from '../damage-report-form-divisions/damage-report-form-AON-Komposit/damage-report-form-universal-damage-notification';
import { FormCollection } from './damage-report-form-factory-types';

export const DamageReportFormsAonKompositInstance: FormCollection = {
    [Divisions.GLAS]: DamageReportFormGlasHrGesch,
    [Divisions.HR]: DamageReportFormGlasHrGesch,
    [Divisions.GESCH]: DamageReportFormGlasHrGesch,
    [Divisions.BHV]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PHV]: DamageReportFormAonKompositBhvPhv,
    [Divisions.MACHINE]: DamageReportFormTechnical,
    [Divisions.ELECTRO]: DamageReportFormTechnical,
    [Divisions.MONTAGE]: DamageReportFormTechnical,
    [Divisions.BAULEIST]: DamageReportFormTechnical,
    [Divisions.BU]: DamageReportFormTechnical,
    [Divisions.TRANSP]: DamageReportFormAONTransport,
    [Divisions.DREISE]: DamageReportFormAONKompositDREISE,
    [Divisions.GBW]: DamageReportFormGBW,
    [Divisions.AON_FOCUS_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.AON_PROTECT_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.BUILDERS_LIABIITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PROFESSIONAL_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.COMPANY_PRODUCT_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PUBLIC_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.EXCEDENT_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.WATERSHED_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.LIABILITY_SOLAR]: DamageReportFormAonKompositBhvPhv,
    [Divisions.LIABILITY_WIND]: DamageReportFormAonKompositBhvPhv,
    [Divisions.HOUSE_LAND_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.HUNTING_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.AVIATION_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.AVIATION_PRODUCT_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PERSONAL_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PROJECT_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.SPECIAL_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.RADIATION_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PET_OWNER_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.ENVIRONMENTAL_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.FINANCIAL_LOSS]: DamageReportFormAonKompositBhvPhv,
    [Divisions.PECUNIARY_DAMAGE_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.FIDELITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.WATER_SPORTS_LIABILITY]: DamageReportFormAonKompositBhvPhv,
    [Divisions.AON_FOCUS_LUGGAGE]: DamageReportFormAONKompositDREISE,
    [Divisions.LUGGAGE]: DamageReportFormAONKompositDREISE,
    [Divisions.AON_ELECTRONICS_PLATINUM]: DamageReportFormTechnical,
    [Divisions.AON_FOCUS_CONSTRUCTION_WORK]: DamageReportFormTechnical,
    [Divisions.AON_ASSEMBLY]: DamageReportFormTechnical,
    [Divisions.CONSTRUCTION_WORK]: DamageReportFormTechnical,
    [Divisions.ELECTRONICS]: DamageReportFormTechnical,
    [Divisions.ELECTRONICS_SOLAR_WATT]: DamageReportFormTechnical,
    [Divisions.ELECTRONICS_SOLARWORLD]: DamageReportFormTechnical,
    [Divisions.ELECTRONICS_SONEPAR]: DamageReportFormTechnical,
    [Divisions.ELECTRONICS_PACKAGE]: DamageReportFormTechnical,
    [Divisions.FBU_INDUSTRY]: DamageReportFormTechnical,
    [Divisions.FIRE_INDUSTRY]: DamageReportFormTechnical,
    [Divisions.MACHINERY]: DamageReportFormTechnical,
    [Divisions.MACHINES_CONSTRUCTION_EQUIPMENT]: DamageReportFormTechnical,
    [Divisions.MACHINE_FRAMEWORK_AGREEMENT]: DamageReportFormTechnical,
    [Divisions.MACHINE_BU]: DamageReportFormTechnical,
    [Divisions.ASSEMBLY]: DamageReportFormTechnical,
    [Divisions.AON_FOCUS_TRANSPORT]: DamageReportFormAONTransport,
    [Divisions.ALL_RISKS]: DamageReportFormGlasHrGesch,
    [Divisions.ALL_DANGEROUS_HOUSEHOLD_ITEMS]: DamageReportFormGlasHrGesch,
    [Divisions.FIRE]: DamageReportFormGlasHrGesch,
    [Divisions.BUNDLED_BUILDINGS]: DamageReportFormGlasHrGesch,
    [Divisions.BUNDLED_BUSINESS]: DamageReportFormGlasHrGesch,
    [Divisions.BUNDLED_SACH]: DamageReportFormGlasHrGesch,
    [Divisions.BUNDLED_PROPERTY_FIRE]: DamageReportFormGlasHrGesch,
    [Divisions.GLASS]: DamageReportFormGlasHrGesch,
    [Divisions.HOUSEHOLD_ITEMS]: DamageReportFormGlasHrGesch,
    [Divisions.TAP_WATER]: DamageReportFormGlasHrGesch,
    [Divisions.STORM]: DamageReportFormGlasHrGesch,
    [Divisions.TERROR]: DamageReportFormGlasHrGesch,
    [Divisions.AON_ART_COLLECTIONS]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ON_ALL_HAZARDS]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ON_CYBER]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_D_AND_O]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ON_MEDIUM_SIZED_BUSINESSES]:
        DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ENGINE]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ON_CRIMINAL_LAW]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_FOCUS_ACCIDENT]: DamageReportFormUniversalDamageNotification,
    [Divisions.AON_PRIVATE]: DamageReportFormUniversalDamageNotification,
    [Divisions.FOREIGN_TRAVEL]: DamageReportFormUniversalDamageNotification,
    [Divisions.AXA_PROFESSIONAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.OCCUPATIONAL_DISABILITY]: DamageReportFormUniversalDamageNotification,
    [Divisions.GUARANTEE_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.CYBER_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.D_AND_O]: DamageReportFormUniversalDamageNotification,
    [Divisions.D_AND_O_EXCESS]: DamageReportFormUniversalDamageNotification,
    [Divisions.D_AND_O_RUN_OFF]: DamageReportFormUniversalDamageNotification,
    [Divisions.D_AND_O_DEDUCTABLE]: DamageReportFormUniversalDamageNotification,
    [Divisions.D_AND_O_BASIC_CONTRACT_SPS2]: DamageReportFormUniversalDamageNotification,
    [Divisions.SINGLE_ACCIDENT]: DamageReportFormUniversalDamageNotification,
    [Divisions.VEHICLE_LEGAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.FAMILY_LEGAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.FAIRY]: DamageReportFormUniversalDamageNotification,
    [Divisions.COMPANY_PROFESSIONAL_JURISPRUDENCE]:
        DamageReportFormUniversalDamageNotification,
    [Divisions.CAMERAS]: DamageReportFormUniversalDamageNotification,
    [Divisions.BUNDLED_FILM]: DamageReportFormUniversalDamageNotification,
    [Divisions.BUNDLED_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.LAND_TENANT_LAW]: DamageReportFormUniversalDamageNotification,
    [Divisions.GROUP_ACCIDENT]: DamageReportFormUniversalDamageNotification,
    [Divisions.GROUP_ACCIDENT_AON]: DamageReportFormUniversalDamageNotification,
    [Divisions.TRADE_CRAFT]: DamageReportFormUniversalDamageNotification,
    [Divisions.FEE_FOR_ADVICE]: DamageReportFormUniversalDamageNotification,
    [Divisions.FEE_FOR_PROJECT_BUSINESS]: DamageReportFormUniversalDamageNotification,
    [Divisions.MOTOR_VEHICLE_BUSINESS_TRIP]: DamageReportFormUniversalDamageNotification,
    [Divisions.CAR_SPECIAL]: DamageReportFormUniversalDamageNotification,
    [Divisions.SUFFER]: DamageReportFormUniversalDamageNotification,
    [Divisions.ARTS]: DamageReportFormUniversalDamageNotification,
    [Divisions.LIFE]: DamageReportFormUniversalDamageNotification,
    [Divisions.METAL_PENSION]: DamageReportFormUniversalDamageNotification,
    [Divisions.LOSS_OF_RENT]: DamageReportFormUniversalDamageNotification,
    [Divisions.MULTI_LINE]: DamageReportFormUniversalDamageNotification,
    [Divisions.MUSICAL_INSTRUMENTS]: DamageReportFormUniversalDamageNotification,
    [Divisions.PRIVATE_TRAFIC_LEGAL_PROTECTION]:
        DamageReportFormUniversalDamageNotification,
    [Divisions.SUBJECTS]: DamageReportFormUniversalDamageNotification,
    [Divisions.PRODUCT_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.PRO_DYNAMIC]: DamageReportFormUniversalDamageNotification,
    [Divisions.PROJECT_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.LEGAL_PROTECTION_FOR_SELF_EMPLOYED]:
        DamageReportFormUniversalDamageNotification,
    [Divisions.LEGAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.PENSIONS]: DamageReportFormUniversalDamageNotification,
    [Divisions.REVENUE_SHARE]: DamageReportFormUniversalDamageNotification,
    [Divisions.RECALL_AUTOMOTIVE_SUPPLIER]: DamageReportFormUniversalDamageNotification,
    [Divisions.SOLAR_POLICY]: DamageReportFormUniversalDamageNotification,
    [Divisions.OTHER_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.SPECIAL_CRIMINAL_LAW_PROTECTION]:
        DamageReportFormUniversalDamageNotification,
    [Divisions.TRANSACTION_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.TRANSPORTATION_GOODS]: DamageReportFormUniversalDamageNotification,
    [Divisions.ENVIRONMENTAL_DAMAGE]: DamageReportFormUniversalDamageNotification,
    [Divisions.ACCIDENT]: DamageReportFormUniversalDamageNotification,
    [Divisions.ACCIDENT_MIDDLE_CLASS]: DamageReportFormUniversalDamageNotification,
    [Divisions.UNITALLRISK]: DamageReportFormUniversalDamageNotification,
    [Divisions.TRANSPORT_LIABILITY]: DamageReportFormUniversalDamageNotification,
    [Divisions.TRAFFIC_LEGAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.PROPERTY_LEGAL_PROTECTION]: DamageReportFormUniversalDamageNotification,
    [Divisions.WATER_SPORTS_INSURANCE]: DamageReportFormUniversalDamageNotification,
    [Divisions.WIND_POLICY]: DamageReportFormUniversalDamageNotification,
    [Divisions.BUILDING]: DamageReportFormGBW,
};
