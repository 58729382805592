import React from 'react';
import { useTheme } from '@material-ui/core';

import {
    isSGIFFOXXKInstance,
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

interface ExternalUserIconProps {
    isActive: boolean;
}

export const ExternalUserIcon = (props: ExternalUserIconProps): JSX.Element => {
    const theme = useTheme();
    const { isActive } = props;

    const currentActiveColor =
        isSGIFFOXXKInstance ||
        isAonDigitalInstance ||
        isAonMotorInstance ||
        isHectorDemoInstance
            ? theme.palette.secondary.contrastText
            : theme.palette.info.main;

    return (
        <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10 11.252V19H0a8 8 0 013.1-6.326c.966-.748 3.4-1.917 6.9-1.422zM15.418 14h3.586v2h-3.586l1.829 1.828-1.414 1.415L11.59 15l4.243-4.243 1.414 1.415L15.418 14z'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
            <circle
                cx='9.5'
                cy='4.5'
                r='4.5'
                fill={isActive ? currentActiveColor : '#C2C2C2'}
            />
        </svg>
    );
};
