import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageTypes {
    FIRE_LIGHTNING_EXPLOSION = 'fireLightningExplosion',
    BURGLARY_ROBBERY = 'burglaryRobbery',
    TAP_WATER = 'tapWater',
    RESULT_OF_PROPERTY_DAMAGE = 'resultOfPropertyDamage',
    STORM_HAIL = 'stormOrHail',
    BROKEN_GLASS = 'brokenGlass',
    OTHER = 'other',
}

export const damageReportFormGenerateTravelDamageTypeOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.fireLightningExplosion'),
            value: DamageTypes.FIRE_LIGHTNING_EXPLOSION,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.burglaryRobbery'),
            value: DamageTypes.BURGLARY_ROBBERY,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.tapWater'),
            value: DamageTypes.TAP_WATER,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.resultOfPropertyDamage'),
            value: DamageTypes.RESULT_OF_PROPERTY_DAMAGE,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.stormOrHail'),
            value: DamageTypes.STORM_HAIL,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.brokenGlass'),
            value: DamageTypes.BROKEN_GLASS,
        },
        {
            title: t('damages-report:itemsAffectedByTheDamage.other'),
            value: DamageTypes.OTHER,
        },
    ];
};
