import ContractRecordDto from './contract-record-dto';

export const constractListGetAdapter = (data: ContractRecordDto): ContractRecordDto => {
    const { fullyCoverage, partialCoverage, liability } = data;
    const formattedItem: ContractRecordDto = { ...data };
    if (typeof fullyCoverage === 'boolean') {
        formattedItem.fullyCoverage = (
            fullyCoverage ? 'common:yes' : 'common:no'
        ) as string;
    }
    if (typeof partialCoverage === 'boolean') {
        formattedItem.partialCoverage = (
            partialCoverage ? 'common:yes' : 'common:no'
        ) as string;
    }
    if (typeof liability === 'boolean') {
        formattedItem.liability = (liability ? 'common:yes' : 'common:no') as string;
    }
    formattedItem.paymentMethod = formattedItem.paymentMethod
        ? `contracts-list:paymentMethodRows.${formattedItem.paymentMethod
              .toLowerCase()
              .trim()}`
        : '';
    return formattedItem;
};
