import React, {
    BaseSyntheticEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileObject } from 'material-ui-dropzone';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { APP_ROUTES } from 'src/routing';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useGlobalFilter } from 'src/shared/contexts';
import { buildFormData } from 'src/shared/utils';
import { useQuery, useValidateContractByDate } from 'src/shared/hooks';
import { DamageReportGroups } from 'src/shared/constants';

import { damageReportFormBHVAndPHVGetSettingsForMutation } from './damage-report-form-BHV-PHV-get-settings-for-mutation';

import { DamageReportFormView } from '../damage-report-form-view';
import { useStyle } from '../../damage-report-form-styles/damage-report-form-styles';

import { DamageReportFormDeleteConfirmationModalWindow } from '../../damage-report-form-components/damage-report-form-delete-confirmation';
import { DamageReportFormBHVAndPHVType } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-BHV-PHV-types';
import { damageReportFormBHVAndPHVDefaultState } from '../../damage-report-form-utils/damage-report-form-default-states/damage-report-form-BHV-PHV-default-state';
import { damageReportFormBHVAndPHVSchema } from '../../damage-report-form-utils/damage-report-form-schemas/damage-report-form-BHV-PHV-schema';
import { damageReportFormBHVAndPHVMutateAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-phv-bhv-mutate-adapter';
import { damageReportFormBHVAndPHVGetReportRecordAdapter } from '../../damage-report-form-utils/damage-report-form-request-body-adapters/damage-report-form-get-bhv-phv-adapter';
import {
    generateDisabledInputs,
    DamageReportFormConfigBHVAndPHV,
    generateFooter,
} from '../../damage-report-form-config/damage-report-form-config-BHV-PHV';

import { damageReportFormGenerateBHVAndPHVDamageTypeOptions } from '../../damage-report-form-utils/damage-report-form-selection-items/damage-report-form-generate-gbw-hr-gesch-phv-damage-type-options';

import { DamageReportFormGetRecordDTO } from '../../damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';

interface DamageReportFormBHVAndPHVProps {
    setDivision: (value: string) => void;
    hideButtons: boolean;
    data: DamageReportFormGetRecordDTO;
    divisionState?: string;
}

export const DamageReportFormBHVAndPHV = memo(
    (props: DamageReportFormBHVAndPHVProps): JSX.Element => {
        const { setDivision, hideButtons, data, divisionState } = props;
        const { t } = useTranslation(['common', 'damages-report', 'errors']);
        const contractId = useQuery().get('contractId');

        const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

        const {
            watch,
            setValue,
            control,
            handleSubmit,
            formState,
            reset,
            trigger,
            getValues,
        } = useForm<DamageReportFormBHVAndPHVType>({
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(damageReportFormBHVAndPHVSchema),
            defaultValues: {
                ...damageReportFormBHVAndPHVDefaultState(contractId),
                division: divisionState,
            },
        });

        const { changeDivision } = useGlobalFilter();

        const [isSaveLoading, setSaveLoading] = useState<boolean>(false);

        const { id } = useParams<{ id?: string }>();

        const classes = useStyle();
        const httpClient = useHttpClient();
        const router = useHistory();

        const [isSendingToAmsLoading, setSendingToAmsLoading] = useState(false);
        const { enqueueSnackbar } = useSnackbar();

        const { fields: files, remove } = useFieldArray({ control, name: 'files' });
        const { fields: fileInfos } = useFieldArray({
            control,
            name: 'fileInfos',
        });

        const [
            division,
            dayOfDamage,
            timeOfDamage,
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            injuredPartySurname,
            injuredPartyRoad,
            injuredPartyPostcodeAndPlace,
            injuredPartyPhonenumber,
            injuredPartyEmail,
            damageOriginatorSurname,
            damageOriginatorRoad,
            damageOriginatorPostCodeAndPlace,
            damageOriginatorPhoneNumber,
            damageOriginatorEmail,
            damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
            damageInformationDamageType,
            damageInformationDamagePlace,
            damageInformationWhatWasDamaged,
            damageInformationAreDamagedItemsOtherwiseInsured,
            indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
            claimsForDamagesMadeAgainstYou,
            estimatedDamageAmount,
            hasDamageAssessed,
            ifYouAreAtFaultForThatIncident,
            itWasYourTurnToBeAtFault,
            existenceBetweenYouAndCoInsured,
            whichExistenceBetweenYouAndCoInsured,
            doYouOrCoInsuredPersonPerformedActivity,
            whichActivity,
            detailedDescription,
            areYouEntitledToDeductInputTax,
            isRecordedByThePolice,
            policeStation,
            fileNumber,
            isWitnesses,
            witnessesSurname,
            witnessesRoad,
            witnessesPostcode,
            witnessesPlace,
            witnessesPhoneNumber,
            witnessesEmail,
        ] = watch([
            'division',
            'dayOfDamage',
            'timeOfDamage',
            'risk',
            'contractNumber',
            'policyHolder',
            'insuranceCompany',
            'injuredPartySurname',
            'injuredPartyRoad',
            'injuredPartyPostcodeAndPlace',
            'injuredPartyPhonenumber',
            'injuredPartyEmail',
            'damageOriginatorSurname',
            'damageOriginatorRoad',
            'damageOriginatorPostCodeAndPlace',
            'damageOriginatorPhoneNumber',
            'damageOriginatorEmail',
            'damageOriginatorRelationshipBetweenPolluterAndPolicyholder',
            'damageInformationDamageType',
            'damageInformationDamagePlace',
            'damageInformationWhatWasDamaged',
            'damageInformationAreDamagedItemsOtherwiseInsured',
            'indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance',
            'claimsForDamagesMadeAgainstYou',
            'estimatedDamageAmount',
            'hasDamageAssessed',
            'ifYouAreAtFaultForThatIncident',
            'itWasYourTurnToBeAtFault',
            'existenceBetweenYouAndCoInsured',
            'whichExistenceBetweenYouAndCoInsured',
            'doYouOrCoInsuredPersonPerformedActivity',
            'whichActivity',
            'detailedDescription',
            'areYouEntitledToDeductInputTax',
            'isRecordedByThePolice',
            'policeStation',
            'fileNumber',
            'isWitnesses',
            'witnessesSurname',
            'witnessesRoad',
            'witnessesPostcode',
            'witnessesPlace',
            'witnessesPhoneNumber',
            'witnessesEmail',
        ]);

        const isContractValidByDate = useValidateContractByDate(dayOfDamage, contractId);

        useEffect(() => {
            trigger();
        }, []);

        useEffect(() => {
            if (data) {
                reset(
                    damageReportFormBHVAndPHVGetReportRecordAdapter(data, divisionState)
                );
                trigger();
            }
        }, [data]);

        useEffect(() => {
            setDivision(division);
        }, [division]);

        const handleChangeValue = useCallback(
            (e: BaseSyntheticEvent): void => {
                setValue(e.target.name, e.target.value);
                trigger();
            },
            [setValue]
        );

        const onDeleteFile = (id: string): void => {
            const newFiles = fileInfos.filter((file) => {
                return file.id !== id;
            });
            setValue('fileInfos', newFiles);
        };

        const saveReport = (
            data: DamageReportFormBHVAndPHVType
        ): Promise<{ id: string }> => {
            setSaveLoading(true);
            const requestSettings = damageReportFormBHVAndPHVGetSettingsForMutation(
                data.division
            );
            if (requestSettings) {
                const formattedData = damageReportFormBHVAndPHVMutateAdapter(data);
                const formData = new FormData();
                buildFormData(formData, formattedData, null);
                if (!id) {
                    return httpClient.post(
                        `create-damage-report/${requestSettings.pathname}`,
                        formData
                    );
                }
                return httpClient.put(
                    `damage-reports/${id}/${requestSettings.pathname}`,
                    formData
                );
            }
            return Promise.reject();
        };

        const handleSaveReport = (): void => {
            const data = getValues();
            saveReport(data)
                .then(() => {
                    if (!id) {
                        changeDivision(divisionState);
                        router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                    }
                    enqueueSnackbar(t(`damages-report:savedSuccessfully`), {
                        variant: 'success',
                    });
                })
                .catch(() => {
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        };

        const makeRequestForSavingDataToAms = async (
            data: DamageReportFormBHVAndPHVType
        ): Promise<string | void> => {
            setSendingToAmsLoading(true);
            try {
                const { id: newId } = await saveReport(data);
                return httpClient
                    .post<string | void>(`damage-reports/${newId || id}/send-to-ams`)
                    .catch(() => {
                        return Promise.reject(newId);
                    });
            } catch {
                return Promise.reject();
            }
        };

        const saveReportToAms = (): void => {
            const data: DamageReportFormBHVAndPHVType = getValues();
            makeRequestForSavingDataToAms(data)
                .then(() => {
                    enqueueSnackbar(t(`damages-report:sentSuccessfully`), {
                        variant: 'success',
                    });
                    changeDivision(divisionState);
                    router.push(`${APP_ROUTES.DAMAGES}?filterValue=created`);
                })
                .catch((newId) => {
                    if (!id) {
                        router.push(`${APP_ROUTES.DAMAGE_REPORT}/${newId}`);
                    }
                    enqueueSnackbar(t(`errors:unknownError`), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSendingToAmsLoading(false);
                    setSaveLoading(false);
                });
        };

        const handleCancel = (): void => {
            history.back();
        };

        const handleReadOnlyFields = (data: {
            licenseNumber: string;
            contractNumber: string;
            policyHolder: string;
            insuranceCompany: string;
            contractId: string;
        }): void => {
            setValue('risk', data.licenseNumber);
            setValue('contractNumber', data.contractNumber);
            setValue('policyHolder', data.policyHolder);
            setValue('insuranceCompany', data.insuranceCompany);
            setValue('contractId', data.contractId);
            trigger();
        };

        const handleChangeDate = useCallback(
            (name, value: ParsableDate): void => {
                setValue(name, value);
                trigger();
            },
            [setValue]
        );

        const handleAddFile = useCallback(
            (newFiles: FileObject[]): void => {
                setValue('files', [...files, ...newFiles]);
            },
            [setValue, files]
        );

        const handleDeleteFile = useCallback(
            (index: number): void => {
                remove(index);
            },
            [setValue]
        );

        const onSubmit = (): void => {
            saveReportToAms();
        };

        const handleChangeSwitcher = useCallback(
            (name, value: boolean): void => {
                setValue(name, value);
                trigger();
            },

            [setValue]
        );

        const isDivisionFromDataIsFromState = data?.division === division;

        const headerConfig = useMemo(() => {
            return generateDisabledInputs({
                licenseNumber: isDivisionFromDataIsFromState || !id ? risk : '',
                contractNumber:
                    isDivisionFromDataIsFromState || !id ? contractNumber : '',
                policyHolder: isDivisionFromDataIsFromState || !id ? policyHolder : '',
                insuranceCompany:
                    isDivisionFromDataIsFromState || !id ? insuranceCompany : '',
                errors: {
                    contractNumber: formState?.errors?.contractNumber,
                    policyHolder: formState?.errors?.policyHolder,
                    insuranceCompany: formState?.errors?.insuranceCompany,
                },
            });
        }, [
            risk,
            contractNumber,
            policyHolder,
            insuranceCompany,
            data?.division,
            division,
            formState?.errors?.insuranceCompany,
            formState?.errors?.policyHolder,
            formState.errors?.contractNumber,
        ]);

        const damageTypeOptions = useMemo(
            () => damageReportFormGenerateBHVAndPHVDamageTypeOptions(t),
            [t]
        );

        const configs = useMemo(
            () =>
                DamageReportFormConfigBHVAndPHV({
                    injuredPartySurname,
                    injuredPartyRoad,
                    injuredPartyPostcodeAndPlace,
                    injuredPartyPhonenumber,
                    injuredPartyEmail,
                    damageOriginatorSurname,
                    damageOriginatorRoad,
                    damageOriginatorPostCodeAndPlace,
                    damageOriginatorPhoneNumber,
                    damageOriginatorEmail,
                    damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
                    damageInformationDamageType,
                    damageInformationDamagePlace,
                    damageInformationWhatWasDamaged,
                    damageInformationAreDamagedItemsOtherwiseInsured,
                    indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                    claimsForDamagesMadeAgainstYou,
                    estimatedDamageAmount,
                    hasDamageAssessed,
                    ifYouAreAtFaultForThatIncident,
                    itWasYourTurnToBeAtFault,
                    existenceBetweenYouAndCoInsured,
                    whichExistenceBetweenYouAndCoInsured,
                    doYouOrCoInsuredPersonPerformedActivity,
                    whichActivity,
                    detailedDescription,
                    areYouEntitledToDeductInputTax,
                    isRecordedByThePolice,
                    policeStation,
                    fileNumber,
                    isWitnesses,
                    witnessesSurname,
                    witnessesRoad,
                    witnessesPostcode,
                    witnessesPlace,
                    witnessesPhoneNumber,
                    witnessesEmail,
                    damageTypeOptions,
                    handleChangeSwitcher,
                    errors: formState?.errors,
                }),
            [
                injuredPartySurname,
                injuredPartyRoad,
                injuredPartyPostcodeAndPlace,
                injuredPartyPhonenumber,
                injuredPartyEmail,
                damageOriginatorSurname,
                damageOriginatorRoad,
                damageOriginatorPostCodeAndPlace,
                damageOriginatorPhoneNumber,
                damageOriginatorEmail,
                damageOriginatorRelationshipBetweenPolluterAndPolicyholder,
                damageInformationDamageType,
                damageInformationDamagePlace,
                damageInformationWhatWasDamaged,
                damageInformationAreDamagedItemsOtherwiseInsured,
                indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
                claimsForDamagesMadeAgainstYou,
                estimatedDamageAmount,
                hasDamageAssessed,
                ifYouAreAtFaultForThatIncident,
                itWasYourTurnToBeAtFault,
                existenceBetweenYouAndCoInsured,
                whichExistenceBetweenYouAndCoInsured,
                doYouOrCoInsuredPersonPerformedActivity,
                whichActivity,
                detailedDescription,
                areYouEntitledToDeductInputTax,
                isRecordedByThePolice,
                policeStation,
                fileNumber,
                isWitnesses,
                witnessesSurname,
                witnessesRoad,
                witnessesPostcode,
                witnessesPlace,
                witnessesPhoneNumber,
                witnessesEmail,
                damageTypeOptions,
                handleChangeSwitcher,
                formState.errors.damageInformationDamageType,
                formState.errors.damageInformationDamagePlace,
                formState.errors.damageInformationWhatWasDamaged,
                formState.errors.whichExistenceBetweenYouAndCoInsured,
                formState.errors.whichActivity,
                formState.errors.detailedDescription,
                formState.errors.injuredPartyEmail,
                formState.errors.damageOriginatorEmail,
                formState.errors.witnessesEmail,
            ]
        );

        const footerConfig = useMemo(() => generateFooter(), [t]);

        return (
            <>
                <DamageReportFormDeleteConfirmationModalWindow
                    id={id}
                    isOpen={isDeleteConfirmOpen}
                    onClose={() => setDeleteConfirmOpen(false)}
                />
                <DamageReportFormView
                    damageReportGroup={DamageReportGroups.LIABILITY}
                    setDeleteConfirmOpen={setDeleteConfirmOpen}
                    deleteButtonClass={classes.deleteButton}
                    deleteText={t('damages-report:deleteReport')}
                    hideButtons={hideButtons}
                    boxContainer={classes.boxContainer}
                    sectionContainer={classes.sectionContainer}
                    formState={formState}
                    handleChangeValue={handleChangeValue}
                    footerConfig={footerConfig}
                    headerConfig={headerConfig}
                    configs={configs}
                    sendText={t('send')}
                    formBlockElementClass={classes.formBlockElement}
                    containerClassName={classes.formBlockContainer}
                    saveText={t('save')}
                    saveButtonClass={clsx(classes.button, classes.saveButton)}
                    sendButtonClass={clsx(classes.button, classes.sendButton)}
                    isSendDisabled={
                        !formState.isValid ||
                        isSendingToAmsLoading ||
                        isSaveLoading ||
                        (contractId && !isContractValidByDate)
                    }
                    isSendingToAmsLoading={isSendingToAmsLoading}
                    isSaveLoading={isSaveLoading}
                    dayOfDamage={dayOfDamage}
                    IsSaveDisabled={
                        isSaveLoading ||
                        isSendingToAmsLoading ||
                        !division ||
                        !dayOfDamage ||
                        !contractNumber ||
                        !insuranceCompany ||
                        !policyHolder ||
                        (contractId && !isContractValidByDate)
                    }
                    handleCancel={handleCancel}
                    cancelText={t('cancel')}
                    handleReadOnlyFields={handleReadOnlyFields}
                    timeOfDamage={timeOfDamage}
                    handleChangeDate={handleChangeDate}
                    division={division}
                    divisionFromRecord={data?.division}
                    mainFormContainer={classes.mainFormContainer}
                    isReceivingDataLoading={false}
                    handleSubmit={handleSubmit(onSubmit)}
                    footerClassName={clsx(
                        classes.buttonsContainer,
                        classes.formBlockContainer
                    )}
                    handleAddFile={handleAddFile}
                    files={files}
                    fileInfos={fileInfos}
                    onDeleteFile={onDeleteFile}
                    handleDeleteFile={handleDeleteFile}
                    handleSaveReport={handleSaveReport}
                />
            </>
        );
    }
);
