import { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import ContractRecordDto from 'src/contracts/contracts-list/contract-record-dto';
import { GridSortModel } from '@material-ui/data-grid';

export const useContractsDetailsSearch = (
    hasAccessToSearch: boolean,
    globalSearchTerm: string,
    allowedCustomers?: string[],
    allowedDivisions?: string[],
    allowedProducts?: string[],
    allowedCustomerConnections?: string[],
    allowedRisks?: string[],
    allowedStatuses?: string[],
    sortModel?: GridSortModel,
    page?: number,
    rowsPerPage?: number
): {
    generalAmountLoading: boolean;
    generalAmount: number;
    searchLoading: boolean;
    searchResults: {
        result: ContractRecordDto[];
    };
} => {
    const httpClient = useHttpClient();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['common', 'errors']);

    const [searchResults, setSearchResults] = useState<{
        result: ContractRecordDto[];
        count: number;
    }>({ result: [], count: 0 });
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResultGeneralAmountData, setSearchResultsGeneralAmountData] = useState<{
        isLoading: boolean;
        count: number;
    }>({ isLoading: false, count: null });

    const makeRequestToGetGeneralAmount = useCallback(async () => {
        try {
            setSearchResultsGeneralAmountData({ count: null, isLoading: true });
            const data = await httpClient.post<{
                count: number;
            }>(`get-contracts/details-search/count`, {
                globalSearchTerm,
                allowedCustomers: allowedCustomers || undefined,
                allowedDivisions: allowedDivisions || undefined,
                allowedProducts: allowedProducts || undefined,
                allowedCustomerConnections: allowedCustomerConnections || undefined,
                allowedRisks: allowedRisks || undefined,
                allowedStatuses: allowedStatuses || undefined,
                sortField:
                    Array.isArray(sortModel) && sortModel.length > 0
                        ? sortModel[0].field
                        : undefined,
            });
            setSearchResultsGeneralAmountData({ count: data.count, isLoading: false });
        } catch {
            setSearchResultsGeneralAmountData({ count: null, isLoading: false });
        }
    }, [
        globalSearchTerm,
        hasAccessToSearch,
        allowedCustomerConnections,
        allowedCustomers,
        allowedDivisions,
        allowedStatuses,
        allowedRisks,
        allowedProducts,
    ]);

    useEffect(() => {
        if (hasAccessToSearch) {
            makeRequestToGetGeneralAmount();
        }
    }, [makeRequestToGetGeneralAmount, hasAccessToSearch]);

    useEffect(() => {
        if (hasAccessToSearch) {
            setSearchLoading(true);
            httpClient
                .post<{
                    result: ContractRecordDto[];
                    count: number;
                }>(`get-contracts/details-search`, {
                    globalSearchTerm,
                    skip:
                        typeof page === 'number' && typeof rowsPerPage === 'number'
                            ? (page + 1) * rowsPerPage - rowsPerPage
                            : undefined,
                    take: typeof rowsPerPage === 'number' ? rowsPerPage + 1 : undefined,
                    allowedCustomers: allowedCustomers || undefined,
                    allowedDivisions: allowedDivisions || undefined,
                    allowedProducts: allowedProducts || undefined,
                    allowedCustomerConnections: allowedCustomerConnections || undefined,
                    allowedRisks: allowedRisks || undefined,
                    allowedStatuses: allowedStatuses || undefined,
                    sortField:
                        Array.isArray(sortModel) && sortModel.length > 0
                            ? sortModel[0].field
                            : undefined,
                    isAscending:
                        Array.isArray(sortModel) && sortModel.length > 0
                            ? sortModel[0].sort === 'asc'
                            : undefined,
                })
                .then((data: { result: ContractRecordDto[]; count: number }) => {
                    if (!data || data?.result?.length === 0) {
                        enqueueSnackbar(t('nothingWasFound'), {
                            variant: 'info',
                        });
                    }
                    setSearchResults(data);
                })
                .catch(() => {
                    enqueueSnackbar(t('errors:unknownError'), {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setSearchLoading(false);
                });
        }
    }, [
        globalSearchTerm,
        hasAccessToSearch,
        allowedCustomerConnections,
        allowedCustomers,
        allowedDivisions,
        allowedStatuses,
        allowedRisks,
        allowedProducts,
        sortModel,
        page,
        rowsPerPage,
    ]);

    return {
        searchLoading,
        searchResults,
        generalAmountLoading: searchResultGeneralAmountData.isLoading,
        generalAmount: searchResultGeneralAmountData.count,
    };
};
