import React, { ReactElement } from 'react';

export const HectorDemoLogo = (): ReactElement => {
    return (
        <svg
            version='1.0'
            xmlns='http://www.w3.org/2000/svg'
            width='1683.000000pt'
            height='255.000000pt'
            viewBox='0 0 1683.000000 255.000000'
            preserveAspectRatio='xMidYMid meet'
        >
            <g
                transform='translate(0.000000,255.000000) scale(0.100000,-0.100000)'
                fill='#000000'
                stroke='none'
            >
                <path
                    d='M3720 2280 l0 -270 1280 0 1280 0 0 270 0 270 -1280 0 -1280 0 0
  -270z'
                />
                <path
                    d='M2 2278 l3 -253 913 -5 913 -5 -910 -990 c-501 -545 -911 -993 -911
  -997 0 -5 675 -8 1500 -8 l1500 0 0 255 0 255 -917 2 -917 3 873 950 c481 523
  894 971 919 998 l46 47 -1507 0 -1507 0 2 -252z'
                />
                <path
                    d='M6990 1275 l0 -1255 1398 2 1397 3 0 1250 0 1250 -250 0 -250 0 -3
  -997 -2 -998 -895 0 -895 0 0 1000 0 1000 -250 0 -250 0 0 -1255z'
                />
                <path
                    d='M10500 1775 l0 -755 1145 0 1145 0 0 -245 0 -245 -1145 0 -1145 0 0
  -255 0 -255 1398 2 1397 3 0 750 0 750 -1147 3 -1148 2 0 245 0 245 1148 2
  1147 3 3 253 2 252 -1400 0 -1400 0 0 -755z'
                />
                <path
                    d='M14030 1775 l0 -755 1145 0 1145 0 0 -245 0 -245 -1145 0 -1145 0 0
  -255 0 -255 1400 0 1400 0 0 755 0 755 -1145 0 -1145 0 0 245 0 245 1145 0
  1145 0 0 255 0 255 -1400 0 -1400 0 0 -755z'
                />
                <path
                    d='M3720 1275 l0 -275 1278 2 1277 3 0 270 0 270 -1277 3 -1278 2 0
  -275z'
                />
                <path d='M3720 280 l0 -270 1280 0 1280 0 0 270 0 270 -1280 0 -1280 0 0 -270z' />
            </g>
        </svg>
    );
};
