import { appInstance, hectorStyleInstances } from 'src/environment';
import { AppInstances } from 'src/shared/constants';

import { useAonStyle, usePaperAonStyle } from './damage-details-view-aon-styles';
import {
    useAonMotorStyle,
    usePaperAonMotorStyle,
} from './damage-details-view-aon-motor-styles';
import { useHectorStyle, usePaperHectorStyle } from './damage-details-view-hector-styles';
import {
    useSgiffoxxkStyle,
    usePaperSgiffoxxkStyle,
} from './damage-details-view-sgiffoxxk-styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const instancesStyles = {
    [AppInstances.AON]: useAonStyle,
    [AppInstances.AON_KOMPOSIT]: useAonStyle,
    [AppInstances.AON_DIGITAL]: useAonMotorStyle,
    [AppInstances.AON_MOTOR]: useAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useSgiffoxxkStyle,
};

const paperInstancesStyles = {
    [AppInstances.AON]: usePaperAonStyle,
    [AppInstances.AON_KOMPOSIT]: usePaperAonStyle,
    [AppInstances.AON_DIGITAL]: usePaperAonMotorStyle,
    [AppInstances.AON_MOTOR]: usePaperAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: usePaperAonMotorStyle,
    [AppInstances.SGIFFOXXK]: usePaperSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: usePaperSgiffoxxkStyle,
};

hectorStyleInstances.forEach((instance) => {
    instancesStyles[instance] = useHectorStyle;
    paperInstancesStyles[instance] = usePaperHectorStyle;
});

export const useStyle = (): ClassNameMap => {
    return instancesStyles[appInstance]();
};

export const usePaperStyle = (): ClassNameMap => {
    return paperInstancesStyles[appInstance]();
};
