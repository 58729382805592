import React from 'react';

import { IconProps } from './index';

export const ManufacturingIcon = (props: IconProps): JSX.Element => {
    const { fill } = props;
    return (
        <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M37.614 12.339l-7.997 3.633-5.177-4.736V8.208l5.177-4.736 7.997 3.633c.845.39 1.822.022 2.222-.779.4-.821.022-1.773-.8-2.162L30.328.206a2.251 2.251 0 00-2.51.432l-3.866 3.46a2.185 2.185 0 00-1.733-.864c-1.222 0-2.222.973-2.222 2.162V7.56h-7.064c-1.067-2.92-4.088-4.91-7.598-4.174C2.758 3.925.559 6.088.092 8.64c-.489 2.855 1.022 5.342 3.288 6.662l5.687 18.21H2.225V40h28.88v-6.488h-8.042L12.022 13.55c.377-.52.688-1.06.91-1.665h7.065v2.162c0 1.19 1 2.163 2.222 2.163.71 0 1.333-.346 1.733-.865l3.865 3.46a2.251 2.251 0 002.51.433l8.71-3.958c.843-.39 1.199-1.34.799-2.163a1.693 1.693 0 00-2.222-.778zm-30.946-.454c-1.222 0-2.222-.974-2.222-2.163 0-1.19 1-2.163 2.222-2.163s2.221.973 2.221 2.163-1 2.163-2.221 2.163z'
                fill={fill}
            />
        </svg>
    );
};
