import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { ThemeAddress, ThemeLoader, ThemeNestedMenu } from 'src/theming';
import { useAbilityCtx } from 'src/roleAccesses';

import { ContractDetailsListItem } from '../contract-details-list-item';

import {
    useStyle,
    useContractDetailsViewDataStyle,
} from '../contracts-details-view-styles';

import { generateContractsDetailsAdditionalMenu } from '../contract-details-view-configs';

import { ContractDetailsViewDetailsResponseBodyBu } from './contract-details-view-details-response-body-bu';

import { useBuStyles } from './contract-details-view-bu-style';
import clsx from 'clsx';

interface ContractDetailsViewBuProps {
    handleOpenContractEdit: () => void;
    hideThemeNestedMenu: boolean;
    contractNumber: string;
}

export const ContractDetailsViewBu = (props: ContractDetailsViewBuProps): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const contractDetailsViewDataClasses = useContractDetailsViewDataStyle();
    const buClasses = useBuStyles();

    const { t } = useTranslation([
        'contract-details-view',
        'formatted-values',
        'damages-list',
        'common',
    ]);

    const router = useHistory();
    const { id } = useParams<{ id: string }>();
    const httpClient = useHttpClient();

    const { handleOpenContractEdit, hideThemeNestedMenu, contractNumber } = props;

    const [isLoadingDetails, setLoadingDetails] = useState(false);
    const [detailsData, setDetailsData] =
        useState<ContractDetailsViewDetailsResponseBodyBu>();

    const makeRequestForDetails =
        async (): Promise<ContractDetailsViewDetailsResponseBodyBu> => {
            setLoadingDetails(true);
            return httpClient.get<ContractDetailsViewDetailsResponseBodyBu>(
                `contracts/${id}/bu`
            );
        };

    useEffect(() => {
        makeRequestForDetails()
            .then((data: ContractDetailsViewDetailsResponseBodyBu) => {
                setDetailsData(data);
            })
            .finally(() => {
                setLoadingDetails(false);
            });
    }, []);

    const goTo = (pathname: string): void => {
        router.push(pathname);
    };

    const menuItems = useMemo(
        () =>
            generateContractsDetailsAdditionalMenu(
                t,
                null,
                handleOpenContractEdit,
                goTo,
                ability,
                id,
                contractNumber
            ),
        [t, contractNumber, ability]
    );
    const isDetailDataEmpty = useMemo(() => {
        if (detailsData) {
            const detailsDataKeys = Object.keys(detailsData);
            return !detailsDataKeys
                ?.map((x) => detailsData[x])
                ?.filter((x) => (x || typeof x === 'boolean') && !Array.isArray(x))
                ?.length;
        }
        return true;
    }, [detailsData]);
    return (
        <Grid container>
            <Grid container className={classes.titleAndMenuContainer}>
                <div style={{ flexGrow: 1 }} />
                {!hideThemeNestedMenu &&
                    Array.isArray(menuItems) &&
                    menuItems.length > 0 && (
                        <ThemeNestedMenu
                            menuItems={menuItems}
                            buttonClass={classes.additionalMenuButton}
                            buttonIcon={<MenuIcon className={classes.menuIcon} />}
                        />
                    )}
            </Grid>
            {isLoadingDetails && <ThemeLoader />}
            {isDetailDataEmpty && !isLoadingDetails && (
                <ListItem>
                    <ListItemText data-title>
                        {t('contract-details-view:noContractDetails')}
                    </ListItemText>
                </ListItem>
            )}
            {detailsData && !isLoadingDetails && !isDetailDataEmpty && (
                <>
                    <List
                        className={clsx(
                            contractDetailsViewDataClasses.list,
                            buClasses.listItem
                        )}
                    >
                        <ListItem>
                            <ListItemText className={buClasses.subtitle}>
                                {t('contract-details-view:contractDetails.fbu')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.mainOperatingMode}
                            title='contract-details-view:contractDetails.mainOperatingMode'
                            value={detailsData?.mainOperatingMode}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.insurancePlace}
                            title='contract-details-view:contractDetails.insurancePlace'
                            value={detailsData?.insurancePlace}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.street}
                            title='contract-details-view:contractDetails.street'
                            value={detailsData?.street}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskLocationFbu}
                            title='contract-details-view:contractDetails.riskLocationFbu'
                            value={detailsData?.riskLocationFbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.risk}
                            title='contract-details-view:contractDetails.risk'
                            value={detailsData?.risk}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.deductibleMin === null ||
                                detailsData?.deductibleMin === 0
                            }
                            title='contract-details-view:contractDetails.deductibleMin'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.deductibleMin,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.deductibleMax === null ||
                                detailsData?.deductibleMax === 0
                            }
                            title='contract-details-view:contractDetails.deductibleMax'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.deductibleMax,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.deductibleJhe}
                            title='contract-details-view:contractDetails.deductibleJhe'
                            value={detailsData?.deductibleJhe}
                        />
                        <ListItem>
                            <ListItemText className={buClasses.subtitle}>
                                {t('contract-details-view:contractDetails.drove')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={
                                typeof detailsData?.unrestOrMaliciousDamageOrstrikeOrLockout !==
                                'boolean'
                            }
                            title='contract-details-view:contractDetails.civilUnrestOrMaliciousDamageOrStrikeOrLockout'
                            value={
                                detailsData?.unrestOrMaliciousDamageOrstrikeOrLockout
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.tapWater}
                            title='contract-details-view:contractDetails.tapWater'
                            value={
                                detailsData?.tapWater ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.storm}
                            title='contract-details-view:contractDetails.storm'
                            value={detailsData?.storm ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.hail}
                            title='contract-details-view:contractDetails.hail'
                            value={detailsData?.hail ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.earthquake}
                            title='contract-details-view:contractDetails.earthquake'
                            value={
                                detailsData?.earthquake ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.landslide}
                            title='contract-details-view:contractDetails.landslide'
                            value={
                                detailsData?.landslide ? t('common:yes') : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={
                                typeof detailsData?.burglaryOrRobberyOrVandalism !==
                                'boolean'
                            }
                            title='contract-details-view:contractDetails.burglaryOrRobberyOrVandalism'
                            value={
                                detailsData?.burglaryOrRobberyOrVandalism
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.volcanicEruption}
                            title='contract-details-view:contractDetails.volcanicEruption'
                            value={
                                detailsData?.volcanicEruption
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.snowPressure}
                            title='contract-details-view:contractDetails.snowPressure'
                            value={
                                detailsData?.snowPressure
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.smokeSupersonicWaves}
                            title='contract-details-view:contractDetails.vehicleImpactAndSmoke'
                            value={
                                detailsData?.smokeSupersonicWaves
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.flood}
                            title='contract-details-view:contractDetails.flooding'
                            value={detailsData?.flood ? t('common:yes') : t('common:no')}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.unnamedDangers}
                            title='contract-details-view:contractDetails.unnamedDangers'
                            value={
                                detailsData?.unnamedDangers
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.sprinklerSystem}
                            title='contract-details-view:contractDetails.sprinklerSystem'
                            value={
                                detailsData?.sprinklerSystem
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.additionalCosts}
                            title='contract-details-view:contractDetails.additionalCosts'
                            value={
                                detailsData?.additionalCosts
                                    ? t('common:yes')
                                    : t('common:no')
                            }
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.deductibleMinEcbu === null ||
                                detailsData?.deductibleMinEcbu === 0
                            }
                            title='contract-details-view:contractDetails.deductibleMin'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.deductibleMinEcbu,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.deductibleMaxEcbu === null ||
                                detailsData?.deductibleMaxEcbu === 0
                            }
                            title='contract-details-view:contractDetails.deductibleMax'
                            value={t('formatted-values:formattedCurrency', {
                                currency: detailsData?.deductibleMaxEcbu,
                            })}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.deductibleJheEcbu}
                            title='contract-details-view:contractDetails.deductibleJhe'
                            value={detailsData?.deductibleJheEcbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskDescription1}
                            title='contract-details-view:contractDetails.riskDescription1'
                            value={detailsData?.riskDescription1}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskDescription2}
                            title='contract-details-view:contractDetails.riskDescription2'
                            value={detailsData?.riskDescription2}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.deadlineMonitoring}
                            title='contract-details-view:contractDetails.deadlineMonitoring'
                            value={detailsData?.deadlineMonitoring}
                        />
                    </List>
                    <List className={contractDetailsViewDataClasses.list}>
                        <ListItem>
                            <ListItemText className={buClasses.subtitle}>
                                {t('contract-details-view:contractDetails.ecbu')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.mainOperatingModeEcbu}
                            title='contract-details-view:contractDetails.mainOperatingMode'
                            value={detailsData?.mainOperatingModeEcbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.insurancePlaceEcbu}
                            title='contract-details-view:contractDetails.insurancePlace'
                            value={detailsData?.insurancePlaceEcbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.streetEcbu}
                            title='contract-details-view:contractDetails.street'
                            value={detailsData?.streetEcbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskLocationEcbu}
                            title='contract-details-view:contractDetails.riskLocationFbu'
                            value={detailsData?.riskLocationEcbu}
                        />
                        <ContractDetailsListItem
                            hidden={!detailsData?.riskEcbu}
                            title='contract-details-view:contractDetails.risk'
                            value={detailsData?.riskEcbu}
                        />
                        <ListItem>
                            <ListItemText className={buClasses.subtitle}>
                                {t('contract-details-view:contractDetails.mbu')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={!detailsData?.operatingMode}
                            title='contract-details-view:contractDetails.operatingMode'
                            value={detailsData?.operatingMode}
                        />
                        <ContractDetailsListItem
                            hidden={
                                detailsData?.vsContentInsurance === null ||
                                detailsData?.vsContentInsurance === 0
                            }
                            title='contract-details-view:contractDetails.vsContentInsurance'
                            value={
                                detailsData?.vsContentInsurance
                                    ? String(detailsData?.vsContentInsurance)
                                    : ''
                            }
                        />
                        <ListItem>
                            <ListItemText className={buClasses.subtitle}>
                                {t('contract-details-view:contractDetails.enterprise')}
                            </ListItemText>
                        </ListItem>
                        <ContractDetailsListItem
                            hidden={
                                !detailsData?.streetEnterprise &&
                                !detailsData?.postCodeEnterprise &&
                                !detailsData?.cityEnterprise
                            }
                            title='contract-details-view:contractDetails.riskLocation'
                            value={
                                <ThemeAddress
                                    street={detailsData?.streetEnterprise}
                                    postcode={detailsData?.postCodeEnterprise}
                                    city={detailsData?.cityEnterprise}
                                />
                            }
                        />
                    </List>
                </>
            )}
        </Grid>
    );
};
