import { CircularProgress } from '@material-ui/core';
import React from 'react';

export default function LoadingIndicator(): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <CircularProgress color='secondary' />
        </div>
    );
}
