import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Typography } from '@material-ui/core';

import { ThemeTable, ThemeSearchField } from 'src/theming';
import { ContractsNavigationPanel } from 'src/contracts/contracts-navigation-panel/contracts-navigation-panel';
import { useCustomersListSettings } from 'src/shared/contexts';
import { AbilityActions, AbilitySubjects, useAbilityCtx } from 'src/roleAccesses';

import { useStyle } from './customer-list-styles';

import { generateCustomerListTable } from './customers-list-configs';
import { CustomerListDTO } from './customer-list-dto';
import {
    appInstancesThatHaveAccessToCreateCustomer,
    AppInstances,
} from 'src/shared/constants';
import { appInstance } from 'src/environment';

interface Props {
    data?: CustomerListDTO[];
    isLoading?: boolean;
    updateTableData?: () => Promise<void>;
}

export const CustomersList = ({
    data,
    isLoading,
    updateTableData,
}: Props): JSX.Element => {
    const classes = useStyle();
    const router = useHistory();
    const ability = useAbilityCtx();
    const { t } = useTranslation(['common', 'customers-list']);

    const {
        page,
        sortModel,
        rowsPerPage,
        searchValue,
        handleChangePage,
        handleChangeRowsPerPage,
        handleSearchValue,
        handleChangeSort,
        handleSubmit,
    } = useCustomersListSettings();

    const onSearchSubmit = (): void => {
        updateTableData();
    };

    const goTo = (pathname: string, customers?: string[], search?: string): void => {
        router.push({
            pathname,
            search: search ? `?${search}` : null,
            state: { customers: customers ? customers : null },
        });
    };

    const openDetailsView = (pathname: string): void => {
        router.push(pathname);
    };

    const openAddCustomerForm = (pathname: string): void => {
        router.push(pathname);
    };

    const columns = useMemo(() => {
        return generateCustomerListTable(t, goTo, openDetailsView, ability);
    }, [t, ability]);

    return (
        <>
            <Grid
                item
                container
                component='form'
                onSubmit={handleSubmit(onSearchSubmit)}
                className={classes.header}
            >
                <ContractsNavigationPanel activeMenu='baseData' />
                <div style={{ flexGrow: 1 }} />
                <div className={classes.searchFieldContainer}>
                    <ThemeSearchField
                        name='searchValue'
                        value={searchValue}
                        onChange={handleSearchValue}
                    />
                </div>
            </Grid>
            <Grid container className={classes.customerListContainer}>
                <Grid item container className={classes.titleContainer}>
                    <Typography variant='h5' className={classes.title}>
                        {t('customers-list:title')}
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    {appInstancesThatHaveAccessToCreateCustomer.includes(
                        appInstance as AppInstances
                    ) &&
                        ability.can(AbilityActions.create, AbilitySubjects.customer) && (
                            <Button
                                className={classes.button}
                                onClick={() => openAddCustomerForm('customer-form')}
                            >
                                {t('Neuen Kunden anlegen')}
                            </Button>
                        )}
                </Grid>
                <Grid item container className={classes.tableContainer}>
                    <ThemeTable
                        infinite
                        minTableHeight={600}
                        isLoading={isLoading}
                        columns={columns}
                        rows={data || []}
                        page={page}
                        pageSize={rowsPerPage}
                        sortModel={sortModel}
                        rowCount={data?.length || 0}
                        getRowId={(r) => r.amsIdNr}
                        onChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangeSort={handleChangeSort}
                        noRowsMessage={t('customers-list:noCustomers')}
                        rowsPerPageOptions={[10, 25, 50]}
                    />
                </Grid>
            </Grid>
        </>
    );
};
