import React, { BaseSyntheticEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Popover } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useNotificationCenterContext } from 'src/shared/contexts';

import { NotificationCenterLayout } from './notification-center-layout';
import { NotificationCenterHeader } from './notification-center-header';
import { NotificationCenterBody } from './notification-center-body';

import {
    useNotificationCenterStyle,
    usePopoverStyles,
    StyledBadge,
} from './notification-center-styles';

const NotificationCenter = (): JSX.Element => {
    const classes = useNotificationCenterStyle();
    const popoverClasses = usePopoverStyles();
    useTranslation(['notification-center', 'formatted-values']);

    const [anchorEl, setAnchorEl] = useState(null);

    const { amountOfUnreadMessages, updateNumberOfUnreadMessages } =
        useNotificationCenterContext();

    useEffect(() => {
        updateNumberOfUnreadMessages();
    }, []);

    const handleClick = (event: BaseSyntheticEvent): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                ref={anchorEl}
                size='small'
                className={classes.openNotificationCenter}
                onClick={handleClick}
                aria-describedby={id}
            >
                <StyledBadge badgeContent={amountOfUnreadMessages} color='error'>
                    <NotificationsIcon />
                </StyledBadge>
            </IconButton>

            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                elevation={2}
                classes={popoverClasses}
                anchorEl={anchorEl || null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <NotificationCenterLayout>
                    <NotificationCenterHeader />
                    <NotificationCenterBody />
                </NotificationCenterLayout>
            </Popover>
        </div>
    );
};

export default NotificationCenter;
