import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';

export const useAllCustomerConnections = (): [string[], () => Promise<void>] => {
    const [cc, setCc] = useState<string[]>([]);

    const httpClient = useHttpClient();

    const makeRequestToGetAllCustomerConnections = (): Promise<string[]> => {
        return httpClient.get<string[]>('customer-connections');
    };

    const updateCustomerConnections = async (): Promise<void> => {
        const data = await makeRequestToGetAllCustomerConnections();
        setCc(data);
    };

    useEffect(() => {
        updateCustomerConnections();
    }, []);

    return [cc, updateCustomerConnections];
};
