import React, { ReactNode, useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import axios from 'axios';

import { useGlobalFilter, useCustomersListSettings } from 'src/shared/contexts';

import { CustomerListDTO } from './customer-list-dto';

interface Props {
    children: JSX.Element;
}

const CancelToken = axios.CancelToken;

export const CustomerListWithData = ({ children }: Props): JSX.Element => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<CustomerListDTO[]>([]);

    const httpClient = useHttpClient();

    const { filter } = useGlobalFilter();
    const { searchValue, page, rowsPerPage, sortModel } = useCustomersListSettings();

    const source = CancelToken.source();

    const makeRequestToReceiveCustomersList = (): Promise<CustomerListDTO[]> => {
        setLoading(true);
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage + 1;
        const requestBody: {
            skip: number;
            take: number;
            sortField?: string;
            isAscending?: boolean;
            searchTerm?: string;
            allowedDivisions?: string[];
            allowedCustomers?: string[];
            allowedCustomerConnections?: string[];
        } = {
            skip,
            take,
        };
        const isAllClientConnectionsChecked = filter.customerConnections.find(
            (item) => item.value === 'all'
        )?.checked;
        const isAllClientsChecked = filter.customers.find(
            (item) => item.value === 'all'
        )?.checked;

        if (!isAllClientConnectionsChecked) {
            requestBody.allowedCustomerConnections = filter.customerConnections
                .filter((item) => item.value !== 'all' && item.checked)
                .map((item) => item.value);
        }

        if (!isAllClientsChecked) {
            requestBody.allowedCustomers = filter.customers
                .filter((item) => item.value !== 'all' && item.checked)
                .map((item) => item.value);
        }

        if (sortModel && sortModel.length !== 0) {
            requestBody.isAscending = sortModel[0].sort === 'asc';
            requestBody.sortField = sortModel[0].field;
        }
        if (searchValue) {
            requestBody.searchTerm = searchValue;
        }
        return httpClient.post<CustomerListDTO[]>('get-customers', requestBody, {
            axiosConfig: {
                cancelToken: source.token,
            },
        });
    };

    const updateTableData = async (): Promise<void> => {
        try {
            const data = await makeRequestToReceiveCustomersList();
            setData(data);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        updateTableData();
        return () => {
            source.cancel();
        };
    }, [sortModel, JSON.stringify(filter), page, rowsPerPage]);

    const chilrenWithProps = React.Children.map<ReactNode, ReactNode>(
        children,
        (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { data, isLoading, updateTableData });
            }
            return child;
        }
    );

    return <>{chilrenWithProps}</>;
};
