import uniqid from 'uniqid';

import { DamageReportFormAONKompositTechnicalTypes } from '../../../damage-report-form-utils/damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-technical-types';

export const damageReportFormAONKompositTechnicalDefaultState = (
    contractId: string
): DamageReportFormAONKompositTechnicalTypes => {
    return {
        contractId,
        id: '',
        division: '',
        dayOfDamage: null,
        timeOfDamage: null,
        risk: '',
        contractNumber: '',
        policyHolder: '',
        insuranceCompany: '',
        placeOfDamage: '',
        itemsAffectedByTheDamage: [
            {
                id: uniqid(),
                numberAndTypeOfThings: '',
                acquisitionOrReplacementPrice: '',
                repairCosts: '',
            },
        ],
        damageAndCause: '',
        estimatedLossAmountInEUR: '',
        shouldDamagedItemsBeOperatedProvisionally: false,
        namely: '',
        isARepairPossibleAndPlanned: false,
        through: '',
        whoCanAnswerQuestionsAboutTheTechnicalDetails: '',
        isWitnesses: false,
        witnessesSurname: '',
        witnessesRoad: '',
        witnessesPostcode: '',
        witnessesPlace: '',
        witnessesPhoneNumber: '',
        witnessesEmail: '',
        damageInformationAreDamagedItemsOtherwiseInsured: false,
        indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance: '',
        warrantyPeriodForTheDamagedObjectExpired: false,
        namelyBy: '',
        lossOfInsuredItemsDueToTheftBurglaryOrArson: '',
        isRecordedByThePolice: false,
        policeStation: '',
        fileNumber: '',
        interruptionInOperationOrDelayIncommissioning: false,
        interruptionInOperationNamely: '',
        interruptionInOperationStandsSince: '',
        expectedDamageInEurosPerDayOrWeekOrMonth: 0,
        mitigationMeasuresHaveAlreadyBeenTaken: '',
        additionalCostsAreIncurredForBridgingMeasures: '',
        whoCreatedTheServicesAffectedByTheDamage: '',
        projectPhase: 'No choice',
        thingAffectedByTheDamage: 'No choice',
        thingAffectedByTheDamageSince: '',
        thingAffectedAcceptedSince: '',
        thingAffectedAcceptedBy: '',
        insuredConstructionOrAssemblyProject: 'No choice',
        insuredConstructionOrAssemblyProjectSince: '',
        insuredConstructionOrAssemblyProjectAcceptedSince: '',
        insuredConstructionOrAssemblyProjectAcceptedBy: '',
        caseOfTheftForConstructionInsurance: false,
        files: [],
        fileInfos: [],
    };
};
