import { TFunction } from 'i18next';
import { SelectItem } from 'src/theming';

export enum DamageTypes {
    TRANSPORTATION_GOODS = 'transportationOfGoods',
    EXHIBITION = 'exhibition',
    WORKS_TRAFFIC = 'worksTraffic',
    CONSEQUENTIAL_DAMAGE_PROPERTY_AND_PROPERTY = 'consequentialDamagePropertyAndProperty',
}

export const damageReportFormGenerateAONTtanspDamageTypeOptions = (
    t: TFunction
): SelectItem[] => {
    return [
        {
            title: t('damages-report:noChoice'),
            value: 'No choice',
        },
        {
            title: t('damages-report:damageTypeOptionsAonTransp.transportationOfGoods'),
            value: DamageTypes.TRANSPORTATION_GOODS,
        },
        {
            title: t('damages-report:damageTypeOptionsAonTransp.exhibition'),
            value: DamageTypes.EXHIBITION,
        },
        {
            title: t('damages-report:damageTypeOptionsAonTransp.worksTraffic'),
            value: DamageTypes.WORKS_TRAFFIC,
        },
        {
            title: t(
                'damages-report:damageTypeOptionsAonTransp.consequentialDamagePropertyAndProperty'
            ),
            value: DamageTypes.CONSEQUENTIAL_DAMAGE_PROPERTY_AND_PROPERTY,
        },
    ];
};
