import { ChangeEvent } from 'react';
import { CheckboxItem } from 'src/theming';
import { DamageReportFormDynamicFieldsRow } from '../../damage-report-form-components/damage-report-form-dynimic-fields-row/damage-report-form-dynimic-fields-row';
import { DamageFormConditionalBlock } from '../../damage-report-form-components/damage-report-form-input-containers/damage-form-conditional-block';
import { DamageReportFormContainer } from '../../damage-report-form-components/damage-report-form-input-containers/damage-report-form-container';
import { DamageReportFormCurrencyInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-currency-input';
import { DamageFormDisabledInput } from '../../damage-report-form-components/damage-report-form-items/damage-form-disabled-input';
import { DamageReportFormCheckboxesGroup } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-checkboxes-group';
import { DamageReportFormFullInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-full-input';
import { DamageReportFormInput } from '../../damage-report-form-components/damage-report-form-items/damage-report-form-input';
import {
    FormContainerProps,
    FormElement,
    generateDisabledInputsProps,
} from '../damage-report-form-config-GBW-HR-GESCH';

export interface DamageReportFormConfigAONKompositLiabilityReturnType {
    title?: string;
    id: string;
    isCombination?: boolean;
    showIfFalse?: boolean;
    toggleValue?: () => void;
    Container: (props: FormContainerProps) => JSX.Element;
    formElements: FormElement[];
    formElementsRows?: {
        RowComponent: React.FC<{ rows: { id: string; formElements: FormElement[] }[] }>;
        rows: { id: string; formElements: FormElement[] }[];
        handleAddRow?: () => void;
        handleDeleteRow?: (id: string) => void;
        handleOnChangeRow: (
            id: string,
            row: { id: string; formElements: FormElement[] }
        ) => void;
    };
}

interface FooterElementsProps {
    elationshipBetweenYouAndTheClaimantOptions: CheckboxItem[];
    handleChangeElationshipBetweenYouAndTheClaimant: (
        e: ChangeEvent<HTMLInputElement>
    ) => void;
}

interface DamageReportFormConfigAONKompositLiabilityProps {
    fileNumberInsuredCompany: string;
    damageLocation: string;
    damageCause: string;
    courseOfTheDamage: string;
    expectedAmountOfDamage: string;
    blameForTheDamage: boolean;
    because: string;
    relationshipBetweenYouAndTheInjuredPerson: boolean;
    wasTheDamagedItemRentedLeasedLoaned: boolean;
    affectedByTheDamageAlsoThroughAnotherContract: boolean;
    itemsAffectedByTheDamage: {
        id: string;
        numberAndTypeOfItems: string;
        acquisitionOrReplacementPrice: number | string;
        repairCosts: number | string;
    }[];
    injuredPersonsOrClaimants: string;
    investigationToBeExpected: boolean;
    productLiabilityIncludingRecallDamage: boolean;
    financialLossIncludingFidelityOrComputerAbuse: boolean;
    descriptionOfTheProductCausingTheDamage: string;
    purposeOfUse: string;
    manufacturerSellerDealerOfTheProduct: string;
    damageCausedByYourSubSupplier: string;
    howManyOfYourProductsAreDefective: string;
    deliveryDate: string;
    batchBasedOnSerialNumberingRecognizable: string;
    etainedSamplesExist: string;
    exitInspectionBeenCarriedOut: string;
    defectProduct: string;
    blameForTheDefect: string;
    handleChangeSwitcher: (name: string, value: boolean) => void;
    handleAddRow: () => void;
    handleDeleteRow: (id: string) => void;
    handleOnChangeRow?: (
        id: string,
        row: { id: string; formElements: FormElement[] }
    ) => void;
    fullWidthClass?: string;
}

export const generateDisabledInputs = ({
    licenseNumber,
    contractNumber,
    policyHolder,
    insuranceCompany,
    errors,
}: generateDisabledInputsProps): DamageReportFormConfigAONKompositLiabilityReturnType[] => [
    {
        Container: DamageReportFormContainer,
        title: '',
        id: 'generalFormBlock',
        formElements: [
            {
                Item: DamageFormDisabledInput,
                value: licenseNumber,
                label: 'damages-report:generalFormBlock.risk',
                name: 'risk',
            },
            {
                Item: DamageFormDisabledInput,
                value: contractNumber,
                label: 'damages-report:generalFormBlock.contractNumber',
                name: 'contractNumber',
                error: Boolean(errors.contractNumber),
            },
            {
                Item: DamageFormDisabledInput,
                value: policyHolder,
                label: 'damages-report:generalFormBlock.policyHolder',
                name: 'policyHolder',
                error: Boolean(errors.policyHolder),
            },
            {
                Item: DamageFormDisabledInput,
                value: insuranceCompany,
                label: 'damages-report:generalFormBlock.client',
                name: 'insuranceCompany',
                error: Boolean(errors.insuranceCompany),
            },
        ],
    },
];

export const DamageReportFormConfigAONKompositLiabiblity = ({
    fileNumberInsuredCompany,
    damageLocation,
    damageCause,
    courseOfTheDamage,
    expectedAmountOfDamage,
    blameForTheDamage,
    because,
    relationshipBetweenYouAndTheInjuredPerson,
    wasTheDamagedItemRentedLeasedLoaned,
    affectedByTheDamageAlsoThroughAnotherContract,
    itemsAffectedByTheDamage,
    injuredPersonsOrClaimants,
    investigationToBeExpected,
    productLiabilityIncludingRecallDamage,
    financialLossIncludingFidelityOrComputerAbuse,
    descriptionOfTheProductCausingTheDamage,
    purposeOfUse,
    manufacturerSellerDealerOfTheProduct,
    damageCausedByYourSubSupplier,
    howManyOfYourProductsAreDefective,
    deliveryDate,
    batchBasedOnSerialNumberingRecognizable,
    etainedSamplesExist,
    exitInspectionBeenCarriedOut,
    defectProduct,
    blameForTheDefect,
    handleChangeSwitcher,
    handleAddRow,
    handleDeleteRow,
    handleOnChangeRow,
    fullWidthClass,
}: DamageReportFormConfigAONKompositLiabilityProps): DamageReportFormConfigAONKompositLiabilityReturnType[][] => {
    return [
        [
            {
                title: 'damages-report:notificationOfLiabilityInsurance.title',
                Container: DamageReportFormContainer,
                id: 'notificationOfLiabilityInsurance',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.fileNumberInsuredCompany',
                        name: 'fileNumberInsuredCompany',
                        value: fileNumberInsuredCompany,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.damageLocation',
                        name: 'damageLocation',
                        value: damageLocation,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.damageCause',
                        name: 'damageCause',
                        value: damageCause,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.courseOfTheDamage',
                        name: 'courseOfTheDamage',
                        value: courseOfTheDamage,
                    },
                    {
                        Item: DamageReportFormCurrencyInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.expectedAmountOfDamage',
                        name: 'expectedAmountOfDamage',
                        value: expectedAmountOfDamage,
                        className: fullWidthClass,
                    },
                ],
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.blameForTheDamage',
                id: 'blameForTheDamage',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.because',
                        value: because,
                        name: 'because',
                    },
                ],
                isCombination: blameForTheDamage,
                toggleValue: () =>
                    handleChangeSwitcher('blameForTheDamage', !blameForTheDamage),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.relationshipBetweenYouAndTheInjuredPerson',
                id: 'relationshipBetweenYouAndTheInjuredPerson',
                formElements: [],
                isCombination: relationshipBetweenYouAndTheInjuredPerson,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'relationshipBetweenYouAndTheInjuredPerson',
                        !relationshipBetweenYouAndTheInjuredPerson
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.wasTheDamagedItemRentedLeasedLoaned',
                id: 'wasTheDamagedItemRentedLeasedLoaned',
                formElements: [],
                isCombination: wasTheDamagedItemRentedLeasedLoaned,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'wasTheDamagedItemRentedLeasedLoaned',
                        !wasTheDamagedItemRentedLeasedLoaned
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.affectedByTheDamageAlsoThroughAnotherContract',
                id: 'affectedByTheDamageAlsoThroughAnotherContract',
                formElements: [],
                isCombination: affectedByTheDamageAlsoThroughAnotherContract,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'affectedByTheDamageAlsoThroughAnotherContract',
                        !affectedByTheDamageAlsoThroughAnotherContract
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.itemsAffectedByTheDamage',
                Container: DamageReportFormContainer,
                id: 'itemsAffectedByTheDamage',
                formElementsRows: {
                    handleAddRow,
                    handleDeleteRow,
                    handleOnChangeRow,
                    RowComponent: DamageReportFormDynamicFieldsRow,
                    rows: itemsAffectedByTheDamage?.map((item) => ({
                        id: item.id,
                        formElements: [
                            {
                                Item: DamageReportFormInput,
                                label: 'damages-report:numberAndTypeOfItems',
                                name: 'numberAndTypeOfItems',
                                value: item.numberAndTypeOfItems,
                            },
                            {
                                Item: DamageReportFormCurrencyInput,
                                label: 'damages-report:acquisitionOrReplacementPrice',
                                name: 'acquisitionOrReplacementPrice',
                                value: item.acquisitionOrReplacementPrice,
                            },
                            {
                                Item: DamageReportFormCurrencyInput,
                                label: 'damages-report:repairCostsLiability',
                                name: 'repairCosts',
                                value: item.repairCosts,
                            },
                        ],
                    })),
                },
                formElements: [],
            },
            {
                title: '  ',
                Container: DamageReportFormContainer,
                id: 'injuredPersonsOrClaimants',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:notificationOfLiabilityInsurance.injuredPersonsOrClaimants',
                        name: 'injuredPersonsOrClaimants',
                        value: injuredPersonsOrClaimants,
                    },
                ],
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.investigationToBeExpected',
                id: 'investigationToBeExpected',
                formElements: [],
                isCombination: investigationToBeExpected,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'investigationToBeExpected',
                        !investigationToBeExpected
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.productLiabilityIncludingRecallDamage',
                id: 'productLiabilityIncludingRecallDamage',
                formElements: [],
                isCombination: productLiabilityIncludingRecallDamage,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'productLiabilityIncludingRecallDamage',
                        !productLiabilityIncludingRecallDamage
                    ),
                Container: DamageFormConditionalBlock,
            },
            {
                title: 'damages-report:notificationOfLiabilityInsurance.financialLossIncludingFidelityOrComputerAbuse',
                id: 'financialLossIncludingFidelityOrComputerAbuse',
                formElements: [],
                isCombination: financialLossIncludingFidelityOrComputerAbuse,
                toggleValue: () =>
                    handleChangeSwitcher(
                        'financialLossIncludingFidelityOrComputerAbuse',
                        !financialLossIncludingFidelityOrComputerAbuse
                    ),
                Container: DamageFormConditionalBlock,
            },
        ],
        [
            {
                title: 'damages-report:productLiabilityClaimsIncludingRecallClaims.title',
                Container: DamageReportFormContainer,
                id: 'productLiabilityClaimsIncludingRecallClaims',
                formElements: [
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.descriptionOfTheProductCausingTheDamage',
                        name: 'descriptionOfTheProductCausingTheDamage',
                        value: descriptionOfTheProductCausingTheDamage,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.purposeOfUse',
                        name: 'purposeOfUse',
                        value: purposeOfUse,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.manufacturerSellerDealerOfTheProduct',
                        name: 'manufacturerSellerDealerOfTheProduct',
                        value: manufacturerSellerDealerOfTheProduct,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.damageCausedByYourSubSupplier',
                        name: 'damageCausedByYourSubSupplier',
                        value: damageCausedByYourSubSupplier,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.howManyOfYourProductsAreDefective',
                        name: 'howManyOfYourProductsAreDefective',
                        value: howManyOfYourProductsAreDefective,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.deliveryDate',
                        name: 'deliveryDate',
                        value: deliveryDate,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.batchBasedOnSerialNumberingRecognizable',
                        name: 'batchBasedOnSerialNumberingRecognizable',
                        value: batchBasedOnSerialNumberingRecognizable,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.etainedSamplesExist',
                        name: 'etainedSamplesExist',
                        value: etainedSamplesExist,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.exitInspectionBeenCarriedOut',
                        name: 'exitInspectionBeenCarriedOut',
                        value: exitInspectionBeenCarriedOut,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.defectProduct',
                        name: 'defectProduct',
                        value: defectProduct,
                    },
                    {
                        Item: DamageReportFormFullInput,
                        label: 'damages-report:productLiabilityClaimsIncludingRecallClaims.blameForTheDefect',
                        name: 'blameForTheDefect',
                        value: blameForTheDefect,
                    },
                ],
            },
        ],
    ];
};

export const generateFooter = ({
    elationshipBetweenYouAndTheClaimantOptions,
    handleChangeElationshipBetweenYouAndTheClaimant,
}: FooterElementsProps): DamageReportFormConfigAONKompositLiabilityReturnType[] => {
    return [
        {
            title: 'damages-report:contractualRelationshipBetweenYouAndTheClaimant.title',
            Container: DamageReportFormContainer,
            id: 'footer',
            formElements: [
                {
                    Item: DamageReportFormCheckboxesGroup,
                    label: '',
                    value: [],
                    name: 'elationshipBetweenYouAndTheClaimant',
                    options: elationshipBetweenYouAndTheClaimantOptions,
                    handleChangeCheckboxesGroup:
                        handleChangeElationshipBetweenYouAndTheClaimant,
                },
            ],
        },
    ];
};
