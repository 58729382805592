import React, {
    ChangeEvent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useDarkMode from 'use-dark-mode';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { GridRowParams, GridSortModelParams } from '@material-ui/data-grid';
import { Grid, InputBase, Typography } from '@material-ui/core';

import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

import { ThemeCheckboxesDropdown, ThemeTable } from 'src/theming';
import { useGlobalFilter } from 'src/shared/contexts';
import { useAuth } from 'src/user-management/context-auth';
import { ThemeDatePicker } from 'src/theming/theme-date-picker/theme-date-picker';

import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { APP_ROUTES } from 'src/routing';

import {
    useGlobalSearchStyle,
    useInputStyles,
} from './contracts-global-search-styles/contracts-global-search-styles';

import { useDamagesDetailsSearch } from '../contracts-hooks';

import {
    generateGlobalSearchListDependOnGlobalFilter,
    generateGlobalSearchDamagesTableHeader,
    DamageColumnKeys,
} from './global-search-list-configs';

import { useStyle } from '../contracts-styles/contracts-styles';
import { globalSearchListGetAllowedFilterValues } from './global-search-list-get-allowed-filter-values';
import { globalSearchListGetFilterValuesFromSearchResult } from './global-search-list-get-filter-values-from-search-result';
import { GlobalSearchAutocompliteFilter } from './components';

type ContractsGlobalSearchListFormData = {
    damageDate: ParsableDate;
    place: string;
    page: number;
    rowsPerPage: number;
};

interface DamagesGlobalSearchListProps {
    globalSearchTerm: string;
}

const shallowEqual = (checkboxItems1, checkboxItems2): boolean => {
    const isAllSelected = checkboxItems1?.find((x) => x.value === 'all')?.checked;
    if (checkboxItems2 === null && isAllSelected) {
        return true;
    }
    if (!checkboxItems1?.length || !checkboxItems2?.length || !checkboxItems2) {
        return false;
    }
    for (let i = 1; i < checkboxItems1.length; i++) {
        if (checkboxItems1[i]?.checked !== checkboxItems2[i]?.checked) {
            return false;
        }
    }
    return true;
};

export const DamagesGlobalSearchList = (
    props: DamagesGlobalSearchListProps
): JSX.Element => {
    const darkMode = useDarkMode();
    const { t } = useTranslation(['common', 'global-search-list', 'contract-types']);
    const classes = useStyle({ isDarkMode: darkMode.value });
    const globalSearchClasses = useGlobalSearchStyle({ filtersCount: 8 });
    const inputClasses = useInputStyles({ filtersCount: 8 });

    const { globalSearchTerm } = props;

    const updateData = function (): (
        value: unknown[],
        setFn: React.Dispatch<React.SetStateAction<boolean>>
    ) => void {
        let valueState: unknown[] = null;
        return function (
            value: unknown[],
            setFn: React.Dispatch<React.SetStateAction<boolean>>
        ) {
            if (!shallowEqual(value, valueState)) {
                setFn((x) => !x);
            }
            valueState = [...value];
        };
    };

    const [clientConnections, setClientConnections] = useState([]);
    const [triggerClientConnections, setTriggerClientConnections] = useState(false);
    const updateClientConnections = useMemo(updateData, []);

    const [clients, setClients] = useState([]);
    const [triggerClients, setTriggerClients] = useState(false);
    const updateClients = useMemo(updateData, []);

    const [divisions, setDivisions] = useState([]);
    const [triggerDivisions, setTriggerDivisions] = useState(false);
    const updateDivisions = useMemo(updateData, []);

    const [products, setProducts] = useState([]);
    const [triggerProducts, setTriggerProducts] = useState(false);
    const updateProducts = useMemo(updateData, []);

    const [risks, setRisks] = useState([]);
    const [triggerRisks, setTriggerRisks] = useState(false);
    const updateRisks = useMemo(updateData, []);

    const [statuses, setStatuses] = useState([]);
    const [triggerStatuses, setTriggerStatuses] = useState(false);
    const updateStatuses = useMemo(updateData, []);

    const [sortModel, setSortModel] = useState([]);

    const { filter } = useGlobalFilter();
    const { userData } = useAuth();
    const history = useHistory();

    function sortByTitle(a, b): number {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    const { handleSubmit, watch, setValue } = useForm<ContractsGlobalSearchListFormData>({
        mode: 'onChange',
        defaultValues: {
            damageDate: null,
            page: 0,
            rowsPerPage: 10,
            place: '',
        },
    });

    useEffect(() => {
        if (Array.isArray(userData.divisions) && userData.divisions.length > 0) {
            const mappedDivisionsForState = userData.divisions
                .map((item) => ({
                    title: item.label,
                    value: item.value,
                    checked: false,
                }))
                .sort(sortByTitle);
            setDivisions([
                { title: t('all'), value: 'all', checked: true },
                ...mappedDivisionsForState,
            ]);
        }
    }, [userData.divisions, t]);

    const onSubmit = (): void => undefined;

    const handleChangeList = useCallback(
        (newList, name) => {
            const allItemIndex = newList.findIndex((x) => x.value === 'all');
            const allItem = newList[allItemIndex];

            const otherItems = newList.slice(0);
            otherItems?.splice(allItemIndex, 1);
            otherItems?.sort(sortByTitle);
            const sortedItems = [allItem, ...otherItems];

            switch (name) {
                case 'clientConnectionList': {
                    setClientConnections(sortedItems);
                    break;
                }
                case 'clientsList': {
                    setClients(sortedItems);
                    break;
                }
                case 'divisionList': {
                    setDivisions(sortedItems);
                    break;
                }
                case 'statusList': {
                    setStatuses(sortedItems);
                    break;
                }
                case 'productList': {
                    setProducts(sortedItems);
                    break;
                }
                case 'riskList': {
                    setRisks(sortedItems);
                    break;
                }
                default: {
                    setValue(name, newList);
                }
            }
        },
        [setValue]
    );

    const handleValue = useCallback(
        (name, value) => {
            setValue(name, value);
        },
        [setValue]
    );

    const handleChangePage = (e: SyntheticEvent, newPage: number): void => {
        setValue('page', newPage);
    };

    const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue('rowsPerPage', Number(e.target.value));
    };
    const handleChangeSort = useCallback(
        (sortOrderParams: GridSortModelParams): void => {
            setSortModel(sortOrderParams.sortModel);
        },
        [setValue]
    );

    const goToDetailsPage = (params: GridRowParams): void => {
        history.push(`${APP_ROUTES.DAMAGES}/${params.id}`);
    };

    useEffect(() => {
        handleChangeList(
            generateGlobalSearchListDependOnGlobalFilter(t, filter.customerConnections),
            'clientConnectionList'
        );
    }, [filter.customerConnections, t, handleChangeList]);

    useEffect(() => {
        handleChangeList(
            generateGlobalSearchListDependOnGlobalFilter(t, filter.customers),
            'clientsList'
        );
    }, [filter.customers, t, handleChangeList]);

    const [damageDate, page, rowsPerPage, place] = watch([
        'damageDate',
        'page',
        'rowsPerPage',
        'place',
    ]);

    const debouncedPlace = useDebounce(place, 1000);

    const allowedClientConnections = useMemo(
        () => globalSearchListGetAllowedFilterValues(clientConnections),
        [triggerClientConnections]
    );

    const allowedClients = useMemo(
        () => globalSearchListGetAllowedFilterValues(clients),
        [triggerClients]
    );

    const allowedDivisions = useMemo(
        () => globalSearchListGetAllowedFilterValues(divisions),
        [triggerDivisions]
    );

    const allowedProducts = useMemo(
        () => globalSearchListGetAllowedFilterValues(products),
        [triggerProducts]
    );

    const allowedRisks = useMemo(
        () => globalSearchListGetAllowedFilterValues(risks),
        [triggerRisks]
    );

    const allowedStatuses = useMemo(
        () => globalSearchListGetAllowedFilterValues(statuses),
        [triggerStatuses]
    );

    const damages = useDamagesDetailsSearch(
        clients.length !== 0 && clientConnections.length !== 0,
        globalSearchTerm,
        allowedClientConnections,
        allowedClients,
        allowedDivisions,
        allowedProducts,
        allowedRisks,
        allowedStatuses,
        sortModel,
        debouncedPlace,
        damageDate,
        page,
        rowsPerPage
    );

    useEffect(() => {
        if (
            Array.isArray(damages?.searchResults?.result) &&
            damages.searchResults.result?.length > 0 &&
            risks.length === 0 &&
            products.length === 0 &&
            statuses.length === 0
        ) {
            const productsFromResult = globalSearchListGetFilterValuesFromSearchResult(
                t,
                DamageColumnKeys.PRODUCT,
                damages.searchResults?.result,
                'productKey'
            ).sort(sortByTitle);
            const risksFromResult = globalSearchListGetFilterValuesFromSearchResult(
                t,
                DamageColumnKeys.RISK,
                damages.searchResults?.result
            ).sort(sortByTitle);
            const statusesFromResult = globalSearchListGetFilterValuesFromSearchResult(
                t,
                DamageColumnKeys.DAMAGE_STATUS,
                damages.searchResults?.result
            ).sort(sortByTitle);
            setProducts([
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...productsFromResult,
            ]);
            setRisks([
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...risksFromResult,
            ]);
            setStatuses([
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...statusesFromResult,
            ]);
        }
    }, [damages, risks, statuses, products]);

    useEffect(() => {
        if (
            Array.isArray(damages?.searchResults?.result) &&
            damages.searchResults?.result?.length > 0 &&
            risks.length !== 0 &&
            products.length !== 0 &&
            statuses.length !== 0
        ) {
            setProducts((value) => [
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...value.slice(1),
            ]);
            setRisks((value) => [
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...value.slice(1),
            ]);
            setStatuses((value) => [
                { title: t('all'), value: 'all', checked: true, data: undefined },
                ...value.slice(1),
            ]);
        }
    }, [t]);

    const columns = useMemo(
        () => generateGlobalSearchDamagesTableHeader(t, goToDetailsPage),
        [t]
    );

    return (
        <Grid container className={classes.contractsListContainer}>
            <Grid
                container
                item
                component='form'
                className={classes.toolBar}
                onSubmit={handleSubmit(onSubmit)}
            >
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:clientConnection')}
                    itemsList={clientConnections}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='clientConnectionList'
                    onCloseHandler={() => {
                        updateClientConnections(
                            clientConnections,
                            setTriggerClientConnections
                        );
                    }}
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:client')}
                    itemsList={clients}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='clientsList'
                    onCloseHandler={() => updateClients(clients, setTriggerClients)}
                />
                <InputBase
                    classes={inputClasses}
                    placeholder={t('global-search-list:place')}
                    name='place'
                    value={place}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleValue('place', e.target.value)
                    }
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:division')}
                    itemsList={divisions}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='divisionList'
                    onCloseHandler={() => updateDivisions(divisions, setTriggerDivisions)}
                />
                <GlobalSearchAutocompliteFilter
                    placeholder='global-search-list:product'
                    name='productList'
                    options={products}
                    className={globalSearchClasses.autocompleteFilter}
                    onChange={handleChangeList}
                    triggerExternalCallback={() =>
                        updateProducts(products, setTriggerProducts)
                    }
                />
                <GlobalSearchAutocompliteFilter
                    placeholder='global-search-list:risk'
                    name='riskList'
                    options={risks}
                    className={globalSearchClasses.autocompleteFilter}
                    onChange={handleChangeList}
                    triggerExternalCallback={() => updateRisks(risks, setTriggerRisks)}
                />
                <ThemeCheckboxesDropdown
                    withBadgeNumber
                    buttonTitle={t('global-search-list:damages.status')}
                    itemsList={statuses}
                    setItemsList={handleChangeList}
                    titleButtonClass={globalSearchClasses.toolBarDropDownButton}
                    name='statusList'
                    onCloseHandler={() => updateStatuses(statuses, setTriggerStatuses)}
                />
                <ThemeDatePicker
                    placeholder={t('global-search-list:damages.damageDay')}
                    name='damageDate'
                    value={damageDate}
                    onChange={handleValue}
                    className={globalSearchClasses.datePicker}
                />
            </Grid>
            <Typography variant='h5' className={globalSearchClasses.title}>
                {t('global-search-list:damages.title')}
            </Typography>
            <Grid item className={globalSearchClasses.tableContainer}>
                <ThemeTable
                    infinite
                    isLoading={damages.searchLoading}
                    noRowsMessage={t('common:noDamages')}
                    dataLoadingMessage={t('common:dataLoading')}
                    columns={columns}
                    rows={damages.searchResults?.result}
                    pageSize={rowsPerPage}
                    page={page}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowCount={damages.searchResults?.result?.length}
                    sortModel={sortModel}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangeSort={handleChangeSort}
                    onChangePage={handleChangePage}
                    getRowId={(r) => r.amsIdNr}
                    amountRecordsToShow={damages.generalAmount}
                    amountRecordsToShowLoading={damages.generalAmountLoading}
                />
            </Grid>
        </Grid>
    );
};
