export enum LIGHT_MAAS_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9FBF9',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_DARK_COLOR = '#F2F4F0',
    PRIMARY_CONTRAST_TEXT = '#5B5B5B',
    SECONDARY_MAIN_COLOR = '#20AF3A',
    SECONDARY_LIGHT_COLOR = '#F5FFF2',
    SECONDARY_DARK_COLOR = '#4D8C38',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#20AF3A',
    INFO_LIGHT_COLOR = '#F5FFF2',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_MAAS_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#1C281A',
    PRIMARY_LIGHT_COLOR = '#3E3E3E',
    PRIMARY_DARK_COLOR = '#0E0E0E',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#21693F',
    SECONDARY_LIGHT_COLOR = '#1F4214',
    SECONDARY_DARK_COLOR = '#152E0D',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#20AF3A',
    INFO_LIGHT_COLOR = '#F5FFF2',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}