import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming/themes';

export const useThemeHeaderAonMotorStyle = makeStyles((theme: Theme) => {
    const isDark = THEME_MODES.DARK === theme.palette.type;
    return {
        header: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            height: 100,
            alignItems: 'center',
            flexDirection: 'row',
            padding: `${theme.spacing(3)}px 120px`,
            overflow: 'hidden',
            [theme.breakpoints.down('lg')]: {
                justifyContent: 'space-between',
                padding: `0 ${theme.spacing(3)}px`,
            },
            [theme.breakpoints.down('md')]: {
                height: 80,
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        logoContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        portalLogo: {
            cursor: 'pointer',
        },
        customerLogo: {
            cursor: 'pointer',
        },
        departmentFiltersContainer: {
            flexWrap: 'nowrap',
            width: 'fit-content',
            minWidth: 850,
            padding: `0 ${theme.spacing(6)}px`,
            gap: 20,
            '& .MuiChip-root': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            },
            '& .MuiAutocomplete-endAdornment': {
                top: 8,
                [theme.breakpoints.down('md')]: {
                    top: 4,
                },
            },
            [theme.breakpoints.down('lg')]: {
                minWidth: 700,
                gap: 10,
            },
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.spacing(1)}px`,
                minWidth: 550,
                gap: 10,
            },
        },
        departmentFilter: {
            width: '50%',
            minWidth: 300,
            maxWidth: 400,
            alignSelf: 'center',
            '& p': {
                maxWidth: '70%',
                paddingTop: 5,
                paddingBottom: 5,
                overflow: 'hidden',
            },
            '& .MuiAutocomplete-root': {
                width: '100%',
            },
            '& .MuiInputBase-root,.MuiAutocomplete-root': {
                minHeight: 50,
                [theme.breakpoints.down('md')]: {
                    minHeight: 40,
                },
            },
            '& .MuiAutocomplete-endAdornment': {
                top: 8,
                [theme.breakpoints.down('md')]: {
                    top: 4,
                },
            },
            [theme.breakpoints.down('md')]: {
                minWidth: 200,
                maxWidth: 300,
            },
        },
        sectionsNavigationContainer: {
            maxWidth: 400,
            minWidth: 350,
            justifyContent: 'space-between',
            '& button': {
                fontSize: '1.4rem',
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                color: theme.palette.secondary.contrastText,
                transition: '0.2s',
                '&:hover': {
                    backgroundColor: theme.palette.info.contrastText,
                    color: theme.palette.secondary.main,
                    opacity: 0.7,
                },
            },
        },
        switcherLabel: {
            fontSize: '1.6rem',
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('md')]: {
                fontSize: '1.4rem',
            },
        },
        switcherContainer: {
            width: 'fit-content',
            margin: `0 ${theme.spacing(5)}px`,
            [theme.breakpoints.down('md')]: {
                margin: `0 ${theme.spacing(1)}px`,
            },
        },
        openProfileButton: {
            paddingLeft: theme.spacing(5),
            fontSize: '1.4rem',
            color: theme.palette.secondary.contrastText,
            borderLeft: `1px solid ${theme.palette.primary.light}`,
            borderRadius: 0,
        },
        logoWrapper: {
            width: 208,
            height: 41,
            cursor: 'pointer',
            [theme.breakpoints.down('md')]: {
                width: 150,
                height: 29,
            },
            '& svg': {
                width: '100%',
                height: '100%',
                '& g': {
                    fill: isDark
                        ? theme.palette.primary.contrastText
                        : theme.palette.secondary.dark,
                },
            },
        },
        logo: {
            cursor: 'pointer',
            filter: isDark ? 'brightness(0) invert(1)' : null,
        },
        customerLogoContainer: {
            flexGrow: 1,
            minWidth: 100,
            maxWidth: 250,

            '& img': {
                maxWidth: '100%',
                filter: 'initial',
            },
        },
        administrationNavigationContainer: {
            margin: '0 auto',
            minWidth: 'auto',
            maxWidth: 'fit-content',
            width: 'fit-content',
            '& .MuiButton-root': {
                marginRight: theme.spacing(2),
            },
        },
        activeSectionButton: {
            backgroundColor: theme.palette.primary.main,
            color: `${theme.palette.primary.contrastText}!important`,
        },
    };
});
