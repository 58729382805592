import React, { BaseSyntheticEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';

import {
    useStyle,
    useInputStyles,
    useFormControlStyles,
    useFormControlLabelStyles,
} from '../../damage-report-form-styles/damage-report-form-styles';

interface DamageReportFormAccidentSceneBlockProps {
    readonly: boolean;
    road: string;
    plz: string;
    place: string;
    country: string;
    handleChangeValue: (e: BaseSyntheticEvent) => void;
}

export const DamageReportFormAccidentSceneBlock = memo(
    (props: DamageReportFormAccidentSceneBlockProps): JSX.Element => {
        const { t } = useTranslation(['common', 'damages-report']);
        const classes = useStyle();
        const inputClasses = useInputStyles();
        const formControlClasses = useFormControlStyles();
        const formControlLabelClasses = useFormControlLabelStyles();

        const { road, plz, place, country, readonly, handleChangeValue } = props;

        return (
            <Grid container spacing={2}>
                <Typography variant='h6' className={classes.formBlockTitle}>
                    {t('damages-report:accidentScene.title')}
                </Typography>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={road}
                                    onChange={handleChangeValue}
                                    name='accidentSceneStreet'
                                />
                            }
                            label={t('damages-report:accidentScene.road')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={plz}
                                    onChange={handleChangeValue}
                                    name='accidentScenePostcode'
                                />
                            }
                            label={t('damages-report:accidentScene.postCode')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={place}
                                    onChange={handleChangeValue}
                                    name='accidentSceneCity'
                                />
                            }
                            label={t('damages-report:accidentScene.place')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
                <Grid item className={clsx(classes.thirdPart)}>
                    <FormControl
                        fullWidth
                        classes={formControlClasses}
                        variant='outlined'
                    >
                        <FormControlLabel
                            classes={formControlLabelClasses}
                            control={
                                <OutlinedInput
                                    disabled={readonly}
                                    classes={inputClasses}
                                    value={country}
                                    onChange={handleChangeValue}
                                    name='accidentSceneCountry'
                                />
                            }
                            label={t('damages-report:accidentScene.country')}
                            labelPlacement='top'
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
);
