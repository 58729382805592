import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { useDocumentFormats } from 'src/shared/hooks';

import { ContractsEditForm } from '../contracts-edit-form/contracts-edit-form';

import { ThemeLoader } from 'src/theming';

import { useStyle } from './contracts-details-view-styles';
import { ContractsDetailsViewData } from './contracts-details-view-data';
import { ContractsDetailsViewDocuments } from './contracts-details-view-documents';
import { ContractDetailsViewDetails } from './contract-details-view-details';
import { useCallback } from 'react';
import { useAuth } from '../../user-management/context-auth';
import { INTERNAL_ROLES } from '../../shared/constants';
import { useGetGeneralContractDetails } from './use-get-general-contract-details';

export const ContractsDetailsView = (): JSX.Element => {
    const classes = useStyle();
    const router = useHistory();
    const { state } = useLocation<{ prevPath: string; prevState: unknown }>();

    const [isMounted, setMounted] = useState(false);
    const [isContractEditOpen, setContractEditOpen] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();

    const documentFormats = useDocumentFormats();

    useEffect(() => setMounted(true), []);

    const [isLoadingContractData, contractData] = useGetGeneralContractDetails(id);

    const handleGoBack = (): void => {
        if (state?.prevPath) {
            router.push(state?.prevPath, state?.prevState);
        } else {
            router.goBack();
        }
    };

    const handleOpenContractEdit = useCallback((): void => {
        setContractEditOpen(true);
    }, [setContractEditOpen]);

    const handleCloseContractEdit = (): void => {
        setContractEditOpen(false);
    };

    const titleValue = useMemo(() => {
        if (contractData) {
            const titlePriority = {
                customer: 3,
                contractNumber: 2,
                divisionName: 1,
            };
            const valuesForTitle = Object.entries(contractData)
                .filter(
                    (item) =>
                        (item[0] === 'customer' && Boolean(item[1])) ||
                        (item[0] === 'contractNumber' && Boolean(item[1])) ||
                        (item[0] === 'divisionName' && Boolean(item[1]))
                )
                .sort((a, b) => titlePriority[b[0]] - titlePriority[a[0]])
                .map((item) => {
                    return item[1];
                })
                .join(' - ');
            return valuesForTitle;
        }
    }, [contractData]);

    const dataForChangeContractModalWindow = {
        customerConnection: contractData?.customerConnection,
        customer: contractData?.customer,
        contractNumber: contractData?.contractNumber,
        division: contractData?.division,
        licenseNumber: contractData?.risk,
        product: contractData?.product,
        insurer: contractData?.insuranceCompany,
        address: contractData?.address,
    };

    const {
        userData: {
            userInfo: { roles: userRoles },
        },
    } = useAuth();

    const hideActions = useMemo(() => {
        return userRoles.includes(INTERNAL_ROLES.ADMIN_DOMAIN) && userRoles.length === 1;
    }, [userRoles]);

    return (
        isMounted && (
            <Grid container className={classes.pageContainer}>
                <Grid container className={classes.titleContainer}>
                    <IconButton
                        size='small'
                        className={classes.backButton}
                        onClick={handleGoBack}
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant='h5' className={classes.title}>
                        {titleValue}
                    </Typography>
                </Grid>
                <Grid container item spacing={2} className={classes.cardsContainer}>
                    <Grid item className={classes.contractDataContainer}>
                        <ContractsDetailsViewData contractData={contractData} />
                    </Grid>
                    <Grid item className={classes.documentsContainer}>
                        <ContractsDetailsViewDocuments
                            documentFormats={documentFormats}
                            hideUploadDocument={hideActions}
                            contractId={contractData?.amsIdNr}
                        />
                    </Grid>
                    <Grid item className={classes.contractDetailsContainer}>
                        <ContractDetailsViewDetails
                            documentFormats={documentFormats}
                            hideThemeNestedMenu={hideActions}
                            division={contractData?.division}
                            handleOpenContractEdit={handleOpenContractEdit}
                            contractNumber={contractData?.contractNumber}
                        />
                    </Grid>
                </Grid>
                {isLoadingContractData && <ThemeLoader />}
                <ContractsEditForm
                    documentFormats={documentFormats}
                    isOpen={isContractEditOpen}
                    handleClose={handleCloseContractEdit}
                    contractData={dataForChangeContractModalWindow}
                />
            </Grid>
        )
    );
};
