import { makeStyles, Theme } from '@material-ui/core';

export const useDamageReportFormDynamicFieldsRowStyles = makeStyles((theme: Theme) => {
    return {
        iconButton: {
            width: 30,
            height: 30,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(4),
            borderRadius: 4,
        },
        iconAddButton: {
            marginLeft: theme.spacing(2),
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.main,
        },
        iconDeleteButton: {
            marginLeft: theme.spacing(2),
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
    };
});
