import React, { ReactElement, ChangeEvent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { UseFormSetValue } from 'react-hook-form';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { UserEditingFormData } from './user-edit-form-types';
import { useCardStyle, useStyle } from './user-editing-form-styles';

interface UserAutomaticCustomersAssigningProps {
    addNewCustomersAutomatically: boolean;
    setValue: UseFormSetValue<UserEditingFormData>;
    editMode: boolean;
}

export const UserAutomaticCustomersAssigning = ({
    addNewCustomersAutomatically,
    setValue,
    editMode,
}: UserAutomaticCustomersAssigningProps): ReactElement => {
    const classes = useCardStyle();
    const generalClasses = useStyle();
    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setValue(e.target.name as 'addNewCustomersAutomatically', checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={handleChange}
                    disabled={!editMode}
                    checked={addNewCustomersAutomatically}
                    name='addNewCustomersAutomatically'
                    icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
                    checkedIcon={
                        <CheckBoxIcon
                            className={clsx(classes.icon, classes.checkedIcon)}
                        />
                    }
                />
            }
            label={t('user-management:umSection.form.autoCustomerAssigning')}
            className={generalClasses.customerAutocompleteAssigning}
        />
    );
};
