import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18-next.config';

ReactDOM.render(
    <Suspense fallback={<React.Fragment />}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Suspense>,
    document.getElementById('root')
);
