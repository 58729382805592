import React, { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormControlLabel, Grid, GridSize } from '@material-ui/core';

import { ThemeCurrencyField } from 'src/theming';

import {
    useFormControlStyles,
    useFormControlLabelStyles,
    useContractsCreateContractsFormStyles,
} from '../contracts-create-contract-form-styles';

interface CurrencyFieldProps {
    md: GridSize;
    label: string;
    name: string;
    value: string;
    handleChangeValue: (name, value) => void;
    error?: boolean;
}

export const CurrencyField = ({
    md,
    label,
    name,
    value,
    error,
    handleChangeValue,
}: CurrencyFieldProps): JSX.Element => {
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const classes = useContractsCreateContractsFormStyles();

    const { t } = useTranslation(['create-contract']);

    const onChange = (e: BaseSyntheticEvent): void => {
        handleChangeValue(e.target.name, e.target.value);
    };

    return (
        <Grid item md={md}>
            <FormControl
                fullWidth
                classes={formControlClasses}
                className={classes.dateField}
                variant='outlined'
                error={error}
            >
                <FormControlLabel
                    classes={formControlLabelClasses}
                    control={
                        <ThemeCurrencyField
                            value={value}
                            onChange={onChange}
                            name={name}
                            decimalPlaces={2}
                            error={error}
                        />
                    }
                    label={t(label)}
                    labelPlacement='top'
                />
            </FormControl>
        </Grid>
    );
};
