import React, { BaseSyntheticEvent, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import uniqid from 'uniqid';
import clsx from 'clsx';
import { useParams } from 'react-router';

import { ThemeCircularProgress, ThemeLoader, ThemeSelectInput } from 'src/theming';
import { useAllCustomerConnections } from 'src/shared/hooks';

import { CustomerAddingFormData } from './customer-add-form-types';
import { useStyle } from '../customer-details-view/customer-details-view-styles';
import {
    useCustomerAddFormStyle,
    useFormControlLabelStyles,
    useFormControlStyles,
    useInputStyles,
} from './customer-add-form-styles';
import { DamageReportFormDynamicFieldsRow } from './customer-add-form-dynimic-fields-row';

import { CustomerFormFullInput } from './customer-form-input';
import { customerAddFormValidationSchema } from './customer-add-form-validation-schema';
import { customerAddFormDefaultValues } from './customer-add-form-default-values';

import { useGetCustomer } from '../customer-requests';
import { useMutateCustomer } from './customer-add-form-use-mutate-customer';
import { customerAddFormGetCustomerFormatter } from './customer-add-form-get-customer-formatter';
import { useCheckDummyCustomer } from './customer-add-form-use-check-dummy-customer';
import { CustomerAddFormAutoComplete } from './customer-add-form-autoComplete';

export interface CustomerRow {
    customerTrigger: string;
    customer: { id: string; customerName: string }[];
}

export const CustomerAddForm = (): JSX.Element => {
    const classes = useStyle();
    const { t } = useTranslation(['customer-form']);
    const customerFormClasses = useCustomerAddFormStyle();
    const formControlClasses = useFormControlStyles();
    const formControlLabelClasses = useFormControlLabelStyles();
    const inputClasses = useInputStyles();
    const { id } = useParams<{ id?: string }>();

    const [customerConnections, updateCustomerConnections] = useAllCustomerConnections();

    const customerConnectionSelectItems = customerConnections?.map((item) => ({
        title: item,
        value: item,
    }));

    const {
        watch,
        setValue,
        reset,
        formState: { isValid, errors },
        trigger,
        control,
        handleSubmit,
    } = useForm<CustomerAddingFormData>({
        mode: 'onChange',
        resolver: yupResolver(customerAddFormValidationSchema),
        defaultValues: customerAddFormDefaultValues,
    });
    const { fields: customersFields, prepend: customerPrepend } = useFieldArray({
        control,
        name: 'customers',
    });

    const onUpdateSuccess = (): void => {
        refetch();
        updateCustomerConnections();
    };

    const [isGettingCustomerLoading, customerData, refetch] =
        useGetCustomer<CustomerAddingFormData>(id, customerAddFormGetCustomerFormatter);
    const [isMutationLoading, mutateCustomer] = useMutateCustomer(id, onUpdateSuccess);

    const [name, street, postCode, city, customerName, customers, countryCode] = watch([
        'name',
        'street',
        'postCode',
        'city',
        'customerName',
        'customers',
        'countryCode',
    ]);

    const checkingData = useCheckDummyCustomer(customersFields[0]?.name, name);

    useEffect(() => {
        if (customerData) {
            reset(customerData);
        }
    }, [customerData]);

    const handleAddRow = useCallback((): void => {
        setValue('customerName', '');

        const newRow = {
            id: uniqid(),
            name: '',
        };
        customerPrepend(newRow);
        trigger();
    }, [customersFields?.length]);

    const handleDeleteRow = useCallback(
        (id: string): void => {
            const filteredRows = customersFields?.filter((item) => item.id !== id);
            setValue('customers', filteredRows);
            trigger();
        },
        [customersFields]
    );

    const handleChangeValue = (e: BaseSyntheticEvent): void => {
        if (e.target.name === 'customerName' && e.target.value) {
            setValue('customers', []);
        }
        setValue(e.target.name, e.target.value);
        trigger();
    };

    const handleOnChangeRow = useCallback(
        (id, row) => {
            const newRows = customersFields?.map((item) => {
                if (item.id === id) {
                    return { ...row };
                }
                return item;
            });
            setValue('customers', newRows);
            trigger();
        },
        [customersFields]
    );
    const handleCancel = (): void => {
        history.back();
    };

    return (
        <>
            {isGettingCustomerLoading && <ThemeLoader />}
            {!isGettingCustomerLoading && (
                <Grid container className={classes.customerDetailsContainer}>
                    <Grid
                        container
                        component='form'
                        onSubmit={handleSubmit(mutateCustomer)}
                    >
                        <Grid container item className={customerFormClasses.header}>
                            <Typography
                                variant='h5'
                                className={customerFormClasses.formTitle}
                            >
                                {!id
                                    ? t('customer-form:createCustomer')
                                    : t('customer-form:editCustomer')}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            className={customerFormClasses.staticFieldsContainer}
                        >
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    classes={formControlClasses}
                                    variant='outlined'
                                    error={Boolean(errors.customerName)}
                                >
                                    <FormControlLabel
                                        label={t('customer-form:customerName')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <ThemeSelectInput
                                                editMode
                                                name='customerName'
                                                value={customerName}
                                                onChange={handleChangeValue}
                                                items={customerConnectionSelectItems}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <DamageReportFormDynamicFieldsRow
                                    handleAddRow={handleAddRow}
                                    handleDeleteRow={handleDeleteRow}
                                    handleOnChangeRow={handleOnChangeRow}
                                    rows={customers?.map((item) => ({
                                        id: item.id,
                                        formElements: [
                                            {
                                                Item: CustomerFormFullInput,
                                                label: 'customer-form:customerConnectionName',
                                                name: 'name',
                                                value: item.name,
                                                maxlength: 20,
                                                error:
                                                    Boolean(checkingData?.isWarning) ||
                                                    Boolean(checkingData.error) ||
                                                    Boolean(errors.customers),
                                            },
                                        ],
                                    }))}
                                    classes={clsx(
                                        customerFormClasses.iconButton,
                                        customerFormClasses.iconAddButton
                                    )}
                                    t={t}
                                    className={customerFormClasses.textField}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors?.name)}
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t('customer-form:name')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <OutlinedInput
                                                classes={inputClasses}
                                                value={name}
                                                onChange={handleChangeValue}
                                                name='name'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors?.street)}
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t('customer-form:street')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <OutlinedInput
                                                classes={inputClasses}
                                                value={street}
                                                onChange={handleChangeValue}
                                                name='street'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors?.postCode)}
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t('customer-form:postCode')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <OutlinedInput
                                                type='number'
                                                classes={inputClasses}
                                                value={postCode}
                                                onChange={handleChangeValue}
                                                name='postCode'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors?.city)}
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t('customer-form:city')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <OutlinedInput
                                                classes={inputClasses}
                                                value={city}
                                                onChange={handleChangeValue}
                                                name='city'
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(errors?.postCode)}
                                    classes={formControlClasses}
                                    variant='outlined'
                                >
                                    <FormControlLabel
                                        label={t('customer-form:country')}
                                        labelPlacement='top'
                                        classes={formControlLabelClasses}
                                        control={
                                            <CustomerAddFormAutoComplete
                                                countryCode={countryCode}
                                                setValue={setValue}
                                                error={Boolean(errors?.countryCode)}
                                                trigger={trigger}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            className={customerFormClasses.formControlButtons}
                        >
                            <>
                                <Button
                                    disableElevation
                                    disabled={
                                        !isValid ||
                                        isMutationLoading ||
                                        checkingData?.isWarning ||
                                        Boolean(checkingData.error)
                                    }
                                    startIcon={
                                        <ThemeCircularProgress
                                            isLoading={isMutationLoading}
                                        />
                                    }
                                    type='submit'
                                    variant='contained'
                                    className={customerFormClasses.containedButton}
                                >
                                    {t('customer-form:save')}
                                </Button>
                                <Button
                                    disableElevation
                                    disabled={isMutationLoading}
                                    variant='outlined'
                                    className={customerFormClasses.outlinedButton}
                                    onClick={handleCancel}
                                >
                                    {t('customer-form:cancel')}
                                </Button>
                            </>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
