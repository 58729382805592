import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ThemeCircularProgress, ThemeModalWindow } from 'src/theming';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmationStyle } from 'src/lib/confirmation-popup/confirmation-styles';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    text: string;
    confirmText?: string;
    closeText?: string;
}

export const Confirmation = ({ isOpen, onClose, onConfirm, text }) => {
    const { t } = useTranslation(['common']);
    const classes = useConfirmationStyle();
    const [isLoading, setIsLoading] = useState(false);

    const onYes = async (): Promise<void> => {
        setIsLoading(true);
        try {
            await onConfirm();
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <ThemeModalWindow isOpen={isOpen} onClose={onClose}>
                <></>
                <Typography variant='body1' className={classes.modalMessage}>
                    {text}
                </Typography>
                <Grid container className={classes.modalActionsContainer}>
                    <Button
                        variant='outlined'
                        className={clsx(classes.modalButton, classes.cancelButton)}
                        onClick={onClose}
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        disableElevation
                        variant='contained'
                        className={clsx(classes.modalButton, classes.okButton)}
                        onClick={onConfirm}
                        disabled={isLoading}
                        startIcon={<ThemeCircularProgress isLoading={isLoading} />}
                    >
                        {t('common:yes')}
                    </Button>
                </Grid>
            </ThemeModalWindow>
        </>
    );
};
