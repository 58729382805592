import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';
import { CustomersList, CustomerListWithData } from 'src/customers';

export const CustomersPage = (): JSX.Element => {
    const darkMode = useDarkMode();
    return (
        <ThemeLayout darkMode={darkMode}>
            <CustomerListWithData>
                <CustomersList />
            </CustomerListWithData>
        </ThemeLayout>
    );
};
