import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ResponseError } from 'src/shared/types';
import { TokenResponse, UserSettingsDto } from '../context-auth-types';
import Cookies from 'js-cookie';

interface useCheckAuthProps {
    setAuth: Dispatch<SetStateAction<boolean>>;
    setUserData: (data: UserSettingsDto) => void;
}

interface useCheckAuthReturn {
    checkAuth: () => Promise<void>;
}

function isCookiePresent(cookieName: string): boolean {
    // Get all cookies as a single string
    const cookies = document.cookie;

    // Create a regex pattern to search for the cookie name
    const pattern = new RegExp('(^|; )' + encodeURIComponent(cookieName) + '=');

    // Test if the pattern exists in the cookies string
    return pattern.test(cookies);
}

export const useCheckAuth = ({
    setAuth,
    setUserData,
}: useCheckAuthProps): useCheckAuthReturn => {
    const httpClient = useHttpClient();
    const checkAuth = useCallback(async (): Promise<void> => {
        try {
            await httpClient.post(`users/refresh-token`);
            setAuth(true);
        } catch (response) {
            const refreshTokenErrors: string[] = (response as ResponseError)?.error
                ?.response?.data?.errors?.refreshToken;
            if (
                !refreshTokenErrors ||
                (Array.isArray(refreshTokenErrors) &&
                    refreshTokenErrors?.length > 0 &&
                    refreshTokenErrors?.some(
                        (item) =>
                            item === "Refresh token doesn't match the saved token" ||
                            item === 'Refresh token has expired, user needs to relogin' ||
                            item === 'Refresh token has been revoked.' ||
                            item === "Refresh token doesn't exist."
                    ))
            ) {
                setAuth(false);
                setUserData(null);
            }
        }
    }, [httpClient, setAuth, setUserData]);

    return { checkAuth };
};
