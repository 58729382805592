import { useState, useEffect } from 'react';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { SelectItem } from 'src/theming';

interface DummyCustomerDTO {
    amsIdNr: string;
    customer: string;
    isDummy: boolean;
}

export const useDummyCustomers = (customerConnection: string): SelectItem[] => {
    const httpClient = useHttpClient();

    const [dummyCustomers, setDummyCustomers] = useState<SelectItem[]>([]);

    const getDummyCustomers = async (customerConnection: string): Promise<void> => {
        if (customerConnection) {
            const data = await httpClient.get<DummyCustomerDTO[]>(
                `customers/relink?customerConnection=${customerConnection}&isDummy=true`
            );
            if (Array.isArray(data)) {
                setDummyCustomers(
                    data
                        ?.map((item) => ({ title: item.customer, value: item.amsIdNr }))
                        .sort((a, b) => a.title.localeCompare(b.title))
                );
            }
        }
    };

    useEffect(() => {
        getDummyCustomers(customerConnection);
    }, [customerConnection]);

    return dummyCustomers;
};
