import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';
import {
    isAonDigitalInstance,
    isAonMotorInstance,
    isHectorDemoInstance,
} from 'src/environment';

export const useStyle = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        addFaqBtn: {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: 0,
            transition: '0.2s',
            '&:hover': {
                opacity: 0.8,
            },
        },
        modalTitle: {
            color: theme.palette.primary.contrastText,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        saveButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        ckEditorContainer: {
            width: '100%',
            overflowY: 'auto',
            '& .ck-content': {
                minHeight: 250,
            },
            '& .ck-editor': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
                '& .ck-toolbar': {
                    backgroundColor: theme.palette.primary.main,
                    '& path': {
                        fill: theme.palette.primary.contrastText,
                    },
                    '& .ck-button__label': {
                        color: theme.palette.primary.contrastText,
                    },
                    '& .ck-list': {
                        backgroundColor: theme.palette.secondary.main,
                        '& .ck-button__label': {
                            color: theme.palette.secondary.contrastText,
                        },
                    },
                },
                '&__main': {
                    '&>.ck-editor__editable': {
                        backgroundColor: `${
                            theme.palette.type === THEME_MODES.DARK
                                ? `#112A3E`
                                : theme.palette.primary.light
                        }!important`,
                    },
                },
            },
        },
        ckEditorLabel: {
            padding: '0!important',
            fontSize: '1.4rem',
            lineHeight: 1.5,
            backgroundColor: () => {
                if (isAonDigitalInstance || isAonMotorInstance || isHectorDemoInstance) {
                    return `${
                        isDarkMode
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light
                    }!important`;
                }
                return `${theme.palette.primary.light}!important`;
            },
            color: theme.palette.primary.contrastText,
            opacity: 0.6,
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            margin: `${theme.spacing(2)}px 0`,
            '& label': {
                fontSize: '2.4rem',
                backgroundColor: () => {
                    if (
                        isAonDigitalInstance ||
                        isAonMotorInstance ||
                        isHectorDemoInstance
                    ) {
                        return `${
                            isDarkMode
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light
                        }!important`;
                    }
                    return `${theme.palette.primary.light}!important`;
                },
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles(() => {
    return {
        root: {
            display: 'flex!important',
            alignItems: 'flex-start',
            margin: 0,
            padding: `0!important`,
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    const isDarkMode = THEME_MODES.DARK === theme.palette.type;
    return {
        root: {
            width: '100%',
            height: 40,
            backgroundColor: () => {
                if (isAonMotorInstance || isAonDigitalInstance || isHectorDemoInstance) {
                    return isDarkMode
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light;
                }
                return theme.palette.primary.light;
            },
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            '&:-internal-autofill-selected': {
                boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.palette.primary.main}`,
            },
        },
    };
});
