import React from 'react';
import { TFunction } from 'i18next';

import { Divisions } from 'src/shared/constants';

import StoreIcon from '@material-ui/icons/Store';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HealingIcon from '@material-ui/icons/Healing';
import WorkIcon from '@material-ui/icons/Work';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WidgetsIcon from '@material-ui/icons/Widgets';

import {
    HardHatIcon,
    GlasIcon,
    HumanCaneIcon,
    CashIcon,
    NoteTextIcon,
    SofaIcon,
    FactoryIcon,
    ManufacturingIcon,
    MontageIcon,
    TranspIcon,
} from './division-icons';
import { DivisionWidgetDto } from 'src/user-management/context-auth-types';

export interface Card {
    key: string;
    title: string;
    icon?: JSX.Element;
    isShow?: boolean;
}

const getDivisionIcon = (division: string): JSX.Element => {
    switch (division) {
        case Divisions.KFZW: {
            return <DirectionsCarIcon />;
        }
        case Divisions.BHV:
        case Divisions.COMPANY_PRODUCT_LIABILITY:
        case Divisions.PUBLIC_LIABILITY: {
            return <StoreIcon />;
        }
        case Divisions.GBW:
        case Divisions.BUILDING:
        case Divisions.BUNDLED_BUILDINGS: {
            return <LocationCityIcon />;
        }
        case Divisions.PHV:
        case Divisions.AON_PRIVATE:
        case Divisions.PERSONAL_LIABILITY: {
            return <HealingIcon />;
        }
        case Divisions.BAULEIST:
        case Divisions.AON_FOCUS_CONSTRUCTION_WORK:
        case Divisions.CONSTRUCTION_WORK: {
            return <HardHatIcon fill='white' />;
        }
        case Divisions.ELECTRO:
        case Divisions.AON_ELECTRONICS_PLATINUM:
        case Divisions.ELECTRONICS:
        case Divisions.ELECTRONICS_SOLAR_WATT:
        case Divisions.ELECTRONICS_SOLARWORLD:
        case Divisions.ELECTRONICS_SONEPAR:
        case Divisions.ELECTRONICS_PACKAGE: {
            return <BatteryChargingFullIcon />;
        }
        case Divisions.BU:
        case Divisions.FBU_INDUSTRY:
        case Divisions.MACHINE_BU: {
            return <CashIcon fill='white' />;
        }
        case Divisions.DREISE:
        case Divisions.FOREIGN_TRAVEL:
        case Divisions.AON_FOCUS_LUGGAGE:
        case Divisions.MOTOR_VEHICLE_BUSINESS_TRIP:
        case Divisions.LUGGAGE: {
            return <WorkIcon />;
        }
        case Divisions.GESCH: {
            return <NoteTextIcon fill='white' />;
        }
        case Divisions.GLAS: {
            return <GlasIcon fill='white' />;
        }
        case Divisions.HR:
        case Divisions.ALL_DANGEROUS_HOUSEHOLD_ITEMS:
        case Divisions.HOUSEHOLD_ITEMS: {
            return <SofaIcon fill='white' />;
        }
        case Divisions.IS: {
            return <FactoryIcon fill='white' />;
        }
        case Divisions.LVR:
        case Divisions.LIFE: {
            return <FavoriteIcon />;
        }
        case Divisions.MACHINE:
        case Divisions.MACHINERY:
        case Divisions.MACHINE_FRAMEWORK_AGREEMENT:
        case Divisions.MACHINES_CONSTRUCTION_EQUIPMENT: {
            return <ManufacturingIcon fill='white' />;
        }
        case Divisions.MONTAGE:
        case Divisions.AON_ASSEMBLY:
        case Divisions.ASSEMBLY: {
            return <MontageIcon fill='white' />;
        }
        case Divisions.PRV:
        case Divisions.PENSIONS: {
            return <HumanCaneIcon fill='white' />;
        }
        case Divisions.TRANSP:
        case Divisions.AON_FOCUS_TRANSPORT:
        case Divisions.TRANSPORTATION_GOODS: {
            return <TranspIcon fill='white' />;
        }
        case Divisions.RS:
        case Divisions.AON_FOCUS_ON_CRIMINAL_LAW:
        case Divisions.VEHICLE_LEGAL_PROTECTION:
        case Divisions.FAMILY_LEGAL_PROTECTION:
        case Divisions.COMPANY_PROFESSIONAL_JURISPRUDENCE:
        case Divisions.LAND_TENANT_LAW:
        case Divisions.PRIVATE_TRAFIC_LEGAL_PROTECTION:
        case Divisions.LEGAL_PROTECTION_FOR_SELF_EMPLOYED:
        case Divisions.LEGAL_PROTECTION:
        case Divisions.SPECIAL_CRIMINAL_LAW_PROTECTION:
        case Divisions.TRAFFIC_LEGAL_PROTECTION:
        case Divisions.PROPERTY_LEGAL_PROTECTION: {
            return <VerifiedUserIcon />;
        }
        default: {
            return <WidgetsIcon />;
        }
    }
};

export const generateDivisions = (
    t: TFunction,
    divisions: DivisionWidgetDto[],
    positions: { division: string; position: number }[]
): Card[] => {
    return divisions
        ?.sort(
            (a, b) =>
                positions.find((item) => item.division === a.value)?.position -
                positions.find((item) => item.division === b.value)?.position
        )
        .map((item) => {
            return {
                key: item.value,
                title: item.label,
                icon: getDivisionIcon(item.value),
            };
        });
};
