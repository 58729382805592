export enum LIGHT_AON_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#F9F9FB',
    PRIMARY_LIGHT_COLOR = '#FFFFFF',
    PRIMARY_CONTRAST_TEXT = '#000000',
    SECONDARY_MAIN_COLOR = '#DF2027',
    SECONDARY_LIGHT_COLOR = 'rgba(223, 32, 39, 0.7)',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#DF2027',
    INFO_LIGHT_COLOR = 'rgba(223, 32, 39, 0.7)',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}

export enum DARK_AON_THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#1A1C28',
    PRIMARY_LIGHT_COLOR = '#20222E',
    PRIMARY_CONTRAST_TEXT = '#FFFFFF',
    SECONDARY_MAIN_COLOR = '#0A1B29',
    SECONDARY_LIGHT_COLOR = 'rgba(12, 63, 102, 0.9)',
    SECONDARY_CONTRAST_TEXT = '#FFFFFF',
    INFO_MAIN_COLOR = '#DF2027',
    INFO_LIGHT_COLOR = 'rgba(223, 32, 39, 0.7)',
    INFO_CONTRAST_TEXT = '#FFFFFF',
    ERROR_MAIN_COLOR = '#CB362D',
    ERROR_LIGHT_COLOR = 'rgba(203, 54, 45, 0.1)',
}
