import moment from 'moment';
import uniqid from 'uniqid';
import { DamageReportFormGetRecordDTO } from '../../damage-report-form-types/damage-report-form-get-record-dto';
import { DamageReportFormPropertyTypes } from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-property-types';

export const damageReportFormPropertyGetAdapter = (
    data: DamageReportFormGetRecordDTO,
    divisionState?: string
): DamageReportFormPropertyTypes => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: divisionState,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() + offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() + offset).toISOString()
            : null,

        risk: data.risk,
        contractNumber: data.contractNumber,
        contractId: data.contractId,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        fileNumberInsuredCompany: data.propertyInsuranceInfo?.fileNumberInsuredCompany,
        inputTaxDeductionForTheDamage:
            data.propertyInsuranceInfo?.isInputTaxDeductionForDamage,
        damageBeenReportedToThePolice:
            data.policeRecordTechnical?.incidentRecordedByPolice,
        existenceForItemAffectedByTheDamage:
            data?.propertyInsuranceInfo?.isExistenceItemAffectedByDamage,
        insurer: data?.propertyInsuranceInfo?.insurer,
        areBusinessInterruptionLossesToBeExpected:
            data?.propertyInsuranceInfo?.isBusinessInterruptionLossesExpected,
        policeStation: data.policeRecordTechnical?.policeStation,
        fileNumber: data.policeRecordTechnical?.policeFileNumber,
        typeOfDamage: data.propertyInsuranceInfo?.damageType,
        causedByThirdParty: data.propertyInsuranceInfo?.causedByThirdParty,
        itemsAffectedByTheDamage:
            Array.isArray(data?.propertyInsuranceInfo?.propertyDamagedItems) &&
            data?.propertyInsuranceInfo?.propertyDamagedItems?.length > 0
                ? data?.propertyInsuranceInfo?.propertyDamagedItems?.map((item) => ({
                      id: uniqid(),
                      numberAndTypeOfThings: item.thingsNumberAndType,
                      replacementPrice: item.replacementPrice
                          ? String(item.replacementPrice).replace('.', ',')
                          : '',
                      repairCosts: item.repairCosts
                          ? String(item.repairCosts).replace('.', ',')
                          : '',
                  }))
                : [
                      {
                          id: uniqid(),
                          numberAndTypeOfThings: '',
                          replacementPrice: '',
                          repairCosts: '',
                      },
                  ],
        files: [],
        fileInfos: data.fileInfos,
    };
};
