import React from 'react';
import { GridSize, Grid } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { SelectItem } from 'src/theming';

import {
    CreateContractSelectField,
    AddField,
    TextField,
    DateField,
    CurrencyField,
    DocumentsField,
} from './contracts-create-contract-form-components';
import { FileObject } from 'material-ui-dropzone';

const Plug = ({ md }: { md: GridSize }): JSX.Element => <Grid item md={md} />;

export interface FormItemProps {
    label: string;
    value: unknown | string;
    name: string;
    items?: SelectItem[];
    handleChangeValue?: (name, value) => void;
    disabled?: boolean;
    editMode?: boolean;
    values?: string[];
    md?: GridSize;
    previewText?: string;
    Component?: (props: FormItemProps) => JSX.Element;
    onSwitch?: (val: boolean) => void;
    error?: boolean;
    onAdd?: (newFiles: FileObject[]) => void;
    onRemove?: (index: number) => void;
    documentFormats?: string[];
}

interface Item {
    id?: string;
    Item?: (props: FormItemProps) => JSX.Element;
    label?: string;
    name?: string;
    value?: unknown;
    Component?: (props: FormItemProps) => JSX.Element;
    items?: SelectItem[];
    md?: GridSize;
    previewText?: string;
    onSwitch?: (val: boolean) => void;
    disabled?: boolean;
    onAdd?: (newFiles: FileObject[]) => void;
    onRemove?: (index: number) => void;
    documentFormats?: string[];
}

interface contractsCreateContractGenerateFormProps {
    divisionSelectionItems: SelectItem[];
    insuranceCompanies: SelectItem[];
    currencyItems: SelectItem[];
    division: string;
    insuranceCompany: string;
    addInsuranceCompany: string;
    contractNumber: string;
    startOfContract: ParsableDate;
    contractExpiration: ParsableDate;
    netPremium: string;
    currency: string;
    handleChangeAddNewInsuranceCompany: (val: boolean) => void;
    contractId?: string;
    risk?: string;
    fields?: FileObject[];
    documentFormats?: string[];
}

export const contractsCreateContractGenerateForm = ({
    divisionSelectionItems,
    insuranceCompanies,
    currencyItems,
    insuranceCompany,
    division,
    addInsuranceCompany,
    contractNumber,
    startOfContract,
    contractExpiration,
    netPremium,
    currency,
    handleChangeAddNewInsuranceCompany,
    contractId,
    risk,
    fields,
}: contractsCreateContractGenerateFormProps): Item[] => {
    return [
        {
            id: 'division',
            label: 'division',
            name: 'division',
            value: division,
            Item: CreateContractSelectField,
            md: 3,
            items: divisionSelectionItems,
            disabled: Boolean(contractId),
        },
        {
            id: 'insuranceCompany',
            label: 'insuranceCompany',
            name: 'insuranceCompany',
            value: insuranceCompany,
            Item: CreateContractSelectField,
            md: 3,
            items: insuranceCompanies,
        },
        {
            id: 'addInsuranceCompany',
            name: 'addInsuranceCompany',
            value: addInsuranceCompany,
            label: 'addInsuranceCompany',
            md: 3,
            Item: AddField,
            Component: TextField,
            previewText: 'addInsuranceCompany',
            onSwitch: handleChangeAddNewInsuranceCompany,
        },
        {
            md: 3,
            Item: () => <Plug md={3} />,
        },
        {
            id: 'contractNumber',
            name: 'contractNumber',
            value: contractNumber,
            label: 'contractNumber',
            md: 3,
            Item: TextField,
        },
        {
            id: 'risk',
            name: 'risk',
            value: risk,
            label: 'risk',
            md: 3,
            Item: TextField,
        },
        {
            md: 3,
            Item: () => <Plug md={3} />,
        },
        {
            md: 3,
            Item: () => <Plug md={3} />,
        },
        {
            id: 'startOfContract',
            name: 'startOfContract',
            value: startOfContract,
            label: 'startOfContract',
            md: 3,
            Item: DateField,
        },
        {
            id: 'contractExpiration',
            name: 'contractExpiration',
            value: contractExpiration,
            label: 'contractExpiration',
            md: 3,
            Item: DateField,
        },
        {
            id: 'netPremium',
            name: 'netPremium',
            value: netPremium,
            label: 'netPremium',
            md: 3,
            Item: CurrencyField,
        },
        {
            id: 'currency',
            label: 'currency',
            name: 'currency',
            value: currency,
            Item: CreateContractSelectField,
            md: 3,
            items: currencyItems,
        },
        ...(!contractId
            ? [
                  {
                      id: 'documents',
                      label: 'documents',
                      Item: DocumentsField,
                      md: 6 as GridSize,
                      value: fields,
                  },
              ]
            : []),
    ];
};
