import { TFunction } from 'react-i18next';
import { IThemeTab } from 'src/theming';

export enum RelinkMenu {
    CONTRACTS = 'contracts',
    DAMAGES = 'damages',
}

export const relinkGenerateMenu = (
    t: TFunction<string[]>,
    onChange: (value: RelinkMenu) => void
): IThemeTab[] => {
    return [
        {
            title: t('menu.contracts'),
            action: () => onChange(RelinkMenu.CONTRACTS),
            value: RelinkMenu.CONTRACTS,
        },
        {
            title: t('menu.damages'),
            action: () => onChange(RelinkMenu.DAMAGES),
            value: RelinkMenu.DAMAGES,
        },
    ];
};
