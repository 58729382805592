import { ExpandPanel } from 'src/theming';
import { FAQResponse } from './faq-get-response-body';

export default (data: FAQResponse[]): ExpandPanel[] => {
    return data
        .sort((a, b) => a.position - b.position)
        .map((item: FAQResponse) => ({
            id: item.id,
            header: item.question,
            content: item.answer,
        }));
};
