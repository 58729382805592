import React from 'react';
import useDarkMode from 'use-dark-mode';

import { ThemeLayout } from 'src/theming';
import { DamagesDetailsView } from 'src/contracts/damages-details-view/damages-details-view';

export const DamageDetailsViewPage = (): JSX.Element => {
    const darkMode = useDarkMode();

    return (
        <ThemeLayout darkMode={darkMode}>
            <DamagesDetailsView />
        </ThemeLayout>
    );
};
