import { makeStyles, Theme } from '@material-ui/core/styles';
import { THEME_MODES } from 'src/theming';

export const useCustomerAddFormStyle = makeStyles((theme: Theme) => {
    return {
        header: {
            paddingTop: theme.spacing(4),
            alignItems: 'center',
        },
        formTitle: {
            width: 'fit-content',
        },
        formControlButtons: {
            paddingTop: theme.spacing(4),
            width: 'fit-content',
            '& button': {
                height: 40,
                transition: '0.2s',
                '&:not(:last-child)': {
                    marginRight: theme.spacing(2),
                },
            },
        },
        containedButton: {
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': {
                backgroundColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                opacity: 0.8,
            },
        },
        outlinedButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
            '&:hover': {
                borderColor:
                    theme.palette.type === THEME_MODES.DARK
                        ? theme.palette.info.main
                        : theme.palette.secondary.main,
                opacity: 0.8,
            },
        },
        addCustomerButton: {
            color: theme.palette.info.main,
        },
        editButton: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
            backgroundColor: theme.palette.info.light,
            color:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.contrastText
                    : theme.palette.info.main,
        },
        staticFieldsContainer: {
            gap: theme.spacing(3),
            paddingTop: theme.spacing(4),
        },
        innerFieldsForm: {
            minWidth: 200,
            width: `${100 / 4}%`,
        },
        switchContainer: {
            display: 'flex',
            height: 40,
            alignItems: 'center',
        },
        dynamicFieldsContainer: {
            width: '100%',
            borderTop: `1px solid ${
                theme.palette.grey[
                    theme.palette.type === THEME_MODES.DARK ? '400' : '100'
                ]
            }`,
        },
        deleteUserButton: {
            width: 30,
            height: 30,
            marginLeft: theme.spacing(2),
            borderRadius: 4,
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        modalActionsContainer: {
            gap: theme.spacing(3),
            justifyContent: 'flex-end',
        },
        modalButton: {
            color: theme.palette.primary.contrastText,
        },
        okButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        cancelButton: {
            borderColor:
                theme.palette.type === THEME_MODES.DARK
                    ? theme.palette.info.main
                    : theme.palette.secondary.main,
        },
        modalMessage: {
            maxWidth: 500,
        },
        iconButton: {
            width: 30,
            height: 30,
            marginTop: theme.spacing(6),
            borderRadius: 4,
        },
        iconAddButton: {
            marginLeft: theme.spacing(2),
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.main,
        },
        iconDeleteButton: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        textField: {
            fontSize: '1.4rem',
            color: theme.palette.primary.contrastText,
            opacity: 0.6,
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(6),
        },
    };
});

export const useFormControlStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            // margin: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
            '& label': {
                fontSize: '2.4rem',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.info.main,
            },
        },
    };
});

export const useInputStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            height: 40,
            fontSize: '1.4rem',
        },
        focused: {
            '& fieldSet': {
                borderColor: `${theme.palette.info.main}!important`,
            },
        },
        input: {
            color: theme.palette.primary.contrastText,
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
            '&[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
        fullWidth: {
            width: '100%',
        },
    };
});

export const useAutoCompleteStyles = makeStyles(() => {
    return {
        clearIndicator: {
            '& span': {
                '& svg': {
                    width: 0,
                    height: 0,
                },
            },
        },
        popupIndicator: {
            '& span': {
                '& svg': {
                    width: 20,
                    top: 'calc(50% - 10px)',
                    height: 20,
                    '& path': {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')",
                    },
                },
            },
        },
        textField: {
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: 0,
            },
        },
    };
});

export const useFormControlLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            alignItems: 'flex-start',
            margin: 0,
            '& .MuiInputBase-root': {
                width: '100%',
            },
        },
        label: {
            fontSize: '1.4rem',
            opacity: 0.6,
            color: theme.palette.primary.contrastText,
        },
        disabled: {
            '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.contrastText,
            },
        },
    };
});
