import React, { ChangeEvent, SyntheticEvent } from 'react';
import { GridColDef, GridCellParams } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { AnyAbility } from '@casl/ability';

import { IconButton } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { CloudDownloadOutlined, Visibility } from '@material-ui/icons';

import {
    renderCellAsCurrencyExpand,
    renderCellAsDateExpand,
    renderCellExpand,
    renderCellExpandValueAsKeyForTranslation,
} from 'src/theming/theme-table/theme-table-cell-expand';
import { CheckboxItem, MenuItem } from 'src/theming';
import { CellWithFilterCheckboxes } from 'src/theming/theme-table/cell-with-filter-checkboxes';
import { Divisions } from 'src/shared/constants';
import { APP_ROUTES } from 'src/routing';
import { appInstance } from 'src/environment';
import { AppInstances } from 'src/shared/constants/app-instances';
import { CellWithSearch } from 'src/theming/theme-table/cell-with-search';
import { CellWithDatePicker } from 'src/theming/theme-table/cell-with-date-picker';
import { AbilityActions, AbilitySubjects } from 'src/roleAccesses';

import { SECTIONS } from './contract-details-view-kfzw/contract-details-view-kfzw-details';
import { DeleteCell } from './delete-document-cell';

const isAonKompositInstance = appInstance === AppInstances.AON_KOMPOSIT;

export enum ColumnKeys {
    DELETE = 'delete',
    DOWNLOAD = 'download',
    TITLE = 'title',
    DATE = 'date',
    TYPE = 'type',
}

export const generateContractDetailsViewTableHeader = (
    t: TFunction,
    titleValue: string,
    dateValue: ParsableDate,
    handleChangeSearchField: (e: ChangeEvent<HTMLInputElement>) => void,
    handleChangeDate: (name: string, value: ParsableDate) => void,
    dateSearchFieldClass: string,
    onDownload: (letterId: string) => void,
    onRemoveSuccess: () => void,
    matchUpLargeSize: boolean
): GridColDef[] => {
    return [
        ...(isAonKompositInstance
            ? [
                  {
                      field: ColumnKeys.DELETE,
                      width: 60,
                      renderCell: (props) => (
                          <DeleteCell
                              {...props}
                              entity='document-contracts'
                              onSuccess={onRemoveSuccess}
                              approveMessage='contract-details-view:documents.approveDeletingMessage'
                          />
                      ),
                      renderHeader: () => <></>,
                  },
              ]
            : []),
        {
            field: ColumnKeys.DOWNLOAD,
            width: 60,
            renderHeader: () => <></>,
            renderCell: (props) => (
                <IconButton onClick={() => onDownload(props.row.id)}>
                    <CloudDownloadOutlined />
                </IconButton>
            ),
        },
        {
            field: ColumnKeys.TITLE,
            headerName: t('contract-details-view:documents.table.title'),
            flex: matchUpLargeSize ? 2.2 : 1,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('contract-details-view:documents.table.title')}
                    value={titleValue}
                    handleChange={handleChangeSearchField}
                    name='title'
                />
            ),
            renderCell: renderCellExpand,
        },
        {
            field: ColumnKeys.DATE,
            headerName: t('contract-details-view:documents.table.date'),
            flex: 1,
            renderHeader: (props) => (
                <CellWithDatePicker
                    {...props}
                    title={t('contract-details-view:documents.table.date')}
                    value={dateValue}
                    handleChange={handleChangeDate}
                    name='date'
                    dateSearchFieldClass={dateSearchFieldClass}
                />
            ),
            renderCell: renderCellAsDateExpand,
        },
        {
            field: ColumnKeys.TYPE,
            headerName: t('contract-details-view:documents.table.type'),
            flex: 0.5,
            renderCell: renderCellExpand,
        },
    ];
};

export const generateContractsDetailsAdditionalMenu = (
    t: TFunction,
    handleOpenVehicleDeregistration: () => void | null,
    handleOpenContractEdit: () => void,
    goTo: (pathname: string) => void,
    ability: AnyAbility,
    contractId: string,
    contractNumber: string
): MenuItem[] => {
    return [
        ...(ability.can(AbilityActions.create, AbilitySubjects.damageReport)
            ? [
                  {
                      title: t(
                          'contract-details-view:contractDetails.addMenu.createDamage'
                      ),
                      action: () =>
                          goTo(
                              `${APP_ROUTES.DAMAGE_REPORT}?contractId=${contractId}&contractNumber=${contractNumber}`
                          ),
                  },
              ]
            : []),
        ...(typeof handleOpenVehicleDeregistration === 'function'
            ? [
                  {
                      title: t(
                          'contract-details-view:contractDetails.addMenu.deactivateVehicle'
                      ),
                      action: handleOpenVehicleDeregistration,
                  },
              ]
            : []),
        ...(ability.can(AbilityActions.edit, AbilitySubjects.contract)
            ? [
                  {
                      title: t(
                          'contract-details-view:contractDetails.addMenu.changeContract'
                      ),
                      action: handleOpenContractEdit,
                  },
              ]
            : []),
    ];
};

export enum DamagesColumnKeys {
    OPEN = '',
    RISK = 'risk',
    DAMAGE_NR = 'damageNumber',
    DAMAGE_NR_ESTATE_AGENT = 'damageNumberE',
    DAMAGE_NR_VU = 'damageNumberI',
    DAMAGE_DAY = 'dayOfDamage',
    DAMAGE_AMOUNT = 'damageHeight',
    RESERVE = 'reserve',
    PAYMENT_VU = 'paymentInEuro',
    DAMAGE_STATUS = 'status',
    DEDUCTIBLE = 'deductible',
    ASSIGNMENT = 'assignment',
    CAUSED = 'caused',
}

export const generateContractDetailsViewDamagesTableHeader = (
    t: TFunction,
    id: string,
    damageNR: string,
    damageNREstateAgent: string,
    damageNRVU: string,
    damageStatus: CheckboxItem[],
    handleChangeValue: (e: SyntheticEvent) => void,
    handleChangeList: (newList: CheckboxItem[], name: string) => void,
    division?: string,
    onCloseHandler?: () => void,
    handleContractDetails?: (path: string, state?: unknown) => void,
    prevState?: { prevPath: string; prevState: unknown }
): GridColDef[] => {
    return [
        {
            field: DamagesColumnKeys.OPEN,
            width: 60,
            renderCell: (props) => (
                <IconButton
                    onClick={() => {
                        handleContractDetails(props.row.links?.damageNumberE, {
                            previousPath: `${APP_ROUTES.CONTRACTS}/${id}`,
                            prevState: {
                                ...prevState,
                                preselectedTab: SECTIONS.DAMAGES,
                            },
                        });
                    }}
                >
                    <Visibility />
                </IconButton>
            ),
        },
        {
            field: DamagesColumnKeys.RISK,
            headerName: t('contract-details-view:damages.risk'),
            flex: 1,
            renderCell: renderCellExpand,
        },
        {
            field: DamagesColumnKeys.DAMAGE_NR,
            headerName: t('contract-details-view:damages.damageNR'),
            flex: 1.5,
            renderCell: renderCellExpand,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('contract-details-view:damages.damageNR')}
                    value={damageNR}
                    handleChange={handleChangeValue}
                    name='damageNR'
                />
            ),
        },
        {
            field: DamagesColumnKeys.DAMAGE_NR_ESTATE_AGENT,
            headerName: t('contract-details-view:damages.damageNREstateAgent'),
            flex: 1.5,
            renderCell: renderCellExpand,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('contract-details-view:damages.damageNREstateAgent')}
                    value={damageNREstateAgent}
                    handleChange={handleChangeValue}
                    name='damageNREstateAgent'
                />
            ),
        },
        {
            field: DamagesColumnKeys.DAMAGE_NR_VU,
            headerName: t('contract-details-view:damages.damageNRVU'),
            flex: 1.5,
            renderCell: renderCellExpand,
            renderHeader: (props) => (
                <CellWithSearch
                    {...props}
                    title={t('contract-details-view:damages.damageNRVU')}
                    value={damageNRVU}
                    handleChange={handleChangeValue}
                    name='damageNRVU'
                />
            ),
        },
        {
            field: DamagesColumnKeys.DAMAGE_DAY,
            headerName: t('contract-details-view:damages.damageDay'),
            flex: 1,
            renderCell: renderCellAsDateExpand,
        },
        {
            field: DamagesColumnKeys.DAMAGE_AMOUNT,
            headerName: t('contract-details-view:damages.damageAmount'),
            flex: 1,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
        },
        ...(isAonKompositInstance
            ? []
            : ([
                  {
                      field: DamagesColumnKeys.RESERVE,
                      headerName: t('contract-details-view:damages.reserve'),
                      flex: 1,
                      renderCell: renderCellAsCurrencyExpand,
                      headerAlign: 'right',
                      align: 'right',
                  },
                  {
                      field: DamagesColumnKeys.PAYMENT_VU,
                      headerName: t('contract-details-view:damages.paymentVU'),
                      flex: 1,
                      renderCell: renderCellAsCurrencyExpand,
                      headerAlign: 'right',
                      align: 'right',
                  },
              ] as GridColDef[])),
        {
            field: DamagesColumnKeys.DEDUCTIBLE,
            headerName: t('contract-details-view:damages.deductible'),
            flex: 1,
            renderCell: renderCellAsCurrencyExpand,
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: DamagesColumnKeys.ASSIGNMENT,
            headerName: t('contract-details-view:damages.assignment'),
            flex: 1,
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['assignments']),
        },
        ...(division === Divisions.KFZW
            ? [
                  {
                      field: DamagesColumnKeys.CAUSED,
                      headerName: t('contract-details-view:damages.caused'),
                      flex: 1,
                      renderCell: renderCellExpand,
                  },
              ]
            : []),
        {
            field: DamagesColumnKeys.DAMAGE_STATUS,
            headerName: t('contract-details-view:damages.damageStatus'),
            flex: 1.5,
            renderHeader: (props) => (
                <CellWithFilterCheckboxes
                    {...props}
                    onCloseHandler={onCloseHandler}
                    name='damageStatus'
                    title={t('contract-details-view:damages.damageStatus')}
                    itemList={damageStatus}
                    setItemList={handleChangeList}
                />
            ),
            renderCell: (params: GridCellParams) =>
                renderCellExpandValueAsKeyForTranslation(params, ['damage-details-view']),
        },
    ];
};

export const contractDetailsViewDamagesGenerateDamageStatusValues = (
    t: TFunction
): CheckboxItem[] => {
    return [
        {
            title: t('common:all'),
            value: 'all',
            checked: true,
            data: undefined,
        },
        {
            title: t('damages-list:filterTitles.openDamages'),
            value: 'Offen',
            checked: false,
            data: undefined,
        },
        {
            title: t('damages-list:filterTitles.createdDamages'),
            value: 'Angelegt',
            checked: false,
            data: undefined,
        },
        {
            title: t('damages-list:filterTitles.closedDamages'),
            value: 'Geschlossen',
            checked: false,
            data: undefined,
        },
    ];
};
