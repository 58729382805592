import React, { useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Paper, Grid, Typography, List } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { ThemeNestedMenu, ThemeLoader } from 'src/theming';

import {
    useStyle,
    usePaperStyle,
} from './damages-details-view-style/damages-details-view-style';

import { useDocumentFormats } from 'src/shared/hooks';

import { ContractUploadDocumentForm } from 'src/contracts/contracts-upload-documents-form/contracts-upload-documents-form';

import { generateDamagesDetailsAdditionalMenu } from './damages-details-view-config';
import { useDamageDetailsData } from './use-damage-details-data';

import { ContractDetailsListItem } from '../contracts-details-view/contract-details-list-item';

import { useAuth } from '../../user-management/context-auth';
import { INTERNAL_ROLES } from '../../shared/constants';
import { isHectorStyles } from 'src/environment';

interface Props {
    setTitle: (value: string) => void;
    setNeedToRefetch: Dispatch<SetStateAction<boolean>>;
}

export const DamagesDetailsViewDetails = (props: Props): JSX.Element => {
    const classes = useStyle();
    const paperClasses = usePaperStyle();

    const { setTitle, setNeedToRefetch } = props;

    const [isOpenUploadDocumentForm, setOpenUploadDocumentForm] = useState(false);

    const { id } = useParams<{ id: string }>();

    const documentFormats = useDocumentFormats();

    const { t } = useTranslation([
        'damage-details-view',
        'formatted-values',
        'damages-list',
        'assignments',
    ]);

    const { isLoading: isDamageDetailsLoading, data: damageData } =
        useDamageDetailsData(id);

    const handleOpenUploadModalWindow = (): void => {
        setOpenUploadDocumentForm(true);
    };

    const handleCloseUploadModalWindow = (): void => {
        setOpenUploadDocumentForm(false);
    };

    useEffect(() => {
        if (damageData) {
            const valuesForTitle = Object.entries(damageData)
                .filter(
                    (item) =>
                        (item[0] === 'customer' && Boolean(item[1])) ||
                        (item[0] === 'contractNumber' && Boolean(item[1])) ||
                        (item[0] === 'division' && Boolean(item[1])) ||
                        (item[0] === 'dayOfDamage' && Boolean(item[1]))
                )
                .map((item) => {
                    if (item[0] === 'dayOfDamage') {
                        return t('formatted-values:formattedDate', {
                            date: item[1],
                        });
                    }
                    return item[1];
                })
                .join(' - ');
            setTitle(valuesForTitle);
        }
    }, [t, damageData]);

    const addMenuItems = useMemo(
        () => generateDamagesDetailsAdditionalMenu(t, handleOpenUploadModalWindow),
        [t]
    );

    const {
        userData: {
            userInfo: { roles: userRoles },
        },
    } = useAuth();

    const hideActions = useMemo(() => {
        return userRoles.includes(INTERNAL_ROLES.ADMIN_DOMAIN) && userRoles.length === 1;
    }, [userRoles]);

    return (
        <>
            {!isDamageDetailsLoading && (
                <Paper classes={paperClasses}>
                    <Grid container className={classes.header}>
                        <Typography variant='h6' className={classes.cardTitle}>
                            {t('damage-details-view:details.title')}
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        {!hideActions && (
                            <ThemeNestedMenu
                                menuItems={addMenuItems}
                                buttonClass={classes.additionalMenuButton}
                                buttonIcon={<MenuIcon className={classes.menuIcon} />}
                            />
                        )}
                    </Grid>
                    <List className={classes.list}>
                        <ContractDetailsListItem
                            hidden={!damageData?.damageProcess?.trim()}
                            value={damageData?.damageProcess?.trim()}
                            title='damage-details-view:details.process'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.dayOfDamage}
                            value={t('formatted-values:formattedDate', {
                                date: damageData?.dayOfDamage,
                            })}
                            title='damage-details-view:details.damageDay'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.timeOfDamage}
                            value={t('formatted-values:formattedTime', {
                                time: damageData?.timeOfDamage,
                            })}
                            title='damage-details-view:details.time'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.classification?.trim()}
                            value={t(`assignments:${damageData?.classification?.trim()}`)}
                            title='damage-details-view:details.assigment'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.damageLocation?.trim()}
                            value={damageData?.damageLocation?.trim()}
                            title='damage-details-view:details.placeOfDamage'
                        />
                        <ContractDetailsListItem
                            hidden={
                                damageData?.damageHeight === null ||
                                damageData?.damageHeight == 0
                            }
                            value={t('formatted-values:formattedCurrency', {
                                currency: damageData?.damageHeight,
                            })}
                            title='damage-details-view:details.damageInMoney'
                        />
                        <ContractDetailsListItem
                            hidden={
                                damageData?.payment === null || damageData?.payment === 0
                            }
                            value={t('formatted-values:formattedCurrency', {
                                currency: damageData?.payment,
                            })}
                            title='damage-details-view:details.payment'
                        />
                        <ContractDetailsListItem
                            hidden={
                                damageData?.reserve === null || damageData?.reserve === 0
                            }
                            value={t('formatted-values:formattedCurrency', {
                                currency: damageData?.reserve,
                            })}
                            title='damage-details-view:details.reserve'
                        />
                        <ContractDetailsListItem
                            hidden={
                                damageData?.totalPayment === null ||
                                damageData?.totalPayment === 0
                            }
                            value={t('formatted-values:formattedCurrency', {
                                currency: damageData?.totalPayment,
                            })}
                            title='damage-details-view:details.totalAmount'
                        />
                    </List>
                    <List className={classes.list}>
                        <ContractDetailsListItem
                            hidden={!damageData?.damageNumberE?.trim()}
                            value={damageData?.damageNumberE?.trim()}
                            title='damage-details-view:details.damageNumberBroker'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.damageNumber?.trim()}
                            value={damageData?.damageNumber?.trim()}
                            title='damage-details-view:details.damageNR'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.damageNumberVu?.trim()}
                            value={damageData?.damageNumberVu?.trim()}
                            title='damage-details-view:details.damageNRVU'
                        />
                        <ContractDetailsListItem
                            hidden={
                                damageData?.deductible === null ||
                                damageData?.deductible === 0
                            }
                            value={t('formatted-values:formattedCurrency', {
                                currency: damageData?.deductible,
                            })}
                            title='damage-details-view:details.sbInMoney'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.agent?.trim()}
                            value={damageData?.agent?.trim()}
                            title='damage-details-view:details.editor'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.status?.trim()}
                            value={damageData?.status?.trim()}
                            title='damage-details-view:details.costStatus'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.notification}
                            value={t('formatted-values:formattedDate', {
                                date: damageData?.notification,
                            })}
                            title='damage-details-view:details.report'
                        />
                        <ContractDetailsListItem
                            hidden={!damageData?.completionDay}
                            value={t('formatted-values:formattedDate', {
                                date: damageData?.completionDay,
                            })}
                            title='damage-details-view:details.graduationDay'
                        />
                        <ContractDetailsListItem
                            hidden={ !damageData?.driver || !isHectorStyles}
                            value={damageData?.driver}
                            title='damage-details-view:details.driver'
                        />
                    </List>
                </Paper>
            )}
            {isDamageDetailsLoading && <ThemeLoader />}
            <ContractUploadDocumentForm
                withoutValidation
                documentFormats={documentFormats}
                isOpen={isOpenUploadDocumentForm}
                handleCloseModalWindow={handleCloseUploadModalWindow}
                pathForRequest={`damages/${id}/upload-documents`}
                formTitle={t('damage-details-view:details.newDamageAddendum')}
                dragAndDropLabel={t(
                    'damage-details-view:details.damageSupplementDocuments'
                )}
                onSuccess={() => setNeedToRefetch(true)}
            />
        </>
    );
};
