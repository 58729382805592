import moment from 'moment';
import {
    DamageReportFormAONKompositTechnicalTypes,
    DamageReportFormAONKompositTechnicalMutation,
} from '../../damage-report-form-types/damage-report-form-AON-Komposit-types/damage-report-form-AON-Komposit-technical-types';

export const damageReportFormTechnicalMutateAdapter = (
    data: DamageReportFormAONKompositTechnicalTypes
): DamageReportFormAONKompositTechnicalMutation => {
    const offset = new Date().getTimezoneOffset() * 60000;
    return {
        id: data.id,
        division: data.division,
        dayOfDamage: data.dayOfDamage
            ? moment(moment(data.dayOfDamage).valueOf() - offset).toISOString()
            : null,
        timeOfDamage: data.timeOfDamage
            ? moment(moment(data.timeOfDamage).valueOf() - offset).toISOString()
            : null,
        contractId: data.contractId,
        risk: data.risk,
        contractNumber: data.contractNumber,
        policyHolder: data.policyHolder,
        insuranceCompany: data.insuranceCompany,
        newFiles: data.files?.map((item) => item.file),
        fileInfos: data.fileInfos,
        whoCreatedServicesAffectedByDamage:
            data?.whoCreatedTheServicesAffectedByTheDamage,
        technicalInsuranceClaim: {
            damageLocation: data.placeOfDamage,
            damagedItems: data?.itemsAffectedByTheDamage?.map((item) => ({
                numberAndTypeOfThings: item.numberAndTypeOfThings,
                machineDirectoryPosition: item.acquisitionOrReplacementPrice,
                damageExtent:
                    item.repairCosts && typeof item.repairCosts === 'string'
                        ? Number(item.repairCosts.replaceAll('.', '').replace(',', '.'))
                        : 0,
            })),
            damageAndCause: data?.damageAndCause,
            estimatedLossAmount:
                data?.estimatedLossAmountInEUR &&
                typeof data?.estimatedLossAmountInEUR === 'string'
                    ? Number(
                          data?.estimatedLossAmountInEUR
                              .replaceAll('.', '')
                              .replace(',', '.')
                      )
                    : '',
            isDamagedItemsOperatedProvisionally:
                data?.shouldDamagedItemsBeOperatedProvisionally,
            isRepairPossibleAndPlanned: data?.isARepairPossibleAndPlanned,
            whoAnswerAboutTechnicalDetails:
                data?.whoCanAnswerQuestionsAboutTheTechnicalDetails,
            hasWarrantyPeriod: data?.warrantyPeriodForTheDamagedObjectExpired,
            isOtherwiseInsured: data?.damageInformationAreDamagedItemsOtherwiseInsured,
            insuredItemsLoss: data?.lossOfInsuredItemsDueToTheftBurglaryOrArson,
            namely: data?.namely,
            namelyBy: data?.namelyBy,
            through: data?.through,
            insuranceCompanyIndication:
                data?.indicationOfInsuranceCompanyInsuranceNumberAndTypeOfInsurance,
        },
        businessInformation: {
            isInterruptionInOperation:
                data?.interruptionInOperationOrDelayIncommissioning,
            expectedDamage:
                data?.expectedDamageInEurosPerDayOrWeekOrMonth &&
                typeof data?.expectedDamageInEurosPerDayOrWeekOrMonth === 'string'
                    ? Number(
                          data?.expectedDamageInEurosPerDayOrWeekOrMonth
                              .replaceAll('.', '')
                              .replace(',', '.')
                      )
                    : '',
            mitigationMeasures: data?.mitigationMeasuresHaveAlreadyBeenTaken,
            additionalCost:
                data?.additionalCostsAreIncurredForBridgingMeasures &&
                typeof data?.additionalCostsAreIncurredForBridgingMeasures === 'string'
                    ? Number(
                          data?.additionalCostsAreIncurredForBridgingMeasures
                              .replaceAll('.', '')
                              .replace(',', '.')
                      )
                    : '',
            interruptionInOperationNamely: data?.interruptionInOperationNamely,
            interruptionInOperationStandsSince: data?.interruptionInOperationStandsSince,
        },
        projectPhaseInformation: {
            projectPhase: data?.projectPhase,
            thingAffectedByDamage: data?.thingAffectedByTheDamage,
            insuredConstruction: data?.insuredConstructionOrAssemblyProject,
            isStolenPartsPermanentlyInstalled: data?.caseOfTheftForConstructionInsurance,
            thingAffectedByTheDamageSince: data?.thingAffectedByTheDamageSince,
            thingAffectedAcceptedSince: data?.thingAffectedAcceptedSince,
            thingAffectedAcceptedBy: data?.thingAffectedAcceptedBy,
            insuredConstructionOrAssemblyProjectSince:
                data?.insuredConstructionOrAssemblyProjectSince,
            insuredConstructionOrAssemblyProjectAcceptedSince:
                data?.insuredConstructionOrAssemblyProjectAcceptedSince,
            insuredConstructionOrAssemblyProjectAcceptedBy:
                data?.insuredConstructionOrAssemblyProjectAcceptedBy,
        },
        policeRecordTechnical: {
            incidentRecordedByPolice: data?.isRecordedByThePolice,
            policeStation: data?.policeStation,
            policeFileNumber: data?.fileNumber,
        },
        witnessesInformationTechnical: {
            isAnyWitnesses: data?.isWitnesses,
            name: data?.witnessesSurname,
            street: data?.witnessesRoad,
            postCode: data?.witnessesPostcode,
            ort: data?.witnessesPlace,
            phoneNumber: data?.witnessesPhoneNumber,
            email: data?.witnessesEmail,
        },
    };
};
