import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { useAbilityCtx, AbilityActions, AbilitySubjects } from 'src/roleAccesses';
import { useGlobalFilter } from 'src/shared/contexts';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ThemeLoader } from 'src/theming';

import { useStyle } from './damage-report-form-styles/damage-report-form-styles';
import { DamageReportFormGetRecordDTO } from './damage-report-form-utils/damage-report-form-types/damage-report-form-get-record-dto';

import { getDamageReportForms } from './damage-report-form-factory/damage-report-form-get-collection';

const damageReportForms = getDamageReportForms();

export const DamageReportForm = (): JSX.Element => {
    const ability = useAbilityCtx();
    const classes = useStyle();
    const { t } = useTranslation(['common', 'damages-report', 'errors']);

    const router = useHistory();
    const httpClient = useHttpClient();
    const { id } = useParams<{ id?: string }>();

    const { division } = useGlobalFilter();

    const [divisionState, setDivisionState] = useState<string>(division);

    const [isReceivingDataLoading, setReceivingDataLoading] = useState<boolean>(false);
    const [damageReportData, setDamageReportData] =
        useState<DamageReportFormGetRecordDTO>(null);

    const setDivision = useCallback((value: string) => {
        setDivisionState(value);
        if (damageReportData && damageReportData.division !== divisionState) {
            setDamageReportData(null);
        }
    }, []);

    const hideButtons = useMemo(() => {
        return ability.cannot(AbilityActions.edit, AbilitySubjects.damageReport);
    }, [ability]);

    const makeRequestForDamageReportData = (): Promise<DamageReportFormGetRecordDTO> => {
        setReceivingDataLoading(true);
        return httpClient.get(`damage-reports/${id}`);
    };

    useEffect(() => {
        if (id) {
            makeRequestForDamageReportData()
                .then((data: DamageReportFormGetRecordDTO) => {
                    setDamageReportData(data);
                    setDivisionState(data.division);
                })
                .finally(() => {
                    setReceivingDataLoading(false);
                });
        }
    }, [id]);

    const Form = damageReportForms[divisionState];

    return (
        <Grid container className={classes.pageContainer}>
            <Grid container className={classes.titleContainer}>
                <IconButton
                    size='small'
                    className={classes.backButton}
                    onClick={router.goBack}
                >
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Typography variant='h5' className={classes.title}>
                    {t('damages-report:damageReport')}
                </Typography>
            </Grid>
            {Form && !isReceivingDataLoading && (
                <Form
                    setDivision={setDivision}
                    hideButtons={hideButtons}
                    data={damageReportData}
                    divisionState={divisionState}
                />
            )}
            {!Form && !isReceivingDataLoading && (
                <Typography variant='body1'>
                    {t('damages-report:noDamageReportForm')}
                </Typography>
            )}
            {isReceivingDataLoading && <ThemeLoader />}
        </Grid>
    );
};
