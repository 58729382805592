import { appInstance, hectorStyleInstances } from 'src/environment';
import { AppInstances } from 'src/shared/constants';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
    useContractDashboardAonMotorStyle,
    useDashboardActionsAonMotorStyle,
    useFormControlAonMotorStyles,
    useFormControlLabelAonMotorStyles,
    useFormControlYearAonMotorStyles,
    useInputAonMotorStyles,
    useAonMotorSelectStyles,
    useAonMotorMenuStyles,
} from './contract-dashboard-aon-motor-styles';
import {
    useInputAonStyles,
    useFormControlYearAonStyles,
    useFormControlLabelAonStyles,
    useFormControlAonStyles,
    useDashboardActionsAonStyle,
    useContractDashboardAonStyle,
} from './contract-dashboard-aon-styles';
import {
    useInputHectorStyles,
    useHectorSelectStyles,
    useHectorMenuStyles,
    useFormControlYearHectorStyles,
    useFormControlLabelHectorStyles,
    useFormControlHectorStyles,
    useDashboardActionsHectorStyle,
    useContractDashboardHectorStyle,
} from './contract-dashboard-hector-styles';
import {
    useSgiffoxxkSelectStyles,
    useSgiffoxxkMenuStyles,
    useInputSgiffoxxkStyles,
    useFormControlYearSgiffoxxkStyles,
    useFormControlSgiffoxxkStyles,
    useFormControlLabelSgiffoxxkStyles,
    useDashboardActionsSgiffoxxkStyle,
    useContractDashboardSgiffoxxkStyle,
} from './contract-dashboard-sgiffoxxk-styles';

interface useStyleProps {
    isDarkMode: boolean;
    editMode: boolean;
}

const useStyleInstance = {
    [AppInstances.AON]: useContractDashboardAonStyle,
    [AppInstances.AON_KOMPOSIT]: useContractDashboardAonStyle,
    [AppInstances.AON_DIGITAL]: useContractDashboardAonMotorStyle,
    [AppInstances.AON_MOTOR]: useContractDashboardAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useContractDashboardAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useContractDashboardSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useContractDashboardSgiffoxxkStyle,
};

const useFormControlLabelStyleInstance = {
    [AppInstances.AON]: useFormControlLabelAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlLabelAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlLabelAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlLabelAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlLabelAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlLabelSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useFormControlLabelSgiffoxxkStyles,
};

const useFormControlStyleInstance = {
    [AppInstances.AON]: useFormControlAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useFormControlSgiffoxxkStyles,
};

const useInputInstanceStyle = {
    [AppInstances.AON]: useInputAonStyles,
    [AppInstances.AON_KOMPOSIT]: useInputAonStyles,
    [AppInstances.AON_DIGITAL]: useInputAonMotorStyles,
    [AppInstances.AON_MOTOR]: useInputAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useInputAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useInputSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useInputSgiffoxxkStyles,
};

const useFormControlYearStylesInstance = {
    [AppInstances.AON]: useFormControlYearAonStyles,
    [AppInstances.AON_KOMPOSIT]: useFormControlYearAonStyles,
    [AppInstances.AON_DIGITAL]: useFormControlYearAonMotorStyles,
    [AppInstances.AON_MOTOR]: useFormControlYearAonMotorStyles,
    [AppInstances.HECTOR_DEMO]: useFormControlYearAonMotorStyles,
    [AppInstances.SGIFFOXXK]: useFormControlYearSgiffoxxkStyles,
    [AppInstances.AGRAVIS]: useFormControlYearSgiffoxxkStyles,
};

const useDashboardActionsStyleInstance = {
    [AppInstances.AON]: useDashboardActionsAonStyle,
    [AppInstances.AON_KOMPOSIT]: useDashboardActionsAonStyle,
    [AppInstances.AON_DIGITAL]: useDashboardActionsAonMotorStyle,
    [AppInstances.AON_MOTOR]: useDashboardActionsAonMotorStyle,
    [AppInstances.HECTOR_DEMO]: useDashboardActionsAonMotorStyle,
    [AppInstances.SGIFFOXXK]: useDashboardActionsSgiffoxxkStyle,
    [AppInstances.AGRAVIS]: useDashboardActionsSgiffoxxkStyle,
};

const useMenuClassesInstance = {
    [AppInstances.AON_MOTOR]: useAonMotorMenuStyles,
    [AppInstances.AON_DIGITAL]: useAonMotorMenuStyles,
    [AppInstances.HECTOR_DEMO]: useAonMotorMenuStyles,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkMenuStyles,
    [AppInstances.AGRAVIS]: useSgiffoxxkMenuStyles,
};

const useSelectClassesInstance = {
    [AppInstances.AON_MOTOR]: useAonMotorSelectStyles,
    [AppInstances.AON_DIGITAL]: useAonMotorSelectStyles,
    [AppInstances.HECTOR_DEMO]: useAonMotorSelectStyles,
    [AppInstances.SGIFFOXXK]: useSgiffoxxkSelectStyles,
    [AppInstances.AGRAVIS]: useSgiffoxxkSelectStyles,
};

hectorStyleInstances.forEach((instance) => {
    useStyleInstance[instance] = useContractDashboardHectorStyle;
    useFormControlLabelStyleInstance[instance] = useFormControlLabelHectorStyles;
    useFormControlStyleInstance[instance] = useFormControlHectorStyles;
    useInputInstanceStyle[instance] = useInputHectorStyles;
    useFormControlYearStylesInstance[instance] = useFormControlYearHectorStyles;
    useDashboardActionsStyleInstance[instance] = useDashboardActionsHectorStyle;
    useMenuClassesInstance[instance] = useHectorMenuStyles;
    useSelectClassesInstance[instance] = useHectorSelectStyles;
});

export const useStyle = (props: useStyleProps): ClassNameMap => {
    return useStyleInstance[appInstance](props);
};

export const useFormControlYearStyles = (): ClassNameMap => {
    return useFormControlYearStylesInstance[appInstance]();
};

export const useFormControlStyles = (): ClassNameMap => {
    return useFormControlStyleInstance[appInstance]();
};

export const useInputStyles = (): ClassNameMap => {
    return useInputInstanceStyle[appInstance]();
};

export const useFormControlLabelStyles = (): ClassNameMap => {
    return useFormControlLabelStyleInstance[appInstance]();
};

export const useDashboardActionsStyle = (): ClassNameMap => {
    return useDashboardActionsStyleInstance[appInstance]();
};

export const useMenuClasses = (): ClassNameMap => {
    const hook = useMenuClassesInstance[appInstance];
    if (hook) {
        return useMenuClassesInstance[appInstance]();
    }
};

export const useSelectClasses = (): ClassNameMap => {
    const hook = useMenuClassesInstance[appInstance];
    if (hook) {
        return useSelectClassesInstance[appInstance]();
    }
};
