import React from 'react';
import clsx from 'clsx';
import { Grid, Switch, Chip } from '@material-ui/core';

import { useSwitcherStyle, useStyle, useChipStyles } from './theme-switcher-styles';

interface SwitcherProps {
    checked: boolean;
    onChange?: () => void;
    leftLabel?: string;
    rightLabel?: string;
    labelClass?: string;
    disabled?: boolean;
}

export const ThemeSwitcher = (props: SwitcherProps): JSX.Element => {
    const { checked, onChange, leftLabel, rightLabel, labelClass, disabled } = props;

    const switcherClasses = useSwitcherStyle();
    const classes = useStyle();
    const chipClasses = useChipStyles({ checked });

    return (
        <Grid
            container
            component='label'
            alignItems='center'
            spacing={1}
            wrap='nowrap'
            style={{ width: 'fit-content' }}
        >
            {!disabled && (
                <>
                    {leftLabel && (
                        <Grid item className={clsx(classes.label, labelClass)}>
                            {leftLabel}
                        </Grid>
                    )}
                    <Grid item>
                        <Switch
                            classes={switcherClasses}
                            checked={!checked}
                            onChange={onChange}
                        />
                    </Grid>
                    {rightLabel && (
                        <Grid item className={clsx(classes.label, labelClass)}>
                            {rightLabel}
                        </Grid>
                    )}
                </>
            )}
            {disabled && (
                <Chip label={checked ? rightLabel : leftLabel} classes={chipClasses} />
            )}
        </Grid>
    );
};
